import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ButtonLink as ButtonLinkInterface } from 'types/Button';

import { defineButtonBackground, defineButtonColor, defineTextAlignment } from '../utils';

/* eslint-disable */
export const Btn = styled(Link)<ButtonLinkInterface>`
  font-size: 13px;
  width: ${({ fullWidth, circleButton }) => (Boolean(fullWidth) ? '100%' : circleButton ? '36px' : 'auto')};
  min-height: ${({ circleButton }) => (circleButton ? '36px' : '30px')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => defineTextAlignment(textAlign)};
  padding: ${({ circleButton, iconButton }) => (circleButton || iconButton ? '5px' : '12px 12px')};
  border-radius: ${({ circleButton }) => (circleButton ? '50%' : '4px')};
  border: 2px solid ${({ theme, transparent }) => (transparent ? theme.colors.grey : 'transparent')};
  outline: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background: ${({ theme, primary, secondary, transparent, iconButton, circleButton }) =>
    defineButtonBackground(theme, primary, secondary, transparent, iconButton, circleButton).default};
  color: ${({ theme, primary, secondary, transparent, iconButton, circleButton }) =>
    defineButtonColor(theme, primary, secondary, transparent, iconButton, circleButton)};
  transition: all 0.3s ease;
  box-shadow: ${({ transparent, disabled, iconButton }) =>
    disabled || transparent || iconButton ? 'none' : '0px 8px 8px rgba(31, 47, 77, 0.1)'};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ theme, primary, secondary, transparent, iconButton, circleButton }) =>
      defineButtonBackground(theme, primary, secondary, transparent, iconButton, circleButton).hover};
    box-shadow: ${({ disabled, iconButton }) =>
      disabled || iconButton ? 'none' : '0px 8px 8px rgba(31, 47, 77, 0.3)'};
  }

  &:active {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ theme, primary, secondary, transparent, iconButton, circleButton }) =>
      defineButtonBackground(theme, primary, secondary, transparent, iconButton, circleButton).active};
    box-shadow: none;
  }

  &:focus {
    box-shadow: ${({ transparent, disabled, iconButton }) =>
      disabled || transparent || iconButton ? 'none' : '0px 8px 8px rgba(31, 47, 77, 0.3)'};
  }

  & > * {
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  }
  &[disabled] {
    pointer-events: none;
  }
`;
/* eslint-enable */

interface ButtonIconProps {
  primary?: boolean;
  secondary?: boolean;
  transparent?: boolean;
}

export const BtnIconLeft = styled.span<ButtonIconProps>`
  display: inline-flex;
  color: ${({ theme, primary, secondary, transparent }) =>
    primary || secondary || transparent ? theme.light : theme.fontPrimary};
  margin-right: 18px;
`;

export const BtnIconRight = styled.span<ButtonIconProps>`
  display: inline-flex;
  color: ${({ theme, primary, secondary, transparent }) =>
    primary || secondary || transparent ? theme.light : theme.fontPrimary};
  margin-left: 18px;
`;

export const ButtonLeft = styled.div`
  align-items: center;
  display: flex;
`;
