import { ReactNode, useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks';
import { Button } from 'common/components/Buttons';
import { ArrowDownIcon } from 'common/components/Icons';
import { ButtonDropDown, ButtonDropDownList, ButtonDropDownListItem, ButtonWrapper, Wrapper } from './styles';

export interface ButtonGroupProps {
  title: string | ReactNode;
  isDisabled?: boolean;
  size?: 'large' | 'medium' | 'small' | 'tiny';
  variant?: 'primary' | 'pending' | 'success' | 'secondary' | 'danger';
  items: any[];
  onItemClick?: (payload: any) => void;
}

const ButtonGroup = ({
  isDisabled,
  title,
  items,
  size = 'medium',
  variant = 'primary',
  onItemClick,
}: ButtonGroupProps) => {
  const node = useRef<any>();
  const [isOpenDropdown, setIsDropdownOpen] = useState(false);
  const closeDropdownHandler = () => setIsDropdownOpen(false);
  useOnClickOutside({ ref: node, handler: closeDropdownHandler });

  const onButtonHandler = () => {
    if (isDisabled) {
      return;
    }
    setIsDropdownOpen((open) => !open);
  };

  const onClickHandler = (item: any) => {
    closeDropdownHandler();
    onItemClick && onItemClick(item);
  };

  return (
    <Wrapper>
      <ButtonWrapper>
        <Button
          isBgTransparent
          primary={variant === 'primary'}
          pending={variant === 'pending'}
          success={variant === 'success'}
          secondary={variant === 'secondary'}
          danger={variant === 'danger'}
          fullWidth
          disabled={isDisabled}
          size={size}
          rightIcon={<ArrowDownIcon color='#fff' />} // TODO: use theme color for icon
          onClick={onButtonHandler}
        >
          {title}
        </Button>
      </ButtonWrapper>
      {isOpenDropdown && (
        <ButtonDropDown ref={node}>
          <ButtonDropDownList>
            {items.map((item, i) => (
              <ButtonDropDownListItem key={i} onClick={() => onClickHandler(item)}>
                {item}
              </ButtonDropDownListItem>
            ))}
          </ButtonDropDownList>
        </ButtonDropDown>
      )}
    </Wrapper>
  );
};

export default ButtonGroup;
