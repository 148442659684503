import * as dateFns from 'date-fns';
import { FULL_DATE_TIME_FORMAT } from 'common/constants';

export const getFormattedLongDate = (date: string | Date | undefined) => {
  const convertedDate = typeof date === 'string' ? new Date(date) : date;

  return convertedDate ? dateFns.format(convertedDate, FULL_DATE_TIME_FORMAT) : '-';
};

export const removeTimezoneOffsetBeforeSend = (date: Date): string => {
  const dateInMs = date.getTime();
  const offsetInMs = new Date().getTimezoneOffset() * 60000;

  return new Date(dateInMs - offsetInMs).toISOString();
};

export const getDateFromErrorMessage = (dateFrom: any, dateTo: any) => {
  if (dateFrom === null) {
    return '';
  }
  if (dateTo && dateFrom > dateTo) {
    return 'date-from-cant-be-greater-then-to';
  }

  return '';
};

export const getDateToErrorMessage = (dateFrom: any, dateTo: any) => {
  if (dateTo === null) {
    return '';
  }
  if (dateFrom && dateFrom > dateTo) {
    return 'date-to-cant-be-less-then-from';
  }

  return '';
};

export const increaseDateTo = (date: Date): Date => {
  const initialDate = new Date(date);
  return new Date(initialDate.setSeconds(initialDate.getSeconds() + 1));
};
