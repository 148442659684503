import { Icon } from 'types/Icon';

export const ArrowUpIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '13'} height={height || '8'} viewBox='0 0 13 8'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M6.05000019,0 L0.710875022,5.09643766 C0.33611529,5.45416286 0.322306072,6.04795922 0.680031271,6.42271895 C0.690072066,6.43323788 0.700356095,6.44352191 0.710875022,6.4535627 L0.732026841,6.47375308 C1.11814607,6.84232143 1.72566952,6.84265231 2.11218999,6.47450477 L6.05000019,2.72387509 L6.05000019,2.72387509 L9.98781039,6.47450477 C10.3743309,6.84265231 10.9818543,6.84232143 11.3679735,6.47375308 L11.3891254,6.4535627 C11.7638851,6.0958375 11.7776943,5.50204114 11.4199691,5.12728141 C11.4099283,5.11676248 11.3996443,5.10647845 11.3891254,5.09643766 L6.05000019,0 L6.05000019,0 Z'
        id='path-ArrowUpIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-350.000000, -3187.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(230.000000, 80.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-ArrowUpIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
