/* eslint-disable prettier/prettier */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { APP_URLS, ErrorCodes } from 'common/constants';
import { Alert, Button, FlipToggle, Message, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { ClientData, ClientIntegrationStatusRequest, ClientRequestParams, ClientStatusRequestData } from 'types/Client';
import StatusSelector from 'components/StatusSelector';
import {
  ClientsConfigurationSchemaRequest,
  IntegrationData,
  IntegrationRequest,
  IntegrationSchema,
  IntegrationsLevel,
  IntegrationsStatusAction,
  IntegrationsSystems,
  TestConnectionRequest,
  UpdateIntegrationRequest,
} from 'types/Integrations';
import { getAllFieldsFromSchema } from 'components/ConfigurationForm/utils';
import { prepareRequestData } from 'components/IntegrationForm/utils';
import IntegrationForm from 'components/IntegrationForm';
import { PropellApForm } from 'components/PropellApForm';
import { mapSystemName } from 'utils';
import { useAccessToken, useAuthenticatedAdminApiClient } from 'hooks';
import getClientIntegrationEditBreadcrumbs from './getClientIntegrationEditBreadcrumbs';
import {
  ButtonBack,
  Content,
  Head,
  HeadActions,
  TabButton,
  TabContent,
  TabContentInner,
  TabHeader,
  TabHeaderLeft,
  TabHeaderRight,
  TabsList,
  TabsWrapper,
  TabTitle,
  Title,
  TitleMark,
} from './styles';

type ClientIntegrationUrlParams = {
  customerId: string;
  clientId: string;
  integrationId: string;
};

type IntegrationEditProps = {
  isFetching: boolean;
  isTestConnectionFetching: boolean;
  errorCode: number | null;
  integrationSchema: IntegrationSchema;
  client: ClientData;
  integration: IntegrationData;
  updateClientIntegration: (payload: UpdateIntegrationRequest) => void;
  updateClientIntegrationStatus: (payload: ClientIntegrationStatusRequest) => void;
  getClient: (payload: ClientRequestParams) => void;
  setStatus: (payload: ClientStatusRequestData) => void;
  getClientIntegration: (payload: IntegrationRequest) => void;
  getClientConfigurationSchema: (payload: ClientsConfigurationSchemaRequest) => void;
  getClientTestConnection: (payload: TestConnectionRequest) => void;
  resetPartial: () => void;
  integrationReset: () => void;
}

type LocationState = {
  fromIntegrationCreatePage: boolean;
}

const IntegrationEdit = ({
  errorCode,
  isFetching,
  isTestConnectionFetching,
  client,
  integrationSchema,
  integration,
  getClientIntegration,
  getClientConfigurationSchema,
  updateClientIntegration,
  updateClientIntegrationStatus,
  getClient,
  setStatus,
  getClientTestConnection,
  integrationReset,
  resetPartial,
}: IntegrationEditProps) => {
  const { push } = useHistory();
  const { pathname, state: locationState } = useLocation<LocationState>();
  const { customerId, clientId, integrationId } = useParams<ClientIntegrationUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();

  const isIntegrationsDefaultPage = pathname.toLowerCase().includes('integrations-default');
  const isProfilePage = /profile+$/.test(pathname);

  const accessToken = useAccessToken();
  const adminApiClient = useAuthenticatedAdminApiClient();

  const [propellAp, setPropellAp] = useState<any>();
  const [propellApError, setPropellApError] = useState('');
  const [loadingPropellAp, setLoadingPropellAp] = useState(false);

  const [integrationRecentlyChangedStatus, setIntegrationRecentlyChangedStatus] = useState(false);

  // whether the integration is PropellAP
  const isPropellApIntegration = useCallback(() => {
    return integration?.systemName === IntegrationsSystems.PROPELLAI;
  }, [integration.systemName]);

  // loads the PropellAP
  const loadPropellAp = useCallback(() => {
    if (!adminApiClient || !isPropellApIntegration()) {
      return;
    }

    setLoadingPropellAp(true);
    adminApiClient.accessPointControllerGetPropellAccessPoints(Number(customerId), Number(clientId))
      .then((data) => {
        setPropellAp(data[0]);
        setLoadingPropellAp(false);
      })
      .catch((reason) => {
        setPropellAp(undefined);
        setLoadingPropellAp(false);
        setPropellApError(reason);
      });
  }, [adminApiClient, customerId, clientId, isPropellApIntegration]);

  // loading of Propell AP
  useEffect(() => {
    loadPropellAp();
  }, [loadPropellAp]);

  // testing of connection
  useEffect(() => {
    if (
      locationState?.fromIntegrationCreatePage &&
      accessToken &&
      integration.id &&
      integration.showTestConnectionButton
    ) {
      getClientTestConnection({
        accessToken,
        integrationId: integration.id,
      });
      window.history.replaceState({}, document.title);
    }
  }, [locationState, accessToken, integration.id, integration.showTestConnectionButton, getClientTestConnection]);

  // setting of breadcrumbs
  useEffect(() => {
    setBreadcrumbs(
      getClientIntegrationEditBreadcrumbs(
        Number(customerId),
        Number(clientId),
        client.customerName,
        client.name,
        integration?.systemName,
      ),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, integration?.systemName, setBreadcrumbs]);

  // loading of client
  useEffect(() => {
    if (!client.name && accessToken) {
      getClient({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
  }, [accessToken, clientId, customerId, client.name, getClient]);

  // loading of integration
  useEffect(() => {
    if (accessToken && integrationId) {
      getClientIntegration({
        accessToken,
        integrationId,
      });
    }
  }, [accessToken, integrationId, getClientIntegration]);

  // loading of configuration schema
  useEffect(() => {
    if (accessToken && integration.systemId) {
      getClientConfigurationSchema({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
        isCreatePage: false,
        systemId: integration.systemId,
      });
    }
  }, [accessToken, customerId, clientId, integration.systemId, getClientConfigurationSchema]);

  // resetting of integration
  useEffect(() => {
    return () => integrationReset();
  }, [integrationReset]);

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toClientIntegrations(Number(customerId), Number(clientId)));
  }, [customerId, clientId, push]);

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      const allFields = getAllFieldsFromSchema(integrationSchema, integration.settings);

      const preparePayload = prepareRequestData({
        integration,
        isIntegrationsDefaultPage,
        formData: data,
        fieldsArray: allFields,
        systemId: integration.systemId,
      });

      if (integration) {
        updateClientIntegration({
          accessToken,
          integrationId: integrationId,
          requestData: preparePayload,
          withTestConnection: integration.showTestConnectionButton,
        });
      }
    },
    [accessToken, isIntegrationsDefaultPage, integration, integrationSchema, integrationId, updateClientIntegration],
  );

  const onFlipToggleChangeHandler = (event: any) => {
    if (integration) {
      updateClientIntegrationStatus({
        accessToken,
        isProfilePage,
        customerId: Number(customerId),
        clientId: Number(clientId),
        integrationId: integration.id,
        system: integration.systemName,
        action: event.target.checked ? IntegrationsStatusAction.ACTIVATE : IntegrationsStatusAction.DEACTIVATE,
      });
      setIntegrationRecentlyChangedStatus(true);
    }
  };

  const onTestConnectionHandler = useCallback(() => {
    getClientTestConnection({
      accessToken,
      integrationId: integration.id,
    });
  }, [accessToken, integration.id, getClientTestConnection]);

  const renderForm = useMemo(() => {
    return integration?.systemName && (
      <IntegrationForm
        isFetching={isFetching}
        errorCode={errorCode}
        integrationName={integration.systemName}
        integration={integration}
        integrationSchema={integrationSchema}
        onSubmit={onSubmitButtonClickHandler}
        onCancel={onCancelHandler}
        resetForm={resetPartial}
        testConnection={onTestConnectionHandler}
        level={clientId ? IntegrationsLevel.CLIENT : IntegrationsLevel.CUSTOMER}
      />
    );
  }, [
    isFetching,
    clientId,
    errorCode,
    integration,
    integrationSchema,
    onSubmitButtonClickHandler,
    onCancelHandler,
    resetPartial,
    onTestConnectionHandler,
  ]);

  const renderPropellApForm = useMemo(() => {
    if (!client || loadingPropellAp || !isPropellApIntegration()) {
      return null;
    }

    return propellApError ? (
      <Alert 
        title={<Message id='propellap-failed-to-load' />}
      />
    ) : (
      <PropellApForm
        customerId={Number(customerId)}
        clientId={Number(clientId)}
        clientName={String(client.name)}
        propellAp={propellAp}
        hideGenericError={!integration.isActive || integrationRecentlyChangedStatus}
        onUpdated={() => loadPropellAp()}
        onOnboarded={() => {
          loadPropellAp();
          getClientIntegration({ accessToken, integrationId });
        }}
        onOffboarded={() => {
          loadPropellAp();
          getClientIntegration({ accessToken, integrationId });
        }}
      />
    );
  }, [
    integration,
    client,
    accessToken,
    integrationId,
    integrationRecentlyChangedStatus,
    customerId, 
    clientId,
    loadingPropellAp, 
    propellAp, 
    propellApError,
    isPropellApIntegration, 
    loadPropellAp,
    getClientIntegration,
  ]);

  const renderMissingSchemaAlert = useMemo(() => {
    if (!integration?.systemName) {
      return null;
    }

    if (errorCode === ErrorCodes.NOT_FOUND_SCHEMA) {
      return (
        <Alert
          title={
            <Message
              id='schema-for-client-not-found-title'
              values={{
                variable: mapSystemName(integration?.systemName),
              }}
            />
          }
        >
          <>
            <p>
              <Message
                id='please-create-schema-before-create-integration'
                values={{
                  variable: mapSystemName(integration?.systemName),
                }}
              />
            </p>
          </>
        </Alert>
      );
    }
    return null;
  }, [integration, errorCode]);

  const renderRequiredLevelsAlert = useMemo(() => {
    const isRequiredLevelsExists = Array.isArray(integrationSchema.requiredParentLevels)
      ? integrationSchema.requiredParentLevels.length > 0
      : false;

    if (!isRequiredLevelsExists) {
      return null;
    }

    const levels = integrationSchema.requiredParentLevels.join(', ');

    return (
      <Alert title={<Message id='alert-pay-attention' />}>
        <>
          <p>
            <Message
              id='please-be-aware-that-settings-for-levels-are-configured'
              values={{
                variable: levels,
              }}
            />
          </p>
        </>
      </Alert>
    );
  }, [integrationSchema.requiredParentLevels]);

  return (
    <>
      <Head>
        <Title>
          {client?.name}
          <TitleMark>id#{client?.id}</TitleMark>
        </Title>
        <HeadActions>
          {client.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={client.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <TabsList>
            <TabButton exact to={APP_URLS.toClientProfile(Number(customerId), Number(clientId))}>
              <Message id='client-screen-client-data-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientIntegrations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-integrations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientTransformations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-transformations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientApproverReferences(Number(customerId), Number(clientId))}>
              <Message id='client-screen-approver-references-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-operation-status-tab' />
            </TabButton>
          </TabsList>
          <TabContent>
            <TabHeader>
              <TabHeaderLeft>
                <ButtonBack>
                  <Button transparent onClick={onCancelHandler}>
                    <ArrowLeftIcon />
                  </Button>
                </ButtonBack>
                <TabTitle>
                  {integration ? mapSystemName(integration?.systemName) : ''}{' '}
                  <TitleMark>{integration?.id ? `id#${integration?.id}` : null}</TitleMark>
                </TabTitle>
              </TabHeaderLeft>
              <TabHeaderRight>
                {integration && (
                  <FlipToggle
                    dataOn='Active'
                    dataOff='Inactive'
                    isChecked={integration.isActive}
                    isDisabled={isFetching}
                    onChange={onFlipToggleChangeHandler}
                  />
                )}
              </TabHeaderRight>
            </TabHeader>
            <TabContentInner>
              {isFetching || isTestConnectionFetching || loadingPropellAp ? <Spinner /> : null}
              {renderMissingSchemaAlert}
              {renderRequiredLevelsAlert}
              {isPropellApIntegration() ? renderPropellApForm : renderForm}
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default IntegrationEdit;
