import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { CustomerData, CustomersRequestData } from 'types/Customer';
import { TablesSortOrderPayload } from 'types/Tables';
import { systemsFilterOptions } from 'common/constants';
import { FormSelectOption } from 'types/Form';
import { IntegrationSystemOptions } from 'types/Integrations';
import { TableSortOrder } from 'types/Tables';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import { defaultFilterOptions } from 'common/constants/Form';

// Actions
export enum ActionTypeKeys {
  GET_CUSTOMERS = 'CUSTOMERS/GET_CUSTOMERS',
  GET_CUSTOMERS_SUCCESS = 'CUSTOMERS/GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED = 'CUSTOMERS/GET_CUSTOMERS_FAILED',
  SET_CUSTOMERS_TABLE_PAGE = 'CUSTOMERS/SET_CUSTOMERS_TABLE_PAGE',
  SET_CUSTOMERS_TABLE_PER_PAGE = 'CUSTOMERS/SET_CUSTOMERS_TABLE_PER_PAGE',
  SET_CUSTOMERS_TABLE_SORT_ORDER = 'CUSTOMERS/SET_CUSTOMERS_TABLE_SORT_ORDER',
  SET_CUSTOMERS_TABLE_FILTER_TYPE = 'CUSTOMERS/SET_CUSTOMERS_TABLE_FILTER_TYPE',
  SET_CUSTOMERS_TABLE_FILTER_STATUS = 'CUSTOMERS/SET_CUSTOMERS_TABLE_FILTER_STATUS',
  SET_CUSTOMERS_TABLE_FILTER_SYSTEM = 'CUSTOMERS/SET_CUSTOMERS_TABLE_FILTER_SYSTEM',
  SET_CUSTOMERS_TABLE_FILTER_SEARCH = 'CUSTOMERS/SET_CUSTOMERS_TABLE_FILTER_SEARCH',
  SET_CUSTOMERS_TABLE_INCLUDE_DEACTIVATED = 'CUSTOMERS/SET_CUSTOMERS_TABLE_INCLUDE_DEACTIVATED',
  SET_CUSTOMERS_TABLE_SHOW_DEACTIVATED_SYSTEMS = 'CUSTOMERS/SET_CUSTOMERS_TABLE_SHOW_DEACTIVATED_SYSTEMS',
  CUSTOMERS_RESET = 'CUSTOMERS/CUSTOMERS_RESET',
}

// ActionCreators
export const actionCreators = {
  getCustomers: (payload?: CustomersRequestData) => createAction(ActionTypeKeys.GET_CUSTOMERS, payload),
  getCustomersSuccess: (payload: CustomerData[]) => createAction(ActionTypeKeys.GET_CUSTOMERS_SUCCESS, payload),
  getCustomersFailed: () => createAction(ActionTypeKeys.GET_CUSTOMERS_FAILED),
  setCustomersTablePage: (payload: number) => createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_PAGE, payload),
  setCustomersTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_PER_PAGE, payload),
  setCustomersTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_SORT_ORDER, payload),
  setCustomersTableFilterType: (payload: FormSelectOption) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_TYPE, payload),
  setCustomersTableFilterStatus: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_STATUS, payload),
  setCustomersTableFilterSystem: (payload: IntegrationSystemOptions[]) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_SYSTEM, payload),
  setCustomersTableFilterSearch: (payload: string) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_SEARCH, payload),
  setIsIncludeDeactivated: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_INCLUDE_DEACTIVATED, payload),
  setShowOnlyDeactivatedSystems: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_CUSTOMERS_TABLE_SHOW_DEACTIVATED_SYSTEMS, payload),
  customersReset: () => createAction(ActionTypeKeys.CUSTOMERS_RESET),
};

// State
export interface State {
  isFetching: boolean;
  totalCount: number;
  customers: CustomerData[];
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterType: FormSelectOption;
  filterStatus: FormSelectOption[];
  filterSystem: IntegrationSystemOptions[];
  filterSearch: string;
  isIncludeDeactivated: boolean;
  showOnlyDeactivatedSystems: boolean;
}

export const initialState: State = {
  isFetching: false,
  customers: [] as CustomerData[],
  totalCount: 0,
  page: 1,
  perPage: ITEMS_PER_PAGE_COUNT,
  orderBy: 'updatedOn',
  sortOrder: TableSortOrder.DESCENDING,
  filterType: defaultFilterOptions,
  filterStatus: [defaultFilterOptions],
  filterSystem: [systemsFilterOptions[0]],
  filterSearch: '',
  isIncludeDeactivated: false,
  showOnlyDeactivatedSystems: false,
};

// Reducer
export const customersReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CUSTOMERS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customers: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case ActionTypeKeys.GET_CUSTOMERS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_SORT_ORDER:
      return {
        ...state,
        orderBy: action.payload.orderBy,
        sortOrder: action.payload.sortOrder,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_TYPE:
      return {
        ...state,
        filterType: action.payload,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_STATUS:
      return {
        ...state,
        filterStatus: [...action.payload],
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_SYSTEM:
      return {
        ...state,
        filterSystem: [...action.payload],
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_FILTER_SEARCH:
      return {
        ...state,
        filterSearch: action.payload,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_INCLUDE_DEACTIVATED:
      return {
        ...state,
        isIncludeDeactivated: action.payload,
      };

    case ActionTypeKeys.SET_CUSTOMERS_TABLE_SHOW_DEACTIVATED_SYSTEMS:
      return {
        ...state,
        showOnlyDeactivatedSystems: action.payload,
      };

    case ActionTypeKeys.CUSTOMERS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
