import { call, put, takeEvery } from 'redux-saga/effects';
import { customersService } from 'common/api/customersService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { ActionWithPayload } from 'store/Action';
import { ActionTypeKeys, actionCreators } from './customersReducer';

export function* getCustomersSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(customersService.getCustomers, payload);
    yield put(actionCreators.getCustomersSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomersFailed());
  }
}

export function* customersSaga() {
  yield* [takeEvery(ActionTypeKeys.GET_CUSTOMERS, getCustomersSaga)];
}
