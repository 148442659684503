import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Message, Button, Checkbox, Spinner } from 'common/components';
import { PenIcon } from 'common/components/Icons';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { useRemoteApiCall } from 'hooks';
import { FormRow, FormWrapper, FormColumn, FormColumnWrapper, FormButtons, FormEditButton } from './styles';

type PermissionsEditFormData = { [key: string]: boolean };

interface PermissionsEditFormProps {
  portalId: string;
  allPermissions: string[];
  portalPermissions: string[];
  onSubmitHandler: (formData: PermissionsEditFormData) => void;
}

const escapePermissionName = (p: string) => p.replace('.', '$');
const unescapePermissionName = (p: string) => p.replace('$', '.');

const PermissionsEditForm = ({
  portalId,
  allPermissions,
  portalPermissions,
  onSubmitHandler,
}: PermissionsEditFormProps) => {
  const paiClient = useAuthenticatedAdminApiClient();
  const [isFetching, callRemoteApi] = useRemoteApiCall();

  const escapedAllPermissions = allPermissions.map(escapePermissionName);
  const escapedPortalPermissions = portalPermissions.map(escapePermissionName);

  const [isFormDisabled, setFormDisabled] = useState(true);

  const defaultValues = useMemo(() => {
    const t: PermissionsEditFormData = {};
    for (const i in escapedAllPermissions) {
      t[escapedAllPermissions[i]] = escapedPortalPermissions.indexOf(escapedAllPermissions[i]) > -1;
    }
    return t;
  }, [escapedAllPermissions, escapedPortalPermissions]);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
    reset,
  } = useForm({ mode: 'onChange' });

  const onEditButtonHandler = () => {
    setFormDisabled(false);
  };

  const onCancelHandler = useCallback(() => {
    setFormDisabled(true);
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = useCallback(
    async (data: PermissionsEditFormData) => {
      const unescapedData: PermissionsEditFormData = {};
      for (const key in data) {
        unescapedData[unescapePermissionName(key)] = data[key];
      }

      const selectedPermissions = Object.keys(unescapedData).filter((key) => unescapedData[key]);
      await callRemoteApi(() =>
        paiClient?.customerPortalControllerCustomerPortalUpdatePermissions(portalId, {
          permissions: selectedPermissions,
        }),
      );

      setFormDisabled(true);
      onSubmitHandler(unescapedData);
    },
    [onSubmitHandler, paiClient, callRemoteApi, portalId],
  );

  const renderFormButtons = useMemo(
    () =>
      !isFormDisabled && (
        <FormButtons>
          <Button transparent disabled={isFormDisabled} onClick={onCancelHandler}>
            <Message id={'create-customers-cancel-button'} />
          </Button>
          <Button disabled={isFormDisabled || !isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
            <Message id={'edit-customers-save-button'} />
          </Button>
        </FormButtons>
      ),
    [isDirty, isValid, isFormDisabled, handleSubmit, onCancelHandler, onSubmit],
  );

  return (
    <>
      {isFetching && <Spinner />}
      <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
        <FormEditButton>
          <Button primary disabled={!isFormDisabled} onClick={onEditButtonHandler}>
            <PenIcon />
          </Button>
        </FormEditButton>
        <FormColumnWrapper>
          <FormColumn>
            {escapedAllPermissions.map((globalPermission) => (
              <FormRow key={globalPermission}>
                <Controller
                  name={globalPermission}
                  control={control}
                  defaultValue={defaultValues[globalPermission]}
                  render={({ field }) => (
                    <Checkbox
                      id={globalPermission}
                      label={unescapePermissionName(globalPermission)}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      disabled={isFormDisabled}
                    />
                  )}
                />
              </FormRow>
            ))}
          </FormColumn>
        </FormColumnWrapper>
        {renderFormButtons}
      </FormWrapper>
    </>
  );
};

export default PermissionsEditForm;
