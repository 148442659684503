import { Icon } from 'types/Icon';

export const ExchangeIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '17'} height={height || '18'} viewBox='0 0 17 18'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M11.7651765,4.74117647 C12.2847059,4.74117647 12.7063529,5.16282353 12.7063529,5.68235294 L12.7063529,5.68235294 L12.7063529,13.4508235 L15.1825882,11.0950588 C15.5929412,10.7044706 16.2583529,10.7044706 16.6687059,11.0950588 C17.0790588,11.4856471 17.0790588,12.1181176 16.6687059,12.5087059 L16.6687059,12.5087059 L11.7642353,16.9764706 L6.86070588,12.5087059 C6.45035294,12.1181176 6.45035294,11.4856471 6.86070588,11.0950588 C7.27105882,10.7044706 7.93647059,10.7044706 8.34682353,11.0950588 L8.34682353,11.0950588 L10.824,13.4508235 L10.824,5.68235294 C10.824,5.16282353 11.2447059,4.74117647 11.7651765,4.74117647 Z M4.23482353,0.976470588 L9.13929412,5.44423529 C9.54964706,5.83482353 9.54964706,6.46729412 9.13929412,6.85788235 C8.72894118,7.24847059 8.06352941,7.24847059 7.65317647,6.85788235 L5.17694118,4.50211765 L5.17694118,12.2705882 C5.17694118,12.7901176 4.75529412,13.2117647 4.23576471,13.2117647 C3.71529412,13.2117647 3.29458824,12.7901176 3.29458824,12.2705882 L3.29458824,4.50211765 L0.817411765,6.85788235 C0.407058824,7.24847059 -0.258352941,7.24847059 -0.668705882,6.85788235 C-1.07905882,6.46729412 -1.07905882,5.83482353 -0.668705882,5.44423529 L4.23482353,0.976470588 Z'
        id='path-ExchangeIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-277.000000, -3301.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(157.976471, 244.023529)'>
            <use
              fill={color || 'currentColor'}
              transform='translate(8.000000, 8.976471) rotate(90.000000) translate(-8.000000, -8.976471)'
              xlinkHref='#path-ExchangeIcon-1'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
