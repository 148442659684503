import { useEffect } from 'react';
import { connect } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { IntlProvider } from 'react-intl-redux';
import { ThemeProvider } from 'styled-components';
import { browserHistory } from 'browserHistory';
import { RootState } from './store/rootReducer';
import theme from './assets/theme';
import App from './App';
import { ErrorBoundary } from './common/components';
import { getMsalInstance, setPathname } from './config/auth';
// eslint-disable-next-line no-redeclare

interface AppIntlProps {
  locale: string;
  defaultLocale: string;
  messages: any; // TODO: change type
}

const AppIntl = ({ locale, defaultLocale, messages }: AppIntlProps) => {
  useEffect(() => {
    if (window.location.href.indexOf('login') < 0) {
      setPathname(window.location.pathname);
    }
  }, []);

  return (
    <MsalProvider instance={getMsalInstance()}>
      <IntlProvider locale={locale} messages={messages} defaultLocale={defaultLocale}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <App history={browserHistory} />
          </ErrorBoundary>
        </ThemeProvider>
      </IntlProvider>
    </MsalProvider>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages,
    defaultLocale: state.intl.defaultLocale,
  };
};

export default connect(mapStateToProps)(AppIntl);
