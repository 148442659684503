import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../logic/clientReducer';
import { getOperationsView } from '../logic/clientSelectors';
import OperationsList from './OperationsList';

const mapStateToProps = ({ shared, client }: RootState) => getOperationsView(shared, client);

const mapDispatchToProps = {
  getClient: actionCreators.getClient,
  getOperations: actionCreators.getClientOperations,
  setOperationsTablePage: actionCreators.setOperationsTablePage,
  setOperationsTablePerPage: actionCreators.setOperationsTablePerPage,
  setOperationsTableOrder: actionCreators.setOperationsTableSortOrder,
  setFilterOperationScope: actionCreators.setOperationsFilterOperationScope,
  setFilterOperation: actionCreators.setOperationsFilterOperation,
  setFilterStatus: actionCreators.setOperationsFilterStatus,
  setFilterDateFrom: actionCreators.setFilterDateFrom,
  setFilterDateTo: actionCreators.setFilterDateTo,
  setFilterTraceId: actionCreators.setOperationsFilterTraceId,
  setFilterSourceEntityId: actionCreators.setFilterSourceEntityId,
  setFilterTargetEntityId: actionCreators.setFilterTargetEntityId,
  reset: actionCreators.clientOperationsReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(OperationsList);
