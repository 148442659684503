import { Icon } from 'types/Icon';

export const FileIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '15'} height={height || '19'} viewBox='0 0 15 19'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M8,0 L8,0.025 L2,0.025 C0.895,0.025 0,0.92 0,2.025 L0,16.0248 C0,17.1294 0.895,18.0248 2,18.0248 L12,18.0248 C13.105,18.0248 14,17.1294 14,16.0248 L14,6.025 L14.025,6.025 L8,0 Z M7,5.025 L7,2.025 L2.5,2.025 C2.224,2.025 2,2.249 2,2.525 L2,15.5248 C2,15.801 2.224,16.0248 2.5,16.0248 L11.5,16.0248 C11.776,16.0248 12,15.801 12,15.5248 L12,7.025 L9,7.025 C7.895,7.025 7,6.129 7,5.025 Z'
        id='path-FileIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-674.000000, -3191.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(554.000000, 134.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-FileIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
