import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/clientReducer';
import { getTransformationsView } from '../../logic/clientSelectors';
import TransformationList from './TransformationList';

const mapStateToProps = ({ client }: RootState) => getTransformationsView(client);

const mapDispatchToProps = {
  getAvailableTransformationTypes: actionCreators.getClientAvailableTransformationTypes,
  getClientTransformations: actionCreators.getClientTransformations,
  updateClientTransformationStatus: actionCreators.updateClientTransformationStatus,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(TransformationList);
