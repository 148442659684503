import { integrationsServiceRequest } from 'config';
import { API_URLS } from 'common/constants';
import {
  CustomerByIdRequestData,
  CustomerRequestData,
  CustomerRequestDataExtended,
  CustomersOptionsRequestData,
  CustomersRequestData,
  CustomerStatusRequestData,
} from 'types/Customer';
import { OperationsRequestData } from 'types/Operations';
import { AccessToken } from 'types/Auth';
import { setAuthHeaders } from 'utils/auth';
import { CustomerAvailableIntegrationsSystemRequestParams, IntegrationStatusRequest } from 'types/Integrations';

export const customersService = {
  getCustomers: ({ accessToken, ...requestData }: CustomersRequestData) =>
    integrationsServiceRequest.post(API_URLS.customersService.customers, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomersCount: (accessToken: AccessToken) =>
    integrationsServiceRequest.get(API_URLS.customersService.customersCount, { headers: setAuthHeaders(accessToken) }),
  getCustomersBasic: ({ accessToken, ...requestData }: CustomersOptionsRequestData) =>
    integrationsServiceRequest.post(API_URLS.customersService.customersBasic, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerById: ({ accessToken, customerId }: CustomerByIdRequestData) =>
    integrationsServiceRequest.get(API_URLS.customersService.customerById(customerId), {
      headers: setAuthHeaders(accessToken),
    }),
  createCustomer: ({ accessToken, ...requestData }: CustomerRequestData) =>
    integrationsServiceRequest.post(API_URLS.customersService.customer, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  updateCustomer: ({ accessToken, ...requestData }: CustomerRequestDataExtended) =>
    integrationsServiceRequest.put(API_URLS.customersService.customerById(requestData.id), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  setCustomerStatus: ({ accessToken, id, ...requestData }: CustomerStatusRequestData) =>
    integrationsServiceRequest.put(API_URLS.customersService.customerStatusById(id), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  updateCustomerIntegrationStatus: ({ accessToken, integrationId, action }: IntegrationStatusRequest) =>
    integrationsServiceRequest.put(API_URLS.integrationsService.updateIntegrationStatus(integrationId, action), null, {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerAvailableSystems: ({ accessToken, customerId }: CustomerAvailableIntegrationsSystemRequestParams) =>
    integrationsServiceRequest.get(API_URLS.customersService.getCustomerAvailableSystems(customerId), {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerOperations: ({ accessToken, ...requestData }: OperationsRequestData) =>
    integrationsServiceRequest.post(API_URLS.operationsService.operations, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
};
