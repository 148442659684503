import { useState, ReactNode } from 'react';
import { VisibilityOffIcon, VisibilityOnIcon } from 'common/components/Icons';
import {
  TextInput,
  Label,
  LeftIcon,
  RightIcon,
  InputContainer,
  InputErrorMessage,
  TextInputWrapper,
  TextInputContainer,
  WrapperVisibilityOff,
  WrapperVisibilityOn,
} from './styles';

interface InputProps {
  inputRef?: any;
  id?: string;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  inputMode?: string;
  max?: number;
  maxLength?: number;
  rightPadding?: number;
  leftPadding?: number;
  min?: number;
  minLength?: number;
  pattern?: any;
  label?: ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  isRequired?: boolean;
  spellCheck?: boolean;
  step?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  styles?: object;
  type?: 'text' | 'password' | 'url' | 'search' | 'email' | 'tel' | 'number';
  value?: string | number;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isTypePassword?: boolean;
  isError?: boolean;
  isNumber?: boolean;
  isInteger?: boolean;
  isNumberWithDecimals?: boolean;
  errorText?: ReactNode;
  textAlign?: string;
  onFocus?: () => void;
  onBlur?: (e?: any) => void;
  onChange?: (event: any) => void;
  nativePlaceholder?: string;
}

export const Input = (props: InputProps) => {
  const [isFocused, setFocused] = useState(false);
  const [type, setType] = useState(props.type || 'text');
  const [isHidden, setHidden] = useState(false);

  const handleOnChange = (event: any) => {
    if (!props.onChange) return event.preventDefault();

    let value = event.target.value;

    if (props.isNumber) {
      const regexp = props.isNumberWithDecimals ? /\d(\.?\d{0,2})$/ : /^\d+$/;

      if (regexp.test(value) || value === '') {
        if (value.includes('.')) {
          const values = value.split('.');

          if (values.length === 2) {
            const valueAfterComma = `${values[1]}`;

            if (valueAfterComma.length > 2) {
              value = parseFloat(value).toFixed(2);
            }

            if (value[value.length - 1] !== '.' && value[value.length - 1] !== '0') {
              value = parseFloat(value);
            }
          } else {
            return null;
          }
        } else if (value.length > 1 && value.charAt(0) === '0') {
          value = value.slice(1);
        }

        props.onChange(value);
      }
    } else {
      props.onChange(value);
    }
  };

  const handleOnFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }

    setFocused(true);
  };

  const handleOnBlur = () => {
    if (props.onBlur) {
      props.onBlur();
    }

    setFocused(false);
  };

  const handleKeyPress = (event: any) => {
    const invalidChars = ['-', '+', 'e', '.'];
    if (props.isInteger && invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const isInputEmpty = props.value === '';

  const isPlaceholderHidden = props.placeholder === '' || props.placeholder === undefined;

  const handleOnClickLeftIcon = (event: any) => event.preventDefault();

  const renderLeftIcon = () => <LeftIcon onClick={handleOnClickLeftIcon}>{props.leftIcon}</LeftIcon>;

  const changeVisibility = () => {
    if (props.disabled) {
      return;
    }
    setHidden(!isHidden);
    setType(type === 'text' ? 'password' : 'text');
  };

  const renderPasswordIcon = () =>
    isHidden ? (
      <WrapperVisibilityOff>
        <VisibilityOffIcon width={18} height={18} />
      </WrapperVisibilityOff>
    ) : (
      <WrapperVisibilityOn isDisabled={props.disabled}>
        <VisibilityOnIcon width={18} height={18} />
      </WrapperVisibilityOn>
    );

  const renderRightIcon = () => {
    if (props.isTypePassword) {
      return (
        <RightIcon isFocused={isFocused} onClick={changeVisibility}>
          {renderPasswordIcon()}
        </RightIcon>
      );
    }

    return <RightIcon isFocused={isFocused}>{props.rightIcon}</RightIcon>;
  };

  // const renderNativePlaceholder = (nativePlaceholder: string | undefined) =>
  //   nativePlaceholder ? nativePlaceholder : '';

  const renderErrorText = () => (
    <InputErrorMessage isError={props.isError} isPlaceholderHidden={isPlaceholderHidden}>
      {props.errorText}
    </InputErrorMessage>
  );

  /* eslint-disable */
  return (
    <InputContainer>
      {props.label && (
        <Label
          isError={props.isError}
          isInputEmpty={isInputEmpty}
          isPlaceholderHidden={isPlaceholderHidden}
          leftIcon={props.leftIcon}
          disabled={props.disabled}
          htmlFor={props.id}
        >
          {props.label}
          {props.isRequired && <span>*</span>}
        </Label>
      )}
      <TextInputContainer>
        {props.leftIcon && renderLeftIcon()}
        {(props.rightIcon || props.isTypePassword) && renderRightIcon()}

        <TextInputWrapper>
          <TextInput
            ref={props.inputRef}
            id={props.id}
            isFocused={isFocused}
            isInputEmpty={isInputEmpty}
            style={props.styles}
            value={props.value}
            autoComplete={props.autoComplete || 'new-password'}
            // inputMode={props.inputMode}
            max={props.max}
            maxLength={props.maxLength}
            rightPadding={props.rightPadding}
            leftPadding={props.leftPadding}
            min={props.min}
            minLength={props.minLength}
            pattern={props.pattern}
            spellCheck={props.spellCheck}
            name={props.id}
            autoFocus={props.autoFocus}
            readOnly={props.readOnly}
            required={props.required}
            step={props.step}
            type={type}
            disabled={props.disabled}
            leftIcon={props.leftIcon}
            isTypePassword={props.isTypePassword}
            isError={props.isError}
            textAlign={props.textAlign}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            onKeyPress={handleKeyPress}
            placeholder={props.placeholder}
          />
        </TextInputWrapper>
      </TextInputContainer>
      {props.isError && props.errorText && renderErrorText()}
    </InputContainer>
  );
  /* eslint-enable */
};

export default Input;
