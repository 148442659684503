import { FormattedMessage } from 'react-intl';

type MessageProps = {
  id: string;
  defaultMessage?: string;
  variable?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  values?: object;
};

const Message = (props: MessageProps) => {
  if (!props.id) return null;

  return <FormattedMessage {...props} values={{ variable: props.variable, ...props.values }} />;
};

export default Message;
