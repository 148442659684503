import styled from 'styled-components';

export const TabsWrapper = styled.div``;

interface TabsItemType {
  label: string;
}

export const TabsItem = styled.div<TabsItemType>``;

export const TabsList = styled.div`
  display: flex;
`;

interface TabButtonType {
  isActive: boolean;
}

export const TabButton = styled.div<TabButtonType>`
  position relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px;
  min-width: 100px;
  text-align: center;
  margin-left: 5px;
  border-radius: 6px 6px 0 0;
  color: ${({ theme, isActive }) => (isActive ? theme.fontPrimary : theme.fontSecondary)};
  background: ${({ theme, isActive }) => (isActive ? theme.backgroundPrimary : theme.backgroundThird)};
  box-shadow: 0 -3px 10px rgba(0,0,0, 0.02);

  &:hover {
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

    &:after {
       width: 80% !important;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: ${({ isActive }) => (isActive ? '80%' : '0%')};
    height: 2px;
    background:${({ theme }) => theme.primary};
    transform: translateX(-50%);
    transition: width .3s ease;
  }
`;

export const TabContent = styled.div`
  background: ${({ theme }) => theme.backgroundPrimary};
  padding: 25px 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
`;

export const TabContentInner = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 25px 15px;
`;
