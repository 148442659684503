import { useMemo } from 'react';
import { Button, Message, Modal } from 'common/components';
import { FormButtonsCentered, ModalText, ModalTextMark } from '../../TransferHistoricalData/styles';

interface ActivateModalProps {
  isVisible: boolean;
  selectedClients: any[];
  onModalCancelHandler: () => void;
  onModalConfirmHandler: () => void;
}

const ActivateModal = ({
  isVisible,
  selectedClients,
  onModalCancelHandler,
  onModalConfirmHandler,
}: ActivateModalProps) => {
  const footerButtons = useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onModalCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button primary onClick={onModalConfirmHandler}>
          <Message id='clients-screen-activate-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onModalCancelHandler, onModalConfirmHandler],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      withScroll
      hideOnOverlayClicked={false}
      closeOnEsc={false}
      isVisible={isVisible}
      title={<Message id='clients-screen-activate-clients-title' />}
      onCloseClicked={onModalCancelHandler}
      footer={footerButtons}
    >
      <h3>
        <Message id='clients-screen-are-you-sure-you-want-activate-next-clients' />
        <div>
          {selectedClients.map((client: any) => (
            <ModalText key={client.id}>
              {client.name} <ModalTextMark>{`(org.Number: ${client.organizationNumber})`}</ModalTextMark>
            </ModalText>
          ))}
        </div>
      </h3>
    </Modal>
  );
};

export default ActivateModal;
