export const BREADCRUMBS_TITLES = {
  GENERAL: 'General',
  CUSTOMERS: 'Customers',
  CUSTOMER_DATA: 'Customer Data',
  CREATE_NEW_CUSTOMER: 'Create New Customer',
  CLIENTS: 'Clients',
  CLIENT_DATA: 'Client Data',
  CREATE_NEW_CLIENT: 'Create New Client',
  INTEGRATIONS: 'Integrations',
  TRANSFORMATIONS: 'Transformations',
  OPERATIONS_STATUS: 'Operation Status',
  ADD_NEW_INTEGRATIONS: 'Add New Integrations',
  ADD_NEW_TRANSFORMATION: 'Add New Transformation',
  SYNC_MASTER_DATA: 'Sync Master Data',
  TRANSFER_HISTORICAL_DATA: 'Transfer Historical Data',
  TRANSFER_EIVOICES: 'Transfer E-Invoices',
  MILLUM_ZIP_BATCH_LOGS: 'Millum Zip Batch Logs',
  LOGIQ_EID_LOGS: 'Logiq E-ID Logs',
  MILLUM_EID_LOGS: 'Millum E-ID Logs',
  SETTINGS: 'Settings',
  INTEGRATIONS_DEFAULT: 'Integrations Default',
  LOGS: 'Logs',
  OPERATION_STATUS: 'Operation Status',
  DASHBOARD: 'Dashboard',
  NOTIFICATIONS: 'Notifications',
  RELEASE_NOTES: 'Release Notes',
  USER_ADMINISTRATION: 'User Administration',
} as const;
