import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { ButtonLink, Message, Spinner, DataTable, Button } from 'common/components';
import { APP_URLS } from 'common/constants';
import { CustomerData, CustomerIntegrationStatusRequest } from 'types/Customer';
import {
  IntegrationData,
  IntegrationSystem,
  CustomerIntegrationsRequestParams,
  CustomerAvailableIntegrationsSystemRequestParams,
  IntegrationsStatusAction,
  TestConnectionRequest,
} from 'types/Integrations';
import { RefreshIcon } from 'common/components/Icons';
import { useAccessToken } from 'hooks';
import customerIntegrationsTableColumns from './IntegrationsListTableColumns';
import getCustomerIntegrationsBreadcrumbs from './getCustomerIntegrationsBreadcrumbs';
import { TableHeader, TableHeaderActions, TableTitle, TableWrapper } from './styles';

type CustomerUrlParams = {
  customerId: string;
};

interface IntegrationsListProps {
  isFetching: boolean;
  customer: CustomerData;
  integrations: IntegrationData[];
  availableSystems: IntegrationSystem[];
  getCustomerIntegrations: (payload: CustomerIntegrationsRequestParams) => void;
  getAvailableSystems: (payload: CustomerAvailableIntegrationsSystemRequestParams) => void;
  updateCustomerIntegrationStatus: (payload: CustomerIntegrationStatusRequest) => void;
  getCustomerTestConnection: (payload: TestConnectionRequest) => void;
}

const IntegrationsList = ({
  isFetching,
  customer,
  integrations,
  availableSystems,
  getCustomerIntegrations,
  getAvailableSystems,
  updateCustomerIntegrationStatus,
  getCustomerTestConnection,
}: IntegrationsListProps) => {
  const accessToken = useAccessToken();
  const { pathname } = useLocation();
  const { customerId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const isProfilePage = /profile+$/.test(pathname);

  useEffect(() => {
    setBreadcrumbs(getCustomerIntegrationsBreadcrumbs(Number(customerId), customer.name));
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs]);

  useEffect(() => {
    if (accessToken) {
      getCustomerIntegrations({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, getCustomerIntegrations]);

  useEffect(() => {
    if (accessToken) {
      getAvailableSystems({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, getAvailableSystems]);

  useEffect(() => {
    // eslint-disable-next-line
    setIsCreateButtonDisabled(!Boolean(availableSystems.length));
  }, [availableSystems]);

  const onRefreshButtonClick = () => {
    getCustomerIntegrations({
      accessToken,
      customerId: Number(customerId),
    });
  };

  const onFlipToggleChangeHandler = ({ event, id, systemName }: any) => {
    updateCustomerIntegrationStatus({
      accessToken,
      isProfilePage,
      customerId: Number(customerId),
      integrationId: id,
      system: systemName,
      action: event.target.checked ? IntegrationsStatusAction.ACTIVATE : IntegrationsStatusAction.DEACTIVATE,
    });
  };

  const onTestConnectionClickHandler = (id: string) => {
    getCustomerTestConnection({
      accessToken,
      integrationId: id,
    });
  };

  return (
    <>
      <TableWrapper>
        <TableHeader>
          <TableTitle>
            <Message id='customer-integrations-connected-systems-title' />
          </TableTitle>
          <TableHeaderActions>
            <ButtonLink
              primary='true'
              to={APP_URLS.toCreateCustomerIntegration(Number(customerId))}
              disabled={isCreateButtonDisabled || isFetching}
            >
              <Message id='customer-integrations-add-new-integration' />
            </ButtonLink>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </TableHeaderActions>
        </TableHeader>
        <DataTable
          isFetching={isFetching}
          loadingComponent={Spinner}
          data={integrations}
          columns={customerIntegrationsTableColumns(
            Number(customerId),
            onFlipToggleChangeHandler,
            onTestConnectionClickHandler,
          )}
          emptyMessage={<Message id='no-data-message' />}
        />
      </TableWrapper>
    </>
  );
};

export default IntegrationsList;
