import { APP_URLS } from 'common/constants';
import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';

const getCustomerCreateBreadcrumbs = () => [
  {
    title: BREADCRUMBS_TITLES.GENERAL,
    url: APP_URLS.customers,
  },
  {
    title: BREADCRUMBS_TITLES.CUSTOMERS,
    url: APP_URLS.customers,
  },
  {
    title: BREADCRUMBS_TITLES.CREATE_NEW_CUSTOMER,
    isActive: true,
  },
];

export default getCustomerCreateBreadcrumbs;
