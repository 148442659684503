import { State as CustomerState } from 'screens/Customer/logic/customerReducer';
import { availableSystemsToOptions, prepareSelectOptionsFromDictionaries } from 'utils';
import { prepareMultipleSelectOptionsFromArray } from 'utils/formHelpers';
import { Dictionaries, systemsFilterOptions } from 'common/constants';
import { integrationsSystemsNamesMapping } from 'types/Integrations';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { State as ClientsState } from './clientsReducer';

export const getClientsView = (sharedState: SharedState, customerState: CustomerState, clientsState: ClientsState) => ({
  isFetching: clientsState.isFetching,
  totalCount: clientsState.totalCount,
  page: clientsState.page,
  perPage: clientsState.perPage,
  orderBy: clientsState.orderBy,
  sortOrder: clientsState.sortOrder,
  filterStatus: clientsState.filterStatus,
  filterSystem: clientsState.filterSystem,
  filterSearch: clientsState.filterSearch,
  isIncludeDeactivated: clientsState.isIncludeDeactivated,
  showOnlyDeactivatedSystems: clientsState.showOnlyDeactivatedSystems,
  customer: customerState.customer,
  customerSystems: customerState.customer.systems,
  customerIntegrations: customerState.integrations,
  clients: clientsState.clients,
  availableSystems: availableSystemsToOptions(sharedState.integrationSystems),
  connectedSystemsOptions: prepareMultipleSelectOptionsFromArray(
    sharedState.integrationSystems,
    systemsFilterOptions[0],
    integrationsSystemsNamesMapping,
  ),
  csvHeaders: clientsState.csvHeaders.sort((a: any, b: any) => b.isRequired - a.isRequired),
  isUploading: clientsState.upload.isUploading,
  isUploaded: clientsState.upload.isUploaded,
  uploadFile: clientsState.upload.file,
  uploadFileName: clientsState.upload.name,
  importType: clientsState.upload.importType,
  importMode: clientsState.upload.importMode,
  errorCode: clientsState.errorCode,
  integrationSchema: clientsState.integrationSchema,
  clientStatusOptions: prepareSelectOptionsFromDictionaries(sharedState.dictionaries[Dictionaries.CLIENT_STATUSES]),
});
