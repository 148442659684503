import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/clientsAllReducer';
import ClientsAll from './ClientsAll';
import { getClientsAllView } from './logic/clientsAllSelectors';

const mapStateToProps = ({ shared, clientsAll }: RootState) => getClientsAllView(shared, clientsAll);

const mapDispatchToProps = {
  getClientsAll: actionCreators.getClientsAll,
  setTablePage: actionCreators.setClientsAllTablePage,
  setTablePerPage: actionCreators.setClientsAllTablePerPage,
  setFilterSystem: actionCreators.setClientsAllTableFilterSystem,
  setFilterStatus: actionCreators.setClientsAllTableFilterStatus,
  setFilterSearch: actionCreators.setClientsAllTableFilterSearch,
  setTableOrder: actionCreators.setClientsAllTableSortOrder,
  setIsIncludeDeactivated: actionCreators.setIsIncludeDeactivated,
  setShowOnlyDeactivatedSystems: actionCreators.setShowOnlyDeactivatedSystems,
  reset: actionCreators.clientsAllReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(ClientsAll);
