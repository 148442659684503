import { ReactNode, useCallback, useEffect, useState } from 'react';
import { PortalWrp } from 'common/components/PortalWrp';
import { Button } from 'common/components/Buttons';
import { PortalIds } from 'types/Portal';
import { CloseIcon } from 'common/components/Icons';
import {
  ModalWrapper,
  ModalOverlay,
  ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalHeaderTitle,
  ModalHeaderButton,
  ModalScrollBox,
  ModalScrollBoxInner,
  ModalBodyWrapper,
} from './styles';

interface ModalProps {
  isFetching?: boolean;
  isVisible: boolean;
  usePortal?: boolean;
  showOverlay?: boolean;
  closeOnEsc?: boolean;
  showCloseButton?: boolean;
  hideOnOverlayClicked?: boolean;
  withScroll?: boolean;
  minHeight?: string;
  size?: 'standart' | 'medium' | 'large';
  verticalAlignment?: 'center' | 'top';
  title?: string | ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  loadingComponent?: any; // ReactNode???
  onCloseClicked?: () => void;
  onOverlayClicked?: () => void;
}

const Modal = ({
  isFetching = false,
  isVisible = false,
  showOverlay = true,
  closeOnEsc = true,
  showCloseButton = true,
  hideOnOverlayClicked = false,
  withScroll = false,
  minHeight = 'auto',
  size = 'standart',
  verticalAlignment = 'center',
  usePortal = true,
  title = '',
  children,
  footer,
  loadingComponent,
  onCloseClicked,
  onOverlayClicked,
}: ModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const LoadingComponent = loadingComponent;

  const hide = () => {
    setIsModalVisible(false);
  };

  const handleCloseClick = useCallback(() => {
    hide();
    if (onCloseClicked) {
      onCloseClicked();
    }
  }, [onCloseClicked]);

  const _handlerEsc = useCallback(
    (event: any) => {
      let isEscape = false;

      if ('key' in event) {
        isEscape = event.key === 'Escape' || event.key === 'Esc';
      } else {
        isEscape = event.keyCode === 27;
      }

      if (isEscape && closeOnEsc && isModalVisible) {
        handleCloseClick();
        if (document.activeElement !== document.body) {
          // @ts-ignore
          document.activeElement.blur();
        }
      }
    },
    [closeOnEsc, handleCloseClick, isModalVisible],
  );

  const enableEscEventListener = useCallback(() => {
    document.addEventListener('keydown', _handlerEsc);
  }, [_handlerEsc]);

  const disableEscEventListener = useCallback(() => {
    document.removeEventListener('keydown', _handlerEsc);
  }, [_handlerEsc]);

  useEffect(() => {
    setIsModalVisible(isVisible);

    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
      enableEscEventListener();
    }

    if (!isModalVisible) {
      document.body.style.overflow = 'auto';
      disableEscEventListener();
    }

    return () => {
      document.body.style.overflow = 'auto';
      disableEscEventListener();
    };
  }, [enableEscEventListener, disableEscEventListener, isVisible, isModalVisible]);

  const handleOverlayClick = () => {
    if (hideOnOverlayClicked) {
      handleCloseClick();
    }
    if (onOverlayClicked) {
      onOverlayClicked();
    }
  };

  const handleModalContainerClick = (event: any) => {
    event.stopPropagation();
  };

  const ModalContent = (
    <ModalContainer size={size} onClick={(event) => handleModalContainerClick(event)}>
      {isFetching && LoadingComponent && <LoadingComponent overlay />}
      <ModalHeader>
        {showCloseButton && (
          <ModalHeaderButton>
            <Button iconButton onClick={() => handleCloseClick()}>
              <CloseIcon />
            </Button>
          </ModalHeaderButton>
        )}
        {title && <ModalHeaderTitle>{title}</ModalHeaderTitle>}
      </ModalHeader>
      <ModalScrollBox withScroll={withScroll}>
        <ModalScrollBoxInner>
          <ModalBody minHeight={minHeight}>
            <ModalBodyWrapper verticalAlignment={verticalAlignment}>{children}</ModalBodyWrapper>
          </ModalBody>
        </ModalScrollBoxInner>
      </ModalScrollBox>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </ModalContainer>
  );

  // TODO: check if null needed
  const modalComponent = (
    <ModalWrapper isVisible={isModalVisible} showOverlay={showOverlay}>
      {isModalVisible ? ModalContent : null}
    </ModalWrapper>
  );

  return (
    // @ts-ignore
    <PortalWrp usePortal={usePortal} portalId={PortalIds.PORTAL_OVERLAY_ID}>
      {showOverlay ? (
        <ModalOverlay isVisible={isModalVisible} onClick={() => handleOverlayClick()}>
          {modalComponent}
        </ModalOverlay>
      ) : (
        modalComponent
      )}
    </PortalWrp>
  );
};

export default Modal;
