import React, { useCallback, useMemo } from 'react';
import { Checkbox } from 'common/components/Checkbox';
import { arrAdd, arrDel } from '../utils';
import { ColumnItem, Key, TableRowSelection, CheckboxProps, UseSelectionConfig, TransformColumns } from '../types';
import useMergedState from './useMergedState';

function useSelection(rowSelection: TableRowSelection | undefined, config: UseSelectionConfig): [TransformColumns] {
  const {
    defaultSelectedRowKeys,
    selectedRowKeys,
    onChange: onSelectionChange,
    onSelect,
    getCheckboxProps,
    renderCell: customizeRenderCell,
  } = rowSelection || {};

  const { data, getRowKey, getRecordByKey } = config;

  // ========================= Keys =========================
  const [mergedSelectedKeys, setMergedSelectedKeys] = useMergedState(selectedRowKeys || defaultSelectedRowKeys || [], {
    value: selectedRowKeys,
  });

  // Reset if rowSelection reset
  React.useEffect(() => {
    if (!rowSelection) {
      setMergedSelectedKeys([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!rowSelection]);

  // Get all checkbox props
  const checkboxPropsMap = useMemo(() => {
    const map = new Map<Key, Partial<CheckboxProps>>();
    data.forEach((record: any, index: number) => {
      const key = getRowKey(record, index);
      const checkboxProps = (getCheckboxProps ? getCheckboxProps(record) : null) || {};
      map.set(key, checkboxProps);
    });
    return map;
  }, [data, getRowKey, getCheckboxProps]);

  const setSelectedKeys = useCallback(
    (keys: Key[]) => {
      let availableKeys: Key[];
      let records: any[];

      // eslint-disable-next-line prefer-const
      availableKeys = [];
      // eslint-disable-next-line prefer-const
      records = [];

      keys.forEach((key) => {
        const record = getRecordByKey(key);
        if (record !== undefined) {
          availableKeys.push(key);
          records.push(record);
        }
      });

      setMergedSelectedKeys(availableKeys);

      onSelectionChange?.(availableKeys, records);
    },
    [getRecordByKey, onSelectionChange, setMergedSelectedKeys],
  );

  // Trigger single `onSelect` event
  const triggerSingleSelection = useCallback(
    (key: Key, selected: boolean, keys: Key[], value: any) => {
      if (onSelect) {
        const rows = keys.map((k) => getRecordByKey(k));
        onSelect(getRecordByKey(key), selected, rows, value);
      }

      setSelectedKeys(keys);
    },
    [onSelect, getRecordByKey, setSelectedKeys],
  );

  const transformColumns = useCallback(
    (columns: ColumnItem[]) => {
      if (!rowSelection) {
        return columns;
      }

      const keySet = new Set(mergedSelectedKeys);

      // Record key only need check with enabled
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const recordKeys = data.map(getRowKey).filter((key: React.Key) => !checkboxPropsMap.get(key)!.disabled);
      const checkedCurrentAll = recordKeys.every((key: React.Key) => keySet.has(key));
      const checkedCurrentSome = recordKeys.some((key: React.Key) => keySet.has(key));

      const onSelectAllChange = () => {
        const changeKeys: Key[] = [];

        if (checkedCurrentAll) {
          recordKeys.forEach((key: Key) => {
            keySet.delete(key);
            changeKeys.push(key);
          });
        } else {
          recordKeys.forEach((key: Key) => {
            if (!keySet.has(key)) {
              keySet.add(key);
              changeKeys.push(key);
            }
          });
        }

        const keys: Key[] = Array.from(keySet);

        setSelectedKeys(keys);
      };

      // ===================== Render =====================
      // Title Cell
      let title: React.ReactNode;

      const allDisabledData = data
        .map((record: any, index: number) => {
          const key = getRowKey(record, index);
          const checkboxProps = checkboxPropsMap.get(key) || {};
          return { checked: keySet.has(key), ...checkboxProps };
        })
        .filter(({ disabled }: any) => disabled);

      const allDisabled = !!allDisabledData.length && allDisabledData.length === data.length;

      const allDisabledAndChecked = allDisabled && allDisabledData.every(({ checked }: any) => checked);
      const allDisabledSomeChecked = allDisabled && allDisabledData.some(({ checked }: any) => checked);

      // eslint-disable-next-line prefer-const
      title = (
        <>
          <Checkbox
            value={!allDisabled ? !!data.length && checkedCurrentAll : allDisabledAndChecked}
            indeterminate={
              !allDisabled ? !checkedCurrentAll && checkedCurrentSome : !allDisabledAndChecked && allDisabledSomeChecked
            }
            onChange={onSelectAllChange}
            disabled={data.length === 0 || allDisabled}
          />
        </>
      );

      let renderCell: (record: any, index: number) => { node: React.ReactNode; checked: boolean };

      // eslint-disable-next-line prefer-const
      renderCell = (record, index) => {
        const key = getRowKey(record, index);
        const checked = keySet.has(key);
        const checkboxProps = checkboxPropsMap.get(key);

        return {
          node: (
            <Checkbox
              {...checkboxProps}
              value={checked}
              onClick={(e: any) => e.stopPropagation()}
              onChange={(value) => {
                const originCheckedKeys = mergedSelectedKeys;
                const checkedKeys = !value ? arrDel(originCheckedKeys, key) : arrAdd(originCheckedKeys, key);
                triggerSingleSelection(key, !value, checkedKeys, value); // TODO: clear second value
              }}
            />
          ),
          checked,
        };
      };

      const renderSelectionCell = (record: any, index: number) => {
        const { node, checked } = renderCell(record, index);

        if (customizeRenderCell) {
          return customizeRenderCell(checked, record, index, node);
        }

        return node;
      };

      // Columns
      const selectionColumn = {
        name: 'selection',
        width: 40,
        title: rowSelection.columnTitle || title,
        cellCallback: ({ row, rowIndex }: any) => renderSelectionCell(row, rowIndex),
      };

      return [{ ...selectionColumn }, ...columns];
    },
    [
      data,
      getRowKey,
      rowSelection,
      setSelectedKeys,
      checkboxPropsMap,
      mergedSelectedKeys,
      triggerSingleSelection,
      customizeRenderCell,
    ],
  );

  return [transformColumns];
}

export default useSelection;
