import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { AuthError } from '@azure/msal-browser';
import { Message } from 'common/components';
import { getAvatarLetter } from 'utils';
import { handleLogoutResponse } from 'config/auth';
import { UserDataWrapper, UserDataAvatar, UserDataContent, UserName, UserLink } from './styles';

const UserData = () => {
  const { instance, accounts } = useMsal();
  const activeAccount = accounts[0];

  const userName = activeAccount && activeAccount.name ? activeAccount.name : 'FirstName LastName';
  const avatarLetter = getAvatarLetter(userName);

  const handleLogout = useCallback(() => {
    instance
      .logoutPopup()
      .then(handleLogoutResponse)
      // eslint-disable-next-line no-console
      .catch((error: AuthError) => console.log(error));
  }, [instance]);

  return (
    <UserDataWrapper>
      <UserDataAvatar>{avatarLetter}</UserDataAvatar>
      <UserDataContent>
        <UserName>{userName}</UserName>
        <UserLink onClick={() => handleLogout()}>
          <Message id={'sign-out-link'} />
        </UserLink>
      </UserDataContent>
    </UserDataWrapper>
  );
};

export default UserData;
