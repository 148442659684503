import { Icon } from 'types/Icon';

export const PropellLogoIntegrations = ({ color, width, height }: Icon) => (
  <svg width={width || '84'} height={height || '32'} viewBox='0 0 84 32'>
    <defs>
      <polygon id='path-PropellLogoIntegrations-1' points='0 0 21.5914193 0 21.5914193 6.60358719 0 6.60358719' />
      <polygon
        id='path-PropellLogoIntegrations-3'
        points='1.2332259e-15 0 16.4535937 0 16.4535937 15.5952844 1.2332259e-15 15.5952844'
      />
      <polygon id='path-PropellLogoIntegrations-5' points='0 32 83.3093352 32 83.3093352 0 0 0' />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-24.000000, -12.000000)'>
        <g transform='translate(24.000000, 12.000000)'>
          <g transform='translate(0.000000, 0.000000)'>
            <g transform='translate(0.000000, 17.000584)'>
              <mask id='mask-PropellLogoIntegrations-2' fill='white'>
                <use xlinkHref='#path-PropellLogoIntegrations-1' />
              </mask>
              <path
                fill={color || 'currentColor'}
                // eslint-disable-next-line max-len
                d='M11.8018781,6.60358719 C7.0122856,6.60358719 2.56842681,4.07385129 -0.000277697784,0 L11.2464826,0 C13.2604857,0 15.203676,0.561304911 16.9392871,1.54552041 C18.6748983,2.52903339 20.1328117,4.00430287 21.1047539,5.76057606 L21.5914193,6.60358719 L11.8018781,6.60358719 Z'
                mask='url(#mask-PropellLogoIntegrations-2)'
              />
            </g>
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M22.4243738,22.0585102 C21.4524315,20.3022371 20.897036,18.3352111 20.897036,16.2979342 C20.897036,14.2606573 21.4524315,12.2936313 22.4243738,10.5373581 L22.9096506,9.694347 L27.8394806,18.2649602 C30.2693362,22.4800158 30.3380664,27.6083335 28.1164841,31.8936401 L22.4243738,22.0585102 Z'
            />
            <g id='Group-8' transform='translate(10.344242, 0.000000)'>
              <mask id='mask-PropellLogoIntegrations-4' fill='white'>
                <use xlinkHref='#path-PropellLogoIntegrations-3' />
              </mask>
              <path
                fill={color || 'currentColor'}
                // eslint-disable-next-line max-len
                d='M1.2332259e-15,15.5952844 L4.92913567,7.02467125 C7.35899128,2.8096156 11.7327314,0.140080349 16.4535937,-0.000421505565 L10.8302136,9.83470835 C9.85827134,11.5909815 8.40035797,13.066251 6.66474682,14.049764 C4.92913567,15.033277 2.98525118,15.5952844 0.971942244,15.5952844 L1.2332259e-15,15.5952844 Z'
                mask='url(#mask-PropellLogoIntegrations-4)'
              />
            </g>
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M39.0163998,14.1902659 C39.0163998,12.7852473 38.113882,11.8017343 36.7948175,11.8017343 C35.4757531,11.8017343 34.5739295,12.7852473 34.5739295,14.1902659 C34.5739295,15.5952844 35.4757531,16.6490483 36.7948175,16.6490483 C38.113882,16.6490483 39.0163998,15.5952844 39.0163998,14.1902659 M40.9602843,14.2605168 C40.9602843,16.6490483 39.5023709,18.3350706 37.2113642,18.3350706 C36.0311486,18.3350706 35.0592064,17.8433141 34.5038108,16.930052 L34.5038108,21.1451077 L32.5599263,21.1451077 L32.5599263,10.185963 L34.5038108,10.185963 L34.5038108,11.5207306 C35.0592064,10.6074686 35.9624184,10.1157121 37.1419398,10.1157121 C39.5023709,10.185963 40.9602843,11.8017343 40.9602843,14.2605168'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M47.2781866,10.1158526 L47.2781866,12.0126276 C45.6119999,11.8721257 44.5706332,12.9258897 44.5706332,14.3309082 L44.5706332,18.1947092 L42.6267487,18.1947092 L42.6267487,10.1861035 L44.5706332,10.1861035 L44.5706332,11.7316239 C45.1253345,10.7481109 46.0285466,10.1861035 47.2781866,10.1158526'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M49.8470299,14.260587 C49.8470299,15.6656056 50.7495477,16.7193695 52.067918,16.7193695 C53.3876767,16.7193695 54.2895002,15.7358565 54.2895002,14.260587 C54.2895002,12.7853176 53.3876767,11.8720555 52.067918,11.8720555 C50.8182779,11.8720555 49.8470299,12.7853176 49.8470299,14.260587 M56.3035034,14.1903361 C56.3035034,16.6491186 54.5678923,18.2648899 52.1380367,18.2648899 C49.6380624,18.2648899 47.9725699,16.6491186 47.9725699,14.1903361 C47.9725699,11.7315536 49.7081811,10.1157823 52.1380367,10.1157823 C54.5678923,10.1860332 56.3035034,11.8018046 56.3035034,14.1903361'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M64.4261636,14.1902659 C64.4261636,12.7852473 63.5229516,11.8017343 62.2045813,11.8017343 C60.8848226,11.8017343 59.9829991,12.7852473 59.9829991,14.1902659 C59.9829991,15.5952844 60.8848226,16.6490483 62.2045813,16.6490483 C63.5229516,16.6490483 64.4261636,15.5952844 64.4261636,14.1902659 M66.3700481,14.2605168 C66.3700481,16.6490483 64.9114405,18.3350706 62.6204338,18.3350706 C61.4402182,18.3350706 60.4689702,17.8433141 59.9135746,16.930052 L59.9135746,21.1451077 L57.9689959,21.1451077 L57.9689959,10.185963 L59.9135746,10.185963 L59.9135746,11.5207306 C60.4689702,10.6074686 61.371488,10.1157121 62.5517036,10.1157121 C64.9114405,10.185963 66.3700481,11.8017343 66.3700481,14.2605168'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M73.4513416,13.6283989 C73.3812229,12.4341332 72.6182482,11.661373 71.4380327,11.661373 C70.3272415,11.661373 69.4941482,12.4341332 69.2858748,13.6283989 L73.4513416,13.6283989 Z M75.1869527,14.8929156 L69.3552993,14.8929156 C69.6329971,16.0169305 70.4653962,16.7194397 71.6456118,16.7194397 C72.4793994,16.7194397 73.242374,16.438436 73.7984638,15.8061777 L74.8398305,16.9301925 C74.0754674,17.7732037 72.8952518,18.3352111 71.5067629,18.3352111 C69.0769073,18.3352111 67.4114148,16.7194397 67.4114148,14.2606573 C67.4114148,11.8018748 69.0769073,10.1861035 71.4380327,10.1861035 C74.1455861,10.1861035 75.3952261,12.0126276 75.1869527,14.8929156 L75.1869527,14.8929156 Z'
            />
            <mask id='mask-PropellLogoIntegrations-6' fill='white'>
              <use xlinkHref='#path-PropellLogoIntegrations-5' />
            </mask>
            <polygon
              fill={color || 'currentColor'}
              mask='url(#mask-PropellLogoIntegrations-6)'
              points='76.9222862 18.1949902 78.8661707 18.1949902 78.8661707 7.09534368 76.9222862 7.09534368'
            />
            <polygon
              fill={color || 'currentColor'}
              mask='url(#mask-PropellLogoIntegrations-6)'
              points='81.3654507 18.1949902 83.3093352 18.1949902 83.3093352 7.09534368 81.3654507 7.09534368'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M32.4330185,30.0048737 L33.4327305,30.0048737 L33.4327305,25.3212443 L32.4330185,25.3212443 L32.4330185,30.0048737 Z M33.3827449,24.42695 C33.2556981,24.5548067 33.1057413,24.618735 32.9328745,24.618735 C32.7607018,24.618735 32.610745,24.5548067 32.4843925,24.42695 C32.3580401,24.2990933 32.2941696,24.1473513 32.2941696,23.9724265 C32.2941696,23.7975017 32.3559573,23.6457597 32.4795328,23.5186055 C32.6031083,23.3900463 32.7537594,23.326118 32.9328745,23.326118 C33.1119895,23.326118 33.2633348,23.3900463 33.3869103,23.5186055 C33.5104859,23.6457597 33.5722736,23.7975017 33.5722736,23.9724265 C33.5722736,24.1473513 33.5084031,24.2990933 33.3827449,24.42695 L33.3827449,24.42695 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M37.024543,25.199921 C37.5556401,25.199921 37.9839889,25.3713332 38.3116723,25.7148603 C38.6379672,26.0583873 38.8025031,26.529771 38.8025031,27.1290114 L38.8025031,30.0050844 L37.8020968,30.0050844 L37.8020968,27.2322803 C37.8020968,26.8831332 37.7097623,26.6140721 37.5243991,26.4265022 C37.3390358,26.2389322 37.0863308,26.146201 36.7655899,26.146201 C36.4136079,26.146201 36.1296619,26.2564949 35.9137519,26.4777853 C35.6978419,26.7004808 35.590234,27.0383877 35.590234,27.4950188 L35.590234,30.0050844 L34.5898277,30.0050844 L34.5898277,25.3214551 L35.590234,25.3214551 L35.590234,25.9206955 C35.8922303,25.4401791 36.3705648,25.199921 37.024543,25.199921'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M42.4584639,26.2955544 L41.3108778,26.2955544 L41.3108778,28.5435841 C41.3108778,28.7374767 41.3532267,28.8765735 41.4400072,28.9608746 C41.5260935,29.0444732 41.6545288,29.0915413 41.8239244,29.100674 C41.9940143,29.1105091 42.2050646,29.1091041 42.4584639,29.0964589 L42.4584639,30.0048034 C41.6927122,30.0989397 41.1435648,30.0314988 40.8103275,29.8031832 C40.4770902,29.5755702 40.3104715,29.1554697 40.3104715,28.5435841 L40.3104715,26.2955544 L39.4593278,26.2955544 L39.4593278,25.3211741 L40.3104715,25.3211741 L40.3104715,24.3095607 L41.3108778,24.0102918 L41.3108778,25.3211741 L42.4584639,25.3211741 L42.4584639,26.2955544 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M43.9581707,27.269724 L46.679609,27.269724 C46.6115731,26.888964 46.4560623,26.6016377 46.2123825,26.4077452 C45.9687027,26.2145551 45.682674,26.1176088 45.3563791,26.1176088 C44.9856525,26.1176088 44.6767138,26.2208777 44.430257,26.4267129 C44.1831059,26.6332506 44.0262067,26.9135518 43.9581707,27.269724 L43.9581707,27.269724 Z M43.9678902,28.0944699 C44.0414801,28.4499396 44.2108757,28.7232157 44.4767714,28.9135957 C44.7419727,29.1039758 45.0661849,29.199517 45.4480193,29.199517 C45.9791164,29.199517 46.3769184,29.006327 46.6428141,28.6185419 L47.4668822,29.1053808 C47.0100694,29.7861123 46.3338753,30.1261268 45.4396884,30.1261268 C44.6864332,30.1261268 44.0789693,29.8942987 43.6159082,29.428535 C43.1528472,28.9634739 42.9216638,28.3747711 42.9216638,27.6638317 C42.9216638,26.96343 43.1500702,26.3782398 43.6061888,25.9068561 C44.0630017,25.4354723 44.648944,25.2001317 45.36471,25.2001317 C46.0443753,25.2001317 46.6011594,25.4403899 47.0364507,25.9209062 C47.471742,26.4021251 47.6890405,26.9859103 47.6890405,27.6722619 C47.6890405,27.7790433 47.6758498,27.9195451 47.6515513,28.0944699 L43.9678902,28.0944699 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M49.7019329,28.6658207 C49.9824076,28.9461219 50.3323068,29.0873263 50.7523247,29.0873263 C51.1716484,29.0873263 51.5222418,28.9461219 51.8027166,28.6658207 C52.0838856,28.384817 52.224123,28.0349674 52.224123,27.6162719 C52.224123,27.1982789 52.0838856,26.8484292 51.8027166,26.5674255 C51.5222418,26.2857193 51.1716484,26.14592 50.7523247,26.14592 C50.3323068,26.14592 49.9824076,26.2857193 49.7019329,26.5674255 C49.4207638,26.8484292 49.2805265,27.1982789 49.2805265,27.6162719 C49.2805265,28.0349674 49.4207638,28.384817 49.7019329,28.6658207 L49.7019329,28.6658207 Z M52.224123,25.3211741 L53.2057846,25.3211741 L53.2057846,29.7898356 C53.2057846,30.5014775 52.973907,31.0480297 52.5115402,31.4287897 C52.0484791,31.8095497 51.4868354,31.9999297 50.8266089,31.9999297 C49.7588609,31.9999297 49.0271272,31.6219798 48.6327964,30.8667823 L49.4936595,30.3609756 C49.7345623,30.8421945 50.1844328,31.0817501 50.8446592,31.0817501 C51.2771735,31.0817501 51.6145763,30.9679436 51.8582561,30.7403306 C52.1026302,30.5120151 52.224123,30.1958859 52.224123,29.7898356 L52.224123,29.217993 C51.8478425,29.7617352 51.3104973,30.0329038 50.6134758,30.0329038 C49.9594976,30.0329038 49.406879,29.7968607 48.9570086,29.3254769 C48.5057497,28.8547957 48.2808144,28.2843582 48.2808144,27.6162719 C48.2808144,26.9481856 48.5057497,26.3784505 48.9570086,25.9063643 C49.406879,25.4349806 49.9594976,25.19964 50.6134758,25.19964 C51.3104973,25.19964 51.8478425,25.4715111 52.224123,26.0145507 L52.224123,25.3211741 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M55.3718274,26.1082655 C55.6245323,25.5272903 56.1000898,25.237154 56.7971112,25.237154 L56.7971112,26.3330684 C56.4145825,26.3077781 56.080651,26.4005093 55.7973992,26.6091546 C55.5134532,26.8192048 55.3718274,27.1634344 55.3718274,27.6446532 L55.3718274,30.0050844 L54.3721153,30.0050844 L54.3721153,25.3214551 L55.3718274,25.3214551 L55.3718274,26.1082655 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M58.4823897,28.7357907 C58.7600875,29.0196044 59.1085982,29.1615113 59.5286161,29.1615113 C59.9479397,29.1615113 60.2964504,29.0196044 60.5748425,28.7357907 C60.851846,28.4519769 60.9906949,28.0943997 60.9906949,27.6637615 C60.9906949,27.2317183 60.851846,26.8748436 60.5748425,26.5903273 C60.2964504,26.3065136 59.9479397,26.1646067 59.5286161,26.1646067 C59.1085982,26.1646067 58.7600875,26.3065136 58.4823897,26.5903273 C58.2046919,26.8748436 58.0651487,27.2317183 58.0651487,27.6637615 C58.0651487,28.0943997 58.2046919,28.4519769 58.4823897,28.7357907 L58.4823897,28.7357907 Z M60.9906949,25.3215956 L61.9911012,25.3215956 L61.9911012,30.0052249 L60.9906949,30.0052249 L60.9906949,29.330816 C60.6144144,29.861913 60.0742922,30.1260565 59.3710226,30.1260565 C58.7350947,30.1260565 58.1921955,29.8879059 57.7423251,29.4101996 C57.2910662,28.9324932 57.066131,28.3501131 57.066131,27.6637615 C57.066131,26.9696823 57.2910662,26.3858971 57.7423251,25.9110009 C58.1921955,25.4375096 58.7350947,25.2000615 59.3710226,25.2000615 C60.0742922,25.2000615 60.6144144,25.4620974 60.9906949,25.9868719 L60.9906949,25.3215956 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M65.7393964,26.2955544 L64.5918103,26.2955544 L64.5918103,28.5435841 C64.5918103,28.7374767 64.6341593,28.8765735 64.7209398,28.9608746 C64.8070261,29.0444732 64.9354614,29.0915413 65.104857,29.100674 C65.2749469,29.1105091 65.4859972,29.1091041 65.7393964,29.0964589 L65.7393964,30.0048034 C64.9736448,30.0989397 64.4244974,30.0314988 64.0912601,29.8031832 C63.7580227,29.5755702 63.5914041,29.1554697 63.5914041,28.5435841 L63.5914041,26.2955544 L62.7402604,26.2955544 L62.7402604,25.3211741 L63.5914041,25.3211741 L63.5914041,24.3095607 L64.5918103,24.0102918 L64.5918103,25.3211741 L65.7393964,25.3211741 L65.7393964,26.2955544 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M66.6467739,30.0048737 L67.646486,30.0048737 L67.646486,25.3212443 L66.6467739,25.3212443 L66.6467739,30.0048737 Z M67.5958061,24.42695 C67.4687594,24.5548067 67.3188026,24.618735 67.1459357,24.618735 C66.9737631,24.618735 66.8238063,24.5548067 66.6974538,24.42695 C66.5711013,24.2990933 66.5072308,24.1473513 66.5072308,23.9724265 C66.5072308,23.7975017 66.5690186,23.6457597 66.6925941,23.5186055 C66.8161696,23.3900463 66.9675149,23.326118 67.1459357,23.326118 C67.3250508,23.326118 67.4763961,23.3900463 67.6006658,23.5186055 C67.7235471,23.6457597 67.7853349,23.7975017 67.7853349,23.9724265 C67.7853349,24.1473513 67.7214644,24.2990933 67.5958061,24.42695 L67.5958061,24.42695 Z'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M69.9604722,28.7213892 C70.23817,29.0023929 70.5811267,29.1435973 70.9872597,29.1435973 C71.3947812,29.1435973 71.737738,29.0023929 72.0154358,28.7213892 C72.2931336,28.441088 72.4319825,28.0884284 72.4319825,27.6634102 C72.4319825,27.2390946 72.2931336,26.8857325 72.0154358,26.6047288 C71.737738,26.3237251 71.3947812,26.1832232 70.9872597,26.1832232 C70.5811267,26.1832232 70.23817,26.3237251 69.9604722,26.6047288 C69.6827744,26.8857325 69.5432313,27.2390946 69.5432313,27.6634102 C69.5432313,28.0884284 69.6827744,28.441088 69.9604722,28.7213892 M70.9872597,30.1264078 C70.3089829,30.1264078 69.7320658,29.8896621 69.2565083,29.4147659 C68.7816451,28.9405721 68.5442135,28.3560844 68.5442135,27.6634102 C68.5442135,26.9700336 68.7816451,26.3862484 69.2565083,25.9113521 C69.7320658,25.4371583 70.3089829,25.1997102 70.9872597,25.1997102 C71.6731733,25.1997102 72.2514789,25.4371583 72.7235651,25.9113521 C73.1956514,26.3862484 73.4316945,26.9700336 73.4316945,27.6634102 C73.4316945,28.3560844 73.1956514,28.9405721 72.7235651,29.4147659 C72.2514789,29.8896621 71.6731733,30.1264078 70.9872597,30.1264078'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M76.7733708,25.199921 C77.3044678,25.199921 77.7328166,25.3713332 78.0605,25.7148603 C78.3867949,26.0583873 78.5513308,26.529771 78.5513308,27.1290114 L78.5513308,30.0050844 L77.5509246,30.0050844 L77.5509246,27.2322803 C77.5509246,26.8831332 77.45859,26.6140721 77.2732268,26.4265022 C77.0878635,26.2389322 76.8351585,26.146201 76.5144176,26.146201 C76.1624356,26.146201 75.8784897,26.2564949 75.6625796,26.4777853 C75.4466696,26.7004808 75.3390617,27.0383877 75.3390617,27.4950188 L75.3390617,30.0050844 L74.3386554,30.0050844 L74.3386554,25.3214551 L75.3390617,25.3214551 L75.3390617,25.9206955 C75.6410581,25.4401791 76.1193925,25.199921 76.7733708,25.199921'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
            <path
              fill={color || 'currentColor'}
              // eslint-disable-next-line max-len
              d='M80.5225685,26.6138614 C80.5225685,26.770521 80.6072664,26.8934601 80.776662,26.9840838 C80.9460577,27.0747075 81.1529425,27.152686 81.3973166,27.2180194 C81.6403021,27.2840552 81.8860647,27.3599262 82.1325215,27.4477399 C82.3796725,27.5355536 82.5886401,27.6830805 82.7580357,27.8924283 C82.9274314,28.101776 83.0121292,28.3659195 83.0121292,28.6841562 C83.0121292,29.1337622 82.841345,29.4871243 82.4990825,29.7428377 C82.15682,29.9985511 81.7256942,30.1264078 81.2070936,30.1264078 C80.7502807,30.1264078 80.3587269,30.0294615 80.0317377,29.8362714 C79.7040543,29.6430814 79.4666227,29.3747228 79.3194429,29.0304933 L80.1796118,28.5253891 C80.3399823,28.9742926 80.6822448,29.199798 81.2070936,29.199798 C81.7319424,29.199798 81.9943668,29.0248732 81.9943668,28.6750236 C81.9943668,28.5253891 81.9089747,28.4031525 81.7395791,28.3097188 C81.5701834,28.2155825 81.3632986,28.136199 81.1189245,28.0701631 C80.875939,28.0048298 80.6301764,27.9289587 80.3837197,27.8411451 C80.1365686,27.7540339 79.9282953,27.6086145 79.7582054,27.4055893 C79.5888098,27.2025642 79.5041119,26.9454458 79.5041119,26.6328291 C79.5041119,26.202191 79.6665651,25.8551514 79.990083,25.5931154 C80.313601,25.3310794 80.7162628,25.1997102 81.1980684,25.1997102 C81.5805971,25.1997102 81.9214711,25.2854163 82.2206905,25.4575311 C82.5199099,25.6296459 82.7497048,25.8677965 82.9100752,26.1740906 L82.068651,26.6517969 C81.9075862,26.2956247 81.6173921,26.1178898 81.1980684,26.1178898 C81.0064569,26.1178898 80.8460865,26.1600404 80.7162628,26.2443415 C80.5871333,26.3286426 80.5225685,26.4522843 80.5225685,26.6138614'
              mask='url(#mask-PropellLogoIntegrations-6)'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
