import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import TransferEInvoices from './TransferEInvoices';

const mapStateToProps = ({ customer }: RootState) => ({
  isFetching: customer.isFetching,
  customerAndAllClientsIntegrations: customer.customerAndAllClientsIntegrations,
});

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, {})(TransferEInvoices);
