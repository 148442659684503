import { Icon } from 'types/Icon';

export const UsersIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '18'} height={height || '14'} viewBox='0 0 18 14'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M4.087,7.311 C2.017,8.007 0.452,9.801 0.083,12 C0.028,12.325 0,12.659 0,13 L0,14 L14,14 L14,13 C14,12.659 13.972,12.325 13.917,12 C13.548,9.801 11.983,8.007 9.913,7.311 C9.312,7.109 8.669,7 8,7 L6,7 C5.331,7 4.688,7.109 4.087,7.311 Z M4.623,5.118 C4.984,5.546 5.459,5.874 6,6.058 C6.314,6.164 6.65,6.222 7,6.222 C7.35,6.222 7.686,6.164 8,6.058 C8.541,5.874 9.016,5.546 9.377,5.118 C9.835,4.576 10.111,3.876 10.111,3.111 C10.111,1.393 8.718,0 7,0 C5.282,0 3.889,1.393 3.889,3.111 C3.889,3.876 4.165,4.576 4.623,5.118 Z M11.874,12 C11.43,10.275 9.864,9 8,9 L6,9 C4.136,9 2.57,10.275 2.126,12 L11.874,12 Z M11.036,7 C14.009,7 16.477,9.162 16.953,12 C17.008,12.325 17.036,12.659 17.036,13 L17.036,13 L17.036,14 L15.036,14 L15.036,13 C15.036,10.374 13.349,8.142 11,7.329 L11,7.329 Z M10.278282,0 C11.996282,0 13.389282,1.393 13.389282,3.111 C13.389282,4.829 11.996282,6.222 10.278282,6.222 C9.94828199,6.222 9.91028199,5.781 10.172282,5.58 C10.912282,5.011 11.389282,4.117 11.389282,3.111 C11.389282,2.105 10.912282,1.211 10.172282,0.642 C9.91028199,0.441 9.94828199,0 10.278282,0 Z M7,2 C7.614,2 8.111,2.497 8.111,3.111 C8.111,3.725 7.614,4.222 7,4.222 C6.386,4.222 5.889,3.725 5.889,3.111 C5.889,2.497 6.386,2 7,2 Z'
        id='path-UsersIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-583.000000, -3298.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(463.000000, 191.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-UsersIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
