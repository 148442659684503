import { Icon } from 'types/Icon';

export const ArrowRightIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '8'} height={height || '13'} viewBox='0 0 8 13'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M3.51606242,2.48393758 L-1.77893758,7.48596187 C-2.14675475,7.83342748 -2.16325295,8.41327875 -1.81578733,8.78109592 C-1.80385598,8.79372611 -1.79156776,8.80601433 -1.77893758,8.81794568 L-1.75989909,8.83593075 C-1.37478793,9.19973347 -0.772777999,9.20006117 -0.387271,8.83667793 L3.51606242,5.15735188 L3.51606242,5.15735188 L7.41939584,8.83667793 C7.80490284,9.20006117 8.40691277,9.19973347 8.79202393,8.83593075 L8.81106242,8.81794568 C9.17887959,8.47048006 9.19537779,7.8906288 8.84791218,7.52281162 C8.83598083,7.51018144 8.82369261,7.49789322 8.81106242,7.48596187 L3.51606242,2.48393758 L3.51606242,2.48393758 Z'
        id='path-ArrowRightIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-308.000000, -3134.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(188.483938, 77.516062)'>
            <use
              fill={color || 'currentColor'}
              transform='translate(3.566063, 6.050000) rotate(90.000000) translate(-3.566063, -6.050000) '
              xlinkHref='#path-ArrowRightIcon-1'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
