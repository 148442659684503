import { RefObject, useRef, useState } from 'react';

// eslint-disable-next-line no-undef
export const useSearchInputFocus = (): [any, RefObject<HTMLInputElement>, VoidFunction] => {
  const [isSearchInputChanged, setIsSearchInputChanged] = useState(false);
  const htmlElRef = useRef<HTMLInputElement>(null);
  const setSearchInputFocus = () => {
    if (isSearchInputChanged) {
      htmlElRef && htmlElRef.current && htmlElRef.current.focus();
    }
  };

  return [setIsSearchInputChanged, htmlElRef, setSearchInputFocus];
};
