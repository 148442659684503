import { IntegrationsSystems, integrationsSystemsNamesMapping } from 'types/Integrations';

export const availableSystemsToOptions = (systems: any) => {
  if (systems.length === 0) {
    return [];
  }
  return systems.map((system: any) => ({
    ...system,
    value: system.name,
    label: integrationsSystemsNamesMapping[system.name] ? integrationsSystemsNamesMapping[system.name] : system.name,
  }));
};

export const mapSystemName = (systemName: string | IntegrationsSystems) =>
  integrationsSystemsNamesMapping[systemName] ? integrationsSystemsNamesMapping[systemName] : systemName;
