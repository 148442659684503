import { combineReducers } from 'redux';
import { History as HistoryInterface } from 'history';
import { connectRouter, ConnectedRouter } from 'connected-react-router';

import { intlReducer, State as IntlState } from 'common/reducers/intlReducer';
import { sharedReducer, State as SharedState } from 'common/reducers/sharedReducer';
import { loginReducer, State as AuthState } from 'screens/Login/logic/loginReducer';
import {
  notificationReducer,
  State as NotificationState,
} from 'components/NotificationManager/logic/notificationReducer';
import { sidebarReducer, State as SidebarState } from 'components/Sidebar/logic/sidebarReducer';
import { customersReducer, State as CustomersState } from 'screens/Customers/logic/customersReducer';
import {
  customerCreateReducer,
  State as CustomerCreateState,
} from 'screens/CustomerCreate/logic/customerCreateReducer';
import { customerReducer, State as CustomerState } from 'screens/Customer/logic/customerReducer';
import { clientsAllReducer, State as ClientsAllState } from 'screens/ClientsAll/logic/clientsAllReducer';
import { clientsReducer, State as ClientsState } from 'screens/CustomerClients/logic/clientsReducer';
import { clientCreateReducer, State as ClientCreateState } from 'screens/ClientCreate/logic/clientCreateReducer';
import { clientReducer, State as ClientState } from 'screens/Client/logic/clientReducer';
import {
  transferHistoricalDataReducer,
  State as TransferHistoricalDataState,
} from 'screens/TransferHistoricalData/logic/transferHistoricalDataReducer';
import {
  syncMasterDataReducer,
  State as SyncMasterDataState,
} from 'screens/SyncMasterData/logic/syncMasterDataReducer';
import { operationsAllReducer, State as OperationsAllState } from 'screens/OperationsAll/logic/operationsAllReducer';
import {
  integrationsDefaultSettingsReducer,
  State as IntegrationsDefaultSettingsState,
} from 'screens/IntegrationsDefaultSettings/logic/integrationsDefaultSettingsReducer';

export interface RootState {
  intl: IntlState;
  shared: SharedState;
  router: ConnectedRouter;
  auth: AuthState;
  notifications: NotificationState;
  sidebar: SidebarState;
  customers: CustomersState;
  customerCreate: CustomerCreateState;
  customer: CustomerState;
  clientsAll: ClientsAllState;
  clients: ClientsState;
  clientCreate: ClientCreateState;
  client: ClientState;
  transferHistoricalData: TransferHistoricalDataState;
  syncMasterData: SyncMasterDataState;
  operationsAll: OperationsAllState;
  integrationsDefaultSettings: IntegrationsDefaultSettingsState;
}

const zipReducers = (history: HistoryInterface) => ({
  intl: intlReducer,
  shared: sharedReducer,
  router: connectRouter(history),
  auth: loginReducer,
  notifications: notificationReducer,
  sidebar: sidebarReducer,
  customers: customersReducer,
  customerCreate: customerCreateReducer,
  customer: customerReducer,
  clientsAll: clientsAllReducer,
  clients: clientsReducer,
  clientCreate: clientCreateReducer,
  client: clientReducer,
  transferHistoricalData: transferHistoricalDataReducer,
  syncMasterData: syncMasterDataReducer,
  operationsAll: operationsAllReducer,
  integrationsDefaultSettings: integrationsDefaultSettingsReducer,
});

// @ts-ignore
export const rootReducer = (history: HistoryInterface) => combineReducers<RootState>(zipReducers(history));
