import { call, put, takeEvery, select } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { clientsService } from 'common/api/clientsService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { RootState } from 'store/rootReducer';
import { TransferClientData, TransferredDataItem } from 'types/TransferHistoricalData';
import { TableSortOrder } from 'types/Tables';
import { actionCreators, ActionTypeKeys } from './transferHistoricalDataReducer';

export const getPage = (state: RootState) => state.transferHistoricalData.page;
export const getPerPage = (state: RootState) => state.transferHistoricalData.perPage;
export const getFilterSearch = (state: RootState) => state.transferHistoricalData.filterSearch;
export const getOrderBy = (state: RootState) => state.transferHistoricalData.orderBy;
export const getSortOrder = (state: RootState) => state.transferHistoricalData.sortOrder;

export function* getClientsForTransferDataSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientsForTransferData, payload);

    const prepareData = {
      data: data.data.map((item: TransferClientData) => ({
        ...item,
        key: item.organizationNumber,
        id: item.clientId,
      })),
      totalCount: data.totalCount,
    };

    yield put(actionCreators.getClientsForTransferDataSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientsForTransferDataFailed());
  }
}

export function* executeDataTransferSaga({ payload }: ActionWithPayload) {
  try {
    const currentPage: number = yield select(getPage);
    const currentPerPage: number = yield select(getPerPage);
    const currentFilterSearch: string = yield select(getFilterSearch);
    const currentOrderBy: string = yield select(getOrderBy);
    const currentSortOrder: TableSortOrder = yield select(getSortOrder);
    const transferredData = [] as TransferredDataItem[];

    for (let i = 0; i < payload.clientIds.length; i++) {
      const { data } = yield call(clientsService.executeDataTransferByClientId, {
        accessToken: payload.accessToken,
        customerId: payload.customerId,
        clientId: payload.clientIds[i],
        fromDateUtc: payload.fromDateUtc,
        toDateUtc: payload.toDateUtc,
      });

      transferredData.push(data);
    }

    const transferResults: TransferredDataItem[] = transferredData.filter(
      (item: TransferredDataItem) => !item.successful,
    );

    yield put(actionCreators.executeDataTransferSuccess(transferResults));

    yield put(
      actionCreators.getClientsForTransferData({
        accessToken: payload.accessToken,
        customerId: payload.customerId,
        page: currentPage,
        size: currentPerPage,
        orderBy: currentOrderBy,
        sortOrder: currentSortOrder,
        search: currentFilterSearch,
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.executeDataTransferFailed());
  }
}

export function* transferHistoricalDataSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA, getClientsForTransferDataSaga),
    takeEvery(ActionTypeKeys.EXECUTE_DATA_TRANSFER, executeDataTransferSaga),
  ];
}
