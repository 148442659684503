export enum BadgeBgType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
  WARNING = 'warning',
  LIGHT = 'light',
  DARK = 'dark',
}

export const getBadgeBackground = ({ theme, bg }: any): string => {
  switch (bg) {
    case BadgeBgType.PRIMARY:
      return theme.status.info;
    case BadgeBgType.SECONDARY:
      return theme.colors.lightGrey;
    case BadgeBgType.SUCCESS:
      return theme.status.success;
    case BadgeBgType.PENDING:
      return theme.status.pending;
    case BadgeBgType.ERROR:
      return theme.status.error;
    case BadgeBgType.WARNING:
      return theme.status.warning;
    case BadgeBgType.LIGHT:
      return theme.colors.grey;
    case BadgeBgType.DARK:
      return theme.colors.black;

    default:
      return theme.primary;
  }
};

export const getBadgeTextColor = ({ theme, bg }: any): string => {
  switch (bg) {
    case BadgeBgType.PRIMARY:
      return theme.colors.white;
    case BadgeBgType.SECONDARY:
      return theme.colors.white;
    case BadgeBgType.SUCCESS:
      return theme.colors.white;
    case BadgeBgType.PENDING:
      return theme.colors.white;
    case BadgeBgType.ERROR:
      return theme.colors.white;
    case BadgeBgType.WARNING:
      return theme.colors.white;
    case BadgeBgType.LIGHT:
      return theme.fontPrimary;
    case BadgeBgType.DARK:
      return theme.colors.white;

    default:
      return theme.colors.white;
  }
};

export const getBadgeSize = (size: 'large' | 'medium' | 'small' | undefined) => {
  if (size === 'large') {
    return '15px';
  }
  if (size === 'small') {
    return '9px';
  }

  return '12px';
};

export const getBadgeAlignment = (vAlignment: 'top' | 'middle' | 'bottom' | undefined) => {
  if (vAlignment === 'top') {
    return 'flex-start';
  }
  if (vAlignment === 'bottom') {
    return 'flex-end';
  }

  return 'center';
};
