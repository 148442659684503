import React, { ReactNode } from 'react';

export enum TableSortOrder {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export enum TableDateField {
  UPDATE_ON = 'updatedOn',
  DATE_TIME = 'dateTime',
  LAST_UPDATE = 'lastUpdate',
  LAST_EXECUTED = 'lastExecuted',
  CREATED_ON = 'createdOn',
  STARTED_DATE_TIME = 'startedDate',
  FINISHED_DATE_TIME = 'finishedDate',
}

export enum TableAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface ColumnItem<TRecord = any> {
  name: string;
  title: string | ReactNode;
  colSpan?: number | string;
  width?: number | string;
  customWidth?: number | string;
  align?: TableAlignment;
  sortable?: boolean;
  headCallback?: () => void;
  rowCallbacks?: () => void;
  cellCallback?: (record: TRecord) => any; // TODO - specify return type
}

export interface CheckboxProps {
  id?: string;
  value?: boolean;
  disabled?: boolean;
  label?: ReactNode;
  onChange?: (event: any) => void;
}

export type TransformColumns = (columns: ColumnItem[]) => ColumnItem[];

export type Key = React.Key;

export declare type GetRowKey = (record: any, index?: number) => Key;

export type SelectionSelectFn = (record: any, selected: boolean, selectedRows: any[], nativeEvent: Event) => void;

export interface TableRowSelection {
  columnTitle?: string | ReactNode;
  defaultSelectedRowKeys?: Key[];
  selectedRowKeys?: Key[];
  onChange?: (selectedRowKeys: Key[], selectedRows: any[]) => void;
  onSelect?: SelectionSelectFn;
  getCheckboxProps?: (payload: any) => Partial<Omit<CheckboxProps, 'checked' | 'defaultChecked'>>;
  renderCell?: (value: boolean, record: any, index: number, originNode: ReactNode) => ReactNode;
}

// export interface UseSelectionConfig<RecordType> { // TODO: add RecordType, change any to RecordType in table types
export interface UseSelectionConfig {
  data: any[];
  getRowKey: GetRowKey;
  // getRecordByKey: (key: Key) => RecordType;
  getRecordByKey: (key: Key) => any;
}
