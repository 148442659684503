import { prepareMultipleSelectOptionsFromArray, prepareSelectOptionsFromDictionaries } from 'utils/formHelpers';
import { integrationsSystemsNamesMapping } from 'types/Integrations';
import { customerTypesNamesMapping } from 'types/Customer';
import { Dictionaries, systemsFilterOptions } from 'common/constants';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { State as CustomersState } from './customersReducer';

export const getCustomersView = (sharedState: SharedState, customersState: CustomersState) => ({
  isFetching: customersState.isFetching,
  totalCount: customersState.totalCount,
  customers: customersState.customers,
  page: customersState.page,
  perPage: customersState.perPage,
  orderBy: customersState.orderBy,
  sortOrder: customersState.sortOrder,
  filterType: customersState.filterType,
  filterStatus: customersState.filterStatus,
  filterSystem: customersState.filterSystem,
  filterSearch: customersState.filterSearch,
  isIncludeDeactivated: customersState.isIncludeDeactivated,
  showOnlyDeactivatedSystems: customersState.showOnlyDeactivatedSystems,
  connectedSystemsOptions: prepareMultipleSelectOptionsFromArray(
    sharedState.integrationSystems,
    systemsFilterOptions[0],
    integrationsSystemsNamesMapping,
  ),
  customerStatusOptions: prepareSelectOptionsFromDictionaries(sharedState.dictionaries[Dictionaries.CUSTOMER_STATUSES]),
  customerTypeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.CUSTOMER_TYPES],
    customerTypesNamesMapping,
  ),
});
