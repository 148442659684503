import { EInvoiceDto, EInvoiceStatus, IPAIAdminApiClient } from 'common/api/generated/PAIAdminApiClient';
import { ZipAdditionalData, toZipAdditionalData } from './zipAdditionalData';

export enum RetryInfoType {
  eInvoice = 'eInvoice',
  zipBatch = 'zipBatch',
}

export type RetryEInvoiceInfo = {
  type: RetryInfoType.eInvoice;
  record: Required<Pick<EInvoiceDto, 'customerId' | 'internalId' | 'systemName' | 'rejected'>>;
};

export type RetryZipBatchInfo = {
  type: RetryInfoType.zipBatch;
  record: Required<Pick<EInvoiceDto, 'customerId' | 'systemName' | 'rejected'> & { additionalData: ZipAdditionalData }>;
};

export type RetryInfo = RetryEInvoiceInfo | RetryZipBatchInfo;

export const toRetryInfo = (dto: EInvoiceDto): RetryInfo | undefined => {
  // can not retry non-failed records
  if (dto.status !== EInvoiceStatus.Failed) {
    return undefined;
  }

  const zipData = toZipAdditionalData(dto);

  if (!!zipData && !!dto.customerId && !!dto.systemName) {
    return {
      type: RetryInfoType.zipBatch,
      record: {
        customerId: dto.customerId!,
        additionalData: zipData,
        systemName: dto.systemName!,
        rejected: dto.rejected!,
      },
    };
  }

  if (!!dto.customerId && !!dto.internalId && !!dto.systemName) {
    return {
      type: RetryInfoType.eInvoice,
      record: {
        customerId: dto.customerId!,
        internalId: dto.internalId!,
        systemName: dto.systemName!,
        rejected: dto.rejected!,
      },
    };
  }

  return undefined;
};

export const retry = async (
  paiClient?: IPAIAdminApiClient,
  retryInfo?: RetryInfo,
  skipRejection?: boolean,
): Promise<void> => {
  if (retryInfo === undefined) {
    throw new Error(`Can not retry eInvoice, input data is undefined`);
  }

  switch (retryInfo.type) {
    case RetryInfoType.eInvoice:
      const { record: eInvoiceRecord } = retryInfo as RetryEInvoiceInfo;
      await paiClient?.accessPointControllerTriggerEInvoiceSyncRetryPerCustomerId(eInvoiceRecord.internalId!, eInvoiceRecord.customerId, {
        accessPointName: eInvoiceRecord.systemName,
        skipRejection:  skipRejection!,
      });
      break;

    case RetryInfoType.zipBatch:
      const { record: zipBatchRecord } = retryInfo as RetryZipBatchInfo;
      await paiClient?.accessPointControllerTriggerEInvoiceSyncRetryPerCustomerId(
        zipBatchRecord.additionalData.ZipBatchId,
        zipBatchRecord.customerId,
        {
          accessPointName: zipBatchRecord.systemName,
          skipRejection: skipRejection!,
        },
      );
      break;

    default:
      throw new Error('Unsupported record type for rerty.');
  }
};
