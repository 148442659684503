import { FormDataTypes } from 'common/constants/Form';
import { AccessToken } from './Auth';

export interface IntegrationSystem {
  id: string;
  name: IntegrationsSystems;
  type: IntegrationsSystemTypes;
}

export interface IntegrationSystemOptions {
  id: string;
  name?: IntegrationsSystems;
  type?: IntegrationsSystemTypes;
  value: IntegrationsSystems;
  label: string | IntegrationsSystems;
}

export interface ConfigurationSchemaRequest {
  isCreatePage: boolean;
  accessToken: AccessToken;
  systemId: string;
}

export interface CustomerConfigurationSchemaRequest extends ConfigurationSchemaRequest {
  customerId: number;
}

export interface ClientsConfigurationSchemaRequest extends CustomerConfigurationSchemaRequest {
  clientId: number;
}

export interface SystemsSettingsRequest {
  accessToken: AccessToken;
  systemId: string;
}

export interface CustomerSystemsSettingsRequest extends SystemsSettingsRequest {
  customerId: number;
}

export interface ClientSystemsSettingsRequest extends CustomerSystemsSettingsRequest {
  clientId: number;
}

export interface IntegrationRequest {
  accessToken: AccessToken;
  integrationId: string;
}

export interface TestConnectionRequest {
  accessToken: AccessToken;
  integrationId: string;
}

export interface UpdateIntegrationRequest extends IntegrationRequest {
  requestData: any;
  withTestConnection: boolean;
}

export interface IntegrationSettings {
  name: string;
  value: string;
}

export interface IntegrationDataSettings {
  currentSettings: IntegrationSettings[];
  parentSettings: IntegrationSettings[];
}

export interface IntegrationData {
  id: string;
  systemId: string;
  isActive: boolean;
  systemName: IntegrationsSystems;
  systemType: IntegrationsSystemTypes;
  customerId: number;
  clientId: number;
  settings: IntegrationDataSettings;
  showTestConnectionButton: boolean;
}

export interface IntegrationSettingsDefault {
  settings: IntegrationDataSettings;
}

export enum IntegrationsLevel {
  UNKNOWN = 'Unknown',
  DEFAULT = 'Default',
  CUSTOMER = 'Customer',
  CLIENT = 'Client',
}

export enum IntegrationsSystems {
  UNKNOWN = 'Unknown',
  VICAI = 'VicAI',
  TRIPLETEX = 'Tripletex',
  NETSUITE = 'NetSuite',
  MILLUM = 'Millum',
  TFSO = 'TFSO',
  POGO = 'POGO',
  EMAIL_SCANNING_SERVICE = 'EmailScanningService',
  LOGIQ = 'Logiq',
  PROPELLAI = 'PropellAI',

  BUSINESSNXT = 'BusinessNxt',
  INFORLN = 'InforLN',
  MERCELL = 'Mercell',
  SFTP = 'SFTP',
  TRIPLETEX_ACCESSPOINT = 'TripletexAccessPoint',
  XLEDGER = 'Xledger'
}

export interface IntegrationsInfo {
  name: IntegrationsSystems;
  isActive: boolean;
}

export enum IntegrationsSystemTypes {
  INVOICE_PROCESSOR = 'InvoiceProcessor',
  ACCESS_POINT = 'AccessPoint',
  ERP = 'ERP',
}

export enum IntegrationsStatusAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
}

export interface CustomerIntegrationsRequestParams {
  accessToken: AccessToken;
  customerId: number;
}

export interface CreateCustomerIntegrationsRequestParams extends CustomerIntegrationsRequestParams {
  requestData: any;
}

export interface UpdateCustomerIntegrationsRequestParams extends CreateCustomerIntegrationsRequestParams {
  systemId: string;
}

export interface ClientIntegrationsRequestParams {
  accessToken: AccessToken;
  customerId: number;
  clientId: number;
}

export interface CreateClientIntegrationsRequestParams extends ClientIntegrationsRequestParams {
  requestData: any;
}

export interface UpdateClientIntegrationsRequestParams extends CreateClientIntegrationsRequestParams {
  system: IntegrationsSystems;
}

export interface CustomerIntegrationBySystemRequestParams {
  accessToken: AccessToken;
  customerId: number;
  system: IntegrationsSystems;
}

export interface ClientIntegrationBySystemRequestParams {
  accessToken: AccessToken;
  customerId: number;
  clientId: number;
  system: IntegrationsSystems;
}

export interface CustomerAvailableIntegrationsSystemRequestParams {
  accessToken: AccessToken;
  customerId: number;
}

export interface ClientAvailableIntegrationsSystemRequestParams {
  accessToken: AccessToken;
  customerId: number;
  clientId: number;
}

export interface ConfigurationSchemaByLevelRequest {
  accessToken: AccessToken;
  system: string | IntegrationsSystems;
  level: IntegrationsLevel;
}

export interface CustomerConfigurationSchemaByLevelRequest extends ConfigurationSchemaByLevelRequest {
  customerId: number;
}

export interface ClientConfigurationSchemaByLevelRequest extends CustomerConfigurationSchemaByLevelRequest {
  clientId: number;
}

export interface DefaultSettingsRequestData {
  accessToken: AccessToken;
  systemId: string;
  requestData: any;
}

export const integrationsSystemsNamesMapping: { [IntegrationsSystem: string]: string } = {
  [IntegrationsSystems.UNKNOWN]: 'None',
  [IntegrationsSystems.VICAI]: 'Vic.ai',
  [IntegrationsSystems.TRIPLETEX]: 'Tripletex',
  [IntegrationsSystems.NETSUITE]: 'NetSuite',
  [IntegrationsSystems.MILLUM]: 'Millum',
  [IntegrationsSystems.TFSO]: '24SevenOffice',
  [IntegrationsSystems.POGO]: 'PowerOffice Go',
  [IntegrationsSystems.EMAIL_SCANNING_SERVICE]: 'Email Scanning Service',
  [IntegrationsSystems.LOGIQ]: 'Logiq',
  [IntegrationsSystems.PROPELLAI]: 'Propell.ai',
};

export interface integrationsOptionsType {
  id: string;
  value: IntegrationsSystems;
  label: string;
}

export interface IntegrationSchemaField {
  isMandatory: boolean;
  isOverridable: boolean;
  isEncrypted: boolean;
  order: number;
  column: number;
  id: string;
  name: string;
  validationRegex: string;
  dataType: FormDataTypes;
}

export interface IntegrationSchemaFieldGroups {
  isOverridable: boolean;
  order: number;
  column: number;
  name: string;
  fields: IntegrationSchemaField[];
  childFieldGroups: IntegrationSchemaFieldGroups;
}

export interface IntegrationSchema {
  name: string;
  requiredParentLevels: IntegrationsLevel[];
  fields: IntegrationSchemaField[];
  fieldGroups: IntegrationSchemaFieldGroups;
}

export interface IntegrationsCsvFileHeadersRequestData {
  accessToken: AccessToken;
  systemId: string;
}

export interface IntegrationStatusRequest {
  accessToken: AccessToken;
  integrationId: string;
  action: IntegrationsStatusAction;
}
