import { Icon } from 'types/Icon';

export const PenIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '15'} height={height || '15'} viewBox='0 0 15 15'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M13.6411,5.08625 C14.017,5.47725 14.0121,6.10025 13.6265,6.48525 L5.6523,14.39125 L0.6915,14.39125 C0.4228,14.40725 0.0697,14.02925 0.0553,13.77025 L0,8.84425 L7.9697,0.82825 C8.3602,0.43825 8.9934,0.43825 9.3839,0.82825 L9.3986,0.84325 L9.9488,0.29325 C10.3394,-0.09775 10.9725,-0.09775 11.3631,0.29325 L14.1915,3.12125 C14.582,3.51225 14.582,4.14525 14.1915,4.53625 L13.6411,5.08625 Z M2.3128,9.31425 L5.1412,12.14225 L11.5052,5.77825 L8.6768,2.95025 L2.3128,9.31425 Z'
        id='path-PenIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-483.000000, -3298.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(363.000000, 191.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-PenIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
