import styled from 'styled-components';

interface SidebarWrapperType {
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
  isSubNavExists?: boolean;
}

const getSidebarWrapperWidth = (isSubNavExists: boolean, isMainNavCollapsed: boolean, isSubNavCollapsed: boolean) => {
  if (isSubNavExists && isMainNavCollapsed && !isSubNavCollapsed) {
    return '320px';
  }
  if (isSubNavExists && !isMainNavCollapsed && isSubNavCollapsed) {
    return '320px';
  }
  if (isSubNavExists && isMainNavCollapsed && isSubNavCollapsed) {
    return '160px';
  }
  if (!isSubNavExists && isMainNavCollapsed) {
    return '80px';
  }
  if (!isSubNavExists && !isMainNavCollapsed) {
    return '260px';
  }

  return '260px';
};

export const SidebarWrapper = styled.aside<SidebarWrapperType>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: ${({ isSubNavExists, isMainNavCollapsed, isSubNavCollapsed }) =>
    getSidebarWrapperWidth(!!isSubNavExists, isMainNavCollapsed, isSubNavCollapsed)};
  overflow: hidden;
  box-shadow: 0 2px 10px ${({ theme }) => theme.boxShadow};
  z-index: 2;
  padding-top: 56px;
`;

interface SidebarMainType {
  isSubNavExists?: boolean;
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
}

export const SidebarMain = styled.div<SidebarMainType>`
  width: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '80px' : '260px')};
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  background: ${({ theme, isSubNavExists }) =>
    isSubNavExists ? theme.sidebar.backgroundMain : theme.backgroundPrimary};
`;

export const SidebarTop = styled.div`
  width: 100%;
`;

export const SidebarBottom = styled.div`
  width: 100%;
`;

interface SidebarSubType {
  isSubNavCollapsed?: boolean;
}

export const SidebarSub = styled.div<SidebarSubType>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // width: 240px;
  width: ${({ isSubNavCollapsed }) => (isSubNavCollapsed ? '80px' : '240px')};
  background: ${({ theme }) => theme.sidebar.backgroundSub};
`;

export const SidebarFooter = styled.div`
  padding: 20px 16px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
