import { Icon } from 'types/Icon';

export const ArrowDownIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '13'} height={height || '8'} viewBox='0 0 13 8'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M9.98781039,0.657620458 L6.05000019,4.40825014 L6.05000019,4.40825014 L2.11218999,0.657620458 C1.72566952,0.289472914 1.11814607,0.289803795 0.732026841,0.658372149 L0.710875022,0.678562521 C0.33611529,1.03628772 0.322306072,1.63008408 0.680031271,2.00484381 C0.690072066,2.01536274 0.700356095,2.02564677 0.710875022,2.03568756 L6.05000019,7.13212522 L6.05000019,7.13212522 L11.3891254,2.03568756 C11.7638851,1.67796237 11.7776943,1.084166 11.4199691,0.709406272 C11.4099283,0.698887345 11.3996443,0.688603315 11.3891254,0.678562521 L11.3679735,0.658372149 C10.9818543,0.289803795 10.3743309,0.289472914 9.98781039,0.657620458 Z'
        id='path-ArrowDownIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-220.000000, -3187.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(100.000000, 80.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-ArrowDownIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
