import { systemsFilterOptions, defaultFilterOptions, EmptyData } from 'common/constants';

export const getArrayOfOptionsValues = (arr: any[]) => arr.map((arrItem: any) => arrItem.value);

export const getAllOptionIndex = (event: any) =>
  event.findIndex((item: any) => item.value === systemsFilterOptions[0].value);

export const checkIsFirstLastCharactersSpaces = (value: string) =>
  value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ';

export const getArrayOfOptionsIds = (arr: any[]) => {
  if (arr.length === 1 && arr[0].value === '') {
    return undefined;
  }

  return arr.map((arrItem: any) => arrItem.id);
};

export const getSelectAllOptionIndex = (event: any, filterOptions: any[]) =>
  event.findIndex((item: any) => item.value === filterOptions[0].value);

export const prepareSelectOptionsFromArray = (data: any[], namesMapping: any) => {
  return data.map((item: any) => ({
    ...item,
    value: item.name,
    label: namesMapping ? namesMapping[item.name] : item.name,
  }));
};

export const prepareMultipleSelectOptionsFromArray = (
  data: any[],
  firstItem: any | undefined,
  namesMapping: any | undefined,
) => {
  if (!data) {
    return [];
  }
  const restOptions = data.map((item: any) => ({
    ...item,
    value: item.name,
    label: namesMapping[item.name] ? namesMapping[item.name] : item.name,
  }));
  /* eslint-disable */
  const firstElement = firstItem
    ? firstItem
    : {
        id: 0,
        value: '',
        label: 'All',
      };
  /* eslint-enable */

  return [firstElement, ...restOptions];
};

export const prepareSelectOptionsFromDictionaries = (
  dictionary: string[],
  namesMapping?: any,
  excludeDefaultOption = false,
) => {
  if (!dictionary) {
    return [defaultFilterOptions];
  }

  let restOptions;
  if (excludeDefaultOption) {
    restOptions = dictionary
      .filter((item: string) => item !== EmptyData.UNKNOWN)
      .map((item: string, index: number) => {
        if (namesMapping) {
          return {
            id: index,
            value: item,
            label: namesMapping[item] ? namesMapping[item] : item,
          };
        } else {
          return {
            id: index,
            value: item,
            label: item,
          };
        }
      });
  } else {
    restOptions = dictionary.map((item: string, index: number) => {
      if (namesMapping) {
        return {
          id: index,
          value: item === EmptyData.UNKNOWN || item === EmptyData.NONE ? '' : item,
          label:
            item === EmptyData.UNKNOWN || item === EmptyData.NONE
              ? 'All'
              : namesMapping[item]
              ? namesMapping[item]
              : item,
        };
      } else {
        return {
          id: index,
          value: item === EmptyData.UNKNOWN || item === EmptyData.NONE ? '' : item,
          label: item === EmptyData.UNKNOWN || item === EmptyData.NONE ? 'All' : item,
        };
      }
    });
  }

  return [...restOptions];
};

export const getSelectedMultiOptions = (event: any[], options: any[], setCurrentState: (payload: any[]) => void) => {
  const allOptionIndex = getSelectAllOptionIndex(event, options);
  let selectedOptions: any[] = [];

  if (!event.length) {
    setCurrentState([options[0]]);
    selectedOptions = [options[0]];
  }

  if (event.length >= 1) {
    if (allOptionIndex === 0) {
      const filtered = event.filter((_item: any, index: number) => index !== allOptionIndex);
      setCurrentState(filtered);
      selectedOptions = [...filtered];
    } else if (allOptionIndex > 0) {
      setCurrentState([options[0]]);
      selectedOptions = [options[0]];
    } else {
      setCurrentState(event);
      selectedOptions = [...event];
    }
  }

  return selectedOptions;
};

export const getAvailableStatuses = (isIncludeDeactivated: boolean, options: any[]) => {
  if (isIncludeDeactivated) {
    return undefined;
  }

  return getArrayOfOptionsValues(options.filter((option) => option.value !== '' && option.value !== 'Deactivated'));
};
