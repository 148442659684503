import { ArrowRightIcon } from 'common/components/Icons';
import { Breadcrumb } from 'types/Breadcrumbs';
import { useBreadcrumbsContext } from './BreadcrumbsContext';
import { Wrapper, BreadcrumbsItem, BreadcrumbsText, BreadcrumbsLink, Separator } from './styles';

interface BreadcrumbsProps {
  breadcrumbs?: Breadcrumb[];
}

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { breadcrumbs: breadcrumbsVal } = useBreadcrumbsContext();

  if (!(breadcrumbs || breadcrumbsVal || []).length) {
    return null;
  }

  return (
    <Wrapper>
      {(breadcrumbs || breadcrumbsVal || []).map((breadcrumb: Breadcrumb, index: number) => (
        <div key={breadcrumb.title ? breadcrumb.title + index : index}>
          {!breadcrumb.isActive && (
            <BreadcrumbsItem>
              <BreadcrumbsLink to={breadcrumb.url ? breadcrumb.url : '/'}>{breadcrumb.title}</BreadcrumbsLink>
              <Separator>
                <ArrowRightIcon width={6} height={10} />
              </Separator>
            </BreadcrumbsItem>
          )}
          {breadcrumb.isActive && (
            <BreadcrumbsItem>
              <BreadcrumbsText>{breadcrumb.title}</BreadcrumbsText>
            </BreadcrumbsItem>
          )}
        </div>
      ))}
    </Wrapper>
  );
};

export default Breadcrumbs;
