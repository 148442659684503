import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { clientsService } from 'common/api/clientsService';
import { integrationsService } from 'common/api/integrationsService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { ImportType } from 'types/Import';
import { RootState } from 'store/rootReducer';
import { ClientData } from 'types/Client';
import { FormSelectOption } from 'types/Form';
import { IntegrationsSystems, IntegrationSystemOptions } from 'types/Integrations';
import { getArrayOfOptionsValues, getAvailableStatuses, prepareSelectOptionsFromDictionaries } from 'utils';
import { TableSortOrder } from 'types/Tables';
import { Dictionaries, ErrorCodes } from 'common/constants';
import { createAdminApiClient } from 'hooks/usePaiClient';
import { FileParameter } from 'common/api/generated/PAIAdminApiClient';
import { actionCreators, ActionTypeKeys } from './clientsReducer';

export const getPage = (state: RootState) => state.clients.page;
export const getPerPage = (state: RootState) => state.clients.perPage;
export const getOrderBy = (state: RootState) => state.clients.orderBy;
export const getSortOrder = (state: RootState) => state.clients.sortOrder;
export const getFilterStatus = (state: RootState) => state.clients.filterStatus;
export const getFilterSystem = (state: RootState) => state.clients.filterSystem;
export const getFilterSearch = (state: RootState) => state.clients.filterSearch;
export const getClientStatusOptions = (state: RootState) =>
  prepareSelectOptionsFromDictionaries(state.shared.dictionaries[Dictionaries.CLIENT_STATUSES]);
export const getShowOnlyDeactivatedSystems = (state: RootState) => state.clients.showOnlyDeactivatedSystems;
export const getIsIncludeDeactivated = (state: RootState) => state.clients.isIncludeDeactivated;

export function* getClientsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClients, payload);

    const prepareData = {
      data: data.data.map((item: ClientData) => ({
        ...item,
        key: item.id,
      })),
      totalCount: data.totalCount,
    };

    yield put(actionCreators.getClientsSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientsFailed());
  }
}

export function* importFileSaga({ payload }: ActionWithPayload) {
  try {
    const { customerId, importType, importMode, integrationSystemId, accessToken } = payload;
    const currentPerPage: number = yield select(getPerPage);
    const currentOrderBy: string = yield select(getOrderBy);
    const currentSortOrder: TableSortOrder = yield select(getSortOrder);
    const currentFilterSearch: string = yield select(getFilterSearch);
    const currentFilterStatus: FormSelectOption[] = yield select(getFilterStatus);
    const currentFilterSystem: IntegrationSystemOptions[] = yield select(getFilterSystem);
    const currentShowOnlyDeactivatedSystems: boolean = yield select(getShowOnlyDeactivatedSystems);
    const currentIsIncludeDeactivated: boolean = yield select(getIsIncludeDeactivated);
    const currentClientStatusOptions: FormSelectOption[] = yield select(getClientStatusOptions);

    const requestParams = {
      customerId,
      importMode,
      integrationSystemId,
    };
    // eslint-disable-next-line
    let formData = new FormData();
    formData.append('file', payload.uploadFile);

    let responseData: any = null;

    const importApproverReferencesCall = ({
      customerId,
      file,
    }: {
      customerId: number;
      file: FileParameter | undefined;
    }) => createAdminApiClient(accessToken).importsControllerApproverReferencesImport(customerId, file);

    switch (importType) {
      case ImportType.IMPORT_CLIENTS:
        // eslint-disable-next-line no-lone-blocks
        {
          const { data: importClientResponse } = yield call(clientsService.importClientsFile, {
            accessToken,
            requestParams,
            formData,
          });
          responseData = importClientResponse;
        }
        break;
      case ImportType.IMPORT_INTEGRATIONS:
        // eslint-disable-next-line no-lone-blocks
        {
          const { data: importIntegrationsResponse } = yield call(clientsService.importIntegrationsFile, {
            accessToken,
            requestParams,
            formData,
          });
          responseData = importIntegrationsResponse;
        }
        break;
      case ImportType.IMPORT_APPROVER_REFERENCES:
        // eslint-disable-next-line no-lone-blocks
        {
          yield call(importApproverReferencesCall, {
            customerId: payload.customerId,
            file: { data: payload.uploadFile, fileName: payload.uploadFile.name },
          });
          responseData = 'Import has been completed successfully';
        }
        break;
      default:
        throw new Error(`Unknown import type ${importType}`);
    }

    yield put(actionCreators.importFileSuccess());
    // eslint-disable-next-line no-redeclare
    const { data: clients } = yield call(clientsService.getClients, {
      accessToken,
      customerId,
      page: 1,
      size: currentPerPage,
      orderBy: currentOrderBy,
      sortOrder: currentSortOrder,
      shouldContainDeactivatedSystem: currentShowOnlyDeactivatedSystems,
      statuses:
        currentFilterStatus[0].value === currentClientStatusOptions[0].value
          ? getAvailableStatuses(currentIsIncludeDeactivated, currentClientStatusOptions)
          : getArrayOfOptionsValues(currentFilterStatus),
      systems:
        currentFilterSystem[0].value === IntegrationsSystems.UNKNOWN
          ? undefined
          : getArrayOfOptionsValues(currentFilterSystem),
      search: currentFilterSearch,
    });

    const prepareData = {
      data: clients.data.map((item: ClientData) => ({
        ...item,
        key: item.id,
      })),
      totalCount: clients.totalCount,
    };

    yield put(actionCreators.getClientsSuccess(prepareData));
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        title: responseData,
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || (error as any).title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.importFileFailed());
  }
}

export function* activateMultipleClientsSaga({ payload }: ActionWithPayload) {
  try {
    const currentPage: number = yield select(getPage);
    const currentPerPage: number = yield select(getPerPage);
    const currentOrderBy: string = yield select(getOrderBy);
    const currentSortOrder: TableSortOrder = yield select(getSortOrder);
    const currentFilterSearch: string = yield select(getFilterSearch);
    const currentFilterStatus: FormSelectOption[] = yield select(getFilterStatus);
    const currentFilterSystem: IntegrationSystemOptions[] = yield select(getFilterSystem);
    const currentShowOnlyDeactivatedSystems: boolean = yield select(getShowOnlyDeactivatedSystems);
    const currentIsIncludeDeactivated: boolean = yield select(getIsIncludeDeactivated);
    const currentClientStatusOptions: FormSelectOption[] = yield select(getClientStatusOptions);

    yield all(
      payload.clientIds.map((id: number) =>
        call(clientsService.setClientStatus, {
          id: id,
          accessToken: payload.accessToken,
          customerId: payload.customerId,
          targetStatus: payload.targetStatus,
          statusReason: payload.statusReason,
        }),
      ),
    );

    yield put(actionCreators.activateMultipleClientsSuccess());

    yield put(
      actionCreators.getClients({
        accessToken: payload.accessToken,
        customerId: payload.customerId,
        page: currentPage,
        size: currentPerPage,
        orderBy: currentOrderBy,
        sortOrder: currentSortOrder,
        shouldContainDeactivatedSystem: currentShowOnlyDeactivatedSystems,
        statuses:
          currentFilterStatus[0].value === currentClientStatusOptions[0].value
            ? getAvailableStatuses(currentIsIncludeDeactivated, currentClientStatusOptions)
            : getArrayOfOptionsValues(currentFilterStatus),
        systems:
          currentFilterSystem[0].value === IntegrationsSystems.UNKNOWN
            ? undefined
            : getArrayOfOptionsValues(currentFilterSystem),
        search: currentFilterSearch,
      }),
    );
  } catch (error) {
    const currentPage: number = yield select(getPage);
    const currentPerPage: number = yield select(getPerPage);
    const currentOrderBy: string = yield select(getOrderBy);
    const currentSortOrder: TableSortOrder = yield select(getSortOrder);
    const currentFilterSearch: string = yield select(getFilterSearch);
    const currentFilterStatus: FormSelectOption[] = yield select(getFilterStatus);
    const currentFilterSystem: IntegrationSystemOptions[] = yield select(getFilterSystem);
    const currentShowOnlyDeactivatedSystems: boolean = yield select(getShowOnlyDeactivatedSystems);
    const currentIsIncludeDeactivated: boolean = yield select(getIsIncludeDeactivated);
    const currentClientStatusOptions: FormSelectOption[] = yield select(getClientStatusOptions);
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.activateMultipleClientsFailed());

    yield put(
      actionCreators.getClients({
        accessToken: payload.accessToken,
        customerId: payload.customerId,
        page: currentPage,
        size: currentPerPage,
        orderBy: currentOrderBy,
        sortOrder: currentSortOrder,
        shouldContainDeactivatedSystem: currentShowOnlyDeactivatedSystems,
        statuses:
          currentFilterStatus[0].value === currentClientStatusOptions[0].value
            ? getAvailableStatuses(currentIsIncludeDeactivated, currentClientStatusOptions)
            : getArrayOfOptionsValues(currentFilterStatus),
        systems:
          currentFilterSystem[0].value === IntegrationsSystems.UNKNOWN
            ? undefined
            : getArrayOfOptionsValues(currentFilterSystem),
        search: currentFilterSearch,
      }),
    );
  }
}

export function* getIntegrationsCsvFileHeadersSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getIntegrationsCsvFileHeaders, payload);

    yield put(actionCreators.getIntegrationsCsvFileHeadersSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    if (errorLocal?.response?.data?.status !== ErrorCodes.NOT_FOUND_SCHEMA) {
      yield put(
        notificationsActionCreators.addNotification({
          type: NotificationType.ERROR,
          messageId: 'error-messages-something-wrong',
          errorText: errorLocal,
          errorStatus: errorLocal?.response?.status,
        }),
      );
    } else {
      yield put(actionCreators.getIntegrationsCsvFileHeadersFailed(ErrorCodes.NOT_FOUND_SCHEMA));
    }
  }
}

export function* getClientConfigurationSchemaSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getClientConfigurationSchema, payload);

    yield put(actionCreators.getClientConfigurationSchemaSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    if (errorLocal?.response?.data?.status !== ErrorCodes.NOT_FOUND_SCHEMA) {
      yield put(
        notificationsActionCreators.addNotification({
          type: NotificationType.ERROR,
          messageId: 'error-messages-something-wrong',
          errorText: errorLocal?.response?.data?.title || error,
          errorStatus: errorLocal?.response?.status,
        }),
      );
    } else {
      yield put(actionCreators.getClientConfigurationSchemaFailed(ErrorCodes.NOT_FOUND_SCHEMA));
    }
  }
}

export function* clientsSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_CLIENTS, getClientsSaga),
    takeEvery(ActionTypeKeys.IMPORT_FILE, importFileSaga),
    takeEvery(ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS, activateMultipleClientsSaga),
    takeEvery(ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS, getIntegrationsCsvFileHeadersSaga),
    takeEvery(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA, getClientConfigurationSchemaSaga),
  ];
}
