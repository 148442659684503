import { useMemo } from 'react';
import { Message, Modal, Button } from 'common/components';
import { FormButtonsCentered, ModalText, ModalTextMark } from '../styles';

interface SyncMasterDataModalProps {
  isVisible: boolean;
  selectedClients: any[];
  onModalCancelHandler: () => void;
  onModalConfirmHandler: () => void;
}

const SyncMasterDataModal = ({
  isVisible,
  selectedClients,
  onModalCancelHandler,
  onModalConfirmHandler,
}: SyncMasterDataModalProps) => {
  const footerButtons = useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onModalCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button primary onClick={onModalConfirmHandler}>
          <Message id='customer-sync-master-data-sync-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onModalCancelHandler, onModalConfirmHandler],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      withScroll
      hideOnOverlayClicked={false}
      closeOnEsc={false}
      isVisible={isVisible}
      title={<Message id='customer-sync-master-data-modal-title' />}
      onCloseClicked={onModalCancelHandler}
      footer={footerButtons}
    >
      <h3>
        <Message id='customer-sync-master-data-modal-text' />
      </h3>
      <div>
        {selectedClients.map((client: any) => (
          <ModalText key={client.clientId}>
            {client.clientName} <ModalTextMark>{`(org.Number: ${client.organizationNumber})`}</ModalTextMark>
          </ModalText>
        ))}
      </div>
    </Modal>
  );
};

export default SyncMasterDataModal;
