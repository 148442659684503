import { useEffect } from 'react';
import { Message } from 'common/components/Message';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Head, Title } from './styles';
import getIntegrationsBreadcrumbs from './getIntegrationsBreadcrumbs';

const Integrations = () => {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  useEffect(() => {
    setBreadcrumbs(getIntegrationsBreadcrumbs());
    return () => setBreadcrumbs(null);
  }, [setBreadcrumbs]);

  return (
    <>
      <Head>
        <Title>
          <Message id={'integrations-screen-title'} />
        </Title>
      </Head>
    </>
  );
};

export default Integrations;
