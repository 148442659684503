import { useCallback, useState } from 'react';
import { useDropzone, ErrorCode } from 'react-dropzone';
import { Message } from 'common/components';
import { CSV_PATTERN } from 'common/constants';
import { Wrapper, DropzoneText, DropzoneMark, UploadErrorMessage } from './styles';

interface UploadOneFileDropzoneProps {
  allowedFileTypes?: string;
  allowedFileString?: string;
  onUpload: (payload: File) => void;
}

const UploadOneFileDropzone = ({
  allowedFileTypes = CSV_PATTERN,
  allowedFileString = '*.csv',
  onUpload,
}: UploadOneFileDropzoneProps) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [fileRejectedErrorCode, setFileRejectedErrorCode] = useState('');

  const onDrop = (acceptedFiles: any) => {
    setIsFileSelected(acceptedFiles.length > 0);
    setFileRejectedErrorCode('');
    onUpload(acceptedFiles);
  };

  const onDropRejected = useCallback((fileRejections) => {
    if (fileRejections.length > 1) {
      setFileRejectedErrorCode(ErrorCode.TooManyFiles);
      return;
    }

    const error = fileRejections[0] && fileRejections[0].errors.length ? fileRejections[0].errors[0].code : '';
    setFileRejectedErrorCode(error);
  }, []);

  const onDragEnter = (event: any) => {
    if (event.dataTransfer.items.length > 1) {
      setFileRejectedErrorCode(ErrorCode.TooManyFiles);
    }
    if (event.dataTransfer.items.length === 1) {
      const dropType = event.dataTransfer.items[0].type;
      if (allowedFileTypes) {
        if (!allowedFileTypes.includes(dropType)) {
          setFileRejectedErrorCode(ErrorCode.FileInvalidType);
        } else {
          setFileRejectedErrorCode('');
        }
      }
    }
  };

  const onDragLeave = () => {
    if (isFileSelected) {
      setFileRejectedErrorCode('');
    }
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected,
    multiple: false,
    accept: allowedFileTypes,
  });

  const renderBody = (isDragActive: boolean, isDragAccept: boolean, isDragReject: boolean) => {
    if (isDragReject) {
      if (fileRejectedErrorCode === ErrorCode.TooManyFiles) {
        return (
          <DropzoneText>
            <Message id='error-dropzone-too-many-files-selected' />
          </DropzoneText>
        );
      }

      return (
        <DropzoneText>
          <Message id='error-dropzone-this-type-doesnt-supported' />
        </DropzoneText>
      );
    }
    if (isDragActive) {
      return (
        <DropzoneText>
          <Message id='dropzone-drop-file-here' />
        </DropzoneText>
      );
    }
    if (isDragAccept) {
      return (
        <DropzoneText>
          <Message id='dropzone-drop-file-here' />
        </DropzoneText>
      );
    }
    if (acceptedFiles.length > 0) {
      return (
        <DropzoneText>
          <Message id='dropzone-selected-file' /> <DropzoneMark>{acceptedFiles[0].name}</DropzoneMark>
        </DropzoneText>
      );
    }

    return (
      <>
        <DropzoneText>
          <Message id='dropzone-drag-drop-some-file-here-or-click-to-select-file' />
        </DropzoneText>
        <DropzoneText>
          <Message
            id='error-dropzone-only-files-will-be-accepted'
            values={{
              variable: allowedFileString,
            }}
          />
        </DropzoneText>
      </>
    );
  };

  const renderErrorText = () => {
    if (fileRejectedErrorCode === ErrorCode.TooManyFiles) {
      return (
        <UploadErrorMessage isError={!!fileRejectedErrorCode}>
          <Message id='error-dropzone-too-many-files-selected' />
        </UploadErrorMessage>
      );
    }

    return (
      <UploadErrorMessage isError={!!fileRejectedErrorCode}>
        <Message
          id='error-dropzone-this-type-doesnt-supported-please-upload-correct'
          values={{
            variable: allowedFileString,
          }}
        />
      </UploadErrorMessage>
    );
  };

  return (
    <div>
      <Wrapper isFileSelected={isFileSelected} {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {renderBody(isDragActive, isDragAccept, isDragReject)}
      </Wrapper>
      {!!fileRejectedErrorCode && renderErrorText()}
    </div>
  );
};

export default UploadOneFileDropzone;
