import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/integrationsDefaultSettingsReducer';
import { getIntegrationsDefaultSettingsView } from './logic/integrationsDefaultSettingsSelector';
import IntegrationsDefaultSettings from './IntegrationsDefaultSettings';

const mapStateToProps = ({ shared, integrationsDefaultSettings }: RootState) =>
  getIntegrationsDefaultSettingsView(shared, integrationsDefaultSettings);

const mapDispatchToProps = {
  getConfigurationSchema: actionCreators.getConfigurationSchema,
  updateDefaultSettings: actionCreators.updateDefaultSettings,
  resetPartial: actionCreators.resetPartial,
  reset: actionCreators.resetIntegrationDefault,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationsDefaultSettings);
