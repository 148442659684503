import { integrationsServiceRequest } from 'config';
import { API_URLS } from 'common/constants';
import {
  ClientSystemsSettingsRequest,
  ConfigurationSchemaRequest,
  CreateClientIntegrationsRequestParams,
  CreateCustomerIntegrationsRequestParams,
  CustomerConfigurationSchemaRequest,
  CustomerIntegrationsRequestParams,
  CustomerSystemsSettingsRequest,
  DefaultSettingsRequestData,
  IntegrationRequest,
  IntegrationsCsvFileHeadersRequestData,
  SystemsSettingsRequest,
  UpdateIntegrationRequest,
} from 'types/Integrations';
import { AccessToken } from 'types/Auth';
import { setAuthHeaders } from 'utils/auth';

export const integrationsService = {
  getIntegrationById: ({ accessToken, integrationId }: IntegrationRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.integrationById(integrationId), {
      headers: setAuthHeaders(accessToken),
    }),
  updateIntegrationById: ({ accessToken, integrationId, requestData }: UpdateIntegrationRequest) =>
    integrationsServiceRequest.put(API_URLS.integrationsService.updateIntegrationById(integrationId), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  integrationSystems: (accessToken: AccessToken) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.integrationSystems, {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerIntegrations: ({ accessToken, customerId }: CustomerIntegrationsRequestParams) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.customerIntegrations(customerId), {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerClientsAllIntegrations: ({ accessToken, customerId }: CustomerIntegrationsRequestParams) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.customerClientsAllIntegrations(customerId), {
      headers: setAuthHeaders(accessToken),
    }),
  createCustomerIntegration: ({ accessToken, customerId, requestData }: CreateCustomerIntegrationsRequestParams) =>
    integrationsServiceRequest.post(API_URLS.integrationsService.createCustomerIntegrations(customerId), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientIntegrations: (accessToken: AccessToken, customerId: number, clientId: number) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.clientIntegrations(customerId, clientId), {
      headers: setAuthHeaders(accessToken),
    }),
  createClientIntegration: ({
    accessToken,
    customerId,
    clientId,
    requestData,
  }: CreateClientIntegrationsRequestParams) =>
    integrationsServiceRequest.post(
      API_URLS.integrationsService.createClientIntegrations(customerId, clientId),
      requestData,
      {
        headers: setAuthHeaders(accessToken),
      },
    ),
  getConfigurationSchema: ({ accessToken, systemId }: ConfigurationSchemaRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.systemSchema(systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerConfigurationSchema: ({ accessToken, systemId }: CustomerConfigurationSchemaRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.customerSystemSchema(systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  getClientConfigurationSchema: ({ accessToken, systemId }: ConfigurationSchemaRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.clientSystemSchema(systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  getSystemsSettings: ({ accessToken, systemId }: SystemsSettingsRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.systemSettings(systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  getCustomerSystemsSettings: ({ accessToken, customerId, systemId }: CustomerSystemsSettingsRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.customerSystemSettings(customerId, systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  getClientSystemsSettings: ({ accessToken, customerId, clientId, systemId }: ClientSystemsSettingsRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.clientSystemSettings(customerId, clientId, systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  updateIntegrationDefaultSettings: ({ accessToken, systemId, requestData }: DefaultSettingsRequestData) =>
    integrationsServiceRequest.put(
      API_URLS.integrationsService.updateIntegrationDefaultSettings(systemId),
      requestData,
      {
        headers: setAuthHeaders(accessToken),
      },
    ),
  getIntegrationsCsvFileHeaders: ({ accessToken, systemId }: IntegrationsCsvFileHeadersRequestData) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.getIntegrationsCsvFileHeaders(systemId), {
      headers: setAuthHeaders(accessToken),
    }),
  testConnection: ({ accessToken, integrationId }: IntegrationRequest) =>
    integrationsServiceRequest.get(API_URLS.integrationsService.testConnection(integrationId), {
      headers: setAuthHeaders(accessToken),
    }),
};
