import styled, { keyframes } from 'styled-components';
import { NotificationTypes } from 'types/Notifications';
import { defineNotificationBackground } from './utils';

interface NotificationWrapperType {
  isEmpty: boolean;
}

export const NotificationWrapper = styled.div<NotificationWrapperType>`
  position: fixed;
  top: 10px;
  right: 10px;
  left: 50%;
  transform: translateX(-50%);
  // max-width: 600px;
  min-width: 400px;
  z-index: 65000;
  // display: block;
  // min-height: 20px;
  // background: #ccc;
  // display: ${({ isEmpty }) => (isEmpty ? 'none' : 'block')};
`;

const SlideDown = keyframes`
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 0.95;
  }
`;

const SlideUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0.95;
  }

  100% {
    transform: translateY(-200%);
    opacity: 0;
  }
`;

interface NotificationItemType {
  type: NotificationTypes;
}

export const NotificationItem = styled.div<NotificationItemType>`
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 10px;
  color: ${({ theme }) => theme.notifications.color};
  background-color: ${({ theme, type }) => defineNotificationBackground(theme, type)};
  width: 550px;
  text-align: center;
  padding: 12px 35px 12px 15px;
  opacity: 0.95;

  &:hover {
    opacity: 1;
  }

  &.notification-enter {
    animation: ${SlideDown} 0.4s forwards;
  }
  &.notification-exit {
    animation: ${SlideUp} 0.4s forwards;
  }
`;

export const NotificationMessage = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin: 0;
`;

export const NotificationText = styled.span`
  display: block;
  font-size: 13px;
  line-height: 21px;
  margin: 0;
  padding-top: 10px;
`;

export const NotificationCloseButton = styled.span`
  position: absolute;
  right: 12px;
  width: 14px;
  height: 14px;
  top: 16px;

  &:hover {
    cursor: pointer;
  }
`;
