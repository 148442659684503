import { PropellLogoIntegrations } from 'common/components/Icons';
import { Breadcrumbs, UserData } from 'common/components';
import {
  HeaderActionItem,
  HeaderActions,
  HeaderBreadcrumbs,
  HeaderContent,
  HeaderWrapper,
  LogoContainer,
  LogoLink,
} from './styles';

interface HeaderProps {
  isSubNavExists: boolean;
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
}

const Header = ({ isSubNavExists, isMainNavCollapsed, isSubNavCollapsed }: HeaderProps) => {
  return (
    <>
      <HeaderWrapper>
        <LogoContainer
          isSubNavExists={isSubNavExists}
          isMainNavCollapsed={isMainNavCollapsed}
          isSubNavCollapsed={isSubNavCollapsed}
        >
          <LogoLink to='/'>
            <PropellLogoIntegrations />
          </LogoLink>
        </LogoContainer>
        <HeaderContent>
          <HeaderBreadcrumbs>
            <Breadcrumbs />
          </HeaderBreadcrumbs>
          <HeaderActions>
            <HeaderActionItem>
              <UserData />
            </HeaderActionItem>
          </HeaderActions>
        </HeaderContent>
      </HeaderWrapper>
    </>
  );
};

export default Header;
