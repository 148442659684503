import styled from 'styled-components';
import { DrawerPlacement, DrawerPlacementType } from 'types/Drawer';

interface DrawerWrapperType {
  isVisible: boolean;
  showOverlay?: boolean;
  placement: DrawerPlacementType;
}

export const DrawerWrapper = styled.div<DrawerWrapperType>`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: ${({ showOverlay }) => (showOverlay ? 'hidden' : 'auto')};
  -webkit-overflow-scrolling: touch;
  padding: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: stretch;
  justify-content: ${({ placement }) => (placement === DrawerPlacement.LEFT ? 'flex-start' : 'flex-end')};
`;

interface DrawerOverlayType {
  isVisible: boolean;
}

export const DrawerOverlay = styled.div<DrawerOverlayType>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: ${({ theme }) => theme.backgroundOverlay};
  overflow: auto;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  min-width: 320px;

  // &:after {
  //   display: inline-block;
  //   height: 100%;
  //   margin-left: -0.05em;
  //   content: "";
  //   vertical-align: middle;
  // }
`;

export const DrawerContainer = styled.div`
  width: 484px;
  background: ${({ theme }) => theme.backgroundPrimary};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DrawerScrollBox = styled.div`
  height: calc(100vh - 56px);
  overflow: auto;
`;

export const DrawerScrollBoxInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

interface DrawerHeaderType {
  placement: DrawerPlacementType;
}

export const DrawerHeader = styled.header<DrawerHeaderType>`
  min-height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.borders.grey};
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  flex-direction: ${({ placement }) => (placement === DrawerPlacement.LEFT ? 'row-reverse' : 'row')};
  justify-content: ${({ placement }) => (placement === DrawerPlacement.LEFT ? 'space-between' : 'flex-start')};
`;

export const DrawerHeaderButton = styled.div`
  margin-right: 16px;
`;

export const DrawerHeaderTitle = styled.h2`
  font-size: 26px;
  line-height: 40px;
  margin: 0;
  font-weight: 400;
`;

export const DrawerBody = styled.div`
  display: flex;
  flex: 1;
  padding: 16px 16px 0;
`;

export const DrawerFooter = styled.footer`
  padding: 26px;
  box-shadow: 0 -2px 10px ${({ theme }) => theme.boxShadow};
`;
