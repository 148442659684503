import { useEffect, useState } from 'react';
import { Message, Select } from 'common/components';
import {
  ConfigurationSchemaRequest,
  IntegrationData,
  IntegrationSchema,
  IntegrationsCsvFileHeadersRequestData,
  IntegrationsSystems,
  IntegrationSystemOptions,
} from 'types/Integrations';
import { ImportMode, ImportType } from 'types/Import';
import { useAccessToken } from 'hooks';
import ImportIntegrationsForm from './ImportIntegrationsForm';
import {
  Content,
  ContentColumnInner,
  ContentColumnLeft,
  ContentColumnRight,
  UploadInfo,
  UploadInfoTitle,
} from './styles';

interface ImportIntegrationsProps {
  isFetching: boolean;
  availableSystems: IntegrationSystemOptions[];
  csvHeaders: any;
  customerIntegrations: IntegrationData[];
  errorCode: null | number;
  integrationSchema: IntegrationSchema;
  onFileUpload: (payload: File) => void;
  setImportType: (payload: null | ImportType) => void;
  setImportMode: (payload: ImportMode) => void;
  getIntegrationsCsvFileHeaders: (payload: IntegrationsCsvFileHeadersRequestData) => void;
  resetUpload: () => void;
  setIntegrationSystemId: (payload: string) => void;
  getClientConfigurationSchema: (payload: ConfigurationSchemaRequest) => void;
}

const ImportIntegrations = ({
  isFetching,
  errorCode,
  availableSystems,
  csvHeaders,
  customerIntegrations,
  integrationSchema,
  onFileUpload,
  setImportType,
  setImportMode,
  resetUpload,
  getIntegrationsCsvFileHeaders,
  setIntegrationSystemId,
  getClientConfigurationSchema,
}: ImportIntegrationsProps) => {
  const accessToken = useAccessToken();
  const [currentSystem, setCurrentSystem] = useState({ id: 0, value: IntegrationsSystems.UNKNOWN, label: '' });

  useEffect(() => {
    setImportType(ImportType.IMPORT_INTEGRATIONS);
    return () => resetUpload();
  }, [setImportType, resetUpload]);

  const onSystemChangeHandler = (event: any) => {
    resetUpload();
    setCurrentSystem(event);
    setImportType(ImportType.IMPORT_INTEGRATIONS);

    getClientConfigurationSchema({
      accessToken,
      systemId: event.id,
      isCreatePage: false,
    });

    getIntegrationsCsvFileHeaders({
      accessToken,
      systemId: event.id,
    });

    setIntegrationSystemId(event.id);
  };

  const onImportModeChangeHandler = (event: any) => {
    setImportMode(event.value);
  };

  const renderForm = () => {
    if (currentSystem.value === IntegrationsSystems.UNKNOWN) {
      return (
        <UploadInfo>
          <UploadInfoTitle>
            <Message id='clients-screen-import-please-select-the-system' />
          </UploadInfoTitle>
        </UploadInfo>
      );
    }

    return (
      <ImportIntegrationsForm
        errorCode={errorCode}
        integrationSchema={integrationSchema}
        customerIntegrations={customerIntegrations}
        isFetching={isFetching}
        csvHeaders={csvHeaders}
        system={currentSystem.value}
        onImportModeChange={onImportModeChangeHandler}
        onFileUpload={onFileUpload}
      />
    );
  };

  return (
    <Content>
      <ContentColumnLeft>
        <Select
          isRequired
          id='systemName'
          options={availableSystems}
          label={<Message id={'client-integrations-system-name-label'} />}
          onChange={(e) => onSystemChangeHandler(e)}
          isError={false}
          errorText={''}
        />
      </ContentColumnLeft>
      <ContentColumnRight>
        <ContentColumnInner>{renderForm()}</ContentColumnInner>
      </ContentColumnRight>
    </Content>
  );
};

export default ImportIntegrations;
