import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: 0 0 5px;
  margin-bottom: -5px;
  z-index: 2;
`;

export const ButtonWrapper = styled.div`
  width: 120px;
`;

export const ButtonDropDown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border-radius: 4px;
  background: ${({ theme }) => theme.backgroundSecondary};
  box-shadow: 0 2px 10px ${({ theme }) => theme.boxShadow};
`;

export const ButtonDropDownList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ButtonDropDownListItem = styled.li`
  font-size: 12px;
  padding: 10px 10px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.backgroundThird};
  }
`;
