import styled from 'styled-components';

export const AlertModalContent = styled.div`
  font-sie: 18px;
`;

export const AlertModalFooter = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    min-width: 100px;
    margin: 0 10px;
  }
`;
