import { Tooltip } from 'antd';
import './pai-tooltip.css';

// @ts-ignore
// eslint-disable-next-line react/prop-types
const StyledTooltip = ({ className, children, ...restProps }) => (
  <Tooltip overlayClassName={className} {...restProps}>
    {children}
  </Tooltip>
);

export default StyledTooltip;
