import { Navigation } from 'common/components/Navigation';
import { GENERAL_NAVIGATION_ITEMS, SETTINGS_NAVIGATION_ITEMS, MONITORING_NAVIGATION_ITEMS } from './constants';
import { MainNavigationWrapper } from './styles';

interface MainNavigationProps {
  isMainNavCollapsed?: boolean;
  isSubNavExists: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  navGeneralLabels: {};
}

const MainNavigation = ({ isMainNavCollapsed, isSubNavExists, navGeneralLabels }: MainNavigationProps) => {
  return (
    <MainNavigationWrapper>
      <Navigation
        isDarkTheme={isSubNavExists}
        isMainNavCollapsed={isMainNavCollapsed}
        navTitle={'main-nav-general-title'}
        navItems={GENERAL_NAVIGATION_ITEMS}
        navLabels={navGeneralLabels}
      />
      <Navigation
        isDarkTheme={isSubNavExists}
        isMainNavCollapsed={isMainNavCollapsed}
        navTitle={'main-nav-monitoring-title'}
        navItems={MONITORING_NAVIGATION_ITEMS}
      />
      <Navigation
        isDarkTheme={isSubNavExists}
        isMainNavCollapsed={isMainNavCollapsed}
        navTitle={'main-nav-settings-title'}
        navItems={SETTINGS_NAVIGATION_ITEMS}
      />
    </MainNavigationWrapper>
  );
};

export default MainNavigation;
