import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectHolder = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const TextLeftIcon = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.light};
  box-shadow: 0 8px 8px rgba(31, 47, 77, 0.1);
  border-radius: 8px;
  margin-right: 16px;
`;

export const getLabelColor = ({ theme, isError, isDisabled }: any) => {
  if (isError) {
    return theme.fontError;
  }
  if (isDisabled) {
    return theme.fontSecondary;
  }
  return theme.fontPrimary;
};

interface LabelProps {
  isInputEmpty?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-size: 12px;
  line-height: 24px;
  pointer-events: none;
  position: relative;
  color: ${({ theme, isDisabled, isInputEmpty, isError }) =>
    getLabelColor({ theme, isDisabled, isInputEmpty, isError })};
  transition: font-size 0.2s, transform 0.2s;
  will-change: transform, font-size;
`;

interface SelectWrapProps {
  withAdditionButton?: boolean;
  isMulti?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
}

/* eslint-disable */
export const SelectWrap = styled.div<SelectWrapProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: ${({ withAdditionButton }) => (withAdditionButton ? '15px' : 0)};
  padding-bottom: 0;

  &:hover ${Label} {
    color: ${({ theme, isError, isDisabled }) =>
      isDisabled ? theme.fontSecondary : isError ? theme.fontError : theme.fontPrimary};
  }
`;
/* eslint-enable */

interface TextErrorMessageProps {
  isError?: boolean;
}

export const TextErrorMessage = styled.p<TextErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;

export const SelectButtonWrapper = styled.div`
  margin-right: -15px;
`;

export const SelectHint = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.fontSecondary};
  padding-top: 8px;
`;

export const MultiValueWrapper = styled.span`
  max-width: 220px;
`;
