import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useDebouncedCallback } from 'use-debounce';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Button, DataTable, DatePicker, Input, Message, Paginator, Select, Spinner, Switcher } from 'common/components';
import { FormSelectOption } from 'types/Form';
import { defaultFilterOptions } from 'common/constants';
import { OperationDataExtended, OperationsRequestData } from 'types/Operations';
import { TableDateField, TablePerPage, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import {
  getArrayOfOptionsIds,
  getArrayOfOptionsValues,
  getSelectedMultiOptions,
  getDateFromErrorMessage,
  getDateToErrorMessage,
  increaseDateTo,
} from 'utils';
import { CalendarIcon, RefreshIcon } from 'common/components/Icons';
import { useAccessToken, useAuthenticatedAdminApiClient, useSearchInputFocus, useUpdateSelection } from 'hooks';
import { CustomersOptionsRequestData } from 'types/Customer';
import { ClientsOptionsRequestData } from 'types/Client';
import { IntegrationSystemOptions } from 'types/Integrations';
import { ColumnItem } from 'common/components/DataTable/types';
import { callRemoteApiSafely } from 'hooks/useRemoteApiCall';
import {
  FULL_DATE_RANGE_DATE_FORMAT,
  DATEPICKER_TIME_FORMAT as timeFormat,
  DATEPICKER_TIME_INTERVAL as timeIntervals,
} from 'common/constants/DateTimeFormats';
import { buildOperationsGridColumns, GridType } from 'components/OperationsGrid/operationsTableColumns';
import { retryInvoiceExport } from 'components/OperationsGrid/retryInvoiceExport';
import getOperationsAllBreadcrumbs from './getOperationsAllBreadcrumbs';
import {
  Head,
  Title,
  FiltersWrapper,
  Wrapper,
  FiltersLeft,
  FiltersRight,
  FiltersColumn,
  TableWrapper,
  FiltersColumnsWrapper,
  FiltersColumnWide,
  FiltersColumnDateRange,
  FilterRow,
  FilterRowColumn,
} from './styles';

interface OperationsAllProps {
  isFetching: boolean;
  showOnlyDeactivatedSystems: boolean;
  operations: OperationDataExtended[];
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterSourceEntityId: string;
  filterTargetEntityId: string;
  filterScope: FormSelectOption[];
  filterType: FormSelectOption[];
  filterStatus: FormSelectOption[];
  filterSystem: IntegrationSystemOptions[];
  filterCustomerName: FormSelectOption[];
  filterClientName: FormSelectOption[];
  filterDateFrom: null | Date;
  filterDateTo: null | Date;
  customersOptions: FormSelectOption[];
  clientsOptions: FormSelectOption[];
  connectedSystemsOptions: IntegrationSystemOptions[];
  operationsScopeOptions: FormSelectOption[];
  operationsTypeOptions: FormSelectOption[];
  operationsStatusOptions: FormSelectOption[];
  getOperations: (payload: OperationsRequestData) => void;
  setOperationsTablePage: (payload: number) => void;
  setOperationsTablePerPage: (payload: number) => void;
  setOperationsTableOrder: (payload: TablesSortOrderPayload) => void;
  setFilterOperationScope: (payload: FormSelectOption[]) => void;
  setFilterOperation: (payload: FormSelectOption[]) => void;
  setFilterStatus: (payload: FormSelectOption[]) => void;
  setFilterSystem: (payload: IntegrationSystemOptions[]) => void;
  setFilterCustomerName: (payload: FormSelectOption[]) => void;
  setFilterClientName: (payload: FormSelectOption[]) => void;
  setFilterDateFrom: (payload: null | Date) => void;
  setFilterDateTo: (payload: null | Date) => void;
  setFilterSourceEntityId: (payload: string) => void;
  setFilterTargetEntityId: (payload: string) => void;
  setShowOnlyDeactivatedSystems: (payload: boolean) => void;
  getCustomersOptions: (payload: CustomersOptionsRequestData) => void;
  getClientsOptions: (payload: ClientsOptionsRequestData) => void;
  reset: () => void;
}

const OperationsAll = ({
  isFetching,
  showOnlyDeactivatedSystems,
  operations,
  totalCount,
  page,
  perPage,
  sortOrder,
  orderBy,
  filterSourceEntityId,
  filterTargetEntityId,
  filterScope,
  filterType,
  filterStatus,
  filterSystem,
  filterCustomerName,
  filterClientName,
  filterDateFrom,
  filterDateTo,
  customersOptions,
  clientsOptions,
  connectedSystemsOptions,
  operationsScopeOptions,
  operationsTypeOptions,
  operationsStatusOptions,
  getOperations,
  setOperationsTablePage,
  setOperationsTablePerPage,
  setOperationsTableOrder,
  setFilterOperationScope,
  setFilterOperation,
  setFilterStatus,
  setFilterSystem,
  setFilterCustomerName,
  setFilterClientName,
  setFilterDateFrom,
  setFilterDateTo,
  setShowOnlyDeactivatedSystems,
  setFilterSourceEntityId,
  setFilterTargetEntityId,
  getCustomersOptions,
  getClientsOptions,
  reset,
}: OperationsAllProps) => {
  const accessToken = useAccessToken();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isFiltersDisabled, setFiltersDisabled] = useState(true);
  const [setIsInputSourceEntityIdChanged, inputSourceEntityId, setInputSourceEntityIdFocus] = useSearchInputFocus();
  const [setIsInputTargetEntityIdChanged, inputTargetEntityId, setInputTargetEntityIdFocus] = useSearchInputFocus();
  const requestData = {
    accessToken,
    page: 1,
    size: Number(perPage),
    orderBy: orderBy,
    sortOrder: sortOrder,
    shouldContainDeactivatedSystem: showOnlyDeactivatedSystems,
    operationScopes:
      filterScope[0].value === operationsScopeOptions[0].value ? undefined : getArrayOfOptionsValues(filterScope),
    operations:
      filterType[0].value === operationsTypeOptions[0].value ? undefined : getArrayOfOptionsValues(filterType),
    statuses:
      filterStatus[0].value === operationsStatusOptions[0].value ? undefined : getArrayOfOptionsValues(filterStatus),
    systems:
      filterSystem[0].value === connectedSystemsOptions[0].value ? undefined : getArrayOfOptionsValues(filterSystem),
    customerIds: getArrayOfOptionsIds(filterCustomerName),
    clientIds: getArrayOfOptionsIds(filterClientName),
    startedDate: filterDateFrom ? filterDateFrom.toISOString() : undefined,
    finishedDate: filterDateTo ? increaseDateTo(filterDateTo).toISOString() : undefined,
    sourceEntityId: filterSourceEntityId ? filterSourceEntityId : undefined,
    targetEntityId: filterTargetEntityId ? filterTargetEntityId : undefined,
  };
  useUpdateSelection();

  useEffect(() => {
    setInputSourceEntityIdFocus();
  }, [isFetching, setInputSourceEntityIdFocus]);

  useEffect(() => {
    setInputTargetEntityIdFocus();
  }, [isFetching, setInputTargetEntityIdFocus]);

  useEffect(() => {
    setBreadcrumbs(getOperationsAllBreadcrumbs());
    return () => setBreadcrumbs(null);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (accessToken) {
      getClientsOptions({
        accessToken,
        orderBy: TableDateField.CREATED_ON,
        sortOrder: TableSortOrder.DESCENDING,
      });
      getCustomersOptions({
        accessToken,
        orderBy: TableDateField.CREATED_ON,
        sortOrder: TableSortOrder.DESCENDING,
      });
    }
  }, [accessToken, getClientsOptions, getCustomersOptions]);

  useEffect(() => {
    if (accessToken) {
      getOperations({
        accessToken,
        page: page,
        size: perPage,
        orderBy: orderBy,
        sortOrder: sortOrder,
        shouldContainDeactivatedSystem: showOnlyDeactivatedSystems,
      });
    }

    return () => reset();
  }, [accessToken, getOperations, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFiltersDisabled(
      isFetching ||
        (!totalCount &&
          filterStatus[0].value === operationsStatusOptions[0].value &&
          filterScope[0].value === operationsScopeOptions[0].value &&
          filterType[0].value === operationsTypeOptions[0].value &&
          filterSystem[0].value === connectedSystemsOptions[0].value &&
          filterCustomerName[0].value === defaultFilterOptions.value &&
          filterClientName[0].value === defaultFilterOptions.value),
    );
  }, [
    isFetching,
    totalCount,
    filterStatus,
    filterScope,
    filterType,
    filterSystem,
    filterClientName,
    filterCustomerName,
    connectedSystemsOptions,
    operationsScopeOptions,
    operationsTypeOptions,
    operationsStatusOptions,
  ]);

  const handleOnPageChange = (page: { selected: number }) => {
    setOperationsTablePage(page.selected);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      page: page.selected,
    });
  };

  const handleOnItemsPerPageChange = (perPage: TablePerPage) => {
    setOperationsTablePage(1);
    setOperationsTablePerPage(Number(perPage.value));
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      size: Number(perPage.value),
    });
  };

  const handleTableSortChange = (name: string, direction: TableSortOrder) => {
    setOperationsTableOrder({ orderBy: name, sortOrder: direction });
    setOperationsTablePage(1);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      orderBy: name,
      sortOrder: direction,
    });
  };

  const handleTableFilterOperationScopeChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    const selectedOptions = getSelectedMultiOptions(event, operationsScopeOptions, setFilterOperationScope);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      operationScopes:
        selectedOptions[0].value === operationsScopeOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const handleTableFilterOperationChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    const selectedOptions = getSelectedMultiOptions(event, operationsTypeOptions, setFilterOperation);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      operations:
        selectedOptions[0].value === operationsTypeOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const handleTableFilterStatusChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    const selectedOptions = getSelectedMultiOptions(event, operationsStatusOptions, setFilterStatus);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      statuses:
        selectedOptions[0].value === operationsStatusOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const handleTableFilterSystemChange = (event: IntegrationSystemOptions[]) => {
    setOperationsTablePage(1);
    const selectedOptions = getSelectedMultiOptions(event, connectedSystemsOptions, setFilterSystem);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      systems:
        selectedOptions[0].value === connectedSystemsOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const debouncedFilterBySourceEntityId = useDebouncedCallback((value: string) => {
    getOperations({
      ...requestData,
      sourceEntityId: value ? value : undefined,
    });
  }, 500);

  const handleFilterBySourceEntityId = (value: string) => {
    if (value !== filterSourceEntityId) {
      setIsInputSourceEntityIdChanged(true);
      setIsInputTargetEntityIdChanged(false);
    }
    setFilterSourceEntityId(value);

    if (value.trim().length !== 0 || filterSourceEntityId.trim().length) {
      debouncedFilterBySourceEntityId(value);
      setOperationsTablePage(1);
    }
  };

  const debouncedFilterByTargetEntityId = useDebouncedCallback((value: string) => {
    getOperations({
      ...requestData,
      targetEntityId: value ? value : undefined,
    });
  }, 500);

  const handleFilterByTargetEntityId = (value: string) => {
    if (value !== filterTargetEntityId) {
      setIsInputTargetEntityIdChanged(true);
      setIsInputSourceEntityIdChanged(false);
    }
    setFilterTargetEntityId(value);

    if (value.trim().length !== 0 || filterTargetEntityId.trim().length) {
      debouncedFilterByTargetEntityId(value);
      setOperationsTablePage(1);
    }
  };

  const handleTableFilterCustomerNameChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    const selectedOptions = getSelectedMultiOptions(event, [defaultFilterOptions], setFilterCustomerName);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      customerIds: getArrayOfOptionsIds(selectedOptions),
    });
  };

  const handleTableFilterClientNameChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    const selectedOptions = getSelectedMultiOptions(event, [defaultFilterOptions], setFilterClientName);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      clientIds: getArrayOfOptionsIds(selectedOptions),
    });
  };

  const onRefreshButtonClick = () => {
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      page: page,
    });
  };

  const dateFromErrorMessage = getDateFromErrorMessage(filterDateFrom, filterDateTo);
  const dateToErrorMessage = getDateToErrorMessage(filterDateFrom, filterDateTo);

  const handleDateFromChange = (date: null | Date) => {
    setFilterDateFrom(date);
    const isFromDateValid = !getDateFromErrorMessage(date, filterDateTo);

    if (date === null && isFromDateValid) {
      setIsInputSourceEntityIdChanged(false);
      setIsInputTargetEntityIdChanged(false);
      getOperations({
        ...requestData,
        startedDate: undefined,
      });
      setOperationsTablePage(1);
    }
  };

  const handleDateFromOnClose = () => {
    const isToDateValid = !getDateToErrorMessage(filterDateFrom, filterDateTo);

    if (filterDateFrom !== null && isToDateValid) {
      setIsInputSourceEntityIdChanged(false);
      setIsInputTargetEntityIdChanged(false);
      getOperations({
        ...requestData,
      });
      setOperationsTablePage(1);
    }
  };

  const handleDateToChange = (date: null | Date) => {
    setFilterDateTo(date);
    const isToDateValid = !getDateToErrorMessage(filterDateFrom, date);

    if (date === null && isToDateValid) {
      setIsInputSourceEntityIdChanged(false);
      setIsInputTargetEntityIdChanged(false);
      getOperations({
        ...requestData,
        finishedDate: undefined,
      });
      setOperationsTablePage(1);
    }
  };

  const handleDateToOnClose = () => {
    const isToDateValid = !getDateToErrorMessage(filterDateFrom, filterDateTo);

    if (filterDateTo !== null && isToDateValid) {
      setIsInputSourceEntityIdChanged(false);
      setIsInputTargetEntityIdChanged(false);
      getOperations({
        ...requestData,
      });
      setOperationsTablePage(1);
    }
  };

  const onShowOnlyDeactivatedSystemsChange = (event: any) => {
    setShowOnlyDeactivatedSystems(event.target.checked);
    setOperationsTablePage(1);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      shouldContainDeactivatedSystem: event.target.checked,
    });
  };

  const paiClient = useAuthenticatedAdminApiClient();
  // We ought to use dispatcher to combine hooks with redux store
  const errorDispatcher: Dispatch = useDispatch();
  const gridColumns: ColumnItem[] = buildOperationsGridColumns({
    gridType: GridType.CUSTOMER_OPERATIONS,
    onRetryButtonClick: (payload) => {
      callRemoteApiSafely(errorDispatcher, () => retryInvoiceExport(paiClient, payload.syncId));
    },
  });

  return (
    <>
      <Head>
        <Title>
          <Message id='customer-operations-screen-operation-status-title' />
        </Title>
      </Head>
      <Wrapper>
        <FiltersWrapper>
          <FilterRow>
            <FiltersLeft>
              <FiltersColumnsWrapper>
                <FiltersColumn>
                  <Select
                    isMulti
                    isClearable={false}
                    id='filterScope'
                    value={filterScope}
                    options={operationsScopeOptions}
                    label={<Message id={'operations-table-filter-operation-scope'} />}
                    onChange={handleTableFilterOperationScopeChange}
                    disabled={isFiltersDisabled}
                  />
                </FiltersColumn>
                <FiltersColumn>
                  <Select
                    isMulti
                    isClearable={false}
                    id='filterType'
                    value={filterType}
                    options={operationsTypeOptions}
                    label={<Message id={'operations-table-filter-operation'} />}
                    onChange={handleTableFilterOperationChange}
                    disabled={isFiltersDisabled}
                  />
                </FiltersColumn>
                <FiltersColumn>
                  <Select
                    isMulti
                    isClearable={false}
                    id='filterOperationsStatus'
                    value={filterStatus}
                    options={operationsStatusOptions}
                    label={<Message id={'operations-table-filter-status'} />}
                    onChange={handleTableFilterStatusChange}
                    disabled={isFiltersDisabled}
                  />
                </FiltersColumn>
                <FiltersColumn>
                  <Select
                    value={filterSystem}
                    options={connectedSystemsOptions}
                    label={<Message id='operations-table-filter-connected-systems' />}
                    onChange={handleTableFilterSystemChange}
                    isMulti
                    isClearable={false}
                    disabled={isFiltersDisabled}
                  />
                </FiltersColumn>
              </FiltersColumnsWrapper>
              <FiltersColumnsWrapper>
                <FiltersColumn>
                  <Input
                    inputRef={inputSourceEntityId}
                    disabled={isFetching}
                    id='filterSourceEntityId'
                    type='text'
                    label={<Message id='operations-table-filter-source-entity-id' />}
                    value={filterSourceEntityId}
                    onChange={handleFilterBySourceEntityId}
                    placeholder='Source Entity Id'
                  />
                </FiltersColumn>
                <FiltersColumn>
                  <Input
                    inputRef={inputTargetEntityId}
                    disabled={isFetching}
                    id='filterTargetEntityId'
                    type='text'
                    label={<Message id='operations-table-filter-target-entity-id' />}
                    value={filterTargetEntityId}
                    onChange={handleFilterByTargetEntityId}
                    placeholder='Target Entity Id'
                  />
                </FiltersColumn>
                <FiltersColumnDateRange>
                  <DatePicker
                    date={filterDateFrom}
                    withMonthYearSelect
                    isError={!!dateFromErrorMessage}
                    dateFormat={FULL_DATE_RANGE_DATE_FORMAT}
                    showTimeSelect={true}
                    timeFormat={timeFormat}
                    timeIntervals={timeIntervals}
                    leftIcon={<CalendarIcon />}
                    label={<Message id={'settings-select-date-from'} />}
                    errorText={<Message id={dateFromErrorMessage} />}
                    startDate={filterDateFrom}
                    maxDate={filterDateTo ? filterDateTo : new Date()}
                    onChange={handleDateFromChange}
                    popperPlacement='bottom-start'
                    isDisabled={isFetching}
                    isClearable={!!filterDateFrom}
                    requestOnClose={handleDateFromOnClose}
                  >
                    Select date range
                  </DatePicker>
                </FiltersColumnDateRange>
                <FiltersColumnDateRange>
                  <DatePicker
                    date={filterDateTo}
                    withMonthYearSelect
                    isError={!!dateToErrorMessage}
                    dateFormat={FULL_DATE_RANGE_DATE_FORMAT}
                    showTimeSelect={true}
                    timeFormat={timeFormat}
                    timeIntervals={timeIntervals}
                    leftIcon={<CalendarIcon />}
                    label={<Message id={'settings-select-date-to'} />}
                    errorText={<Message id={dateToErrorMessage} />}
                    startDate={filterDateTo}
                    maxDate={new Date()}
                    onChange={handleDateToChange}
                    popperPlacement='bottom-start'
                    isDisabled={isFetching}
                    isClearable={!!filterDateTo}
                    requestOnClose={handleDateToOnClose}
                  >
                    Select date range
                  </DatePicker>
                </FiltersColumnDateRange>
              </FiltersColumnsWrapper>
              <FiltersColumnsWrapper>
                <FiltersColumnWide>
                  <Select
                    id='filterCustomerName'
                    value={filterCustomerName}
                    options={customersOptions}
                    label={<Message id={'operations-table-filter-customer-name'} />}
                    onChange={handleTableFilterCustomerNameChange}
                    disabled={isFiltersDisabled}
                    isMulti={true}
                    isClearable={false}
                  />
                </FiltersColumnWide>
                <FiltersColumnWide>
                  <Select
                    id='filterClientName'
                    value={filterClientName}
                    options={clientsOptions}
                    label={<Message id={'operations-table-filter-client-name'} />}
                    onChange={handleTableFilterClientNameChange}
                    disabled={isFiltersDisabled}
                    isMulti={true}
                    isClearable={false}
                  />
                </FiltersColumnWide>
              </FiltersColumnsWrapper>
            </FiltersLeft>
            <FiltersRight>
              <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
                <RefreshIcon width={20} height={20} />
              </Button>
            </FiltersRight>
          </FilterRow>
          <FilterRow>
            <FiltersLeft>
              <FilterRowColumn>
                <Switcher
                  text={<Message id='customers-screen-show-deactivated-systems' />}
                  tooltipText={
                    <Message id='customers-screen-this-toggle-shows-only-entities-with-deactivated-systems' />
                  }
                  leftLabel='No'
                  rightLabel='Yes'
                  isChecked={showOnlyDeactivatedSystems}
                  onChange={onShowOnlyDeactivatedSystemsChange}
                />
              </FilterRowColumn>
            </FiltersLeft>
          </FilterRow>
        </FiltersWrapper>
        <TableWrapper>
          <DataTable
            isFetching={isFetching}
            data={operations}
            loadingComponent={Spinner}
            columns={gridColumns}
            emptyMessage={<Message id='no-data-message' />}
            orderBy={orderBy}
            sortOrder={sortOrder}
            onSortChange={handleTableSortChange}
          />
          <Paginator
            totalCount={totalCount}
            page={page}
            perPage={perPage}
            onItemsPerPageChange={handleOnItemsPerPageChange}
            onPageChange={handleOnPageChange}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default OperationsAll;
