import { ReactNode, useEffect, useState } from 'react';
import { Message, Spinner } from 'common/components';
import { ColumnItem } from '../types';
import { DataTable } from '../index';
import BodyCell from './BodyCell';
import { TableCollapseContent, TableRowOpener, Tr, TrCollapse, Td, TdCollapse } from './styles';

interface BodyRowProps {
  collapsedTable?: boolean;
  nestedTable?: boolean;
  collapsedFlag?: string;
  collapsedColumns?: any;
  collapseComponent?: ReactNode;
  rowIndex: number;
  rowData: any;
  columns: ColumnItem[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  rowCallbacks?: {};
  collapsedCallback?: (payload: any) => void;
}

const BodyRow = ({
  collapsedTable,
  nestedTable,
  collapsedFlag,
  collapsedColumns,
  collapsedCallback,
  rowIndex,
  rowData,
  columns,
  rowCallbacks,
}: BodyRowProps) => {
  const [collapseRow, setCollapseRow] = useState(true);

  useEffect(() => {
    return () => setCollapseRow(true);
  }, [rowData]);

  // todo: needed for row click
  const getRowCallbacks = () => {
    if (!rowCallbacks) {
      return {};
    }
    const callbacks = {};
    Object.keys(rowCallbacks).forEach((funcName: string) => {
      // @ts-expect-error: Element implicitly has an 'any' type
      callbacks[funcName] = (event: any) => {
        // @ts-expect-error: Element implicitly has an 'any' type
        rowCallbacks[funcName](event, rowData);
      };
    });
    return callbacks;
  };

  const onTableRowOpenerClick = () => {
    if (typeof collapsedCallback === 'function' && collapseRow) {
      collapsedCallback(rowData);
    }
    setCollapseRow(!collapseRow);
  };

  return (
    <>
      <Tr key={rowData.id} {...getRowCallbacks()} collapse={collapseRow}>
        {columns.map((column: ColumnItem) => (
          <BodyCell
            nestedTable={nestedTable}
            collapse={collapseRow}
            key={column.name}
            rowIndex={rowIndex}
            rowData={rowData}
            {...column}
          />
        ))}
        {collapsedTable && collapsedFlag && (
          <Td collapse={collapseRow}>
            {rowData[collapsedFlag] ? (
              <TableRowOpener onClick={onTableRowOpenerClick}>{collapseRow ? '+' : '-'}</TableRowOpener>
            ) : null}
          </Td>
        )}
      </Tr>
      {collapsedTable && (
        <TrCollapse collapse={collapseRow}>
          <TdCollapse colSpan={columns.length + 1}>
            {rowData.nested && rowData.nested.length && (
              <TableCollapseContent>
                <DataTable
                  nestedTable={true}
                  hiddenHeader={true}
                  data={rowData.nested}
                  loadingComponent={Spinner}
                  columns={collapsedColumns}
                  emptyMessage={<Message id='no-data-message' />}
                />
              </TableCollapseContent>
            )}
          </TdCollapse>
        </TrCollapse>
      )}
    </>
  );
};

export default BodyRow;
