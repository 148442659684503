import styled from 'styled-components';
import { getPlaceholderColor } from 'common/components/Input/helpers';

export const CronContainer = styled.div`
  .pai-cron-scheduler {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .ant-btn-dangerous.ant-btn-primary {
    font-size: 13px;
    background: transparent;
    color: ${({ theme }) => theme.buttons.secondary};
    border-color: ${({ theme }) => theme.buttons.secondary};
    border-radius: 4px;
  }

  .ant-btn-dangerous.ant-btn-primary:focus {
    box-shadow: 0 0 3px ${({ theme }) => theme.secondary};
  }

  .ant-btn-dangerous.ant-btn-primary[disabled],
  .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
  .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.form.border};
  }

  .ant-select {
    font-size: 13px;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.fontSecondary};
  }
`;

export const CronInput = styled.div``;

interface LabelProps {
  isError?: boolean;
  isInputEmpty?: boolean;
  disabled?: boolean;
}

export const Label = styled.label<LabelProps>`
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
  padding: 0 0 5px;
  color: ${({ theme, isError, disabled }) => getPlaceholderColor({ theme, isError, disabled })};
  z-index: 1;
  pointer-events: none;
`;

interface InputErrorMessageProps {
  isError?: boolean;
  isPlaceholderHidden?: boolean;
}

export const CronErrorMessage = styled.div<InputErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;
