import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { customersService } from 'common/api/customersService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { browserHistory } from 'browserHistory';
import { APP_URLS, defaultFilterOptions, ErrorCodes } from 'common/constants';
import { OperationData } from 'types/Operations';
import { clientsService } from 'common/api/clientsService';
import { integrationsService } from 'common/api/integrationsService';
import { getErrorTraceId } from 'utils/integrations';
import { ActionTypeKeys, actionCreators } from './customerReducer';

export function* getCustomerSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(customersService.getCustomerById, payload);

    yield put(actionCreators.getCustomerSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerFailed());
  }
}

export function* updateCustomerSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, id } = payload;
    yield call(customersService.updateCustomer, payload);
    const { data } = yield call(customersService.getCustomerById, { accessToken, customerId: id });

    yield put(actionCreators.updateCustomerSuccess(data));
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'customer-data-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    if (errorLocal.response) {
      yield put(actionCreators.updateCustomerFailed(errorLocal.response.status));
    } else {
      yield put(actionCreators.updateCustomerFailed(ErrorCodes.CommonError));
    }
  }
}

export function* setCustomerStatusSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, id } = payload;
    yield call(customersService.setCustomerStatus, payload);

    yield put(actionCreators.setCustomerStatusSuccess());
    yield put(actionCreators.getCustomer({ accessToken, customerId: id }));
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'customer-status-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.setCustomerStatusFailed());
  }
}

export function* getCustomersIntegrationsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getCustomerIntegrations, payload);
    yield put(actionCreators.getCustomerIntegrationsSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerIntegrationsFailed());
  }
}

export function* getCustomersClientsAllIntegrationsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getCustomerClientsAllIntegrations, payload);
    yield put(actionCreators.getCustomerClientAllIntegrationsSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerClientAllIntegrationsFailed());
  }
}

export function* getCustomerConfigurationSchema({ payload }: ActionWithPayload) {
  try {
    const { isCreatePage, ...requestData } = payload;
    const { data } = yield call(integrationsService.getCustomerConfigurationSchema, requestData);

    yield put(actionCreators.getCustomerConfigurationSchemaSuccess(data));

    if (isCreatePage) {
      yield put(actionCreators.getCustomerSystemSettings(requestData));
    }
  } catch (error) {
    const errorLocal = error as any;
    if (errorLocal?.response?.data?.status !== ErrorCodes.NOT_FOUND_SCHEMA) {
      yield put(
        notificationsActionCreators.addNotification({
          type: NotificationType.ERROR,
          messageId: 'error-messages-something-wrong',
          errorText: errorLocal?.response?.data?.title || error,
          errorStatus: errorLocal?.response?.status,
        }),
      );
    } else {
      yield put(actionCreators.getCustomerConfigurationSchemaFailed(ErrorCodes.NOT_FOUND_SCHEMA));
    }
  }
}

export function* getCustomerSystemSettingsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getCustomerSystemsSettings, payload);

    yield put(
      actionCreators.getCustomerSystemSettingsSuccess({
        settings: {
          currentSettings: [],
          parentSettings: [...data.settings],
        },
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerSystemSettingsFailed());
  }
}

export function* createCustomerIntegrationSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.createCustomerIntegration, payload);

    yield put(actionCreators.createCustomerIntegrationSuccess());
    yield browserHistory.push({
      pathname: APP_URLS.toCustomerIntegrationProfile(data.customerId, data.id),
      state: { fromIntegrationCreatePage: true },
    });
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.createCustomerIntegrationFailed());
  }
}

export function* getCustomersIntegrationSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getIntegrationById, payload);
    yield put(actionCreators.getCustomerIntegrationSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerIntegrationFailed());
  }
}

export function* getCustomerAvailableSystemsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(customersService.getCustomerAvailableSystems, payload);

    yield put(actionCreators.getCustomerAvailableSystemsSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerAvailableSystemsFailed());
  }
}

export function* updateCustomerIntegrationSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, integrationId, withTestConnection } = payload;
    yield call(integrationsService.updateIntegrationById, payload);
    const { data } = yield call(integrationsService.getIntegrationById, {
      accessToken,
      integrationId,
    });
    yield put(actionCreators.updateCustomerIntegrationSuccess(data));
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-data-was-updated',
      }),
    );
    if (withTestConnection) {
      yield put(actionCreators.getCustomerTestConnection({ accessToken, integrationId }));
    }
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateCustomerIntegrationFailed(ErrorCodes.CommonError));
  }
}

export function* updateCustomerIntegrationStatusSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, isProfilePage, integrationId, action } = payload;
    yield call(customersService.updateCustomerIntegrationStatus, { accessToken, integrationId, action });
    if (isProfilePage) {
      yield put(
        actionCreators.getCustomerIntegration({
          accessToken,
          integrationId,
        }),
      );
      yield put(actionCreators.updateCustomerIntegrationStatusSuccess({ isProfilePage, integrationId }));
    } else {
      yield put(actionCreators.updateCustomerIntegrationStatusSuccess({ isProfilePage, integrationId }));
    }
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-status-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateCustomerIntegrationStatusFailed(ErrorCodes.CommonError));
  }
}

export function* getCustomerOperationsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(customersService.getCustomerOperations, payload);
    const prepareData = {
      data: data.data.map((item: OperationData, index: number) => ({ ...item, id: `${index + 1}_${item.traceId}` })),
      totalCount: data.totalCount,
    };
    yield put(actionCreators.getCustomerOperationsSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerOperationsFailed());
  }
}

export function* getClientsOptionsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientsBasicByCustomerId, payload);

    const prepareData = data.data.map((item: any) => ({
      ...item,
      value: item.name,
      label: item.name,
    }));

    yield put(actionCreators.getClientsOptionsSuccess([defaultFilterOptions, ...prepareData]));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientsOptionsFailed());
  }
}

export function* getCustomerTestConnectionSaga({ payload }: ActionWithPayload) {
  try {
    yield call(integrationsService.testConnection, payload);

    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-connection-success',
      }),
    );
    yield put(actionCreators.getCustomerTestConnectionSuccess());
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        autoDeleteTime: 4000,
        messageId: 'integration-connection-failed',
        errorText: errorLocal?.response?.data?.title || error,
        errorTraceId: errorLocal?.response?.data?.traceId ? getErrorTraceId(errorLocal?.response?.data?.traceId) : '',
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomerTestConnectionFailed());
  }
}

export function* customerSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_CUSTOMER, getCustomerSaga),
    takeEvery(ActionTypeKeys.UPDATE_CUSTOMER, updateCustomerSaga),
    takeEvery(ActionTypeKeys.SET_CUSTOMER_STATUS, setCustomerStatusSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS, getCustomersIntegrationsSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS, getCustomersClientsAllIntegrationsSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA, getCustomerConfigurationSchema),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS, getCustomerSystemSettingsSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_INTEGRATION, getCustomersIntegrationSaga),
    takeEvery(ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION, createCustomerIntegrationSaga),
    takeEvery(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION, updateCustomerIntegrationSaga),
    takeEvery(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS, updateCustomerIntegrationStatusSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS, getCustomerAvailableSystemsSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID, getCustomerOperationsSaga),
    takeEvery(ActionTypeKeys.GET_CLIENTS_OPTIONS, getClientsOptionsSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION, getCustomerTestConnectionSaga),
  ];
}
