import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Message, Select, Input, Textarea, Button, Spinner } from 'common/components';
import { APP_URLS } from 'common/constants';
import { CustomerRequestData } from 'types/Customer';
import { FormSelectOption } from 'types/Form';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { validateOrganizationNumber, validateStringValue } from 'utils/validation';
import { useAccessToken } from 'hooks';
import getCustomerCreateBreadcrumbs from './getCustomerCreateBreadcrumbs';
import {
  Head,
  Title,
  Content,
  FormWrapper,
  FormColumnWrapper,
  FormColumn,
  FormRow,
  FormButtons,
  FormSection,
  FormSectionHeader,
  FormSectionTitle,
} from './styles';

const customerDefaultValues = {
  organizationNumber: '',
  customerName: '',
  customerType: {
    id: 0,
    value: '',
    label: '',
  },
  customerComments: '',
};

interface CreateFormProps {
  organizationNumber: string;
  customerName: string;
  customerType: FormSelectOption;
  customerComments: string;
}

interface CustomerCreateProps {
  isFetching: boolean;
  customerTypeOptions: FormSelectOption[];
  createCustomer: (payload: CustomerRequestData) => void;
}

const CustomerCreate = ({ isFetching, customerTypeOptions, createCustomer }: CustomerCreateProps) => {
  const accessToken = useAccessToken();
  const { push } = useHistory();
  const [defaultValues, setDefaultValues] = useState<any>(customerDefaultValues);

  useEffect(() => {
    setDefaultValues({ ...defaultValues, customerType: customerTypeOptions[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTypeOptions.length, customerTypeOptions]);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
    reset,
  } = useForm({ defaultValues, mode: 'onChange' });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { setBreadcrumbs } = useBreadcrumbsContext();
  useEffect(() => {
    setBreadcrumbs(getCustomerCreateBreadcrumbs());
    return () => setBreadcrumbs(null);
  }, [setBreadcrumbs]);

  const onSubmit = (data: CreateFormProps) => {
    createCustomer({
      accessToken,
      organizationNumber: data.organizationNumber,
      name: data.customerName,
      type: data.customerType.value,
      comment: data.customerComments,
    });
  };

  const onCancelHandler = () => {
    push(APP_URLS.customers);
  };

  return (
    <>
      <Head>
        <Title>
          <Message id={'create-customers-screen-title'} />
        </Title>
      </Head>
      <Content>
        {isFetching && <Spinner />}
        <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>
                <Message id='create-customers-customer-data' />
              </FormSectionTitle>
            </FormSectionHeader>
            <FormColumnWrapper>
              <FormColumn>
                <FormRow>
                  <Controller
                    control={control}
                    name='organizationNumber'
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 100,
                      validate: validateOrganizationNumber,
                    }}
                    render={({ field, fieldState }) => {
                      const messageId = fieldState.error?.message
                        ? fieldState.error.message
                        : 'create-customers-customer-organization-number-label-error';

                      return (
                        <Input
                          isRequired
                          isError={fieldState.invalid}
                          id='organizationNumber'
                          type='text'
                          label={<Message id={'create-customers-customer-organization-number-label'} />}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          errorText={<Message id={messageId} />}
                        />
                      );
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Controller
                    control={control}
                    name='customerName'
                    rules={{
                      required: true,
                      minLength: 1,
                      maxLength: 70,
                      validate: (value: string) => validateStringValue(true, value, 'create-customers-customer-name'),
                    }}
                    render={({ field, fieldState }) => {
                      const messageId = fieldState.error?.message
                        ? fieldState.error.message
                        : 'create-customers-customer-name-label-error';

                      return (
                        <Input
                          isRequired
                          isError={fieldState.invalid}
                          id='customerName'
                          type='text'
                          label={<Message id={'create-customers-customer-name-label'} />}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          errorText={<Message id={messageId} />}
                        />
                      );
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Controller
                    control={control}
                    name='customerType'
                    rules={{ required: true }}
                    render={({ field, fieldState }) => {
                      return (
                        <Select
                          isRequired
                          id='customerType'
                          options={customerTypeOptions}
                          value={field.value}
                          label={<Message id={'create-customers-customer-type-label'} />}
                          onChange={field.onChange}
                          isError={fieldState.invalid}
                          errorText={<Message id={'create-customers-customer-type-label-error'} />}
                        />
                      );
                    }}
                  />
                </FormRow>
              </FormColumn>
              <FormColumn>
                <FormRow>
                  <Controller
                    control={control}
                    name='customerComments'
                    rules={{ maxLength: 200 }}
                    render={({ field: { onChange, value }, fieldState }) => {
                      return (
                        <Textarea
                          id='customerComments'
                          isError={fieldState.invalid}
                          value={value}
                          label={<Message id='create-customers-comments-label' />}
                          onChange={(e) => onChange(e)}
                          minHeight={116}
                          errorText={<Message id='create-customers-comments-error-200-symbols' />}
                        />
                      );
                    }}
                  />
                </FormRow>
              </FormColumn>
            </FormColumnWrapper>
          </FormSection>
          <FormButtons>
            <Button transparent onClick={onCancelHandler}>
              <Message id={'create-customers-cancel-button'} />
            </Button>
            <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
              <Message id={'create-customers-save-proceed-button'} />
            </Button>
          </FormButtons>
        </FormWrapper>
      </Content>
    </>
  );
};

export default CustomerCreate;
