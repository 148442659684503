import styled from 'styled-components';
import { TableAlignment } from '../types';
import { getTableAlignment } from '../utils';

export const Thead = styled.thead`
  position: relative;
`;

interface ThProps {
  customWidth?: number;
  isSortable?: boolean;
  alignment?: TableAlignment;
}

export const Th = styled.th<ThProps>`
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  text-align: left;
  padding: 16px 10px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.backgroundPrimary};
  z-index: 2;
  cursor: ${({ isSortable }) => (isSortable ? 'pointer' : 'default')};
  width: ${({ customWidth }) => (customWidth ? `${customWidth}%` : 'auto')};
  text-align: ${({ alignment }) => getTableAlignment(alignment)};
`;

export const ThOpener = styled(Th)`
  width: 40px;
  text-align: center;
`;

interface TitleType {
  isHighlighted: boolean;
}

export const Title = styled.span<TitleType>`
  color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.fontPrimary : theme.fontSecondary)};
`;

interface SortableArrowsType {
  isAsc?: boolean;
  isDesc?: boolean;
}

export const SortableArrows = styled.span<SortableArrowsType>`
  padding-right: 15px;
  position: relative;
  top: -1px;

  &:before,
  &:after {
    border: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    right: 0;
    top: 50%;
    position: absolute;
    width: 0;
  }

  &:before {
    border-bottom-color: ${({ theme, isAsc }) => (isAsc ? theme.primary : theme.fontSecondary)};
    margin-top: -8px;
  }

  &:after {
    border-top-color: ${({ theme, isDesc }) => (isDesc ? theme.primary : theme.fontSecondary)};
    margin-top: 2px;
  }
`;

export const LinkWrapper = styled.div`
  width: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 10px;
`;
