import { NotificationType, NotificationTypes } from 'types/Notifications';

export const defineNotificationBackground = (theme: any, type: NotificationTypes) => {
  if (type === NotificationType.SUCCESS) {
    return theme.notifications.success;
  }
  if (type === NotificationType.WARNING) {
    return theme.notifications.warning;
  }
  if (type === NotificationType.ERROR) {
    return theme.notifications.error;
  }

  return theme.notifications.info;
};
