import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useAccessToken } from 'hooks';
import { APP_URLS } from 'common/constants';
import { Button, FlipToggle, Message, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { ClientData, ClientRequestParams, ClientStatusRequestData } from 'types/Client';

import StatusSelector from 'components/StatusSelector';
import ConfigurationForm from 'components/ConfigurationForm';
import { Schema, Transformation } from 'common/api/generated/PAITransformationApiClient';
import { UpdateTransformationRequest } from 'common/api/generated/PAIAdminApiClient';

import { ConfigurationLevel } from 'types/Configuration';
import getClientTransformationEditBreadcrumbs from './getClientTransformationEditBreadcrumbs';

import {
  ButtonBack,
  Content,
  Head,
  HeadActions,
  TabButton,
  TabContent,
  TabContentInner,
  TabHeader,
  TabHeaderLeft,
  TabHeaderRight,
  TabsList,
  TabsWrapper,
  TabTitle,
  Title,
  TitleMark,
} from './styles';

type clientTransformationUrlParams = {
  customerId: string;
  clientId: string;
  transformationId: string;
};

interface TransformationEditProps {
  isFetching: boolean;
  errorCode: number | null;
  client: ClientData;
  transformation: Transformation;
  transformationSchema: Schema;
  getClient: (payload: ClientRequestParams) => void;
  setStatus: (payload: ClientStatusRequestData) => void;
  getClientTransformation: (payload: { accessToken: string; transformationId: string }) => void;
  getClientTransformationSchema: (payload: { accessToken: string; typeId: string }) => void;
  updateClientTransformation: (payload: {
    accessToken: string;
    transformationId: string;
    request: UpdateTransformationRequest;
  }) => void;
  updateClientTransformationStatus: (payload: {
    accessToken: string;
    customerId: number;
    clientId: number;
    transformationId: string;
    active: boolean;
  }) => void;
  resetPartial: () => void;
  integrationReset: () => void;
}

const IntegrationEdit = ({
  errorCode,
  isFetching,
  client,
  transformation,
  transformationSchema,
  getClient,
  setStatus,
  getClientTransformation,
  getClientTransformationSchema,
  updateClientTransformation,
  updateClientTransformationStatus,
  resetPartial,
  integrationReset,
}: TransformationEditProps) => {
  const accessToken = useAccessToken();
  const { push } = useHistory();
  const { customerId, clientId, transformationId } = useParams<clientTransformationUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs(
      getClientTransformationEditBreadcrumbs(
        Number(customerId),
        Number(clientId),
        client.customerName,
        client.name,
        transformation?.displayName,
      ),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, transformation?.displayName, setBreadcrumbs]);

  useEffect(() => {
    if (!client.name && accessToken) {
      getClient({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
  }, [accessToken, clientId, customerId, client.name, getClient]);

  useEffect(() => {
    if (accessToken && transformationId) {
      getClientTransformation({
        accessToken,
        transformationId,
      });
    }
  }, [accessToken, transformationId, getClientTransformation]);

  useEffect(() => {
    if (accessToken && transformation?.typeId) {
      getClientTransformationSchema({
        accessToken,
        typeId: transformation.typeId,
      });
    }
  }, [accessToken, transformation, getClientTransformationSchema]);

  useEffect(() => {
    return () => integrationReset();
  }, [integrationReset]);

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toClientTransformations(Number(customerId), Number(clientId)));
  }, [customerId, clientId, push]);

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      if (transformation) {
        updateClientTransformation({
          accessToken: accessToken || '',
          transformationId: transformation.id || '',
          request: {
            isActive: undefined,
            settings: Object.keys(data)
              .map((key) => ({ name: key.replace('_', '.'), value: data[key]?.toString() }))
              .filter((s) => !s.name.startsWith('Transformations')),
          },
        });
      }
    },
    [accessToken, transformation, updateClientTransformation],
  );

  const onFlipToggleChangeHandler = (event: any) => {
    if (transformation) {
      updateClientTransformationStatus({
        accessToken: accessToken || '',
        customerId: Number(customerId),
        clientId: Number(clientId),
        transformationId: transformation.id || '',
        active: event.target.checked ? true : false,
      });
    }
  };

  const renderForm = useMemo(() => {
    if (!transformation?.displayName) {
      return null;
    }

    return (
      <ConfigurationForm
        isFetching={isFetching}
        errorCode={errorCode}
        name={transformation.displayName}
        settings={transformation.settings}
        schema={transformationSchema}
        level={ConfigurationLevel.CLIENT}
        isEdit={true}
        onSubmit={onSubmitButtonClickHandler}
        onCancel={onCancelHandler}
        resetForm={resetPartial}
      />
    );
  }, [
    isFetching,
    errorCode,
    transformation,
    transformationSchema,
    onSubmitButtonClickHandler,
    onCancelHandler,
    resetPartial,
  ]);

  return (
    <>
      <Head>
        <Title>
          {client?.name}
          <TitleMark>id#{client?.id}</TitleMark>
        </Title>
        <HeadActions>
          {client.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={client.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <TabsList>
            <TabButton exact to={APP_URLS.toClientProfile(Number(customerId), Number(clientId))}>
              <Message id='client-screen-client-data-tab' />
            </TabButton>
            <TabButton to={APP_URLS.toClientIntegrations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-integrations-tab' />
            </TabButton>
            <TabButton to={APP_URLS.toClientTransformations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-transformations-tab' />
            </TabButton>
            <TabButton to={APP_URLS.toClientApproverReferences(Number(customerId), Number(clientId))}>
              <Message id='client-screen-approver-references-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-operation-status-tab' />
            </TabButton>
          </TabsList>
          <TabContent>
            <TabHeader>
              <TabHeaderLeft>
                <ButtonBack>
                  <Button transparent onClick={onCancelHandler}>
                    <ArrowLeftIcon />
                  </Button>
                </ButtonBack>
                <TabTitle>
                  {transformation ? transformation.displayName : ''}{' '}
                  <TitleMark>{transformation?.id ? `id#${transformation?.id}` : null}</TitleMark>
                </TabTitle>
              </TabHeaderLeft>
              <TabHeaderRight>
                {transformation && (
                  <FlipToggle
                    dataOn='Active'
                    dataOff='Inactive'
                    isChecked={transformation.isActive || false}
                    isDisabled={isFetching}
                    onChange={onFlipToggleChangeHandler}
                  />
                )}
              </TabHeaderRight>
            </TabHeader>
            <TabContentInner>
              {isFetching ? <Spinner /> : null}
              {renderForm}
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default IntegrationEdit;
