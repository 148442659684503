import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Button, Message, Select, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import StatusSelector from 'components/StatusSelector';
import { APP_URLS } from 'common/constants';
import { ClientData, ClientRequestParams, ClientStatusRequestData } from 'types/Client';

import { useAccessToken } from 'hooks';
import {
  Schema,
  TransformationType,
  Transformation,
} from 'common/api/generated/PAITransformationApiClient';
import { CreateTransformationRequest } from 'common/api/generated/PAIAdminApiClient';
import ConfigurationForm from 'components/ConfigurationForm';
import { ConfigurationLevel } from 'types/Configuration';
import getClientTransformationCreateBreadcrumbs from './getClientTransformationCreateBreadcrumbs';

import {
  ButtonBack,
  Content,
  FormColumn,
  FormColumnWrapper,
  FormInfo,
  FormInfoTitle,
  FormRow,
  FormTitleMark,
  Head,
  HeadActions,
  TabButton,
  TabContent,
  TabContentInner,
  TabHeader,
  TabsList,
  TabsWrapper,
  TabTitle,
  Title,
  TitleMark,
} from './styles';

type clientUrlParams = {
  customerId: string;
  clientId: string;
};

interface IntegrationCreateProps {
  isFetching: boolean;
  errorCode: number | null;
  client: ClientData;
  availableTransformationTypes: TransformationType[];
  transformations: Transformation[];
  transformationSchema: Schema;
  getClient: (payload: ClientRequestParams) => void;
  setStatus: (payload: ClientStatusRequestData) => void;
  getAvailableTransformationTypes: (payload: { accessToken: string }) => void;
  getClientTransformations: (payload: { accessToken: string; customerId: number; clientId: number }) => void;
  getClientTransformationSchema: (payload: { accessToken: string; typeId: string }) => void;
  createClientTransformation: (payload: {
    accessToken: string;
    customerId: number;
    clientId: number;
    request: CreateTransformationRequest;
  }) => void;
}

const IntegrationCreate = ({
  isFetching,
  errorCode,
  client,
  availableTransformationTypes,
  transformations,
  transformationSchema,
  getClient,
  setStatus,
  getAvailableTransformationTypes,
  getClientTransformations,
  getClientTransformationSchema,
  createClientTransformation,
}: IntegrationCreateProps) => {
  const accessToken = useAccessToken();
  const { push } = useHistory();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const { customerId, clientId } = useParams<clientUrlParams>();
  const [currentType, setCurrentType] = useState<any>({ id: '0', value: 'Unknown', label: '' });
  const [isTypeNameOptionsExists, setIsTypeNameOptionsExists] = useState(false);

  const availableTypes = useMemo(() => {
    return availableTransformationTypes.map((type) => ({ ...type, label: type.displayName, value: type.id }));
  }, [availableTransformationTypes]);

  useEffect(() => {
    setBreadcrumbs(
      getClientTransformationCreateBreadcrumbs(Number(customerId), Number(clientId), client.customerName, client.name),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, setBreadcrumbs]);

  useEffect(() => {
    if (!client.name && accessToken) {
      getClient({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
  }, [accessToken, customerId, clientId, client.name, getClient]);

  useEffect(() => {
    if (accessToken) {
      getAvailableTransformationTypes({ accessToken });
    }
  }, [accessToken, getAvailableTransformationTypes]);

  useEffect(() => {
    if (accessToken) {
      getClientTransformations({ accessToken, customerId: Number(customerId), clientId: Number(clientId) });
    }
  }, [accessToken, getClientTransformations, customerId, clientId]);

  useEffect(() => {
    const notAddedTransformationTypes = availableTransformationTypes.filter(
      (t) => !transformations.map((tr) => tr.typeId).includes(t.id),
    );
    setIsTypeNameOptionsExists(!!notAddedTransformationTypes.length);
  }, [availableTransformationTypes, transformations]);

  const onTypeChangeHandler = (type: any) => {
    if (accessToken && type.value !== currentType.value) {
      setCurrentType(type);
      getClientTransformationSchema({
        accessToken,
        typeId: type.id,
      });
    }
  };

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toClientTransformations(Number(customerId), Number(clientId)));
  }, [customerId, clientId, push]);

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      createClientTransformation({
        accessToken: accessToken || '',
        customerId: Number(customerId),
        clientId: Number(clientId),
        request: {
          typeId: currentType.id,
          isActive: true,
          settings: Object.keys(data)
            .map((key) => ({ name: key.replace('_', '.'), value: data[key]?.toString() }))
            .filter((s) => !s.name.startsWith('Transformations')),
        },
      });
    },
    [accessToken, customerId, clientId, currentType.id, createClientTransformation],
  );

  const renderForm = useMemo(
    () => (
      <ConfigurationForm
        isFetching={isFetching}
        errorCode={errorCode}
        name={currentType.name}
        schema={transformationSchema}
        settings={[]}
        level={ConfigurationLevel.CLIENT}
        isEdit={false}
        onSubmit={onSubmitButtonClickHandler}
        onCancel={onCancelHandler}
        resetForm={() => false}
      />
    ),
    [isFetching, errorCode, currentType.name, transformationSchema, onSubmitButtonClickHandler, onCancelHandler],
  );

  return (
    <>
      <Head>
        <Title>
          {client?.name}
          <TitleMark>id#{client?.id}</TitleMark>
        </Title>
        <HeadActions>
          {client.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={client.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <TabsList>
            <TabButton exact to={APP_URLS.toClientProfile(Number(customerId), Number(clientId))}>
              <Message id='client-screen-client-data-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientIntegrations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-integrations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientTransformations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-transformations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientApproverReferences(Number(customerId), Number(clientId))}>
              <Message id='client-screen-approver-references-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-operation-status-tab' />
            </TabButton>
          </TabsList>
          <TabContent>
            <TabHeader>
              <ButtonBack>
                <Button transparent onClick={onCancelHandler}>
                  <ArrowLeftIcon />
                </Button>
              </ButtonBack>
              <TabTitle>
                <Message id='client-transformations-add-new-transformation' />
              </TabTitle>
            </TabHeader>
            <TabContentInner>
              {isFetching && <Spinner />}
              <FormColumnWrapper>
                <FormColumn>
                  <FormRow>
                    <FormTitleMark>
                      <Message id='client-transformations-select-transformation-type' />
                    </FormTitleMark>
                    <Select
                      id='transformationType'
                      isRequired
                      disabled={!isTypeNameOptionsExists}
                      options={availableTypes}
                      value={currentType}
                      label={''}
                      onChange={onTypeChangeHandler}
                    />
                  </FormRow>
                </FormColumn>
              </FormColumnWrapper>
              {isTypeNameOptionsExists ? (
                renderForm
              ) : (
                <FormInfo>
                  <FormInfoTitle>
                    <Message id='client-integrations-all-integrations-for-this-client-created' />
                  </FormInfoTitle>
                </FormInfo>
              )}
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default IntegrationCreate;
