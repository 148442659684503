const theme = {
  primary: '#2f94d9',
  secondary: '#fc7e38',
  third: '#6c757d',

  fontPrimary: '#304156',
  fontSecondary: '#828d99',
  fontError: '#bf2600',
  fontSuccess: '#36b37e',

  linkColor: '#1077c6',

  backgroundPrimary: '#fff',
  backgroundSecondary: '#fafbfc',
  backgroundThird: '#d8d8d8',
  backgroundDropzone: '#fcfcfc',
  backgroundAlert: '#eff2f4',
  backgroundOverlay: 'rgba(0, 0, 0, 0.3)',
  boxShadow: 'rgba(0, 0, 0, 0.2)',

  buttons: {
    white: '#fff',
    primary: '#2f94d9',
    primaryTransparent: 'rgba(47, 148, 217, 0.7)',
    secondary: '#fc7e38',
    secondaryTransparent: 'rgba(252, 126, 56, 0.7)',
    danger: '#bf2600',
    dangerTransparent: 'rgba(191, 38, 0, 0.7)',
    success: '#36b37e',
    successTransparent: 'rgba(54, 179, 126, 0.7)',
    pending: '#e6b800',
    pendingTransparent: 'rgba(230, 184, 0, 0.7)',
    third: '#e8edf3',
    default: '#b5bfcc',
  },

  notifications: {
    color: '#fff',
    info: '#2f94d9',
    success: '#36b37e',
    warning: '#fc7e38',
    error: '#bf2600',
  },

  status: {
    info: '#2f94d9',
    success: '#36b37e',
    pending: '#e6b800',
    warning: '#fc7e38',
    error: '#bf2600',
  },

  borders: {
    grey: '#ddd',
  },

  sidebar: {
    fontPrimary: '#304156',
    fontSecondary: '#fff',
    activeBackgroundPrimary: '#b5bfcc',
    activeBackgroundSecondary: '#fff',
    backgroundMain: '#4e5a6b',
    backgroundSub: '#e8edf3',
  },

  typography: {
    fontFamily: ['Poppins', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    disabledTextColor: '#fc7e38',
  },

  form: {
    border: '#ddd',
    checkboxBorder: '#bbb',
    errorBackground: 'rgba(191,38,0,0.05)',
    activeBackground: 'rgba(47,148,217,0.1)',
  },

  datePickerPopup: {
    body: '#fff',
    header: '#fff',
    border: '#e1e5e9',
    background: '#f0f1f2',
    cellBackground: '#f7f7f7',
    cellBackgroundHover: '#cccccc',
  },

  table: {
    highlight: '#f3f3f3',
  },

  tooltip: {
    background: 'rgba(47, 148, 217, 0.95)',
  },

  colors: {
    white: '#fff',
    black: '#231f20',
    grey: '#e9e9e9',
    lightGrey: '#a1abb7',
    error: '#bf2600',
    success: '#36b37e',
    dangerLightTransparent: 'rgba(191, 38, 0, 0.1)',
  },
};

export default theme;
