import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Spinner, DataTable, Select, Button, Paginator, Message, Input } from 'common/components';
import { getArrayOfOptionsValues, getSelectedMultiOptions, removeTimezoneOffsetBeforeSend } from 'utils';
import { transferDataStatusFilterOptions } from 'common/constants';
import {
  ExecuteClientsRequestData,
  TransferClientData,
  TransferClientsRequestData,
  TransferredDataItem,
} from 'types/TransferHistoricalData';
import { CustomerByIdRequestData, CustomerData } from 'types/Customer';
import { NotificationType } from 'types/Notifications';
import { SelectOptionTransferStatus } from 'types/Form';
import { TablePerPage, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { useSearchInputFocus, useAccessToken } from 'hooks';
import { RefreshIcon } from 'common/components/Icons';
import TransferHistoricalDataModal from './components/TransferHistoricalDataModal';
import TransferHistoricalDataError from './components/TransferHistoricalDataError';
import transferHistoricalDataTableColumns from './transferHistoricalDataTableColumns';
import getTransferHistoricalDataBreadcrumbs from './getTransferHistoricalDataBreadcrumbs';
import { Wrapper, FiltersLeft, FiltersRight, FiltersWrapper, Head, Title, FiltersColumn } from './styles';

type CustomerUrlParams = {
  customerId: string;
};

interface TransferHistoricalDataProps {
  isFetching: boolean;
  isNotAllDataTransferred: boolean;
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterStatus: SelectOptionTransferStatus[];
  filterSearch: string;
  customer: CustomerData;
  clients: TransferClientData[];
  transferredData: TransferredDataItem[];
  getCustomer: (payload: CustomerByIdRequestData) => void;
  getClientsForTransferData: (payload: TransferClientsRequestData) => void;
  executeDataTransfer: (payload: ExecuteClientsRequestData) => void;
  setTablePage: (payload: number) => void;
  setTablePerPage: (payload: number) => void;
  resetTransferData: () => void;
  resetFilters: () => void;
  addNotification: (payload: any) => void;
  setFilterStatus: (payload: SelectOptionTransferStatus[]) => void;
  setTableOrder: (payload: TablesSortOrderPayload) => void;
  setFilterSearch: (payload: string) => void;
}

const TransferHistoricalData = ({
  isFetching,
  isNotAllDataTransferred,
  totalCount,
  page,
  perPage,
  orderBy,
  sortOrder,
  filterStatus,
  filterSearch,
  customer,
  clients,
  transferredData,
  getCustomer,
  getClientsForTransferData,
  executeDataTransfer,
  setTablePage,
  setTablePerPage,
  resetTransferData,
  resetFilters,
  addNotification,
  setFilterStatus,
  setTableOrder,
  setFilterSearch,
}: TransferHistoricalDataProps) => {
  const accessToken = useAccessToken();
  const { customerId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [isFiltersDisabled, setFiltersDisabled] = useState(true);
  const [setIsSearchInputChanged, inputRef, setSearchInputFocus] = useSearchInputFocus();
  const requestData = {
    accessToken,
    customerId: Number(customerId),
    page: 1,
    size: Number(perPage),
    orderBy: orderBy,
    sortOrder: sortOrder,
    search: filterSearch,
    statuses:
      filterStatus[0].value === transferDataStatusFilterOptions[0].value
        ? undefined
        : getArrayOfOptionsValues(filterStatus),
    applyStatusFilter: filterStatus[0].value === transferDataStatusFilterOptions[0].value ? undefined : true,
  };

  useEffect(() => {
    setSearchInputFocus();
  }, [isFetching, setSearchInputFocus]);

  useEffect(() => {
    if (accessToken) {
      getClientsForTransferData({
        accessToken,
        customerId: Number(customerId),
        page: page,
        size: perPage,
        orderBy: orderBy,
        sortOrder: sortOrder,
      });
    }

    return () => resetTransferData();
    // eslint-disable-next-line
  }, [accessToken, customerId, getClientsForTransferData, resetTransferData]);

  useEffect(() => {
    if (!customer.name && accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, getCustomer, customer.name]);

  useEffect(() => {
    setBreadcrumbs(getTransferHistoricalDataBreadcrumbs(Number(customerId), customer.name));
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs]);

  useEffect(() => {
    setFiltersDisabled(
      isFetching || (!totalCount && filterStatus[0].value === transferDataStatusFilterOptions[0].value),
    );
  }, [isFetching, totalCount, filterStatus]);

  useEffect(() => {
    if (isNotAllDataTransferred) {
      addNotification({
        type: NotificationType.ERROR,
        autoDeleteEnabled: false,
        messageId: 'transfer-historical-error-some-data-is-not-transferred',
        errorComponent: <TransferHistoricalDataError transferredData={transferredData} />,
      });
    }
  }, [addNotification, isNotAllDataTransferred, transferredData]);

  const handleOnItemsPerPageChange = (perPage: TablePerPage) => {
    setTablePage(1);
    setTablePerPage(Number(perPage.value));
    setIsSearchInputChanged(false);
    getClientsForTransferData({
      ...requestData,
      size: Number(perPage.value),
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleOnPageChange = (page: { selected: number }) => {
    setTablePage(page.selected);
    setIsSearchInputChanged(false);
    getClientsForTransferData({
      ...requestData,
      page: page.selected,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableFilterStatusChange = (event: SelectOptionTransferStatus[]) => {
    setTablePage(1);
    setIsSearchInputChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, transferDataStatusFilterOptions, setFilterStatus);
    getClientsForTransferData({
      ...requestData,
      statuses:
        selectedOptions[0].value === transferDataStatusFilterOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
      applyStatusFilter: selectedOptions[0].value === transferDataStatusFilterOptions[0].value ? undefined : true,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableSortChange = (name: string, direction: TableSortOrder) => {
    setTableOrder({ orderBy: name, sortOrder: direction });
    setTablePage(1);
    setIsSearchInputChanged(false);
    getClientsForTransferData({
      ...requestData,
      orderBy: name,
      sortOrder: direction,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getClientsForTransferData({
      ...requestData,
      search: value ? value : undefined,
    });
  }, 500);

  const handleSearch = (value: string) => {
    if (value !== filterSearch) {
      setIsSearchInputChanged(true);
    }
    setFilterSearch(value);

    if (value.trim().length !== 0 || filterSearch.trim().length) {
      debouncedSearch(value);
      setTablePage(1);
    }
  };

  const onRefreshButtonClick = () => {
    setIsSearchInputChanged(false);
    getClientsForTransferData({
      ...requestData,
      page: page,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const onTableChange = (selectedKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedClients(selectedRows);
  };

  const onTransferSelectedHandler = useCallback(() => {
    setWarningModalVisible(true);
  }, []);

  const onTransferModalCancelHandler = useCallback(() => {
    setWarningModalVisible(false);
    setSelectedClients([]);
    setSelectedRowKeys([]);
  }, []);

  const onTransferModalConfirmHandler = useCallback(
    (dateRange: any) => {
      const dateFromLocal = removeTimezoneOffsetBeforeSend(dateRange.dateFrom).split('T')[0];
      const dateToLocal = removeTimezoneOffsetBeforeSend(dateRange.dateTo).split('T')[0];

      executeDataTransfer({
        accessToken,
        customerId: Number(customerId),
        clientIds: selectedClients.map((client: any) => Number(client.clientId)),
        fromDateUtc: dateFromLocal,
        toDateUtc: dateToLocal,
      });
      setWarningModalVisible(false);
      setSelectedClients([]);
      setSelectedRowKeys([]);
      resetFilters();
    },
    [accessToken, customerId, executeDataTransfer, selectedClients, resetFilters],
  );

  return (
    <>
      <Head>
        <Title>
          <Message id='customer-transfer-historical-data' />
        </Title>
      </Head>
      <Wrapper>
        <FiltersWrapper>
          <FiltersLeft>
            <FiltersColumn>
              <Select
                isMulti
                isClearable={false}
                value={filterStatus}
                options={transferDataStatusFilterOptions}
                label={<Message id='clients-screen-filter-status' />}
                onChange={handleTableFilterStatusChange}
                disabled={isFiltersDisabled}
              />
            </FiltersColumn>
            <FiltersColumn>
              <Input
                inputRef={inputRef}
                disabled={isFetching}
                id='filterSearch'
                type='text'
                label={<Message id='transfer-historical-data-search' />}
                value={filterSearch}
                onChange={handleSearch}
                placeholder='Client Name, Org. Number'
              />
            </FiltersColumn>
          </FiltersLeft>
          <FiltersRight>
            <Button primary disabled={!selectedClients.length} onClick={onTransferSelectedHandler}>
              <Message id='customer-transfer-transfer-selected-button' />
            </Button>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </FiltersRight>
        </FiltersWrapper>
        <DataTable
          isFetching={isFetching}
          data={clients}
          columns={transferHistoricalDataTableColumns(Number(customerId))}
          loadingComponent={Spinner}
          orderBy={orderBy}
          sortOrder={sortOrder}
          onSortChange={handleTableSortChange}
          rowSelection={{
            selectedRowKeys,
            onChange: onTableChange,
            getCheckboxProps: (record: any) => ({
              disabled: !record.transferable,
            }),
          }}
          emptyMessage={<Message id='no-data-message' />}
        />
        <Paginator
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          onItemsPerPageChange={handleOnItemsPerPageChange}
          onPageChange={handleOnPageChange}
        />
        {isWarningModalVisible && (
          <TransferHistoricalDataModal
            isVisible={isWarningModalVisible}
            selectedClients={selectedClients}
            onModalCancelHandler={onTransferModalCancelHandler}
            onModalConfirmHandler={onTransferModalConfirmHandler}
          />
        )}
      </Wrapper>
    </>
  );
};

export default TransferHistoricalData;
