import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { actionCreators, ActionTypeKeys } from '../reducers/sharedReducer';
import { integrationsService } from '../api/integrationsService';
import { dictionariesService } from '../api/dictionariesService';

export function* getIntegrationSystemsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.integrationSystems, payload);
    yield put(actionCreators.getIntegrationSystemsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    yield put(actionCreators.getIntegrationSystemsFailed());
  }
}

export function* getDictionariesSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(dictionariesService.getDictionaries, payload);
    yield put(actionCreators.getDictionariesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(actionCreators.getDictionariesFailed());
  }
}

export function* sharedSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_INTEGRATION_SYSTEMS, getIntegrationSystemsSaga),
    takeEvery(ActionTypeKeys.GET_DICTIONARIES, getDictionariesSaga),
  ];
}
