import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Status } from 'types/Status';
import { RootState } from 'store/rootReducer';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { useRemoteApiCall } from 'hooks/useRemoteApiCall';
import { ApiException, RoleModel } from 'common/api/generated/PAIAdminApiClient';
import { ButtonLink, Message, Spinner } from 'common/components';
import { APP_URLS } from 'common/constants';
import { PortalUsersGrid } from './PortalUsersGrid';
import {
  ColumnWrapper,
  CreatePortalButtonWrapper,
  CreateUserButtonWrapper,
  FormColumn,
  FormColumnWrapper,
  FormRow,
  FormSection,
  FormSectionHeader,
  FormSectionTitle,
  PortalNotExistTitle,
  PortalPropertyTitle,
  PortalPropertyValue,
} from './styles';
import { usePortalBreadcrumbsEffect } from './breadcrumbs';
import { PermissionsEditForm } from './PermissionsEditForm';

type CustomerPortalInfo = {
  id: string;
  identityProviderId: string;
  domain: string;
  roles: RoleModel[];
  permissions: string[];
  createdById: string;
  createdAt?: Date;
};

type CustomerUrlParams = {
  customerId: string;
};

const PortalView = () => {
  const { customerId } = useParams<CustomerUrlParams>();
  const paiClient = useAuthenticatedAdminApiClient();
  const [isFetching, callRemoteApi] = useRemoteApiCall();

  // breadcrumbs

  usePortalBreadcrumbsEffect('Customer Portal');

  // customer state

  const customerStatus = useSelector<RootState, Status>((state) => state.customer.customer.status);

  // portal info

  const [portalInfo, setPortalInfo] = useState<CustomerPortalInfo | null | undefined>(undefined);
  const [reloadPortalInfoKey, setReloadPortalInfoKey] = useState(0);
  useEffect(() => {
    (async () => {
      if (!paiClient) {
        return;
      }

      const result = await callRemoteApi(async () => {
        try {
          const response = await paiClient?.customerPortalControllerCustomerPortalGet(Number(customerId));
          return response ?? null;
        } catch (e) {
          if ((e as ApiException).status === 404) {
            return null;
          }
          throw e;
        }
      });

      if (!result) {
        setPortalInfo(null);
      } else {
        setPortalInfo({
          id: result.id ?? '',
          identityProviderId: result.identityProviderId ?? '',
          domain: result.domain ?? '',
          roles: result.roles ?? [],
          permissions: result.permissions ?? [],
          createdById: result?.createdById ?? '',
          createdAt: result?.createdDate,
        });
      }
    })();
  }, [paiClient, callRemoteApi, customerId, reloadPortalInfoKey]);

  // permissions

  const [allPermissions, setAllPermissions] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const result = await callRemoteApi(() => paiClient?.customerPortalControllerGetAllPermissions());
      result && setAllPermissions(result);
    })();
  }, [paiClient, callRemoteApi]);

  const onPermissionsSaved = useCallback(() => {
    setReloadPortalInfoKey(reloadPortalInfoKey + 1);
  }, [reloadPortalInfoKey]);

  if (customerStatus !== Status.ACTIVE) {
    return (
      <>
        <PortalNotExistTitle>
          Customer Portal is only available for active customers, please activate the customer!
        </PortalNotExistTitle>
      </>
    );
  }

  return (
    <>
      {isFetching && <Spinner />}
      {portalInfo === undefined && <></>}
      {portalInfo === null && (
        <>
          <PortalNotExistTitle>Customer Portal does not exist, let`s create and start using it!</PortalNotExistTitle>
          <CreatePortalButtonWrapper>
            <ButtonLink primary='true' to={APP_URLS.toCustomerPortalCreate(Number(customerId))} disabled={isFetching}>
              <Message id='customer-portal-create-portal-link' />
            </ButtonLink>
          </CreatePortalButtonWrapper>
        </>
      )}
      {portalInfo && (
        <>
          <FormSection>
            <FormColumnWrapper>
              <FormColumn>
                <FormSectionHeader>
                  <FormSectionTitle>
                    <Message id='customer-portal-data-title' />
                  </FormSectionTitle>
                </FormSectionHeader>
                <FormRow>
                  <ColumnWrapper>
                    <PortalPropertyTitle>Identity Provider</PortalPropertyTitle>
                    <PortalPropertyValue>{portalInfo.identityProviderId}</PortalPropertyValue>
                  </ColumnWrapper>
                </FormRow>
                <FormRow>
                  <ColumnWrapper>
                    <PortalPropertyTitle>Domain</PortalPropertyTitle>
                    <PortalPropertyValue>{portalInfo.domain}</PortalPropertyValue>
                  </ColumnWrapper>
                </FormRow>
                <FormRow>
                  <ColumnWrapper>
                    <PortalPropertyTitle>Created By</PortalPropertyTitle>
                    <PortalPropertyValue>{portalInfo.createdById}</PortalPropertyValue>
                  </ColumnWrapper>
                </FormRow>
                <FormRow>
                  <ColumnWrapper>
                    <PortalPropertyTitle>Created At</PortalPropertyTitle>
                    <PortalPropertyValue>{portalInfo?.createdAt?.toString() ?? ''}</PortalPropertyValue>
                  </ColumnWrapper>
                </FormRow>

                <FormRow>&nbsp;</FormRow>

                <FormSectionHeader>
                  <FormSectionTitle>
                    <Message id='customer-portal-roles-title' />
                  </FormSectionTitle>
                </FormSectionHeader>
                <FormRow>
                  {portalInfo &&
                    portalInfo?.roles?.map((role) => (
                      <ul key={role.name}>
                        <li>
                          <div>{role.name}</div>
                          <ul>
                            {role.permissions?.map((perm) => (
                              <li key={perm}>{perm}</li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    ))}
                </FormRow>
              </FormColumn>
              <FormColumn>
                <FormSectionHeader>
                  <FormSectionTitle>
                    <Message id='customer-portal-permissions-title' />
                  </FormSectionTitle>
                </FormSectionHeader>
                <FormRow>
                  {allPermissions && (
                    <PermissionsEditForm
                      portalId={portalInfo.id}
                      allPermissions={allPermissions}
                      portalPermissions={portalInfo.permissions}
                      onSubmitHandler={onPermissionsSaved}
                    />
                  )}
                </FormRow>
              </FormColumn>
            </FormColumnWrapper>
          </FormSection>
          <FormSection>
            <FormColumnWrapper>
              <FormColumn width='100%'>
                <FormSectionHeader>
                  <FormSectionTitle>
                    <Message id='customer-portal-users-grid-title' />
                  </FormSectionTitle>
                </FormSectionHeader>
                <FormRow>
                  <ColumnWrapper>
                    <PortalUsersGrid customerId={customerId} portalId={portalInfo.id} />
                  </ColumnWrapper>
                  <CreateUserButtonWrapper>
                    <ButtonLink
                      primary='true'
                      to={APP_URLS.toCustomerPortalUserCreate(Number(customerId), portalInfo.id)}
                      disabled={isFetching}
                    >
                      <Message id='customer-portal-create-portal-user-link' />
                    </ButtonLink>
                  </CreateUserButtonWrapper>
                </FormRow>
              </FormColumn>
            </FormColumnWrapper>
          </FormSection>
        </>
      )}
    </>
  );
};

export default PortalView;
