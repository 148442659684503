import styled from 'styled-components';
import { BadgeBgType, getBadgeAlignment, getBadgeBackground, getBadgeSize, getBadgeTextColor } from './helpers';

interface BadgeWrapperType {
  pill?: boolean;
  bg?: BadgeBgType;
  size?: 'large' | 'medium' | 'small';
  vAlignment?: 'top' | 'middle' | 'bottom';
}

export const BadgeWrapper = styled.span<BadgeWrapperType>`
  font-size: ${({ size }) => getBadgeSize(size)};
  line-height: 15px;
  letter-spacing: 0.5px;
  display: inline-block;
  vertical-align: top;
  padding: 3px 8px;
  border-radius: ${({ pill }) => (pill ? '10px' : '4px')};
  background: ${({ theme, bg }) => getBadgeBackground({ theme, bg })};
  color: ${({ theme, bg }) => getBadgeTextColor({ theme, bg })};
  margin: 0 5px;
  align-self: ${({ vAlignment }) => getBadgeAlignment(vAlignment)};
`;
