import styled from 'styled-components';

export const FormWrapper = styled.form`
  padding-right: 55px;
  padding-top: 10px;
`;

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormEditButton = styled.span`
  position: absolute;
  right: 15px;
  top: 15px;
`;

export const FormColumn = styled.div`
  width: 48%;
  flex: 0 0 48%;
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: 48%;
  flex: 0 0 48%;
`;

export const ColumnLeft = styled(Column)`
  width: 58%;
  flex: 0 0 58%;
`;

export const ColumnRight = styled(Column)`
  width: 40%;
  flex: 0 0 40%;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;

  & > button {
    margin-left: 20px;
    min-width: 130px;
  }
`;

export const FormButtonsCentered = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    min-width: 100px;
    margin: 0 10px;
  }
`;

export const WarningTitle = styled.div`
  padding-right: 40px;
  color: ${({ theme }) => theme.fontError};
  display: inline-flex;
  align-items: center;
`;

export const IconCircle = styled.div`
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.fontError};
  border: 2px solid ${({ theme }) => theme.fontError};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const ReasonBlock = styled.div`
  text-align: left;
  padding: 0 40px;
`;
