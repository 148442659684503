import { ReactNode } from 'react';
import { ColumnItem } from '../types';
import BodyRow from './BodyRow';
import { Tbody, TdEmpty } from './styles';

interface BodyProps {
  collapsedTable?: boolean;
  nestedTable?: boolean;
  emptyMessage?: string | ReactNode | null;
  columns: ColumnItem[];
  data: any[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  rowCallbacks?: {};
  collapsedFlag?: string;
  collapsedColumns?: any;
  collapsedCallback?: (payload: any) => void;
}

const Body = ({
  collapsedTable,
  nestedTable,
  collapsedFlag,
  collapsedColumns,
  collapsedCallback,
  emptyMessage,
  columns,
  data,
  rowCallbacks,
}: BodyProps) => {
  if (!data.length) {
    return (
      <Tbody>
        <tr>
          <TdEmpty colSpan={collapsedTable ? columns.length + 1 : columns.length}>
            {emptyMessage && <span>{emptyMessage}</span>}
          </TdEmpty>
        </tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {data.map((rowData: any, index: number) => {
        return (
          <BodyRow
            collapsedTable={collapsedTable}
            nestedTable={nestedTable}
            collapsedFlag={collapsedFlag}
            collapsedColumns={collapsedColumns}
            collapsedCallback={collapsedCallback}
            key={rowData.id ? rowData.id : index}
            rowIndex={index}
            rowData={rowData}
            rowCallbacks={rowCallbacks}
            columns={columns}
          />
        );
      })}
    </Tbody>
  );
};
export default Body;
