import { AnyAction, Reducer } from 'redux';

// Actions
export enum ActionTypeKeys {}

// ActionCreators
export const actionCreators = {};

export interface State {
  isFetching: boolean;
}

export const initialState: State = {
  isFetching: false,
};

// Reducer
export const loginReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    default:
      return state;
  }
};
