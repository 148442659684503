import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { OperationStatus } from 'types/Operations';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

interface StatusLabelType {
  status: string;
}

const getStatusColor = (theme: any, status: string) => {
  if (status === OperationStatus.SUCCESS) {
    return theme.status.success;
  }
  if (status === OperationStatus.START) {
    return theme.status.pending;
  }
  if (status === OperationStatus.PARTIAL_SUCCESS) {
    return theme.status.warning;
  }
  if (status === OperationStatus.FAILURE) {
    return theme.status.error;
  }

  return theme.status.info;
};

export const StatusLabel = styled.span<StatusLabelType>`
  font-weight: 600;
  color: ${({ theme, status }) => getStatusColor(theme, status)};
`;

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 56px;
  z-index: 3;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const FilterRowColumn = styled.div`
  display: inline-flex;
  padding-right: 20px;
`;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
`;

export const FiltersRight = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 12px;

  & > button {
    margin-left: 10px;
  }
`;

export const FiltersColumnsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 50%;
`;

export const FiltersColumn = styled.div`
  min-width: 180px;
  margin-right: 24px;
  width: 100%;
`;

export const FiltersColumnWide = styled(FiltersColumn)``;

export const FiltersColumnDateRange = styled(FiltersColumn)`
  max-width: 210px;
`;

export const FiltersColumnClientName = styled(FiltersColumn)`
  max-width: 28%;
  min-width: 220px;
`;

export const TableWrapper = styled.div``;

export const ItemsCount = styled.span``;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.linkColor};
`;

export const TagList = styled.div`
  display: flex;
  align-items: center;
`;

interface TagListItemType {
  isActive: boolean;
}

export const TagListItem = styled.div<TagListItemType>`
  border: 1px solid ${({ theme, isActive }) => (!isActive ? theme.colors.dangerLightTransparent : theme.borders.grey)};
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 4px;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  background: ${({ theme, isActive }) => (!isActive ? theme.colors.dangerLightTransparent : theme.backgroundSecondary)};
`;

export const TableCellContent = styled.div`
  font-size: 12px;
  letter-spacing: -0.5px;
`;

export const TableCellItem = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: 400;

  strong {
    font-weight: 400;
    font-size: 11px;
    display: inline-block;
    width: 45px;
    letter-spacing: 0;
  }
`;

export const DateWrap = styled.div`
  overflow: hidden;
  user-select: text;
`;
