import { SyncMasterDataStatus, synMasterDataStatusNamesMapping } from 'types/SyncMasterData';

export const syncMasterDataStatusFilterOptions = [
  {
    id: 0,
    value: SyncMasterDataStatus.All,
    label: 'All',
  },
  {
    id: 1,
    value: SyncMasterDataStatus.NONE,
    label: synMasterDataStatusNamesMapping[SyncMasterDataStatus.NONE],
  },
  {
    id: 2,
    value: SyncMasterDataStatus.START,
    label: synMasterDataStatusNamesMapping[SyncMasterDataStatus.START],
  },
  {
    id: 3,
    value: SyncMasterDataStatus.PARTIAL_SUCCESS,
    label: synMasterDataStatusNamesMapping[SyncMasterDataStatus.PARTIAL_SUCCESS],
  },
  {
    id: 4,
    value: SyncMasterDataStatus.SUCCESS,
    label: synMasterDataStatusNamesMapping[SyncMasterDataStatus.SUCCESS],
  },
  {
    id: 5,
    value: SyncMasterDataStatus.FAILURE,
    label: synMasterDataStatusNamesMapping[SyncMasterDataStatus.FAILURE],
  },
];
