import styled from 'styled-components';

export const TabHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
`;

export const TabTitle = styled.h3`
  display: flex;
  align-items: baseline;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  min-height: 30px;
  margin: 0;
  letter-spacing: -0.5;
`;

export const FormWrapper = styled.form`
  position: relative;
`;

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface FormColumnType {
  width?: string;
}

export const FormColumn = styled.div<FormColumnType>`
  width: ${({ width }) => (width ? width : '47%')};
  flex: 0 0 ${({ width }) => (width ? width : '47%')};
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;

  & > button {
    margin-left: 20px;
    min-width: 100px;
  }
`;

export const ButtonBack = styled.span`
  margin-right: 10px;
`;

export const FormSection = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  margin-top: 20px;
  padding: 15px;

  &:first-child {
    margin: 0;
  }
`;

export const FormSectionHeader = styled.div`
  position: relative;
  padding: 0 40px 12px 20px;
  margin: 0 -15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.borders.grey};
`;

export const FormSectionTitle = styled.h3`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

export const PortalPropertyTitle = styled.div`
  display: flex;
  width: 150px;
  white-space: nowrap;
`;

export const PortalPropertyValue = styled.div`
  display: flex;
  font-weight: bold;
`;

export const CreateUserButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export const CreatePortalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const PortalNotExistTitle = styled.div`
  text-align: center;
`;
