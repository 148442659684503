import { IPAIAdminApiClient } from 'common/api/generated/PAIAdminApiClient';
import { IntegrationsSystems } from 'types/Integrations';

export const startFileTransfer = async (
  paiClient: IPAIAdminApiClient | undefined,
  transferInfo: { customerId: number; selectedClients: string[]; accessPointName: string },
): Promise<void> => {
  let { customerId, selectedClients, accessPointName } = transferInfo;

  switch (accessPointName) {

    case IntegrationsSystems.TRIPLETEX_ACCESSPOINT:
      // Do expect single client selected if target access point is tripletex
      if (selectedClients.length != 1) {
        throw new Error(`Please choose a target client with ${IntegrationsSystems.TRIPLETEX_ACCESSPOINT} integration in "Client Name" dropdown filter.`);
      }

      return await paiClient?.accessPointControllerTriggerEInvoiceSyncByClientId(customerId,
        parseInt(selectedClients[0]),
        {
          accessPointName: accessPointName,
          skipRejection: false
        });

    case IntegrationsSystems.SFTP:
      // Do expect single or no client selected if target access point is tripletex
      if (selectedClients.length > 1) {
        throw new Error(`Please choose a single target client with ${IntegrationsSystems.SFTP} integration in "Client Name" dropdown filter.`);
      }

      return await (
        selectedClients.length !== 1
          ? paiClient?.accessPointControllerTriggerEInvoiceSync(customerId,
              {
                accessPointName: accessPointName,
                skipRejection: false
              })
          : paiClient?.accessPointControllerTriggerEInvoiceSyncByClientId(customerId,
              parseInt(selectedClients[0]),
              {
                accessPointName: accessPointName,
                skipRejection: false
              })
      );

    default:
      // Do not expect client-level sycn for other access points
      if (selectedClients.length > 0) {
        throw new Error(`Client sync is not supported for ${accessPointName} access point.`);
      }

      return await paiClient?.accessPointControllerTriggerEInvoiceSync(customerId,
        {
          accessPointName: accessPointName,
          skipRejection: false
        });
  }

};
