import styled from 'styled-components';

export const Wrapper = styled.div`
  font: 13px/1.5 'Poppins', Arial, sans-serif;
  background-color: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme }) => theme.fontPrimary};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 16px);
`;

export const Content = styled.div``;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  margin: 0 0 20px;
  letter-spacing: -0.5;
`;

export const SubTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  letter-spacing: -0.5;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.linkColor};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 20px;
`;
