import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  getPlaceholderColor,
  getTextInputBackground,
  getTextInputBorder,
  getTextInputColor,
  getTextInputPadding,
} from './helpers';

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

interface InputErrorMessageProps {
  isError?: boolean;
  isPlaceholderHidden?: boolean;
}

export const InputErrorMessage = styled.div<InputErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;

export const TextInputContainer = styled.div`
  position: relative;
`;

export const TextInputWrapper = styled.div``;

interface TextInputType {
  isFocused?: boolean;
  isInputEmpty?: boolean;
  leftIcon?: ReactNode | undefined;
  isTypePassword?: boolean;
  isError?: boolean;
  textAlign?: string;
  rightPadding?: number;
  leftPadding?: number;
}

export const TextInput = styled.input<TextInputType>`
  box-sizing: border-box;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme, readOnly, disabled }) => getTextInputColor({ theme, readOnly, disabled })};
  background: ${({ theme, isError }) => getTextInputBackground({ theme, isError })};
  padding: ${({ leftIcon, isTypePassword }) => getTextInputPadding({ leftIcon, isTypePassword })};
  border: 1px solid ${({ theme, isFocused, isError }) => getTextInputBorder({ theme, isFocused, isError })};
  border-radius: 4px;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-weight: 400;
  }
}
`;

interface LabelProps {
  isError?: boolean;
  isInputEmpty?: boolean;
  disabled?: boolean;
  isPlaceholderHidden?: boolean;
  leftIcon?: ReactNode | undefined;
}

export const Label = styled.label<LabelProps>`
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
  padding: 0;
  color: ${({ theme, isError, disabled }) => getPlaceholderColor({ theme, isError, disabled })};
  z-index: 1;
  pointer-events: none;
`;

export const LeftIcon = styled.span`
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12px;
  top: 10px;
  cursor: default;

  svg {
    color: ${({ theme }) => theme.fontPrimary};
  }
`;

interface RightIconProps {
  isFocused?: boolean;
}

export const RightIcon = styled.span<RightIconProps>`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 10px;
  cursor: default;

  svg {
    color: ${({ theme }) => theme.fontPrimary};
  }
`;

export const WrapperVisibilityOff = styled.span`
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface WrapperVisibilityOnType {
  isDisabled?: boolean;
}

export const WrapperVisibilityOn = styled.span<WrapperVisibilityOnType>`
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
