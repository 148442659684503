import { Icon } from 'types/Icon';

export const WarningIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '4'} height={height || '17'} viewBox='0 0 4 17'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M0,15 C-1.3527075e-16,13.8954305 0.8954305,13 2,13 C3.1045695,13 4,13.8954305 4,15 C4,16.1045695 3.1045695,17 2,17 C0.8954305,17 1.3527075e-16,16.1045695 0,15 Z M0,9 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 C3.1045695,-2.02906125e-16 4,0.8954305 4,2 L4,9 C4,10.1045695 3.1045695,11 2,11 C0.8954305,11 1.3527075e-16,10.1045695 0,9 Z'
        id='path-WarningIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1175.000000, -3191.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(1055.000000, 134.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-WarningIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
