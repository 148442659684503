import styled from 'styled-components';

export const Content = styled.div`
  text-align: left;
  display: flex;
  justify-content: space-between;
  text-align: flex-start;
  flex: 1;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const ContentColumnLeft = styled.div`
  width: 33%;
`;

export const ContentColumnRight = styled.div`
  width: 63%;
  // overflow: hidden;
  max-height: calc(100vh - 288px);
`;

export const ContentColumnInner = styled.div`
  overflow-y: auto;
  max-height: 100%;
  margin-right: -12px;
  padding-right: 20px;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
`;

export const ListWrapper = styled.div`
  position: relative;
  min-height: 60px;
`;

export const List = styled.ul`
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
`;

interface ListItemType {
  isRequired: boolean;
}

export const ListItem = styled.li<ListItemType>`
  padding: 0 0 0 20px;
  margin-bottom: 5px;
  position: relative;
  word-break: break-all;
  font-weight: ${({ isRequired }) => (isRequired ? '600' : '400')};

  &:after {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.fontSecondary};
    border-radius: 50%;
    left: 5px;
    top: 6px;
  }
`;

export const Note = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.fontSecondary};

  p {
    margin: 0 0 5px;
  }
`;

export const NoteMark = styled.span`
  color: ${({ theme }) => theme.fontError};
`;

export const UploadWrapper = styled.div`
  padding: 10px 0;
`;

export const UploadInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  text-align: center;
`;

export const UploadInfoTitle = styled.h3`
  margin: 0;
  font-size: 16px;
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;
