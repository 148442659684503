export const notificationStatusFilterOptions = [
  {
    id: 0,
    value: '',
    label: 'All',
  },
  {
    id: 1,
    value: 'true',
    label: 'Read',
  },
  {
    id: 2,
    value: 'false',
    label: 'Unread',
  },
];
