import { ApproverReferenceAttributes } from 'common/api/generated/PAIAdminApiClient';
import { Button, DataTable, Message, Spinner } from 'common/components';
import { ColumnItem } from 'common/components/DataTable/types';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { useRemoteApiCall } from 'hooks/useRemoteApiCall';
import { ReactElement, useEffect, useState } from 'react';
import { TableCellWrapper, TableHeader, TableHeaderActions, TableTitle, TableWrapper } from './styles';

interface IApproverRefrencesGridProps {
  customerId: string;
  clientId: string;
}

interface ApproverReferenceGridRecord {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: ApproverReferenceAttributes;
}

const ApproverRefrencesGrid = ({ customerId, clientId }: IApproverRefrencesGridProps) => {

  const paiAdminClient = useAuthenticatedAdminApiClient();
  const [isFetching, callRemoteApi] = useRemoteApiCall();
  const [approverReferences, setApproverReferences] = useState<ApproverReferenceAttributes[]>([]);
  const [useApproverReferenceSyncFeature, setUseApproverReferenceSyncFeature] = useState<boolean>(false);

  // TODO - fix memory leak and dependencies warning
  useEffect(() => {
    (async () => {
      const result = await callRemoteApi(() =>
        paiAdminClient?.transformationsControllerGetApproverReferences(parseInt(customerId), parseInt(clientId)),
      );
      setApproverReferences(result?.references || []);
      setUseApproverReferenceSyncFeature(result?.transformationSyncEnabled || false);
    })();
  }, [paiAdminClient, clientId, customerId, callRemoteApi]);

  const onSyncReferencesClick = () =>
    callRemoteApi(() =>
      paiAdminClient?.syncMasterDataControllerExecuteApproverReferencesSync(parseInt(customerId), parseInt(clientId)),
    );

  const columns: ColumnItem<ApproverReferenceGridRecord>[] = [
    {
      name: 'inputReference',
      title: <Message id='client-approver-references-grid-inputReference-id' />,
      width: 120,
      cellCallback: ({ row }) => {
        return <TableCellWrapper>{row.inputReference}</TableCellWrapper>;
      },
    },
    {
      name: 'outputReferenceId',
      title: <Message id='client-approver-references-grid-outputReferenceId-id' />,
      width: 120,
      cellCallback: ({ row }) => {
        return <TableCellWrapper>{row.outputReferenceId}</TableCellWrapper>;
      },
    },
    {
      name: 'outputReferenceName',
      title: <Message id='client-approver-references-grid-outputReferenceName-id' />,
      width: 120,
      cellCallback: ({ row }) => {
        return <TableCellWrapper>{row.outputReferenceName}</TableCellWrapper>;
      },
    },
  ];

  return (
    <TableWrapper>
      <TableHeader>
        <TableTitle>
          <Message id='client-approver-references-grid-title' />
        </TableTitle>
        <TableHeaderActions>
          <Button primary disabled={isFetching || !useApproverReferenceSyncFeature} onClick={onSyncReferencesClick}>
            <Message id='client-approver-references-grid-sync-approvers-button' />
          </Button>
        </TableHeaderActions>
      </TableHeader>
      <DataTable
        isFetching={isFetching}
        data={approverReferences}
        loadingComponent={Spinner}
        columns={columns}
        emptyMessage={<Message id='no-data-message' />}
        rowKey={(_: ApproverReferenceAttributes, index?: number) => (index || 0).toString()}
      />
    </TableWrapper>
  );
};

export default ApproverRefrencesGrid;
