import styled from 'styled-components';

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface FormColumnType {
  columnWidth?: string;
}

export const FormColumn = styled.div<FormColumnType>`
  width: ${({ columnWidth }) => (columnWidth ? columnWidth : '47%')};
  flex: 0 0 ${({ columnWidth }) => (columnWidth ? columnWidth : '47%')};

  & > div {
    width: 100%;
  }
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

interface FormGroupType {
  withPadding: boolean;
}

export const FormGroup = styled.div<FormGroupType>`
  padding-left: ${({ withPadding }) => (withPadding ? '20px' : 0)};
  width: 100%;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 44px;
  min-height: 44px;
  margin: 0;
  letter-spacing: -0.5;
`;

export const FormSubTitle = styled.h4`
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 10px;
`;

export const FormRowSwitcher = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const FormRowSwitcherLabel = styled.span`
  font-size: 12px;
  line-height: 24px;
  padding-right: 10px;
`;

export const FormListItem = styled.div`
  margin-bottom: 10px;
`;

export interface FormButtonsType {
  isEditPage?: boolean;
}

export const FormButtons = styled.div<FormButtonsType>`
  padding-top: 10px;
  display: flex;
  justify-content: ${({ isEditPage }) => (isEditPage ? 'space-between' : 'flex-end')};

  & > button {
    margin-left: 20px;
    min-width: 100px;
  }
`;

export const FormButtonsLeft = styled.div``;
export const FormButtonsRight = styled.div`
  display: flex;

  & > button {
    margin-left: 20px;
    min-width: 100px;
  }
`;

export const FormEditButton = styled.span`
  position: absolute;
  right: 15px;
  top: 75px;
  z-index: 1;
`;

export const IconCircle = styled.div`
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.fontError};
  border: 2px solid ${({ theme }) => theme.fontError};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const WarningTitle = styled.div`
  padding-right: 40px;
  color: ${({ theme }) => theme.fontError};
  display: inline-flex;
  align-items: center;
`;

export const ReasonBlock = styled.div`
  text-align: left;
  padding: 0 40px;
`;

export const ModalText = styled.h3`
  margin: 0 0 10px;
`;

export const FormButtonsCentered = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    min-width: 100px;
    margin: 0 10px;
  }
`;
