import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/customerReducer';
import { getIntegrationView } from '../../logic/customerSelectors';
import IntegrationEdit from './IntegrationEdit';

const mapStateToProps = ({ customer }: RootState) => getIntegrationView(customer);

const mapDispatchToProps = {
  getCustomer: actionCreators.getCustomer,
  setStatus: actionCreators.setCustomerStatus,
  getCustomerIntegration: actionCreators.getCustomerIntegration,
  getCustomerConfigurationSchema: actionCreators.getCustomerConfigurationSchema,
  updateCustomerIntegration: actionCreators.updateCustomerIntegration,
  updateCustomerIntegrationStatus: actionCreators.updateCustomerIntegrationStatus,
  getCustomerTestConnection: actionCreators.getCustomerTestConnection,
  resetPartial: actionCreators.customerResetPartial,
  integrationReset: actionCreators.integrationReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationEdit);
