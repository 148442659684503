import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import {
  ConfigurationSchemaRequest,
  DefaultSettingsRequestData,
  IntegrationData,
  IntegrationSettingsDefault,
  IntegrationSchema,
  SystemsSettingsRequest,
} from 'types/Integrations';

// Actions
export enum ActionTypeKeys {
  GET_CONFIGURATION_SCHEMA = 'INTEGRATIONS_DEFAULT/GET_CONFIGURATION_SCHEMA',
  GET_CONFIGURATION_SCHEMA_SUCCESS = 'INTEGRATIONS_DEFAULT/GET_CONFIGURATION_SCHEMA_SUCCESS',
  GET_CONFIGURATION_SCHEMA_FAILED = 'INTEGRATIONS_DEFAULT/GET_CONFIGURATION_SCHEMA_FAILED',
  GET_DEFAULT_SYSTEM_SETTINGS = 'INTEGRATIONS_DEFAULT/GET_DEFAULT_SYSTEM_SETTINGS',
  GET_DEFAULT_SYSTEM_SETTINGS_SUCCESS = 'INTEGRATIONS_DEFAULT/GET_DEFAULT_SYSTEM_SETTINGS_SUCCESS',
  GET_DEFAULT_SYSTEM_SETTINGS_FAILED = 'INTEGRATIONS_DEFAULT/GET_DEFAULT_SYSTEM_SETTINGS_FAILED',
  UPDATE_DEFAULT_SETTINGS = 'INTEGRATIONS_DEFAULT/UPDATE_DEFAULT_SETTINGS',
  UPDATE_DEFAULT_SETTINGS_SUCCESS = 'INTEGRATIONS_DEFAULT/UPDATE_DEFAULT_SETTINGS_SUCCESS',
  UPDATE_DEFAULT_SETTINGS_FAILED = 'INTEGRATIONS_DEFAULT/UPDATE_DEFAULT_SETTINGS_FAILED',
  RESET_PARTIAL = 'INTEGRATIONS_DEFAULT/RESET_PARTIAL',
  RESET_INTEGRATION_DEFAULT = 'INTEGRATIONS_DEFAULT/RESET_INTEGRATION_DEFAULT',
}

export const actionCreators = {
  getConfigurationSchema: (payload: ConfigurationSchemaRequest) =>
    createAction(ActionTypeKeys.GET_CONFIGURATION_SCHEMA, payload),
  getConfigurationSchemaSuccess: (payload: IntegrationSchema) =>
    createAction(ActionTypeKeys.GET_CONFIGURATION_SCHEMA_SUCCESS, payload),
  getConfigurationSchemaFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CONFIGURATION_SCHEMA_FAILED, payload),

  getDefaultSystemSettings: (payload: SystemsSettingsRequest) =>
    createAction(ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS, payload),
  getDefaultSystemSettingsSuccess: (payload: IntegrationSettingsDefault) =>
    createAction(ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS_SUCCESS, payload),
  getDefaultSystemSettingsFailed: () => createAction(ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS_FAILED),

  updateDefaultSettings: (payload: DefaultSettingsRequestData) =>
    createAction(ActionTypeKeys.UPDATE_DEFAULT_SETTINGS, payload),
  updateDefaultSettingsSuccess: (payload: IntegrationSettingsDefault) =>
    createAction(ActionTypeKeys.UPDATE_DEFAULT_SETTINGS_SUCCESS, payload),
  updateDefaultSettingsFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_DEFAULT_SETTINGS_FAILED, payload),

  resetPartial: () => createAction(ActionTypeKeys.RESET_PARTIAL),
  resetIntegrationDefault: () => createAction(ActionTypeKeys.RESET_INTEGRATION_DEFAULT),
};

// State
export interface State {
  isFetching: boolean;
  integrationSchema: IntegrationSchema;
  integration: IntegrationData;
  errorCode: number | null;
}

export const initialState: State = {
  isFetching: false,
  integrationSchema: {} as IntegrationSchema,
  integration: {} as IntegrationData,
  errorCode: null,
};

// Reducer
export const integrationsDefaultSettingsReducer: Reducer<State> = (
  state: State = initialState,
  action: AnyAction,
): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CONFIGURATION_SCHEMA:
      return {
        ...state,
        integrationSchema: initialState.integrationSchema,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CONFIGURATION_SCHEMA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integrationSchema: action.payload,
        errorCode: null,
      };

    case ActionTypeKeys.GET_CONFIGURATION_SCHEMA_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integration: action.payload,
      };

    case ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.UPDATE_DEFAULT_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_DEFAULT_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integration: action.payload,
      };

    case ActionTypeKeys.UPDATE_DEFAULT_SETTINGS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.RESET_PARTIAL:
      return {
        ...state,
        errorCode: null,
      };

    case ActionTypeKeys.RESET_INTEGRATION_DEFAULT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
