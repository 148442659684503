import { availableSystemsToOptions, prepareSelectOptionsFromDictionaries } from 'utils';
import { Dictionaries } from 'common/constants';
import {
  operationScopeTypeNamesMapping,
  operationStatusNamesMapping,
  operationTypeNamesMapping,
} from 'types/Operations';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { State as ClientState } from './clientReducer';

// client

export const getClientView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  errorCode: clientState.errorCode,
  client: clientState.client,
  integrations: clientState.integrations,
});

// integrations

export const getIntegrationsView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  client: clientState.client,
  integrations: clientState.integrations,
  availableSystems: clientState.availableSystems,
});

export const getIntegrationView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  isTestConnectionFetching: clientState.isTestConnectionFetching,
  errorCode: clientState.errorCode,
  integrationSchema: clientState.integrationSchema,
  client: clientState.client,
  integration: clientState.integration,
});

export const getIntegrationCreateView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  errorCode: clientState.errorCode,
  integration: clientState.integration,
  client: clientState.client,
  availableSystems: availableSystemsToOptions(clientState.availableSystems),
  integrationSchema: clientState.integrationSchema,
});

// transformations

export const getTransformationsView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  errorCode: clientState.errorCode,
  client: clientState.client,
  availableTransformationTypes: clientState.availableTransformationTypes,
  transformations: clientState.transformations,
});

export const getTransformationView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  errorCode: clientState.errorCode,
  client: clientState.client,
  transformation: clientState.transformation,
  transformationSchema: clientState.transformationSchema,
});

export const getTransformationCreateView = (clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  errorCode: clientState.errorCode,
  client: clientState.client,
  availableTransformationTypes: clientState.availableTransformationTypes,
  transformations: clientState.transformations,
  transformation: clientState.transformation,
  transformationSchema: clientState.transformationSchema,
});

// operations

export const getOperationsView = (sharedState: SharedState, clientState: ClientState) => ({
  isFetching: clientState.isFetching,
  client: clientState.client,
  operations: clientState.operations.data,
  totalCount: clientState.operations.totalCount,
  page: clientState.operations.page,
  perPage: clientState.operations.perPage,
  orderBy: clientState.operations.orderBy,
  sortOrder: clientState.operations.sortOrder,
  filterScope: clientState.operations.filterScope,
  filterType: clientState.operations.filterType,
  filterStatus: clientState.operations.filterStatus,
  filterDateFrom: clientState.operations.filterDateFrom,
  filterDateTo: clientState.operations.filterDateTo,
  filterTraceId: clientState.operations.filterTraceId,
  filterSourceEntityId: clientState.operations.filterSourceEntityId,
  filterTargetEntityId: clientState.operations.filterTargetEntityId,
  operationsScopeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_SCOPE_TYPES],
    operationScopeTypeNamesMapping,
  ),
  operationsTypeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_TYPES],
    operationTypeNamesMapping,
  ),
  operationsStatusOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_STATUSES],
    operationStatusNamesMapping,
  ),
});
