import { ReactNode, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import {
  SelectContainer,
  SelectHolder,
  SelectWrap,
  TextLeftIcon,
  Label,
  TextErrorMessage,
  SelectButtonWrapper,
  SelectHint,
} from './styles';
import { customStyles } from './customReactSelectStyles';
import {
  Control,
  SingleValue,
  MultiValueContainer,
  DropdownIndicator,
  ClearIndicator,
  // eslint-disable-next-line
  Option,
  MultiValueLabel,
} from './customReactSelectComponents';

const SelectStyled = styled(Select)``;

interface CustomSelectProps {
  isFloating?: boolean;
  isError?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  closeMenuOnSelect?: boolean;
  isSearchable?: boolean;
  isRequired?: boolean;
  id?: string;
  menuPlacement?: string;
  maxMenuHeight?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  value?: { value: string } | object[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  options: object[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  defaultValue?: { value: string } | object[];
  label?: ReactNode;
  placeholder?: string;
  leftIcon?: ReactNode;
  errorText?: ReactNode;
  hintText?: ReactNode;
  button?: ReactNode;
  onBlur?: (payload: any) => void;
  onChange?: (payload: any) => void;
  onFocus?: (payload: any) => void;
}

export const CustomSelect = ({
  isError,
  isMulti,
  isClearable,
  id,
  menuPlacement,
  maxMenuHeight,
  label,
  placeholder = '',
  options,
  defaultValue,
  disabled,
  closeMenuOnSelect,
  isSearchable,
  isRequired,
  leftIcon,
  errorText,
  hintText,
  button,
  value,
  onBlur,
  onChange,
  onFocus,
}: CustomSelectProps) => {
  const [isFocused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(undefined);
  /* eslint-disable */
  const isInputEmpty = isMulti && Array.isArray(value) ? !Boolean(value.length > 0) : !Boolean(value || currentValue);
  /* eslint-enable */
  const handleFocusChange = (event: any) => {
    if (onFocus && onBlur) {
      event.type === 'focus' ? onFocus(event) : onBlur(event);
    }
    setFocused(!isFocused);
  };

  const handleOnChange = (selectValue: any) => {
    setCurrentValue(selectValue);
    if (onChange) {
      onChange(selectValue);
    }
  };

  const renderLeftIcon = () => <TextLeftIcon>{leftIcon}</TextLeftIcon>;

  const renderErrorText = () => <TextErrorMessage isError={isError}>{errorText}</TextErrorMessage>;

  const renderHintText = () => <SelectHint>{hintText}</SelectHint>;

  const renderButton = () => <SelectButtonWrapper>{button}</SelectButtonWrapper>;

  return (
    <SelectContainer>
      {label && (
        <Label htmlFor={id} isInputEmpty={isInputEmpty} isDisabled={disabled} isError={isError}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
      )}
      <SelectHolder>
        {leftIcon && renderLeftIcon()}
        <SelectWrap withAdditionButton={!!button} isMulti={isMulti} isError={isError} isDisabled={disabled}>
          <SelectStyled
            menuPlacement={menuPlacement}
            id={id}
            options={options}
            value={value}
            defaultValue={defaultValue}
            isDisabled={disabled}
            isError={isError}
            isInputEmpty={isInputEmpty}
            isMulti={isMulti}
            isClearable={isClearable}
            onChange={handleOnChange}
            onBlur={handleFocusChange}
            onFocus={handleFocusChange}
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            styles={customStyles}
            placeholder={placeholder}
            components={{
              Control,
              SingleValue,
              MultiValueContainer,
              ClearIndicator,
              DropdownIndicator,
              Option,
              MultiValueLabel,
            }}
            maxMenuHeight={maxMenuHeight}
          />
          {isError && errorText && renderErrorText()}
        </SelectWrap>
        {button && renderButton()}
      </SelectHolder>
      {hintText && renderHintText()}
    </SelectContainer>
  );
};

export default CustomSelect;
