import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators as customerActionCreators } from 'screens/Customer/logic/customerReducer';
import { actionCreators as sharedActionCreators } from 'common/reducers/sharedReducer';
import { actionCreators } from './logic/sidebarReducer';
import Sidebar from './Sidebar';
import { getSidebarView } from './logic/sidebarSelectors';
const mapStateToProps = ({ customer, sidebar }: RootState) => getSidebarView(sidebar, customer);

const mapDispatchToProps = {
  getSidebarInfo: actionCreators.getSidebarInfo,
  getCustomerAndAllClientsIntegrations: customerActionCreators.getCustomerClientAllIntegrations,
  setMainNavCollapsed: sharedActionCreators.setMainNavCollapsed,
  setSubNavCollapsed: sharedActionCreators.setSubNavCollapsed,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(Sidebar);
