import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/clientReducer';
import { getClientView } from './logic/clientSelectors';
import Client from './Client';

const mapStateToProps = ({ client }: RootState) => getClientView(client);

const mapDispatchToProps = {
  getClient: actionCreators.getClient,
  updateClient: actionCreators.updateClient,
  setStatus: actionCreators.setClientStatus,
  reset: actionCreators.clientReset,
  resetPartial: actionCreators.clientResetPartial,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(Client);
