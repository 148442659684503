import { integrationsServiceRequest } from 'config';
import { API_URLS } from 'common/constants';
import { OperationsRequestData } from 'types/Operations';
import { setAuthHeaders } from 'utils/auth';

export const operationsService = {
  getOperations: ({ accessToken, ...requestData }: OperationsRequestData) =>
    integrationsServiceRequest.post(API_URLS.operationsService.operations, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
};
