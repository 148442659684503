import styled from 'styled-components';
import backgroundImage from 'assets/images/backgroundMain.jpg';
import { Button } from 'common/components/Buttons/Button';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const LoginContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
`;

export const LoginHolder = styled.div`
  background-color: #fff;
  border-radius: 5px;
  min-height: 200px;
  padding: 20px;
  width: 400px;
`;

export const LogoContainer = styled.div`
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h2`
  font-size: 34px;
  margin: 0 0 20px;
  text-align: center;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  margin: 0 0 35px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  min-height: 48px;
`;

export const ButtonCentered = styled(Button)`
  margin: 0 auto;
  width: 140px;
`;
