import { Dispatch } from 'redux';
import { NotificationType } from 'types/Notifications';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

type ApiCallActionFn<Result> = () => Promise<Result> | undefined;

export const callRemoteApiSafely = async <Result>(
  errorDispatcher: Dispatch,
  apiCallAction: ApiCallActionFn<Result>,
): Promise<Result | undefined> => {
  let result: Result | undefined = undefined;
  try {
    result = await apiCallAction();
  } catch (error) {
    const errorLocal = error as any;
    errorDispatcher(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
  }

  return result;
};

export const useRemoteApiCall = (): [
  boolean,
  <Result>(apiCallAction: ApiCallActionFn<Result>) => Promise<Result | undefined>,
] => {
  const count = useRef(0); // keep number of ongoing requests between re-renders
  const [isFetching, setIsFetching] = useState<boolean>(count.current > 0);

  // We ought to use dispatcher to combine hooks with redux store
  const errorDispatcher = useDispatch();

  // keep callRemoteApi func instance between re-renders to avoid cyclic change notifications
  // when effect relies upon callRemoteApi func
  const callRemoteApi = useRef(async <Result>(action: ApiCallActionFn<Result>) => {
    setIsFetching((count.current += 1) > 0);
    const result = await callRemoteApiSafely(errorDispatcher, action);
    setIsFetching((count.current -= 1) > 0);
    return result;
  });

  return [isFetching, callRemoteApi.current];
};
