import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/clientReducer';
import { getIntegrationsView } from '../../logic/clientSelectors';
import IntegrationsList from './IntegrationsList';

const mapStateToProps = ({ client }: RootState) => getIntegrationsView(client);

const mapDispatchToProps = {
  getClientIntegrations: actionCreators.getClientIntegrations,
  reset: actionCreators.integrationsReset,
  getAvailableSystems: actionCreators.getClientAvailableSystems,
  getClientTestConnection: actionCreators.getClientTestConnection,
  updateClientIntegrationStatus: actionCreators.updateClientIntegrationStatus,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationsList);
