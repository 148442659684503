import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Message, Modal } from 'common/components';
import { DynamicFormElement } from 'common/components/DynamicFormElement';
import { ConfigurationLevel } from 'types/Configuration';
import { FormDataTypes } from 'common/constants/Form';
import { PenIcon, WarningIcon } from 'common/components/Icons';
import SectionGroupItem from './components/SectionGroupItem';

import {
  convertToColumns,
  getAllFieldsFromSchema,
  getCurrentFormData,
  isObjectNotEmpty,
  prepareDefaultValues,
  getExtendSchema,
  convertToChildColumns,
} from './utils';

import {
  FormColumnWrapper,
  FormColumn,
  FormGroup,
  FormRow,
  FormHeader,
  FormSubTitle,
  FormListItem,
  FormButtons,
  FormEditButton,
  FormButtonsCentered,
  IconCircle,
  ModalText,
  WarningTitle,
  FormButtonsLeft,
  FormButtonsRight,
} from './styles';

interface ConfigurationFormProps {
  isFetching: boolean;
  errorCode: number | null;
  name: string;
  settings: any;
  schema: any;
  level: ConfigurationLevel;
  isEdit?: boolean;
  showTestConnectionButton?: boolean;
  withConfirmation?: boolean;
  testConnection?: () => void;
  onSubmit: (payload: any) => void;
  onCancel?: () => void;
  resetForm: () => void;
}

const ConfigurationForm = ({
  isFetching,
  errorCode,
  level,
  name,
  settings,
  schema,
  isEdit,
  showTestConnectionButton,
  withConfirmation,
  testConnection,
  onCancel,
  onSubmit,
  resetForm,
}: ConfigurationFormProps) => {
  const isDefaultLevel = level === ConfigurationLevel.DEFAULT;
  const isEditPage = isDefaultLevel || (isEdit ? true : false);
  const [formColumns, setFormColumns] = useState<any>({});
  const [defaultFormValues, setDefaultFormValues] = useState<any>({});
  const [allFields, setAllFields] = useState<any>([]);
  const [isFormDisabled, setFormDisabled] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [currentFormData, setCurrentFormData] = useState([]);
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);

  const {
    handleSubmit,
    formState: { isValid, dirtyFields },
    control,
    reset,
    getValues,
    watch,
    clearErrors,
  } = useForm({ mode: 'onChange' });
  const currentValues = getValues();
  const isDirty = isObjectNotEmpty(dirtyFields);

  const actualSettings = useMemo(() => {
    return !Array.isArray(settings) ? settings : { currentSettings: settings, parentSettings: [] };
  }, [settings]);

  useEffect(() => {
    setFormColumns({});
  }, [name]);

  useEffect(() => {
    setFormDisabled(isEditPage);
  }, [isEditPage]);

  useEffect(() => {
    const extendSchema = getExtendSchema(schema);
    if (isObjectNotEmpty(actualSettings)) {
      const allFields = getAllFieldsFromSchema(extendSchema, actualSettings);
      setAllFields(allFields);
    }
  }, [schema, actualSettings]);

  useEffect(() => {
    if (isObjectNotEmpty(schema)) {
      setDefaultFormValues(
        prepareDefaultValues(
          isDefaultLevel,
          actualSettings,
          !isEditPage,
          allFields,
          currentFormData,
          formColumns,
          currentValues,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFields, actualSettings, currentFormData, formColumns, schema]);

  useEffect(() => {
    return () => setCurrentFormData([]);
  }, [schema]);

  useEffect(() => {
    if (isObjectNotEmpty(defaultFormValues)) {
      reset(defaultFormValues, {
        keepErrors: true,
        keepDirty: true,
      });
    }
  }, [defaultFormValues, reset, allFields]);

  useEffect(() => {
    if (isObjectNotEmpty(schema)) {
      setFormColumns(convertToColumns(schema));
    }
    return () => resetForm();
  }, [actualSettings, schema, resetForm]);

  useEffect(() => {
    if (isRequestPending) {
      if (errorCode) {
        setFormDisabled(() => false);
      } else {
        setFormDisabled(() => true);
      }
    }
  }, [errorCode, isRequestPending]);

  const onEditButtonHandler = useCallback(() => {
    setFormDisabled(false);
    setIsRequestPending(false);
  }, []);

  const onCancelEditButtonClickHandler = useCallback(() => {
    setCurrentFormData([]);
    if (defaultFormValues) {
      reset(defaultFormValues);
    }
    setFormDisabled(true);
    setIsRequestPending(false);
  }, [reset, defaultFormValues, setFormDisabled]);

  const onSwitchChangeHandler = useCallback(
    (value: any, section: any) => {
      const isSectionOverride = value.target.checked;
      const sectionFieldIds = section.fields.map((section: any) => section.fieldId);
      if (!isSectionOverride) {
        clearErrors([...sectionFieldIds]);
      }
      const currentValues = getValues();
      const currentFormData = getCurrentFormData(isSectionOverride, allFields, actualSettings, currentValues, section);

      setCurrentFormData(currentFormData);
    },
    [getValues, clearErrors, allFields, actualSettings],
  );

  const onSubmitButtonHandler = useCallback(
    (data: any) => {
      onSubmit({ data });
      setIsRequestPending(true);
      if (isEditPage) {
        setCurrentFormData([]);
      }
    },
    [isEditPage, onSubmit],
  );

  const getSection = (section: any, isSectionOverride: boolean) => {
    if (
      (!Object.prototype.hasOwnProperty.call(section, 'fieldGroups') ||
        !Object.prototype.hasOwnProperty.call(section, 'childFieldGroups') ||
        !Object.prototype.hasOwnProperty.call(section, 'fields')) &&
      Object.prototype.hasOwnProperty.call(section, 'dataType')
    ) {
      if (section.dataType === FormDataTypes.BOOLEAN) {
        const isCheckboxRequired = section.isOverridable ? false : section.isMandatory;
        return (
          <FormListItem key={`${section.name}`}>
            <DynamicFormElement
              fieldId={section.fieldId}
              defaultValue={''}
              control={control}
              isRequired={isCheckboxRequired}
              name={section.name}
              disabled={isFormDisabled || (section.isOverridable && !isSectionOverride)}
              dataType={section.dataType}
              validationRegex={section.validationRegex}
              isEncrypted={section.isEncrypted}
            />
          </FormListItem>
        );
      }

      return (
        <FormRow key={`${section.name}`}>
          <DynamicFormElement
            fieldId={section.fieldId}
            defaultValue={''}
            control={control}
            isRequired={section.isMandatory}
            name={section.name}
            disabled={isFormDisabled || (section.isOverridable && !isSectionOverride)}
            dataType={section.dataType}
            validationRegex={section.validationRegex}
            isEncrypted={section.isEncrypted}
          />
        </FormRow>
      );
    }

    const innerSections = convertToChildColumns(section);

    return (
      <>
        <FormHeader>
          {section.hasParent && (
            <FormSubTitle>{section.name ? <Message id={section.name} /> : section.name}</FormSubTitle>
          )}
        </FormHeader>
        <FormGroup withPadding={section.hasParent}>
          <FormColumnWrapper>{renderColumns(innerSections.fieldsColumns, true, isSectionOverride)}</FormColumnWrapper>
          <FormColumnWrapper>{renderColumns(innerSections.groupsColumns, true, isSectionOverride)}</FormColumnWrapper>
        </FormGroup>
      </>
    );
  };

  const renderColumns = (columns: any, isChild = true, isSectionOverride: boolean) => {
    return (
      isObjectNotEmpty(columns) &&
      Object.keys(columns).map((key) => {
        const columnsLength = Object.keys(columns).length;
        const columnWidth = columnsLength > 1 ? `${100 / columnsLength - 3}%` : isChild ? '100%' : '47%';

        return (
          <FormColumn key={key} columnWidth={columnWidth}>
            {columns[key].map((item: any) => {
              return <div key={item.groupName}>{getSection(item, isSectionOverride)}</div>;
            })}
          </FormColumn>
        );
      })
    );
  };

  const renderMainColumns = (columns: any, isChild = true) => {
    return (
      isObjectNotEmpty(columns) &&
      Object.keys(columns).map((key, index) => {
        const columnsLength = Object.keys(columns).length;
        const columnWidth = columnsLength > 1 ? `${100 / columnsLength - 3}%` : isChild ? '100%' : '47%';
        return (
          <FormColumn key={key + index} columnWidth={columnWidth}>
            {columns[key].map((item: any) => {
              return item.groupName ? (
                <SectionGroupItem
                  key={item.groupName}
                  groupName={item.groupName}
                  group={item}
                  level={level}
                  watch={watch}
                  control={control}
                  isFormDisabled={isFormDisabled}
                  onSwitchChangeHandler={onSwitchChangeHandler}
                  getSection={getSection}
                  configurationData={{}}
                />
              ) : (
                <div key={item.groupName}>{getSection(item, false)}</div>
              );
            })}
          </FormColumn>
        );
      })
    );
  };

  const renderFormButtons = () => {
    if (isEditPage) {
      return (
        <FormButtons isEditPage={isEditPage}>
          <FormButtonsLeft>
            {showTestConnectionButton ? (
              <Button secondary onClick={testConnection} disabled={!isFormDisabled}>
                <Message id={'test-connection'} />
              </Button>
            ) : null}
          </FormButtonsLeft>
          {!isFormDisabled ? (
            <FormButtonsRight>
              <Button transparent onClick={onCancelEditButtonClickHandler}>
                <Message id={'cancel-button'} />
              </Button>
              {withConfirmation ? (
                <Button disabled={!isDirty || !isValid} primary onClick={() => setWarningModalVisible(true)}>
                  <Message id={'save-button'} />
                </Button>
              ) : (
                <Button
                  disabled={isFetching || !isDirty || !isValid}
                  primary
                  onClick={handleSubmit((data) => onSubmitButtonHandler(data))}
                >
                  <Message id={'save-button'} />
                </Button>
              )}
            </FormButtonsRight>
          ) : null}
        </FormButtons>
      );
    }

    return (
      <FormButtons>
        <Button transparent onClick={onCancel}>
          <Message id={'cancel-button'} />
        </Button>
        {withConfirmation ? (
          <Button disabled={!isValid} primary onClick={() => setWarningModalVisible(true)}>
            <Message id={'save-button'} />
          </Button>
        ) : (
          <Button
            disabled={isFetching || !isValid}
            primary
            onClick={handleSubmit((data) => onSubmitButtonHandler(data))}
          >
            <Message id={'save-and-proceed-button'} />
          </Button>
        )}
      </FormButtons>
    );
  };

  // WARNING MODAL ACTIONS
  const onModalCancelHandler = useCallback(() => {
    setWarningModalVisible(false);
  }, []);

  const onModalConfirmHandler = useCallback(
    (data: any) => {
      setWarningModalVisible(false);
      return onSubmitButtonHandler(data);
    },
    [onSubmitButtonHandler],
  );

  const modalFooterButtons = useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onModalCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button primary onClick={handleSubmit((data) => onModalConfirmHandler(data))}>
          <Message id='confirm-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onModalCancelHandler, onModalConfirmHandler, handleSubmit],
  );

  // WARNING MODAL ELEMENTS
  const warningModalTitle = () => {
    return (
      <WarningTitle>
        <IconCircle>
          <WarningIcon height={14} width={3} />
        </IconCircle>
        <Message id='modal-title-warning' />
      </WarningTitle>
    );
  };

  // if (integrationName === IntegrationsSystems.UNKNOWN || !isObjectNotEmpty(formColumns)) {
  //   return null;
  // }

  if (!name) {
    return <></>;
  }

  return (
    <>
      {isEditPage && (
        <FormEditButton>
          <Button primary disabled={!isFormDisabled} onClick={onEditButtonHandler}>
            <PenIcon />
          </Button>
        </FormEditButton>
      )}
      <FormColumnWrapper>{renderMainColumns(formColumns.groupsColumns, false)}</FormColumnWrapper>
      <FormColumnWrapper>{renderMainColumns(formColumns.fieldsColumns, false)}</FormColumnWrapper>
      <div>{renderFormButtons()}</div>

      <Modal
        hideOnOverlayClicked={false}
        closeOnEsc={false}
        isVisible={isWarningModalVisible}
        title={warningModalTitle()}
        onCloseClicked={onModalCancelHandler}
        footer={modalFooterButtons}
      >
        <ModalText>
          <Message
            id='integrations-default-warning-modal-are-you-sure-you-want-change-default-settings'
            values={{
              variable: name,
            }}
          />
        </ModalText>
        <p>
          {/* eslint-disable-next-line max-len */}
          <Message id='integrations-default-warning-modal-these-changes-will-affect-settings-all-customers-and-clients' />
        </p>
      </Modal>
    </>
  );
};

export default ConfigurationForm;
