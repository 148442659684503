import { SpinnerComponent, SpinnerOverlay } from './styles';

interface SpinnerProps {
  overlay?: boolean;
  isVisible?: boolean;
  isFixed?: boolean;
  transparent?: boolean;
}

const Spinner = ({ overlay = true, isVisible = true, isFixed = false, transparent = false }: SpinnerProps) => {
  const Component = <SpinnerComponent isVisible={isVisible} />;

  if (overlay) {
    return (
      <SpinnerOverlay isVisible={isVisible} isFixed={isFixed} transparent={transparent}>
        {Component}
      </SpinnerOverlay>
    );
  }
  return Component;
};

export default Spinner;
