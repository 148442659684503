import { ReactNode } from 'react';

export const defineButtonBackground = (
  theme: any,
  primary?: boolean | string,
  secondary?: boolean | string,
  danger?: boolean | string,
  success?: boolean | string,
  pending?: boolean | string,
  transparent?: boolean | string,
  iconButton?: boolean | string,
  circleButton?: boolean | string,
  isBgTransparent?: boolean | string,
  disabled?: boolean,
) => {
  if (primary || primary === 'true') {
    return {
      default: isBgTransparent ? theme.buttons.primaryTransparent : theme.buttons.primary,
      hover: disabled ? theme.buttons.primaryTransparent : theme.buttons.primary,
      active: theme.buttons.primary,
    };
  }

  if (secondary || secondary === 'true') {
    return {
      default: isBgTransparent ? theme.buttons.secondaryTransparent : theme.buttons.secondary,
      hover: disabled ? theme.buttons.secondaryTransparent : theme.buttons.secondary,
      active: theme.buttons.secondary,
    };
  }

  if (danger || danger === 'true') {
    return {
      default: isBgTransparent ? theme.buttons.dangerTransparent : theme.buttons.danger,
      hover: disabled ? theme.buttons.dangerTransparent : theme.buttons.danger,
      active: theme.buttons.danger,
    };
  }

  if (success || success === 'true') {
    return {
      default: isBgTransparent ? theme.buttons.successTransparent : theme.buttons.success,
      hover: disabled ? theme.buttons.successTransparent : theme.buttons.success,
      active: theme.buttons.success,
    };
  }

  if (pending || pending === 'true') {
    return {
      default: isBgTransparent ? theme.buttons.pendingTransparent : theme.buttons.pending,
      hover: disabled ? theme.buttons.pendingTransparent : theme.buttons.pending,
      active: theme.buttons.pending,
    };
  }

  if (circleButton || circleButton === 'true') {
    return {
      default: theme.buttons.third,
      hover: theme.buttons.third,
      active: theme.buttons.third,
    };
  }

  if (transparent || transparent === 'true') {
    return {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
    };
  }

  if (iconButton || iconButton === 'true') {
    return {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
    };
  }

  return {
    default: theme.buttons.default,
    hover: theme.buttons.default,
    active: theme.buttons.default,
  };
};

export const defineButtonColor = (
  theme: any,
  primary?: boolean | string,
  secondary?: boolean | string,
  transparent?: boolean | string,
  iconButton?: boolean | string,
  circleButton?: boolean | string,
) => {
  if (primary || primary === 'true') return theme.buttons.white;
  if (secondary || secondary === 'true') return theme.buttons.white;
  if (transparent || transparent === 'true') return theme.fontPrimary;
  if (iconButton || iconButton === 'true') return theme.fontPrimary;
  if (circleButton || circleButton === 'true') return theme.fontPrimary;
  return theme.buttons.white;
};

export const defineTextAlignment = (textAlign?: string, leftIcon?: ReactNode, rightIcon?: ReactNode) => {
  if (leftIcon || rightIcon) {
    return 'space-between';
  }
  if (textAlign === 'left') {
    return 'flex-start';
  }

  if (textAlign === 'right') {
    return 'flex-end';
  }

  return 'center';
};

interface ButtonPaddingType {
  circleButton?: boolean;
  iconButton?: boolean;
  size?: 'large' | 'medium' | 'small' | 'tiny';
}

export const getButtonPadding = ({ circleButton, iconButton, size }: ButtonPaddingType) => {
  if (circleButton || iconButton) {
    return '5px';
  }
  if (size === 'tiny') {
    return '3px 8px';
  }
  if (size === 'small') {
    return '8px 10px';
  }

  if (size === 'large') {
    return '14px 14px';
  }

  return '12px 12px';
};

interface ButtonSizeType {
  circleButton?: boolean;
  size?: 'large' | 'medium' | 'small' | 'tiny';
}

export const getButtonSize = ({ size, circleButton }: ButtonSizeType) => {
  if (circleButton) {
    return '36px';
  }
  if (size === 'tiny') {
    return '16px';
  }

  if (size === 'small') {
    return '20px';
  }

  if (size === 'large') {
    return '36px';
  }

  return '30px';
};

interface ButtonFontSize {
  circleButton?: boolean;
  size?: 'large' | 'medium' | 'small' | 'tiny';
}
export const getButtonFontSize = ({ size }: ButtonFontSize) => {
  if (size === 'tiny') {
    return '12px';
  }
  if (size === 'small') {
    return '12px';
  }
  if (size === 'large') {
    return '14px';
  }

  return '13px';
};

export const defineButtonBorder = (
  theme: any,
  primary?: boolean | string,
  secondary?: boolean | string,
  danger?: boolean | string,
  success?: boolean | string,
  pending?: boolean | string,
  transparent?: boolean | string,
  isBgTransparent?: boolean | string,
) => {
  if ((primary || primary === 'true') && isBgTransparent) {
    return theme.buttons.primary;
  }
  if ((secondary || secondary === 'true') && isBgTransparent) {
    return theme.buttons.secondary;
  }
  if ((danger || danger === 'true') && isBgTransparent) {
    return theme.buttons.danger;
  }
  if ((success || success === 'true') && isBgTransparent) {
    return theme.buttons.success;
  }
  if ((pending || pending === 'true') && isBgTransparent) {
    return theme.buttons.pending;
  }
  if (transparent || transparent === 'true') {
    return theme.colors.grey;
  }

  return 'transparent';
};
