import styled from 'styled-components';

export const AlertWrapper = styled.div`
  padding: 16px 16px 16px 57px;
  background: ${({ theme }) => theme.backgroundAlert};
  border-radius: 4px;
  max-width: 770px;
  margin: 10px auto;
  position: relative;
  border-left: 4px solid ${({ theme }) => theme.secondary};

  h3 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 0 0 10px;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 3px;
  }
`;

export const AlertTitle = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 0 10px;
`;

export const AlertText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0 0 3px;
`;

export const AlertIcon = styled.span`
  position: absolute;
  left: 16px;
  top: 12px;
`;

export const IconCircle = styled.div`
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.secondary};
  border: 2px solid ${({ theme }) => theme.secondary};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;
