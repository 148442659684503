import { createGlobalStyle } from 'styled-components';
import theme from './assets/theme';

export default createGlobalStyle`
  :root {
      --react-datepicker-body: ${theme.datePickerPopup.body};
      --react-datepicker-header: ${theme.datePickerPopup.header};
      --react-datepicker-border: ${theme.datePickerPopup.border};
      --react-datepicker-background: ${theme.datePickerPopup.background};
      --react-datepicker-cell-background: ${theme.datePickerPopup.cellBackground};
      --react-datepicker-cell-hover: ${theme.datePickerPopup.cellBackgroundHover};
      --react-datepicker-primary: ${theme.buttons.primary};
      --react-datepicker-font-color: ${theme.fontPrimary};
      --react-datepicker-font-family: ${theme.typography.fontFamily};
    }

  body {
    margin: 0;
    padding: 0;
    font: 13px/1.5 'Poppins', Arial, sans-serif;
    background-color: ${theme.backgroundPrimary};
    color: ${theme.fontPrimary};
    -webkit-touch-callout: none;
    min-width: 960px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    transition:color 0.3s ease;
    color: ${theme.linkColor};
  }
`;
