import { ReactElement } from 'react';
import { getFormattedLongDate } from 'utils';
import { APP_URLS } from 'common/constants';
import { Message } from 'common/components';
import { IntegrationsInfo, IntegrationsSystems, integrationsSystemsNamesMapping } from 'types/Integrations';
import { CustomerTypes, customerTypesNamesMapping } from 'types/Customer';
import { Status } from 'types/Status';
import { TableDateField } from 'types/Tables';
import { TagList, TagListItem, StatusLabel, StyledLink, DateWrap } from './styles';

interface CustomersDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: CustomersDataTableRow;
}

interface CustomersDataTableRow {
  id: number;
  name: string;
  comment: string;
  lastUpdate: string;
  organizationNumber: string;
  type: CustomerTypes;
  status: Status;
  integrations: IntegrationsInfo[];
}

const customersTableColumns = [
  {
    name: 'id',
    title: <Message id='customers-screen-table-customer-id' />,
    width: 100,
    sortable: true,
    cellCallback: ({ row: { id } }: CustomersDataTable) => (
      <StyledLink to={APP_URLS.toCustomerProfile(id)}>{id}</StyledLink>
    ),
  },
  {
    name: 'name',
    title: <Message id='customers-screen-table-customer-name' />,
    width: 300,
    sortable: true,
    cellCallback: ({ row: { id, name } }: CustomersDataTable) => (
      <StyledLink to={APP_URLS.toCustomerProfile(id)}>{name}</StyledLink>
    ),
  },
  {
    name: 'organizationNumber',
    title: <Message id='customers-screen-table-organization-number' />,
    width: 200,
    cellCallback: ({ row: { organizationNumber } }: CustomersDataTable) => <strong>{organizationNumber}</strong>,
  },
  {
    name: 'type',
    title: <Message id='customers-screen-table-customer-type' />,
    width: 150,
    cellCallback: ({ row: { type } }: CustomersDataTable) => customerTypesNamesMapping[type],
  },
  {
    name: 'systems',
    title: <Message id='customers-screen-table-connected-systems' />,
    width: 300,
    cellCallback: ({ row: { integrations } }: CustomersDataTable) => (
      <>
        {integrations && integrations.length ? (
          <TagList>
            {integrations.map((item: IntegrationsInfo, index: number) => (
              <TagListItem
                key={item.name + index}
                isActive={item.isActive}
                title={item.isActive ? 'Active' : 'Inactive'}
              >
                {`${item.name === IntegrationsSystems.VICAI ? integrationsSystemsNamesMapping[item.name] : item.name}`}
              </TagListItem>
            ))}
          </TagList>
        ) : (
          <span>
            <Message id='customers-screen-no-connected-systems' />
          </span>
        )}
      </>
    ),
  },
  {
    name: TableDateField.UPDATE_ON,
    title: <Message id='customers-screen-table-last-update' />,
    width: 300,
    cellCallback: ({ row: { lastUpdate } }: CustomersDataTable) => (
      <DateWrap>{getFormattedLongDate(lastUpdate)}</DateWrap>
    ),
    sortable: true,
  },
  {
    name: 'status',
    title: <Message id='customers-screen-table-status' />,
    width: 200,
    sortable: true,
    cellCallback: ({ row: { status } }: CustomersDataTable) => {
      const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);

      return (
        <>
          <StatusLabel status={status}>{statusFormatted}</StatusLabel>
        </>
      );
    },
  },
];

export default customersTableColumns;
