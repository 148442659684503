import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Message } from 'common/components/Message';
import { Select } from 'common/components/Select';
import {
  ConfigurationSchemaRequest,
  DefaultSettingsRequestData,
  IntegrationData,
  IntegrationSchema,
  IntegrationsLevel,
  IntegrationsSystems,
  IntegrationSystemOptions,
} from 'types/Integrations';
import { Spinner } from 'common/components/Spinner';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import IntegrationForm from 'components/IntegrationForm';
import { getAllFieldsFromSchema } from 'components/ConfigurationForm/utils';
import { prepareRequestData } from 'components/IntegrationForm/utils';
import { ErrorCodes } from 'common/constants';
import { Alert } from 'common/components/Alert';
import { useAccessToken } from 'hooks';
import getIntegrationsDefaultSettingsBreadcrumbs from './getIntegrationsDefaultSettingsBreadcrumbs';
import {
  Content,
  FormColumn,
  FormColumnWrapper,
  FormRow,
  FormTitleMark,
  FormWrapper,
  Head,
  Title,
  Wrapper,
} from './styles';

interface IntegrationsDefaultSettingsProps {
  isFetching: boolean;
  errorCode: number | null;
  availableSystems: IntegrationSystemOptions[];
  integrationSchema: IntegrationSchema;
  integration: IntegrationData;
  getConfigurationSchema: (payload: ConfigurationSchemaRequest) => void;
  updateDefaultSettings: (payload: DefaultSettingsRequestData) => void;
  resetPartial: () => void;
  reset: () => void;
}

const IntegrationsDefaultSettings = ({
  isFetching,
  errorCode,
  availableSystems,
  integrationSchema,
  integration,
  getConfigurationSchema,
  updateDefaultSettings,
  resetPartial,
  reset,
}: IntegrationsDefaultSettingsProps) => {
  const accessToken = useAccessToken();
  const { pathname } = useLocation();
  const isIntegrationsDefaultPage = pathname.toLowerCase().includes('integrations-default');
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [currentSystem, setCurrentSystem] = useState({ id: '0', value: IntegrationsSystems.UNKNOWN, label: '' });

  useEffect(() => {
    setBreadcrumbs(getIntegrationsDefaultSettingsBreadcrumbs());
    return () => setBreadcrumbs(null);
  }, [setBreadcrumbs]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const onSystemNameChangeHandler = (integrationOption: IntegrationSystemOptions) => {
    if (integrationOption.value !== currentSystem.value) {
      setCurrentSystem(integrationOption);

      getConfigurationSchema({
        isCreatePage: true,
        accessToken,
        systemId: integrationOption.id,
      });
    }
  };

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      const allFields = getAllFieldsFromSchema(integrationSchema, integration.settings);
      const preparePayload = prepareRequestData({
        integration,
        isIntegrationsDefaultPage,
        formData: data,
        fieldsArray: allFields,
      });

      updateDefaultSettings({
        accessToken,
        systemId: currentSystem.id,
        requestData: preparePayload,
      });
    },
    [accessToken, isIntegrationsDefaultPage, currentSystem.id, integrationSchema, updateDefaultSettings, integration],
  );

  const renderForm = useMemo(
    () => (
      <IntegrationForm
        isFetching={isFetching}
        errorCode={null}
        integrationName={currentSystem.value}
        integration={integration}
        integrationSchema={integrationSchema}
        onSubmit={onSubmitButtonClickHandler}
        resetForm={resetPartial}
        level={IntegrationsLevel.DEFAULT}
        withConfirmation={true}
      />
    ),
    [isFetching, integration, integrationSchema, currentSystem.value, onSubmitButtonClickHandler, resetPartial],
  );

  const renderMissingSchemaAlert = useMemo(() => {
    if (errorCode === ErrorCodes.NOT_FOUND_SCHEMA) {
      return (
        <Alert
          title={
            <Message
              id='schema-for-default-not-found-title'
              values={{
                variable: currentSystem.value,
              }}
            />
          }
        >
          <>
            <p>
              <Message
                id='please-create-default-schema-before-edit'
                values={{
                  variable: currentSystem.value,
                }}
              />
            </p>
          </>
        </Alert>
      );
    }
    return null;
  }, [currentSystem.value, errorCode]);

  return (
    <Wrapper>
      <Head>
        <Title>
          <Message id='integrations-default-screen-title' />
        </Title>
      </Head>
      <Content>
        {isFetching && <Spinner transparent={true} />}
        <FormColumnWrapper>
          <FormColumn>
            <FormRow>
              <FormTitleMark>
                <Message id='integrations-default-screen-select-system' />
              </FormTitleMark>
              <Select
                id='systemName'
                isRequired
                disabled={false}
                options={availableSystems}
                value={currentSystem}
                label={''}
                onChange={onSystemNameChangeHandler}
              />
            </FormRow>
          </FormColumn>
        </FormColumnWrapper>
        {currentSystem.value !== IntegrationsSystems.UNKNOWN && (
          <FormWrapper>
            {renderMissingSchemaAlert}
            {renderForm}
          </FormWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

export default IntegrationsDefaultSettings;
