import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { browserHistory } from 'browserHistory';
import { msalConfig } from './authConfig';

let msalInstance: PublicClientApplication | null = null;
export const getMsalInstance = () => {
  msalInstance = msalInstance === null ? new PublicClientApplication(msalConfig) : msalInstance;
  return msalInstance;
};

export const setPathname = (pathname: string) => sessionStorage.setItem('requestPathname', pathname);
export const getPathname = () => sessionStorage.getItem('requestPathname');
export const clearPathname = () => sessionStorage.removeItem('requestPathname');

export const handleLoginResponse = (response: AuthenticationResult | null) => {
  if (response !== null) {
    const pathname = getPathname();
    if (pathname) {
      browserHistory.push(pathname);
    }
  }
};

export const handleLogoutResponse = () => {
  clearPathname();
};
