import { APP_URLS } from 'common/constants';
import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';

const getTransferHistoricalDataBreadcrumbs = (customerId: number, customerName: string) => [
  {
    title: BREADCRUMBS_TITLES.GENERAL,
    url: APP_URLS.customers,
  },
  {
    title: BREADCRUMBS_TITLES.CUSTOMERS,
    url: APP_URLS.customers,
  },
  {
    title: customerName,
    url: APP_URLS.toCustomerProfile(customerId),
  },
  {
    title: BREADCRUMBS_TITLES.TRANSFER_HISTORICAL_DATA,
    isActive: true,
  },
];

export default getTransferHistoricalDataBreadcrumbs;
