import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainNavigation } from 'components/MainNavigation';
import { SubNavigation } from 'components/SubNavigation';
import { CustomerIntegrationsRequestParams, IntegrationData } from 'types/Integrations';
import { isAccessIntegrationExists } from 'utils';
import { Button } from 'common/components';
import { ArrowLeftIcon, ArrowRightIcon } from 'common/components/Icons';
import { useAccessToken } from 'hooks';
import { SidebarInfoRequestData } from 'types/Sidebar';
import { SidebarWrapper, SidebarMain, SidebarTop, SidebarSub, SidebarFooter } from './styles';

type CustomerUrlParams = {
  customerId: string;
};

interface SidebarProps {
  isSubNavExists: boolean;
  isSubNavCollapsed: boolean;
  isMainNavCollapsed: boolean;
  customersAllCount: number;
  clientsAllCount: number;
  clientsCount: number;
  customerIntegrations: IntegrationData[];
  customerAndAllClientsIntegrations: IntegrationData[];
  getSidebarInfo: (payload: SidebarInfoRequestData) => void;
  getCustomerAndAllClientsIntegrations: (payload: CustomerIntegrationsRequestParams) => void;
  setMainNavCollapsed: (payload: boolean) => void;
  setSubNavCollapsed: (payload: boolean) => void;
}

const Sidebar = ({
  isMainNavCollapsed,
  isSubNavCollapsed,
  isSubNavExists,
  customersAllCount,
  clientsAllCount,
  clientsCount,
  customerAndAllClientsIntegrations,
  getSidebarInfo,
  getCustomerAndAllClientsIntegrations,
  setMainNavCollapsed,
  setSubNavCollapsed,
}: SidebarProps) => {
  const accessToken = useAccessToken();
  const { customerId } = useParams<CustomerUrlParams>();
  const [navGeneralLabels, setNavGeneralLabels] = useState({ customersAll: 0, clientsAll: 0 });
  const [subNavGeneralLabels, setSubNavGeneralLabels] = useState({ clients: 0 });

  const isAccessPointIntegration = useMemo(
    () => isAccessIntegrationExists(customerAndAllClientsIntegrations),
    [customerAndAllClientsIntegrations],
  );

  useEffect(() => {
    if (accessToken) {
      getSidebarInfo({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [customerId, getSidebarInfo, accessToken]);

  useEffect(() => {
    if (customerId && accessToken) {
      getCustomerAndAllClientsIntegrations({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, getCustomerAndAllClientsIntegrations]);

  useEffect(() => {
    setNavGeneralLabels((navGeneralLabels) => ({
      ...navGeneralLabels,
      customersAll: customersAllCount,
      clientsAll: clientsAllCount,
    }));
    setSubNavGeneralLabels((subNavGeneralLabels) => ({
      ...subNavGeneralLabels,
      clients: clientsCount,
    }));
  }, [customersAllCount, clientsCount, clientsAllCount]);

  useEffect(() => {
    if (!isSubNavCollapsed) {
      setMainNavCollapsed(true);
    }
  }, [isSubNavCollapsed, setMainNavCollapsed]);

  const onMainNavSidebarCollapseButtonClick = () => {
    setMainNavCollapsed(!isMainNavCollapsed);
    if (!isSubNavCollapsed) {
      setSubNavCollapsed(true);
    }
  };

  const onSubNavSidebarCollapseButtonClick = () => {
    setSubNavCollapsed(!isSubNavCollapsed);
    if (!isMainNavCollapsed) {
      setMainNavCollapsed(true);
    }
  };

  return (
    <SidebarWrapper
      isMainNavCollapsed={isMainNavCollapsed}
      isSubNavCollapsed={isSubNavCollapsed}
      isSubNavExists={isSubNavExists}
    >
      <SidebarMain
        isSubNavExists={isSubNavExists}
        isMainNavCollapsed={isMainNavCollapsed}
        isSubNavCollapsed={isSubNavCollapsed}
      >
        <SidebarTop>
          <MainNavigation
            isSubNavExists={isSubNavExists}
            isMainNavCollapsed={isMainNavCollapsed}
            navGeneralLabels={navGeneralLabels}
          />
        </SidebarTop>
        <SidebarFooter>
          <Button onClick={onMainNavSidebarCollapseButtonClick}>
            {isMainNavCollapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          </Button>
        </SidebarFooter>
      </SidebarMain>
      {isSubNavExists && (
        <SidebarSub isSubNavCollapsed={isSubNavCollapsed}>
          <SubNavigation
            isMainNavCollapsed={isSubNavCollapsed}
            subNavGeneralLabels={subNavGeneralLabels}
            isAccessPointPageHidden={!isAccessPointIntegration}
          />
          <SidebarFooter>
            <Button onClick={onSubNavSidebarCollapseButtonClick}>
              {isSubNavCollapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            </Button>
          </SidebarFooter>
        </SidebarSub>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
