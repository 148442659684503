import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { ClientData, CustomerClientsRequestData, Clients, MultipleClientsStatusRequestData } from 'types/Client';
import { FormSelectOption } from 'types/Form';
import { defaultFilterOptions, systemsFilterOptions } from 'common/constants';
import { TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import {
  ConfigurationSchemaRequest,
  IntegrationSchema,
  IntegrationsCsvFileHeadersRequestData,
  IntegrationsSystems,
  IntegrationSystemOptions,
} from 'types/Integrations';
import { ImportFileData, ImportMode, ImportType } from 'types/Import';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';

// Actions
export enum ActionTypeKeys {
  GET_CLIENTS = 'CLIENTS/GET_CLIENTS',
  GET_CLIENTS_SUCCESS = 'CLIENTS/GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAILED = 'CLIENTS/GET_CLIENTS_FAILED',
  SET_CLIENTS_TABLE_PAGE = 'CLIENTS/SET_CLIENTS_TABLE_PAGE',
  SET_CLIENTS_TABLE_PER_PAGE = 'CLIENTS/SET_CLIENTS_TABLE_PER_PAGE',
  SET_CLIENTS_TABLE_FILTER_STATUS = 'CLIENTS/SET_CLIENTS_TABLE_FILTER_STATUS',
  SET_CLIENTS_TABLE_FILTER_SYSTEM = 'CLIENTS/SET_CLIENTS_TABLE_FILTER_SYSTEM',
  SET_CLIENTS_TABLE_FILTER_SEARCH = 'CLIENTS/SET_CLIENTS_TABLE_FILTER_SEARCH',
  SET_CLIENTS_TABLE_SORT_ORDER = 'CLIENTS/SET_CLIENTS_TABLE_SORT_ORDER',
  SET_CLIENTS_TABLE_INCLUDE_DEACTIVATED = 'CLIENTS/SET_CLIENTS_TABLE_INCLUDE_DEACTIVATED',
  SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS = 'CLIENTS/SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS',
  SET_UPLOAD_FILE = 'CLIENTS/SET_UPLOAD_FILE',
  SET_IMPORT_TYPE = 'CLIENTS/SET_IMPORT_TYPE',
  SET_IMPORT_ACTION = 'CLIENTS/SET_IMPORT_ACTION',
  GET_INTEGRATIONS_CSV_FILE_HEADERS = 'CLIENTS/GET_INTEGRATIONS_CSV_FILE_HEADERS',
  GET_INTEGRATIONS_CSV_FILE_HEADERS_SUCCESS = 'CLIENTS/GET_INTEGRATIONS_CSV_FILE_HEADERS_SUCCESS',
  GET_INTEGRATIONS_CSV_FILE_HEADERS_FAILED = 'CLIENTS/GET_INTEGRATIONS_CSV_FILE_HEADERS_FAILED',
  IMPORT_FILE = 'CLIENTS/IMPORT_FILE',
  IMPORT_FILE_SUCCESS = 'CLIENTS/IMPORT_FILE_SUCCESS',
  IMPORT_FILE_FAILED = 'CLIENTS/IMPORT_FILE_FAILED',
  ACTIVATE_MULTIPLE_CLIENTS = 'CLIENTS/ACTIVATE_MULTIPLE_CLIENTS',
  ACTIVATE_MULTIPLE_CLIENTS_SUCCESS = 'CLIENTS/ACTIVATE_MULTIPLE_CLIENTS_SUCCESS',
  ACTIVATE_MULTIPLE_CLIENTS_FAILED = 'CLIENTS/ACTIVATE_MULTIPLE_CLIENTS_FAILED',
  GET_CLIENT_CONFIGURATION_SCHEMA = 'CLIENTS/GET_CLIENT_CONFIGURATION_SCHEMA',
  GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS = 'CLIENTS/GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS',
  GET_CLIENT_CONFIGURATION_SCHEMA_FAILED = 'CLIENTS/GET_CLIENT_CONFIGURATION_SCHEMA_FAILED',
  RESET_UPLOAD_FILE = 'CLIENTS/RESET_UPLOAD_FILE',
  CLIENTS_RESET = 'CLIENTS/CLIENTS_RESET',
}

// ActionCreators
export const actionCreators = {
  getClients: (payload: CustomerClientsRequestData) => createAction(ActionTypeKeys.GET_CLIENTS, payload),
  getClientsSuccess: (payload: Clients) => createAction(ActionTypeKeys.GET_CLIENTS_SUCCESS, payload),
  getClientsFailed: () => createAction(ActionTypeKeys.GET_CLIENTS_FAILED),
  setClientsTablePage: (payload: number) => createAction(ActionTypeKeys.SET_CLIENTS_TABLE_PAGE, payload),
  setClientsTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_CLIENTS_TABLE_PER_PAGE, payload),
  setClientsTableFilterStatus: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_FILTER_STATUS, payload),
  setClientsTableFilterSystem: (payload: IntegrationsSystems[]) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_FILTER_SYSTEM, payload),
  setClientsTableFilterSearch: (payload: string) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_FILTER_SEARCH, payload),
  setClientsTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_SORT_ORDER, payload),
  setIsIncludeDeactivated: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_INCLUDE_DEACTIVATED, payload),
  setShowOnlyDeactivatedSystems: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS, payload),
  setUploadFile: (payload: File) => createAction(ActionTypeKeys.SET_UPLOAD_FILE, payload),
  setImportType: (payload: null | ImportType) => createAction(ActionTypeKeys.SET_IMPORT_TYPE, payload),
  setImportMode: (payload: ImportMode) => createAction(ActionTypeKeys.SET_IMPORT_ACTION, payload),
  importFile: (payload: ImportFileData) => createAction(ActionTypeKeys.IMPORT_FILE, payload),
  importFileSuccess: () => createAction(ActionTypeKeys.IMPORT_FILE_SUCCESS),
  importFileFailed: () => createAction(ActionTypeKeys.IMPORT_FILE_FAILED),
  activateMultipleClients: (payload: MultipleClientsStatusRequestData) =>
    createAction(ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS, payload),
  activateMultipleClientsSuccess: () => createAction(ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS_SUCCESS),
  activateMultipleClientsFailed: () => createAction(ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS_FAILED),
  resetUploadFile: () => createAction(ActionTypeKeys.RESET_UPLOAD_FILE),
  clientsReset: () => createAction(ActionTypeKeys.CLIENTS_RESET),
  getIntegrationsCsvFileHeaders: (payload: IntegrationsCsvFileHeadersRequestData) =>
    createAction(ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS, payload),
  getIntegrationsCsvFileHeadersSuccess: (payload: any) =>
    createAction(ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS_SUCCESS, payload),
  getIntegrationsCsvFileHeadersFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS_FAILED, payload),
  getClientConfigurationSchema: (payload: ConfigurationSchemaRequest) =>
    createAction(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA, payload),
  getClientConfigurationSchemaSuccess: (payload: IntegrationSchema) =>
    createAction(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS, payload),
  getClientConfigurationSchemaFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_FAILED, payload),
};

interface UploadFileInterface {
  importType: null | ImportType;
  importMode: ImportMode;
  isUploading: boolean;
  isUploaded: boolean;
  file: File;
  name: string;
}

export const uploadFileInitialState = {
  importType: null,
  importMode: ImportMode.CREATE,
  isUploading: false,
  isUploaded: false,
  file: {} as File,
  name: '',
};

// State
export interface State {
  isFetching: boolean;
  totalCount: number;
  clients: ClientData[];
  csvHeaders: any;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterStatus: FormSelectOption[];
  filterSystem: IntegrationSystemOptions[];
  filterSearch: string;
  isIncludeDeactivated: boolean;
  showOnlyDeactivatedSystems: boolean;
  upload: UploadFileInterface;
  integrationSchema: IntegrationSchema;
  errorCode: number | null;
}

export const initialState: State = {
  isFetching: false,
  totalCount: 0,
  clients: [] as ClientData[],
  csvHeaders: [],
  page: 1,
  perPage: ITEMS_PER_PAGE_COUNT,
  orderBy: 'updatedOn',
  sortOrder: TableSortOrder.DESCENDING,
  filterStatus: [defaultFilterOptions],
  filterSystem: [systemsFilterOptions[0]],
  filterSearch: '',
  isIncludeDeactivated: false,
  showOnlyDeactivatedSystems: false,
  upload: uploadFileInitialState,
  integrationSchema: {} as IntegrationSchema,
  errorCode: null,
};

// Reducer
export const clientsReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CLIENTS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clients: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case ActionTypeKeys.GET_CLIENTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_FILTER_STATUS:
      return {
        ...state,
        filterStatus: [...action.payload],
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_FILTER_SYSTEM:
      return {
        ...state,
        filterSystem: [...action.payload],
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_FILTER_SEARCH:
      return {
        ...state,
        filterSearch: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_SORT_ORDER:
      return {
        ...state,
        orderBy: action.payload.orderBy,
        sortOrder: action.payload.sortOrder,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_INCLUDE_DEACTIVATED:
      return {
        ...state,
        isIncludeDeactivated: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS:
      return {
        ...state,
        showOnlyDeactivatedSystems: action.payload,
      };

    case ActionTypeKeys.SET_UPLOAD_FILE:
      // eslint-disable-next-line no-case-declarations
      const fileName = action.payload[0] ? action.payload[0].name : '';

      return {
        ...state,
        upload: {
          ...state.upload,
          file: action.payload[0],
          name: fileName,
        },
      };

    case ActionTypeKeys.SET_IMPORT_TYPE:
      return {
        ...state,
        upload: {
          ...state.upload,
          importType: action.payload,
        },
      };

    case ActionTypeKeys.SET_IMPORT_ACTION:
      return {
        ...state,
        upload: {
          ...state.upload,
          importMode: action.payload,
        },
      };

    case ActionTypeKeys.IMPORT_FILE:
      return {
        ...state,
        upload: {
          ...state.upload,
          isUploading: true,
          isUploaded: false,
        },
      };

    case ActionTypeKeys.IMPORT_FILE_SUCCESS:
      return {
        ...state,
        upload: {
          ...state.upload,
          isUploading: false,
          isUploaded: true,
        },
      };

    case ActionTypeKeys.IMPORT_FILE_FAILED:
      return {
        ...state,
        upload: {
          ...state.upload,
          isUploading: false,
          isUploaded: false,
        },
      };

    case ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.ACTIVATE_MULTIPLE_CLIENTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        csvHeaders: action.payload,
      };

    case ActionTypeKeys.GET_INTEGRATIONS_CSV_FILE_HEADERS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA:
      return {
        ...state,
        integrationSchema: initialState.integrationSchema,
      };

    case ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS:
      return {
        ...state,
        integrationSchema: action.payload,
        errorCode: null,
      };

    case ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_FAILED:
      return {
        ...state,
        errorCode: action.payload,
      };

    case ActionTypeKeys.RESET_UPLOAD_FILE:
      return {
        ...state,
        upload: uploadFileInitialState,
        csvHeaders: initialState.csvHeaders,
        integrationSchema: initialState.integrationSchema,
        errorCode: null,
      };

    case ActionTypeKeys.CLIENTS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
