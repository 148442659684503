import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, Message, Select, Button, Textarea, Spinner } from 'common/components';
import { PenIcon } from 'common/components/Icons';
import { CustomerData, CustomerRequestDataExtended } from 'types/Customer';
import { FormSelectOption } from 'types/Form';
import { validateOrganizationNumber, validateStringValue } from 'utils/validation';
import { useAccessToken } from 'hooks';
import { FormRow, FormWrapper, FormColumn, FormColumnWrapper, FormButtons, FormEditButton } from './styles';

interface EditFormProps {
  organizationNumber: string;
  customerName: string;
  customerType: FormSelectOption;
  customerComments: string;
}

const defaultValues = {
  organizationNumber: '',
  customerName: '',
  customerType: {
    id: 0,
    value: '',
    label: '',
  },
  customerComments: '',
};

interface CustomerEditFormProps {
  isFetching: boolean;
  errorCode: number | null;
  formData: CustomerData;
  customerTypeOptions: FormSelectOption[];
  onSubmitHandler: (payload: CustomerRequestDataExtended) => void;
  resetPartial: () => void;
}

const CustomerEditForm = ({
  isFetching,
  errorCode,
  formData,
  customerTypeOptions,
  onSubmitHandler,
  resetPartial,
}: CustomerEditFormProps) => {
  const accessToken = useAccessToken();
  const [isFormDisabled, setFormDisabled] = useState(true);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState<EditFormProps>(defaultValues);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
    reset,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const type = customerTypeOptions.find((item) => item.value === formData.type);
    setDefaultFormValues({
      organizationNumber: formData.organizationNumber ? formData.organizationNumber : '',
      customerName: formData.name ? formData.name : '',
      customerType: type ? type : customerTypeOptions[0],
      customerComments: formData.comment ? formData.comment : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    reset(defaultFormValues);
    return resetPartial();
  }, [reset, resetPartial, defaultFormValues]);

  const onEditButtonHandler = () => {
    setFormDisabled(false);
    setIsRequestPending(false);
  };

  const onCancelHandler = useCallback(() => {
    setFormDisabled(true);
    reset(defaultFormValues);
  }, [reset, defaultFormValues]);

  const onSubmit = useCallback(
    (data: EditFormProps) => {
      onSubmitHandler({
        accessToken,
        id: formData.id,
        organizationNumber: data.organizationNumber,
        name: data.customerName,
        type: data.customerType.value,
        comment: data.customerComments,
      });
      setIsRequestPending(true);
    },
    [accessToken, formData.id, onSubmitHandler],
  );

  useEffect(() => {
    if (isRequestPending) {
      if (errorCode) {
        setFormDisabled(() => false);
      } else {
        setFormDisabled(() => true);
      }
    }
  }, [errorCode, isRequestPending]);

  const renderFormButtons = useMemo(
    () =>
      isFormDisabled ? null : (
        <FormButtons>
          <Button transparent onClick={onCancelHandler}>
            <Message id={'create-customers-cancel-button'} />
          </Button>
          <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
            <Message id={'edit-customers-save-button'} />
          </Button>
        </FormButtons>
      ),
    [isDirty, isValid, isFormDisabled, handleSubmit, onCancelHandler, onSubmit],
  );

  return (
    <>
      <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
        {isFetching && <Spinner />}
        <FormEditButton>
          <Button primary disabled={!isFormDisabled} onClick={onEditButtonHandler}>
            <PenIcon />
          </Button>
        </FormEditButton>
        <FormColumnWrapper>
          <FormColumn>
            <FormRow>
              <Controller
                control={control}
                name='organizationNumber'
                defaultValue={defaultFormValues.organizationNumber}
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 100,
                  validate: validateOrganizationNumber,
                }}
                render={({ field, fieldState }) => {
                  const messageId = fieldState.error?.message
                    ? fieldState.error.message
                    : 'create-customers-customer-organization-number-label-error';

                  return (
                    <Input
                      isRequired
                      disabled={isFormDisabled}
                      isError={fieldState.invalid}
                      id='organizationNumber'
                      type='text'
                      label={<Message id={'create-customers-customer-organization-number-label'} />}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      errorText={<Message id={messageId} />}
                    />
                  );
                }}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name='customerName'
                defaultValue={defaultFormValues.customerName}
                rules={{
                  required: true,
                  minLength: 1,
                  maxLength: 70,
                  validate: (value: string) => validateStringValue(true, value, 'create-customers-customer-name'),
                }}
                render={({ field, fieldState }) => {
                  const messageId = fieldState.error?.message
                    ? fieldState.error.message
                    : 'create-customers-customer-name-label-error';

                  return (
                    <Input
                      isRequired
                      disabled={isFormDisabled}
                      isError={fieldState.invalid}
                      id='customerName'
                      type='text'
                      label={<Message id={'create-customers-customer-name-label'} />}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      errorText={<Message id={messageId} />}
                    />
                  );
                }}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name='customerType'
                defaultValue={defaultFormValues.customerType}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      isRequired
                      disabled={isFormDisabled}
                      id='customerType'
                      options={customerTypeOptions}
                      value={field.value}
                      label={<Message id={'create-customers-customer-type-label'} />}
                      onChange={field.onChange}
                      isError={fieldState.invalid}
                      errorText={<Message id={'create-customers-customer-type-label-error'} />}
                    />
                  );
                }}
              />
            </FormRow>
          </FormColumn>
          <FormColumn>
            <FormRow>
              <Controller
                control={control}
                name='customerComments'
                defaultValue={defaultFormValues.customerComments}
                rules={{ maxLength: 200 }}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <Textarea
                      disabled={isFormDisabled}
                      id='customerComments'
                      isError={fieldState.invalid}
                      value={value}
                      label={<Message id='create-customers-comments-label' />}
                      onChange={(e) => onChange(e)}
                      minHeight={116}
                      errorText={<Message id='create-customers-comments-error-200-symbols' />}
                    />
                  );
                }}
              />
            </FormRow>
          </FormColumn>
        </FormColumnWrapper>
        {renderFormButtons}
      </FormWrapper>
    </>
  );
};

export default CustomerEditForm;
