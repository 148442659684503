import { FormSelectOption } from './Form';
import { TableSortOrder } from './Tables';
import { AccessToken } from './Auth';
import { IntegrationsInfo, IntegrationsSystems, IntegrationSystemOptions } from './Integrations';

export interface OperationData {
  customerId: number;
  customerName: string;
  clientId: number;
  clientName: string;
  traceId: string;
  syncId: string;
  successfulItemsCount: number | null;
  failedItemsCount: number | null;
  startedDate: string;
  finishedDate: string;
  logsUrl: string | null;
  scope: OperationScope;
  type: OperationType;
  status: OperationStatus;
  integrations: IntegrationsInfo[];
  sourceEntityId: string | null;
  targetEntityId: string | null;
  /** Arbitrary data to display on UI */
  additionalData: { [key: string]: string; } | undefined;
}

export interface OperationDataExtended extends OperationData {
  id: string;
}

export interface OperationsList {
  data: OperationData[];
  totalCount: number;
}

export interface OperationsState {
  data: OperationData[];
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterScope: FormSelectOption[];
  filterType: FormSelectOption[];
  filterStatus: FormSelectOption[];
  filterTraceId?: string;
  filterDateFrom: null | Date;
  filterDateTo: null | Date;
  filterSourceEntityId: string;
  filterTargetEntityId: string;
}

export interface OperationsCustomerState extends OperationsState {
  filterSystem: IntegrationSystemOptions[];
  filterClientName: FormSelectOption[];
  clientsOptions: FormSelectOption[];
  showOnlyDeactivatedSystems: boolean;
}

export interface OperationsAllState extends OperationsCustomerState {
  isFetching: boolean;
  filterCustomerName: FormSelectOption[];
  customersOptions: FormSelectOption[];
}

export enum OperationScope {
  NONE = 'None',
  SYNC_MASTER_DATA = 'SyncMasterdata',
  NEW_VENDOR = 'NewVendor',
  POST_INVOICE = 'PostInvoice',
  SYNC_HISTORICAL_DATA = 'SyncHistoricalData',
  INVOICE_TRANSFER = 'InvoiceTransfer',
  INVOICE_EXPORT = 'InvoiceExport',
}

export enum OperationType {
  NONE = 'None',
  ACCOUNTS = 'Accounts',
  CURRENCIES = 'Currencies',
  DIMENSIONS = 'Dimensions',
  VALUE_ADDED_TAX = 'ValueAddedTax',
  VENDORS = 'Vendors',
  INVOICES = 'Invoices',
}

export enum OperationStatus {
  NONE = 'None',
  START = 'Start',
  IN_PROGRESS = 'InProgress',
  PARTIAL_SUCCESS = 'PartialSuccess',
  SUCCESS = 'Success',
  FAILURE = 'Failure',
}

export const operationScopeTypeNamesMapping: { [OperationScope: string]: any } = {
  [OperationScope.NONE]: 'None',
  [OperationScope.SYNC_MASTER_DATA]: 'Sync Master Data',
  [OperationScope.NEW_VENDOR]: 'New Vendor',
  [OperationScope.POST_INVOICE]: 'Post Invoice',
  [OperationScope.SYNC_HISTORICAL_DATA]: 'Sync Historical Data',
  [OperationScope.INVOICE_TRANSFER]: 'Invoice Transfer',
  [OperationScope.INVOICE_EXPORT]: 'Invoice Export',
};

export const operationTypeNamesMapping: { [OperationType: string]: any } = {
  [OperationType.NONE]: 'None',
  [OperationType.ACCOUNTS]: 'Accounts',
  [OperationType.CURRENCIES]: 'Currencies',
  [OperationType.DIMENSIONS]: 'Dimensions',
  [OperationType.VALUE_ADDED_TAX]: 'Value Added Tax',
  [OperationType.VENDORS]: 'Vendors',
  [OperationType.INVOICES]: 'Invoices',
};

export const operationStatusNamesMapping: { [OperationStatus: string]: any } = {
  [OperationStatus.NONE]: 'None',
  [OperationStatus.START]: 'Start',
  [OperationStatus.IN_PROGRESS]: 'In Progress',
  [OperationStatus.PARTIAL_SUCCESS]: 'Partial Success',
  [OperationStatus.SUCCESS]: 'Success',
  [OperationStatus.FAILURE]: 'Failure',
};

export interface OperationsRequestData {
  accessToken: AccessToken;
  shouldContainDeactivatedSystem?: boolean;
  customerIds?: number[];
  clientIds?: number[];
  page?: number;
  size?: number;
  orderBy?: string;
  sortOrder?: TableSortOrder;
  search?: '';
  systems?: IntegrationsSystems[];
  operationScopes?: string[];
  operations?: string[];
  statuses?: string[];
  traceId?: string;
  sourceEntityId?: string;
  targetEntityId?: string;
  startedDate?: string;
  finishedDate?: string;
}
