import { integrationsServiceDataRequest, integrationsServiceRequest } from 'config';
import { API_URLS } from 'common/constants';
import {
  ClientRequestData,
  ClientRequestDataExtended,
  CustomerClientsRequestData,
  ClientStatusRequestData,
  ClientsRequestData,
  ClientsCountRequestData,
  ClientRequestParams,
  ClientsOptionsRequestData,
  ClientsOptionsRequestDataByCustomerId,
} from 'types/Client';
import { ImportFileRequestData } from 'types/Import';
import { OperationsRequestData } from 'types/Operations';
import { ExecuteHistoricalDataByClientIdRequestData, TransferClientsRequestData } from 'types/TransferHistoricalData';
import { ExecuteMasterDataByClientIdRequestData, SyncClientsRequestData } from 'types/SyncMasterData';
import { AccessToken } from 'types/Auth';
import { setAuthHeaders } from 'utils/auth';
import { ClientAvailableIntegrationsSystemRequestParams, IntegrationStatusRequest } from 'types/Integrations';

export const clientsService = {
  getClientsAll: ({ accessToken, ...requestData }: ClientsRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.clientsAll, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientsAllCount: (accessToken: AccessToken) =>
    integrationsServiceRequest.get(API_URLS.clientsService.clientsAllCount, { headers: setAuthHeaders(accessToken) }),
  getClients: ({ accessToken, customerId, ...requestData }: CustomerClientsRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.clients(customerId), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientsBasic: ({ accessToken, ...requestData }: ClientsOptionsRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.clientsBasic, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientsBasicByCustomerId: ({ accessToken, ...requestData }: ClientsOptionsRequestDataByCustomerId) =>
    integrationsServiceRequest.post(API_URLS.clientsService.clientsBasic, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientsCount: ({ accessToken, customerId }: ClientsCountRequestData) =>
    integrationsServiceRequest.get(API_URLS.clientsService.clientsCount(customerId), {
      headers: setAuthHeaders(accessToken),
    }),
  getClientById: ({ accessToken, customerId, clientId }: ClientRequestParams) =>
    integrationsServiceRequest.get(API_URLS.clientsService.clientById(customerId, clientId), {
      headers: setAuthHeaders(accessToken),
    }),
  createClient: ({ accessToken, customerId, ...requestData }: ClientRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.client(customerId), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  updateClient: ({ accessToken, customerId, ...requestData }: ClientRequestDataExtended) =>
    integrationsServiceRequest.put(API_URLS.clientsService.clientById(customerId, requestData.id), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  setClientStatus: ({ accessToken, customerId, id, ...requestData }: ClientStatusRequestData) =>
    integrationsServiceRequest.put(API_URLS.clientsService.setClientStatusById(customerId, id), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientsForTransferData: ({ accessToken, customerId, ...requestData }: TransferClientsRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.clientsForTransferData(customerId), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  executeDataTransferByClientId: ({
    accessToken,
    customerId,
    clientId,
    ...requestData
  }: ExecuteHistoricalDataByClientIdRequestData) =>
    integrationsServiceRequest.post(
      API_URLS.clientsService.executeTransferDataByClientId(customerId, clientId),
      requestData,
      { headers: setAuthHeaders(accessToken) },
    ),
  getClientsForSyncMasterData: ({ accessToken, customerId, ...requestData }: SyncClientsRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.clientsForSyncMasterData(customerId), requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  executeSyncMasterDataByClientId: ({ accessToken, customerId, clientId }: ExecuteMasterDataByClientIdRequestData) =>
    integrationsServiceRequest.post(
      API_URLS.clientsService.executeSyncMasterDataByClientId(customerId, clientId),
      null,
      { headers: setAuthHeaders(accessToken) },
    ),
  getClientOperations: ({ accessToken, ...requestData }: OperationsRequestData) =>
    integrationsServiceRequest.post(API_URLS.operationsService.operations, requestData, {
      headers: setAuthHeaders(accessToken),
    }),
  importClientsFile: ({ accessToken, requestParams, formData }: ImportFileRequestData) =>
    integrationsServiceDataRequest.post(API_URLS.clientsService.importClients, formData, {
      headers: setAuthHeaders(accessToken),
      params: requestParams,
    }),
  importIntegrationsFile: ({ accessToken, requestParams, formData }: ImportFileRequestData) =>
    integrationsServiceRequest.post(API_URLS.clientsService.importIntegrations, formData, {
      headers: setAuthHeaders(accessToken),
      params: requestParams,
    }),
  updateClientIntegrationStatus: ({ accessToken, integrationId, action }: IntegrationStatusRequest) =>
    integrationsServiceRequest.put(API_URLS.integrationsService.updateIntegrationStatus(integrationId, action), null, {
      headers: setAuthHeaders(accessToken),
    }),
  getClientAvailableSystems: ({ accessToken, customerId, clientId }: ClientAvailableIntegrationsSystemRequestParams) =>
    integrationsServiceRequest.get(API_URLS.clientsService.getClientAvailableSystems(customerId, clientId), {
      headers: setAuthHeaders(accessToken),
    }),
};
