import styled from 'styled-components';

const getBorderColor = ({ theme, isDragAccept, isDragReject, isDragActive, isFileSelected }: any) => {
  if (isDragAccept) {
    return theme.colors.success;
  }
  if (isDragReject) {
    return theme.colors.error;
  }
  if (isDragActive || isFileSelected) {
    return theme.primary;
  }
  return theme.colors.lightGrey;
};

interface WrapperType {
  isFileSelected: boolean;
}

export const Wrapper = styled.div<WrapperType>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getBorderColor(props)};
  border-style: dashed;
  background-color: ${({ theme }) => theme.backgroundDropzone};
  color: ${({ theme }) => theme.fontSecondary};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const DropzoneMark = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.fontPrimary};
`;

export const DropzoneText = styled.p`
  margin: 0;
`;

interface UploadErrorMessageProps {
  isError?: boolean;
}

export const UploadErrorMessage = styled.div<UploadErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;
