import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Message, Spinner, DataTable, ButtonLink, Button } from 'common/components';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { APP_URLS } from 'common/constants';
import {
  IntegrationData,
  ClientIntegrationsRequestParams,
  IntegrationSystem,
  ClientAvailableIntegrationsSystemRequestParams,
  IntegrationsStatusAction,
  TestConnectionRequest,
} from 'types/Integrations';
import { ClientData, ClientIntegrationStatusRequest } from 'types/Client';
import { RefreshIcon } from 'common/components/Icons';
import { useAccessToken } from 'hooks';
import clientIntegrationsTableColumns from './integrationsListTableColumns';
import getClientIntegrationsBreadcrumbs from './getClientIntegrationsBreadcrumbs';
import { TableWrapper, TableHeader, TableTitle, TableHeaderActions } from './styles';

type clientUrlParams = {
  customerId: string;
  clientId: string;
};

interface IntegrationsListProps {
  isFetching: boolean;
  client: ClientData;
  availableSystems: IntegrationSystem[];
  integrations: IntegrationData[];
  getClientIntegrations: (payload: ClientIntegrationsRequestParams) => void;
  getAvailableSystems: (payload: ClientAvailableIntegrationsSystemRequestParams) => void;
  getClientTestConnection: (payload: TestConnectionRequest) => void;
  updateClientIntegrationStatus: (payload: ClientIntegrationStatusRequest) => void;
  reset: () => void;
}

const IntegrationsList = ({
  isFetching,
  client,
  availableSystems,
  integrations,
  getClientIntegrations,
  getAvailableSystems,
  getClientTestConnection,
  updateClientIntegrationStatus,
  reset,
}: IntegrationsListProps) => {
  const accessToken = useAccessToken();
  const { pathname } = useLocation();
  const { customerId, clientId } = useParams<clientUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const isProfilePage = /profile+$/.test(pathname);

  useEffect(() => {
    setBreadcrumbs(
      getClientIntegrationsBreadcrumbs(Number(customerId), Number(clientId), client.customerName, client.name),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, setBreadcrumbs]);

  useEffect(() => {
    if (accessToken) {
      getAvailableSystems({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
  }, [accessToken, customerId, clientId, getAvailableSystems]);

  useEffect(() => {
    // eslint-disable-next-line
    setIsCreateButtonDisabled(!Boolean(availableSystems.length));
  }, [availableSystems]);

  useEffect(() => {
    if (accessToken) {
      getClientIntegrations({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
    return () => reset();
  }, [accessToken, getClientIntegrations, customerId, clientId, reset]);

  const onRefreshButtonClick = () => {
    getClientIntegrations({
      accessToken,
      customerId: Number(customerId),
      clientId: Number(clientId),
    });
  };

  const onFlipToggleChangeHandler = ({ event, id, systemName }: any) => {
    updateClientIntegrationStatus({
      accessToken,
      isProfilePage,
      customerId: Number(customerId),
      clientId: Number(clientId),
      integrationId: id,
      system: systemName,
      action: event.target.checked ? IntegrationsStatusAction.ACTIVATE : IntegrationsStatusAction.DEACTIVATE,
    });
  };

  const onTestConnectionClickHandler = (id: string) => {
    getClientTestConnection({
      accessToken,
      integrationId: id,
    });
  };

  return (
    <>
      <TableWrapper>
        <TableHeader>
          <TableTitle>
            <Message id='client-integrations-table-connected-systems-title' />
          </TableTitle>
          <TableHeaderActions>
            <ButtonLink
              primary='true'
              to={APP_URLS.toCreateClientIntegration(Number(customerId), Number(clientId))}
              disabled={isCreateButtonDisabled}
            >
              <Message id='client-integrations-table-add-new-integration' />
            </ButtonLink>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </TableHeaderActions>
        </TableHeader>
        <DataTable
          isFetching={isFetching}
          data={integrations}
          loadingComponent={Spinner}
          columns={clientIntegrationsTableColumns(
            Number(customerId),
            Number(clientId),
            onFlipToggleChangeHandler,
            onTestConnectionClickHandler,
          )}
          emptyMessage={<Message id='no-data-message' />}
        />
      </TableWrapper>
    </>
  );
};

export default IntegrationsList;
