import { Message } from 'common/components/Message';
import { SomethingWrongIcon } from 'common/components/Icons';
import { Content, SubTitle, Title, Wrapper, StyledLink, IconWrapper } from './styles';

const ApplicationFallback = () => {
  return (
    <Wrapper>
      <Content>
        <IconWrapper>
          <SomethingWrongIcon />
        </IconWrapper>
        <Title>
          <Message id='error-boundary-page-something-went-wrong' />
        </Title>
        <SubTitle>
          <Message id='error-boundary-page-brace-yourself-till-we-get-fixed-error' />
        </SubTitle>
        <SubTitle>
          <Message id='error-boundary-page-you-may-also-refresh-the-page' />
          <StyledLink href='/'>
            <Message id='error-boundary-page-main-page' />
          </StyledLink>
        </SubTitle>
      </Content>
    </Wrapper>
  );
};

export default ApplicationFallback;
