import { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'config/authConfig';
import { AccessToken } from 'types/Auth';

export const useAccessToken = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState<AccessToken>(null);

  useEffect(() => {
    if (!accessToken && account && inProgress === InteractionStatus.None) {
      const request = {
        scopes: loginRequest.scopes,
        account: account,
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('%c acquireTokenSilent error: ', 'color:red; font-weight: 700; font-size: 12px;', error);
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(request)
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('%c acquireTokenPopup error: ', 'color:red; font-weight: 700; font-size: 12px;', error);
              });
          } else {
            // eslint-disable-next-line no-console
            console.error(
              '%c Something went wrong with Azure AD: ',
              'color:red; font-weight: 700; font-size: 12px;',
              error,
            );
          }
        });
    }
  }, [account, inProgress, instance, accessToken]);

  return accessToken;
};
