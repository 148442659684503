import { AccessToken } from './Auth';

export interface ImportFileData {
  accessToken: AccessToken;
  importType: ImportType;
  importMode: ImportMode;
  customerId: number;
  uploadFile: File;
  integrationSystemId?: string;
}

export interface ImportFileParams {
  customerId: number;
  importMode: ImportMode;
}

export interface ImportFileRequestData {
  accessToken: AccessToken;
  requestParams: ImportFileParams;
  formData: FormData;
}

export enum ImportType {
  IMPORT_CLIENTS = 'ImportClients',
  IMPORT_INTEGRATIONS = 'IMPORT_INTEGRATIONS',
  IMPORT_APPROVER_REFERENCES = 'IMPORT_APPROVER_REFERENCES',
}

export enum ImportMode {
  CREATE = 'Create',
  UPDATE = 'Update',
  UPDATE_NOT_ACTIVE = 'UpdateNotActive',
}

export const importModeNamesMapping: { [ImportMode: string]: any } = {
  [ImportMode.CREATE]: 'Create',
  [ImportMode.UPDATE]: 'Update',
  [ImportMode.UPDATE_NOT_ACTIVE]: 'Update not active',
};
