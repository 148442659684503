import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import Header from './Header';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(Header);
