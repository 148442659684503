import { Icon } from 'types/Icon';

export const PreviousIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '6'} height={height || '10'} viewBox='0 0 6 10'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M5.62497635,5 C5.62497635,5.16603595 5.55657521,5.32223273 5.43945128,5.43945128 L1.06442763,9.81447493 C0.94730369,9.93159886 0.791012299,10 0.624976348,10 C0.283254494,10 0,9.71674551 0,9.37492904 L0,0.624976348 C0,0.283254494 0.283254494,0 0.624976348,0 C0.791012299,0 0.94730369,0.0684011353 1.06442763,0.185525071 L5.43945128,4.56045412 C5.55657521,4.67776727 5.62497635,4.83396405 5.62497635,5'
        id='path-PreviousIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-330.000000, -2636.000000)'>
        <g transform='translate(120.000000, 2573.000000)'>
          <g transform='translate(0.000000, 49.000000)'>
            <g transform='translate(210.000000, 14.000000)'>
              <use
                fill={color || 'currentColor'}
                transform='translate(2.812488, 5.000000) scale(-1, 1) translate(-2.812488, -5.000000) '
                xlinkHref='#path-PreviousIcon-1'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
