export const APP_URLS = {
  main: '/',
  login: '/login',
  dashboard: '/dashboard',
  settings: '/settings',
  userAdministration: '/user-administration',
  createSetup: '/create-setup',
  customers: '/customers',
  releaseNotes: '/release-notes',
  clients: '/clients',
  customerCreate: '/customers/create',
  integrations: '/integrations',
  operationStatus: '/operation-status',
  transferEInvoices: '/transfer-einvoices',
  integrationsDefault: '/integrations-default',
  monitoring: '/monitoring',
  monitoringSubscriptions: '/monitoring/subscriptions-monitoring',
  monitoringInvoice: '/monitoring/invoice-monitoring',
  customer: '/customers/:customerId',
  customerProfile: '/customers/:customerId/profile',
  customerData: '/customers/:customerId/profile/customer-data',
  customerPortal: '/customers/:customerId/profile/portal',
  customerPortalCreate: '/customers/:customerId/profile/portal/create',
  customerPortalUserCreate: '/customers/:customerId/profile/portal/:portalId/users/create',
  customerIntegrations: '/customers/:customerId/profile/integrations',
  customerIntegrationProfile: '/customers/:customerId/profile/integrations/:integrationId/profile',
  createCustomerIntegration: '/customers/:customerId/profile/integrations/create',
  customerClients: '/customers/:customerId/clients',
  client: '/customers/:customerId/clients/:clientId',
  clientProfile: '/customers/:customerId/clients/:clientId/client-data',
  clientCreate: '/customers/:customerId/clients/create',
  customerSettings: '/customers/:customerId/settings',
  customerTransferHistoricalData: '/customers/:customerId/transfer-historical-data',
  customerSyncMasterData: '/customers/:customerId/sync-master-data',
  customerTransferEInvoices: '/customers/:customerId/transfer-einvoices',
  customerOperations: '/customers/:customerId/operation-status',
  clientOperations: '/customers/:customerId/clients/:clientId/operation-status',
  clientApproverReferences: '/customers/:customerId/clients/:clientId/approver-references',
  clientIntegrations: '/customers/:customerId/clients/:clientId/integrations',
  clientIntegrationCreate: '/customers/:customerId/clients/:clientId/integrations/create',
  clientIntegrationProfile: '/customers/:customerId/clients/:clientId/integrations/:integrationId/profile',
  clientTransformations: '/customers/:customerId/clients/:clientId/transformations',
  clientTransformationCreate: '/customers/:customerId/clients/:clientId/transformations/create',
  clientTransformationProfile: '/customers/:customerId/clients/:clientId/transformations/:transformationId/profile',

  toCustomerProfile: (customerId: number) => `/customers/${customerId}/profile`,
  toCustomerData: (customerId: number) => `/customers/${customerId}/profile/customer-data`,
  toCustomerClients: (customerId: number) => `/customers/${customerId}/clients`,

  toCustomerIntegrations: (customerId: number) => `/customers/${customerId}/profile/integrations`,
  toCreateCustomerIntegration: (customerId: number) => `/customers/${customerId}/profile/integrations/create`,
  toCustomerIntegrationProfile: (customerId: number, integrationId: string) =>
    `/customers/${customerId}/profile/integrations/${integrationId}/profile`,

  toCustomerPortal: (customerId: number) => `/customers/${customerId}/profile/portal`,
  toCustomerPortalCreate: (customerId: number) => `/customers/${customerId}/profile/portal/create`,
  toCustomerPortalUserCreate: (customerId: number, portalId: string) =>
    `/customers/${customerId}/profile/portal/${portalId}/users/create`,

  toCreateClients: (customerId: number) => `/customers/${customerId}/clients/create`,
  toClient: (customerId: number, clientId: number) => `/customers/${customerId}/clients/${clientId}`,
  toClientProfile: (customerId: number, clientId: number) => `/customers/${customerId}/clients/${clientId}/client-data`,

  toClientIntegrations: (customerId: number, clientId: number) =>
    `/customers/${customerId}/clients/${clientId}/integrations`,
  toCreateClientIntegration: (customerId: number, clientId: number) =>
    `/customers/${customerId}/clients/${clientId}/integrations/create`,
  toClientIntegrationProfile: (customerId: number, clientId: number, integrationId: string) =>
    `/customers/${customerId}/clients/${clientId}/integrations/${integrationId}/profile`,

  toClientTransformations: (customerId: number, clientId: number) =>
    `/customers/${customerId}/clients/${clientId}/transformations`,
  toCreateClientTransformation: (customerId: number, clientId: number) =>
    `/customers/${customerId}/clients/${clientId}/transformations/create`,
  toClientTransformationProfile: (customerId: number, clientId: number, transformationId: string) =>
    `/customers/${customerId}/clients/${clientId}/transformations/${transformationId}/profile`,

  toClientOperations: (customerId: number, clientId: number) =>
    `/customers/${customerId}/clients/${clientId}/operation-status`,

  toClientApproverReferences: (customerId: number, clientId: number) =>
    `/customers/${customerId}/clients/${clientId}/approver-references`,

  toCustomerTransferHistoricalData: (customerId: number) => `/customers/${customerId}/transfer-historical-data`,
  toCustomerSyncMasterData: (customerId: number) => `/customers/${customerId}/sync-master-data`,
  toCustomerTransferEInvoices: (customerId: number) => `/customers/${customerId}/transfer-einvoices`,
  toCustomerOperations: (customerId: number) => `/customers/${customerId}/operation-status`,
};
