import { ReactElement } from 'react';
import { APP_URLS } from 'common/constants';
import { FlipToggle, Message } from 'common/components';
import { TableAlignment } from 'common/components/DataTable/types';
import { StyledLink } from './styles';

interface ClientTransformationsDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: ClientTransformationsTableRow;
}

interface ClientTransformationsTableRow {
  id: string;
  isActive: boolean;
  typeId: string;
  typeName: string;
  displayName: string;
}

export const clientTransformationListTableColumns = (
  customerId: number,
  clientId: number,
  onFlipToggleChangeHandler: any,
) => {
  return [
    {
      name: 'transformationName',
      title: <Message id='client-transformations-table-transformation-name' />,
      width: 400,
      cellCallback: ({ row }: ClientTransformationsDataTable) => {
        return (
          <>
            <StyledLink to={APP_URLS.toClientTransformationProfile(Number(customerId), Number(clientId), row.id)}>
              {row.displayName}
            </StyledLink>
          </>
        );
      },
    },
    {
      name: 'transformationType',
      title: <Message id='client-transformations-table-transformation-type' />,
      width: 400,
      cellCallback: ({ row }: ClientTransformationsDataTable) => {
        return (
          <>
            <StyledLink to={APP_URLS.toClientTransformationProfile(Number(customerId), Number(clientId), row.id)}>
              {row.typeName}
            </StyledLink>
          </>
        );
      },
    },
    {
      name: 'status',
      title: <Message id='client-transformations-table-transformation-status' />,
      width: 50,
      align: TableAlignment.CENTER,
      cellCallback: ({ row: { id, typeId, isActive } }: ClientTransformationsDataTable) => (
        <>
          <FlipToggle
            dataOn='Active'
            dataOff='Inactive'
            isChecked={isActive}
            onChange={(event: any) => onFlipToggleChangeHandler({ event, id, typeId })}
          />
        </>
      ),
    },
  ];
};

export default clientTransformationListTableColumns;
