import { ReactElement } from 'react';
import { APP_URLS } from 'common/constants';
import { Button, FlipToggle, Message } from 'common/components';
import { mapSystemName } from 'utils';
import { IntegrationsLevel, IntegrationsSystemTypes } from 'types/Integrations';
import { TableAlignment } from 'common/components/DataTable/types';
import { StyledLink, TableButtonWrapper } from './styles';

interface ClientIntegrationsDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: ClientIntegrationsTableRow;
}

interface ClientIntegrationsTableRow {
  id: string;
  isActive: boolean;
  showTestConnectionButton: boolean;
  missingParentIntegrations: IntegrationsLevel[];
  systemId: string;
  systemName: string;
  systemType: IntegrationsSystemTypes;
}

export const clientIntegrationsTableColumns = (
  customerId: number,
  clientId: number,
  onFlipToggleChangeHandler: any,
  onTestConnectionClickHandler: (id: string) => void,
) => {
  return [
    {
      name: 'id',
      title: <Message id='client-integrations-table-integration-id' />,
      width: 500,
      cellCallback: ({ row }: ClientIntegrationsDataTable) => (
        <StyledLink
          to={APP_URLS.toClientIntegrationProfile(Number(customerId), Number(clientId), row.id)}
          title={row.id}
        >
          {row.id}
        </StyledLink>
      ),
    },
    {
      name: 'systemName',
      title: <Message id='client-integrations-table-integration-system' />,
      width: 400,
      cellCallback: ({ row }: ClientIntegrationsDataTable) => {
        return (
          <>
            <StyledLink to={APP_URLS.toClientIntegrationProfile(Number(customerId), Number(clientId), row.id)}>
              {mapSystemName(row.systemName)}
            </StyledLink>
          </>
        );
      },
    },
    {
      name: 'systemType',
      title: <Message id='client-integrations-table-integration-type' />,
      width: 250,
    },
    {
      name: 'status',
      title: <Message id='client-integrations-table-integration-status' />,
      width: 150,
      align: TableAlignment.CENTER,
      cellCallback: ({ row: { id, systemName, isActive } }: ClientIntegrationsDataTable) => (
        <>
          <FlipToggle
            dataOn='Active'
            dataOff='Inactive'
            isChecked={isActive}
            onChange={(event: any) => onFlipToggleChangeHandler({ event, id, systemName })}
          />
        </>
      ),
    },
    {
      name: 'testConnection',
      title: <Message id='client-integrations-table-integration-test-connection' />,
      width: 200,
      align: TableAlignment.CENTER,
      cellCallback: ({ row: { id, showTestConnectionButton } }: ClientIntegrationsDataTable) => (
        <TableButtonWrapper>
          {showTestConnectionButton ? (
            <Button secondary size={'tiny'} onClick={() => onTestConnectionClickHandler(id)}>
              <Message id={'test-connection'} />
            </Button>
          ) : null}
        </TableButtonWrapper>
      ),
    },
  ];
};

export default clientIntegrationsTableColumns;
