import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/clientReducer';
import { getTransformationCreateView } from '../../logic/clientSelectors';
import TransformationCreate from './TransformationCreate';

const mapStateToProps = ({ client }: RootState) => getTransformationCreateView(client);

const mapDispatchToProps = {
  getClient: actionCreators.getClient,
  setStatus: actionCreators.setClientStatus,
  getAvailableTransformationTypes: actionCreators.getClientAvailableTransformationTypes,
  getClientTransformations: actionCreators.getClientTransformations,
  createClientTransformation: actionCreators.createClientTransformation,
  getClientTransformationSchema: actionCreators.getClientTransformationSchema,
  resetPartial: actionCreators.clientResetPartial,
  integrationReset: actionCreators.integrationReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(TransformationCreate);
