export const getTextInputBackground = ({ theme, isError }: any) => {
  if (!isError) {
    // return theme.form.activeBackground;
  }
  return theme.backgroundPrimary;
};

export const getTextInputColor = ({ theme, readOnly, disabled }: any) => {
  if (disabled || readOnly) {
    return theme.fontSecondary;
  }
  return theme.fontPrimary;
};

export const getTextInputPadding = ({ leftIcon, isTypePassword }: any) => {
  if (leftIcon) {
    return '7px 10px 7px 42px';
  }
  if (isTypePassword) {
    return '7px 32px 7px 10px';
  }
  return '7px 10px';
};

export const getTextInputBorder = ({ theme, isFocused, isError }: any) => {
  if (isError) {
    return theme.colors.error;
  }
  if (isFocused) {
    return theme.primary;
  }
  return theme.form.border;
};

export const getPlaceholderColor = ({ theme, isError, disabled }: any) => {
  if (isError) {
    return theme.fontError;
  }
  if (disabled) {
    return theme.fontSecondary;
  }
  return theme.fontPrimary;
};
