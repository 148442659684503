import { ReactNode, useCallback, useMemo } from 'react';
import { Head } from './Head';
import { Body } from './Body';
import { ColumnItem, GetRowKey, TableRowSelection, TableSortOrder } from './types';
import useSelection from './hooks/useSelection';
import useLazyKVMap from './hooks/useLazyKVMap';
import { Table, TableContainer } from './styles';

interface DataTableProps {
  isFetching?: boolean;
  hiddenHeader?: boolean;
  nestedTable?: boolean;
  collapsedTable?: boolean;
  rowSelection?: TableRowSelection;
  orderBy?: string;
  sortOrder?: TableSortOrder;
  emptyMessage?: string | ReactNode | null;
  rowKey?: string | GetRowKey;
  data: any[];
  collapsedFlag?: string;
  collapsedColumns?: any;
  columns: ColumnItem[];
  loadingComponent?: any; // TODO: change to ReactNode
  // eslint-disable-next-line @typescript-eslint/ban-types
  rowCallbacks?: {};
  onSortChange?: (name: string, direction: TableSortOrder) => void;
  collapsedCallback?: (payload: any) => void;
}

const DataTable = ({
  isFetching,
  hiddenHeader = false,
  nestedTable,
  rowSelection,
  rowKey = 'key',
  collapsedTable,
  orderBy = '',
  sortOrder = TableSortOrder.ASCENDING,
  emptyMessage,
  data,
  collapsedFlag,
  collapsedColumns,
  collapsedCallback,
  columns,
  loadingComponent,
  rowCallbacks = {},
  onSortChange,
}: DataTableProps) => {
  const LoadingComponent = loadingComponent;

  const getRowKey = useMemo<GetRowKey>(() => {
    if (typeof rowKey === 'function') {
      return rowKey;
    }

    return (record: any) => (record as any)?.[rowKey as string];
  }, [rowKey]);

  const [getRecordByKey] = useLazyKVMap(data, getRowKey);

  const selectionConfig = {
    data,
    getRowKey,
    getRecordByKey,
  };

  const [transformColumns] = useSelection(rowSelection, selectionConfig);

  const transformedColumns = useCallback(
    (innerColumns: ColumnItem[]): ColumnItem[] => {
      return transformColumns(innerColumns);
    },
    [transformColumns],
  );

  const getHead = () =>
    !hiddenHeader ? (
      <Head
        collapsedTable={collapsedTable}
        columns={transformedColumns(columns)}
        orderBy={orderBy}
        sortOrder={sortOrder}
        onSortChange={onSortChange}
      />
    ) : null;

  const getBody = () => (
    <Body
      collapsedTable={collapsedTable}
      collapsedFlag={collapsedFlag}
      collapsedColumns={collapsedColumns}
      collapsedCallback={collapsedCallback}
      columns={transformedColumns(columns)}
      data={data}
      rowCallbacks={rowCallbacks}
      nestedTable={nestedTable}
      emptyMessage={emptyMessage}
    />
  );

  return (
    <TableContainer>
      <Table>
        {getHead()}
        {getBody()}
      </Table>
      {isFetching && LoadingComponent && <LoadingComponent overlay />}
    </TableContainer>
  );
};

export default DataTable;
