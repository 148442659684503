import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  height: 40px;
  overflow: hidden;
`;

export const BreadcrumbsItem = styled.div`
  white-space: nowrap;
`;

export const BreadcrumbsText = styled.span`
  font-weight: 600;
`;

export const Separator = styled.span`
  padding: 0 10px;
`;

export const BreadcrumbsLink = styled(Link)`
  color: ${({ theme }) => theme.fontPrimary};

  &:hover {
    color: ${({ theme }) => theme.linkColor};
  }
`;
