import styled from 'styled-components';
import { OperationStatus } from 'types/Operations';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const TableWrapper = styled.div``;

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 56px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 10px;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
`;

export const FiltersRight = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    margin-left: 10px;
  }
`;

export const FiltersColumnsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 50%;
`;

export const FiltersColumn = styled.div`
  min-width: 180px;
  margin-right: 24px;
  width: 100%;
`;

export const FiltersColumnWide = styled(FiltersColumn)`
  max-width: 270px;
`;

export const FiltersColumnDateRange = styled(FiltersColumn)`
  max-width: 210px;
`;

interface StatusLabelType {
  status: string;
}

const getStatusColor = (theme: any, status: string) => {
  if (status === OperationStatus.SUCCESS) {
    return theme.status.success;
  }
  if (status === OperationStatus.START) {
    return theme.status.pending;
  }
  if (status === OperationStatus.PARTIAL_SUCCESS) {
    return theme.status.warning;
  }
  if (status === OperationStatus.FAILURE) {
    return theme.status.error;
  }

  return theme.status.info;
};

export const StatusLabel = styled.span<StatusLabelType>`
  font-weight: 600;
  color: ${({ theme, status }) => getStatusColor(theme, status)};
`;

export const ItemsCount = styled.span`
  // display: flex;
  // justify-content: center;
`;

export const LinkWrapper = styled.div`
  width: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 10px;
`;

export const TableCellContent = styled.div`
  font-size: 12px;
  letter-spacing: -0.5px;
`;

export const TableCellItem = styled.span`
  display: block;
  margin-bottom: 4px;
  font-weight: 400;

  strong {
    font-weight: 400;
    font-size: 11px;
    display: inline-block;
    width: 45px;
    letter-spacing: 0;
  }
`;

export const DateWrap = styled.div`
  overflow: hidden;
  user-select: text;
`;
