import { useEffect } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { APP_URLS } from 'common/constants';
import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';
import {
  CustomerByIdRequestData,
  CustomerData,
  CustomerRequestDataExtended,
  CustomerStatusRequestData,
} from 'types/Customer';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import StatusSelector from 'components/StatusSelector';
import { useAccessToken } from 'hooks';
import { FormSelectOption } from 'types/Form';
import { featureFlags } from 'featureFlags';
import getCustomerBreadcrumbs from './getCustomerBreadcrumbs';
import IntegrationsListContainer from './Integrations/IntegrationList/IntegrationsListContainer';
import { CustomerEditForm } from './CustomerEditForm';

import { Content, Head, HeadActions, TabContent, TabContentInner, TabsWrapper, Title, TitleMark } from './styles';

import CustomerTabsList from './CustomerTabsList';
import { PortalView, PortalCreate } from './Portal';
import PortalUserCreate from './Portal/PortalUserCreate';

interface CustomerProps {
  isFetching: boolean;
  customer: CustomerData;
  errorCode: number | null;
  customerTypeOptions: FormSelectOption[];
  getCustomer: (payload: CustomerByIdRequestData) => void;
  updateCustomer: (payload: CustomerRequestDataExtended) => void;
  setStatus: (payload: CustomerStatusRequestData) => void;
  resetPartial: () => void;
}

type CustomerUrlParams = {
  customerId: string;
};

const Customer = ({
  isFetching,
  customer,
  errorCode,
  customerTypeOptions,
  getCustomer,
  updateCustomer,
  setStatus,
  resetPartial,
}: CustomerProps) => {
  const accessToken = useAccessToken();
  const pathname = useLocation().pathname;
  // TODO - this is a hack for breadcrumbs which renders those only if we are on customer-data (initial) page
  // to let the breadcrumbs from other tabs being rendered (as otherwise they are overridden by this container).
  // This (inherited from ancestor) approach is not the best and should be reviewed.
  const isCustomerDataPage = pathname.toLowerCase().includes('customer-data');
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const { customerId } = useParams<CustomerUrlParams>();

  useEffect(() => {
    if (accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, getCustomer, customerId]);

  useEffect(() => {
    if (!isCustomerDataPage) {
      return;
    }
    setBreadcrumbs(
      getCustomerBreadcrumbs(Number(customerId), customer.name).concat([
        {
          title: BREADCRUMBS_TITLES.CUSTOMER_DATA,
          isActive: true,
        },
      ]),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs, isCustomerDataPage]);

  return (
    <>
      <Head>
        <Title>
          {customer?.name}
          <TitleMark>id#{customer?.id}</TitleMark>
        </Title>
        <HeadActions>
          {customer.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={customer.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <CustomerTabsList customerId={Number(customerId)} />
          <TabContent>
            <TabContentInner>
              <Switch>
                <Route
                  exact
                  path={APP_URLS.customerData}
                  render={() => (
                    <CustomerEditForm
                      isFetching={isFetching}
                      formData={customer}
                      errorCode={errorCode}
                      customerTypeOptions={customerTypeOptions}
                      resetPartial={resetPartial}
                      onSubmitHandler={updateCustomer}
                    />
                  )}
                />
                <Route exact path={APP_URLS.customerIntegrations} render={() => <IntegrationsListContainer />} />
                {featureFlags.customerPortal && (
                  <>
                    <Route exact path={APP_URLS.customerPortal} render={() => <PortalView />} />
                    <Route exact path={APP_URLS.customerPortalCreate} render={() => <PortalCreate />} />
                    <Route exact path={APP_URLS.customerPortalUserCreate} render={() => <PortalUserCreate />} />
                  </>
                )}
              </Switch>
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default Customer;
