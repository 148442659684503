import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/clientReducer';
import { getIntegrationCreateView } from '../../logic/clientSelectors';
import IntegrationCreate from './IntegrationCreate';

const mapStateToProps = ({ client }: RootState) => getIntegrationCreateView(client);

const mapDispatchToProps = {
  getAvailableSystems: actionCreators.getClientAvailableSystems,
  createClientIntegration: actionCreators.createClientIntegration,
  getClient: actionCreators.getClient,
  setStatus: actionCreators.setClientStatus,
  getClientConfigurationSchema: actionCreators.getClientConfigurationSchema,
  resetPartial: actionCreators.clientResetPartial,
  integrationReset: actionCreators.integrationReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationCreate);
