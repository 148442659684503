import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, Message, Modal, Textarea } from 'common/components';
import { WarningIcon } from 'common/components/Icons';
import { validateStatusReason } from 'utils/validation';
import { Status } from 'types/Status';
import { useAccessToken } from 'hooks';
import {
  FormButtonsCentered,
  IconCircle,
  ModalText,
  ReasonBlock,
  StatusLabel,
  StatusSelectorWrapper,
  WarningTitle,
} from './styles';

type UrlParams = {
  customerId: string;
  clientId: string;
};

interface StatusSelectorProps {
  isDisabled: boolean;
  currentStatus: Status;
  onStatusSelect: (payload: any) => void;
}

const StatusSelector = ({ currentStatus, isDisabled, onStatusSelect }: StatusSelectorProps) => {
  const accessToken = useAccessToken();
  const { customerId, clientId } = useParams<UrlParams>();
  const isClientPage = Boolean(clientId);
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);
  const [statusReason, setStatusReason] = useState('');
  const [statusReasonError, setStatusReasonError] = useState('');
  const [targetStatus, setTargetStatus] = useState<Status | null>(null);

  const getButtonVariant = useMemo(() => {
    switch (currentStatus) {
      case Status.PENDING:
        return 'pending';
      case Status.ACTIVE:
        return 'success';
      case Status.SUSPENDED:
        return 'secondary';
      case Status.DEACTIVATED:
        return 'danger';

      default:
        return 'pending';
    }
  }, [currentStatus]);

  const getButtonGroupTitle = useMemo(() => {
    switch (currentStatus) {
      case Status.PENDING:
        return <Message id={'pending-button'} />;
      case Status.ACTIVE:
        return <Message id={'active-button'} />;
      case Status.SUSPENDED:
        return <Message id={'suspended-button'} />;
      case Status.DEACTIVATED:
        return <Message id={'deactivated-button'} />;

      default:
        return <Message id={'pending-button'} />;
    }
  }, [currentStatus]);

  const getButtonGroupItems = useMemo(() => {
    switch (currentStatus) {
      case Status.PENDING:
        return [Status.ACTIVE, Status.SUSPENDED, Status.DEACTIVATED];
      case Status.ACTIVE:
        return [Status.SUSPENDED, Status.DEACTIVATED];
      case Status.SUSPENDED:
        return [Status.ACTIVE, Status.DEACTIVATED];
      case Status.DEACTIVATED:
        return [Status.ACTIVE, Status.SUSPENDED];

      default:
        return [];
    }
  }, [currentStatus]);

  // REASON MODAL START
  const onReasonChangeHandler = (e: any) => {
    setStatusReasonError(validateStatusReason(e));
    return setStatusReason(e);
  };

  const onModalCancelHandler = useCallback(() => {
    setWarningModalVisible(false);
    setStatusReason('');
    setStatusReasonError('');
  }, []);

  const onModalConfirmHandler = useCallback(
    (targetStatus: Status | null) => {
      setWarningModalVisible(false);
      if (targetStatus && statusReason) {
        if (isClientPage) {
          onStatusSelect({
            accessToken,
            customerId: Number(customerId),
            id: Number(clientId),
            targetStatus: targetStatus,
            statusReason: statusReason,
          });
        } else {
          onStatusSelect({
            accessToken,
            id: Number(customerId),
            targetStatus: targetStatus,
            statusReason: statusReason,
          });
        }
      }
      setStatusReason('');
      setStatusReasonError('');
    },
    [accessToken, isClientPage, clientId, customerId, statusReason, onStatusSelect],
  );

  const onButtonGroupItemClick = useCallback(
    (item: any) => {
      if (item === Status.ACTIVE) {
        if (isClientPage) {
          onStatusSelect({
            accessToken,
            customerId: Number(customerId),
            id: Number(clientId),
            targetStatus: Status.ACTIVE,
          });
        } else {
          onStatusSelect({
            accessToken,
            id: Number(customerId),
            targetStatus: Status.ACTIVE,
          });
        }
      }
      if (item === Status.SUSPENDED) {
        setWarningModalVisible(true);
        setTargetStatus(Status.SUSPENDED);
      }
      if (item === Status.DEACTIVATED) {
        setWarningModalVisible(true);
        setTargetStatus(Status.DEACTIVATED);
      }
    },
    [accessToken, isClientPage, clientId, customerId, onStatusSelect],
  );

  // REASON MODAL ELEMENTS
  const reasonModalTitle = () => {
    return (
      <WarningTitle>
        <IconCircle>
          <WarningIcon height={14} width={3} />
        </IconCircle>
        <Message id='modal-title-warning' />
      </WarningTitle>
    );
  };

  const modalFooterButtons = useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onModalCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button
          primary
          onClick={() => onModalConfirmHandler(targetStatus)}
          disabled={!!statusReasonError || !statusReason}
        >
          <Message id='confirm-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onModalCancelHandler, onModalConfirmHandler, statusReasonError, statusReason, targetStatus],
  );

  const reasonModalText = () => {
    if (targetStatus === Status.SUSPENDED) {
      return (
        <>
          {isClientPage ? (
            <ModalText>
              <Message id='edit-client-confirmation-modal-are-you-sure-to-suspend-this-client' />
            </ModalText>
          ) : (
            <>
              <ModalText>
                <Message id='edit-customer-confirmation-modal-if-you-choose-suspend-all-client-will-be-suspended' />
              </ModalText>
              <ModalText>
                <Message id='edit-customer-confirmation-modal-do-you-want-to-proceed-please-state-reason' />
              </ModalText>
            </>
          )}
        </>
      );
    }

    return (
      <>
        {isClientPage ? (
          <ModalText>
            <Message id='edit-client-confirmation-modal-are-you-sure-to-deactivate-this-client' />
          </ModalText>
        ) : (
          <>
            <ModalText>
              <Message id='edit-customer-confirmation-modal-if-you-choose-deactivate-all-client-will-be-deactivate' />
            </ModalText>
            <ModalText>
              <Message id='edit-customer-confirmation-modal-do-you-want-to-proceed-please-state-reason' />
            </ModalText>
          </>
        )}
      </>
    );
  };

  const reasonModalLabel = () => {
    if (targetStatus === Status.SUSPENDED) {
      return <Message id='suspend-reason-label' />;
    }
    return <Message id='deactivation-reason-label' />;
  };
  // REASON MODAL END

  return (
    <>
      <StatusSelectorWrapper>
        <StatusLabel>
          <Message id='status-label' />
        </StatusLabel>
        <ButtonGroup
          size='small'
          variant={getButtonVariant}
          isDisabled={isDisabled}
          title={getButtonGroupTitle}
          items={getButtonGroupItems}
          onItemClick={onButtonGroupItemClick}
        />
      </StatusSelectorWrapper>

      <Modal
        hideOnOverlayClicked={false}
        closeOnEsc={false}
        isVisible={isWarningModalVisible}
        title={reasonModalTitle()}
        onCloseClicked={onModalCancelHandler}
        footer={modalFooterButtons}
      >
        {reasonModalText()}
        <ReasonBlock>
          <Textarea
            id='statusReason'
            isError={!!statusReasonError}
            value={statusReason}
            label={reasonModalLabel()}
            onChange={onReasonChangeHandler}
            minHeight={50}
            errorText={<Message id={statusReasonError} />}
          />
        </ReasonBlock>
      </Modal>
    </>
  );
};

export default StatusSelector;
