import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
