import { TableAlignment } from './types';

export declare type Key = string | number;

export function arrDel(list: Key[], value: Key) {
  const clone = list.slice();
  const index = clone.indexOf(value);
  if (index >= 0) {
    clone.splice(index, 1);
  }
  return clone;
}

export function arrAdd(list: Key[], value: Key) {
  const clone = list.slice();
  if (clone.indexOf(value) === -1) {
    clone.push(value);
  }
  return clone;
}

export const getTableAlignment = (alignment: TableAlignment | undefined) => {
  switch (alignment) {
    case TableAlignment.LEFT:
      return 'left';
    case TableAlignment.CENTER:
      return 'center';
    case TableAlignment.RIGHT:
      return 'right';

    default:
      return 'left';
  }
};
