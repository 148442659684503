import { RootState } from 'store/rootReducer';
import { NotificationProps } from 'types/Notifications';

interface NotificationView {
  notifications: NotificationProps[];
}

export const getNotificationsView = ({ notifications }: RootState): NotificationView => {
  return {
    notifications: notifications.notifications,
  };
};
