import { IPAIAdminApiClient } from 'common/api/generated/PAIAdminApiClient';

export const retryInvoiceExport = async (
  paiClient?: IPAIAdminApiClient,
  syncId?: string,
): Promise<void> => {

  if (syncId === undefined) {
    throw new Error('Can not retry eInvoice, syncId is undefined');
  }

  await paiClient?.operationsControllerTriggerInvoiceExportRetry({ syncId });

};
