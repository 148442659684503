import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/customerReducer';
import { getIntegrationsView } from '../../logic/customerSelectors';
import IntegrationsList from './IntegrationsList';

const mapStateToProps = ({ customer }: RootState) => getIntegrationsView(customer);

const mapDispatchToProps = {
  getCustomerIntegrations: actionCreators.getCustomerIntegrations,
  getAvailableSystems: actionCreators.getCustomerAvailableSystems,
  getCustomerTestConnection: actionCreators.getCustomerTestConnection,
  updateCustomerIntegrationStatus: actionCreators.updateCustomerIntegrationStatus,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationsList);
