import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { History as HistoryInterface } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import styled from 'styled-components';
import { AccessToken } from 'types/Auth';
import Router from './routes';
import NotificationContainer from './components/NotificationManager';
import { UnAuthenticatedRouter } from './routes/Router';
import GlobalStyles from './globalStyles';
import { useAccessToken } from './hooks';
import { actionCreators } from './common/reducers/sharedReducer';

const AppContainer = styled.div``;

interface Props {
  history: HistoryInterface;
  getIntegrationSystems: (payload: AccessToken) => void;
  getDictionaries: (payload: AccessToken) => void;
}

const App: React.FC<Props> = ({ history, getIntegrationSystems, getDictionaries }) => {
  const accessToken = useAccessToken();

  useEffect(() => {
    if (accessToken) {
      getIntegrationSystems(accessToken);
      getDictionaries(accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <ConnectedRouter history={history}>
      <NotificationContainer>
        <AppContainer>
          <GlobalStyles />
          <AuthenticatedTemplate>
            <Router />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <UnAuthenticatedRouter />
          </UnauthenticatedTemplate>
        </AppContainer>
      </NotificationContainer>
    </ConnectedRouter>
  );
};

const mapDispatchToProps = {
  getIntegrationSystems: actionCreators.getIntegrationSystems,
  getDictionaries: actionCreators.getDictionaries,
};

export default connect(null, mapDispatchToProps)(App);
