import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators as customerActionCreators } from 'screens/Customer/logic/customerReducer';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import SyncMasterData from './SyncMasterData';
import { actionCreators } from './logic/syncMasterDataReducer';
import { getSyncMasterDataView } from './logic/syncMasterDataSelectors';

const mapStateToProps = ({ customer, syncMasterData }: RootState) => getSyncMasterDataView(customer, syncMasterData);

const mapDispatchToProps = {
  getClientsForSyncMasterData: actionCreators.getClientsForSyncMasterData,
  getCustomer: customerActionCreators.getCustomer,
  executeSyncMasterData: actionCreators.executeSyncMasterData,
  setTablePage: actionCreators.setTablePage,
  setTablePerPage: actionCreators.setTablePerPage,
  setFilterStatus: actionCreators.setSyncMasterDataTableFilterStatus,
  setTableOrder: actionCreators.setSyncMasterDataTableSortOrder,
  setFilterSearch: actionCreators.setSyncMasterDataTableFilterSearch,
  resetSyncMasterData: actionCreators.syncMasterDataReset,
  resetFilters: actionCreators.syncMasterDataResetFilters,
  addNotification: notificationsActionCreators.addNotification,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(SyncMasterData);
