import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators as customerActionCreators } from 'screens/Customer/logic/customerReducer';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { actionCreators } from './logic/transferHistoricalDataReducer';
import TransferHistoricalData from './TransferHistoricalData';
import { getTransferHistoricalDataView } from './logic/transferHistoricalDataSelectors';

const mapStateToProps = ({ customer, transferHistoricalData }: RootState) =>
  getTransferHistoricalDataView(customer, transferHistoricalData);

const mapDispatchToProps = {
  getClientsForTransferData: actionCreators.getClientsForTransferData,
  getCustomer: customerActionCreators.getCustomer,
  executeDataTransfer: actionCreators.executeDataTransfer,
  setTablePage: actionCreators.setTablePage,
  setTablePerPage: actionCreators.setTablePerPage,
  setFilterStatus: actionCreators.setTransferHistoricalDataTableFilterStatus,
  setTableOrder: actionCreators.setTransferHistoricalDataTableSortOrder,
  setFilterSearch: actionCreators.setTransferHistoricalDataTableFilterSearch,
  resetTransferData: actionCreators.transferHistoricalDataReset,
  resetFilters: actionCreators.transferHistoricalDataResetFilters,
  addNotification: notificationsActionCreators.addNotification,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(TransferHistoricalData);
