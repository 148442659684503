import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { APP_URLS, ErrorCodes } from 'common/constants';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Alert, Button, Message, Select, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import IntegrationForm from 'components/IntegrationForm';
import StatusSelector from 'components/StatusSelector';
import { CustomerByIdRequestData, CustomerData, CustomerStatusRequestData } from 'types/Customer';
import {
  IntegrationSystem,
  CustomerAvailableIntegrationsSystemRequestParams,
  IntegrationData,
  IntegrationSchema,
  IntegrationsLevel,
  IntegrationsSystems,
  CustomerConfigurationSchemaRequest,
  CreateCustomerIntegrationsRequestParams,
  IntegrationSystemOptions,
} from 'types/Integrations';
import { getAllFieldsFromSchema } from 'components/ConfigurationForm/utils';
import { prepareRequestData } from 'components/IntegrationForm/utils';
import { mapSystemName } from 'utils';
import { useAccessToken } from 'hooks';
import CustomerTabsList from 'screens/Customer/CustomerTabsList';
import getCustomerIntegrationsCreateBreadcrumbs from './getIntegrationCreateBreadcrumbs';
import {
  ButtonBack,
  Content,
  FormColumn,
  FormColumnWrapper,
  FormInfo,
  FormInfoTitle,
  FormRow,
  FormTitleMark,
  Head,
  HeadActions,
  TabContent,
  TabContentInner,
  TabHeader,
  TabsWrapper,
  TabTitle,
  Title,
  TitleMark,
} from './styles';

type CustomerUrlParams = {
  customerId: string;
  clientId: string;
};

interface IntegrationCreateProps {
  isFetching: boolean;
  errorCode: number | null;
  integrations: IntegrationData[];
  availableSystems: IntegrationSystem[];
  integration: IntegrationData;
  integrationSchema: IntegrationSchema;
  customer: CustomerData;
  getCustomer: (payload: CustomerByIdRequestData) => void;
  getAvailableSystems: (payload: CustomerAvailableIntegrationsSystemRequestParams) => void;
  setStatus: (payload: CustomerStatusRequestData) => void;
  createCustomerIntegration: (payload: CreateCustomerIntegrationsRequestParams) => void;
  getCustomerConfigurationSchema: (payload: CustomerConfigurationSchemaRequest) => void;
  resetPartial: () => void;
  integrationReset: () => void;
}

const IntegrationCreate = ({
  isFetching,
  errorCode,
  availableSystems,
  integration,
  integrationSchema,
  customer,
  setStatus,
  getCustomer,
  getAvailableSystems,
  getCustomerConfigurationSchema,
  createCustomerIntegration,
  resetPartial,
  integrationReset,
}: IntegrationCreateProps) => {
  const accessToken = useAccessToken();
  const { pathname } = useLocation();
  const isIntegrationsDefaultPage = pathname.toLowerCase().includes('integrations-default');
  const { push } = useHistory();
  const { customerId, clientId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [currentSystem, setCurrentSystem] = useState({ id: '0', value: IntegrationsSystems.UNKNOWN, label: '' });
  const [isSystemNameOptionsExists, setIsSystemNameOptionsExists] = useState(false);

  useEffect(() => {
    setBreadcrumbs(getCustomerIntegrationsCreateBreadcrumbs(Number(customerId), customer.name));
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs]);

  useEffect(() => {
    if (!customer.name && accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, getCustomer, customer.name, customerId]);

  useEffect(() => {
    if (accessToken) {
      getAvailableSystems({
        accessToken,
        customerId: Number(customerId),
      });
    }
    return () => integrationReset();
  }, [accessToken, customerId, getAvailableSystems, integrationReset]);

  useEffect(() => {
    setIsSystemNameOptionsExists(Boolean(availableSystems.length));
  }, [availableSystems]);

  const onSystemNameChangeHandler = (integrationOption: IntegrationSystemOptions) => {
    if (integrationOption.value !== currentSystem.value) {
      setCurrentSystem(integrationOption);
      integrationReset();
      resetPartial();

      getCustomerConfigurationSchema({
        accessToken,
        customerId: Number(customerId),
        isCreatePage: true,
        systemId: integrationOption.id,
      });
    }
  };

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toCustomerIntegrations(Number(customerId)));
  }, [customerId, push]);

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      const allFields = getAllFieldsFromSchema(integrationSchema, integration.settings);
      const preparePayload = prepareRequestData({
        integration: null,
        isIntegrationsDefaultPage,
        formData: data,
        fieldsArray: allFields,
        systemId: currentSystem.id,
      });

      createCustomerIntegration({
        accessToken,
        customerId: Number(customerId),
        requestData: preparePayload,
      });
    },
    [
      accessToken,
      isIntegrationsDefaultPage,
      currentSystem.id,
      integrationSchema,
      customerId,
      createCustomerIntegration,
      integration,
    ],
  );

  const renderForm = useMemo(
    () => (
      <IntegrationForm
        isFetching={isFetching}
        errorCode={null}
        integrationName={currentSystem.value}
        integration={integration}
        integrationSchema={integrationSchema}
        onSubmit={onSubmitButtonClickHandler}
        onCancel={onCancelHandler}
        resetForm={resetPartial}
        level={clientId ? IntegrationsLevel.CLIENT : IntegrationsLevel.CUSTOMER}
      />
    ),
    [
      isFetching,
      clientId,
      integration,
      integrationSchema,
      currentSystem.value,
      onCancelHandler,
      onSubmitButtonClickHandler,
      resetPartial,
    ],
  );

  const renderMissingSchemaAlert = useMemo(() => {
    if (errorCode === ErrorCodes.NOT_FOUND_SCHEMA) {
      return (
        <Alert
          title={
            <Message
              id='schema-for-customer-not-found-title'
              values={{
                variable: mapSystemName(currentSystem.value),
              }}
            />
          }
        >
          <p>
            <Message
              id='please-create-schema-before-create-integration'
              values={{
                variable: mapSystemName(currentSystem.value),
              }}
            />
          </p>
        </Alert>
      );
    }
    return null;
  }, [currentSystem.value, errorCode]);

  const renderRequiredLevelsAlert = useMemo(() => {
    const isRequiredLevelsExists = Array.isArray(integrationSchema.requiredParentLevels)
      ? integrationSchema.requiredParentLevels.length > 0
      : false;

    if (!isRequiredLevelsExists) {
      return null;
    }

    const levels = integrationSchema.requiredParentLevels.join(', ');

    return (
      <Alert title={<Message id='alert-pay-attention' />}>
        <p>
          <Message
            id='please-be-aware-that-settings-for-levels-are-configured'
            values={{
              variable: levels,
            }}
          />
        </p>
      </Alert>
    );
  }, [integrationSchema.requiredParentLevels]);

  return (
    <>
      <Head>
        <Title>
          {customer?.name} <TitleMark>id#{customer?.id}</TitleMark>
        </Title>
        <HeadActions>
          {customer.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={customer.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <CustomerTabsList customerId={Number(customerId)} />
          <TabContent>
            <TabContentInner>
              <TabHeader>
                <ButtonBack>
                  <Button transparent onClick={onCancelHandler}>
                    <ArrowLeftIcon />
                  </Button>
                </ButtonBack>
                <TabTitle>
                  <Message id={'customer-integrations-add-new-integration'} />
                </TabTitle>
              </TabHeader>
              {isFetching && <Spinner />}
              <FormColumnWrapper>
                <FormColumn>
                  <FormRow>
                    <FormTitleMark>
                      <Message id='customer-integrations-select-system' />
                    </FormTitleMark>
                    <Select
                      id='systemName'
                      isRequired
                      disabled={!isSystemNameOptionsExists}
                      options={availableSystems}
                      value={currentSystem}
                      label={''}
                      onChange={onSystemNameChangeHandler}
                    />
                  </FormRow>
                </FormColumn>
              </FormColumnWrapper>
              {renderMissingSchemaAlert}
              {renderRequiredLevelsAlert}
              {isSystemNameOptionsExists ? (
                renderForm
              ) : (
                <FormInfo>
                  <FormInfoTitle>
                    <Message id='customer-integrations-all-integrations-for-this-customer-created' />
                  </FormInfoTitle>
                </FormInfo>
              )}
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default IntegrationCreate;
