import { TransferredDataItem, TransferredDataReasonCode } from 'types/TransferHistoricalData';
import { Message } from 'common/components';
import { ErrorTitle, ErrorText, ErrorBlock } from '../styles';

interface TransferHistoricalDataErrorProps {
  transferredData: TransferredDataItem[];
}

const filterData = (data: TransferredDataItem[]) => {
  const filteredObject: any = {
    clientsWithClientNotFoundException: [],
    clientsWithDuplicateOperationRunException: [],
    clientsWithIntegrationSetupException: [],
    clientsIsNotAvailable: [],
    optionIsDisabledForSomeClients: [],
    clientsWithException: [],
  };

  data.forEach((item: TransferredDataItem) => {
    if (item?.reasonCode === TransferredDataReasonCode.CLIENT_NOT_FOUND_EXCEPTION) {
      filteredObject.clientsWithClientNotFoundException.push(item);
    }
    if (item?.reasonCode === TransferredDataReasonCode.DUPLICATE_OPERATION_RUN_EXCEPTION) {
      filteredObject.clientsWithDuplicateOperationRunException.push(item);
    }
    if (item?.reasonCode === TransferredDataReasonCode.INTEGRATION_SETUP_EXCEPTION) {
      filteredObject.clientsWithIntegrationSetupException.push(item);
    }
    if (item?.reasonCode === TransferredDataReasonCode.CLIENT_IS_NOT_AVAILABLE) {
      filteredObject.clientsIsNotAvailable.push(item);
    }
    if (item?.reasonCode === TransferredDataReasonCode.OPTION_IS_DISABLED_FOR_SOME_CLIENTS) {
      filteredObject.optionIsDisabledForSomeClients.push(item);
    }
    if (item?.reasonCode === TransferredDataReasonCode.EXCEPTION) {
      filteredObject.clientsWithException.push(item);
    }
  });

  return filteredObject;
};

const TransferHistoricalDataError = ({ transferredData }: TransferHistoricalDataErrorProps) => {
  const data = filterData(transferredData);

  const renderClientNotFoundExceptionError = () =>
    data.clientsWithClientNotFoundException.length ? (
      <ErrorBlock>
        <ErrorTitle>
          <Message id='transfer-historical-error-clients-not-found' />
        </ErrorTitle>
        {data.clientsWithClientNotFoundException.map((item: TransferredDataItem) => (
          <ErrorText key={item.clientId}>
            <span>{item.clientId}</span>{' '}
          </ErrorText>
        ))}
      </ErrorBlock>
    ) : null;

  const renderDuplicateOperationRunExceptionError = () =>
    data.clientsWithDuplicateOperationRunException.length ? (
      <ErrorBlock>
        <ErrorTitle>
          <Message id='transfer-historical-error-previous-operation-is-not-completed' />
        </ErrorTitle>
        {data.clientsWithDuplicateOperationRunException.map((item: TransferredDataItem) => (
          <ErrorText key={item.clientId}>
            <span>{item.clientId}</span>{' '}
          </ErrorText>
        ))}
      </ErrorBlock>
    ) : null;

  const renderIntegrationSetupExceptionError = () =>
    data.clientsWithIntegrationSetupException.length ? (
      <ErrorBlock>
        <ErrorTitle>
          <Message id='transfer-historical-error-clients-do-not-have-required-integration' />
        </ErrorTitle>
        {data.clientsWithIntegrationSetupException.map((item: TransferredDataItem) => (
          <ErrorText key={item.clientId}>
            <span>{item.clientId}</span>{' '}
          </ErrorText>
        ))}
      </ErrorBlock>
    ) : null;

  const renderClientsIsNotAvailable = () =>
    data.clientsIsNotAvailable.length ? (
      <ErrorBlock>
        <ErrorTitle>
          <Message id='transfer-historical-error-clients-is-not-available' />
        </ErrorTitle>
        {data.clientsIsNotAvailable.map((item: TransferredDataItem) => (
          <ErrorText key={item.clientId}>
            <span>{item.clientId}</span>{' '}
          </ErrorText>
        ))}
      </ErrorBlock>
    ) : null;

  const renderOptionIsDisabledForSomeClients = () =>
    data.optionIsDisabledForSomeClients.length ? (
      <ErrorBlock>
        <ErrorTitle>
          <Message id='transfer-historical-error-option-are-disabled-for-clients' />
        </ErrorTitle>
        {data.optionIsDisabledForSomeClients.map((item: TransferredDataItem) => (
          <ErrorText key={item.clientId}>
            <span>{item.clientId}</span>{' '}
          </ErrorText>
        ))}
      </ErrorBlock>
    ) : null;

  const renderExceptionError = () =>
    data.clientsWithException.length ? (
      <ErrorBlock>
        <ErrorTitle>
          <Message id='transfer-historical-error-clients-have-exceptions' />
        </ErrorTitle>
        {data.clientsWithException.map((item: TransferredDataItem) => (
          <p key={item.clientId}>
            <ErrorText key={item.clientId}>
              client Id: <strong>{item.clientId}</strong> ({item.reason})
            </ErrorText>
          </p>
        ))}
      </ErrorBlock>
    ) : null;

  return (
    <>
      {renderClientNotFoundExceptionError()}
      {renderDuplicateOperationRunExceptionError()}
      {renderIntegrationSetupExceptionError()}
      {renderClientsIsNotAvailable()}
      {renderOptionIsDisabledForSomeClients()}
      {renderExceptionError()}
    </>
  );
};

export default TransferHistoricalDataError;
