import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
`;

export const FiltersRight = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    margin-left: 10px;
  }
`;

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface FormColumnType {
  width?: string;
}

export const FormColumn = styled.div<FormColumnType>`
  width: ${({ width }) => (width ? width : '47%')};
  flex: 0 0 ${({ width }) => (width ? width : '47%')};
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

export const FormTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 44px;
  min-height: 44px;
  margin: 0;
  letter-spacing: -0.5;
`;

export const FormTitleMark = styled(FormTitle)`
  font-weight: 600;
`;

export const Content = styled.div`
  min-height: 300px;
`;

export const FormWrapper = styled.div`
  position: relative;
  padding: 20px;
  background: ${({ theme }) => theme.backgroundPrimary};
`;
