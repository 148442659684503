import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: inline;
`;

interface LabelTextType {
  disabled?: boolean;
}

export const LabelText = styled.span<LabelTextType>`
  font-style: normal;
  font-size: 12px;
  color: ${({ theme, disabled }) => (disabled ? theme.fontSecondary : theme.fontPrimary)};
`;

interface StyledCheckboxType {
  disabled?: boolean;
  indeterminate?: boolean;
  textPosition?: 'left' | 'right';
}

export const StyledCheckbox = styled.span<StyledCheckboxType>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  min-width: 14px;
  height: 14px;
  background: ${({ theme, disabled }) => (disabled ? theme.backgroundThird : theme.backgroundPrimary)};
  border: 1px solid ${({ theme }) => theme.form.checkboxBorder};
  box-sizing: border-box;
  border-radius: 4px;
  transition: all 0.1s;
  margin-right: 10px;
  margin-left: ${({ textPosition }) => (textPosition === 'right' ? 0 : '10px')};
  margin-top: 2px;
  color: ${({ theme, disabled }) => (disabled ? theme.fontSecondary : theme.primary)};
  opacity: ${({ disabled }) => (disabled ? '0.8' : '1')};

  & svg {
    position: absolute;
    top: -3px;
    left: 0;
  }

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 6px;
    height: 6px;
    border-radius: 2px;
    content: '';
    background: ${({ theme }) => theme.primary};
    display: ${({ indeterminate }) => (indeterminate ? 'block' : 'none')};
  }
`;

interface HiddenCheckboxType {
  disabled?: boolean;
  indeterminate?: boolean;
}

export const HiddenCheckbox = styled.input<HiddenCheckboxType>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:checked + ${StyledCheckbox} {
    background: ${({ theme, disabled }) => (disabled ? theme.backgroundPrimary : theme.backgroundPrimary)};
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.form.checkboxBorder : theme.primary)};
  }

  &:focus + ${StyledCheckbox} {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.form.checkboxBorder : theme.primary)};
    box-shadow: 0 0 3px ${({ theme }) => theme.primary};
  }
`;

interface LabelWrapperType {
  disabled?: boolean;
  textPosition?: 'left' | 'right';
}

export const LabelWrapper = styled.label<LabelWrapperType>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${({ textPosition }) => (textPosition === 'right' ? 'row' : 'row-reverse')};
  box-sizing: border-box;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover ${StyledCheckbox} {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.form.checkboxBorder : theme.primary)};
  }
`;

export const CheckboxHolder = styled.span``;
