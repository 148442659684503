import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';

const getIntegrationsDefaultSettingsBreadcrumbs = () => [
  {
    title: BREADCRUMBS_TITLES.INTEGRATIONS_DEFAULT,
    isActive: true,
  },
];

export default getIntegrationsDefaultSettingsBreadcrumbs;
