import ReactDOM from 'react-dom';
import { ReactNode } from 'react';
import { PortalIds, PortalIdsType } from 'types/Portal';

interface PortalWrpProps {
  usePortal: boolean;
  children: ReactNode;
  portalId: PortalIdsType;
}

const PortalWrp = ({ usePortal = true, portalId = PortalIds.PORTAL_ID, children }: PortalWrpProps) => {
  if (!usePortal || !portalId) {
    return children;
  }

  const portalDiv = document.getElementById(portalId);

  return portalDiv ? ReactDOM.createPortal(children, portalDiv) : null;
};

export default PortalWrp;
