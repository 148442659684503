import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getOperationsView } from '../logic/customerSelectors';
import { actionCreators } from '../logic/customerReducer';
import OperationsList from './OperationsList';

const mapStateToProps = ({ shared, customer }: RootState) => getOperationsView(shared, customer);

const mapDispatchToProps = {
  getCustomer: actionCreators.getCustomer,
  getOperations: actionCreators.getCustomerOperations,
  setOperationsTablePage: actionCreators.setOperationsTablePage,
  setOperationsTablePerPage: actionCreators.setOperationsTablePerPage,
  setOperationsTableOrder: actionCreators.setOperationsTableSortOrder,
  setFilterOperationScope: actionCreators.setOperationsFilterOperationScope,
  setFilterOperation: actionCreators.setOperationsFilterOperation,
  setFilterStatus: actionCreators.setOperationsFilterStatus,
  setFilterSystem: actionCreators.setOperationsFilterSystems,
  setFilterClientName: actionCreators.setOperationsFilterClientName,
  setFilterDateFrom: actionCreators.setFilterDateFrom,
  setFilterDateTo: actionCreators.setFilterDateTo,
  setFilterSourceEntityId: actionCreators.setFilterSourceEntityId,
  setFilterTargetEntityId: actionCreators.setFilterTargetEntityId,
  setShowOnlyDeactivatedSystems: actionCreators.setShowOnlyDeactivatedSystems,
  getClientsOptions: actionCreators.getClientsOptions,
  reset: actionCreators.customerOperationsReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(OperationsList);
