import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';

import getCustomerBreadcrumbs from '../getCustomerBreadcrumbs';

type CustomerUrlParams = {
  customerId: string;
};

const useCustomerName = () => useSelector<RootState, string>((state) => state.customer.customer.name);

const usePortalBreadcrumbsEffect = (currentPositionTitle: string) => {
  const { customerId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const customerName = useCustomerName();

  useEffect(() => {
    setBreadcrumbs(
      getCustomerBreadcrumbs(Number(customerId), customerName).concat([
        {
          title: currentPositionTitle,
          isActive: true,
        },
      ]),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, customerName, setBreadcrumbs, currentPositionTitle]);
};

export { usePortalBreadcrumbsEffect };
