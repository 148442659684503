import { State as CustomerState } from 'screens/Customer/logic/customerReducer';
import { State as TransferHistoricalDataState } from './transferHistoricalDataReducer';

export const getTransferHistoricalDataView = (
  customerState: CustomerState,
  transferHistoricalDataState: TransferHistoricalDataState,
) => ({
  customer: customerState.customer,
  isFetching: transferHistoricalDataState.isFetching,
  clients: transferHistoricalDataState.clients,
  totalCount: transferHistoricalDataState.totalCount,
  page: transferHistoricalDataState.page,
  perPage: transferHistoricalDataState.perPage,
  orderBy: transferHistoricalDataState.orderBy,
  sortOrder: transferHistoricalDataState.sortOrder,
  filterStatus: transferHistoricalDataState.filterStatus,
  filterSearch: transferHistoricalDataState.filterSearch,
  transferredData: transferHistoricalDataState.transferredData,
  isNotAllDataTransferred: transferHistoricalDataState.transferredData.some((item: any) => !item.successful),
});
