import styled from 'styled-components';
import { getPlaceholderColor, getTextareaColor, getTextareaBackground, getTextareaBorder } from './helpers';

export const TextareaContainer = styled.div``;

interface TextareaType {
  isFloating?: boolean;
  isFocused?: boolean;
  isInputEmpty?: boolean;
  isError?: boolean;
  minHeight?: number;
}

export const TextareaField = styled.textarea<TextareaType>`
  display: block;
  font-size: 13px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  border: 0;
  padding: 7px 10px;
  width: 100%;
  max-width: 100% !important;
  width: 100% !important;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '74px')};
  color: ${({ theme, disabled }) => getTextareaColor({ theme, disabled })};
  background: ${({ theme, isFloating, isError }) => getTextareaBackground({ theme, isFloating, isError })};
  border: 1px solid
    ${({ theme, isFocused, isFloating, isError }) => getTextareaBorder({ theme, isFocused, isFloating, isError })};
  border-radius: 4px;
  outline: none;
`;

// interface LabelType {
//   isPlaceholderHidden?: boolean;
// }

// export const Label = styled.label<LabelType>`
//   box-sizing: border-box;
//   font-size: 12px;
//   display: inline-block;
//   position: relative;
//   width: 100%;
//   color: ${({ theme }) => theme.fontPrimary};
//   padding-top: ${({ isPlaceholderHidden }) =>
//   isPlaceholderHidden ? 0 : '22px'};
// `;

interface LabelType {
  isError?: boolean;
  disabled?: boolean;
}

export const Label = styled.label<LabelType>`
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme, isError, disabled }) => getPlaceholderColor({ theme, isError, disabled })};
  pointer-events: none;
  transition: padding 0.2s, top 0.2s;
`;

export const TextareaWrapper = styled.div``;

interface InputErrorMessageProps {
  isError?: boolean;
  isPlaceholderHidden?: boolean;
}

export const InputErrorMessage = styled.div<InputErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;
