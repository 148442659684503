import styled from 'styled-components';

export const TableCellWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  word-break: break-all;
`;

export const TableWrapper = styled.div``;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const TableTitle = styled.h3`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

export const TableHeaderActions = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    margin-left: 10px;
  }
`;
