import { APP_URLS } from 'common/constants';
import {
  ListIcon,
  UsersIcon,
  IntegrationsDefaultIcon,
  InfoIcon,
  CheckListIcon,
  FileIcon,
  SyncIcon,
  ExchangeIcon,
} from 'common/components/Icons';

export const GENERAL_NAVIGATION_ITEMS = [
  {
    path: APP_URLS.customers,
    name: 'customersAll',
    title: 'All Customers',
    messageId: 'main-nav-all-customers',
    icon: ListIcon,
  },
  {
    path: APP_URLS.clients,
    name: 'clientsAll',
    title: 'All Clients',
    messageId: 'main-nav-all-clients',
    icon: UsersIcon,
  },
  {
    path: APP_URLS.operationStatus,
    name: 'operationsAll',
    title: 'All Operations',
    messageId: 'main-nav-all-operations',
    icon: CheckListIcon,
  },
  {
    path: APP_URLS.transferEInvoices,
    name: 'transferEInvoicesAll',
    title: 'All Transfer E-Invoices',
    messageId: 'main-nav-all-transfer-einvoices',
    icon: FileIcon,
  },
];

export const SETTINGS_NAVIGATION_ITEMS = [
  {
    path: APP_URLS.integrationsDefault,
    name: 'integrationsDefault',
    title: 'Integrations Default',
    messageId: 'main-nav-integrations-default',
    icon: IntegrationsDefaultIcon,
  },
  {
    path: APP_URLS.releaseNotes,
    name: 'releaseNotes',
    title: 'Release Notes',
    messageId: 'main-nav-release-notes',
    icon: InfoIcon,
  },
];

export const MONITORING_NAVIGATION_ITEMS = [
  {
    path: APP_URLS.monitoringInvoice,
    name: 'monitoringInvoice',
    title: 'Invoice monitoring',
    messageId: 'main-nav-monitoring-invoice',
    icon: SyncIcon,
  },
  {
    path: APP_URLS.monitoringSubscriptions,
    name: 'monitoringSubscriptions',
    title: 'Subscriptions Monitoring',
    messageId: 'main-nav-monitoring-subscriptions',
    icon: ExchangeIcon,
  },
];
