import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { OperationData, OperationsAllState, OperationsList, OperationsRequestData } from 'types/Operations';
import { FormSelectOption } from 'types/Form';
import { TableDateField, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import { defaultFilterOptions, systemsFilterOptions } from 'common/constants';
import { IntegrationsSystems } from 'types/Integrations';
import { CustomersOptionsRequestData } from 'types/Customer';
import { ClientsOptionsRequestData } from 'types/Client';

// Actions
export enum ActionTypeKeys {
  GET_OPERATIONS = 'OPERATIONS/GET_OPERATIONS',
  GET_OPERATIONS_SUCCESS = 'OPERATIONS/GET_OPERATIONS_SUCCESS',
  GET_OPERATIONS_FAILED = 'OPERATIONS/GET_OPERATIONS_FAILED',
  SET_OPERATIONS_TABLE_PAGE = 'OPERATIONS/SET_OPERATIONS_TABLE_PAGE',
  SET_OPERATIONS_TABLE_PER_PAGE = 'OPERATIONS/SET_OPERATIONS_TABLE_PER_PAGE',
  SET_OPERATIONS_TABLE_SORT_ORDER = 'OPERATIONS/SET_OPERATIONS_TABLE_SORT_ORDER',
  SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE',
  SET_OPERATIONS_TABLE_FILTER_OPERATION = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_OPERATION',
  SET_OPERATIONS_TABLE_FILTER_STATUS = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_STATUS',
  SET_OPERATIONS_TABLE_FILTER_SYSTEM = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_SYSTEM',
  SET_OPERATIONS_TABLE_FILTER_CUSTOMER_NAME = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_CUSTOMER_NAME',
  SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME',
  SET_OPERATIONS_TABLE_FILTER_DATE_FROM = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_DATE_FROM',
  SET_OPERATIONS_TABLE_FILTER_DATE_TO = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_DATE_TO',
  SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID',
  SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID = 'OPERATIONS/SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID',
  SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS = 'OPERATIONS/SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS',
  GET_CUSTOMERS_OPTIONS = 'OPERATIONS/GET_CUSTOMERS_OPTIONS',
  GET_CUSTOMERS_OPTIONS_SUCCESS = 'OPERATIONS/GET_CUSTOMERS_OPTIONS_SUCCESS',
  GET_CUSTOMERS_OPTIONS_FAILED = 'OPERATIONS/GET_CUSTOMERS_OPTIONS_FAILED',
  GET_CLIENTS_OPTIONS = 'OPERATIONS/GET_CLIENTS_OPTIONS',
  GET_CLIENTS_OPTIONS_SUCCESS = 'OPERATIONS/GET_CLIENTS_OPTIONS_SUCCESS',
  GET_CLIENTS_OPTIONS_FAILED = 'OPERATIONS/GET_CLIENTS_OPTIONS_FAILED',
  OPERATIONS_RESET = 'OPERATIONS/OPERATIONS_RESET',
}

// ActionCreators
export const actionCreators = {
  getOperations: (payload: OperationsRequestData) => createAction(ActionTypeKeys.GET_OPERATIONS, payload),
  getOperationsSuccess: (payload: OperationsList) => createAction(ActionTypeKeys.GET_OPERATIONS_SUCCESS, payload),
  getOperationsFailed: () => createAction(ActionTypeKeys.GET_OPERATIONS_FAILED),

  setOperationsTablePage: (payload: number) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_PAGE, payload),
  setOperationsTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_PER_PAGE, payload),
  setOperationsTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_SORT_ORDER, payload),
  setOperationsFilterOperationScope: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE, payload),
  setOperationsFilterOperation: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION, payload),
  setOperationsFilterStatus: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_STATUS, payload),
  setOperationsFilterSystems: (payload: IntegrationsSystems[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SYSTEM, payload),
  setOperationsFilterCustomerName: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_CUSTOMER_NAME, payload),
  setOperationsFilterClientName: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME, payload),
  setFilterDateFrom: (payload: null | Date) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_FROM, payload),
  setFilterDateTo: (payload: null | Date) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_TO, payload),
  setFilterSourceEntityId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID, payload),
  setFilterTargetEntityId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID, payload),
  setShowOnlyDeactivatedSystems: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS, payload),

  getCustomersOptions: (payload: CustomersOptionsRequestData) =>
    createAction(ActionTypeKeys.GET_CUSTOMERS_OPTIONS, payload),
  getCustomersOptionsSuccess: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.GET_CUSTOMERS_OPTIONS_SUCCESS, payload),
  getCustomersOptionsFailed: () => createAction(ActionTypeKeys.GET_CUSTOMERS_OPTIONS_FAILED),

  getClientsOptions: (payload: ClientsOptionsRequestData) => createAction(ActionTypeKeys.GET_CLIENTS_OPTIONS, payload),
  getClientsOptionsSuccess: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.GET_CLIENTS_OPTIONS_SUCCESS, payload),
  getClientsOptionsFailed: () => createAction(ActionTypeKeys.GET_CLIENTS_OPTIONS_FAILED),

  operationsReset: () => createAction(ActionTypeKeys.OPERATIONS_RESET),
};

// State
// eslint-disable-next-line
export interface State extends OperationsAllState {}

export const initialState: State = {
  isFetching: false,
  data: [] as OperationData[],
  totalCount: 0,
  page: 1,
  perPage: ITEMS_PER_PAGE_COUNT,
  orderBy: TableDateField.STARTED_DATE_TIME,
  sortOrder: TableSortOrder.DESCENDING,
  filterScope: [defaultFilterOptions],
  filterType: [defaultFilterOptions],
  filterStatus: [defaultFilterOptions],
  filterSystem: [systemsFilterOptions[0]],
  filterCustomerName: [defaultFilterOptions],
  filterClientName: [defaultFilterOptions],
  filterDateFrom: null,
  filterDateTo: null,
  filterSourceEntityId: '',
  filterTargetEntityId: '',
  showOnlyDeactivatedSystems: false,
  customersOptions: [] as FormSelectOption[],
  clientsOptions: [] as FormSelectOption[],
};

export const operationsAllReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_OPERATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_OPERATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case ActionTypeKeys.GET_OPERATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_SORT_ORDER:
      return {
        ...state,
        orderBy: action.payload.orderBy,
        sortOrder: action.payload.sortOrder,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE:
      return {
        ...state,
        filterScope: [...action.payload],
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION:
      return {
        ...state,
        filterType: [...action.payload],
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_STATUS:
      return {
        ...state,
        filterStatus: [...action.payload],
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SYSTEM:
      return {
        ...state,
        filterSystem: [...action.payload],
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_CUSTOMER_NAME:
      return {
        ...state,
        filterCustomerName: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME:
      return {
        ...state,
        filterClientName: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_FROM:
      return {
        ...state,
        filterDateFrom: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_TO:
      return {
        ...state,
        filterDateTo: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID:
      return {
        ...state,
        filterSourceEntityId: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID:
      return {
        ...state,
        filterTargetEntityId: action.payload,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS:
      return {
        ...state,
        showOnlyDeactivatedSystems: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMERS_OPTIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMERS_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customersOptions: [...action.payload],
      };

    case ActionTypeKeys.GET_CUSTOMERS_OPTIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CLIENTS_OPTIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENTS_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clientsOptions: [...action.payload],
      };

    case ActionTypeKeys.GET_CLIENTS_OPTIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.OPERATIONS_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
