import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/customersReducer';
import Customers from './Customers';
import { getCustomersView } from './logic/customersSelectors';

const mapStateToProps = ({ shared, customers }: RootState) => getCustomersView(shared, customers);

const mapDispatchToProps = {
  getCustomers: actionCreators.getCustomers,
  setTablePage: actionCreators.setCustomersTablePage,
  setTablePerPage: actionCreators.setCustomersTablePerPage,
  setTableOrder: actionCreators.setCustomersTableSortOrder,
  setFilterType: actionCreators.setCustomersTableFilterType,
  setFilterStatus: actionCreators.setCustomersTableFilterStatus,
  setFilterSystem: actionCreators.setCustomersTableFilterSystem,
  setFilterSearch: actionCreators.setCustomersTableFilterSearch,
  setIsIncludeDeactivated: actionCreators.setIsIncludeDeactivated,
  setShowOnlyDeactivatedSystems: actionCreators.setShowOnlyDeactivatedSystems,
  reset: actionCreators.customersReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(Customers);
