import styled from 'styled-components';

export const PropellApFormButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > button {
    width: 20rem;
  }
`;

export const PropellApAlerts = styled.div`
  margin-bottom: 2.5rem;
`;
