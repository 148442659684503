import styled from 'styled-components';

export const Content = styled.div`
  text-align: left;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
`;

export const List = styled.ul`
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 0 0 0 20px;
  margin-bottom: 5px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.fontSecondary};
    border-radius: 50%;
    left: 5px;
    top: 6px;
  }
`;

export const Note = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.fontSecondary};

  p {
    margin: 0 0 5px;
  }
`;

export const NoteMark = styled.span`
  color: ${({ theme }) => theme.fontError};
`;

export const UploadWrapper = styled.div`
  padding: 10px 0;
`;
