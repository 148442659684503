import { useEffect } from 'react';

export const useUpdateSelection = () => {
  useEffect(() => {
    const trimSelection = (e: ClipboardEvent) => {
      // @ts-ignore
      const text = window.getSelection().toString().trim();
      if (e.clipboardData) {
        e.clipboardData.setData('text/plain', text);
      }
      e.preventDefault();
    };
    // @ts-ignore
    window.addEventListener('copy', trimSelection);
    // @ts-ignore
    return () => window.removeEventListener('copy', trimSelection);
  }, []);
};
