import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const TableWrapper = styled.div``;

export const ActionIcon = styled.span`
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
`;
