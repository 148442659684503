import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/customerCreateReducer';
import CustomerCreate from './CustomerCreate';
import { getCustomerCreateView } from './logic/customerCreateSelectors';

const mapStateToProps = ({ shared, customerCreate }: RootState) => getCustomerCreateView(shared, customerCreate);

const mapDispatchToProps = {
  createCustomer: actionCreators.createCustomer,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(CustomerCreate);
