import styled from 'styled-components';

export const PanelGroupContainer = styled.div``;

export const PanelContainer = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  margin-top: 20px;
  padding: 15px;

  &:first-child {
    margin: 0;
  }
`;

interface PanelHeaderType {
  isActive?: boolean;
}

export const PanelHeader = styled.div<PanelHeaderType>`
  position: relative;
  padding: ${({ isActive }) => (isActive ? '12px 40px 12px 20px' : '12px 40px 12px 20px')};
  margin: 0 -15px;
  border-bottom: 1px solid ${({ theme, isActive }) => (isActive ? theme.borders.grey : 'transparent')};

  &:hover {
    cursor: pointer;
  }
`;

export const PanelHeaderArrow = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
`;

export const PanelTitle = styled.h3`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

interface PanelContentWrapperType {
  isActive?: boolean;
}

export const PanelContentWrapper = styled.div<PanelContentWrapperType>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const PanelContentBox = styled.div`
  padding-top: 20px;
`;
