import { ReactElement } from 'react';
import { getFormattedLongDate } from 'utils';
import { operationScopeTypeNamesMapping, OperationData } from 'types/Operations';
import { TableDateField } from 'types/Tables';
import { Message, CopyToClipboard } from 'common/components';
import { TableAlignment } from 'common/components/DataTable/types';
import { ItemsCount, StatusLabel, Link, LinkWrapper, TableCellContent, TableCellItem, DateWrap } from './styles';

interface ClientOperationsDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: OperationData;
}

export const clientOperationsTableColumns = [
  {
    name: 'traceId',
    title: <Message id='operations-table-trace-id' />,
    width: 120,
    cellCallback: ({ row: { logsUrl, traceId } }: ClientOperationsDataTable) => (
      <LinkWrapper>
        <Link title={traceId} href={logsUrl ? logsUrl : ''} target='_blank' rel='noopener noreferrer'>
          {traceId}
        </Link>
        <CopyToClipboard copyText={traceId} />
      </LinkWrapper>
    ),
  },
  {
    name: 'scope',
    title: <Message id='operations-table-operation-scope' />,
    width: 250,
    sortable: true,
    cellCallback: ({ row: { scope } }: ClientOperationsDataTable) => (
      <span title={scope}>{operationScopeTypeNamesMapping[scope]}</span>
    ),
  },
  {
    name: 'type',
    title: <Message id='operations-table-operation' />,
    width: 200,
    sortable: true,
    cellCallback: ({ row: { type } }: ClientOperationsDataTable) => <span title={type}>{type}</span>,
  },
  {
    name: 'status',
    title: <Message id='operations-table-status' />,
    width: 180,
    sortable: true,
    cellCallback: ({ row: { status } }: ClientOperationsDataTable) => {
      const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);
      return (
        <>
          <StatusLabel status={status}>{statusFormatted}</StatusLabel>
        </>
      );
    },
  },
  {
    name: 'entityId',
    title: <Message id='operations-table-entity-id' />,
    width: 220,
    cellCallback: ({ row: { sourceEntityId, targetEntityId } }: ClientOperationsDataTable) => (
      <TableCellContent>
        <TableCellItem>
          <strong>
            <Message id='operations-table-source' />{' '}
          </strong>
          {sourceEntityId ? sourceEntityId : '-'}
        </TableCellItem>
        <TableCellItem>
          <strong>
            <Message id='operations-table-target' />{' '}
          </strong>
          {targetEntityId ? targetEntityId : '-'}
        </TableCellItem>
      </TableCellContent>
    ),
  },
  {
    name: 'syncedItemsCount',
    title: <Message id='operations-table-synced-items' />,
    width: 100,
    align: TableAlignment.CENTER,
    cellCallback: ({ row: { successfulItemsCount } }: ClientOperationsDataTable) => (
      <ItemsCount>{successfulItemsCount ? successfulItemsCount : '-'}</ItemsCount>
    ),
  },
  {
    name: 'failedItemsCount',
    title: <Message id='operations-table-failed-items' />,
    width: 100,
    align: TableAlignment.CENTER,
    cellCallback: ({ row: { failedItemsCount } }: ClientOperationsDataTable) => (
      <ItemsCount>{failedItemsCount ? failedItemsCount : '-'}</ItemsCount>
    ),
  },
  {
    name: TableDateField.STARTED_DATE_TIME,
    title: <Message id='operations-table-started-date' />,
    width: 190,
    sortable: true,
    cellCallback: ({ row: { startedDate } }: ClientOperationsDataTable) => (
      <DateWrap>{getFormattedLongDate(startedDate)}</DateWrap>
    ),
  },
  {
    name: TableDateField.FINISHED_DATE_TIME,
    title: <Message id='operations-table-finished-date' />,
    width: 190,
    sortable: true,
    cellCallback: ({ row: { finishedDate } }: ClientOperationsDataTable) => (
      <DateWrap>{getFormattedLongDate(finishedDate)}</DateWrap>
    ),
  },
  {
    name: 'logsUrl',
    title: <Message id='operations-table-logs-url' />,
    width: 100,
    cellCallback: ({ row: { logsUrl } }: ClientOperationsDataTable) => (
      <a href={logsUrl ? logsUrl : ''} target='_blank' rel='noopener noreferrer'>
        Details
      </a>
    ),
  },
];
