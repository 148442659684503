import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TableWrapper = styled.div``;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const TableTitle = styled.h3`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

export const TableHeaderActions = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    margin-left: 10px;
  }
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

interface StatusLabelType {
  isActive: boolean;
}

export const StatusLabel = styled.span<StatusLabelType>`
  font-weight: 600;
  color: ${({ theme, isActive }) => (isActive ? theme.fontSuccess : theme.fontError)};
`;

export const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-left: 8px;
  min-height: 30px;
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.fontPrimary};

  &:hover {
    color: ${({ theme }) => theme.linkColor};
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.linkColor};
`;

export const IconCircle = styled.div`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.secondary};
  border: 2px solid ${({ theme }) => theme.secondary};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const TableButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
