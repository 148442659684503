import styled from 'styled-components';

export const StatusSelectorWrapper = styled.div`
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
`;

export const StatusLabel = styled.span`
  padding-right: 10px;
`;

export const IconCircle = styled.div`
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.fontError};
  border: 2px solid ${({ theme }) => theme.fontError};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

export const WarningTitle = styled.div`
  padding-right: 40px;
  color: ${({ theme }) => theme.fontError};
  display: inline-flex;
  align-items: center;
`;

export const ReasonBlock = styled.div`
  text-align: left;
  padding: 0 40px;
`;

export const ModalText = styled.h3`
  margin: 0 0 10px;
`;

export const FormButtonsCentered = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    min-width: 100px;
    margin: 0 10px;
  }
`;
