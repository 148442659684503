import { all, call } from 'redux-saga/effects';
import { intlSaga } from 'common/sagas/intlSaga';
import { sidebarSaga } from 'components/Sidebar/logic/sidebarSaga';
import { loginSaga } from 'screens/Login/logic/loginSaga';
import { customersSaga } from 'screens/Customers/logic/customersSaga';
import { customerCreateSaga } from 'screens/CustomerCreate/logic/customerCreateSaga';
import { customerSaga } from 'screens/Customer/logic/customerSaga';
import { clientsAllSaga } from 'screens/ClientsAll/logic/clientsAllSaga';
import { operationsAllSaga } from 'screens/OperationsAll/logic/operationsAllSaga';
import { clientsSaga } from 'screens/CustomerClients/logic/clientsSaga';
import { clientCreateSaga } from 'screens/ClientCreate/logic/clientCreateSaga';
import { clientSaga } from 'screens/Client/logic/clientSaga';
import { transferHistoricalDataSaga } from 'screens/TransferHistoricalData/logic/transferHistoricalDataSaga';
import { syncMasterDataSaga } from 'screens/SyncMasterData/logic/syncMasterDataSaga';
import { integrationsDefaultSettingsSaga } from 'screens/IntegrationsDefaultSettings/logic/integrationsDefaultSettingsSaga';
import { sharedSaga } from '../common/sagas/sharedSaga';

export default function* rootSaga() {
  yield all([
    call(loginSaga),
    call(intlSaga),
    call(sharedSaga),
    call(sidebarSaga),
    call(customerCreateSaga),
    call(customerSaga),
    call(customersSaga),
    call(clientsAllSaga),
    call(clientsSaga),
    call(clientCreateSaga),
    call(clientSaga),
    call(transferHistoricalDataSaga),
    call(syncMasterDataSaga),
    call(integrationsDefaultSettingsSaga),
    call(operationsAllSaga),
  ]);
}
