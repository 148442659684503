import { useState, useEffect, useCallback, ReactElement } from 'react';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { useRemoteApiCall } from 'hooks/useRemoteApiCall';
import { UserModel } from 'common/api/generated/PAIAdminApiClient';
import { DataTable, Message, Spinner } from 'common/components';
import { ColumnItem, GetRowKey, TableAlignment } from 'common/components/DataTable/types';
import { RetryIcon, TrashIcon } from 'common/components/Icons';
import { ActionIcon, TableWrapper, Wrapper } from './styles';

interface PortalUsersGridRecord {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: UserModel;
}

const getColumns = (settings: {
  onDelete: (input: PortalUsersGridRecord) => void;
  onReInvite: (input: PortalUsersGridRecord) => void;
}): ColumnItem<PortalUsersGridRecord>[] => {
  return [
    {
      name: 'userName',
      title: <Message id='customer-portal-users-grid-column-user-name' />,
      width: '20%',
      cellCallback: (record) => record.row.name,
    },
    {
      name: 'email',
      title: <Message id='customer-portal-users-grid-column-email' />,
      width: '30%',
    },
    {
      name: 'authServerUserId',
      title: <Message id='customer-portal-users-grid-column-authServerUserId' />,
      width: '40%',
    },
    {
      name: 'action',
      title: <Message id='customer-portal-users-grid-column-action' />,
      width: '10%',
      align: TableAlignment.CENTER,
      cellCallback: (record: PortalUsersGridRecord) => {
        return (
          <>
            {!record?.row.authServerUserId && (
              <ActionIcon title='Re-invite' onClick={() => settings.onReInvite(record)}>
                <RetryIcon width={13} />
              </ActionIcon>
            )}
            <ActionIcon title='Delete' onClick={() => settings.onDelete(record)}>
              <TrashIcon width={13} />
            </ActionIcon>
          </>
        );
      },
    },
  ];
};

const gridRowKeyBuilder: GetRowKey = (_: PortalUsersGridRecord, index?: number) => (index ?? 0).toString();

const PortalUsersGrid = ({ customerId, portalId }: { customerId: string; portalId: string }) => {
  const paiClient = useAuthenticatedAdminApiClient();
  const [isFetching, callRemoteApi] = useRemoteApiCall();
  const [users, setUsers] = useState<UserModel[] | undefined>(undefined);
  const [reloadUsersKey, setReloadUsersKey] = useState(0);

  useEffect(() => {
    (async () => {
      if (!paiClient) {
        return;
      }
      const result = await callRemoteApi(() =>
        paiClient?.customerPortalControllerCustomerPortalGetAdminUsers(portalId),
      );
      setUsers(result?.sort((a, b) => ((a.name ?? '') < (b.name ?? '') ? -1 : 1))); // TODO - apply sorting on grid
    })();
  }, [reloadUsersKey, paiClient, callRemoteApi, portalId]);

  const onDeleteHandler = useCallback(
    async (userId?: string) => {
      userId &&
        (await callRemoteApi(() => paiClient?.customerPortalControllerCustomerPortalDeleteUser(portalId, userId)));
      setReloadUsersKey(reloadUsersKey + 1);
    },
    [paiClient, reloadUsersKey, callRemoteApi, portalId],
  );

  const onReinviteHandler = useCallback(
    async (userId?: string) => {
      userId &&
        (await callRemoteApi(() =>
          paiClient?.customerPortalControllerCustomerPortalInviteUser(Number(customerId), portalId, userId),
        ));
    },
    [paiClient, callRemoteApi, customerId, portalId],
  );

  return (
    <>
      {isFetching && <Spinner />}
      <Wrapper>
        <TableWrapper>
          <DataTable
            isFetching={isFetching}
            data={users ?? []}
            loadingComponent={Spinner}
            columns={getColumns({
              onReInvite: (r) => onReinviteHandler(r.row.id),
              onDelete: (r) => onDeleteHandler(r.row.id),
            })}
            emptyMessage={<Message id='no-data-message' />}
            rowKey={gridRowKeyBuilder}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default PortalUsersGrid;
