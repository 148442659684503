import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Message, Select, Input, Spinner, DataTable, Paginator, Button } from 'common/components';
import { useSearchInputFocus, useAccessToken } from 'hooks';
import { TablePerPage, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { CustomerByIdRequestData, CustomerData } from 'types/Customer';
import {
  SyncClientData,
  SyncClientsRequestData,
  ExecuteClientsRequestData,
  SyncedDataItem,
} from 'types/SyncMasterData';
import { SelectOptionSyncMasterDataStatus } from 'types/Form';
import { NotificationType } from 'types/Notifications';
import { syncMasterDataStatusFilterOptions } from 'common/constants';
import { RefreshIcon } from 'common/components/Icons';
import { getArrayOfOptionsValues, getSelectedMultiOptions } from 'utils';
import syncMasterDataTableColumns from './syncMasterDataTableColumns';
import SyncMasterDataModal from './components/SyncMasterDataModal';
import SyncMasterDataError from './components/SyncMasterDataError';
import getSyncMasterDataBreadcrumbs from './getSyncMasterDataBreadcrumbs';
import { Head, Title, Wrapper, FiltersWrapper, FiltersColumn, FiltersLeft, FiltersRight } from './styles';

type CustomerUrlParams = {
  customerId: string;
};

interface SyncMasterDataProps {
  isFetching: boolean;
  isNotAllDataSynced: boolean;
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterStatus: SelectOptionSyncMasterDataStatus[];
  filterSearch: string;
  customer: CustomerData;
  clients: SyncClientData[];
  syncedData: SyncedDataItem[];
  setTablePage: (payload: number) => void;
  setTablePerPage: (payload: number) => void;
  setFilterStatus: (payload: SelectOptionSyncMasterDataStatus[]) => void;
  setTableOrder: (payload: TablesSortOrderPayload) => void;
  setFilterSearch: (payload: string) => void;
  getCustomer: (payload: CustomerByIdRequestData) => void;
  getClientsForSyncMasterData: (payload: SyncClientsRequestData) => void;
  executeSyncMasterData: (payload: ExecuteClientsRequestData) => void;
  resetSyncMasterData: () => void;
  resetFilters: () => void;
  addNotification: (payload: any) => void;
}

const SyncMasterData = ({
  isFetching,
  isNotAllDataSynced,
  filterStatus,
  filterSearch,
  page,
  perPage,
  orderBy,
  sortOrder,
  clients,
  syncedData,
  totalCount,
  customer,
  setTablePage,
  setTablePerPage,
  setFilterStatus,
  setTableOrder,
  setFilterSearch,
  getCustomer,
  getClientsForSyncMasterData,
  resetSyncMasterData,
  resetFilters,
  executeSyncMasterData,
  addNotification,
}: SyncMasterDataProps) => {
  const accessToken = useAccessToken();
  const { customerId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [isFiltersDisabled, setFiltersDisabled] = useState(true);
  const [setIsSearchInputChanged, inputRef, setSearchInputFocus] = useSearchInputFocus();
  const requestData = {
    accessToken,
    customerId: Number(customerId),
    page: 1,
    size: Number(perPage),
    orderBy: orderBy,
    sortOrder: sortOrder,
    search: filterSearch,
    statuses:
      filterStatus[0].value === syncMasterDataStatusFilterOptions[0].value
        ? undefined
        : getArrayOfOptionsValues(filterStatus),
    applyStatusFilter: filterStatus[0].value === syncMasterDataStatusFilterOptions[0].value ? undefined : true,
  };

  useEffect(() => {
    setSearchInputFocus();
  }, [isFetching, setSearchInputFocus]);

  useEffect(() => {
    if (accessToken) {
      getClientsForSyncMasterData({
        accessToken,
        customerId: Number(customerId),
        page: page,
        size: perPage,
        orderBy: orderBy,
        sortOrder: sortOrder,
      });
    }

    return () => resetSyncMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, customerId, getClientsForSyncMasterData, resetSyncMasterData]);

  useEffect(() => {
    if (!customer.name && accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, getCustomer, customer.name]);

  useEffect(() => {
    setBreadcrumbs(getSyncMasterDataBreadcrumbs(Number(customerId), customer.name));
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs]);

  useEffect(() => {
    setFiltersDisabled(
      isFetching || (!totalCount && filterStatus[0].value === syncMasterDataStatusFilterOptions[0].value),
    );
  }, [isFetching, totalCount, filterStatus]);

  useEffect(() => {
    if (isNotAllDataSynced) {
      addNotification({
        type: NotificationType.ERROR,
        autoDeleteEnabled: false,
        messageId: 'sync-master-data-error-some-data-is-not-synced',
        errorComponent: <SyncMasterDataError syncedData={syncedData} />,
      });
    }
  }, [addNotification, isNotAllDataSynced, syncedData]);

  const handleOnItemsPerPageChange = (perPage: TablePerPage) => {
    setTablePage(1);
    setTablePerPage(Number(perPage.value));
    setIsSearchInputChanged(false);
    getClientsForSyncMasterData({
      ...requestData,
      size: Number(perPage.value),
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleOnPageChange = (page: { selected: number }) => {
    setTablePage(page.selected);
    setIsSearchInputChanged(false);
    getClientsForSyncMasterData({
      ...requestData,
      page: page.selected,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableFilterStatusChange = (event: SelectOptionSyncMasterDataStatus[]) => {
    setTablePage(1);
    setIsSearchInputChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, syncMasterDataStatusFilterOptions, setFilterStatus);
    getClientsForSyncMasterData({
      ...requestData,
      statuses:
        selectedOptions[0].value === syncMasterDataStatusFilterOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
      applyStatusFilter: selectedOptions[0].value === syncMasterDataStatusFilterOptions[0].value ? undefined : true,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableSortChange = (name: string, direction: TableSortOrder) => {
    setTableOrder({ orderBy: name, sortOrder: direction });
    setTablePage(1);
    setIsSearchInputChanged(false);
    getClientsForSyncMasterData({
      ...requestData,
      orderBy: name,
      sortOrder: direction,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getClientsForSyncMasterData({
      ...requestData,
      search: value ? value : undefined,
    });
  }, 500);

  const handleSearch = (value: string) => {
    if (value !== filterSearch) {
      setIsSearchInputChanged(true);
    }
    setFilterSearch(value);

    if (value.trim().length !== 0 || filterSearch.trim().length) {
      debouncedSearch(value);
      setTablePage(1);
    }
  };

  const onRefreshButtonClick = () => {
    setIsSearchInputChanged(false);
    getClientsForSyncMasterData({
      ...requestData,
      page: page,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const onTableChange = (selectedKeys: any, selectedRows: any) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedClients(selectedRows);
  };

  const onSyncSelectedHandler = useCallback(() => {
    setWarningModalVisible(true);
  }, []);

  const onSyncModalCancelHandler = useCallback(() => {
    setWarningModalVisible(false);
    setSelectedClients([]);
    setSelectedRowKeys([]);
  }, []);

  const onSyncModalConfirmHandler = useCallback(() => {
    executeSyncMasterData({
      accessToken,
      customerId: Number(customerId),
      clientIds: selectedClients.map((client: any) => Number(client.clientId)),
    });
    setWarningModalVisible(false);
    setSelectedClients([]);
    setSelectedRowKeys([]);
    resetFilters();
  }, [accessToken, customerId, resetFilters, selectedClients, executeSyncMasterData]);

  const onSyncButtonClickHandler = useCallback(
    (clientId: number) => {
      executeSyncMasterData({
        accessToken,
        customerId: Number(customerId),
        clientIds: [clientId],
      });
      setSelectedClients([]);
      setSelectedRowKeys([]);
      resetFilters();
    },
    [accessToken, customerId, resetFilters, executeSyncMasterData],
  );

  return (
    <>
      <Head>
        <Title>
          <Message id='customer-sync-master-data' />
        </Title>
      </Head>
      <Wrapper>
        <FiltersWrapper>
          <FiltersLeft>
            <FiltersColumn>
              <Select
                isMulti
                isClearable={false}
                value={filterStatus}
                options={syncMasterDataStatusFilterOptions}
                label={<Message id='customer-sync-master-data-filter-status' />}
                onChange={handleTableFilterStatusChange}
                disabled={isFiltersDisabled}
              />
            </FiltersColumn>
            <FiltersColumn>
              <Input
                inputRef={inputRef}
                disabled={isFetching}
                id='filterSearch'
                type='text'
                label={<Message id='customer-sync-master-data-search' />}
                value={filterSearch}
                onChange={handleSearch}
                placeholder='Client Name, Org. Number'
              />
            </FiltersColumn>
          </FiltersLeft>
          <FiltersRight>
            <Button primary disabled={!selectedClients.length} onClick={onSyncSelectedHandler}>
              <Message id='customer-sync-master-data-selected-button' />
            </Button>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </FiltersRight>
        </FiltersWrapper>
        <DataTable
          isFetching={isFetching}
          data={clients}
          columns={syncMasterDataTableColumns(Number(customerId), onSyncButtonClickHandler)}
          loadingComponent={Spinner}
          orderBy={orderBy}
          sortOrder={sortOrder}
          onSortChange={handleTableSortChange}
          rowSelection={{
            selectedRowKeys,
            onChange: onTableChange,
            getCheckboxProps: (record: any) => ({
              disabled: !record.syncable,
            }),
          }}
          emptyMessage={<Message id='no-data-message' />}
        />
        <Paginator
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          onItemsPerPageChange={handleOnItemsPerPageChange}
          onPageChange={handleOnPageChange}
        />
        <SyncMasterDataModal
          isVisible={isWarningModalVisible}
          selectedClients={selectedClients}
          onModalCancelHandler={onSyncModalCancelHandler}
          onModalConfirmHandler={onSyncModalConfirmHandler}
        />
      </Wrapper>
    </>
  );
};

export default SyncMasterData;
