import { useMemo } from 'react';
import { Alert, Message, Select, Spinner, UploadOneFileDropzone } from 'common/components';
import {
  IntegrationData,
  IntegrationSchema,
  IntegrationsSystems,
  integrationsSystemsNamesMapping,
} from 'types/Integrations';
import { ErrorCodes, importModeOptionsIntegrations } from 'common/constants';
import { isCustomerSystemExists } from 'utils/customerUtils';
import { mapSystemName } from 'utils';
import { FormRow, List, ListItem, ListWrapper, Note, NoteMark, Title, UploadWrapper } from './styles';

interface ImportIntegrationsFormProps {
  isFetching: boolean;
  errorCode: null | number;
  csvHeaders: any;
  system: IntegrationsSystems;
  customerIntegrations: IntegrationData[];
  integrationSchema: IntegrationSchema;
  onFileUpload: (payload: File) => void;
  onImportModeChange: (payload: any) => void;
}

const ImportIntegrationsForm = ({
  isFetching,
  errorCode,
  system,
  csvHeaders,
  customerIntegrations,
  integrationSchema,
  onFileUpload,
  onImportModeChange,
}: ImportIntegrationsFormProps) => {
  const isRequiredFieldsExists = csvHeaders.some((item: any) => item.isRequired);
  const isNonRequiredFieldsExists = csvHeaders.some((item: any) => !item.isRequired);
  const isSchemaNotFound = errorCode === ErrorCodes.NOT_FOUND_SCHEMA;

  const renderMissingSchemaAlert = useMemo(() => {
    if (isSchemaNotFound) {
      return (
        <Alert
          title={
            <Message
              id='schema-for-client-not-found-title'
              values={{
                variable: mapSystemName(system),
              }}
            />
          }
        >
          <>
            <p>
              <Message
                id='please-create-schema-before-create-integration'
                values={{
                  variable: mapSystemName(system),
                }}
              />
            </p>
          </>
        </Alert>
      );
    }
    return null;
  }, [system, isSchemaNotFound]);

  return (
    <>
      <FormRow key={`${system}-select`}>
        <Select
          isRequired
          id='importMode'
          options={importModeOptionsIntegrations}
          defaultValue={importModeOptionsIntegrations[0]}
          label={<Message id='client-screen-import-select-import-action-label' />}
          onChange={onImportModeChange}
          isError={false}
          errorText={''}
          disabled={isSchemaNotFound}
        />
      </FormRow>
      {isSchemaNotFound ? (
        renderMissingSchemaAlert
      ) : (
        <>
          {!isCustomerSystemExists(customerIntegrations, system, integrationSchema) && (
            <Alert
              title={
                <Message
                  id='client-integrations-missing-customer-integration'
                  values={{
                    variable: system,
                  }}
                />
              }
            >
              <>
                <p>
                  <Message
                    id='client-integrations-you-need-integration-setup-on-customer-level'
                    values={{
                      variable: system,
                    }}
                  />
                </p>
                <p>
                  <Message id='client-integrations-client-level-integration-valid-but-not-work' />
                </p>
                <p>
                  <Message
                    id='client-integrations-set-up-integration-on-customer-level'
                    values={{
                      variable: system,
                    }}
                  />
                </p>
              </>
            </Alert>
          )}
          <Title>Import {integrationsSystemsNamesMapping[system]} Credentials</Title>
          <ListWrapper>
            {isFetching && <Spinner />}
            {isRequiredFieldsExists && (
              <>
                <p>
                  <Message id='clients-screen-import-csv-file-must-contain-data-with-following-headers' />
                </p>
                <List>
                  {csvHeaders.map((header: any) =>
                    header.isRequired ? (
                      <ListItem key={header.name} isRequired={header.isRequired}>
                        {header.name}
                        {header.isRequired ? '*' : ''}
                      </ListItem>
                    ) : null,
                  )}
                </List>
              </>
            )}
            {isNonRequiredFieldsExists && (
              <>
                <p>
                  <Message id='clients-screen-import-csv-file-may-contain-data-with-following-headers' />
                </p>
                <Alert title={<Message id='client-integrations-master-data-settings' />}>
                  <>
                    <p>
                      <Message id='client-integrations-master-data-settings-should-be-set-as-boolean-value' />
                    </p>
                    <p>
                      <Message id='client-integrations-master-these-options-used-from-customer-level-by-default' />
                    </p>
                  </>
                </Alert>
                <List>
                  {csvHeaders.map((header: any) =>
                    !header.isRequired ? (
                      <ListItem key={header.name} isRequired={header.isRequired}>
                        {header.name}
                        {header.isRequired ? '*' : ''}
                      </ListItem>
                    ) : null,
                  )}
                </List>
              </>
            )}
          </ListWrapper>
          <Note>
            <p>
              <Message id='clients-screen-import-any-other-columns-will-be-ignored' /> ( <NoteMark>,</NoteMark> ),{' '}
              <Message id='clients-screen-import-so-save-from-spreadsheet-as-csv' />
            </p>
            <p>
              <Message id='clients-screen-import-such-programs-automatically-wrap-any-fields-containing-commas' />
            </p>
          </Note>
          <UploadWrapper key={`${system}-upload`}>
            <UploadOneFileDropzone onUpload={onFileUpload} />
          </UploadWrapper>
        </>
      )}
    </>
  );
};

export default ImportIntegrationsForm;
