import { Icon } from 'types/Icon';

export const CloseIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '14'} height={height || '14'} viewBox='0 0 14 14'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M12.97025,1.70725 C13.36125,1.31725 13.36125,0.68325 12.97025,0.29325 C12.58025,-0.09775 11.94725,-0.09775 11.55625,0.29325 L6.63225,5.21725 L1.70725,0.29325 C1.31725,-0.09775 0.68325,-0.09775 0.29325,0.29325 C-0.09775,0.68325 -0.09775,1.31725 0.29325,1.70725 L5.21725,6.63225 L0.29325,11.55625 C-0.09775,11.94725 -0.09775,12.58025 0.29325,12.97025 C0.68325,13.36125 1.31725,13.36125 1.70725,12.97025 L6.63225,8.04625 L11.55625,12.97025 C11.94725,13.36125 12.58025,13.36125 12.97025,12.97025 C13.36125,12.58025 13.36125,11.94725 12.97025,11.55625 L8.04625,6.63225 L12.97025,1.70725 Z'
        id='path-CloseIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-517.000000, -3243.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(397.000000, 136.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-CloseIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
