import { availableSystemsToOptions } from 'utils';
import { prepareMultipleSelectOptionsFromArray, prepareSelectOptionsFromDictionaries } from 'utils/formHelpers';
import { Dictionaries, systemsFilterOptions } from 'common/constants';
import { integrationsSystemsNamesMapping } from 'types/Integrations';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { customerTypesNamesMapping } from 'types/Customer';
import {
  operationScopeTypeNamesMapping,
  operationStatusNamesMapping,
  operationTypeNamesMapping,
} from 'types/Operations';
import { State as CustomerState } from './customerReducer';

export const getCustomerView = (sharedState: SharedState, customerState: CustomerState) => ({
  isFetching: customerState.isFetching,
  customer: customerState.customer,
  errorCode: customerState.errorCode,
  customerTypeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.CUSTOMER_TYPES],
    customerTypesNamesMapping,
    true,
  ),
});

export const getIntegrationsView = (state: CustomerState) => ({
  isFetching: state.isFetching,
  customer: state.customer,
  integrations: state.integrations,
  availableSystems: state.availableSystems,
});

export const getIntegrationView = (state: CustomerState) => ({
  isFetching: state.isFetching,
  isTestConnectionFetching: state.isTestConnectionFetching,
  customer: state.customer,
  errorCode: state.errorCode,
  integration: state.integration,
  integrationSchema: state.integrationSchema,
});

export const getIntegrationCreateView = (state: CustomerState) => ({
  isFetching: state.isFetching,
  errorCode: state.errorCode,
  customer: state.customer,
  integration: state.integration,
  integrations: state.integrations,
  availableSystems: availableSystemsToOptions(state.availableSystems),
  integrationSchema: state.integrationSchema,
});

export const getOperationsView = (sharedState: SharedState, state: CustomerState) => ({
  isFetching: state.isFetching,
  customer: state.customer,
  operations: state.operations.data,
  totalCount: state.operations.totalCount,
  page: state.operations.page,
  perPage: state.operations.perPage,
  orderBy: state.operations.orderBy,
  sortOrder: state.operations.sortOrder,
  filterScope: state.operations.filterScope,
  filterType: state.operations.filterType,
  filterStatus: state.operations.filterStatus,
  filterSystem: state.operations.filterSystem,
  filterClientName: state.operations.filterClientName,
  filterDateFrom: state.operations.filterDateFrom,
  filterDateTo: state.operations.filterDateTo,
  filterSourceEntityId: state.operations.filterSourceEntityId,
  filterTargetEntityId: state.operations.filterTargetEntityId,
  showOnlyDeactivatedSystems: state.operations.showOnlyDeactivatedSystems,
  clientsOptions: state.operations.clientsOptions,
  connectedSystemsOptions: prepareMultipleSelectOptionsFromArray(
    sharedState.integrationSystems,
    systemsFilterOptions[0],
    integrationsSystemsNamesMapping,
  ),
  operationsScopeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_SCOPE_TYPES],
    operationScopeTypeNamesMapping,
  ),
  operationsTypeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_TYPES],
    operationTypeNamesMapping,
  ),
  operationsStatusOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_STATUSES],
    operationStatusNamesMapping,
  ),
});
