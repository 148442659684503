import { Key, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { TablePerPage, TableSortOrder } from 'types/Tables';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';

import { Spinner, Message, DataTable, Paginator, Select, Input, Button, DatePicker } from 'common/components';
import { CalendarIcon, RefreshIcon } from 'common/components/Icons';
import {
  ClientsQueryParams,
  CustomersQueryParams,
  EInvoiceDto,
  EInvoiceStatus,
  GetEInvoicesRequest,
  ListSortDirection,
} from 'common/api/generated/PAIAdminApiClient';
import {
  DATEPICKER_TIME_FORMAT as timeFormat,
  DATEPICKER_TIME_INTERVAL as timeIntervals,
  FULL_DATE_RANGE_DATE_FORMAT,
} from 'common/constants/DateTimeFormats';
import { buildAccessPointGridColumns } from './accessPointGridColumns';
import {
  FiltersLeft,
  FiltersRight,
  FiltersWrapper,
  TableWrapper,
  Wrapper,
  FiltersColumn,
  FiltersColumnsWrapper,
  FiltersColumnDateRange,
  ButtonWrapper,
  ButtonWrapper2,
} from './styles';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import { ColumnItem, GetRowKey, TableRowSelection } from 'common/components/DataTable/types';
import { callRemoteApiSafely, useRemoteApiCall } from 'hooks/useRemoteApiCall';
import { retry, toRetryInfo } from './retryLogic';
import { downloadFile } from './downloadLogic';
import { IntegrationSystem, IntegrationsSystemTypes } from 'types/Integrations';
import { startFileTransfer } from './startFileTransferLogic';

interface IAccessPointGridProps {
  accessPoints: IntegrationSystem[];
  customerId?: number;
}

interface IOption {
  label: string;
  value: string;
}

const AccessPointGrid = ({ accessPoints, customerId }: IAccessPointGridProps) => {

  accessPoints = accessPoints.filter((x) => x.type === IntegrationsSystemTypes.ACCESS_POINT);

  const paiClient = useAuthenticatedAdminApiClient();

  // We ought to use dispatcher to combine hooks with redux store
  const errorDispatcher: Dispatch = useDispatch();
  const [isFetching, callRemoteApi] = useRemoteApiCall();

  // access points
  const accessPointOptions: IOption[] = (
    accessPoints.length !== 1 // if there is more than a single option available for access points to choose from
      ? [{ label: 'ALL', value: '' }] // all access points means empty string value in search request
      : []
  ).concat(
    accessPoints
      .map<IOption>((ap) => ({ label: ap.name, value: ap.name })),
  );
  const [selectedAccessPointOption, setSelectedAccessPointOption] = useState<IOption>(accessPointOptions[0]);

  // statuses
  const statusOptions: IOption[] = Object.values(EInvoiceStatus).map((status) => ({ label: status, value: status }));
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<IOption[]>([]);

  // customers
  const [customerOptions, setCustomerOptions] = useState<IOption[]>([]);
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState<IOption[]>(
    customerId === undefined ? [] : [{ label: customerId.toString(), value: customerId.toString() }],
  );
  useEffect(() => {
    (async () => {
      if (customerId === undefined) {
        const result = await callRemoteApi(() =>
          paiClient?.customersControllerGetCustomersBasic({} as CustomersQueryParams),
        );
        setCustomerOptions(
          result?.map((customer) => ({ label: customer.name, value: customer.id?.toString()! })) || [],
        );
      }
    })();
  }, [paiClient]);

  // clients
  const [clientOptions, setClientOptions] = useState<IOption[]>([]);
  const [selectedClientOptions, setSelectedClientOptions] = useState<IOption[]>([]);
  useEffect(() => {
    (async () => {
      const result = await callRemoteApi(() =>
        paiClient?.clientsControllerGetClientsBasic(customerId, {} as ClientsQueryParams),
      );
      setClientOptions(result?.data?.map((client) => ({ label: client.name, value: client.id?.toString()! })) || []);
    })();
  }, [paiClient]);

  // vendor org number
  const [vendorOrgNumberInputValue, setVendorOrgNumberInputValue] = useState<string>('');
  const [vendorOrgNumber] = useDebounce(vendorOrgNumberInputValue, 300);

  // internal id
  const [internalIdInputValue, setInternalIdInputValue] = useState<string>('');
  const [internalId] = useDebounce(internalIdInputValue, 300);

  // trace id
  const [traceIdInputValue, setTraceIdInputValue] = useState<string>('');
  const [traceId] = useDebounce(traceIdInputValue, 300);

  // source id
  const [sourceIdInputValue, setSourceIdInputValue] = useState<string>('');
  const [sourceId] = useDebounce(sourceIdInputValue, 300);

  // target id
  const [targetIdInputValue, setTargetIdInputValue] = useState<string>('');
  const [targetId] = useDebounce(targetIdInputValue, 300);

  // dates
  const [filterDateFrom, setFilterDateFrom] = useState<Date>();
  const [filterDateTo, setFilterDateTo] = useState<Date>();

  // pagination
  const [paginatorTotalItemsCount, setPaginatorTotalItemsCount] = useState<number>(0);
  const [paginatorPageNumber, setPaginatorPageNumber] = useState<number>(1);
  const [paginatorItemsPerPage, setPaginatorItemsPerPage] = useState<number>(ITEMS_PER_PAGE_COUNT);

  const withPaginatorReset = <TState,>(setStateAction: (value: TState) => void) => {
    return (value: TState) => {
      setStateAction(value);
      setPaginatorPageNumber(1);
    };
  };

  // selection
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedGridItems, setSelectedGridItems] = useState<EInvoiceDto[]>([]);
  const onGridSelectionChange = (selectedKeys: Key[], selectedRows: EInvoiceDto[]) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedGridItems(selectedRows);
  };
  // TODO - optimize - make latter dependent on former?
  const resetGridRowsSelection = () => {
    setSelectedRowKeys([]);
    setSelectedGridItems([]);
  };
  const gridRowsSelection: TableRowSelection = {
    selectedRowKeys,
    onChange: onGridSelectionChange,
    getCheckboxProps: (record: EInvoiceDto) => ({ disabled: !toRetryInfo(record) }),
  };

  const onRetrySelectedClicked = async () =>
    await callRemoteApi(() =>
      Promise.allSettled(selectedGridItems.map((record) => retry(paiClient, toRetryInfo(record)))),
    );

  // sorting
  const [sortConfig, setSortConfig] = useState<{ name: string; direction: TableSortOrder }>();
  const onGridSortChange = (name: string, direction: TableSortOrder) =>
    withPaginatorReset<{ name: string; direction: TableSortOrder }>((state) => setSortConfig(state))({
      name,
      direction,
    });

  // grid controls/state setup
  const gridRowKeyBuilder: GetRowKey = (_: EInvoiceDto, index?: number) => index!.toString();
  const gridColumns: ColumnItem[] = buildAccessPointGridColumns({
    onRetryButtonClick: (payload, skipRejection) =>
      callRemoteApiSafely(errorDispatcher, () => retry(paiClient, payload, skipRejection)),
    onDownloadFileLinkClick: (payload) => callRemoteApiSafely(errorDispatcher, () => downloadFile(paiClient, payload)),
  })
    // hide customer name column for customer view
    .filter((c) => customerId === undefined || c.name !== 'customerName');

  // start new transfer
  const onStartNewTransferClick = () =>
    callRemoteApi(async () => {
      return await startFileTransfer(paiClient, {
        customerId: customerId!,
        selectedClients: selectedClientOptions.map((x) => x.value),
        accessPointName: selectedAccessPointOption.value,
      });
    });

  // grid request (initiates grid refresh after change)
  const [gridRequest, setGridRequest] = useState<GetEInvoicesRequest>();
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setGridRequest({
      ...gridRequest,
      accessPointName: selectedAccessPointOption.value, // selectedAccessPointOption.value === ACCESS_POINT_OPTION_ALL ? undefined : selectedAccessPointOption.value,
      page: paginatorPageNumber,
      size: paginatorItemsPerPage,
      filter: {
        ...gridRequest?.filter,
        statuses: selectedStatusOptions.map((opt) => EInvoiceStatus[opt.value as keyof typeof EInvoiceStatus]),
        customerIds: selectedCustomerOptions.map((opt) => parseInt(opt.value)),
        clientIds: selectedClientOptions.map((opt) => parseInt(opt.value)),
        vendorOrgNumber: vendorOrgNumber,
        traceId: traceId,
        internalId: internalId,
        sourceId: sourceId,
        targetId: targetId,
        updatedOnFrom: filterDateFrom,
        updatedOnTo: filterDateTo,
      },
      orderBy: sortConfig?.name,
      sortOrder:
        sortConfig?.direction === TableSortOrder.ASCENDING ? ListSortDirection.Ascending : ListSortDirection.Descending,
    });
  }, [
    selectedAccessPointOption,
    selectedStatusOptions,
    selectedCustomerOptions,
    selectedClientOptions,
    vendorOrgNumber,
    sourceId,
    targetId,
    traceId,
    internalId,
    paginatorPageNumber,
    paginatorItemsPerPage,
    filterDateFrom,
    filterDateTo,
    sortConfig,
    refreshKey, // doesn't affect anything, simply initiates reload effect
  ]);

  // grid data
  const [gridItems, setGridItems] = useState<EInvoiceDto[]>([]);

  useEffect(() => {
    (async () => {
      const result = await callRemoteApi(() => {
        if (!!gridRequest && !!paiClient) {
          return paiClient?.accessPointControllerGetEInvoiceRecords(gridRequest);
        }
        return undefined;
      });

      resetGridRowsSelection();
      setGridItems(result?.data || []);
      setPaginatorTotalItemsCount(result?.totalCount || 0);
    })();
  }, [paiClient, gridRequest]);

  return (
    <Wrapper>
      <FiltersWrapper>
        <FiltersLeft>
          <FiltersColumnsWrapper>
            <FiltersColumn>
              <Select
                id='filterAccessPointName'
                value={selectedAccessPointOption}
                options={accessPointOptions}
                label={<Message id={'access-point-grid-filter-access-point-name'} />}
                onChange={withPaginatorReset(setSelectedAccessPointOption)}
                disabled={isFetching}
              />
            </FiltersColumn>
            <FiltersColumn>
              <Select
                isMulti
                isClearable={false}
                id='filterTransferEInvoiceStatus'
                value={selectedStatusOptions}
                options={statusOptions}
                label={<Message id={'access-point-grid-filter-status'} />}
                onChange={withPaginatorReset(setSelectedStatusOptions)}
                disabled={isFetching}
              />
            </FiltersColumn>
            {customerId === undefined ? ( // do render select if specific customer is not set via properties
              <FiltersColumn>
                <Select
                  id='filterTransferEInvoiceCustomerName'
                  value={selectedCustomerOptions}
                  options={customerOptions}
                  label={<Message id={'access-point-grid-filter-customer-name'} />}
                  onChange={withPaginatorReset(setSelectedCustomerOptions)}
                  disabled={isFetching}
                  isMulti={true}
                  isClearable={false}
                />
              </FiltersColumn>
            ) : null}
            <FiltersColumn>
              <Select
                id='filterTransferEInvoiceClientName'
                value={selectedClientOptions}
                options={clientOptions}
                label={<Message id={'access-point-grid-filter-client-name'} />}
                onChange={withPaginatorReset(setSelectedClientOptions)}
                disabled={isFetching}
                isMulti={true}
                isClearable={false}
              />
            </FiltersColumn>
          </FiltersColumnsWrapper>
          <FiltersColumnsWrapper>
            <FiltersColumn>
              <Input
                id='traceId'
                disabled={isFetching}
                type='text'
                label={<Message id='access-point-grid-filter-trace-id' />}
                value={traceIdInputValue}
                onChange={withPaginatorReset(setTraceIdInputValue)}
                placeholder='Trace Id'
              />
            </FiltersColumn>
            <FiltersColumn>
              <Input
                id='internalId'
                disabled={isFetching}
                type='text'
                label={<Message id='access-point-grid-filter-internal-id' />}
                value={internalIdInputValue}
                onChange={withPaginatorReset(setInternalIdInputValue)}
                placeholder='E-Invoice Id'
              />
            </FiltersColumn>
            <FiltersColumn>
              <Input
                id='filterSourceId'
                disabled={isFetching}
                type='text'
                label={<Message id='access-point-grid-filter-source-id' />}
                value={sourceIdInputValue}
                onChange={withPaginatorReset(setSourceIdInputValue)}
                placeholder='Invoice Number'
              />
            </FiltersColumn>
            <FiltersColumn>
              <Input
                id='filterTargetId'
                disabled={isFetching}
                type='text'
                label={<Message id='access-point-grid-filter-target-id' />}
                value={targetIdInputValue}
                onChange={withPaginatorReset(setTargetIdInputValue)}
                placeholder='Target Id'
              />
            </FiltersColumn>
            <FiltersColumn>
              <Input
                id='filterVendorOrgNumber'
                disabled={isFetching}
                type='text'
                label={<Message id='access-point-grid-filter-vendor-org-number' />}
                value={vendorOrgNumberInputValue}
                onChange={withPaginatorReset(setVendorOrgNumberInputValue)}
                placeholder='Vendor Org Number'
              />
            </FiltersColumn>
          </FiltersColumnsWrapper>
          <FiltersColumnsWrapper>
            <FiltersColumnDateRange>
              <DatePicker
                date={filterDateFrom}
                withMonthYearSelect
                dateFormat={FULL_DATE_RANGE_DATE_FORMAT}
                showTimeSelect={true}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                leftIcon={<CalendarIcon />}
                label={<Message id={'access-point-grid-filter-date-from'} />}
                onChange={withPaginatorReset(setFilterDateFrom)}
                popperPlacement='bottom-start'
                isDisabled={isFetching}
              >
                Select date range
              </DatePicker>
            </FiltersColumnDateRange>
            <FiltersColumnDateRange>
              <DatePicker
                date={filterDateTo}
                withMonthYearSelect
                dateFormat={FULL_DATE_RANGE_DATE_FORMAT}
                showTimeSelect={true}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                leftIcon={<CalendarIcon />}
                label={<Message id={'access-point-grid-filter-date-to'} />}
                maxDate={new Date()}
                onChange={withPaginatorReset(setFilterDateTo)}
                popperPlacement='bottom-start'
                isDisabled={isFetching}
              >
                Select date range
              </DatePicker>
            </FiltersColumnDateRange>
          </FiltersColumnsWrapper>
        </FiltersLeft>
        <FiltersRight>
          {customerId !== undefined ? ( // do render new transfer button only if specific customer is set via properties
            <ButtonWrapper>
              <Button
                primary
                disabled={isFetching || !selectedAccessPointOption.value}
                onClick={onStartNewTransferClick}
              >
                <Message id='access-point-grid-start-new-transfer-button' />
              </Button>
            </ButtonWrapper>
          ) : null}
          <ButtonWrapper>
            <ButtonWrapper2>
              <Button primary disabled={!selectedGridItems.length || isFetching} onClick={onRetrySelectedClicked}>
                <Message id='access-point-grid-retry-selected-button' />
              </Button>
            </ButtonWrapper2>
            <ButtonWrapper2>
              <Button primary disabled={isFetching} onClick={() => setRefreshKey(refreshKey + 1)}>
                <RefreshIcon width={20} height={20} />
              </Button>
            </ButtonWrapper2>
          </ButtonWrapper>
        </FiltersRight>
      </FiltersWrapper>
      <TableWrapper>
        <DataTable
          isFetching={isFetching}
          data={gridItems}
          loadingComponent={Spinner}
          columns={gridColumns}
          emptyMessage={<Message id='no-data-message' />}
          orderBy={sortConfig?.name}
          sortOrder={sortConfig?.direction}
          onSortChange={onGridSortChange}
          rowKey={gridRowKeyBuilder}
          rowSelection={gridRowsSelection}
        />
      </TableWrapper>
      <Paginator
        totalCount={paginatorTotalItemsCount}
        page={paginatorPageNumber}
        perPage={paginatorItemsPerPage}
        onItemsPerPageChange={withPaginatorReset((perPage: TablePerPage) =>
          setPaginatorItemsPerPage(parseInt(perPage.value)),
        )}
        onPageChange={(page: { selected: number }) => {
          setPaginatorPageNumber(page.selected);
        }}
      />
    </Wrapper>
  );
};

export default AccessPointGrid;
