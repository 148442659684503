import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';

const getReleaseNotesBreadcrumbs = () => [
  {
    title: BREADCRUMBS_TITLES.RELEASE_NOTES,
    isActive: true,
  },
];

export default getReleaseNotesBreadcrumbs;
