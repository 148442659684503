import { prepareSelectOptionsFromDictionaries } from 'utils/formHelpers';
import { Dictionaries } from 'common/constants';
import { customerTypesNamesMapping } from 'types/Customer';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { State as CustomerCreateState } from './customerCreateReducer';

export const getCustomerCreateView = (sharedState: SharedState, customerCreateState: CustomerCreateState) => ({
  isFetching: customerCreateState.isFetching,
  customerTypeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.CUSTOMER_TYPES],
    customerTypesNamesMapping,
    true,
  ),
});
