import { Icon } from 'types/Icon';

export const SyncIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '17'} height={height || '16'} viewBox='0 0 17 16'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M15.9936,3.11424005 L13.5874,5.54630005 C13.3361,5.80033005 12.9031,5.62235005 12.9031,5.26499005 L12.9031,3.83292005 L2,3.83292005 L2,7.25633005 C2,7.80863005 1.55228,8.25633005 1,8.25633005 C0.447723,8.25633005 0,7.80863005 0,7.25633005 L0,3.83292005 C0,2.72837005 0.895416,1.83292005 2,1.83292005 L12.9031,1.83292005 L12.9031,0.400796055 C12.9031,0.0434349545 13.3361,-0.134604945 13.5874,0.119485055 L15.9936,2.55155005 C16.1478,2.70744005 16.1478,2.95835005 15.9936,3.11424005 Z M16.2032,12.1712001 C16.2032,13.2757001 15.3078,14.1712001 14.2032,14.1712001 L3.30017,14.1712001 L3.30017,15.6033001 C3.30017,15.9607001 2.86716,16.1387001 2.61581,15.8846001 L0.209686,13.4526001 C0.055481,13.2967001 0.055481,13.0458001 0.209686,12.8899001 L2.61581,10.4578001 C2.86716,10.2037701 3.30017,10.3817501 3.30017,10.7391101 L3.30017,12.1712001 L14.2032,12.1712001 L14.2032,8.74778005 C14.2032,8.19547005 14.651,7.74778005 15.2032,7.74778005 C15.7555,7.74778005 16.2032,8.19547005 16.2032,8.74778005 L16.2032,12.1712001 Z'
        id='path-SyncIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-608.000000, -3133.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(488.000000, 76.000000)'>
            <use fill={color || 'currentColor'} fillRule='nonzero' xlinkHref='#path-SyncIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
