import { ImportMode, importModeNamesMapping } from 'types/Import';

export const importModeOptionsIntegrations = [
  {
    id: 1,
    value: ImportMode.CREATE,
    label: importModeNamesMapping[ImportMode.CREATE],
  },
  {
    id: 2,
    value: ImportMode.UPDATE,
    label: importModeNamesMapping[ImportMode.UPDATE],
  },
];

export const importModeOptionsClients = [
  {
    id: 1,
    value: ImportMode.CREATE,
    label: importModeNamesMapping[ImportMode.CREATE],
  },
  {
    id: 2,
    value: ImportMode.UPDATE,
    label: importModeNamesMapping[ImportMode.UPDATE],
  },
];
