import { ReactNode, useState } from 'react';
import { TextareaContainer, TextareaField, InputErrorMessage, Label, TextareaWrapper } from './styles';

interface TextareaProps {
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  value?: string | number | undefined;
  isError?: boolean;
  isRequired?: boolean;
  errorText?: ReactNode | string;
  label?: ReactNode | string;
  placeholder?: string;
  minHeight?: number;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
}

const Textarea = ({
  id,
  disabled,
  value,
  label,
  placeholder,
  errorText,
  isError,
  isRequired,
  minHeight,
  onChange,
  onFocus,
  onBlur,
}: TextareaProps) => {
  const [isFocused, setFocused] = useState(false);
  const isInputEmpty = value === '';

  const handleOnChange = (event: any) => {
    if (!onChange) return event.preventDefault();

    onChange(event.target.value);
  };

  const handleOnFocus = (e: any) => {
    if (onFocus) {
      onFocus(e);
    }

    setFocused(!isFocused);
  };

  const handleOnBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    }

    setFocused(!isFocused);
  };

  const renderErrorText = () => <InputErrorMessage isError={isError}>{errorText}</InputErrorMessage>;

  return (
    <TextareaContainer>
      <div>
        <Label htmlFor={id} isError={isError} disabled={disabled}>
          {label}
          {isRequired && <span>*</span>}
        </Label>
        <TextareaWrapper>
          <TextareaField
            value={value}
            disabled={disabled}
            isFocused={isFocused}
            isError={isError}
            isInputEmpty={isInputEmpty}
            placeholder={placeholder}
            minHeight={minHeight}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
        </TextareaWrapper>
        {isError && errorText && renderErrorText()}
      </div>
    </TextareaContainer>
  );
};

export default Textarea;
