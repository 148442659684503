import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'browserHistory';
import AppIntl from './AppIntl';
import { configureStore, runRootSaga } from './store';

// import TaskApp from './tasks-app/TaskApp';
// import AccessPointGrid from 'components/AccessPointGrid/AccessPointGrid';

browserHistory.listen((_location, action) => {
  setTimeout(() => {
    if (action === 'POP') {
      return;
    }
    window.scrollTo(0, 0);
  }, 0);
});

const renderApp = () => {
  const store = configureStore(browserHistory);

  runRootSaga();

  return render(
    <Provider store={store}>
      <AppIntl />
    </Provider>,
    document.getElementById('root'),
  );
};

renderApp();
