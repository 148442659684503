import { v4 } from 'uuid';
import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { NotificationProps } from 'types/Notifications';

export enum ActionTypeKeys {
  ADD_NOTIFICATION = 'NOTIFICATION/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'NOTIFICATION/REMOVE_NOTIFICATION',
}

export const actionCreators = {
  addNotification: (payload: NotificationProps) => createAction(ActionTypeKeys.ADD_NOTIFICATION, payload),
  removeNotification: (payload: string) => createAction(ActionTypeKeys.REMOVE_NOTIFICATION, payload),
};

// State
export interface State {
  notifications: NotificationProps[];
}

export const initialState: State = {
  notifications: [] as NotificationProps[],
};

// Reducer
export const notificationReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.ADD_NOTIFICATION:
      // eslint-disable-next-line no-case-declarations
      const notification = {
        id: v4(),
        ...action.payload,
      };
      return {
        ...state,
        notifications: [...state.notifications, notification],
      };
    case ActionTypeKeys.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((el: any) => el.id !== action.payload),
      };

    default:
      return state;
  }
};
