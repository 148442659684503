import { ReactElement, ReactNode, useState } from 'react';
import Cron, { CronError } from 'react-js-cron';
import 'react-js-cron/dist/styles.css';
import './pai-cron-scheduler.css';
import { Message, Input } from 'common/components/index';
import { CronContainer, CronInput, CronErrorMessage, Label } from './styles';

interface CronSchedulerProps {
  disabled?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  id: string;
  value: string;
  label: string | ReactElement;
  errorText?: ReactNode;
  onChange: (event: any) => void;
}

const CronScheduler = ({
  id,
  label,
  disabled,
  isError,
  isRequired,
  value,
  onChange,
  errorText,
}: CronSchedulerProps) => {
  const [error, onError] = useState<CronError>();

  const renderCronError = () => <CronErrorMessage isError={!!error}>{error}</CronErrorMessage>;

  const renderErrorText = () => <CronErrorMessage isError={isError}>{errorText}</CronErrorMessage>;

  return (
    <CronContainer>
      <Label isError={isError} disabled={disabled} htmlFor={id}>
        {label}
        {isRequired && <span>*</span>}
      </Label>
      <Cron
        className='pai-cron-scheduler'
        disabled={disabled}
        value={value}
        setValue={onChange}
        onError={onError}
        allowEmpty={'always'}
        clearButton={true}
        clearButtonAction={'empty'}
      />
      {error && renderCronError()}
      <CronInput>
        <Input
          id={id}
          label={<Message id='converted-cron-value' />}
          readOnly
          type='text'
          value={value}
          disabled={true}
          isError={isError}
        />
      </CronInput>
      {isError && errorText && renderErrorText()}
    </CronContainer>
  );
};

export default CronScheduler;
