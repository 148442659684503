import { Icon } from 'types/Icon';

export const FolderIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '18'} height={height || '15'} viewBox='0 0 18 15'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M2,2 L2,13 L16,13 L16,4 L10.4142136,4 C9.61856409,4 8.85550235,3.68392948 8.29289322,3.12132034 L7.17157287,2 L2,2 Z M10.4142136,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,13 C18,14.1045695 17.1045695,15 16,15 L2,15 C0.8954305,15 0,14.1045695 0,13 L0,2 C0,0.8954305 0.8954305,0 2,0 L7.58578644,0 C7.85100293,0 8.10535684,0.10535684 8.29289322,0.292893219 L9.70710678,1.70710678 C9.89464316,1.89464316 10.1489971,2 10.4142136,2 Z'
        id='path-FolderIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-820.000000, -3243.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(700.000000, 136.000000)'>
            <use fill={color || 'currentColor'} fillRule='nonzero' xlinkHref='#path-FolderIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
