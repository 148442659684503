import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Message } from 'common/components';
import { CloseIcon } from 'common/components/Icons';
import { NotificationItem, NotificationMessage, NotificationText, NotificationCloseButton } from './styles';

const NotificationToast = ({
  id,
  title,
  errorTraceId,
  type,
  messageId,
  messageVariable,
  errorText,
  errorStatus,
  errorComponent,
  removeNotification,
  autoDeleteTime = 2000,
  autoDeleteEnabled = true,
}: any) => {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const [timerId, setTimerId] = useState<NodeJS.Timeout>(null);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);

  const handleCloseNotification = useCallback(() => {
    removeNotification(id);
  }, [removeNotification, id]);

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  useEffect(() => {
    if (currentLocation !== location) {
      handleCloseNotification();
    }
  }, [currentLocation, location, handleCloseNotification]);

  const handleStartTimer = useCallback(() => {
    if (autoDeleteTime !== 0 && autoDeleteEnabled) {
      const timer = setTimeout(handleCloseNotification, autoDeleteTime);
      setTimerId(timer);
    }
  }, [autoDeleteTime, handleCloseNotification, autoDeleteEnabled]);

  const handlePauseTimer = useCallback(() => {
    if (autoDeleteEnabled) {
      clearTimeout(timerId);
    }
  }, [timerId, autoDeleteEnabled]);

  useEffect(() => {
    handleStartTimer();

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [autoDeleteTime, handleStartTimer]); // eslint-disable-line react-hooks/exhaustive-deps

  let textMessage;
  if (errorText) {
    // eslint-disable-next-line prefer-const
    textMessage = <span dangerouslySetInnerHTML={{ __html: errorText }} />;
  }

  if (errorStatus === 401) {
    return null;
  }

  return (
    <NotificationItem onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer} type={type}>
      <NotificationCloseButton onClick={handleCloseNotification}>
        <CloseIcon />
      </NotificationCloseButton>
      {title && <NotificationMessage>{title}</NotificationMessage>}
      <NotificationMessage>
        <Message id={messageId} values={{ variable: messageVariable }} />
      </NotificationMessage>
      {textMessage && <NotificationText>{textMessage}</NotificationText>}
      {errorTraceId && (
        <NotificationText>
          <strong>TraceId:</strong> {errorTraceId}
        </NotificationText>
      )}
      {errorComponent && <NotificationText>{errorComponent}</NotificationText>}
    </NotificationItem>
  );
};

export default NotificationToast;
