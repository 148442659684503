import mainTheme from 'assets/theme';

interface CustomStylesType {
  // eslint-disable-next-line @typescript-eslint/ban-types
  styles: object;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  isFloating?: boolean;
  isError?: boolean;
  isInputEmpty: boolean;
}
/* eslint-disable */
export const customStyles = {
  control: (styles: object, { isError, isDisabled, isFocused }: CustomStylesType) => {
    return {
      ...styles,
      minHeight: 36,
      boxShadow: 'none',
      backgroundColor: mainTheme.backgroundPrimary,
      borderRadius: 4,
      borderStyle: 'solid',
      borderColor: isDisabled
        ? mainTheme.borders.grey
        : isError
        ? mainTheme.colors.error
        : isFocused
        ? mainTheme.primary
        : mainTheme.borders.grey,
      ':hover': {
        borderColor: isDisabled
          ? mainTheme.borders.grey
          : isError
          ? mainTheme.colors.error
          : isFocused
          ? mainTheme.primary
          : mainTheme.borders.grey,
      },
    };
  },
  option: (styles: object, { isDisabled, isFocused, isSelected }: CustomStylesType) => ({
    ...styles,
    fontSize: 12,
    backgroundColor: isDisabled ? null : isSelected ? mainTheme.primary : isFocused ? mainTheme.backgroundThird : null,
    color: isDisabled
      ? mainTheme.fontPrimary
      : isSelected
      ? mainTheme.colors.white
      : isFocused
      ? mainTheme.fontPrimary
      : mainTheme.fontPrimary,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    ':hover': {
      backgroundColor: isSelected ? mainTheme.primary : mainTheme.backgroundThird,
    },
    ':active': {
      color: mainTheme.colors.white,
      backgroundColor: mainTheme.primary,
    },
  }),
  input: (styles: object) => ({
    ...styles,
    fontSize: 12,
    color: mainTheme.fontPrimary,
    paddingTop: 0,
    paddingBottom: 8,
    margin: '8px 0 0 ',
  }),
  valueContainer: (styles: object, { isDisabled }: CustomStylesType) => ({
    ...styles,
    padding: '0 10px',
    color: isDisabled ? mainTheme.fontSecondary : mainTheme.fontPrimary,
  }),
  singleValue: (styles: object, { isDisabled }: CustomStylesType) => ({
    ...styles,
    color: isDisabled ? mainTheme.fontSecondary : mainTheme.fontPrimary,
    fontSize: 12,
    lineHeight: '18px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  }),
  multiValue: (styles: object, { isDisabled }: CustomStylesType) => {
    return {
      ...styles,
      color: isDisabled ? mainTheme.fontSecondary : mainTheme.fontPrimary,
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 400,
      backgroundColor: 'transparent',
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: mainTheme.colors.grey,
      padding: '1px 2px 1px 5px',
      margin: '3px 5px 1px 0',
    };
  },
  multiValueLabel: (styles: object, { isDisabled }: CustomStylesType) => ({
    ...styles,
    color: isDisabled ? mainTheme.fontSecondary : mainTheme.fontPrimary,
    fontSize: 12,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
  }),
  dropdownIndicator: (styles: object, { isDisabled, isError }: CustomStylesType) => ({
    ...styles,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 6,
    paddingBottom: 6,
    opacity: 1,
    background: 'transparent',
    marginLeft: 0,
    color: isDisabled ? mainTheme.fontSecondary : isError ? mainTheme.fontPrimary : mainTheme.fontPrimary,
  }),
  menu: (styles: object) => ({
    ...styles,
    backgroundColor: mainTheme.backgroundPrimary,
    zIndex: 3,
  }),
  clearIndicator: (styles: object) => ({
    ...styles,
    padding: 5,
  }),
  multiValueRemove: (styles: object) => ({
    ...styles,
    paddingLeft: 2,
    paddingRight: 2,
    marginLeft: 3,
    color: mainTheme.fontPrimary,
    svg: {
      width: 12,
      height: 12,
      strokeWidth: 0,
    },
  }),
  placeholder: (styles: object) => ({
    ...styles,
    display: 'none',
  }),
  indicatorSeparator: (styles: object) => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles: object) => ({
    ...styles,
    width: 20,
  }),
};
/* eslint-enable */
