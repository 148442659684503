import {
  RetrySyncAllRequestData,
  RetrySyncRequestData,
  StartNewTransferRequestData,
  OperationsAvailability,
} from 'types/AccessPoint';

export const API_URLS = {
  customersService: {
    customer: '/api/customer',
    customers: '/api/customers',
    customersCount: '/api/customers/count',
    customersBasic: '/api/customers/basic',
    customerById: (customerId: number) => `/api/customers/${customerId}`,
    customerStatusById: (id: number) => `/api/customers/${id}/status`,
    getCustomerAvailableSystems: (customerId: number) => `/api/integrations/customers/${customerId}/availableSystems`,
  },

  clientsService: {
    clientsAll: '/api/clients',
    clientsAllCount: '/api/clients/count',
    client: (customerId: number) => `/api/customers/${customerId}/client`,
    clients: (customerId: number) => `/api/customers/${customerId}/clients`,
    clientsBasic: '/api/clients/basic',
    clientsCount: (customerId: number) => `/api/customers/${customerId}/clients/count`,
    clientById: (customerId: number, id: number) => `/api/customers/${customerId}/clients/${id}`,
    setClientStatusById: (customerId: number, id: number) => `/api/customers/${customerId}/clients/${id}/status`,
    eInvoiceLogs: (system: any) => `/api/einvoice-operation-logs/${system}`,
    clientsForTransferData: (customerId: number) => `/api/customers/${customerId}/operations/transferhistoricaldata`,
    executeTransferDataByClientId: (customerId: number, clientId: number) =>
      `/api/customers/${customerId}/clients/${clientId}/transferhistoricaldata/execute`,
    clientsForSyncMasterData: (customerId: number) => `/api/customers/${customerId}/operations/syncmasterdata`,
    executeSyncMasterDataByClientId: (customerId: number, clientId: number) =>
      `/api/customers/${customerId}/clients/${clientId}/syncmasterdata/execute`,
    importClients: '/api/imports/clients',
    importIntegrations: '/api/imports/integrations',
    getClientAvailableSystems: (customerId: number, clientId: number) =>
      `/api/integrations/customers/${customerId}/clients/${clientId}/availableSystems`,
  },

  integrationsService: {
    integrationSystems: '/integrations/api/Systems',
    integrationById: (id: string) => `/api/integrations/${id}`,
    updateIntegrationById: (id: string) => `/api/integrations/${id}`,
    testConnection: (id: string) => `/api/integrations/${id}/test-connection`,
    customerIntegrations: (customerId: number) => `/api/Integrations/Customers/${customerId}`,
    customerClientsAllIntegrations: (customerId: number) => `/api/Integrations/Customers/${customerId}/Clients`,
    createCustomerIntegrations: (customerId: number) => `/api/Integrations/Customers/${customerId}`,
    clientIntegrations: (customerId: number, clientId: number) =>
      `/api/integrations/customers/${customerId}/clients/${clientId}`,
    createClientIntegrations: (customerId: number, clientId: number) =>
      `/api/integrations/customers/${customerId}/clients/${clientId}`,
    systemSettings: (systemId: string) => `/integrations/api/systems/${systemId}/settings`,
    customerSystemSettings: (customerId: number, systemId: string) =>
      `/integrations/api/customers/${customerId}/systems/${systemId}/Settings`,
    clientSystemSettings: (customerId: number, clientId: number, systemId: string) =>
      `/integrations/api/customers/${customerId}/clients/${clientId}/systems/${systemId}/settings`,
    customerSystemSchema: (systemId: string) => `/integrations/api/systems/${systemId}/customerSchema`,
    clientSystemSchema: (systemId: string) => `/integrations/api/systems/${systemId}/clientSchema`,
    systemSchema: (systemId: string) => `/integrations/api/systems/${systemId}/schema`,
    updateIntegrationDefaultSettings: (systemId: string) => `/integrations/api/systems/${systemId}/settings`,
    availableSystems: '/api/integrations/availableSystems',
    getIntegrationsCsvFileHeaders: (systemId: string) => `/api/imports/systems/${systemId}/integrationfields`,
    updateIntegrationStatus: (integrationId: string, action: string) => `/api/integrations/${integrationId}/${action}`,
  },

  operationsService: {
    operations: '/api/operations',
  },

  invoiceService: {
    eInvoiceLogs: (system: any) => `/api/einvoices-logs/${system}`,
    zipBatchesLogs: (system: any) => `/api/zipbatches-logs/${system}`,
    retrySyncEInvoiceLogs: ({ system, id }: RetrySyncRequestData) =>
      `/api/einvoice-management/${system}/retry-sync/${id}`,
    customerRetrySyncAllEInvoiceLogs: ({ system, customerId }: RetrySyncAllRequestData) =>
      `/api/einvoice-management/${system}/customers/${customerId}/retry-sync-all`,
    retrySyncZipBatchLogs: ({ system, id }: RetrySyncRequestData) =>
      `/api/zipbatch-management/${system}/retry-sync/${id}`,
    customerRetrySyncAllZipBatchLogs: ({ system, customerId }: RetrySyncAllRequestData) =>
      `/api/zipbatch-management/${system}/customers/${customerId}/retry-sync-all`,
    checkOperationAvailability: ({ managementName, system }: OperationsAvailability) =>
      `/api/${managementName}-logs/${system}/operations-availability`,
    startNewTransfer: ({ managementName, system, customerId }: StartNewTransferRequestData) =>
      `/api/${managementName}-management/${system}/customers/${customerId}/trigger-export`,
    einvoiceDownloadFile: '/api/blobs/download',
  },

  notificationsService: {
    notifications: '/api/notifications',
    notificationsLatest: '/api/notifications/latest',
    notificationsMarkAllAsRead: '/api/notifications/MarkAllAsRead',
    updateNotificationsById: (id: number) => `/api/notifications/${id}`,
  },

  dictionariesService: {
    dictionaries: '/api/dictionaries',
  },
};
