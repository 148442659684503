import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

import { APP_URLS } from 'common/constants';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { useRemoteApiCall } from 'hooks/useRemoteApiCall';
import { Button, Input, Message, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import { FormButtonsLeft, FormButtonsRight } from 'components/ConfigurationForm/styles';

import {
  ButtonBack,
  FormButtons,
  FormColumn,
  FormColumnWrapper,
  FormRow,
  FormWrapper,
  TabHeader,
  TabTitle,
} from './styles';
import { usePortalBreadcrumbsEffect } from './breadcrumbs';

const defaultValues = {
  userName: '',
  email: '',
};

interface CreatePortalFormProps {
  userName: string;
  email: string;
}

type UrlParams = {
  customerId: string;
  portalId: string;
};

export const validateEmail = (value: string) => {
  if (!value?.replace(/\s/g, '')) {
    return 'error-customer-portal-create-user-email-cant-be-empty';
  }
  if (
    // eslint-disable-next-line no-useless-escape
    !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )
  ) {
    return 'error-customer-portal-create-user-email-incorrect';
  }

  return true;
};

const PortalUserCreate = () => {
  const { customerId, portalId } = useParams<UrlParams>();

  const { push } = useHistory();

  const paiClient = useAuthenticatedAdminApiClient();
  const [isFetching, callRemoteApi] = useRemoteApiCall();

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toCustomerPortal(Number(customerId)));
  }, [customerId, push]);

  usePortalBreadcrumbsEffect('Create Customer Portal User');

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
  } = useForm({ mode: 'onChange' });

  const [isFormDisabled, setFormDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: CreatePortalFormProps) => {
      setFormDisabled(true);

      const result = await callRemoteApi(() =>
        paiClient?.customerPortalControllerCustomerPortalCreateUser(Number(customerId), portalId, {
          name: data.userName,
          email: data.email,
          isAdmin: true,
        }),
      );

      setFormDisabled(false);

      if (result) {
        push(APP_URLS.toCustomerPortal(Number(customerId)));
      }
    },
    [paiClient, push, callRemoteApi, customerId, portalId],
  );

  return (
    <>
      <TabHeader>
        <ButtonBack>
          <Button transparent onClick={onCancelHandler}>
            <ArrowLeftIcon />
          </Button>
        </ButtonBack>
        <TabTitle>
          <Message id={'create-customer-portal-user-tab'} />
        </TabTitle>
      </TabHeader>
      <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
        {isFetching && <Spinner />}
        <FormColumnWrapper>
          <FormColumn>
            <FormRow>
              <Controller
                control={control}
                name='userName'
                defaultValue={defaultValues.userName}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  const messageId = fieldState.error?.message
                    ? fieldState.error.message
                    : 'customer-portal-create-user-name-label-error';

                  return (
                    <Input
                      isRequired
                      disabled={isFormDisabled}
                      isError={fieldState.invalid}
                      id='userName'
                      type='text'
                      label={<Message id={'customer-portal-create-user-name-label'} />}
                      value={field.value}
                      onChange={field.onChange}
                      errorText={<Message id={messageId} />}
                    />
                  );
                }}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name='email'
                defaultValue={defaultValues.email}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 100,
                  validate: validateEmail,
                }}
                render={({ field, fieldState }) => {
                  const messageId = fieldState.error?.message
                    ? fieldState.error.message
                    : 'customer-portal-create-user-email-label-error';

                  return (
                    <Input
                      isRequired
                      disabled={isFormDisabled}
                      isError={fieldState.invalid}
                      id='email'
                      type='text'
                      label={<Message id={'customer-portal-create-user-email-label'} />}
                      value={field.value}
                      onChange={field.onChange}
                      errorText={<Message id={messageId} />}
                    />
                  );
                }}
              />
            </FormRow>
          </FormColumn>
        </FormColumnWrapper>

        <FormButtons>
          <FormButtonsLeft></FormButtonsLeft>
          <FormButtonsRight>
            <Button transparent onClick={onCancelHandler}>
              <Message id={'cancel-button'} />
            </Button>
            <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
              <Message id={'save-button'} />
            </Button>
          </FormButtonsRight>
        </FormButtons>
      </FormWrapper>
    </>
  );
};

export default PortalUserCreate;
