import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators as customerActionCreators } from 'screens/Customer/logic/customerReducer';
import { actionCreators } from './logic/clientsReducer';
import Clients from './CustomerClients';
import { getClientsView } from './logic/clientsSelectors';

const mapStateToProps = ({ shared, customer, clients }: RootState) => getClientsView(shared, customer, clients);

const mapDispatchToProps = {
  getIntegrationsCsvFileHeaders: actionCreators.getIntegrationsCsvFileHeaders,
  getCustomer: customerActionCreators.getCustomer,
  getCustomerIntegrations: customerActionCreators.getCustomerIntegrations,
  getClientConfigurationSchema: actionCreators.getClientConfigurationSchema,
  getClients: actionCreators.getClients,
  setTablePage: actionCreators.setClientsTablePage,
  setTablePerPage: actionCreators.setClientsTablePerPage,
  setFilterStatus: actionCreators.setClientsTableFilterStatus,
  setFilterSystem: actionCreators.setClientsTableFilterSystem,
  setFilterSearch: actionCreators.setClientsTableFilterSearch,
  setTableOrder: actionCreators.setClientsTableSortOrder,
  setUploadFile: actionCreators.setUploadFile,
  setImportType: actionCreators.setImportType,
  setImportMode: actionCreators.setImportMode,
  setIsIncludeDeactivated: actionCreators.setIsIncludeDeactivated,
  setShowOnlyDeactivatedSystems: actionCreators.setShowOnlyDeactivatedSystems,
  importFile: actionCreators.importFile,
  activateMultipleClients: actionCreators.activateMultipleClients,
  resetUploadFile: actionCreators.resetUploadFile,
  reset: actionCreators.clientsReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(Clients);
