import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/operationsAllReducer';
import { getOperationsAllView } from './logic/operationsAllSelectors';
import OperationsAll from './OperationsAll';

const mapStateToProps = ({ shared, operationsAll }: RootState) => getOperationsAllView(shared, operationsAll);

const mapDispatchToProps = {
  getOperations: actionCreators.getOperations,
  setOperationsTablePage: actionCreators.setOperationsTablePage,
  setOperationsTablePerPage: actionCreators.setOperationsTablePerPage,
  setOperationsTableOrder: actionCreators.setOperationsTableSortOrder,
  setFilterOperationScope: actionCreators.setOperationsFilterOperationScope,
  setFilterOperation: actionCreators.setOperationsFilterOperation,
  setFilterStatus: actionCreators.setOperationsFilterStatus,
  setFilterSystem: actionCreators.setOperationsFilterSystems,
  setFilterCustomerName: actionCreators.setOperationsFilterCustomerName,
  setFilterClientName: actionCreators.setOperationsFilterClientName,
  setFilterDateFrom: actionCreators.setFilterDateFrom,
  setFilterDateTo: actionCreators.setFilterDateTo,
  setFilterSourceEntityId: actionCreators.setFilterSourceEntityId,
  setFilterTargetEntityId: actionCreators.setFilterTargetEntityId,
  setShowOnlyDeactivatedSystems: actionCreators.setShowOnlyDeactivatedSystems,
  getCustomersOptions: actionCreators.getCustomersOptions,
  getClientsOptions: actionCreators.getClientsOptions,
  reset: actionCreators.operationsReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(OperationsAll);
