import { createContext, useState, useMemo, useContext, ReactNode } from 'react';
import { Breadcrumb } from 'types/Breadcrumbs';

const defaultValue = {
  breadcrumbs: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBreadcrumbs: () => {},
};

interface BreadcrumbsContextProps {
  breadcrumbs: Breadcrumb[] | null;
  setBreadcrumbs: (payload: Breadcrumb[] | null) => void;
}

export const BreadcrumbsContext = createContext<BreadcrumbsContextProps>(defaultValue);

export function useBreadcrumbsContext() {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('useBreadcrumbsContext must be used within the BreadcrumbsContext');
  }
  return context;
}

interface BreadcrumbsContextProviderProps {
  children: ReactNode;
}

const BreadcrumbsContextProvider = ({ children }: BreadcrumbsContextProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const breadcrumbsContextValue = useMemo(
    () => ({
      breadcrumbs,
      setBreadcrumbs,
    }),
    [breadcrumbs],
  );

  return (
    // @ts-ignore
    <BreadcrumbsContext.Provider value={breadcrumbsContextValue}>{children}</BreadcrumbsContext.Provider>
  );
};

export default BreadcrumbsContextProvider;
