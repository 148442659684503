import { FormDataTypes } from 'common/constants/Form';

export const isObjectNotEmpty = (obj: any) => {
  return obj && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length > 0;
};

export const changeDotsToUnderscore = (str: string) => str.replace(/\./g, '_');

export const prepareColumns = (inputData: any, fieldName: string) => {
  const outputData = {};

  inputData[fieldName].forEach((item: any) => {
    if (!Object.prototype.hasOwnProperty.call(outputData, item.column)) {
      if ('isOverridable' in item) {
        // @ts-ignore
        outputData[item.column] = [{ ...item, groupName: item.name, hasParent: fieldName === 'childFieldGroups' }];
      } else {
        // @ts-ignore
        outputData[item.column] = [item];
      }
    } else {
      if ('isOverridable' in item) {
        // @ts-ignore
        outputData[item.column] = [
          // @ts-ignore
          ...outputData[item.column],
          { ...item, groupName: item.name, hasParent: fieldName === 'childFieldGroups' },
        ];
      } else {
        // @ts-ignore
        outputData[item.column] = [...outputData[item.column], item];
      }
    }
  });

  return outputData;
};

export const convertToColumns = (data: any) => ({
  groupsColumns: prepareColumns(data, 'fieldGroups'),
  fieldsColumns: prepareColumns(data, 'fields'),
});

export const convertToChildColumns = (data: any) => ({
  groupsColumns: prepareColumns(data, 'childFieldGroups'),
  fieldsColumns: prepareColumns(data, 'fields'),
});

const stringToBoolean = (value: string | boolean) => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    switch (value.toLowerCase().trim()) {
      case 'true':
        return true;
      case 'false':
        return false;
    }
  }

  return Boolean(value);
};

export const getValueByFieldType = (value: string, fieldId: string, allFields: any[]) => {
  const currentField = allFields.find((field: any) => field.fieldId === fieldId);

  switch (currentField?.dataType) {
    case FormDataTypes.STRING:
      return value ? value : '';
    case FormDataTypes.INTEGER:
      return value;
    case FormDataTypes.CRON:
      return value ? value : '';
    case FormDataTypes.BOOLEAN:
      return stringToBoolean(value);

    default:
      return value;
  }
};

const getFieldValueByType = (field: any, value: any) => {
  if (value === undefined) {
    return undefined;
  }
  if (field.dataType === FormDataTypes.BOOLEAN) {
    return stringToBoolean(value);
  }
  if (field.dataType === FormDataTypes.CRON && value === '') {
    return value;
  }

  return value ? value : undefined;
};

const getParentValue = (field: any, parentSettings: any) => {
  if (!parentSettings) {
    return undefined;
  }
  const parentItem = parentSettings.find((item: any) => item.name === field.name);

  return getFieldValueByType(field, parentItem?.value);
};

const getCurrentValue = (field: any, currentSettings: any) => {
  if (!currentSettings) {
    return undefined;
  }
  const currentItem = currentSettings.find((item: any) => item.name === field.name);

  return getFieldValueByType(field, currentItem?.value);
};

export const getExtendSchema = (schema: any) => {
  const parentProp = 'fieldGroups';
  const childProp = 'childFieldGroups';
  const targetProp = 'fields';
  isObjectNotEmpty(schema) &&
    schema.fieldGroups.length &&
    schema.fieldGroups.map((group: any) => {
      const groupName = group.name;

      const extendSchema = (sch: any, groupName: any) => {
        if (sch[targetProp] !== undefined && sch[targetProp].length !== 0) {
          sch[targetProp] = sch[targetProp].map((item: any) => ({
            ...item,
            fieldId: changeDotsToUnderscore(item.name),
            fieldGroup: groupName,
          }));
        }

        if (sch[childProp] !== undefined && sch[childProp].length !== 0) {
          sch[childProp] = sch[childProp].map((item: any) => extendSchema(item, groupName));
        }

        if (sch[parentProp] !== undefined && sch[parentProp].length !== 0) {
          sch[parentProp] = sch[parentProp].map((item: any) => extendSchema(item, groupName));
        }

        return sch;
      };
      return extendSchema(group, groupName);
    });

  return schema;
};

const getUpdatedFields = (fields: any, settings: any) => {
  return fields.map((field: any) => {
    const currentValue = getCurrentValue(field, settings.currentSettings);
    const parentValue = getParentValue(field, settings.parentSettings);
    return { ...field, parentValue, currentValue };
  });
};

export const getAllFieldsFromSchema = (
  schema: any,
  settings: any,
  parentProp = 'fieldGroups',
  childProp = 'childFieldGroups',
  targetProp = 'fields',
) => {
  const result = [] as any;
  const pushTargetPropToArray = (o: any) => {
    if (o[targetProp] !== undefined && o[targetProp].length !== 0) {
      const updatedFields = getUpdatedFields(o[targetProp], settings);
      result.push(...updatedFields);
    }
    if (o[childProp] !== undefined && o[childProp].length !== 0) {
      const updatedChildFields = getUpdatedFields(o[childProp], settings);
      result.push(...updatedChildFields);
    }

    if (o[childProp] !== undefined && o[childProp].length !== 0) {
      o[childProp].forEach((item: any) => pushTargetPropToArray(item));
    }
    if (o[parentProp] !== undefined && o[parentProp].length !== 0) {
      o[parentProp].forEach((item: any) => pushTargetPropToArray(item));
    }
  };
  pushTargetPropToArray(schema);

  return result;
};

// SAVE - EDIT Default Values
export const prepareDefaultValues = (
  isDefaultLevel: boolean,
  settings: any,
  create: boolean,
  allFields: any[],
  currentFormData: any[],
  formColumns: any,
  currentValues: any,
) => {
  const defaultValues = {};

  if (allFields.length === 0) {
    return {};
  }

  if (create && !isDefaultLevel) {
    //CREATE
    isObjectNotEmpty(formColumns.groupsColumns) &&
      Object.keys(formColumns.groupsColumns).map((key) => {
        return formColumns.groupsColumns[key].map((item: any) => {
          // @ts-ignore
          return (defaultValues[item.groupName] = currentValues[item.groupName]
            ? currentValues[item.groupName]
            : false);
        });
      });

    if (currentFormData.length) {
      currentFormData.forEach((item: any) => {
        // @ts-ignore
        defaultValues[item.fieldId] = getValueByFieldType(item.value, item.fieldId, allFields);
      });
    } else {
      allFields.forEach((item: any) => {
        // @ts-ignore
        defaultValues[item.fieldId] = item.parentValue
          ? item.parentValue
          : item.dataType === FormDataTypes.BOOLEAN
          ? false
          : '';
      });
    }
  } else {
    // EDIT
    isObjectNotEmpty(formColumns.groupsColumns) &&
      Object.keys(formColumns.groupsColumns).map((key) => {
        return formColumns.groupsColumns[key].map((item: any) => {
          // @ts-ignore
          return (defaultValues[item.groupName] = currentValues[item.groupName]
            ? currentValues[item.groupName]
            : false);
        });
      });

    if (currentFormData.length) {
      currentFormData.forEach((item: any) => {
        // @ts-ignore
        defaultValues[item.fieldId] = getValueByFieldType(item.value, item.fieldId, allFields);
      });
    } else {
      isObjectNotEmpty(formColumns.groupsColumns) &&
        Object.keys(formColumns.groupsColumns).map((key) => {
          return formColumns.groupsColumns[key].map((item: any) => {
            // @ts-ignore
            return (defaultValues[item.groupName] = false);
          });
        });
      settings.currentSettings.forEach((item: any) => {
        const isFieldOverride =
          !settings.parentSettings.length ||
          settings.parentSettings.find((parentSettingsItem: any) => {
            return parentSettingsItem.name === item.name && parentSettingsItem.value !== item.value;
          });

        if (isFieldOverride) {
          const overrideGroup = allFields.find((field: any) => field.fieldId === changeDotsToUnderscore(item.name));
          if (overrideGroup) {
            // @ts-ignore
            defaultValues[overrideGroup.fieldGroup] = true;
          }
        }
        // @ts-ignore
        defaultValues[changeDotsToUnderscore(item.name)] = getValueByFieldType(
          item.value,
          changeDotsToUnderscore(item.name), // change to fieldId
          allFields,
        );
      });
    }
  }

  return defaultValues;
};

export const getIsGroupOverridable = (section: any, configurationData: any) => {
  if (!section.parentId) {
    const allFields = getAllFieldsFromSchema(section, configurationData);
    const isOverridableExists = allFields.find((field: any) => field.isOverridable);

    return Boolean(isOverridableExists);
  }
  return false;
};

const getCurrentFormDataItem = ({
  isSectionOverride,
  fieldItem,
  switcherGroupFields,
  settings,
  currentValues,
}: any) => {
  const switcherGroupField = switcherGroupFields.find((field: any) => field.fieldId === fieldItem.fieldId);
  const getParentValue = () => {
    if (switcherGroupField.dataType === FormDataTypes.STRING || switcherGroupField.dataType === FormDataTypes.CRON) {
      return switcherGroupField.parentValue ? switcherGroupField.parentValue : '';
    }
    return switcherGroupField.parentValue;
  };

  if (isSectionOverride)
    if (settings.currentSettings.length) {
      // EDIT with isSectionOverride === true
      return {
        fieldId: fieldItem.fieldId,
        fieldGroup: fieldItem.fieldGroup,
        name: fieldItem.name,
        value:
          switcherGroupField && switcherGroupField.currentValue !== undefined
            ? switcherGroupField.currentValue
            : currentValues[fieldItem.fieldId],
      };
    } else {
      // CREATE with isSectionOverride === true
      return {
        fieldId: fieldItem.fieldId,
        fieldGroup: fieldItem.fieldGroup,
        name: fieldItem.name,
        value: currentValues[fieldItem.fieldId],
      };
    }

  // EDIT with isSectionOverride === false
  if (settings.currentSettings.length) {
    return {
      fieldId: fieldItem.fieldId,
      fieldGroup: fieldItem.fieldGroup,
      name: fieldItem.name,
      value: switcherGroupField ? getParentValue() : currentValues[fieldItem.fieldId],
    };
  }

  // CREATE with isSectionOverride === false
  return {
    fieldId: fieldItem.fieldId,
    fieldGroup: fieldItem.fieldGroup,
    name: fieldItem.name,
    value: switcherGroupField ? getParentValue() : currentValues[fieldItem.fieldId],
  };
};

export const getCurrentFormData = (
  isSectionOverride: any,
  allFields: any,
  settings: any,
  currentValues: any,
  section: any,
) => {
  const result: any = [];
  const switcherGroupName = section.groupName;
  const switcherGroupFields = allFields.filter((field: any) => field.fieldGroup === switcherGroupName);

  allFields.forEach((fieldItem: any) => {
    const getFormDataItem = getCurrentFormDataItem({
      isSectionOverride,
      fieldItem,
      switcherGroupFields,
      settings,
      currentValues,
    });
    result.push({
      ...getFormDataItem,
    });
  });

  return result;
};
