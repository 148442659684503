import { Wrapper, FlipToggleContainer, Input, FlipToggleLabels } from './styles';

interface FlipToggleProps {
  isChecked: boolean;
  isDisabled?: boolean;
  dataOn?: string;
  dataOff?: string;
  onChange: (event?: any) => void;
}

const FlipToggle = ({ dataOn, dataOff, isChecked, isDisabled, onChange }: FlipToggleProps) => {
  return (
    <Wrapper>
      <FlipToggleContainer isDisabled={isDisabled}>
        <Input type='checkbox' checked={isChecked} onChange={onChange} disabled={isDisabled} />
        <FlipToggleLabels data-on={dataOn || 'On'} data-off={dataOff || 'Off'} />
      </FlipToggleContainer>
    </Wrapper>
  );
};

export default FlipToggle;
