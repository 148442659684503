import { Icon } from 'types/Icon';

/* eslint-disable */
export const ZipIcon = ({ color, width, height }: Icon) => (
  <svg
    x='0px'
    y='0px'
    width={width || '550.801'}
    height={height || '550.801'}
    viewBox='0 0 550.801 550.801'
    xmlSpace='preserve'
  >
    <g fill={color || 'currentColor'}>
      <g>
        <path
          d='M475.095,131.992c-0.032-2.526-0.833-5.021-2.568-6.993L366.324,3.694c-0.021-0.034-0.053-0.045-0.084-0.076
			c-0.633-0.707-1.36-1.29-2.141-1.804c-0.232-0.15-0.465-0.285-0.707-0.422c-0.686-0.366-1.393-0.67-2.131-0.892
			c-0.2-0.058-0.379-0.14-0.58-0.192C359.87,0.114,359.047,0,358.203,0H97.2C85.292,0,75.6,9.693,75.6,21.601v507.6
			c0,11.913,9.692,21.601,21.6,21.601H453.6c11.918,0,21.601-9.688,21.601-21.601V133.202
			C475.2,132.796,475.137,132.398,475.095,131.992z M243.599,523.494H141.75v-15.936l62.398-89.797v-0.785h-56.565v-24.484h95.051
			v17.106l-61.038,88.636v0.771h62.002V523.494z M292.021,523.494h-29.744V392.492h29.744V523.494z M399.705,463.44
			c-10.104,9.524-25.069,13.796-42.566,13.796c-3.893,0-7.383-0.19-10.104-0.58v46.849h-29.352V394.242
			c9.134-1.561,21.958-2.721,40.036-2.721c18.277,0,31.292,3.491,40.046,10.494c8.354,6.607,13.996,17.486,13.996,30.322
			C411.761,445.163,407.479,456.053,399.705,463.44z M97.2,366.752V21.601h129.167v-3.396h32.756v3.396h88.28v110.515
			c0,5.961,4.831,10.8,10.8,10.8H453.6l0.011,223.836H97.2z'
        />
        <path
          d='M359.279,413.87c-6.033,0-10.114,0.586-12.245,1.171v38.676c2.521,0.585,5.632,0.785,9.914,0.785
			c15.736,0,25.46-7.979,25.46-21.378C382.408,421.063,374.045,413.87,359.279,413.87z'
        />
        <rect x='259.124' y='39.918' width='32.756' height='13.516' />
        <rect x='226.368' y='21.601' width='32.756' height='10.125' />
        <rect x='226.368' y='60.146' width='32.756' height='13.516' />
        <rect x='259.124' y='82.274' width='32.756' height='13.518' />
        <rect x='259.124' y='124.983' width='32.756' height='13.516' />
        <rect x='226.368' y='103.275' width='32.756' height='13.516' />
        <path
          d='M259.124,149.537c-23.193,0-34.225,18.792-34.225,41.99l-7.765,70.348c0,23.198,18.792,42.003,41.984,42.003
			c23.19,0,41.974-18.805,41.974-42.003l-7.741-70.348C293.361,168.334,282.318,149.537,259.124,149.537z M273.04,285.431h-27.799
			v-58.728h27.799V285.431z'
        />
      </g>
    </g>
  </svg>
);
/* eslint-enable */
