import styled from 'styled-components';
import { getPlaceholderColor } from 'common/components/Input/helpers';

interface LabelProps {
  isError?: boolean;
  isInputEmpty?: boolean;
  disabled?: boolean;
}

interface ErrorMessageProps {
  isError?: boolean;
  isPlaceholderHidden?: boolean;
}

export const Container = styled.div``;

export const Label = styled.label<LabelProps>`
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
  padding: 0 0 5px;
  color: ${({ theme, isError, disabled }) => getPlaceholderColor({ theme, isError, disabled })};
  z-index: 1;
  pointer-events: none;
`;

export const ErrorMessage = styled.div<ErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;

export const EditorContainer = styled.div``;

export const EditorWrapper = styled.div``;
