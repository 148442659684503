import styled from 'styled-components';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;

export const Content = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.backgroundPrimary};
  position: relative;
`;

export const FormWrapper = styled.form``;

export const FormSection = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  margin-top: 20px;
  padding: 15px;

  &:first-child {
    margin: 0;
  }
`;

export const FormSectionHeader = styled.div`
  position: relative;
  padding: 0 40px 12px 20px;
  margin: 0 -15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.borders.grey};
`;

export const FormSectionTitle = styled.h3`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormColumn = styled.div`
  width: 48%;
  flex: 0 0 48%;
`;

export const FormRow = styled.div`
  margin-bottom: 20px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;

  & > button {
    margin-left: 20px;
  }
`;
