import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import {
  ClientData,
  ClientIntegrationStatusRequest,
  ClientRequestDataExtended,
  ClientRequestParams,
  ClientStatusRequestData,
} from 'types/Client';
import {
  IntegrationData,
  ClientIntegrationsRequestParams,
  IntegrationSystem,
  ClientAvailableIntegrationsSystemRequestParams,
  IntegrationSchema,
  CreateClientIntegrationsRequestParams,
  ClientIntegrationBySystemRequestParams,
  ClientsConfigurationSchemaRequest,
  IntegrationSettingsDefault,
  IntegrationRequest,
  UpdateIntegrationRequest,
  TestConnectionRequest,
} from 'types/Integrations';
import { OperationData, OperationsRequestData, OperationsList, OperationsState } from 'types/Operations';
import { TableDateField, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { defaultFilterOptions } from 'common/constants';
import { FormSelectOption } from 'types/Form';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import {
  Schema,
  TransformationType,
  Transformation,
} from 'common/api/generated/PAITransformationApiClient';
import { CreateTransformationRequest, UpdateTransformationRequest } from 'common/api/generated/PAIAdminApiClient';

// Actions
export enum ActionTypeKeys {
  GET_CLIENT = 'CLIENT/GET_CLIENT',
  GET_CLIENT_SUCCESS = 'CLIENT/GET_CLIENT_SUCCESS',
  GET_CLIENT_FAILED = 'CLIENT/GET_CLIENT_FAILED',

  UPDATE_CLIENT = 'CLIENT/UPDATE_CLIENT',
  UPDATE_CLIENT_SUCCESS = 'CLIENT/UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAILED = 'CLIENT/UPDATE_CLIENT_FAILED',

  SET_CLIENT_STATUS = 'CLIENT/SET_CLIENT_STATUS',
  SET_CLIENT_STATUS_SUCCESS = 'CLIENT/SET_CLIENT_STATUS_SUCCESS',
  SET_CLIENT_STATUS_FAILED = 'CLIENT/SET_CLIENT_STATUS_FAILED',

  GET_CLIENT_INTEGRATIONS = 'CLIENT/GET_CLIENT_INTEGRATIONS',
  GET_CLIENT_INTEGRATIONS_SUCCESS = 'CLIENT/GET_CLIENT_INTEGRATIONS_SUCCESS',
  GET_CLIENT_INTEGRATIONS_FAILED = 'CLIENT/GET_CLIENT_INTEGRATIONS_FAILED',

  GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES = 'CLIENT/GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES',
  GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_SUCCESS = 'CLIENT/GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_SUCCESS',
  GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_FAILED = 'CLIENT/GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_FAILED',

  GET_CLIENT_TRANSFORMATION_SCHEMA = 'CLIENT/GET_CLIENT_TRANSFORMATION_SCHEMA',
  GET_CLIENT_TRANSFORMATION_SCHEMA_SUCCESS = 'CLIENT/GET_CLIENT_TRANSFORMATION_SCHEMA_SUCCESS',
  GET_CLIENT_TRANSFORMATION_SCHEMA_FAILED = 'CLIENT/GET_CLIENT_TRANSFORMATION_SCHEMA_FAILED',

  GET_CLIENT_TRANSFORMATIONS = 'CLIENT/GET_CLIENT_TRANSFORMATIONS',
  GET_CLIENT_TRANSFORMATIONS_SUCCESS = 'CLIENT/GET_CLIENT_TRANSFORMATIONS_SUCCESS',
  GET_CLIENT_TRANSFORMATIONS_FAILED = 'CLIENT/GET_CLIENT_TRANSFORMATIONS_FAILED',

  GET_CLIENT_TRANSFORMATION = 'CLIENT/GET_CLIENT_TRANSFORMATION',
  GET_CLIENT_TRANSFORMATION_SUCCESS = 'CLIENT/GET_CLIENT_TRANSFORMATION_SUCCESS',
  GET_CLIENT_TRANSFORMATION_FAILED = 'CLIENT/GET_CLIENT_TRANSFORMATION_FAILED',

  CREATE_CLIENT_TRANSFORMATION = 'CLIENT/CREATE_CLIENT_TRANSFORMATION',
  CREATE_CLIENT_TRANSFORMATION_SUCCESS = 'CLIENT/CREATE_CLIENT_TRANSFORMATION_SUCCESS',
  CREATE_CLIENT_TRANSFORMATION_FAILED = 'CLIENT/CREATE_CLIENT_TRANS FORMATION_FAILED',

  UPDATE_CLIENT_TRANSFORMATION = 'CLIENT/UPDATE_CLIENT_TRANSFORMATION',
  UPDATE_CLIENT_TRANSFORMATION_SUCCESS = 'CLIENT/UPDATE_CLIENT_TRANSFORMATION_SUCCESS',
  UPDATE_CLIENT_TRANSFORMATION_FAILED = 'CLIENT/UPDATE_CLIENT_TRANSFORMATION_FAILED',

  UPDATE_CLIENT_TRANSFORMATION_STATUS = 'CLIENT/UPDATE_CLIENT_TRANSFORMATION_STATUS',
  UPDATE_CLIENT_TRANSFORMATION_STATUS_SUCCESS = 'CLIENT/UPDATE_CLIENT_TRANSFORMATION_STATUS_SUCCESS',
  UPDATE_CLIENT_TRANSFORMATION_STATUS_FAILED = 'CLIENT/UPDATE_CLIENT_TRANSFORMATION_STATUS_FAILED',

  GET_CLIENT_AVAILABLE_SYSTEMS = 'CLIENT/GET_CLIENT_AVAILABLE_SYSTEMS',
  GET_CLIENT_AVAILABLE_SYSTEMS_SUCCESS = 'CLIENT/GET_CLIENT_AVAILABLE_SYSTEMS_SUCCESS',
  GET_CLIENT_AVAILABLE_SYSTEMS_FAILED = 'CLIENT/GET_CLIENT_AVAILABLE_SYSTEMS_FAILED',

  GET_CLIENT_OPERATIONS_BY_CLIENT_ID = 'CLIENT/GET_CLIENT_OPERATIONS_BY_CLIENT_ID',
  GET_CLIENT_OPERATIONS_BY_CLIENT_ID_SUCCESS = 'CLIENT/GET_CLIENT_OPERATIONS_BY_CLIENT_ID_SUCCESS',
  GET_CLIENT_OPERATIONS_BY_CLIENT_ID_FAILED = 'CLIENT/GET_CLIENT_OPERATIONS_BY_CLIENT_ID_FAILED',

  SET_OPERATIONS_TABLE_PAGE = 'CLIENT/SET_OPERATIONS_TABLE_PAGE',
  SET_OPERATIONS_TABLE_PER_PAGE = 'CLIENT/SET_OPERATIONS_TABLE_PER_PAGE',
  SET_OPERATIONS_TABLE_SORT_ORDER = 'CLIENT/SET_OPERATIONS_TABLE_SORT_ORDER',
  SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE',
  SET_OPERATIONS_TABLE_FILTER_OPERATION = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_OPERATION',
  SET_OPERATIONS_TABLE_FILTER_STATUS = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_STATUS',
  SET_OPERATIONS_TABLE_FILTER_TRACE_ID = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_TRACE_ID',
  SET_OPERATIONS_TABLE_FILTER_DATE_FROM = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_DATE_FROM',
  SET_OPERATIONS_TABLE_FILTER_DATE_TO = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_DATE_TO',
  SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID',
  SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID = 'CLIENT/SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID',

  GET_CLIENT_CONFIGURATION_SCHEMA = 'CLIENT/GET_CLIENT_CONFIGURATION_SCHEMA',
  GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS = 'CLIENT/GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS',
  GET_CLIENT_CONFIGURATION_SCHEMA_FAILED = 'CLIENT/GET_CLIENT_CONFIGURATION_SCHEMA_FAILED',

  GET_CLIENT_SYSTEM_SETTINGS = 'CLIENT/GET_CUSTOMER_SYSTEM_SETTINGS',
  GET_CLIENT_SYSTEM_SETTINGS_SUCCESS = 'CLIENT/GET_CUSTOMER_SYSTEM_SETTINGS_SUCCESS',
  GET_CLIENT_SYSTEM_SETTINGS_FAILED = 'CLIENT/GET_CUSTOMER_SYSTEM_SETTINGS_FAILED',

  GET_CLIENT_INTEGRATION = 'CLIENT/GET_CLIENT_INTEGRATION',
  GET_CLIENT_INTEGRATION_SUCCESS = 'CLIENT/GET_CLIENT_INTEGRATION_SUCCESS',
  GET_CLIENT_INTEGRATION_FAILED = 'CLIENT/GET_CLIENT_INTEGRATION_FAILED',

  CREATE_CLIENT_INTEGRATION = 'CLIENT/CREATE_CLIENT_INTEGRATION',
  CREATE_CLIENT_INTEGRATION_SUCCESS = 'CLIENT/CREATE_CLIENT_INTEGRATION_SUCCESS',
  CREATE_CLIENT_INTEGRATION_FAILED = 'CLIENT/CREATE_CLIENT_INTEGRATION_FAILED',

  UPDATE_CLIENT_INTEGRATION = 'CLIENT/UPDATE_CLIENT_INTEGRATION',
  UPDATE_CLIENT_INTEGRATION_SUCCESS = 'CLIENT/UPDATE_CLIENT_INTEGRATION_SUCCESS',
  UPDATE_CLIENT_INTEGRATION_FAILED = 'CLIENT/UPDATE_CLIENT_INTEGRATION_FAILED',

  UPDATE_CLIENT_INTEGRATION_STATUS = 'CLIENT/UPDATE_CLIENT_INTEGRATION_STATUS',
  UPDATE_CLIENT_INTEGRATION_STATUS_SUCCESS = 'CLIENT/UPDATE_CLIENT_INTEGRATION_STATUS_SUCCESS',
  UPDATE_CLIENT_INTEGRATION_STATUS_FAILED = 'CLIENT/UPDATE_CLIENT_INTEGRATION_STATUS_FAILED',

  GET_CLIENT_TEST_CONNECTION = 'CUSTOMERS/GET_CLIENT_TEST_CONNECTION',
  GET_CLIENT_TEST_CONNECTION_SUCCESS = 'CUSTOMERS/GET_CLIENT_TEST_CONNECTION_SUCCESS',
  GET_CLIENT_TEST_CONNECTION_FAILED = 'CUSTOMERS/GET_CLIENT_TEST_CONNECTION_FAILED',

  CLIENT_RESET = 'CLIENT/CLIENT_RESET',
  CLIENT_RESET_PARTIAL = 'CLIENT/CLIENT_RESET_PARTIAL',

  INTEGRATION_RESET = 'CLIENT/INTEGRATION_RESET',
  INTEGRATIONS_RESET = 'CLIENT/INTEGRATIONS_RESET',

  CLIENT_OPERATIONS_RESET = 'CLIENT/CLIENT_OPERATIONS_RESET',
}

// ActionCreators
export const actionCreators = {
  getClient: (payload: ClientRequestParams) => createAction(ActionTypeKeys.GET_CLIENT, payload),
  getClientSuccess: (payload: ClientData) => createAction(ActionTypeKeys.GET_CLIENT_SUCCESS, payload),
  getClientFailed: () => createAction(ActionTypeKeys.GET_CLIENT_FAILED),

  updateClient: (payload: ClientRequestDataExtended) => createAction(ActionTypeKeys.UPDATE_CLIENT, payload),
  updateClientSuccess: (payload: ClientData) => createAction(ActionTypeKeys.UPDATE_CLIENT_SUCCESS, payload),
  updateClientFailed: (payload: number) => createAction(ActionTypeKeys.UPDATE_CLIENT_FAILED, payload),

  setClientStatus: (payload: ClientStatusRequestData) => createAction(ActionTypeKeys.SET_CLIENT_STATUS, payload),
  setClientStatusSuccess: () => createAction(ActionTypeKeys.SET_CLIENT_STATUS_SUCCESS),
  setClientStatusFailed: () => createAction(ActionTypeKeys.SET_CLIENT_STATUS_FAILED),

  getClientOperations: (payload: OperationsRequestData) =>
    createAction(ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID, payload),
  getClientOperationsSuccess: (payload: OperationsList) =>
    createAction(ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID_SUCCESS, payload),
  getClientOperationsFailed: () => createAction(ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID_FAILED),

  // operations

  setOperationsTablePage: (payload: number) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_PAGE, payload),
  setOperationsTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_PER_PAGE, payload),
  setOperationsTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_SORT_ORDER, payload),
  setOperationsFilterOperationScope: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE, payload),
  setOperationsFilterOperation: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION, payload),
  setOperationsFilterStatus: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_STATUS, payload),
  setOperationsFilterTraceId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TRACE_ID, payload),
  setFilterDateFrom: (payload: null | Date) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_FROM, payload),
  setFilterDateTo: (payload: null | Date) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_TO, payload),
  setFilterSourceEntityId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID, payload),
  setFilterTargetEntityId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID, payload),

  // configuration schema

  getClientConfigurationSchema: (payload: ClientsConfigurationSchemaRequest) =>
    createAction(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA, payload),
  getClientConfigurationSchemaSuccess: (payload: IntegrationSchema) =>
    createAction(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS, payload),
  getClientConfigurationSchemaFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_FAILED, payload),

  // system settings

  getClientSystemSettings: (payload: ClientIntegrationBySystemRequestParams) =>
    createAction(ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS, payload),
  getClientSystemSettingsSuccess: (payload: IntegrationSettingsDefault) =>
    createAction(ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS_SUCCESS, payload),
  getClientSystemSettingsFailed: () => createAction(ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS_FAILED),

  // integrations

  getClientAvailableSystems: (payload: ClientAvailableIntegrationsSystemRequestParams) =>
    createAction(ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS, payload),
  getClientAvailableSystemsSuccess: (payload: IntegrationSystem[]) =>
    createAction(ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS_SUCCESS, payload),
  getClientAvailableSystemsFailed: () => createAction(ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS_FAILED),

  getClientIntegrations: (payload: ClientIntegrationsRequestParams) =>
    createAction(ActionTypeKeys.GET_CLIENT_INTEGRATIONS, payload),
  getClientIntegrationsSuccess: (payload: IntegrationData[]) =>
    createAction(ActionTypeKeys.GET_CLIENT_INTEGRATIONS_SUCCESS, payload),
  getClientIntegrationsFailed: () => createAction(ActionTypeKeys.GET_CLIENT_INTEGRATIONS_FAILED),

  getClientIntegration: (payload: IntegrationRequest) => createAction(ActionTypeKeys.GET_CLIENT_INTEGRATION, payload),
  getClientIntegrationSuccess: (payload: IntegrationData) =>
    createAction(ActionTypeKeys.GET_CLIENT_INTEGRATION_SUCCESS, payload),
  getClientIntegrationFailed: () => createAction(ActionTypeKeys.GET_CLIENT_INTEGRATION_FAILED),

  createClientIntegration: (payload: CreateClientIntegrationsRequestParams) =>
    createAction(ActionTypeKeys.CREATE_CLIENT_INTEGRATION, payload),
  createClientIntegrationSuccess: () => createAction(ActionTypeKeys.CREATE_CLIENT_INTEGRATION_SUCCESS),
  createClientIntegrationFailed: (payload: number) =>
    createAction(ActionTypeKeys.CREATE_CLIENT_INTEGRATION_FAILED, payload),

  updateClientIntegration: (payload: UpdateIntegrationRequest) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION, payload),
  updateClientIntegrationSuccess: (payload: IntegrationData) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_SUCCESS, payload),
  updateClientIntegrationFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_FAILED, payload),

  updateClientIntegrationStatus: (payload: ClientIntegrationStatusRequest) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS, payload),
  updateClientIntegrationStatusSuccess: (
    payload: Pick<ClientIntegrationStatusRequest, 'isProfilePage' | 'integrationId'>,
  ) => createAction(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS_SUCCESS, payload),
  updateClientIntegrationStatusFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS_FAILED, payload),

  // transformations

  getClientAvailableTransformationTypes: (payload: { accessToken: string }) =>
    createAction(ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES, payload),
  getClientAvailableTransformationTypesSuccess: (payload: TransformationType[]) =>
    createAction(ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_SUCCESS, payload),
  getClientAvailableTransformationTypesFailed: () =>
    createAction(ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_FAILED),

  getClientTransformationSchema: (payload: { accessToken: string; typeId: string }) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA, payload),
  getClientTransformationSchemaSuccess: (payload: Schema) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA_SUCCESS, payload),
  getClientTransformationSchemaFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA_FAILED, payload),

  getClientTransformations: (payload: { accessToken: string; customerId: number; clientId: number }) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS, payload),
  getClientTransformationsSuccess: (payload: Transformation[]) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS_SUCCESS, payload),
  getClientTransformationsFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS_FAILED, payload),

  getClientTransformation: (payload: { accessToken: string; transformationId: string }) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATION, payload),
  getClientTransformationSuccess: (payload: Transformation) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SUCCESS, payload),
  getClientTransformationFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CLIENT_TRANSFORMATION_FAILED, payload),

  createClientTransformation: (payload: {
    accessToken: string;
    customerId: number;
    clientId: number;
    request: CreateTransformationRequest;
  }) => createAction(ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION, payload),
  createClientTransformationSuccess: (payload: any) =>
    createAction(ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION_SUCCESS, payload),
  createClientTransformationFailed: (payload: number) =>
    createAction(ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION_FAILED, payload),

  updateClientTransformation: (payload: {
    accessToken: string;
    transformationId: string;
    request: UpdateTransformationRequest;
  }) => createAction(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION, payload),
  updateClientTransformationSuccess: (payload: any) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_SUCCESS, payload),
  updateClientTransformationFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_FAILED, payload),

  updateClientTransformationStatus: (payload: { accessToken: string; transformationId: string; active: boolean }) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS, payload),
  updateClientTransformationStatusSuccess: (payload: any) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS_SUCCESS, payload),
  updateClientTransformationStatusFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS_FAILED, payload),

  // test connection

  getClientTestConnection: (payload: TestConnectionRequest) =>
    createAction(ActionTypeKeys.GET_CLIENT_TEST_CONNECTION, payload),
  getClientTestConnectionSuccess: () => createAction(ActionTypeKeys.GET_CLIENT_TEST_CONNECTION_SUCCESS),
  getClientTestConnectionFailed: () => createAction(ActionTypeKeys.GET_CLIENT_TEST_CONNECTION_FAILED),

  clientReset: () => createAction(ActionTypeKeys.CLIENT_RESET),
  clientResetPartial: () => createAction(ActionTypeKeys.CLIENT_RESET_PARTIAL),
  integrationReset: () => createAction(ActionTypeKeys.INTEGRATION_RESET),
  integrationsReset: () => createAction(ActionTypeKeys.INTEGRATIONS_RESET),
  clientOperationsReset: () => createAction(ActionTypeKeys.CLIENT_OPERATIONS_RESET),
};

// State
export interface State {
  isFetching: boolean;
  isTestConnectionFetching: boolean;
  isModalFetching: boolean;

  client: ClientData;
  errorCode: number | null;

  availableTransformationTypes: TransformationType[];
  transformations: Transformation[];
  transformation: Transformation;
  transformationSchema: Schema;

  availableSystems: IntegrationSystem[];
  integrations: IntegrationData[];
  integration: IntegrationData;
  integrationSchema: IntegrationSchema;

  operations: OperationsState;
}

export const initialState: State = {
  isFetching: false,
  isTestConnectionFetching: false,
  isModalFetching: false,
  client: {} as ClientData,
  availableTransformationTypes: [] as TransformationType[],
  transformations: [] as Transformation[],
  transformation: {} as Transformation,
  transformationSchema: {} as Schema,
  availableSystems: [] as IntegrationSystem[],
  integrations: [] as IntegrationData[],
  integration: {} as IntegrationData,
  integrationSchema: {} as IntegrationSchema,
  operations: {
    data: [] as OperationData[],
    totalCount: 0,
    page: 1,
    perPage: ITEMS_PER_PAGE_COUNT,
    orderBy: TableDateField.STARTED_DATE_TIME,
    sortOrder: TableSortOrder.DESCENDING,
    filterScope: [defaultFilterOptions],
    filterType: [defaultFilterOptions],
    filterStatus: [defaultFilterOptions],
    filterDateFrom: null,
    filterDateTo: null,
    filterTraceId: '',
    filterSourceEntityId: '',
    filterTargetEntityId: '',
  },
  errorCode: null,
};

// Reducer
export const clientReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CLIENT:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        client: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.UPDATE_CLIENT:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        client: action.payload,
        errorCode: null,
      };

    case ActionTypeKeys.UPDATE_CLIENT_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.SET_CLIENT_STATUS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.SET_CLIENT_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_CLIENT_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CLIENT_INTEGRATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integrations: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_INTEGRATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        availableTransformationTypes: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transformationSchema: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transformations: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transformation: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TRANSFORMATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transformation: action.payload,
      };

    case ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transformation: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transformation: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        operations: {
          ...state.operations,
          data: action.payload.data,
          totalCount: action.payload.totalCount,
        },
      };

    case ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_PAGE:
      return {
        ...state,
        operations: {
          ...state.operations,
          page: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_PER_PAGE:
      return {
        ...state,
        operations: {
          ...state.operations,
          perPage: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_SORT_ORDER:
      return {
        ...state,
        operations: {
          ...state.operations,
          orderBy: action.payload.orderBy,
          sortOrder: action.payload.sortOrder,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterScope: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterType: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_STATUS:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterStatus: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TRACE_ID:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterTraceId: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_FROM:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterDateFrom: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_TO:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterDateTo: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterSourceEntityId: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterTargetEntityId: action.payload,
        },
      };

    case ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA:
      return {
        ...state,
        integrationSchema: initialState.integrationSchema,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integrationSchema: action.payload,
        errorCode: null,
      };

    case ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS:
    case ActionTypeKeys.GET_CLIENT_INTEGRATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS_SUCCESS:
    case ActionTypeKeys.GET_CLIENT_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integration: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS_FAILED:
    case ActionTypeKeys.GET_CLIENT_INTEGRATION_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        availableSystems: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.CREATE_CLIENT_INTEGRATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.CREATE_CLIENT_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.CREATE_CLIENT_INTEGRATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_INTEGRATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integration: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { integrationId, isProfilePage } = action.payload;
      if (!isProfilePage) {
        return {
          ...state,
          isFetching: false,
          integrations: state.integrations.map((integration) => {
            if (integration.id === integrationId) {
              return {
                ...integration,
                isActive: !integration.isActive,
              };
            } else {
              return {
                ...integration,
              };
            }
          }),
        };
      } else {
        return {
          ...state,
          isFetching: false,
        };
      }

    case ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CLIENT_TEST_CONNECTION:
      return {
        ...state,
        isTestConnectionFetching: true,
      };

    case ActionTypeKeys.GET_CLIENT_TEST_CONNECTION_SUCCESS:
      return {
        ...state,
        isTestConnectionFetching: false,
      };

    case ActionTypeKeys.GET_CLIENT_TEST_CONNECTION_FAILED:
      return {
        ...state,
        isTestConnectionFetching: false,
      };

    case ActionTypeKeys.CLIENT_RESET:
      return {
        ...initialState,
      };

    case ActionTypeKeys.CLIENT_RESET_PARTIAL:
      return {
        ...state,
        errorCode: null,
      };

    case ActionTypeKeys.INTEGRATION_RESET:
      return {
        ...state,
        integration: initialState.integration,
        integrationSchema: initialState.integrationSchema,
      };

    case ActionTypeKeys.INTEGRATIONS_RESET:
      return {
        ...state,
        isFetching: false,
        integrations: initialState.integrations,
      };

    case ActionTypeKeys.CLIENT_OPERATIONS_RESET:
      return {
        ...state,
        isFetching: false,
        operations: initialState.operations,
      };

    default:
      return state;
  }
};
