import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavigationWrapper = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

interface NavigationTitleProps {
  isMainNavCollapsed?: boolean;
}

export const NavigationTitle = styled.h3<NavigationTitleProps>`
  display: block;
  font-size: 12px;
  min-height: 38px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontSecondary};
  padding: 10px 24px;
  margin: 0;
  letter-spacing: 1px;
  display: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? 'none' : 'block')};

  & > span {
    display: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? 'none' : 'block')};
  }
`;

export const CollapsedNavigationTitle = styled(NavigationTitle)`
  padding: 10px 5px;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.5px;
  display: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? 'block' : 'none')};

  & > span {
    display: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? 'block' : 'none')};
  }
`;

export const NavList = styled.div``;

export const NavItem = styled.div`
  display: block;
  white-space: nowrap;
`;

interface IconContainerProps {
  isMainNavCollapsed?: boolean;
}

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '0 auto' : '-2px 5px 0 0')};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

interface NavItemTextProps {
  isMainNavCollapsed?: boolean;
}

export const NavItemText = styled.div<NavItemTextProps>`
  display: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? 'none' : 'block')};
  white-space: nowrap;
`;

const activeClassName = 'active';

interface NavLinkStyledType {
  darktheme: string;
  collapsed: string;
}

const getNavLinkColor = (theme: any, collapsed: string, darktheme: string) => {
  if (darktheme === 'true') {
    return collapsed === 'true' ? theme.sidebar.fontSecondary : theme.sidebar.fontSecondary;
  }

  return collapsed === 'true' ? theme.sidebar.fontSecondary : theme.sidebar.fontPrimary;
};

export const NavLinkStyled = styled(NavLink).attrs({ activeClassName })<NavLinkStyledType>`
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transform: translateZ(0) scale(1, 1);
  transition: width 0.1s linear;
  text-decoration: none;
  color: ${({ theme, collapsed, darktheme }) => getNavLinkColor(theme, collapsed, darktheme)};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  &:hover ${IconContainer} {
    color: ${({ theme }) => theme.primary};
  }

  &.${activeClassName} {
    background-color: ${({ theme }) => theme.sidebar.activeBackgroundPrimary};
    color: ${({ theme }) => theme.sidebar.fontSecondary};
    cursor: pointer;
  }

  &.${activeClassName} ${IconContainer} {
    color: ${({ theme }) => theme.sidebar.fontSecondary};
  }

  &.${activeClassName} ${NavItemText} {
    color: ${({ theme }) => theme.sidebar.fontSecondary};
  }
`;

interface NavLinkInnerType {
  isMainNavCollapsed: boolean;
}

export const NavLinkInner = styled.div<NavLinkInnerType>`
  flex: 1;
  display: flex;
  align-items: center;
  padding: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '12px' : '12px 24px')};
`;

interface NavItemLabelType {
  isMainNavCollapsed?: boolean;
}

export const NavItemLabel = styled.span<NavItemLabelType>`
  font-size: 9px;
  line-height: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '12px' : '18px')};
  font-weight: 500;
  display: block;
  padding: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '2px 4px' : '1px 6px')};
  min-width: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '16px' : '18px')};
  text-align: center;
  border-radius: 4px;
  position: absolute;
  right: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '50%' : '12px')};
  top: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? '6px' : '12px')};
  transform: ${({ isMainNavCollapsed }) => (isMainNavCollapsed ? 'translateX(93%)' : 'translateX(0)')};
  color: ${({ theme }) => theme.sidebar.fontSecondary};
  background: ${({ theme }) => theme.primary};
`;
