import { IntegrationData, IntegrationSchema, IntegrationsLevel, IntegrationsSystems } from 'types/Integrations';
import { ConfigurationLevel } from 'types/Configuration';
import ConfigurationForm from 'components/ConfigurationForm';

interface IntegrationFormProps {
  isFetching: boolean;
  errorCode: number | null;
  integrationName: IntegrationsSystems;
  integration: IntegrationData;
  integrationSchema: IntegrationSchema;
  level: IntegrationsLevel;
  withConfirmation?: boolean;
  onSubmit: (payload: any) => void;
  onCancel?: () => void;
  testConnection?: () => void;
  resetForm: () => void;
}

const IntegrationForm = ({
  isFetching,
  errorCode,
  level,
  integration,
  integrationName,
  integrationSchema,
  onCancel,
  onSubmit,
  resetForm,
  withConfirmation,
  testConnection,
}: IntegrationFormProps) => {
  const isDefaultLevel = level === IntegrationsLevel.DEFAULT;
  const isEditPage = isDefaultLevel || !!integration.id;

  let configLevel = ConfigurationLevel.UNKNOWN;
  if (level === IntegrationsLevel.DEFAULT) configLevel = ConfigurationLevel.DEFAULT;
  if (level === IntegrationsLevel.CUSTOMER) configLevel = ConfigurationLevel.CUSTOMER;
  if (level === IntegrationsLevel.CLIENT) configLevel = ConfigurationLevel.CLIENT;

  if (integrationName === IntegrationsSystems.UNKNOWN) {
    return null;
  }

  if (!integration?.settings) {
    return <></>;
  }

  return (
    <ConfigurationForm
      isFetching={isFetching}
      errorCode={errorCode}
      level={configLevel}
      isEdit={isEditPage}
      name={integrationName}
      schema={integrationSchema}
      settings={integration.settings}
      showTestConnectionButton={integration.showTestConnectionButton}
      withConfirmation={withConfirmation}
      testConnection={testConnection}
      onSubmit={onSubmit}
      onCancel={onCancel}
      resetForm={resetForm}
    />
  );
};

export default IntegrationForm;
