import { Controller } from 'react-hook-form';
import { Message } from 'common/components/Message';
import { Switcher } from 'common/components/Switcher';
import { getIsGroupOverridable } from '../utils';
import { FormRowSwitcher, FormRowSwitcherLabel, FormTitle } from '../styles';

const SectionGroupItem = ({
  group,
  level,
  control,
  watch,
  isFormDisabled,
  onSwitchChangeHandler,
  getSection,
  configurationData,
}: any) => {
  const isGroupOverridable = getIsGroupOverridable(group, configurationData);
  const isSectionOverride = watch(group.groupName);

  return (
    <div>
      <FormTitle>{group.name ? <Message id={group.name} /> : group.name}</FormTitle>
      {isGroupOverridable && (
        <FormRowSwitcher>
          <FormRowSwitcherLabel>
            <Message
              id='override-settings-for-current'
              values={{
                variable: level.toLowerCase(),
              }}
            />
          </FormRowSwitcherLabel>
          <Controller
            control={control}
            name={group.groupName}
            defaultValue={false}
            render={({ field }) => {
              return (
                <Switcher
                  leftLabel='No'
                  rightLabel='Yes'
                  isChecked={field.value}
                  isDisable={isFormDisabled}
                  onChange={(value) => {
                    onSwitchChangeHandler(value, group);
                    return field.onChange(value);
                  }}
                  switcherRef={field.ref}
                />
              );
            }}
          />
        </FormRowSwitcher>
      )}
      {getSection(group, isSectionOverride)}
    </div>
  );
};

export default SectionGroupItem;
