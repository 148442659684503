import { APP_URLS } from 'common/constants';
import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';

const getClientsAllBreadcrumbs = () => [
  {
    title: BREADCRUMBS_TITLES.GENERAL,
    url: APP_URLS.customers,
  },
  {
    title: BREADCRUMBS_TITLES.CLIENTS,
    isActive: true,
  },
];

export default getClientsAllBreadcrumbs;
