import { ReactElement, useMemo } from 'react';
import { Message, Spinner, Modal, Button } from 'common/components';
import { FormButtonsCentered } from '../styles';

interface ImportModalProps {
  isFetching: boolean;
  withScroll: boolean;
  isConfirmButtonDisabled: boolean;
  modalTitle: ReactElement;
  modalBody: ReactElement;
  onModalConfirmHandler: () => void;
  onModalCancelHandler: () => void;
}

const ImportModal = ({
  isFetching,
  withScroll,
  isConfirmButtonDisabled,
  modalTitle,
  modalBody,
  onModalCancelHandler,
  onModalConfirmHandler,
}: ImportModalProps) => {
  const renderFooterButtons = useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onModalCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button primary onClick={onModalConfirmHandler} disabled={isConfirmButtonDisabled}>
          <Message id='clients-screen-import-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onModalCancelHandler, isConfirmButtonDisabled, onModalConfirmHandler],
  );

  return (
    <Modal
      withScroll={withScroll}
      size={'large'}
      verticalAlignment={'top'}
      isFetching={isFetching}
      isVisible={true}
      loadingComponent={Spinner}
      title={modalTitle}
      closeOnEsc={false}
      hideOnOverlayClicked={false}
      footer={renderFooterButtons}
      onCloseClicked={onModalCancelHandler}
    >
      {modalBody}
    </Modal>
  );
};

export default ImportModal;
