import styled from 'styled-components';

export const UserDataWrapper = styled.div`
  display: flex;
  padding: 10px;
`;
export const UserDataAvatar = styled.div`
  font-size: 9px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.white};
  width: 36px;
  min-width: 36px;
  height: 36px;
  background: #a1abb7;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
`;
export const UserDataContent = styled.div`
  text-align: left;
  min-width: 90px;
`;
export const UserName = styled.div`
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
`;
export const UserLink = styled.span`
  font-size: 11px;
  line-height: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.fontSecondary};
`;
