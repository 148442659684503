import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchInputFocus, useAccessToken } from 'hooks';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { ClientData, ClientsRequestData } from 'types/Client';
import { Message, Spinner, DataTable, Paginator, Select, Input, Button, Switcher } from 'common/components';
import { FormSelectOption } from 'types/Form';
import { getArrayOfOptionsValues, getSelectedMultiOptions } from 'utils';
import { IntegrationSystemOptions } from 'types/Integrations';
import { RefreshIcon } from 'common/components/Icons';
import { Status } from 'types/Status';
import { getAvailableStatuses } from 'utils/formHelpers';
import getNotificationsBreadcrumbs from './getClientsAllBreadcrumbs';
import clientsAllTableColumns from './clientsAllTableColumns';
import {
  FiltersColumn,
  FiltersColumnWide,
  FiltersLeft,
  FiltersRight,
  FiltersWrapper,
  Head,
  Title,
  Wrapper,
  FilterRow,
  FilterRowColumn,
} from './styles';

interface ClientsAllProps {
  isFetching: boolean;
  isIncludeDeactivated: boolean;
  showOnlyDeactivatedSystems: boolean;
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  filterSearch: string;
  filterStatus: FormSelectOption[];
  clients: ClientData[];
  sortOrder: TableSortOrder;
  filterSystem: IntegrationSystemOptions[];
  connectedSystemsOptions: IntegrationSystemOptions[];
  clientStatusOptions: FormSelectOption[];
  getClientsAll: (payload: ClientsRequestData) => void;
  setTablePage: (payload: number) => void;
  setTablePerPage: (payload: number) => void;
  setFilterStatus: (payload: FormSelectOption[]) => void;
  setFilterSystem: (payload: IntegrationSystemOptions[]) => void;
  setFilterSearch: (payload: string) => void;
  setTableOrder: (payload: TablesSortOrderPayload) => void;
  setIsIncludeDeactivated: (payload: boolean) => void;
  setShowOnlyDeactivatedSystems: (payload: boolean) => void;
  reset: () => void;
}

const ClientsAll = ({
  isFetching,
  isIncludeDeactivated,
  showOnlyDeactivatedSystems,
  totalCount,
  page,
  perPage,
  orderBy,
  clients,
  sortOrder,
  filterSystem,
  filterStatus,
  filterSearch,
  connectedSystemsOptions,
  clientStatusOptions,
  getClientsAll,
  setTablePage,
  setTablePerPage,
  setFilterStatus,
  setFilterSystem,
  setFilterSearch,
  setTableOrder,
  setIsIncludeDeactivated,
  setShowOnlyDeactivatedSystems,
  reset,
}: ClientsAllProps) => {
  const accessToken = useAccessToken();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isFiltersDisabled, setFiltersDisabled] = useState(true);
  const [setIsSearchInputChanged, inputRef, setSearchInputFocus] = useSearchInputFocus();
  const requestData = {
    accessToken,
    page: 1,
    size: Number(perPage),
    orderBy: orderBy,
    sortOrder: sortOrder,
    shouldContainDeactivatedSystem: showOnlyDeactivatedSystems,
    statuses:
      filterStatus[0].value === clientStatusOptions[0].value
        ? getAvailableStatuses(isIncludeDeactivated, clientStatusOptions)
        : getArrayOfOptionsValues(filterStatus),
    systems:
      filterSystem[0].value === connectedSystemsOptions[0].value ? undefined : getArrayOfOptionsValues(filterSystem),
    search: filterSearch ? filterSearch : undefined,
  };
  const statusFilterOptions = !isIncludeDeactivated
    ? clientStatusOptions.filter((option: FormSelectOption) => option.value !== Status.DEACTIVATED)
    : clientStatusOptions;

  useEffect(() => {
    setSearchInputFocus();
  }, [isFetching, setSearchInputFocus]);

  useEffect(() => {
    setFiltersDisabled(
      isFetching ||
        (!totalCount &&
          filterSystem[0].value === connectedSystemsOptions[0].value &&
          filterStatus[0].value === clientStatusOptions[0].value),
    );
  }, [isFetching, totalCount, filterSystem, filterStatus, connectedSystemsOptions, clientStatusOptions]);

  useEffect(() => {
    setBreadcrumbs(getNotificationsBreadcrumbs());
    return () => setBreadcrumbs(null);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (accessToken) {
      getClientsAll({
        accessToken,
        page: page,
        size: perPage,
        orderBy: orderBy,
        sortOrder: sortOrder,
        shouldContainDeactivatedSystem: showOnlyDeactivatedSystems,
        statuses:
          filterStatus[0].value === clientStatusOptions[0].value
            ? getAvailableStatuses(isIncludeDeactivated, clientStatusOptions)
            : getArrayOfOptionsValues(filterStatus),
      });
    }
    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, getClientsAll, reset, clientStatusOptions.length]);

  const handleOnPageChange = (page: { selected: number }) => {
    setTablePage(page.selected);
    setIsSearchInputChanged(false);
    getClientsAll({
      ...requestData,
      page: page.selected,
    });
  };

  const handleOnItemsPerPageChange = (perPage: { id: number; label: string; value: string }) => {
    setTablePerPage(Number(perPage.value));
    setTablePage(1);
    setIsSearchInputChanged(false);
    getClientsAll({
      ...requestData,
      size: Number(perPage.value),
    });
  };

  const handleTableFilterStatusChange = (event: FormSelectOption[]) => {
    setTablePage(1);
    setIsSearchInputChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, clientStatusOptions, setFilterStatus);
    getClientsAll({
      ...requestData,
      statuses:
        selectedOptions[0].value === clientStatusOptions[0].value
          ? getAvailableStatuses(isIncludeDeactivated, clientStatusOptions)
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const handleTableFilterSystemChange = (event: IntegrationSystemOptions[]) => {
    setTablePage(1);
    setIsSearchInputChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, connectedSystemsOptions, setFilterSystem);
    getClientsAll({
      ...requestData,
      systems:
        selectedOptions[0].value === connectedSystemsOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getClientsAll({
      ...requestData,
      search: value ? value : undefined,
    });
  }, 500);

  const handleSearch = (value: string) => {
    if (value !== filterSearch) {
      setIsSearchInputChanged(true);
    }
    setFilterSearch(value);

    if (value.trim().length !== 0 || filterSearch.trim().length) {
      debouncedSearch(value);
      setTablePage(1);
    }
  };

  const handleTableSortChange = (name: string, direction: TableSortOrder) => {
    setTableOrder({ orderBy: name, sortOrder: direction });
    setTablePage(1);
    getClientsAll({
      ...requestData,
      orderBy: name,
      sortOrder: direction,
    });
  };

  const onRefreshButtonClick = () => {
    setIsSearchInputChanged(false);
    getClientsAll({
      ...requestData,
      page: page,
    });
  };

  const onShowDeactivatedChange = (event: any) => {
    setIsIncludeDeactivated(event.target.checked);
    setTablePage(1);
    getClientsAll({
      ...requestData,
      statuses:
        filterStatus[0].value === clientStatusOptions[0].value
          ? getAvailableStatuses(event.target.checked, clientStatusOptions)
          : getArrayOfOptionsValues(filterStatus),
    });
  };

  const onShowOnlyDeactivatedSystemsChange = (event: any) => {
    setShowOnlyDeactivatedSystems(event.target.checked);
    setTablePage(1);
    getClientsAll({
      ...requestData,
      shouldContainDeactivatedSystem: event.target.checked,
    });
  };

  return (
    <>
      <Head>
        <Title>
          <Message id='clients-all-screen-title' />
        </Title>
      </Head>
      <Wrapper>
        <FiltersWrapper>
          <FilterRow>
            <FiltersLeft>
              <FiltersColumn>
                <Select
                  value={filterSystem}
                  options={connectedSystemsOptions}
                  label={<Message id='clients-all-screen-filter-connected-systems' />}
                  onChange={handleTableFilterSystemChange}
                  isMulti
                  isClearable={false}
                  disabled={isFiltersDisabled}
                />
              </FiltersColumn>
              <FiltersColumn>
                <Select
                  isMulti
                  isClearable={false}
                  value={filterStatus}
                  options={statusFilterOptions}
                  label={<Message id='clients-all-screen-filter-status' />}
                  onChange={handleTableFilterStatusChange}
                  disabled={isFiltersDisabled}
                />
              </FiltersColumn>
              <FiltersColumnWide>
                <Input
                  inputRef={inputRef}
                  disabled={isFetching}
                  id='filterSearch'
                  type='text'
                  label={<Message id='clients-all-screen-filter-search' />}
                  value={filterSearch}
                  onChange={handleSearch}
                  placeholder='Customer or Client Name, Org. Number'
                />
              </FiltersColumnWide>
            </FiltersLeft>
            <FiltersRight>
              <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
                <RefreshIcon width={20} height={20} />
              </Button>
            </FiltersRight>
          </FilterRow>
          <FilterRow>
            <FiltersLeft>
              <FilterRowColumn>
                <Switcher
                  text={<Message id='clients-screen-include-deactivated-clients' />}
                  leftLabel='No'
                  rightLabel='Yes'
                  isChecked={isIncludeDeactivated}
                  onChange={onShowDeactivatedChange}
                />
              </FilterRowColumn>
              <FilterRowColumn>
                <Switcher
                  text={<Message id='clients-screen-show-deactivated-systems' />}
                  tooltipText={<Message id='clients-screen-this-toggle-shows-only-entities-with-deactivated-systems' />}
                  leftLabel='No'
                  rightLabel='Yes'
                  isChecked={showOnlyDeactivatedSystems}
                  onChange={onShowOnlyDeactivatedSystemsChange}
                />
              </FilterRowColumn>
            </FiltersLeft>
          </FilterRow>
        </FiltersWrapper>
        <DataTable
          isFetching={isFetching}
          data={clients}
          loadingComponent={Spinner}
          columns={clientsAllTableColumns}
          emptyMessage={<Message id='no-data-message' />}
          orderBy={orderBy}
          sortOrder={sortOrder}
          onSortChange={handleTableSortChange}
        />
        <Paginator
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          onItemsPerPageChange={handleOnItemsPerPageChange}
          onPageChange={handleOnPageChange}
        />
      </Wrapper>
    </>
  );
};

export default ClientsAll;
