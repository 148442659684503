import { EInvoiceDto } from 'common/api/generated/PAIAdminApiClient';

export interface ZipAdditionalData {
  ZipBatchId: string;
  ZipBatchFileName: string;
  ZipBatchFileUri: string;
}

const zipAdditionalDataFields: (keyof ZipAdditionalData)[] = ['ZipBatchId', 'ZipBatchFileName', 'ZipBatchFileUri'];

export const toZipAdditionalData = ({ additionalData }: EInvoiceDto): ZipAdditionalData | undefined => {
  if (additionalData === undefined) {
    return undefined;
  }

  const objectFields = Object.keys(additionalData).map((key) => key.toLowerCase());
  return (
    zipAdditionalDataFields.every((field) => objectFields.includes(field.toLowerCase())) ? additionalData : undefined
  ) as ZipAdditionalData | undefined;
};
