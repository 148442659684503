import { useEffect, useState, useMemo } from 'react';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { InvoiceMonitoring as InvoiceMonitoringData } from 'common/api/generated/PAIAdminApiClient';
import { RefreshIcon } from 'common/components/Icons';
import { Message, Spinner, DataTable, Alert, Button } from 'common/components';
import { Wrapper, FiltersWrapper, FilterRow, FiltersLeft, FiltersRight } from '../styles';
import invoiceMonitoringTableColumns from './invoiceMonitoringTableColumns';

const InvoiceMonitoring = () => {
  // table
  const adminClient = useAuthenticatedAdminApiClient();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [invoiceMonitoring, setInvoiceMonitoring] = useState<InvoiceMonitoringData>();
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const stuckInvoicesAmount = useMemo(() => invoiceMonitoring?.stuckInvoices?.length || 0, [invoiceMonitoring]);
  const onRefreshButtonClick = () => setRefreshKey(refreshKey + 1);

  // fetching of invoice monitoring from api
  useEffect(() => {
    setIsFetching(true);
    adminClient
      ?.monitoringControllerGetInvoiceMonitoring()
      .finally(() => setIsFetching(false))
      .catch((error) => console.error(error))
      .then((data) => {
        if (!data) return;
        setInvoiceMonitoring(data);
      });
  }, [adminClient, refreshKey]);

  return (
    <Wrapper>
      <FiltersWrapper>
        <FilterRow>
          <FiltersLeft>{}</FiltersLeft>
          <FiltersRight>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </FiltersRight>
        </FilterRow>
      </FiltersWrapper>
      {stuckInvoicesAmount > 0 && (
        <Alert
          title={<Message id='monitoring-screen-invoices-stuck-in-ready-for-posting' />}
          text={`There are ${stuckInvoicesAmount} invoices that have been stuck in the 'Ready for Posting' state for a while. These should be investigated.`}
        />
      )}
      <DataTable
        isFetching={isFetching}
        data={invoiceMonitoring?.stuckInvoices ?? []}
        loadingComponent={Spinner}
        columns={invoiceMonitoringTableColumns}
        emptyMessage={<Message id='no-data-message' />}
      />
    </Wrapper>
  );
};

export default InvoiceMonitoring;
