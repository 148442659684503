import styled from 'styled-components';
import StyledTooltip from './TooltipCustom';

export const TooltipCustom = styled(StyledTooltip)`
  .ant-tooltip-inner {
    font-size: 12px;
    background: ${({ theme }) => theme.tooltip.background};
  }
  .ant-tooltip-arrow-content::before {
    background: ${({ theme }) => theme.tooltip.background};
  }
`;
