import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { ClientRequestData } from 'types/Client';

// Actions
export enum ActionTypeKeys {
  CLIENT_CREATE = 'CLIENTS/CLIENT_CREATE',
  CLIENT_CREATE_SUCCESS = 'CLIENTS/CLIENT_CREATE_SUCCESS',
  CLIENT_CREATE_FAILED = 'CLIENTS/CLIENT_CREATE_FAILED',
}

// ActionCreators
export const actionCreators = {
  createClient: (payload: ClientRequestData) => createAction(ActionTypeKeys.CLIENT_CREATE, payload),
  createClientSuccess: () => createAction(ActionTypeKeys.CLIENT_CREATE_SUCCESS),
  createClientFailed: () => createAction(ActionTypeKeys.CLIENT_CREATE_FAILED),
};

// State
export interface State {
  isFetching: boolean;
}

export const initialState: State = {
  isFetching: false,
};

// Reducer
export const clientCreateReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.CLIENT_CREATE:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.CLIENT_CREATE_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
