import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;

export const Content = styled.div``;

interface MarkdownContainerType {
  isAsideExists: boolean;
  topPosition: number;
}

export const MarkdownContainer = styled.div<MarkdownContainerType>`
  max-width: ${({ isAsideExists }) => (isAsideExists ? '1400px' : '1200px')};
  min-height: 200px;
  padding: ${({ isAsideExists }) => (isAsideExists ? '20px 20px 20px 320px' : '20px')};
  background: ${({ theme }) => theme.backgroundPrimary};
  position: relative;

  tr {
    border-top: 1px solid ${({ theme }) => theme.borders.grey};
    background: ${({ theme }) => theme.backgroundPrimary};
  }

  th,
  td {
    padding: 6px 16px;
    border: 1px solid ${({ theme }) => theme.borders.grey};
  }

  th {
    text-align: left;
  }

  table {
    border-collapse: collapse;
  }

  table tr:nth-child(2n) {
    background: ${({ theme }) => theme.backgroundSecondary};
  }

  .contains-task-list {
    list-style: none;
    display: block;
    padding-inline-start: 20px;
  }

  ol,
  ul {
    padding-inline-start: 40px;
  }

  code {
    background: ${({ theme }) => theme.backgroundThird};
    padding: 2px 4px;
    border-radius: 2px;
  }

  a {
    text-decoration: underline;
  }

  .toc {
    border-radius: 4px;
    display: inline-block;
    padding: 10px 15px 10px 10px;
    margin-bottom: 14px;
    width: 280px;
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    border: 1px solid ${({ theme }) => theme.borders.grey};
    background: ${({ theme }) => theme.backgroundSecondary};
    position: absolute;
    top: 20px;
    left: 20px;
    transform: translateY(${({ topPosition }) => `${topPosition}px`});

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.colors.grey};
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.lightGrey};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.primary};
    }
  }
  .toc ol {
    list-style: none;
    padding-inline-start: 20px;
  }
  .toc li {
    margin-top: 4px;
    margin-bottom: 0;
    position: relative;
  }
  .toc li:after {
    position: absolute;
    content: '';
    left: -10px;
    top: 7px;
    background: ${({ theme }) => theme.fontPrimary};
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
  .toc a {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 20px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  h1[id],
  h2[id],
  h3[id],
  h4[id],
  h5[id],
  h6[id] {
    padding-top: 70px;
    margin-top: -50px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
