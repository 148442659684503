import styled from 'styled-components';

interface WrapperProps {
  isHeaderExists: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  width: 100%;
  padding-top: ${({ isHeaderExists }) => (isHeaderExists ? '56px' : 0)};
`;

interface MainProps {
  isSidebarExists: boolean;
  isSubNavExists: boolean;
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
}

const getMainLeftPadding = (
  isSidebarExists: boolean,
  isSubNavExists: boolean,
  isMainNavCollapsed: boolean,
  isSubNavCollapsed: boolean,
) => {
  if (isSubNavExists && isSubNavCollapsed && isMainNavCollapsed) {
    return '160px';
  }
  if (isSubNavExists && isSubNavCollapsed && !isMainNavCollapsed) {
    return '320px';
  }
  if (isSubNavExists) {
    return '320px';
  }
  if (!isSubNavExists && isMainNavCollapsed) {
    return '80px';
  }
  if (isSidebarExists) {
    return '260px';
  }

  return '260px';
};

export const Main = styled.div<MainProps>`
  display: flex;
  flex: 1;
  min-height: calc(100vh - 56px);
  width: 100%;
  padding-left: ${({ isSidebarExists, isSubNavExists, isMainNavCollapsed, isSubNavCollapsed }) =>
    getMainLeftPadding(isSidebarExists, isSubNavExists, isMainNavCollapsed, isSubNavCollapsed)};
  position: relative;
  z-index: 1;
`;

export const Content = styled.div`
  padding: 24px;
  flex: 1;
  background: ${({ theme }) => theme.backgroundSecondary};
`;
