import { ReactElement } from 'react';
import { getFormattedLongDate } from 'utils';
import { operationScopeTypeNamesMapping, OperationData, OperationStatus, OperationScope } from 'types/Operations';
import { TableDateField } from 'types/Tables';
import { Message } from 'common/components';
import { ColumnItem, TableAlignment } from 'common/components/DataTable/types';
import { APP_URLS } from 'common/constants';
import { IntegrationsInfo, IntegrationsSystems, integrationsSystemsNamesMapping } from 'types/Integrations';
import { ActionIcon } from 'components/AccessPointGrid/styles';
import { RetryIcon } from 'common/components/Icons';
import {
  ItemsCount,
  StatusLabel,
  StyledLink,
  TableCellContent,
  TableCellItem,
  TagList,
  TagListItem,
  DateWrap,
} from './styles';

interface CustomerOperationsDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: OperationData;
}

const operationActionCellCallback = ({ row }: CustomerOperationsDataTable, settings: OperationsGridColumnsSettings) => {

  return (
    <>
      {(row.status === OperationStatus.FAILURE && row.scope === OperationScope.INVOICE_EXPORT) && (
        <>
          <ActionIcon title='Retry' onClick={() => settings.onRetryButtonClick(row)}>
            <RetryIcon width={13} />
          </ActionIcon>
        </>
      )}
    </>
  );
};

export enum GridType {
  CUSTOMER_OPERATIONS = 'CUSTOMER_OPERATIONS',
  CLIENT_OPERATIONS = 'CLIENT_OPERATIONS',
}

type OperationsGridColumnsSettings = {
  gridType: GridType;
  onRetryButtonClick: (payload: { syncId: string }) => void;
};


export const buildOperationsGridColumns = (settings: OperationsGridColumnsSettings): ColumnItem<CustomerOperationsDataTable>[] => [

  // customer-specific columns
  ...(settings.gridType === GridType.CUSTOMER_OPERATIONS
    ? [
      {
        name: 'customerName',
        title: <Message id='operations-table-customer-name' />,
        width: 350,
        cellCallback: ({ row: { customerId, customerName } }: CustomerOperationsDataTable) => (
          <StyledLink to={APP_URLS.toCustomerProfile(customerId)}>{customerName}</StyledLink>
        ),
      },
    ]
    : []),

  // generic columns
  {
    name: 'clientName',
    title: <Message id='operations-table-client-name' />,
    width: 350,
    cellCallback: ({ row: { customerId, clientId, clientName } }: CustomerOperationsDataTable) => (
      <StyledLink to={APP_URLS.toClientOperations(customerId, clientId)}>{clientName}</StyledLink>
    ),
  },
  {
    name: 'connectedSystems',
    title: <Message id='operations-table-connected-systems' />,
    width: 300,
    cellCallback: ({ row: { integrations } }: CustomerOperationsDataTable) => (
      <>
        {integrations && integrations.length ? (
          <TagList>
            {integrations.map((item: IntegrationsInfo, index: number) => (
              <TagListItem
                key={item.name + index}
                isActive={item.isActive}
                title={item.isActive ? 'Active' : 'Inactive'}
              >
                {`${item.name === IntegrationsSystems.VICAI ? integrationsSystemsNamesMapping[item.name] : item.name}`}
              </TagListItem>
            ))}
          </TagList>
        ) : (
          <span>
            <Message id='operations-table-no-connected-systems' />
          </span>
        )}
      </>
    ),
  },
  {
    name: 'scope',
    title: <Message id='operations-table-operation-scope' />,
    width: 250,
    sortable: true,
    cellCallback: ({ row: { scope } }: CustomerOperationsDataTable) => (
      <span title={scope}>{operationScopeTypeNamesMapping[scope]}</span>
    ),
  },
  {
    name: 'type',
    title: <Message id='operations-table-operation' />,
    width: 150,
    sortable: true,
    cellCallback: ({ row: { type } }: CustomerOperationsDataTable) => <span title={type}>{type}</span>,
  },
  {
    name: 'status',
    title: <Message id='operations-table-status' />,
    width: 200,
    sortable: true,
    cellCallback: ({ row: { status } }: CustomerOperationsDataTable) => {
      const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);
      return (
        <>
          <StatusLabel status={status}>{statusFormatted}</StatusLabel>
        </>
      );
    },
  },
  {
    name: 'entityId',
    title: <Message id='operations-table-entity-id' />,
    width: 220,
    cellCallback: ({ row: { sourceEntityId, targetEntityId } }: CustomerOperationsDataTable) => (
      <TableCellContent>
        <TableCellItem>
          <strong>
            <Message id='operations-table-source' />{' '}
          </strong>
          {sourceEntityId ? sourceEntityId : '-'}
        </TableCellItem>
        <TableCellItem>
          <strong>
            <Message id='operations-table-target' />{' '}
          </strong>
          {targetEntityId ? targetEntityId : '-'}
        </TableCellItem>
      </TableCellContent>
    ),
  },
  {
    name: 'syncedItemsCount',
    title: <Message id='operations-table-synced-items' />,
    width: 100,
    align: TableAlignment.CENTER,
    cellCallback: ({ row: { successfulItemsCount } }: CustomerOperationsDataTable) => (
      <ItemsCount>{successfulItemsCount ? successfulItemsCount : '-'}</ItemsCount>
    ),
  },
  {
    name: 'failedItemsCount',
    title: <Message id='operations-table-failed-items' />,
    width: 100,
    align: TableAlignment.CENTER,
    cellCallback: ({ row: { failedItemsCount } }: CustomerOperationsDataTable) => (
      <ItemsCount>{failedItemsCount ? failedItemsCount : '-'}</ItemsCount>
    ),
  },
  {
    name: TableDateField.STARTED_DATE_TIME,
    title: <Message id='operations-table-started-date' />,
    width: 150,
    sortable: true,
    cellCallback: ({ row: { startedDate } }: CustomerOperationsDataTable) => (
      <DateWrap>{getFormattedLongDate(startedDate)}</DateWrap>
    ),
  },
  {
    name: TableDateField.FINISHED_DATE_TIME,
    title: <Message id='operations-table-finished-date' />,
    width: 150,
    sortable: true,
    cellCallback: ({ row: { finishedDate } }: CustomerOperationsDataTable) => (
      <DateWrap>{getFormattedLongDate(finishedDate)}</DateWrap>
    ),
  },
  {
    name: 'logsUrl',
    title: <Message id='operations-table-logs-url' />,
    width: 150,
    cellCallback: ({ row: { logsUrl } }: CustomerOperationsDataTable) => (
      <a href={logsUrl ? logsUrl : ''} target='_blank' rel='noopener noreferrer'>
        Details
      </a>
    ),
  },
  {
    name: 'actions',
    title: <Message id='operations-table-actions' />,
    width: 150,
    cellCallback: (record) => operationActionCellCallback(record, settings),
  },
];
