import { APP_URLS } from 'common/constants';
import { Message } from 'common/components/Message';
import { StyledLink } from '../styles';

const invoiceMonitoringTableColumns = [
  {
    name: 'customerName',
    title: <Message id='monitoring-screen-table-customer-name' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { customerId, customerName } }: any) => (
      <StyledLink to={APP_URLS.toCustomerProfile(Number(customerId))}>{customerName}</StyledLink>
    ),
  },
  {
    name: 'clientName',
    title: <Message id='monitoring-screen-table-client-name' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { customerId, clientId, clientName } }: any) => (
      <StyledLink to={APP_URLS.toClient(Number(customerId), Number(clientId))}>{clientName}</StyledLink>
    ),
  },
  {
    name: 'externalId',
    title: <Message id='monitoring-screen-table-external-id' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { externalId } }: any) => <span>{externalId}</span>,
  },
  {
    name: 'referenceNumber',
    title: <Message id='monitoring-screen-table-reference-number' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { referenceNumber } }: any) => <span>{referenceNumber}</span>,
  },
  {
    name: 'vendorExternalId',
    title: <Message id='monitoring-screen-table-vendor-external-id' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { vendorExternalId } }: any) => <span>{vendorExternalId}</span>,
  },
  {
    name: 'vendorName',
    title: <Message id='monitoring-screen-table-vendor-name' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { vendorName } }: any) => <span>{vendorName}</span>,
  },
];

export default invoiceMonitoringTableColumns;
