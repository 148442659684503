import { Icon } from 'types/Icon';

export const CopyIcon = ({ color, width, height }: Icon) => (
  <svg viewBox='0 0 17 16.92' width={width || '17'} height={height || '20'}>
    <g>
      <path
        // eslint-disable-next-line max-len
        d='M11.18 0H5a1.67 1.67 0 0 0-1.67 1.67v11.66A1.67 1.67 0 0 0 5 15h8.33A1.67 1.67 0 0 0 15 13.33V3.82zM5 13.33V1.67h5V5h3.33v8.33z'
        fill={color || 'currentColor'}
      />
      <path d='M1.67 3.33H0v13.34a1.66 1.66 0 0 0 1.67 1.66h10v-1.66h-10z' fill={color || 'currentColor'} />
    </g>
  </svg>
);
