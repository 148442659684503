import styled from 'styled-components';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;
