import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

interface SpinnerComponentType {
  isVisible: boolean;
}

export const SpinnerComponent = styled.div<SpinnerComponentType>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: ${rotate} 0.6s linear infinite;
  margin-left: auto;
  margin-right: auto;
`;

interface SpinnerOverlayType {
  isVisible: boolean;
  isFixed?: boolean;
  transparent?: boolean;
}

export const SpinnerOverlay = styled.div<SpinnerOverlayType>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${({ transparent }) => (transparent ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.6)')};

  ${SpinnerComponent} {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -15px;
    margin-left: -15px;
  }
`;
