import { integrationsSystemsNamesMapping } from 'types/Integrations';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { Dictionaries, systemsFilterOptions } from 'common/constants';
import { prepareMultipleSelectOptionsFromArray, prepareSelectOptionsFromDictionaries } from 'utils/formHelpers';
import { State as ClientsAllState } from './clientsAllReducer';

export const getClientsAllView = (sharedState: SharedState, clientAllState: ClientsAllState) => ({
  isFetching: clientAllState.isFetching,
  totalCount: clientAllState.totalCount,
  page: clientAllState.page,
  perPage: clientAllState.perPage,
  orderBy: clientAllState.orderBy,
  sortOrder: clientAllState.sortOrder,
  filterStatus: clientAllState.filterStatus,
  filterSystem: clientAllState.filterSystem,
  filterSearch: clientAllState.filterSearch,
  isIncludeDeactivated: clientAllState.isIncludeDeactivated,
  showOnlyDeactivatedSystems: clientAllState.showOnlyDeactivatedSystems,
  clients: clientAllState.clients,
  connectedSystemsOptions: prepareMultipleSelectOptionsFromArray(
    sharedState.integrationSystems,
    systemsFilterOptions[0],
    integrationsSystemsNamesMapping,
  ),
  clientStatusOptions: prepareSelectOptionsFromDictionaries(sharedState.dictionaries[Dictionaries.CLIENT_STATUSES]),
});
