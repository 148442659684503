import { Button as ButtonInterface } from 'types/Button';
import { Btn, BtnIconLeft, BtnIconRight, ButtonLeft } from './styles';

const Button = ({
  disabled,
  leftIcon,
  rightIcon,
  children,
  size = 'medium',
  style,
  onClick,
  onSubmit,
  ...rest
}: ButtonInterface) => {
  const renderLeftIcon = () => <BtnIconLeft>{leftIcon}</BtnIconLeft>;
  const renderRightIcon = () => <BtnIconRight>{rightIcon}</BtnIconRight>;

  const renderButtonLayout = () =>
    rightIcon ? (
      <>
        <ButtonLeft>
          {leftIcon && renderLeftIcon()}
          {children}
        </ButtonLeft>
        {rightIcon && renderRightIcon()}
      </>
    ) : (
      <>
        {leftIcon && renderLeftIcon()}
        {children}
      </>
    );

  return (
    <Btn
      onClick={onClick}
      onSubmit={onSubmit}
      disabled={disabled}
      rightIcon={rightIcon}
      size={size}
      style={style}
      {...rest}
    >
      {renderButtonLayout()}
    </Btn>
  );
};

export default Button;
