import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClientData, ClientRequestDataExtended } from 'types/Client';
import { Button, Message, Spinner, Input, Textarea } from 'common/components';
import { validateOrganizationNumber, validateCountryCode, validateStringValue, validateEmail } from 'utils/validation';
import { PenIcon } from 'common/components/Icons';
import { useAccessToken } from 'hooks';
import {
  ColumnWrapper,
  FormButtons,
  FormColumn,
  FormColumnWrapper,
  FormEditButton,
  FormRow,
  FormWrapper,
} from './styles';

interface EditFormProps {
  organizationNumber: string;
  clientName: string;
  clientComments: string;
  countryCode: string;
  email: string;
}

const defaultValues = {
  organizationNumber: '',
  clientName: '',
  clientComments: '',
  countryCode: '',
  email: '',
};

interface ClientEditFormProps {
  isFetching: boolean;
  customerId: number;
  errorCode: number | null;
  formData: ClientData;
  resetPartial: () => void;
  onSubmitHandler: (payload: ClientRequestDataExtended) => void;
}

const ClientEditForm = ({
  isFetching,
  customerId,
  errorCode,
  formData,
  resetPartial,
  onSubmitHandler,
}: ClientEditFormProps) => {
  const accessToken = useAccessToken();
  const [isFormDisabled, setFormDisabled] = useState(true);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState<EditFormProps>(defaultValues);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
    reset,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    setDefaultFormValues({
      organizationNumber: formData.organizationNumber ? formData.organizationNumber : '',
      clientName: formData.name ? formData.name : '',
      clientComments: formData.comment ? formData.comment : '',
      countryCode: formData.countryCode ? formData.countryCode : '',
      email: formData.email ? formData.email : '',
    });
  }, [formData]);

  useEffect(() => {
    reset(defaultFormValues);

    return () => resetPartial();
  }, [reset, resetPartial, defaultFormValues]);

  const onEditButtonHandler = () => {
    setFormDisabled(false);
    setIsRequestPending(false);
  };

  const onCancelHandler = useCallback(() => {
    setFormDisabled(true);
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const onSubmit = useCallback(
    (data: EditFormProps) => {
      onSubmitHandler({
        accessToken,
        customerId: Number(customerId),
        id: formData.id,
        organizationNumber: data.organizationNumber,
        name: data.clientName,
        comment: data.clientComments,
        countryCode: data.countryCode,
        email: data.email,
      });
      setIsRequestPending(true);
    },
    [accessToken, formData.id, onSubmitHandler, customerId],
  );

  useEffect(() => {
    if (isRequestPending) {
      if (errorCode) {
        setFormDisabled(() => false);
      } else {
        setFormDisabled(() => true);
      }
    }
  }, [errorCode, isRequestPending]);

  const renderFormButtons = useMemo(
    () =>
      isFormDisabled ? null : (
        <FormButtons>
          <Button transparent onClick={onCancelHandler}>
            <Message id={'create-client-cancel-button'} />
          </Button>
          <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
            <Message id={'edit-customers-save-button'} />
          </Button>
        </FormButtons>
      ),
    [handleSubmit, isDirty, isValid, isFormDisabled, onCancelHandler, onSubmit],
  );

  return (
    <>
      <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
        {isFetching && <Spinner />}
        <FormEditButton>
          <Button primary disabled={!isFormDisabled} onClick={onEditButtonHandler}>
            <PenIcon />
          </Button>
        </FormEditButton>
        <FormColumnWrapper>
          <FormColumn>
            <FormRow>
              <Controller
                control={control}
                name='organizationNumber'
                defaultValue={defaultFormValues.organizationNumber}
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 100,
                  validate: validateOrganizationNumber,
                }}
                render={({ field, fieldState }) => {
                  const messageId = fieldState.error?.message
                    ? fieldState.error.message
                    : 'create-client-organization-number-error';

                  return (
                    <Input
                      isRequired
                      disabled={isFormDisabled}
                      isError={fieldState.invalid}
                      id='organizationNumber'
                      type='text'
                      label={<Message id='create-client-organization-number-label' />}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      errorText={<Message id={messageId} />}
                    />
                  );
                }}
              />
            </FormRow>
            <FormRow>
              <ColumnWrapper>
                <Controller
                  control={control}
                  name='clientName'
                  defaultValue={defaultFormValues.clientName}
                  rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 70,
                    validate: (value: string) => validateStringValue(true, value, 'create-client-client-name'),
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'create-client-client-name-error';

                    return (
                      <Input
                        isRequired
                        disabled={isFormDisabled}
                        isError={fieldState.invalid}
                        id='clientName'
                        type='text'
                        label={<Message id={'create-client-client-name-label'} />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </ColumnWrapper>
            </FormRow>
            <FormRow>
              <ColumnWrapper>
                <Controller
                  control={control}
                  name='countryCode'
                  defaultValue={defaultFormValues.countryCode}
                  rules={{
                    required: true,
                    minLength: 2,
                    maxLength: 2,
                    validate: validateCountryCode,
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'create-client-country-code-error';

                    return (
                      <Input
                        isRequired
                        disabled={isFormDisabled}
                        isError={fieldState.invalid}
                        id='countryCode'
                        type='text'
                        label={<Message id={'create-client-country-code-label'} />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </ColumnWrapper>
            </FormRow>
            <FormRow>
              <ColumnWrapper>
                <Controller
                  control={control}
                  name='email'
                  defaultValue={defaultFormValues.email}
                  rules={{
                    required: false,
                    validate: (value) => validateEmail(value, false),
                  }}
                  render={({ field, fieldState }) => {
                    const messageId = fieldState.error?.message
                      ? fieldState.error.message
                      : 'create-client-email-code-error';

                    return (
                      <Input
                        disabled={isFormDisabled}
                        isError={fieldState.invalid}
                        id='email'
                        type='email'
                        label={<Message id={'create-client-email-label'} />}
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        errorText={<Message id={messageId} />}
                      />
                    );
                  }}
                />
              </ColumnWrapper>
            </FormRow>
          </FormColumn>
          <FormColumn>
            <FormRow>
              <Controller
                control={control}
                name='clientComments'
                defaultValue={defaultFormValues.clientComments}
                rules={{ maxLength: 200 }}
                render={({ field: { onChange, value }, fieldState }) => {
                  return (
                    <Textarea
                      disabled={isFormDisabled}
                      id='clientComments'
                      isError={fieldState.invalid}
                      value={value}
                      label={<Message id='create-client-comments-label' />}
                      onChange={(e) => onChange(e)}
                      minHeight={116}
                      errorText={<Message id='create-client-comments-error-200-symbols' />}
                    />
                  );
                }}
              />
            </FormRow>
          </FormColumn>
        </FormColumnWrapper>

        {renderFormButtons}

      </FormWrapper>
    </>
  );
};

export default ClientEditForm;
