import styled from 'styled-components';

export const FormWrapper = styled.form`
  padding-right: 0px;
  padding-top: 0px;
  position: relative;
`;

export const FormColumnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormEditButton = styled.span`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const FormColumn = styled.div`
  width: 48%;
  flex: 0 0 48%;
`;

export const FormRow = styled.div`
  margin-bottom: 10px;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0;

  & > button {
    margin-left: 20px;
    min-width: 130px;
  }
`;
