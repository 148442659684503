import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import messages from 'assets/translations/translation_en.json';
import { IntlData } from 'types/Intl';

export enum ActionTypeKeys {
  INTL_CHANGE_LANGUAGE = 'INTL/INTL_CHANGE_LANGUAGE',
  INTL_CHANGE_LANGUAGE_SUCCESS = 'INTL/INTL_CHANGE_LANGUAGE_SUCCESS',
  INTL_CHANGE_LANGUAGE_FAILED = 'INTL/INTL_CHANGE_LANGUAGE_FAILED',
}

export const actionCreators = {
  changeLanguage: (payload: string) => createAction(ActionTypeKeys.INTL_CHANGE_LANGUAGE, payload),
  changeLanguageSuccess: (payload: IntlData) => createAction(ActionTypeKeys.INTL_CHANGE_LANGUAGE_SUCCESS, payload),
  changeLanguageFailed: () => createAction(ActionTypeKeys.INTL_CHANGE_LANGUAGE_FAILED),
};

// State
export interface State {
  locale: string;
  defaultLocale: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  messages: object;
}

export const initialState: State = {
  locale: 'en',
  defaultLocale: 'en',
  messages,
};

// Reducer
export const intlReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.INTL_CHANGE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        locale: action.payload.locale,
        messages: action.payload.messages,
      };
    }

    default:
      return state;
  }
};
