import { ReactElement } from 'react';
import { Button, Message } from 'common/components';
import { APP_URLS } from 'common/constants';
import { TableDateField } from 'types/Tables';
import { getFormattedLongDate } from 'utils';
import { SyncMasterDataStatus } from 'types/SyncMasterData';
import { SyncIcon } from 'common/components/Icons';
import { TableAlignment } from 'common/components/DataTable/types';
import { ActionButtons, DateWrap, StatusLabel, StyledLink } from './styles';

interface SyncMasterDataTableRow {
  syncable: boolean;
  id: number;
  key: string;
  clientId: number;
  clientName: string;
  lastExecuted: string;
  organizationNumber: string;
  status: SyncMasterDataStatus;
}

interface SyncMasterDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: SyncMasterDataTableRow;
}

const syncMasterDataTableColumns = (customerId: number, onSyncButtonClickHandler: any) => {
  return [
    {
      name: 'clientId',
      title: <Message id='customer-sync-master-data-table-client-id' />,
      width: 100,
      sortable: true,
      cellCallback: ({ row: { clientId } }: SyncMasterDataTable) => (
        <StyledLink to={APP_URLS.toClient(Number(customerId), Number(clientId))}>{clientId}</StyledLink>
      ),
    },
    {
      name: 'clientName',
      title: <Message id='customer-sync-master-data-table-client-name' />,
      width: 450,
      sortable: true,
      cellCallback: ({ row: { clientId, clientName } }: SyncMasterDataTable) => (
        <StyledLink to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>{clientName}</StyledLink>
      ),
    },
    {
      name: 'organizationNumber',
      title: <Message id='customer-sync-master-data-table-organization-number' />,
      width: 200,
      cellCallback: ({ row: { organizationNumber } }: SyncMasterDataTable) => <strong>{organizationNumber}</strong>,
    },
    {
      name: TableDateField.LAST_EXECUTED,
      title: <Message id='customer-sync-master-data-table-last-executed' />,
      width: 350,
      sortable: true,
      cellCallback: ({ row: { lastExecuted } }: SyncMasterDataTable) => (
        <DateWrap>{getFormattedLongDate(lastExecuted)}</DateWrap>
      ),
    },
    {
      name: 'status',
      title: <Message id='customer-sync-master-data-table-status' />,
      width: 200,
      cellCallback: ({ row: { status } }: SyncMasterDataTable) => {
        const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);
        return (
          <>
            <StatusLabel status={status}>{statusFormatted}</StatusLabel>
          </>
        );
      },
    },
    {
      name: 'action',
      align: TableAlignment.RIGHT,
      title: <Message id='customer-sync-master-data-table-action' />,
      width: 80,
      cellCallback: ({ row }: SyncMasterDataTable) => (
        <ActionButtons>
          <Button primary size='small' disabled={!row.syncable} onClick={() => onSyncButtonClickHandler(row.clientId)}>
            <SyncIcon />
          </Button>
        </ActionButtons>
      ),
    },
  ];
};

export default syncMasterDataTableColumns;
