import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { APP_URLS } from 'common/constants';
import { Message, Input, Textarea, Button, Spinner } from 'common/components';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { ClientRequestData } from 'types/Client';
import { CustomerByIdRequestData, CustomerData } from 'types/Customer';
import { validateOrganizationNumber, validateCountryCode, validateStringValue } from 'utils/validation';
import { useAccessToken } from 'hooks';
import getClientCreateBreadcrumbs from './getClientCreateBreadcrumbs';
import {
  Head,
  Title,
  Content,
  FormWrapper,
  FormColumnWrapper,
  FormColumn,
  FormRow,
  FormSection,
  FormSectionHeader,
  FormSectionTitle,
  FormButtons,
} from './styles';

const defaultValues = {
  clientName: '',
  organizationNumber: '',
  clientComments: '',
  countryCode: '',
  email: '',
  useApproverReferenceFeature: false,
  useApproverReferenceSyncFeature: false,
  alwaysGeneratePropellPdf: false,
};

interface CreateFormProps {
  clientName: string;
  organizationNumber: string;
  clientComments: string;
  countryCode: string;
  email: string;
  useApproverReferenceFeature: boolean;
  useApproverReferenceSyncFeature: boolean;
  alwaysGeneratePropellPdf: boolean;
}

type clientsUrlParams = {
  customerId: string;
};

interface ClientCreateProps {
  isFetching: boolean;
  customer: CustomerData;
  getCustomer: (payload: CustomerByIdRequestData) => void;
  createClient: (payload: ClientRequestData) => void;
}

const ClientCreate = ({ isFetching, customer, getCustomer, createClient }: ClientCreateProps) => {
  const accessToken = useAccessToken();
  const { push } = useHistory();
  const { customerId } = useParams<clientsUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
  } = useForm({ defaultValues, mode: 'onChange' });

  useEffect(() => {
    if (!customer.name && accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, customer.name, getCustomer]);

  useEffect(() => {
    setBreadcrumbs(getClientCreateBreadcrumbs(Number(customerId), customer.name));
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs]);

  const onSubmit = (data: CreateFormProps) => {
    if (accessToken) {
      createClient({
        accessToken,
        customerId: Number(customerId),
        organizationNumber: data.organizationNumber,
        name: data.clientName,
        comment: data.clientComments,
        countryCode: data.countryCode,
        email: data.email,
      });
    }
  };

  const onCancelHandler = () => {
    push(APP_URLS.toCustomerClients(Number(customerId)));
  };

  return (
    <>
      <Head>
        <Title>
          <Message id={'create-client-screen-title'} />
        </Title>
      </Head>
      <Content>
        {isFetching && <Spinner />}
        <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
          <FormSection>
            <FormSectionHeader>
              <FormSectionTitle>
                <Message id='create-client-client-data' />
              </FormSectionTitle>
            </FormSectionHeader>
            <FormColumnWrapper>
              <FormColumn>
                <FormRow>
                  <Controller
                    control={control}
                    name='organizationNumber'
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 100,
                      validate: validateOrganizationNumber,
                    }}
                    render={({ field, fieldState }) => {
                      const messageId = fieldState.error?.message
                        ? fieldState.error.message
                        : 'create-client-organization-number-error';

                      return (
                        <Input
                          isRequired
                          isError={fieldState.invalid}
                          id='organizationNumber'
                          type='text'
                          label={<Message id='create-client-organization-number-label' />}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          errorText={<Message id={messageId} />}
                        />
                      );
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Controller
                    control={control}
                    name='clientName'
                    rules={{
                      required: true,
                      minLength: 1,
                      maxLength: 70,
                      validate: (value: string) => validateStringValue(true, value, 'create-client-client-name'),
                    }}
                    render={({ field, fieldState }) => {
                      const messageId = fieldState.error?.message
                        ? fieldState.error.message
                        : 'create-client-client-name-error';

                      return (
                        <Input
                          isRequired
                          isError={fieldState.invalid}
                          id='clientName'
                          type='text'
                          label={<Message id={'create-client-client-name-label'} />}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          errorText={<Message id={messageId} />}
                        />
                      );
                    }}
                  />
                </FormRow>
                <FormRow>
                  <Controller
                    control={control}
                    name='countryCode'
                    rules={{
                      required: true,
                      minLength: 2,
                      maxLength: 2,
                      validate: validateCountryCode,
                    }}
                    render={({ field, fieldState }) => {
                      const messageId = fieldState.error?.message
                        ? fieldState.error.message
                        : 'create-client-country-code-error';

                      return (
                        <Input
                          isRequired
                          isError={fieldState.invalid}
                          id='countryCode'
                          type='text'
                          label={<Message id={'create-client-country-code-label'} />}
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          errorText={<Message id={messageId} />}
                        />
                      );
                    }}
                  />
                </FormRow>
              </FormColumn>
              <FormColumn>
                <FormRow>
                  <Controller
                    control={control}
                    name='clientComments'
                    rules={{ maxLength: 200 }}
                    render={({ field: { onChange, value }, fieldState }) => {
                      return (
                        <Textarea
                          id='customerComments'
                          isError={fieldState.invalid}
                          value={value}
                          label={<Message id='create-client-comments-label' />}
                          onChange={(e) => onChange(e)}
                          minHeight={116}
                          errorText={<Message id='create-client-comments-error-200-symbols' />}
                        />
                      );
                    }}
                  />
                </FormRow>
              </FormColumn>
            </FormColumnWrapper>
          </FormSection>

          <FormButtons>
            <Button transparent onClick={onCancelHandler}>
              <Message id={'create-customers-cancel-button'} />
            </Button>
            <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
              <Message id={'create-customers-save-proceed-button'} />
            </Button>
          </FormButtons>
        </FormWrapper>
      </Content>
    </>
  );
};

export default ClientCreate;
