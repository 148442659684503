import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { CustomerRequestData } from 'types/Customer';

// Actions
export enum ActionTypeKeys {
  CUSTOMER_CREATE = 'CUSTOMERS/CUSTOMER_CREATE',
  CUSTOMER_CREATE_SUCCESS = 'CUSTOMERS/CUSTOMER_CREATE_SUCCESS',
  CUSTOMER_CREATE_FAILED = 'CUSTOMERS/CUSTOMER_CREATE_FAILED',
}

// ActionCreators
export const actionCreators = {
  createCustomer: (payload: CustomerRequestData) => createAction(ActionTypeKeys.CUSTOMER_CREATE, payload),
  createCustomerSuccess: () => createAction(ActionTypeKeys.CUSTOMER_CREATE_SUCCESS),
  createCustomerFailed: () => createAction(ActionTypeKeys.CUSTOMER_CREATE_FAILED),
};

// State
export interface State {
  isFetching: boolean;
}

export const initialState: State = {
  isFetching: false,
};

// Reducer
export const customerCreateReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.CUSTOMER_CREATE:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.CUSTOMER_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.CUSTOMER_CREATE_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
