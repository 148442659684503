import { ReactNode } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateTimeHolder = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const TextLeftIcon = styled.div`
  z-index: 1;
  position: absolute;
  left: 10px;
  top: 6px;
  width: 22x;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.fontSecondary};
  margin-right: 8px;
`;

export const TextRightIcon = styled.div`
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 7px;
  width: 22x;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.fontSecondary};
  margin-right: 8px;
`;

export const getLabelColor = ({ theme, isError, isDisabled }: any) => {
  if (isError) {
    return theme.fontError;
  }
  if (isDisabled) {
    return theme.fontSecondary;
  }
  return theme.fontPrimary;
};

interface LabelProps {
  isDisabled?: boolean;
  isError?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-size: 12px;
  line-height: 24px;
  pointer-events: none;
  position: relative;
  color: ${({ theme, isDisabled, isError }) => getLabelColor({ theme, isDisabled, isError })};
  transition: font-size 0.2s, transform 0.2s;
  will-change: transform, font-size;
`;

interface SelectWrapProps {
  isMulti?: boolean;
  isError?: boolean;
  isDisabled?: boolean;
}

/* eslint-disable */
export const DateTimeWrap = styled.div<SelectWrapProps>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  &:hover ${Label} {
    color: ${({ theme, isError, isDisabled }) =>
      isDisabled ? theme.fontSecondary : isError ? theme.fontError : theme.fontPrimary};
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
`;
/* eslint-enable */

interface DatePickerProps {
  isFocused?: boolean;
  isError?: boolean;
  disabled?: boolean;
  leftIcon?: ReactNode;
}

/* eslint-disable */
export const DatePickerStyled = styled(DatePicker)<DatePickerProps>`
  z-index: 0;
  font-size: 12px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  box-sizing: border-box;
  background: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme, readOnly, disabled }) => (disabled || readOnly ? theme.fontSecondary : theme.fontPrimary)};
  width: 100%;
  padding: 8px 10px;
  padding: ${({ leftIcon }) => (leftIcon ? '8px 12px 8px 36px' : '8px 12px')};
  border: 1px solid
    ${({ theme, isFocused, isError }) =>
      isError ? theme.colors.error : isFocused ? theme.primary : theme.borders.grey};
  border-radius: 4px;
  outline: none;
  text-align: left;

  &:focus {
    border-color: ${({ theme, isError }) => (isError ? theme.error : theme.primary)};
  }

  &[disabled] {
    border-color: ${({ theme, isError }) => (isError ? theme.isError : theme.borders.grey)};
  }
`;
/* eslint-enable */

interface TextErrorMessageProps {
  isError?: boolean;
}

export const TextErrorMessage = styled.p<TextErrorMessageProps>`
  font-size: 10px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.fontError};
  visibility: ${({ isError }) => (isError ? 'visible' : 'hidden')};
`;
