import { call, put, takeEvery } from 'redux-saga/effects';
import { NotificationType } from 'types/Notifications';
import { clientsService } from 'common/api/clientsService';
import { customersService } from 'common/api/customersService';
import { ActionWithPayload } from 'store/Action';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { ActionTypeKeys, actionCreators } from './sidebarReducer';

export function* getSidebarInfoSaga({ payload }: ActionWithPayload) {
  try {
    let clientsCount;
    const { accessToken, customerId } = payload;
    const { data: customersAllCount } = yield call(customersService.getCustomersCount, accessToken);
    const { data: clientsAllCount } = yield call(clientsService.getClientsAllCount, accessToken);

    if (customerId) {
      const { data } = yield call(clientsService.getClientsCount, { accessToken, customerId });
      // eslint-disable-next-line prefer-const
      clientsCount = data;
    }

    const prepareData = {
      customersAllCount: customersAllCount ? customersAllCount : 0,
      clientsCount: clientsCount ? clientsCount : 0,
      clientsAllCount: clientsAllCount ? clientsAllCount : 0,
    };

    yield put(actionCreators.getSidebarInfoSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getSidebarInfoFailed());
  }
}

export function* sidebarSaga() {
  yield* [takeEvery(ActionTypeKeys.GET_SIDEBAR_INFO, getSidebarInfoSaga)];
}
