import { State as CustomerState } from 'screens/Customer/logic/customerReducer';
import { State as SyncMasterDataState } from './syncMasterDataReducer';

export const getSyncMasterDataView = (customerState: CustomerState, syncMasterDataState: SyncMasterDataState) => ({
  customer: customerState.customer,
  isFetching: syncMasterDataState.isFetching,
  clients: syncMasterDataState.clients,
  totalCount: syncMasterDataState.totalCount,
  page: syncMasterDataState.page,
  perPage: syncMasterDataState.perPage,
  orderBy: syncMasterDataState.orderBy,
  sortOrder: syncMasterDataState.sortOrder,
  filterStatus: syncMasterDataState.filterStatus,
  filterSearch: syncMasterDataState.filterSearch,
  syncedData: syncMasterDataState.syncedData,
  isNotAllDataSynced: syncMasterDataState.syncedData.some((item: any) => !item.successful),
});
