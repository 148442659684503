import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Status } from 'types/Status';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 56px;
  z-index: 3;
  background: ${({ theme }) => theme.backgroundSecondary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const FilterRowColumn = styled.div`
  display: inline-flex;
  padding-right: 20px;
`;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
`;

export const FiltersRight = styled.div`
  display: flex;
  align-items: flex-start;

  & > button {
    margin-left: 10px;
  }
`;

export const FiltersColumn = styled.div`
  max-width: 200px;
  margin-right: 24px;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }
`;

export const FiltersColumnWide = styled(FiltersColumn)`
  white-space: nowrap;
  max-width: 210px;
`;

export const CustomerLogoContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const TagList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

interface TagListItemType {
  isActive: boolean;
}

export const TagListItem = styled.div<TagListItemType>`
  border: 1px solid ${({ theme, isActive }) => (!isActive ? theme.colors.dangerLightTransparent : theme.borders.grey)};
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-flex;
  align-items: center;
  background: ${({ theme, isActive }) => (!isActive ? theme.colors.dangerLightTransparent : theme.backgroundSecondary)};
`;

export const TagListImage = styled.img`
  width: 12px;
  height: auto;
  margin-left: 10px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

interface StatusLabelType {
  status: string;
}

const getStatusColor = (theme: any, status: string) => {
  if (status === Status.ACTIVE) {
    return theme.status.success;
  }
  if (status === Status.PENDING) {
    return theme.status.pending;
  }
  if (status === Status.SUSPENDED) {
    return theme.status.warning;
  }

  return theme.status.error;
};

export const StatusLabel = styled.span<StatusLabelType>`
  font-weight: 600;
  color: ${({ theme, status }) => getStatusColor(theme, status)};
`;

export const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-left: 8px;
  min-height: 30px;
  font-weight: normal;
  margin: 0;
  color: ${({ theme }) => theme.fontPrimary};

  &:hover {
    color: ${({ theme }) => theme.linkColor};
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.linkColor};
`;

export const DateWrap = styled.div`
  overflow: hidden;
  user-select: text;
`;
