import { ReactNode } from 'react';
import { WarningIcon } from 'common/components/Icons';
import { AlertText, AlertTitle, AlertWrapper, AlertIcon, IconCircle } from './styles';

interface AlertProps {
  title: ReactNode;
  text?: ReactNode;
  children?: ReactNode;
}

const Alert = ({ title, text, children }: AlertProps) => {
  return (
    <AlertWrapper>
      <AlertIcon>
        <IconCircle>
          <WarningIcon height={14} width={3} />
        </IconCircle>
      </AlertIcon>
      <AlertTitle>{title}</AlertTitle>
      {text && <AlertText>{text}</AlertText>}
      {children && children}
    </AlertWrapper>
  );
};

export default Alert;
