import * as React from 'react';
import { Key } from '../types';

interface MapCache<RecordType> {
  data?: readonly RecordType[];
  kvMap?: Map<Key, RecordType>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getRowKey?: Function;
}

export default function useLazyKVMap<RecordType>(data: readonly any[], getRowKey: any) {
  const mapCacheRef = React.useRef<MapCache<RecordType>>({});

  function getRecordByKey(key: Key): RecordType {
    if (!mapCacheRef.current || mapCacheRef.current.data !== data || mapCacheRef.current.getRowKey !== getRowKey) {
      const kvMap = new Map<Key, RecordType>();

      /* eslint-disable no-inner-declarations */
      // @ts-ignore
      function dig(records: readonly RecordType[]) {
        records.forEach((record, index) => {
          const rowKey = getRowKey(record, index);
          kvMap.set(rowKey, record);
        });
      }
      /* eslint-enable */

      dig(data);

      mapCacheRef.current = {
        data,
        kvMap,
        getRowKey,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return mapCacheRef.current.kvMap!.get(key)!;
  }

  return [getRecordByKey];
}
