import { ReactElement } from 'react';
import { Button, FlipToggle, Message } from 'common/components';
import { APP_URLS } from 'common/constants';
import { mapSystemName } from 'utils';
import { IntegrationsLevel, IntegrationsSystems, IntegrationsSystemTypes } from 'types/Integrations';
import { TableAlignment } from 'common/components/DataTable/types';
import { StyledLink, TableButtonWrapper } from './styles';

interface CustomerIntegrationsDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: CustomerIntegrationsTableRow;
}

interface CustomerIntegrationsTableRow {
  id: string;
  isActive: boolean;
  showTestConnectionButton: boolean;
  missingParentIntegrations: IntegrationsLevel[];
  systemId: string;
  systemName: string;
  systemType: IntegrationsSystemTypes;
}

export const customerIntegrationsTableColumns = (
  customerId: number,
  onFlipToggleChangeHandler: any,
  onTestConnectionClickHandler: (id: string) => void,
) => {
  return [
    {
      name: 'id',
      title: <Message id='client-integrations-table-integration-id' />,
      width: 500,
      cellCallback: ({ row }: CustomerIntegrationsDataTable) => (
        <StyledLink to={APP_URLS.toCustomerIntegrationProfile(Number(customerId), row.id)} title={row.id}>
          {row.id}
        </StyledLink>
      ),
    },
    {
      name: 'systemName',
      title: <Message id='client-integrations-table-integration-system' />,
      width: 400,
      cellCallback: ({ row }: CustomerIntegrationsDataTable) => {
        return (
          <>
            <StyledLink to={APP_URLS.toCustomerIntegrationProfile(Number(customerId), row.id)}>
              {mapSystemName(row.systemName)}
            </StyledLink>
          </>
        );
      },
    },
    {
      name: 'systemType',
      title: <Message id='client-integrations-table-integration-type' />,
      width: 250,
    },
    {
      name: 'status',
      title: <Message id='client-integrations-table-integration-status' />,
      width: 150,
      align: TableAlignment.CENTER,
      cellCallback: ({ row: { id, systemName, isActive } }: CustomerIntegrationsDataTable) => (
        <>
          <FlipToggle
            dataOn='Active'
            dataOff='Inactive'
            isChecked={isActive}
            onChange={(event: any) => onFlipToggleChangeHandler({ event, id, systemName })}
            isDisabled={systemName === IntegrationsSystems.VICAI}
          />
        </>
      ),
    },
    {
      name: 'testConnection',
      title: <Message id='client-integrations-table-integration-test-connection' />,
      width: 200,
      align: TableAlignment.CENTER,
      cellCallback: ({ row: { id, showTestConnectionButton } }: CustomerIntegrationsDataTable) => (
        <TableButtonWrapper>
          {showTestConnectionButton ? (
            <Button secondary size={'tiny'} onClick={() => onTestConnectionClickHandler(id)}>
              <Message id={'test-connection'} />
            </Button>
          ) : null}
        </TableButtonWrapper>
      ),
    },
  ];
};

export default customerIntegrationsTableColumns;
