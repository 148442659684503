import { IntegrationsStatusAction, IntegrationsSystems } from './Integrations';
import { TableDateField, TableSortOrder } from './Tables';
import { Status } from './Status';
import { AccessToken } from './Auth';

export interface CustomerData {
  id: number;
  logo: string | null;
  name: string;
  organizationNumber: string;
  status: Status;
  type: string;
  comment: string;
  systems: IntegrationsSystems[];
  latestUpdate: string;
}

export interface CustomersRequestData {
  accessToken: AccessToken;
  type?: string;
  statuses?: string[];
  page?: number;
  size?: number;
  orderBy?: string;
  shouldContainDeactivatedSystem: boolean;
  sortOrder?: TableSortOrder;
  systems?: IntegrationsSystems[];
  organizationNumber?: string;
  search?: string;
}

export interface CustomerRequestData {
  accessToken: AccessToken;
  name: string;
  organizationNumber: string;
  type: CustomerTypes | string;
  comment: string;
}

export interface CustomerRequestDataExtended extends CustomerRequestData {
  accessToken: AccessToken;
  id: number;
}

export interface CustomerStatusRequestData {
  accessToken: AccessToken;
  id: number;
  targetStatus: Status;
  statusReason?: string;
}

export enum CustomerTypes {
  UNKNOWN = 'Unknown',
  COMPANY = 'Company',
  ACCOUNTING_FIRM = 'AccountingFirm',
}

export const customerTypesNamesMapping: { [CustomerType: string]: any } = {
  [CustomerTypes.UNKNOWN]: 'Unknown',
  [CustomerTypes.COMPANY]: 'Company',
  [CustomerTypes.ACCOUNTING_FIRM]: 'Accounting Firm',
};

export interface CustomerByIdRequestData {
  accessToken: AccessToken;
  customerId: number;
}

export interface CustomersOptionsRequestData {
  accessToken: AccessToken;
  orderBy: TableDateField;
  sortOrder: TableSortOrder;
}

export interface CustomerIntegrationStatusRequest {
  accessToken: AccessToken;
  isProfilePage: boolean;
  customerId: number;
  integrationId: string;
  system: string;
  action: IntegrationsStatusAction;
}
