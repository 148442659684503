import { useCallback, useMemo, useState } from 'react';
import { subYears, isSameDay } from 'date-fns';
import { Message, Modal, Button, DatePicker, Switcher } from 'common/components';
import { CalendarIcon } from 'common/components/Icons';
import { DATE_RANGE_DATE_FORMAT, TRANSFER_HISTORICAL_DATA_YEARS_QUANTITY } from 'common/constants/DateTimeFormats';
import {
  FormButtonsCentered,
  ModalText,
  ModalTextMark,
  TransferDateRange,
  TransferDateRangeColumn,
  FormRowSwitcher,
  FormRowSwitcherLabel,
} from '../styles';

interface TransferHistoricalDataModalProps {
  isVisible: boolean;
  selectedClients: any[];
  onModalCancelHandler: () => void;
  onModalConfirmHandler: (data: any) => void;
}

const TransferHistoricalDataModal = ({
  isVisible,
  selectedClients,
  onModalCancelHandler,
  onModalConfirmHandler,
}: TransferHistoricalDataModalProps) => {
  const defaultRange = {
    dateFrom: subYears(new Date(), TRANSFER_HISTORICAL_DATA_YEARS_QUANTITY),
    dateTo: new Date(),
  };
  const [dateFrom, setDateFrom] = useState(defaultRange.dateFrom);
  const [dateTo, setDateTo] = useState(defaultRange.dateTo);
  const [isDateRangeOverride, setIsDateRangeOverride] = useState(false);

  const onModalConfirmButtonClick = useCallback(() => {
    onModalConfirmHandler({
      dateFrom,
      dateTo,
    });
  }, [dateFrom, dateTo, onModalConfirmHandler]);

  const onSwitchChangeHandler = useCallback(() => {
    setIsDateRangeOverride(!isDateRangeOverride);
    setDateFrom(defaultRange.dateFrom);
    setDateTo(defaultRange.dateTo);
  }, [isDateRangeOverride, defaultRange.dateFrom, defaultRange.dateTo]);

  const isRangeValid = useMemo(() => {
    if (!dateFrom || !dateTo) {
      return false;
    }
    if (isSameDay(dateFrom, dateTo)) {
      return true;
    }
    return dateTo > dateFrom;
  }, [dateFrom, dateTo]);

  const dateFromErrorMessage = useMemo(() => {
    if (!dateFrom) {
      return 'settings-select-date-date-field-cant-be-empty';
    }
    if (dateTo && dateFrom > dateTo) {
      return 'settings-select-date-from-cant-be-greater-then-to';
    }

    return '';
  }, [dateFrom, dateTo]);

  const dateToErrorMessage = useMemo(() => {
    if (!dateTo) {
      return 'settings-select-date-date-field-cant-be-empty';
    }
    if (dateFrom && dateFrom > dateTo) {
      return 'settings-select-date-to-cant-be-less-then-from';
    }

    return '';
  }, [dateFrom, dateTo]);

  const handleDateFromChange = (date: any) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: any) => {
    setDateTo(date);
  };

  const footerButtons = useMemo(
    () => (
      <FormButtonsCentered>
        <Button transparent onClick={onModalCancelHandler}>
          <Message id='cancel-button' />
        </Button>
        <Button primary onClick={onModalConfirmButtonClick} disabled={!isRangeValid}>
          <Message id='customer-transfer-transfer-button' />
        </Button>
      </FormButtonsCentered>
    ),
    [onModalCancelHandler, onModalConfirmButtonClick, isRangeValid],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      withScroll
      minHeight={'400px'}
      hideOnOverlayClicked={false}
      closeOnEsc={false}
      isVisible={isVisible}
      title={<Message id='customer-transfer-historical-data-modal-title' />}
      onCloseClicked={onModalCancelHandler}
      footer={footerButtons}
    >
      <FormRowSwitcher>
        <FormRowSwitcherLabel>
          <Message id='customer-transfer-historical-change-default-period-for-transfer' />
        </FormRowSwitcherLabel>
        <Switcher leftLabel='No' rightLabel='Yes' isChecked={isDateRangeOverride} onChange={onSwitchChangeHandler} />
      </FormRowSwitcher>
      <TransferDateRange>
        <TransferDateRangeColumn>
          <DatePicker
            date={dateFrom}
            withMonthYearSelect
            isError={!!dateFromErrorMessage}
            dateFormat={DATE_RANGE_DATE_FORMAT}
            leftIcon={<CalendarIcon />}
            label={<Message id={'settings-select-date-from'} />}
            errorText={<Message id={dateFromErrorMessage} />}
            startDate={dateFrom}
            maxDate={dateTo}
            onChange={handleDateFromChange}
            popperPlacement='bottom-start'
            isDisabled={!isDateRangeOverride}
            isClearable={false}
          >
            Select date range
          </DatePicker>
        </TransferDateRangeColumn>
        <TransferDateRangeColumn>
          <DatePicker
            date={dateTo}
            withMonthYearSelect
            isError={!!dateToErrorMessage}
            dateFormat={DATE_RANGE_DATE_FORMAT}
            leftIcon={<CalendarIcon />}
            label={<Message id={'settings-select-date-to'} />}
            errorText={<Message id={dateToErrorMessage} />}
            startDate={dateTo}
            maxDate={new Date()}
            onChange={handleDateToChange}
            popperPlacement='bottom-start'
            isDisabled={!isDateRangeOverride}
            isClearable={false}
          >
            Select date range
          </DatePicker>
        </TransferDateRangeColumn>
      </TransferDateRange>

      <h3>
        <Message id='customer-transfer-historical-data-modal-text' />
      </h3>
      <div>
        {selectedClients.map((client: any) => (
          <ModalText key={client.clientId}>
            {client.clientName} <ModalTextMark>{`(org.Number: ${client.organizationNumber})`}</ModalTextMark>
          </ModalText>
        ))}
      </div>
    </Modal>
  );
};

export default TransferHistoricalDataModal;
