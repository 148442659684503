export interface TablesSortOrderPayload {
  orderBy: string;
  sortOrder?: TableSortOrder;
}

export enum TableSortOrder {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export enum TableDateField {
  UPDATE_ON = 'updatedOn',
  DATE_TIME = 'dateTime',
  LAST_UPDATE = 'lastUpdate',
  LAST_EXECUTED = 'lastExecuted',
  CREATED_ON = 'createdOn',
  STARTED_DATE_TIME = 'startedDate',
  FINISHED_DATE_TIME = 'finishedDate',
}

export interface TablePerPage {
  id: number;
  label: string;
  value: string;
}
