import { Icon } from 'types/Icon';

/* eslint-disable */
export const XmlIcon = ({ color, width, height }: Icon) => (
  <svg
    x='0px'
    y='0px'
    width={width || '550.801'}
    height={height || '550.801'}
    viewBox='0 0 550.801 550.801'
    xmlSpace='preserve'
  >
    <g fill={color || 'currentColor'}>
      <g>
        <path
          d='M475.084,131.986c-0.021-2.525-0.828-5.015-2.562-6.992L366.324,3.684c-0.031-0.029-0.062-0.045-0.084-0.071
			c-0.633-0.712-1.371-1.289-2.151-1.803c-0.232-0.15-0.464-0.29-0.707-0.422c-0.675-0.372-1.392-0.669-2.13-0.891
			c-0.201-0.058-0.38-0.145-0.58-0.188C359.87,0.114,359.037,0,358.193,0H97.2C85.282,0,75.6,9.688,75.6,21.601v507.6
			c0,11.907,9.682,21.601,21.6,21.601H453.6c11.908,0,21.601-9.693,21.601-21.601V133.197
			C475.2,132.791,475.137,132.393,475.084,131.986z M177.504,510.543l-10.676-21.331c-4.369-8.211-7.162-14.328-10.481-21.151
			h-0.345c-2.447,6.823-5.424,12.94-9.094,21.151l-9.79,21.331h-30.406l34.077-59.611l-32.854-58.197h30.586l10.304,21.494
			c3.499,7.172,6.117,12.941,8.92,19.575h0.346c2.797-7.509,5.065-12.751,8.037-19.575l9.967-21.494h30.409l-33.212,57.507
			l34.965,60.302H177.504z M323.989,510.543l-1.751-45.099c-0.527-14.164-1.055-31.292-1.055-48.421h-0.517
			c-3.67,15.035-8.563,31.815-13.109,45.621l-14.333,45.969h-20.812l-12.579-45.615c-3.839-13.807-7.857-30.586-10.666-45.975
			h-0.345c-0.696,15.905-1.226,34.088-2.101,48.764l-2.089,44.751h-24.647l7.522-117.814h35.479l11.538,39.335
			c3.671,13.626,7.341,28.308,9.956,42.119h0.527c3.322-13.627,7.341-29.184,11.19-42.293l12.583-39.161h34.783l6.465,117.814
			h-26.04V510.543z M444.087,510.543H370.49V392.734h26.747v95.438h46.85V510.543z M453.6,366.747H97.2V21.601h250.192v110.51
			c0,5.962,4.842,10.8,10.801,10.8H453.6V366.747z'
        />
        <polygon
          points='118.078,234.885 180.415,263.844 180.415,250.08 133.273,229.429 133.273,229.166 180.415,208.518
			180.415,194.751 118.078,223.71 		'
        />
        <polygon
          points='376.829,208.518 425.018,229.166 425.018,229.429 376.829,250.08 376.829,263.844 439.167,235.272
			439.167,223.322 376.829,194.751 		'
        />
        <path
          d='M277.383,154.916c-41.08,0-74.382,33.302-74.382,74.379c0,41.077,33.302,74.382,74.382,74.382
			s74.377-33.305,74.377-74.382C351.76,188.217,318.463,154.916,277.383,154.916z M305.337,184.438l5.321-3.56
			c0,0-2.236-5.729-1.856-8.537c1.287-0.628,6.528,2.122,10.953,6.781c-2.979,11.093-9.972,9.77-9.972,9.77
			S304.668,188.769,305.337,184.438z M260.502,249.93c-0.33,1.661-2.333,5.664-3.663,8.321c-1.329,2.661-1.993,3.665-3.667,4.994
			c-1.667,1.35-2.655,3.665-2.655,3.665l-0.335,5.656c0,0,0.654,4.669,1.653,6.336c0.996,1.655-3.565,11.574-3.565,11.574
			c-2.895-0.574-4.503-3.928-5.508-6.575c-1.005-2.674-2.328-4.093-1.993-7.077c0.335-3.001-2.587-4.583-3.576-6.246
			c-0.991-1.686-2.331-4-2.331-5.664c0-1.669-3.657-4.002-3.657-4.002s-6.663-2.996-7.662-4.319c-1-1.334-2.01-7.011-2.334-9.326
			c-0.34-2.323,0.994-8.316,0.994-8.316s2.689-2.02,1-3.667c-1.669-1.661-1.988-6.004-1.988-6.004l-3-3.322c0,0-2.339-3.33-2.668-5
			c-0.334-1.663,0-2.663,0.335-4.329c0.33-1.674-0.665-4.662-0.665-6.32c12.443-30.504,33.302-37.621,33.302-37.621l1.669,6.977
			c0,0-3.662,1.004-5.647,0.345c-2.009-0.669-3.02-0.669-3.02-0.669l-2.652,3.657c0,0-1.015,2.336-1.345,3.67
			s0.665,3.322,0.665,3.322s4.657,0.335,4.657-0.994c0-1.334-0.654-1.999-0.654-1.999l-0.67-2.331c0,0,3.02-1.669,10.982-1.012
			c7.994,0.675,5.007,6.331,8.345,7.675c3.331,1.332-2.679,5.988-4.008,8.646c-1.329,2.666-3.327-3.333-3.327-3.333
			s1.999-2.305-1.326-2.993c-3.333-0.651-5.537,5.996-3.853,5.671c1.659-0.329,3.518,1.793,3.177,3.449
			c-0.334,1.669-0.334,1.54-1.999,5.197c-1.664,3.66-5.281,6.371-5.281,6.371s-1.701-1.037-0.717,0.967
			c1.021,1.994-0.33,6.653-0.33,7.987c0,1.323-3.997-2.334-4.651-5.672c-0.68-3.309-4.577-0.424-5.906-0.084
			c-1.334,0.34-3.765-0.58-4.406-2.231c-0.68-1.674-6.681,3.32-8.351,4.314c-1.656,1.004-0.981,3.673,1.669,2.339
			c2.663-1.334,4.991-0.335,4.332,2.344c-0.659,2.645-3.317,0.999-2.993,2.645c0.33,1.664,2.993,3.333,3.652,5.672
			c0.669,2.32,7.008,0.335,9.001-0.67c1.991-1.01,7.657-1.999,8.316,0.67c0.691,2.666,7.003,3.649,9.329,4.308
			c2.339,0.669,7,1.004,9.671,3.667C265.502,243.264,260.854,248.261,260.502,249.93z M275.484,172.007
			c-0.338,3.338-5.65,7.657-4.989,9.002c0.669,1.345,0,7.037-4.659,2.035c-4.665-4.994-9.337-6.689-8.991-10.033
			c0.082-0.665,4.822-1.706,4.898-2.874c7.062-6.565,18.339-5.215,19.203-3.66C278.923,169.776,275.822,168.674,275.484,172.007z
			 M333.345,194.369c4.503,7.726,11.085,28.334,8.653,32.015c-1.461-0.659-2.689-1.208-2.689-1.208h-8.886l5.321,6.226
			c0,0,2.578,3.778,6.228,3.536c-2.404,27.849-25.645,45.736-25.645,45.736c-3.549-3.555-1.771-6.65-1.771-6.65l0.996-5.342
			l0.564-8.985c0,0,0-9.313-8.886-4.892c-9.545,2.437-5.568,2.437-15.203,3.104c-9.656,0.67-8.058-19.686-8.058-19.686
			c0-30.473,23.366-7.475,23.366-7.475c13.331,8.867,15.108-7.354,15.108-7.354l9.761-3.538l0.887-4.448l-1.419-6.204l-14.186-5.854
			c0,0-1.366,4.608,2.272,10.275c0,0-1.213,6.436-4.197,4.772l-8.222-4.129c0,0-2.769-1.2-7.203,1.474
			c-4.451,2.655-11.976-0.132-11.976-0.132s0.611-4.464,5.283-6.782l3.818-2.521c0,0-1.002-4.559-0.127-8.105
			c0.876-3.549,2.447-0.442,5.991-3.111c3.549-2.649,5.996,5.216,11.316,4.333c5.321-0.891,2.669-1.777,6.229-3.549
			c3.56-1.777,6.217,3.549,6.217,3.549l7.088,0.883C333.978,200.312,332.87,192.288,333.345,194.369z'
        />
      </g>
    </g>
  </svg>
);
/* eslint-enable */
