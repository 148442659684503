import { ReactElement } from 'react';
import { APP_URLS } from 'common/constants';
import { Message } from 'common/components';
import { PdfIcon, XmlIcon, RetryIcon, SyncIcon, ZipIcon, FileIcon } from 'common/components/Icons';
import { TableDateField } from 'types/Tables';
import { getFormattedLongDate } from 'utils';
import { ColumnItem, TableAlignment } from 'common/components/DataTable/types';
import { ActionIcon, StatusLabel, Link, StyledLink, DownloadFileLink, TableNameWrapper, DateWrap } from './styles';
import { EInvoiceDto } from 'common/api/generated/PAIAdminApiClient';
import { RetryInfo, toRetryInfo } from './retryLogic';
import { DownloadFileInfo, DownloadFileType, toDownloadFilesInfo } from './downloadLogic';

interface AccessPointLogsDataGridRecord {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: EInvoiceDto;
}

enum KnownFileType {
  DEFAULT = 'Default',
  XML = 'Xml',
  PDF = 'Pdf',
  ZIP = 'Zip',
}

type AccessPointGridColumnsSettings = {
  onRetryButtonClick: (payload: RetryInfo, skipRejection: boolean) => void;
  onDownloadFileLinkClick: (payload: DownloadFileInfo) => void;
};

const getFileType = (file: DownloadFileInfo): KnownFileType => {
  let fileName = '';
  switch (file.type) {
    case DownloadFileType.eInvoice:
      fileName = file.record.fileName;
      break;
    case DownloadFileType.zipBatch:
      fileName = file.record.zip.ZipBatchFileName;
      break;
    default:
      throw new Error(`Unsupported file type`);
  }
  return KnownFileType[fileName.split('.').pop()?.toUpperCase() as keyof typeof KnownFileType] || KnownFileType.DEFAULT;
};

const eInvoiceActionCellCallback = (
  settings: AccessPointGridColumnsSettings,
  { row }: AccessPointLogsDataGridRecord,
) => {
  const retryInfo = toRetryInfo(row);
  const downloadFilesInfo = toDownloadFilesInfo(row);

  return (
    <>
      {downloadFilesInfo.map((fileInfo) => {
        return (
          <DownloadFileLink title='Download File' onClick={() => settings.onDownloadFileLinkClick(fileInfo)}>
            {(() => {
              switch (getFileType(fileInfo)) {
                case KnownFileType.PDF:
                  return <PdfIcon width={20} height={20} />;
                case KnownFileType.XML:
                  return <XmlIcon width={20} height={20} />;
                case KnownFileType.ZIP:
                  return <ZipIcon width={20} height={20} />;
                default:
                  return <FileIcon width={20} height={20} />;
              }
            })()}
          </DownloadFileLink>
        );
      })}
      {!!retryInfo && (
        <>
          {!retryInfo?.record?.rejected && (
            <ActionIcon title='Retry' onClick={() => settings.onRetryButtonClick(retryInfo, false)}>
              <RetryIcon width={13} />
            </ActionIcon>
          )}
          {retryInfo?.record?.rejected && (
            <ActionIcon title='Retry with skip rejection' onClick={() => settings.onRetryButtonClick(retryInfo, true)}>
              <SyncIcon width={13} />
            </ActionIcon>
          )}
        </>
      )}
    </>
  );
};

export const buildAccessPointGridColumns = (
  settings: AccessPointGridColumnsSettings,
): ColumnItem<AccessPointLogsDataGridRecord>[] => [
  {
    name: 'logsUrl',
    title: <Message id='access-point-grid-column-view-logs' />,
    width: 120,
    cellCallback: ({ row: { logsUrl } }) => (
      <Link href={logsUrl} target='_blank' rel='noopener noreferrer'>
        <Message id='access-point-grid-column-view-logs-details' />
      </Link>
    ),
  },
  {
    name: 'customerName',
    title: <Message id='access-point-grid-column-customer-name' />,
    width: 250,
    cellCallback: ({ row: { customerId, customerName } }) => (
      <StyledLink to={APP_URLS.toCustomerTransferEInvoices(Number(customerId))}>{customerName}</StyledLink>
    ),
  },
  {
    name: 'clientName',
    title: <Message id='access-point-grid-column-client-name' />,
    width: 350,
    sortable: true,
    cellCallback: ({ row: { clientId, customerId, clientName } }) =>
      clientName ? (
        <StyledLink to={APP_URLS.toClient(Number(customerId), Number(clientId))}>
          {clientName ? clientName : '-'}
        </StyledLink>
      ) : (
        '-'
      ),
  },
  {
    name: 'vendorOrgNumber',
    title: <Message id='access-point-grid-column-vendor-org-number' />,
    width: 150,
    cellCallback: ({ row: { vendorOrgNumber } }) => (
      <TableNameWrapper>{vendorOrgNumber ? vendorOrgNumber : '-'}</TableNameWrapper>
    ),
  },
  {
    name: 'status',
    title: <Message id='access-point-grid-column-status' />,
    width: 200,
    cellCallback: ({ row: { status } }) => {
      const statusFormatted = status ? status.charAt(0).toUpperCase() + status.slice(1) : '-';
      return (
        <>
          <StatusLabel status={status}>{statusFormatted}</StatusLabel>
        </>
      );
    },
  },
  {
    name: 'rejected',
    title: <Message id='access-point-grid-column-rejected' />,
    width: 200,
    cellCallback: ({ row: { rejected, skippedRejection } }) => {
      return (
        <>
          <span>{rejected ? 'Yes' : 'No' + (skippedRejection ? ' (skipped)' : '')}</span>
        </>
      );
    },
  },
  {
    name: 'sourceId',
    title: <Message id='access-point-grid-column-source-id' />,
    width: 150,
    cellCallback: ({ row: { sourceSystemExternalId } }) => (
      <TableNameWrapper>{sourceSystemExternalId ? sourceSystemExternalId : '-'}</TableNameWrapper>
    ),
  },
  {
    name: 'targetId',
    title: <Message id='access-point-grid-column-target-id' />,
    width: 150,
    cellCallback: ({ row: { targetSystemExternalId } }) => (
      <TableNameWrapper>{targetSystemExternalId ? targetSystemExternalId : '-'}</TableNameWrapper>
    ),
  },
  {
    name: TableDateField.UPDATE_ON,
    title: <Message id='access-point-grid-column-update-on' />,
    width: 250,
    sortable: true,
    cellCallback: ({ row: { updatedOn } }) => <DateWrap>{getFormattedLongDate(updatedOn)}</DateWrap>,
  },
  {
    name: 'action',
    title: <Message id='access-point-grid-column-action' />,
    width: 200,
    align: TableAlignment.CENTER,
    cellCallback: (record) => eInvoiceActionCellCallback(settings, record),
  },
];
