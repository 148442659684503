import { Message } from 'common/components/Message';
import { IntegrationSystem } from 'types/Integrations';
import AccessPointGrid from 'components/AccessPointGrid/AccessPointGrid';
import { Head, Title, Content } from './styles';

interface TransferEInvoicesAllProps {
  integrations: IntegrationSystem[];
}

const TransferEInvoicesAll = ({ integrations }: TransferEInvoicesAllProps) => {
  const integrationsAvailable = integrations.length > 0;

  return (
    <>
      <Head>
        <Title>
          <Message id='transfer-einvoices-page-title' />
        </Title>
      </Head>
      <Content>{integrationsAvailable ? <AccessPointGrid accessPoints={integrations} /> : null}</Content>
    </>
  );
};

export default TransferEInvoicesAll;
