import { AxiosInstance } from 'axios';
import {
  createAuthAxiosInstanceWithUrl,
  createAuthAxiosInstanceWithBlob,
  createAxiosInstanceWithUrlMultipart,
} from './helpers';

export const integrationApiUrl = process.env.REACT_APP_API_URL;

export let integrationsServiceRequest: AxiosInstance;
export let integrationsServiceBlobRequest: AxiosInstance;
export let integrationsServiceDataRequest: AxiosInstance;

const setConfig = () => {
  if (integrationApiUrl) {
    integrationsServiceRequest = createAuthAxiosInstanceWithUrl(integrationApiUrl);
    integrationsServiceBlobRequest = createAuthAxiosInstanceWithBlob(integrationApiUrl);
    integrationsServiceDataRequest = createAxiosInstanceWithUrlMultipart(integrationApiUrl);
  } else {
    throw new Error('REACT_APP_API_URL is missed');
  }
};

setConfig();
