/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from 'react';
import { useAuthenticatedAdminApiClient } from 'hooks';
import { LegalEntity } from 'common/api/generated/PAIAdminApiClient';
import { AlertModal, Alert, Button, Message } from 'common/components';
// import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';

import { PropellApFormButtons, PropellApAlerts } from './styles';

type PropellApFormProps = {
  customerId: number;
  clientId: number;
  clientName: string;
  propellAp: LegalEntity | undefined;
  hideGenericError: boolean;
  onOnboarded?: () => void;
  onOffboarded?: () => void;
  onUpdated?: () => void;
};

const PropellApForm = ({
  customerId,
  clientId,
  clientName,
  propellAp,
  hideGenericError,
  onOnboarded,
  onOffboarded,
  onUpdated,
}: PropellApFormProps) => {
  const adminApiClient = useAuthenticatedAdminApiClient();

  const [showGenericError, setShowGenericError] = React.useState(false);
  const [specificError, setSpecificError] = React.useState('');

  const [isOnboardModalVisible, setIsOnboardModalVisible] = React.useState(false);
  const [isOnboardInProgress, setIsOnboardInProgress] = React.useState(false);

  const [isOffboardModalVisible, setIsOffboardModalVisible] = React.useState(false);
  const [isOffboardInProgress, setIsOffboardInProgress] = React.useState(false);

  const [isUpdateModalVisible, setIsUpdateModalVisible] = React.useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = React.useState(false);

  React.useEffect(() => {
    setShowGenericError(propellAp ? !hideGenericError && propellAp.identifiers?.length === 0 : false);
  }, [propellAp, hideGenericError]);

  // handles onboarding of Propell AP
  const onOnboard = React.useCallback(() => {
    if (!adminApiClient) return;

    setIsOnboardInProgress(true);
    adminApiClient.accessPointControllerAddPropellAccessPoint(Number(customerId), Number(clientId))
      .then(() => {
        onOnboarded && onOnboarded();
        setShowGenericError(false);
        setSpecificError('');
      })
      .catch((error) => {
        setShowGenericError(false);
        setSpecificError(error.title);
      })
      .finally(() => {
        setIsOnboardInProgress(false);
        setIsOnboardModalVisible(false);
      });
  }, [adminApiClient, customerId, clientId, onOnboarded]);

  // handles offboarding of Propell AP
  const onOffboard = React.useCallback(() => {
    if (!adminApiClient || !propellAp) return;

    setIsOffboardInProgress(true);
    adminApiClient.accessPointControllerRemovePropellAccessPoint(propellAp.id!)
      .then(() => {
        onOffboarded && onOffboarded();
        setShowGenericError(false);
        setSpecificError('');
      })
      .finally(() => {
        setIsOffboardInProgress(false);
        setIsOffboardModalVisible(false);
      });
  }, [adminApiClient, propellAp, onOffboarded]);

  // handles updating of Propell AP
  const onUpdate = React.useCallback(() => {
    if (!adminApiClient || !propellAp) return;

    setIsUpdateInProgress(true);
    adminApiClient.accessPointControllerUpdatePropellAccessPoint(propellAp.id!, { partyName: clientName })
      .then(() => {
        onUpdated && onUpdated();
        setShowGenericError(false);
        setSpecificError('');
      })
      .finally(() => {
        setIsUpdateInProgress(false);
        setIsUpdateModalVisible(false);
      });
  }, [adminApiClient, propellAp, clientName, onUpdated]);

  // rendering of onboard actions
  const renderOnboardActions = () => (
    <>
      <AlertModal
        isVisible={isOnboardModalVisible}
        title={<Message id='propellap-confirm-onboard' />}
        content={<Message id='propellap-confirm-onboard-desc' />}
        loading={isOnboardInProgress}
        onCancel={() => setIsOnboardModalVisible(false)}
        onConfirm={() => onOnboard()}
      />
      <Button primary size='large' onClick={() => setIsOnboardModalVisible(true)}>
        Onboard
      </Button>
    </>
  );

  // rendering of offboard actions
  const renderOffboardActions = () => (
    <>
      <AlertModal
        isVisible={isOffboardModalVisible}
        title={<Message id='propellap-confirm-offboard' />}
        content={<Message id='propellap-confirm-offboard-desc' />}
        loading={isOffboardInProgress}
        onCancel={() => setIsOffboardModalVisible(false)}
        onConfirm={() => onOffboard()}
      />
      <Button danger size='large' onClick={() => setIsOffboardModalVisible(true)}>
        Offboard
      </Button>
    </>
  );

  // rendering of update actions
  const renderUpdateActions = () => (
    <>
      <AlertModal
        isVisible={isUpdateModalVisible}
        title={<Message id='propellap-confirm-update' />}
        content={<Message id='propellap-confirm-update-desc' />}
        loading={isUpdateInProgress}
        onCancel={() => setIsUpdateModalVisible(false)}
        onConfirm={() => onUpdate()}
      />
      <Button primary size='large' onClick={() => setIsUpdateModalVisible(true)}>
        Update
      </Button>
    </>
  );

  return (
    <>
      {(showGenericError || specificError) && (
        <PropellApAlerts>
          {showGenericError && (
            <Alert 
              title={<Message id='propellap-generic-error-title' />}
              text={<Message id='propellap-generic-error-description' />}
            />
          )}
          {specificError && (
            <Alert 
              title={<Message id='propellap-specific-error-title' />}
              text={(
                <>
                  {specificError}. 
                  {specificError?.includes('identifier') && <Message id='propellap-generic-error-description' />}
                </>
              )}
            />
          )}
        </PropellApAlerts>
      )}
      <PropellApFormButtons>
        {!propellAp && renderOnboardActions()}
        {propellAp && propellAp.identifiers!.length === 0 && renderOnboardActions()}
        {propellAp && propellAp.identifiers!.length > 0 && renderUpdateActions()}
        {propellAp && renderOffboardActions()}
      </PropellApFormButtons>
    </>
  );
};

export default PropellApForm;
