import { APP_URLS } from 'common/constants';
import { FolderIcon, UsersIcon, HamburgerIcon, SyncIcon, FileIcon, CheckListIcon } from 'common/components/Icons';

export const SUB_NAVIGATION_ITEMS = [
  {
    path: APP_URLS.toCustomerProfile,
    name: 'customerProfile',
    title: 'Customer Profile',
    messageId: 'sub-nav-customer-profile',
    icon: FolderIcon,
  },
  {
    path: APP_URLS.toCustomerClients,
    name: 'clients',
    title: 'Clients',
    messageId: 'sub-nav-clients',
    icon: UsersIcon,
  },
  {
    path: APP_URLS.toCustomerOperations,
    name: 'operationStatus',
    title: 'Operation Status',
    messageId: 'sub-nav-customer-operation-status',
    icon: CheckListIcon,
  },
  {
    path: APP_URLS.toCustomerTransferHistoricalData,
    name: 'transferHistoricalData',
    title: 'Transfer Historical Data',
    messageId: 'sub-nav-customer-transfer-historical-data',
    icon: HamburgerIcon,
  },
  {
    path: APP_URLS.toCustomerSyncMasterData,
    name: 'syncMasterData',
    title: 'Sync Master Data',
    messageId: 'sub-nav-customer-sync-master-data',
    icon: SyncIcon,
  },
  {
    path: APP_URLS.toCustomerTransferEInvoices,
    name: 'transferEInvoices',
    title: 'Transfer E-Invoices',
    messageId: 'sub-nav-customer-transfer-einvoices',
    icon: FileIcon,
  },
];
