import { useParams } from 'react-router-dom';
import { IntegrationData } from 'types/Integrations';
import AccessPointGrid from 'components/AccessPointGrid/AccessPointGrid';
import { Head, Title, Content } from './styles';

type customerUrlParams = {
  customerId: string;
};

interface TransferEInvoicesProps {
  isFetching: boolean;
  customerAndAllClientsIntegrations: IntegrationData[];
}

const TransferEInvoices = ({ customerAndAllClientsIntegrations }: TransferEInvoicesProps) => {
  const { customerId } = useParams<customerUrlParams>();
  const customerOrClientIntegrationsExists = customerAndAllClientsIntegrations.length > 0;

  return (
    <>
      <Head>
        <Title>Transfer E-Invoices</Title>
      </Head>
      <Content>
        {customerOrClientIntegrationsExists ? (
          <AccessPointGrid
            customerId={parseInt(customerId)}
            accessPoints={customerAndAllClientsIntegrations.map((x) => ({ id: x.systemId, name: x.systemName, type: x.systemType }))}
          />
        ) : null}
      </Content>
    </>
  );
};

export default TransferEInvoices;
