import { Icon } from 'types/Icon';

export const ArrowLeftIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '8'} height={height || '13'} viewBox='0 0 8 13'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M3.68587493,2.31412507 L-1.60912507,7.31614936 C-1.97694224,7.66361498 -1.99344044,8.24346624 -1.64597483,8.61128342 C-1.63404348,8.6239136 -1.62175526,8.63620182 -1.60912507,8.64813317 L-1.59008659,8.66611825 C-1.20497542,9.02992096 -0.602965494,9.03024866 -0.217458495,8.66686542 L3.68587493,4.98753938 L3.68587493,4.98753938 L7.58920835,8.66686542 C7.97471535,9.03024866 8.57672528,9.02992096 8.96183644,8.66611825 L8.98087493,8.64813317 C9.3486921,8.30066756 9.3651903,7.72081629 9.01772468,7.35299912 C9.00579333,7.34036893 8.99350511,7.32808071 8.98087493,7.31614936 L3.68587493,2.31412507 L3.68587493,2.31412507 Z'
        id='path-ArrowLeftIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-265.000000, -3134.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(145.314125, 77.685875)'>
            <use
              fill={color || 'currentColor'}
              transform='translate(3.735875, 6.050000) rotate(-90.000000) translate(-3.735875, -6.050000) '
              xlinkHref='#path-ArrowLeftIcon-1'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
