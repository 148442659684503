import { useEffect, useState } from 'react';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { SubscriptionMonitoring as SubscriptionMonitoringData } from 'common/api/generated/PAIAdminApiClient';
import { RefreshIcon } from 'common/components/Icons';
import { Message, Spinner, DataTable, Button } from 'common/components';
import { Wrapper, FiltersWrapper, FilterRow, FiltersLeft, FiltersRight } from '../styles';
import subscriptionsMonitoringTableColumns from './subscriptionsMonitoringTableColumns';

const SubscriptionsMonitoring = () => {
  // table
  const adminClient = useAuthenticatedAdminApiClient();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [subscriptionMonitoring, setSubscriptionMonitoring] = useState<SubscriptionMonitoringData>();
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const onRefreshButtonClick = () => setRefreshKey(refreshKey + 1);

  // fetching of invoice monitoring from api
  useEffect(() => {
    setIsFetching(true);
    adminClient
      ?.monitoringControllerGetSubscriptionMonitoring()
      .finally(() => setIsFetching(false))
      .catch((error) => console.error(error))
      .then((data) => {
        if (!data) return;
        setSubscriptionMonitoring(data);
      });
  }, [adminClient, refreshKey]);

  return (
    <Wrapper>
      <FiltersWrapper>
        <FilterRow>
          <FiltersLeft>{}</FiltersLeft>
          <FiltersRight>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </FiltersRight>
        </FilterRow>
      </FiltersWrapper>
      <DataTable
        isFetching={isFetching}
        data={subscriptionMonitoring?.failedSubscriptions ?? []}
        loadingComponent={Spinner}
        columns={subscriptionsMonitoringTableColumns}
        emptyMessage={<Message id='no-data-message' />}
      />
    </Wrapper>
  );
};

export default SubscriptionsMonitoring;
