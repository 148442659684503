import styled from 'styled-components';

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const PaginatorInfo = styled.div`
  font-size: 12px;
`;

export const PaginatorControls = styled.div``;

export const ItemsPerPage = styled.div`
  display: inline-block;
  width: 100px;
  vertical-align: middle;
  margin-right: 10px;

  :global(.Select .Select-input) {
    height: 28px;
  }

  :global(.Select-placeholder),
  :global(.Select--single > .Select-control .Select-value) {
    line-height: 28px;
  }

  :global(.Select .Select-control) {
    height: 29px;
  }
`;

export const PaginationWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface ArrowType {
  isDisabled: boolean;
}

export const Arrow = styled.div<ArrowType>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.borders.grey};
  background: ${({ theme }) => theme.backgroundPrimary};
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.fontSecondary : theme.fontPrimary)};

  span {
    display: block;
    line-height: 35px;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &:hover {
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    color: ${({ theme, isDisabled }) => (isDisabled ? theme.fontSecondary : theme.primary)};
  }
`;

export const ArrowPrevious = styled(Arrow)`
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const ArrowNext = styled(Arrow)`
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const PaginationInput = styled.input`
  display: inline-block;
  box-sizing: content-box;
  padding: 4px 0;
  min-height: auto;
  height: 26px;
  width: 48px;
  text-align: center;
  font-size: 13px;
  border: 1px solid ${({ theme }) => theme.form.border};
  border-radius: 0;
  outline: 0;
  min-width: auto;
`;

export const PaginationLabel = styled.span`
  line-height: 36px;
  padding: 0 10px;
`;
