import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

export const Title = styled.h2`
  display: flex;
  align-items: baseline;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 44px;
  min-height: 44px;
  margin: 0;
  letter-spacing: -0.5;
`;

export const TitleMark = styled.span`
  display: inline-block;
  padding-left: 10px;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.fontSecondary};
`;

export const HeadActions = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Content = styled.div``;

export const TabsWrapper = styled.div``;

export const TabsList = styled.div`
  display: flex;
`;

const activeClassName = 'active';

export const TabButton = styled(NavLink).attrs({ activeClassName })`
  position relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px;
  min-width: 100px;
  text-align: center;
  margin-left: 5px;
  border-radius: 6px 6px 0 0;
  color: ${({ theme, isActive }) => (isActive ? theme.fontPrimary : theme.fontSecondary)};
  background: ${({ theme }) => theme.backgroundThird};
  box-shadow: 0 -3px 10px rgba(0,0,0, 0.02);

  &:hover {
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

    &:after {
      width: 80%;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    height: 2px;
    background: ${({ theme }) => theme.primary};
    transform: translateX(-50%);
    transition: width .3s ease;
  }

  &.${activeClassName} {
    background: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.fontPrimary};

    &:after {
      width: 80%;
    }
  }
`;

export const TabContent = styled.div`
  background: ${({ theme }) => theme.backgroundPrimary};
  padding: 25px 20px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.07);
`;

export const TabContentInner = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 25px 15px;
  position: relative;
`;

export const FormButtonsCentered = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    min-width: 100px;
    margin: 0 10px;
  }
`;

export const WarningTitle = styled.div`
  padding-right: 40px;
  color: ${({ theme }) => theme.fontError};
  display: inline-flex;
  align-items: center;
`;

export const IconCircle = styled.div`
  width: 25px;
  height: 25px;
  color: ${({ theme }) => theme.fontError};
  border: 2px solid ${({ theme }) => theme.fontError};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;
