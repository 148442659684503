import { TableSortOrder } from './Tables';
import { AccessToken } from './Auth';

export interface SyncClientData {
  clientId: number;
  organizationNumber: string;
  clientName: string;
  status: SyncMasterDataStatus;
  lastExecuted: null | string;
  transferable: boolean;
}

export interface SyncClientDataExtended extends SyncClientData {
  id: number;
}

export interface SyncClientsRequestData {
  accessToken: AccessToken;
  customerId: number;
  page?: number;
  size?: number;
  orderBy?: string;
  sortOrder?: TableSortOrder;
  organizationNumber?: string;
  search?: string;
  statuses?: SyncMasterDataStatus[];
  applyStatusFilter?: boolean;
}

export interface ExecuteClientsRequestData {
  accessToken: AccessToken;
  customerId: number;
  clientIds: number[];
}

export interface ExecuteMasterDataByClientIdRequestData {
  accessToken: AccessToken;
  customerId: number;
  clientId: number;
}

export interface SyncClients {
  data: SyncClientDataExtended[];
  totalCount: number;
}

export enum SyncMasterDataStatus {
  All = 'All',
  NONE = 'None',
  START = 'Start',
  IN_PROGRESS = 'InProgress',
  PARTIAL_SUCCESS = 'PartialSuccess',
  SUCCESS = 'Success',
  FAILURE = 'Failure',
}

export const synMasterDataStatusNamesMapping: { [OperationStatus: string]: any } = {
  [SyncMasterDataStatus.NONE]: 'None',
  [SyncMasterDataStatus.START]: 'Start',
  [SyncMasterDataStatus.IN_PROGRESS]: 'In Progress',
  [SyncMasterDataStatus.PARTIAL_SUCCESS]: 'Partial Success',
  [SyncMasterDataStatus.SUCCESS]: 'Success',
  [SyncMasterDataStatus.FAILURE]: 'Failure',
};

export enum SyncedDataReasonCode {
  CLIENT_NOT_FOUND_EXCEPTION = 10,
  DUPLICATE_OPERATION_RUN_EXCEPTION = 20,
  INTEGRATION_SETUP_EXCEPTION = 30,
  CLIENT_IS_NOT_AVAILABLE = 40,
  OPTION_IS_DISABLED_FOR_SOME_CLIENTS = 50,
  EXCEPTION = 100,
}

export interface SyncedDataItem {
  clientId: number;
  successful: boolean;
  reason: null | string;
  reasonCode: SyncedDataReasonCode;
}
