// import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { APP_URLS } from 'common/constants';
import { Message } from 'common/components';
import { InvoiceMonitoringContainer } from './InvoiceMonitoring';
import { SubscriptionsMonitoringContainer } from './SubscriptionsMonitoring';

import { Content, Head, TabButton, TabContent, TabContentInner, TabsList, TabsWrapper, Title } from './styles';

const Monitoring = () => {
  return (
    <>
      <Head>
        <Title>
          <Message id='monitoring-screen-title' />
        </Title>
      </Head>
      <Content>
        <TabsWrapper>
          <TabsList>
            <TabButton exact to={APP_URLS.monitoringInvoice}>
              <Message id='monitoring-screen-invoice-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.monitoringSubscriptions}>
              <Message id='monitoring-screen-subscriptions-tab' />
            </TabButton>
          </TabsList>
          <TabContent>
            <TabContentInner>
              <Switch>
                <Route exact path={APP_URLS.monitoringInvoice} render={() => <InvoiceMonitoringContainer />} />
                <Route
                  exact
                  path={APP_URLS.monitoringSubscriptions}
                  render={() => <SubscriptionsMonitoringContainer />}
                />
              </Switch>
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default Monitoring;
