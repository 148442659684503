import { Icon } from 'types/Icon';

export const ListIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '18'} height={height || '20'} viewBox='0 0 18 20'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M15.5,2 L2.5,2 C2.2295695,2 2,2.2295695 2,2.5 L2,17.5 C2,17.7704305 2.2295695,18 2.5,18 L15.5,18 C15.7704305,18 16,17.7704305 16,17.5 L16,2.5 C16,2.2295695 15.7704305,2 15.5,2 Z M15.5,0 C16.875,0 18,1.125 18,2.5 L18,17.5 C18,18.875 16.875,20 15.5,20 L2.5,20 C1.125,20 0,18.875 0,17.5 L0,2.5 C0,1.125 1.125,0 2.5,0 L15.5,0 Z M8,12 L5,12 C4.44771525,12 4,12.4477153 4,13 C4,13.5522847 4.44771525,14 5,14 L5,14 L8,14 C8.55228475,14 9,13.5522847 9,13 C9,12.4477153 8.55228475,12 8,12 L8,12 Z M12,8 L5,8 C4.44771525,8 4,8.44771525 4,9 C4,9.55228475 4.44771525,10 5,10 L5,10 L12,10 C12.5522847,10 13,9.55228475 13,9 C13,8.44771525 12.5522847,8 12,8 L12,8 Z M13,4 L5,4 C4.44771525,4 4,4.44771525 4,5 C4,5.55228475 4.44771525,6 5,6 L5,6 L13,6 C13.5522847,6 14,5.55228475 14,5 C14,4.44771525 13.5522847,4 13,4 L13,4 Z'
        id='path-ListIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-122.000000, -3295.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(2.000000, 188.000000)'>
            <use fill={color || 'currentColor'} fillRule='nonzero' xlinkHref='#path-ListIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
