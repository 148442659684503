import { TableSortOrder } from './Tables';
import { AccessToken } from './Auth';

export interface TransferClientData {
  clientId: number;
  organizationNumber: string;
  clientName: string;
  status: TransferStatus;
  lastExecuted: null | string;
  transferable: boolean;
}

export interface TransferClientDataExtended extends TransferClientData {
  id: number;
}

export interface TransferClientsRequestData {
  accessToken: AccessToken;
  customerId: number;
  page?: number;
  size?: number;
  orderBy?: string;
  sortOrder?: TableSortOrder;
  organizationNumber?: string;
  search?: string;
  statuses?: TransferStatus[];
  applyStatusFilter?: boolean;
}

export interface ExecuteClientsRequestData {
  accessToken: AccessToken;
  customerId: number;
  clientIds: number[];
  fromDateUtc: string;
  toDateUtc: string;
}

export interface ExecuteHistoricalDataByClientIdRequestData {
  accessToken: AccessToken;
  customerId: number;
  clientId: number;
  fromDateUtc: string;
  toDateUtc: string;
}

export interface TransferClients {
  data: TransferClientDataExtended[];
  totalCount: number;
}

export enum TransferStatus {
  All = 'All',
  NONE = 'None',
  START = 'Start',
  IN_PROGRESS = 'InProgress',
  PARTIAL_SUCCESS = 'PartialSuccess',
  SUCCESS = 'Success',
  FAILURE = 'Failure',
}

export const transferStatusNamesMapping: { [OperationStatus: string]: any } = {
  [TransferStatus.NONE]: 'None',
  [TransferStatus.START]: 'Start',
  [TransferStatus.IN_PROGRESS]: 'In Progress',
  [TransferStatus.PARTIAL_SUCCESS]: 'Partial Success',
  [TransferStatus.SUCCESS]: 'Success',
  [TransferStatus.FAILURE]: 'Failure',
};

export enum TransferredDataReasonCode {
  CLIENT_NOT_FOUND_EXCEPTION = 10,
  DUPLICATE_OPERATION_RUN_EXCEPTION = 20,
  INTEGRATION_SETUP_EXCEPTION = 30,
  CLIENT_IS_NOT_AVAILABLE = 40,
  OPTION_IS_DISABLED_FOR_SOME_CLIENTS = 50,
  EXCEPTION = 100,
}

export interface TransferredDataItem {
  clientId: number;
  successful: boolean;
  reason: null | string;
  reasonCode: TransferredDataReasonCode;
}
