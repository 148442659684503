import { TableSortOrder } from './Tables';
import { IntegrationsInfo, IntegrationsSystems } from './Integrations';
import { OperationStatus } from './Operations';
import { AccessToken } from './Auth';

export interface NotificationProps {
  id?: string;
  autoDeleteEnabled?: boolean;
  autoDeleteTime?: number;
  type?: NotificationTypes;
  title?: string;
  errorTraceId?: string;
  messageId?: string;
  messageVariable?: string;
  errorText?: string; // TODO: change to messageText
  errorStatus?: string;
  timeOut?: number;
  onClick?: () => void;
  onHide?: () => void;
}

export enum NotificationType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type NotificationTypes =
  | NotificationType.INFO
  | NotificationType.SUCCESS
  | NotificationType.WARNING
  | NotificationType.ERROR;

// types for Notifications page
export interface NotificationsRequestData {
  accessToken: AccessToken;
  page?: number;
  size?: number;
  orderBy?: string;
  sortOrder?: TableSortOrder;
  systems?: IntegrationsSystems[];
  operationStatuses?: OperationStatus[];
  isRead?: string;
  notificationTypes?: string[];
  createdByName?: string;
  customerIds?: number[];
  clientIds?: number[];
}

export interface NotificationData {
  id: number;
  type: NotificationsType;
  isRead: boolean;
  createdOn: string | Date;
  updatedOn: string | Date;
  createdById: string | null;
  createdByName: string | null;
  customerId: number;
  customerName: string;
  clientId: number;
  clientName: string;
  operationStatus?: string;
  syncId?: string | undefined;
  syncStartedOn?: string | Date | undefined;
  syncFinishedOn?: string | Date | undefined;
  integrations: IntegrationsInfo[];
}

export interface NotificationsList {
  data: NotificationData[];
  totalCount: number;
}

export enum NotificationsType {
  NONE = 'None',
  SYNC_HISTORIC_DATA_STARTED = 'SyncHistoricDataStarted',
  SYNC_HISTORIC_DATA_FINISHED = 'SyncHistoricDataFinished',
}

export interface NotificationUpdateRequestData {
  accessToken: AccessToken;
  id: number;
  isRead: boolean;
  pathname: string;
}

export enum NotificationsStatus {
  NONE = 'None',
  SUCCESS = 'Success',
  PARTIAL_SUCCESS = 'PartialSuccess',
  FAILURE = 'Failure',
}
