import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SwitcherContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

interface InnerType {
  isDisable?: boolean;
}

export const Inner = styled.label<InnerType>`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
`;

interface SliderType {
  isDisable?: boolean;
}

export const Slider = styled.span<SliderType>`
  position: absolute;
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.buttons.default};
  transition: 0.4s;
  border-radius: 34px;
  opacity: ${({ isDisable }) => (isDisable ? '0.65' : '1')};
  border: 1px solid transparent;

  &:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

interface InputType {
  disabled?: boolean;
}

export const Input = styled.input<InputType>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.primary};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 3px ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
  }

  &:checked + ${Slider}:before {
    transform: translateX(15px);
  }
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontSecondary};
`;

export const LeftLabel = styled(Label)`
  margin-right: 10px;
`;

export const RightLabel = styled(Label)`
  margin-left: 10px;
`;

export const SwitcherLabel = styled.span`
  font-size: 12px;
  line-height: 24px;
  padding-right: 10px;
`;
