import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.header`
  display: flex;
  flex: 1;
  height: 56px;
  background: ${({ theme }) => theme.backgroundPrimary};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 16px;
`;

interface LogoContainerType {
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
  isSubNavExists: boolean;
}

const getLogoFlex = (isMainNavCollapsed: boolean, isSubNavCollapsed: boolean, isSubNavExists: boolean) => {
  if (isSubNavExists && isMainNavCollapsed && !isSubNavCollapsed) {
    return '0 0 324px';
  }
  if (isSubNavExists && !isMainNavCollapsed && isSubNavCollapsed) {
    return '0 0 324px';
  }
  if (isSubNavExists && isMainNavCollapsed && isSubNavCollapsed) {
    return '0 0 164px';
  }
  if (!isSubNavExists && isMainNavCollapsed) {
    return '0 0 112px';
  }

  return '0 0 264px';
};

const getLogoWidth = (isMainNavCollapsed: boolean, isSubNavCollapsed: boolean, isSubNavExists: boolean) => {
  if (isSubNavExists && isMainNavCollapsed && !isSubNavCollapsed) {
    return '324px';
  }
  if (isSubNavExists && !isMainNavCollapsed && isSubNavCollapsed) {
    return '324px';
  }
  if (isSubNavExists && isMainNavCollapsed && isSubNavCollapsed) {
    return '164px';
  }
  if (!isSubNavExists && isMainNavCollapsed) {
    return '112px';
  }
  if (!isSubNavExists && !isMainNavCollapsed) {
    return '264px';
  }

  return '264px';
};

export const LogoContainer = styled.div<LogoContainerType>`
  flex: ${({ isMainNavCollapsed, isSubNavCollapsed, isSubNavExists }) =>
    getLogoFlex(isMainNavCollapsed, isSubNavCollapsed, isSubNavExists)};
  width: ${({ isMainNavCollapsed, isSubNavCollapsed, isSubNavExists }) =>
    getLogoWidth(isMainNavCollapsed, isSubNavCollapsed, isSubNavExists)};
  text-align: left;
  display: inline-flex;
  align-items: center;
`;

export const LogoLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;
export const HeaderContent = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderBreadcrumbs = styled.div``;

export const HeaderActions = styled.div`
  display: flex;
`;

export const HeaderActionItem = styled.div`
  position: relative;
  margin-left: 20px;
  display: flex;
  align-items: center;
`;
