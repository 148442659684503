export enum Dictionaries {
  CLIENT_STATUSES = 'ClientStatuses',
  CUSTOMER_STATUSES = 'CustomerStatuses',
  CUSTOMER_TYPES = 'CustomerTypes',
  EINVOICE_STATUSES = 'EInvoiceStatuses',
  NOTIFICATION_TYPES = 'NotificationTypes',
  OPERATION_SCOPE_TYPES = 'OperationScopeTypes',
  OPERATION_TYPES = 'OperationTypes',
  OPERATION_STATUSES = 'OperationStatuses',
  ZIP_BATCH_STATUSES = 'ZipBatchStatuses',
}
