import { IntegrationsSystemTypes } from 'types/Integrations';

export const isAccessIntegrationExists = (integrations: any) =>
  !!integrations.find((item: any) => item.systemType === IntegrationsSystemTypes.ACCESS_POINT);

export const getErrorTraceId = (errorTraceId?: string) => {
  if (!errorTraceId) {
    return '';
  }
  const convertToParts = errorTraceId.split('-');
  if (convertToParts.length > 1) {
    return convertToParts[1];
  }

  return errorTraceId;
};
