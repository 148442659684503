import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Message, Spinner, DataTable, ButtonLink, Button } from 'common/components';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { APP_URLS } from 'common/constants';
import { TransformationType, Transformation } from 'common/api/generated/PAITransformationApiClient';
import { ClientData } from 'types/Client';
import { RefreshIcon } from 'common/components/Icons';
import { useAccessToken } from 'hooks';
import clientTransformationListTableColumns from './transformationListTableColumns';
import getClientTransformationListBreadcrumbs from './getClientTransformationListBreadcrumbs';

import { TableWrapper, TableHeader, TableTitle, TableHeaderActions } from './styles';

type clientUrlParams = {
  customerId: string;
  clientId: string;
};

interface TransformationListProps {
  isFetching: boolean;
  client: ClientData;
  availableTransformationTypes: TransformationType[];
  transformations: Transformation[];
  getAvailableTransformationTypes: (payload: { accessToken: string }) => void;
  getClientTransformations: (payload: { accessToken: string; customerId: number; clientId: number }) => void;
  updateClientTransformationStatus: (payload: {
    accessToken: string;
    customerId: number;
    clientId: number;
    transformationId: string;
    active: boolean;
  }) => void;
}

const TransformationList = ({
  isFetching,
  client,
  availableTransformationTypes,
  transformations,
  getAvailableTransformationTypes,
  getClientTransformations,
  updateClientTransformationStatus,
}: TransformationListProps) => {
  const accessToken = useAccessToken();
  const { customerId, clientId } = useParams<clientUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);

  useEffect(() => {
    setBreadcrumbs(
      getClientTransformationListBreadcrumbs(Number(customerId), Number(clientId), client.customerName, client.name),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, setBreadcrumbs]);

  useEffect(() => {
    if (accessToken) {
      getAvailableTransformationTypes({ accessToken });
    }
  }, [accessToken, getAvailableTransformationTypes]);

  useEffect(() => {
    if (accessToken) {
      getClientTransformations({ accessToken, customerId: Number(customerId), clientId: Number(clientId) });
    }
  }, [accessToken, getClientTransformations, customerId, clientId]);

  useEffect(() => {
    // eslint-disable-next-line
    const notAddedTransformationTypes = availableTransformationTypes.filter(
      (t) => !transformations.map((tr) => tr.typeId).includes(t.id),
    );
    setIsCreateButtonDisabled(!notAddedTransformationTypes.length);
  }, [availableTransformationTypes, transformations]);

  const onRefreshButtonClick = () => {
    getClientTransformations({
      accessToken: accessToken || '',
      customerId: Number(customerId),
      clientId: Number(clientId),
    });
  };

  const onFlipToggleChangeHandler = ({ event, id }: any) => {
    updateClientTransformationStatus({
      accessToken: accessToken || '',
      customerId: Number(customerId),
      clientId: Number(clientId),
      transformationId: id,
      active: event.target.checked,
    });
  };

  return (
    <>
      <TableWrapper>
        <TableHeader>
          <TableTitle>
            <Message id='client-transformations-table-added-transformations-title' />
          </TableTitle>
          <TableHeaderActions>
            <ButtonLink
              primary='true'
              to={APP_URLS.toCreateClientTransformation(Number(customerId), Number(clientId))}
              disabled={isCreateButtonDisabled}
            >
              <Message id='client-transformations-table-add-new-transformation' />
            </ButtonLink>
            <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
              <RefreshIcon width={20} height={20} />
            </Button>
          </TableHeaderActions>
        </TableHeader>
        <DataTable
          isFetching={isFetching}
          data={transformations}
          loadingComponent={Spinner}
          columns={clientTransformationListTableColumns(
            Number(customerId),
            Number(clientId),
            onFlipToggleChangeHandler,
          )}
          emptyMessage={<Message id='no-data-message' />}
        />
      </TableWrapper>
    </>
  );
};

export default TransformationList;
