import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { AuthError } from '@azure/msal-browser';
import { PropellLogoIntegrations } from 'common/components/Icons';
import { Message, Spinner } from 'common/components';
import { loginRequest } from 'config/authConfig';
import { handleLoginResponse } from 'config/auth';
import {
  Wrapper,
  LoginContainer,
  LoginHolder,
  Title,
  SubTitle,
  LogoContainer,
  ButtonContainer,
  ButtonCentered,
} from './styles';

interface LoginProps {
  isFetching: boolean;
}

const Login = ({ isFetching }: LoginProps) => {
  const { instance } = useMsal();

  const handleLogin = useCallback(() => {
    instance
      .loginPopup(loginRequest)
      .then(handleLoginResponse)
      .catch((error: AuthError) => {
        // eslint-disable-next-line no-console
        console.log('%c Error loginPopup', 'color:red; font-weight: 700; font-size: 12px;', error);
        return;
      });
  }, [instance]);

  return (
    <Wrapper>
      <LoginContainer>
        <LogoContainer>
          <PropellLogoIntegrations width={84} height={32} />
        </LogoContainer>
        <LoginHolder>
          <Title>
            <Message id={'login-screen-sign-in-title'} />
          </Title>
          <SubTitle>
            <Message id={'login-screen-use-your-google-account'} />
          </SubTitle>
          <ButtonContainer>
            {isFetching && <Spinner overlay={false} />}
            <ButtonCentered primary onClick={() => handleLogin()}>
              <Message id={'login-screen-sign-in'} />
            </ButtonCentered>
          </ButtonContainer>
        </LoginHolder>
      </LoginContainer>
    </Wrapper>
  );
};

export default Login;
