import { IntegrationsLevel } from 'types/Integrations';

export const isCustomerSystemExists = (customerSystems: any, currentSystem: any, integrationSchema: any) => {
  const isCustomerLevelRequired = Array.isArray(integrationSchema?.requiredParentLevels)
    ? integrationSchema.requiredParentLevels.includes(IntegrationsLevel.CUSTOMER)
    : false;
  if (!isCustomerLevelRequired) {
    return true;
  }

  if (!customerSystems.length) {
    return false;
  }

  return !(
    customerSystems
      .map(function (integration: any) {
        return integration.systemName;
      })
      .indexOf(currentSystem) < 0
  );
};
