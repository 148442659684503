import { useState, useEffect, useCallback } from 'react';
import { PreviousIcon, NextIcon } from 'common/components/Icons';
import { Message } from 'common/components';
import { ArrowNext, ArrowPrevious, PaginationContainer, PaginationInput, PaginationLabel } from './styles';

interface PaginationProps {
  page: number;
  totalItemsCount: number;
  itemsCountPerPage: number;
  onPageChange: (payload: any) => void;
}

const Pagination = ({ page, totalItemsCount, itemsCountPerPage, onPageChange }: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState(1);
  const [isPrevPageDisabled, setIsPrevPageDisabled] = useState(false);
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const getTotalPages = useCallback(
    () => Math.ceil(totalItemsCount / itemsCountPerPage),
    [totalItemsCount, itemsCountPerPage],
  );
  useEffect(() => {
    if (page) {
      setCurrentPage(page);
      setInputValue(page);
    }
  }, [page]);

  useEffect(() => {
    setIsPrevPageDisabled(currentPage <= 1);
    setIsNextPageDisabled(currentPage >= getTotalPages());
    setIsInputDisabled(currentPage === 1 && currentPage === getTotalPages());
  }, [currentPage, getTotalPages]);

  const callCallback = (selectedItem: any) => {
    if (typeof onPageChange !== 'undefined' && typeof onPageChange === 'function') {
      onPageChange({ selected: selectedItem });
    }

    // if (scrollOnChange) {
    //   scrollTo(scrollOnChange, scrollOptions);
    // }
  };

  const handlePageSelected = (page: number, event: any) => {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }

    if (inputValue === page) {
      return;
    }

    setInputValue(page);

    // Call the callback with the new page item:
    callCallback(page);
  };

  const handlePreviousPage = (event: any) => {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    if (currentPage > 1) {
      handlePageSelected(currentPage - 1, event);
    }
  };

  const handlePageChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handlePageBlur = (event: any) => {
    let inputValue = parseInt(event.target.value);
    if (!inputValue || inputValue < 1 || inputValue > getTotalPages()) {
      inputValue = getTotalPages();
    }
    handlePageSelected(inputValue, event);
  };

  const handlePageKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handlePageBlur(event);
    }
  };

  const handleHighlightTextOnClick = (event: any) => event.target.setSelectionRange(0, event.target.value.length);

  const handleNextPage = (event: any) => {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    if (currentPage < getTotalPages()) {
      handlePageSelected(currentPage + 1, event);
    }
  };

  return (
    <PaginationContainer>
      <PaginationLabel>
        <Message id='pagination-page' />
      </PaginationLabel>
      <ArrowPrevious isDisabled={isPrevPageDisabled}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <span role='button' tabIndex={0} onClick={handlePreviousPage}>
          <PreviousIcon />
        </span>
      </ArrowPrevious>
      <div>
        <PaginationInput
          type='numeric'
          value={inputValue}
          maxLength={5}
          onChange={handlePageChange}
          onBlur={handlePageBlur}
          onKeyPress={handlePageKeyPress}
          disabled={isInputDisabled}
          onClick={handleHighlightTextOnClick}
        />
      </div>
      <ArrowNext isDisabled={isNextPageDisabled}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/tabindex-no-positive */}
        <span role='button' tabIndex={2} onClick={handleNextPage}>
          <NextIcon />
        </span>
      </ArrowNext>
      <PaginationLabel>
        of <strong>{getTotalPages()}</strong> pages
      </PaginationLabel>
    </PaginationContainer>
  );
};

export default Pagination;
