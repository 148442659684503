import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/clientReducer';
import { getTransformationView } from '../../logic/clientSelectors';
import TransformationEdit from './TransformationEdit';

const mapStateToProps = ({ client }: RootState) => getTransformationView(client);

const mapDispatchToProps = {
  resetPartial: actionCreators.clientResetPartial,
  setStatus: actionCreators.setClientStatus,
  getClient: actionCreators.getClient,
  getClientTransformation: actionCreators.getClientTransformation,
  getClientTransformationSchema: actionCreators.getClientTransformationSchema,
  updateClientTransformation: actionCreators.updateClientTransformation,
  updateClientTransformationStatus: actionCreators.updateClientTransformationStatus,
  integrationReset: actionCreators.integrationReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(TransformationEdit);
