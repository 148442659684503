import { put, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import messagesEn from 'assets/translations/translation_en.json';
import messagesNo from 'assets/translations/translation_no.json';
import { actionCreators, ActionTypeKeys } from 'common/reducers/intlReducer';

export function* changeLanguageSaga({ payload }: ActionWithPayload) {
  try {
    const intlData = {
      locale: payload === 'en' ? 'en' : 'en', // TODO: change to correct Norwegian locale 'no' or 'nn' or other...
      messages: payload === 'en' ? messagesEn : messagesNo,
    };

    yield put(actionCreators.changeLanguageSuccess(intlData));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* intlSaga() {
  yield* [takeEvery(ActionTypeKeys.INTL_CHANGE_LANGUAGE, changeLanguageSaga)];
}
