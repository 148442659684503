import { checkIsFirstLastCharactersSpaces } from './formHelpers';

export const convertValidationRegex = (validationRegex: string) => {
  // eslint-disable-next-line no-useless-escape
  const regexOptionsPattern = new RegExp('^[(][?][ims]{1,3}[)]');
  const regexOptions = regexOptionsPattern.exec(validationRegex);

  if (regexOptions) {
    const validationStringFormatted = validationRegex.replace(regexOptions[0], '');
    const regexOptionsFormatted = regexOptions[0].replace(/[^ims]/g, '');

    return new RegExp(validationStringFormatted, regexOptionsFormatted);
  }

  return new RegExp(validationRegex);
};

export const validateStringValue = (isRequired: boolean, value: string, stringId: string, validationRegex?: string) => {
  const pattern = validationRegex ? convertValidationRegex(validationRegex) : null;

  if (!value) {
    return isRequired ? `${stringId}-cant-be-empty` : true;
  }
  const valueWithReplacedAllSpaces = value.replace(/\s/g, '');

  if (valueWithReplacedAllSpaces === '') {
    return `${stringId}-cant-be-empty`;
  }

  if (checkIsFirstLastCharactersSpaces(value)) {
    return `${stringId}-cant-start-end-with-spaces`;
  }

  if (pattern && !pattern.test(value)) {
    return `${stringId}-error`;
  }

  return true;
};

export const validateRegexValue = (isRequired: boolean, value: string, stringId: string, validationRegex?: string) => {
  const validation = validateStringValue(isRequired, value, stringId, validationRegex);
  if (validation !== true) return validation;

  try {
    new RegExp(value);
    return true;
  } catch (e) {
    return 'error-invalid-regex';
  }
};

export const validateOrganizationNumber = (value: string) => {
  if (!value) {
    return 'error-organization-number-cant-be-empty';
  }
  const valueWithReplacedAllSpaces = value.replace(/\s/g, '');

  if (valueWithReplacedAllSpaces === '') {
    return 'error-organization-number-cant-be-empty';
  }

  if (checkIsFirstLastCharactersSpaces(value)) {
    return 'error-organization-number-cant-start-end-with-spaces';
  }

  return true;
};

export const validateStatusReason = (reason: string) => {
  if (!reason) {
    return 'error-reason-field-cant-be-empty';
  }
  if (reason.length > 200) {
    return 'error-reason-field-200-symbols';
  }
  if (reason.length < 5) {
    return 'error-reason-field-should-be-at-least-5-symbols';
  }

  const reasonWithReplacedAllSpaces = reason.replace(/\s/g, '');

  if (reasonWithReplacedAllSpaces === '') {
    return 'error-reason-field-cant-be-empty';
  }

  if (checkIsFirstLastCharactersSpaces(reason)) {
    return 'error-reason-field-cant-start-end-with-spaces';
  }

  return '';
};

export const validateScriptId = (value: string) => {
  if (Number(value) < 0) {
    return 'error-this-field-cant-be-negative';
  }

  return true;
};

export const validateCronValue = (isRequired: boolean, value: string, stringId: string, validationRegex: any) => {
  const pattern = new RegExp(validationRegex);

  if (!value) {
    return isRequired ? `${stringId}-cant-be-empty` : true;
  }

  if (validationRegex && !pattern.test(value)) {
    return `${stringId}-error`;
  }

  if (checkIsFirstLastCharactersSpaces(value)) {
    return `${stringId}-cant-start-end-with-spaces`;
  }

  return true;
};

export const validateUuid = (value: string) => {
  const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$');
  const valueWithReplacedAllSpaces = value.replace(/\s/g, '');

  if (!value) {
    return true;
  }

  if (valueWithReplacedAllSpaces === '') {
    return false;
  }

  return pattern.test(value);
};

export const validateCountryCode = (value: string) =>
  validateStringValue(true, value, 'create-client-country-code', '^[A-Z]{2}$');

export const validateEmail = (value: string, required: boolean) =>
  validateStringValue(
    required,
    value,
    'create-client-email',
    '^\\s*[\\w\\-\\+_]+(\\.[\\w\\-\\+_]+)*\\@[\\w\\-\\+_]+\\.[\\w\\-\\+_]+(\\.[\\w\\-\\+_]+)*\\s*$',
  );
