import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { IntegrationSystem } from '../../types/Integrations';
import { AccessToken } from '../../types/Auth';

export enum ActionTypeKeys {
  SET_MAIN_NAV_COLLAPSED = 'SHARED/SET_MAIN_NAV_COLLAPSED',
  SET_SUB_NAV_COLLAPSED = 'SHARED/SET_SUB_NAV_COLLAPSED',
  GET_INTEGRATION_SYSTEMS = 'SHARED/GET_INTEGRATION_SYSTEMS',
  GET_INTEGRATION_SYSTEMS_SUCCESS = 'SHARED/GET_INTEGRATION_SYSTEMS_SUCCESS',
  GET_INTEGRATION_SYSTEMS_FAILED = 'SHARED/GET_INTEGRATION_SYSTEMS_FAILED',
  GET_DICTIONARIES = 'SHARED/GET_DICTIONARIES',
  GET_DICTIONARIES_SUCCESS = 'SHARED/GET_DICTIONARIES_SUCCESS',
  GET_DICTIONARIES_FAILED = 'SHARED/GET_DICTIONARIES_FAILED',
}

export const actionCreators = {
  setMainNavCollapsed: (payload: boolean) => createAction(ActionTypeKeys.SET_MAIN_NAV_COLLAPSED, payload),
  setSubNavCollapsed: (payload: boolean) => createAction(ActionTypeKeys.SET_SUB_NAV_COLLAPSED, payload),
  getIntegrationSystems: (payload: AccessToken) => createAction(ActionTypeKeys.GET_INTEGRATION_SYSTEMS, payload),
  getIntegrationSystemsSuccess: (payload: IntegrationSystem[]) =>
    createAction(ActionTypeKeys.GET_INTEGRATION_SYSTEMS_SUCCESS, payload),
  getIntegrationSystemsFailed: () => createAction(ActionTypeKeys.GET_INTEGRATION_SYSTEMS_FAILED),
  getDictionaries: (payload: AccessToken) => createAction(ActionTypeKeys.GET_DICTIONARIES, payload),
  getDictionariesSuccess: (payload: any) => createAction(ActionTypeKeys.GET_DICTIONARIES_SUCCESS, payload),
  getDictionariesFailed: () => createAction(ActionTypeKeys.GET_DICTIONARIES_FAILED),
};

export interface State {
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
  integrationSystems: IntegrationSystem[];
  dictionaries: any;
}

export const initialState: State = {
  isMainNavCollapsed: false,
  isSubNavCollapsed: false,
  integrationSystems: [] as IntegrationSystem[],
  dictionaries: {},
};

export const sharedReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.SET_MAIN_NAV_COLLAPSED: {
      return {
        ...state,
        isMainNavCollapsed: action.payload,
      };
    }

    case ActionTypeKeys.SET_SUB_NAV_COLLAPSED: {
      return {
        ...state,
        isSubNavCollapsed: action.payload,
      };
    }

    case ActionTypeKeys.GET_INTEGRATION_SYSTEMS:
    case ActionTypeKeys.GET_DICTIONARIES:
      return {
        ...state,
      };

    case ActionTypeKeys.GET_INTEGRATION_SYSTEMS_SUCCESS:
      return {
        ...state,
        integrationSystems: action.payload,
      };

    case ActionTypeKeys.GET_DICTIONARIES_SUCCESS:
      return {
        ...state,
        dictionaries: action.payload,
      };

    case ActionTypeKeys.GET_INTEGRATION_SYSTEMS_FAILED:
    case ActionTypeKeys.GET_DICTIONARIES_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
