import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Paginator, DataTable, Spinner, Message, Select, Input, Button, DatePicker } from 'common/components';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { OperationDataExtended, OperationsRequestData } from 'types/Operations';
import { TablePerPage, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { FormSelectOption } from 'types/Form';
import { ClientData } from 'types/Client';
import { useSearchInputFocus, useAccessToken, useUpdateSelection } from 'hooks';
import { CalendarIcon, RefreshIcon } from 'common/components/Icons';
import {
  getArrayOfOptionsValues,
  getDateFromErrorMessage,
  getDateToErrorMessage,
  getSelectedMultiOptions,
  increaseDateTo,
} from 'utils';
import {
  DATEPICKER_TIME_FORMAT as timeFormat,
  DATEPICKER_TIME_INTERVAL as timeIntervals,
  FULL_DATE_RANGE_DATE_FORMAT,
} from 'common/constants/DateTimeFormats';
import getClientOperationsBreadcrumbs from './getClientOperationsBreadcrumbs';
import { clientOperationsTableColumns } from './operationsListTableColumns';
import {
  TableWrapper,
  Wrapper,
  FiltersWrapper,
  FiltersLeft,
  FiltersRight,
  FiltersColumnsWrapper,
  FiltersColumn,
  FiltersColumnWide,
  FiltersColumnDateRange,
} from './styles';

type clientUrlParams = {
  customerId: string;
  clientId: string;
};

interface OperationsListProps {
  isFetching: boolean;
  client: ClientData;
  operations: OperationDataExtended[];
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterSourceEntityId: string;
  filterTargetEntityId: string;
  filterScope: FormSelectOption[];
  filterType: FormSelectOption[];
  filterStatus: FormSelectOption[];
  filterDateFrom: null | Date;
  filterDateTo: null | Date;
  filterTraceId: string;
  operationsScopeOptions: FormSelectOption[];
  operationsTypeOptions: FormSelectOption[];
  operationsStatusOptions: FormSelectOption[];
  getOperations: (payload: OperationsRequestData) => void;
  setOperationsTablePage: (payload: number) => void;
  setOperationsTablePerPage: (payload: number) => void;
  setOperationsTableOrder: (payload: TablesSortOrderPayload) => void;
  setFilterOperationScope: (payload: FormSelectOption[]) => void;
  setFilterOperation: (payload: FormSelectOption[]) => void;
  setFilterStatus: (payload: FormSelectOption[]) => void;
  setFilterDateFrom: (payload: null | Date) => void;
  setFilterDateTo: (payload: null | Date) => void;
  setFilterTraceId: (payload: string) => void;
  setFilterSourceEntityId: (payload: string) => void;
  setFilterTargetEntityId: (payload: string) => void;
  reset: () => void;
}

const OperationsList = ({
  isFetching,
  client,
  operations,
  totalCount,
  page,
  perPage,
  orderBy,
  sortOrder,
  filterScope,
  filterType,
  filterStatus,
  filterDateFrom,
  filterDateTo,
  filterTraceId,
  filterSourceEntityId,
  filterTargetEntityId,
  operationsScopeOptions,
  operationsTypeOptions,
  operationsStatusOptions,
  getOperations,
  setOperationsTablePage,
  setOperationsTablePerPage,
  setOperationsTableOrder,
  setFilterOperationScope,
  setFilterOperation,
  setFilterStatus,
  setFilterDateFrom,
  setFilterDateTo,
  setFilterTraceId,
  setFilterSourceEntityId,
  setFilterTargetEntityId,
  reset,
}: OperationsListProps) => {
  const accessToken = useAccessToken();
  const { customerId, clientId } = useParams<clientUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isFiltersDisabled, setFiltersDisabled] = useState(true);
  const [setIsSearchInputChanged, inputRef, setSearchInputFocus] = useSearchInputFocus();
  const [setIsInputSourceEntityIdChanged, inputSourceEntityId, setInputSourceEntityIdFocus] = useSearchInputFocus();
  const [setIsInputTargetEntityIdChanged, inputTargetEntityId, setInputTargetEntityIdFocus] = useSearchInputFocus();
  const requestData = {
    accessToken,
    customerIds: [Number(customerId)],
    clientIds: [Number(clientId)],
    page: 1,
    size: Number(perPage),
    orderBy: orderBy,
    sortOrder: sortOrder,
    operationScopes:
      filterScope[0].value === operationsScopeOptions[0].value ? undefined : getArrayOfOptionsValues(filterScope),
    operations:
      filterType[0].value === operationsTypeOptions[0].value ? undefined : getArrayOfOptionsValues(filterType),
    statuses:
      filterStatus[0].value === operationsStatusOptions[0].value ? undefined : getArrayOfOptionsValues(filterStatus),
    startedDate: filterDateFrom ? filterDateFrom.toISOString() : undefined,
    finishedDate: filterDateTo ? increaseDateTo(filterDateTo).toISOString() : undefined,
    traceId: filterTraceId ? filterTraceId : undefined,
    sourceEntityId: filterSourceEntityId ? filterSourceEntityId : undefined,
    targetEntityId: filterTargetEntityId ? filterTargetEntityId : undefined,
  };
  useUpdateSelection();

  useEffect(() => {
    setInputSourceEntityIdFocus();
  }, [isFetching, setInputSourceEntityIdFocus]);

  useEffect(() => {
    setInputTargetEntityIdFocus();
  }, [isFetching, setInputTargetEntityIdFocus]);

  useEffect(() => {
    setSearchInputFocus();
  }, [isFetching, setSearchInputFocus]);

  useEffect(() => {
    if (accessToken) {
      getOperations({
        accessToken,
        customerIds: [Number(customerId)],
        clientIds: [Number(clientId)],
        page: page,
        size: perPage,
        orderBy: orderBy,
        sortOrder: sortOrder,
      });
    }

    return () => reset();
  }, [accessToken, customerId, clientId, getOperations, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setBreadcrumbs(
      getClientOperationsBreadcrumbs(Number(customerId), Number(clientId), client.customerName, client.name),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, setBreadcrumbs]);

  useEffect(() => {
    setFiltersDisabled(
      isFetching ||
        (!totalCount &&
          filterStatus[0].value === operationsStatusOptions[0].value &&
          filterScope[0].value === operationsScopeOptions[0].value &&
          filterType[0].value === operationsTypeOptions[0].value),
    );
  }, [
    isFetching,
    totalCount,
    filterStatus,
    filterScope,
    filterType,
    operationsStatusOptions,
    operationsScopeOptions,
    operationsTypeOptions,
  ]);

  const handleOnPageChange = (page: { selected: number }) => {
    setOperationsTablePage(page.selected);
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      page: page.selected,
    });
  };

  const handleOnItemsPerPageChange = (perPage: TablePerPage) => {
    setOperationsTablePage(1);
    setOperationsTablePerPage(Number(perPage.value));
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      size: Number(perPage.value),
    });
  };

  const handleTableSortChange = (name: string, direction: TableSortOrder) => {
    setOperationsTableOrder({ orderBy: name, sortOrder: direction });
    setOperationsTablePage(1);
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      orderBy: name,
      sortOrder: direction,
    });
  };

  const handleTableFilterOperationScopeChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, operationsScopeOptions, setFilterOperationScope);
    getOperations({
      ...requestData,
      operationScopes:
        selectedOptions[0].value === operationsScopeOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const handleTableFilterOperationChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, operationsTypeOptions, setFilterOperation);
    getOperations({
      ...requestData,
      operations:
        selectedOptions[0].value === operationsTypeOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const handleTableFilterStatusChange = (event: FormSelectOption[]) => {
    setOperationsTablePage(1);
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, operationsStatusOptions, setFilterStatus);
    getOperations({
      ...requestData,
      statuses:
        selectedOptions[0].value === operationsStatusOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
  };

  const debouncedSearchByTraceId = useDebouncedCallback((value: string) => {
    getOperations({
      ...requestData,
      traceId: value,
    });
  }, 300);

  const handleSearchByTraceId = (value: string) => {
    if (value !== filterTraceId) {
      setIsSearchInputChanged(true);
      setIsInputSourceEntityIdChanged(false);
      setIsInputTargetEntityIdChanged(false);
    }
    setFilterTraceId(value);
    setOperationsTablePage(1);
    debouncedSearchByTraceId(value);
  };

  const onRefreshButtonClick = () => {
    setIsSearchInputChanged(false);
    setIsInputSourceEntityIdChanged(false);
    setIsInputTargetEntityIdChanged(false);
    getOperations({
      ...requestData,
      page: page,
    });
  };

  const debouncedFilterBySourceEntityId = useDebouncedCallback((value: string) => {
    getOperations({
      ...requestData,
      sourceEntityId: value ? value : undefined,
    });
  }, 500);

  const handleFilterBySourceEntityId = (value: string) => {
    if (value !== filterSourceEntityId) {
      setIsSearchInputChanged(false);
      setIsInputSourceEntityIdChanged(true);
      setIsInputTargetEntityIdChanged(false);
    }
    setFilterSourceEntityId(value);

    if (value.trim().length !== 0 || filterSourceEntityId.trim().length) {
      debouncedFilterBySourceEntityId(value);
      setOperationsTablePage(1);
    }
  };

  const debouncedFilterByTargetEntityId = useDebouncedCallback((value: string) => {
    getOperations({
      ...requestData,
      targetEntityId: value ? value : undefined,
    });
  }, 500);

  const handleFilterByTargetEntityId = (value: string) => {
    if (value !== filterTargetEntityId) {
      setIsSearchInputChanged(false);
      setIsInputTargetEntityIdChanged(true);
      setIsInputSourceEntityIdChanged(false);
    }
    setFilterTargetEntityId(value);

    if (value.trim().length !== 0 || filterTargetEntityId.trim().length) {
      debouncedFilterByTargetEntityId(value);
      setOperationsTablePage(1);
    }
  };

  const dateFromErrorMessage = getDateFromErrorMessage(filterDateFrom, filterDateTo);
  const dateToErrorMessage = getDateToErrorMessage(filterDateFrom, filterDateTo);

  const handleDateFromChange = (date: null | Date) => {
    setFilterDateFrom(date);
    const isFromDateValid = !getDateFromErrorMessage(date, filterDateTo);

    if (date === null && isFromDateValid) {
      setIsSearchInputChanged(false);
      setIsInputTargetEntityIdChanged(false);
      setIsInputSourceEntityIdChanged(false);
      getOperations({
        ...requestData,
        startedDate: undefined,
      });
      setOperationsTablePage(1);
    }
  };

  const handleDateFromOnClose = () => {
    const isToDateValid = !getDateToErrorMessage(filterDateFrom, filterDateTo);

    if (filterDateFrom !== null && isToDateValid) {
      setIsSearchInputChanged(false);
      setIsInputTargetEntityIdChanged(false);
      setIsInputSourceEntityIdChanged(false);
      getOperations({
        ...requestData,
      });
      setOperationsTablePage(1);
    }
  };

  const handleDateToChange = (date: null | Date) => {
    setFilterDateTo(date);
    const isToDateValid = !getDateToErrorMessage(filterDateFrom, date);

    if (date === null && isToDateValid) {
      setIsSearchInputChanged(false);
      setIsInputTargetEntityIdChanged(false);
      setIsInputSourceEntityIdChanged(false);
      getOperations({
        ...requestData,
        finishedDate: undefined,
      });
      setOperationsTablePage(1);
    }
  };

  const handleDateToOnClose = () => {
    const isToDateValid = !getDateToErrorMessage(filterDateFrom, filterDateTo);

    if (filterDateTo !== null && isToDateValid) {
      setIsSearchInputChanged(false);
      setIsInputTargetEntityIdChanged(false);
      setIsInputSourceEntityIdChanged(false);
      getOperations({
        ...requestData,
      });
      setOperationsTablePage(1);
    }
  };

  return (
    <Wrapper>
      <FiltersWrapper>
        <FiltersLeft>
          <FiltersColumnsWrapper>
            <FiltersColumn>
              <Select
                isMulti
                isClearable={false}
                id='filterScope'
                value={filterScope}
                options={operationsScopeOptions}
                label={<Message id={'operations-table-filter-operation-scope'} />}
                onChange={handleTableFilterOperationScopeChange}
                disabled={isFiltersDisabled}
              />
            </FiltersColumn>
            <FiltersColumn>
              <Select
                isMulti
                isClearable={false}
                id='filterType'
                value={filterType}
                options={operationsTypeOptions}
                label={<Message id={'operations-table-filter-operation'} />}
                onChange={handleTableFilterOperationChange}
                disabled={isFiltersDisabled}
              />
            </FiltersColumn>
            <FiltersColumn>
              <Select
                isMulti
                isClearable={false}
                id='filterOperationsStatus'
                value={filterStatus}
                options={operationsStatusOptions}
                label={<Message id={'operations-table-filter-status'} />}
                onChange={handleTableFilterStatusChange}
                disabled={isFiltersDisabled}
              />
            </FiltersColumn>
          </FiltersColumnsWrapper>
          <FiltersColumnsWrapper>
            <FiltersColumnDateRange>
              <DatePicker
                date={filterDateFrom}
                withMonthYearSelect
                isError={!!dateFromErrorMessage}
                dateFormat={FULL_DATE_RANGE_DATE_FORMAT}
                showTimeSelect={true}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                leftIcon={<CalendarIcon />}
                label={<Message id={'settings-select-date-from'} />}
                errorText={<Message id={dateFromErrorMessage} />}
                startDate={filterDateFrom}
                maxDate={filterDateTo ? filterDateTo : new Date()}
                onChange={handleDateFromChange}
                popperPlacement='bottom-start'
                isDisabled={isFetching}
                isClearable={!!filterDateFrom}
                requestOnClose={handleDateFromOnClose}
              >
                Select date range
              </DatePicker>
            </FiltersColumnDateRange>
            <FiltersColumnDateRange>
              <DatePicker
                date={filterDateTo}
                withMonthYearSelect
                isError={!!dateToErrorMessage}
                dateFormat={FULL_DATE_RANGE_DATE_FORMAT}
                showTimeSelect={true}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                leftIcon={<CalendarIcon />}
                label={<Message id={'settings-select-date-to'} />}
                errorText={<Message id={dateToErrorMessage} />}
                startDate={filterDateTo}
                maxDate={new Date()}
                onChange={handleDateToChange}
                popperPlacement='bottom-start'
                isDisabled={isFetching}
                isClearable={!!filterDateTo}
                requestOnClose={handleDateToOnClose}
              >
                Select date range
              </DatePicker>
            </FiltersColumnDateRange>
          </FiltersColumnsWrapper>
          <FiltersColumnsWrapper>
            <FiltersColumnWide>
              <Input
                inputRef={inputRef}
                disabled={isFetching}
                id='filterTraceId'
                type='text'
                label={<Message id='operations-table-filter-traceId' />}
                value={filterTraceId}
                onChange={handleSearchByTraceId}
                placeholder='Enter an entire Trace Id here'
              />
            </FiltersColumnWide>
            <FiltersColumnWide>
              <Input
                inputRef={inputSourceEntityId}
                disabled={isFetching}
                id='filterSourceEntityId'
                type='text'
                label={<Message id='operations-table-filter-source-entity-id' />}
                value={filterSourceEntityId}
                onChange={handleFilterBySourceEntityId}
                placeholder='Source Entity Id'
              />
            </FiltersColumnWide>
            <FiltersColumnWide>
              <Input
                inputRef={inputTargetEntityId}
                disabled={isFetching}
                id='filterTargetEntityId'
                type='text'
                label={<Message id='operations-table-filter-target-entity-id' />}
                value={filterTargetEntityId}
                onChange={handleFilterByTargetEntityId}
                placeholder='Target Entity Id'
              />
            </FiltersColumnWide>
          </FiltersColumnsWrapper>
        </FiltersLeft>
        <FiltersRight>
          <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
            <RefreshIcon width={20} height={20} />
          </Button>
        </FiltersRight>
      </FiltersWrapper>
      <TableWrapper>
        <DataTable
          isFetching={isFetching}
          data={operations}
          loadingComponent={Spinner}
          columns={clientOperationsTableColumns}
          emptyMessage={<Message id='no-data-message' />}
          orderBy={orderBy}
          sortOrder={sortOrder}
          onSortChange={handleTableSortChange}
        />
        <Paginator
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          onItemsPerPageChange={handleOnItemsPerPageChange}
          onPageChange={handleOnPageChange}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default OperationsList;
