import { APP_URLS } from 'common/constants';
import { Message } from 'common/components';
import { featureFlags } from 'featureFlags';
import { TabButton, TabsList } from './styles';

// TODO - there are lost of other similarities (copy-pastes) between pages where this component is being reused
// Do apply DRY principle at some point in the future.
const CustomerTabsList = ({ customerId }: { customerId: number }) => (
  <TabsList>
    <TabButton to={APP_URLS.toCustomerData(Number(customerId))}>
      <Message id='customer-profile-customer-data-tab' />
    </TabButton>
    <TabButton to={APP_URLS.toCustomerIntegrations(Number(customerId))}>
      <Message id='customer-profile-integrations-tab' />
    </TabButton>
    {featureFlags.customerPortal && (
      <TabButton to={APP_URLS.toCustomerPortal(Number(customerId))}>
        <Message id='customer-profile-portal-tab' />
      </TabButton>
    )}
  </TabsList>
);

export default CustomerTabsList;
