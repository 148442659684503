import { Icon } from 'types/Icon';

export const CalendarIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '18'} height={height || '17'} viewBox='0 0 18 17'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M15,0 C15.552,0 16,0.448 16,1 L16,2 C17.105,2 18,2.895 18,4 L18,15 C18,16.105 17.105,17 16,17 L2,17 C0.895,17 0,16.105 0,15 L0,4 C0,2.895 0.895,2 2,2 L2,1 C2,0.448 2.448,0 3,0 C3.552,0 4,0.448 4,1 L4,2 L14,2 L14,1 C14,0.448 14.448,0 15,0 Z M15,6 L3,6 C2.448,6 2,6.448 2,7 L2,7 L2,14 C2,14.552 2.448,15 3,15 L3,15 L15,15 C15.552,15 16,14.552 16,14 L16,14 L16,7 C16,6.448 15.552,6 15,6 L15,6 Z M13,11 C13.552,11 14,11.448 14,12 C14,12.552 13.552,13 13,13 C12.448,13 12,12.552 12,12 C12,11.448 12.448,11 13,11 Z'
        id='path-CalendarIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-253.000000, -3242.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(133.000000, 135.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-CalendarIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
