import styled from 'styled-components';

export const RadioButtonContainer = styled.div`
  display: inline-block;
`;

export const LabelWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
`;

export const LabelText = styled.span`
  font-style: normal;
  font-size: 12px;
  color: ${({ theme }) => theme.fontPrimary};
`;

export const StyledRadioButton = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background: ${({ theme }) => theme.backgroundPrimary};
  border: 1px solid ${({ theme }) => theme.borders.grey};
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 50%;
  transition: all 0.1s;
  margin-right: 10px;
  color: ${({ theme }) => theme.primary};

  ${LabelWrapper}:hover & {
    border: 1px solid ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.form.activeBackground};
  }

  &:after {
    display: none;
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    width: 6px;
    height: 6px;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
  }
`;

export const HiddenRadioButton = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &:checked + ${StyledRadioButton} {
    background: ${({ theme }) => theme.backgroundPrimary};
    border: 1px solid ${({ theme }) => theme.primary};

    &:after {
      display: block;
    }
  }

  // &:focused + ${StyledRadioButton} {
  //     border: 1px solid ${({ theme }) => theme.primary};
  // }
`;
