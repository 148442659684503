import { Icon } from 'types/Icon';

export const TrashIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '16'} height={height || '18'} viewBox='0 0 16 18'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M9,0 C10.105,0 11,0.895 11,2 L11,3 L15,3 C15.552,3 16,3.448 16,4 C16,4.552 15.552,5 15,5 L15,15 C15,16.657 13.657,18 12,18 L4,18 C2.343,18 1,16.657 1,15 L1,5 C0.448,5 0,4.552 0,4 C0,3.448 0.448,3 1,3 L5,3 L5,2 C5,0.895 5.895,0 7,0 L9,0 Z M13,5 L3,5 L3,15 C3,15.552 3.448,16 4,16 L12,16 C12.552,16 13,15.552 13,15 L13,5 Z M9,2 L7,2 L7,3 L9,3 L9,2 Z'
        id='path-TrashIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1250.000000, -3296.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(1130.000000, 189.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-TrashIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
