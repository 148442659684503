import { call, put, takeEvery } from 'redux-saga/effects';
import { browserHistory } from 'browserHistory';
import { APP_URLS, ErrorCodes } from 'common/constants';
import { ActionWithPayload } from 'store/Action';
import { clientsService } from 'common/api/clientsService';
import { OperationData } from 'types/Operations';
import { NotificationType } from 'types/Notifications';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { integrationsService } from 'common/api/integrationsService';
import { getErrorTraceId } from 'utils/integrations';
import { createAdminApiClient, createTransformationApiClient } from 'hooks/usePaiClient';
import {
  Schema,
  TransformationType,
  Transformation,
} from 'common/api/generated/PAITransformationApiClient';
import {
  CreateTransformationRequest,
  UpdateTransformationRequest,
} from 'common/api/generated/PAIAdminApiClient';
import { ActionTypeKeys, actionCreators } from './clientReducer';


export function* getClientSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientById, payload);

    yield put(actionCreators.getClientSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientFailed());
  }
}

export function* updateClientSaga({ payload }: ActionWithPayload) {
  try {
    yield call(clientsService.updateClient, payload);
    const { data } = yield call(clientsService.getClientById, {
      accessToken: payload.accessToken,
      customerId: payload.customerId,
      clientId: payload.id,
    });
    yield put(actionCreators.updateClientSuccess(data));
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'client-data-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    if (errorLocal.response) {
      yield put(actionCreators.updateClientFailed(errorLocal.response.data.status));
    } else {
      yield put(actionCreators.updateClientFailed(ErrorCodes.CommonError));
    }
  }
}

export function* setClientStatusSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, customerId, id } = payload;
    yield call(clientsService.setClientStatus, payload);
    yield put(actionCreators.setClientStatusSuccess());
    yield put(
      actionCreators.getClient({
        accessToken,
        customerId,
        clientId: id,
      }),
    );
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'client-status-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.setClientStatusFailed());
  }
}

export function* getClientOperationsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientOperations, payload);
    const prepareData = {
      data: data.data.map((item: OperationData, index: number) => ({ ...item, id: `${index + 1}_${item.traceId}` })),
      totalCount: data.totalCount,
    };
    yield put(actionCreators.getClientOperationsSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientOperationsFailed());
  }
}

export function* getClientIntegrationsSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, customerId, clientId } = payload;
    const { data } = yield call(integrationsService.getClientIntegrations, accessToken, customerId, clientId);
    yield put(actionCreators.getClientIntegrationsSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientIntegrationsFailed());
  }
}

// transformations

export function* getClientAvailableTransformationTypesSaga({ payload }: ActionWithPayload) {
  const { accessToken } = payload;

  const apiTransformationTypesAvailable = () =>
    createTransformationApiClient(accessToken).transformationsGetTransformationTypes();

  try {
    const data: TransformationType[] = yield call(apiTransformationTypesAvailable);

    yield put(actionCreators.getClientAvailableTransformationTypesSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientAvailableTransformationTypesFailed());
  }
}

export function* getClientTransformationSchemaSaga({ payload }: ActionWithPayload) {
  const { accessToken, typeId } = payload;

  const apiTransformationTypeClientSettingsSchema = ({ typeId }: { typeId: string }) =>
    createTransformationApiClient(accessToken).transformationsGetTransformationSettingsSchema(typeId);

  try {
    const data: Schema = yield call(apiTransformationTypeClientSettingsSchema, { typeId });

    yield put(actionCreators.getClientTransformationSchemaSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientTransformationSchemaFailed(errorLocal?.response?.status));
  }
}

export function* getClientTransformationsSaga({ payload }: ActionWithPayload) {
  const { accessToken, customerId, clientId } = payload;

  const apiCustomersClientsTransformations = ({ customerId, clientId }: { customerId: number; clientId: number }) =>
    createTransformationApiClient(accessToken).transformationsGetClientTransformations(customerId, clientId);

  try {
    const data: Transformation[] = yield call(apiCustomersClientsTransformations, { customerId, clientId });

    yield put(actionCreators.getClientTransformationsSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientIntegrationFailed());
  }
}

export function* getClientTransformationSaga({ payload }: ActionWithPayload) {
  const { accessToken, transformationId } = payload;

  const apiTransformation = ({ transformationId }: { transformationId: string }) =>
    createTransformationApiClient(accessToken).transformationsGetTransformation(transformationId);

  try {
    const data: Transformation = yield call(apiTransformation, { transformationId });

    yield put(actionCreators.getClientTransformationSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientTransformationFailed(errorLocal?.response?.status));
  }
}

export function* createClientTransformationSaga({ payload }: ActionWithPayload) {
  const { accessToken, customerId, clientId, request } = payload;

  const apiCustomersClientsTransformations = ({
    customerId,
    clientId,
    request,
  }: {
    customerId: number;
    clientId: number;
    request: CreateTransformationRequest;
  }) =>
    createAdminApiClient(accessToken).transformationsControllerCreateClientTransformation(customerId, clientId, request);

  try {
    const id: string = yield call(apiCustomersClientsTransformations, { customerId, clientId, request });

    yield put(actionCreators.createClientTransformationSuccess({ id, ...request.transformation }));
    yield browserHistory.push({
      pathname: APP_URLS.toClientTransformationProfile(customerId, clientId, id),
      state: { fromIntegrationCreatePage: true },
    });
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.createClientTransformationFailed(errorLocal?.response?.status));
  }
}

export function* updateClientTransformationSaga({ payload }: ActionWithPayload) {
  const { accessToken, transformationId, request } = payload;

  const apiTransformationPut = ({
    transformationId,
    request,
  }: {
    transformationId: string;
    request: UpdateTransformationRequest;
  }) => createAdminApiClient(accessToken).transformationsControllerUpdateTransformation(transformationId, request);

  try {
    yield call(apiTransformationPut, { transformationId, request });

    yield put(actionCreators.updateClientTransformationSuccess({ id: transformationId, ...request.transformation }));
    yield put(actionCreators.getClientTransformation({ accessToken, transformationId }));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateClientTransformationFailed(errorLocal?.response?.status));
  }
}

export function* updateClientTransformationStatusSaga({ payload }: ActionWithPayload) {
  const { accessToken, customerId, clientId, transformationId, active } = payload;

  const apiTransformationStatus = ({ transformationId, active }: { transformationId: string; active: boolean }) =>
    createAdminApiClient(accessToken).transformationsControllerUpdateTransformation(transformationId, {
      isActive: active,
      settings: undefined,
    });

  try {
    const data: Transformation = yield call(apiTransformationStatus, { transformationId, active });

    yield put(actionCreators.updateClientTransformationStatusSuccess(data));
    yield put(actionCreators.getClientTransformations({ accessToken, customerId, clientId }));
    yield put(actionCreators.getClientTransformation({ accessToken, transformationId }));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateClientTransformationStatusFailed(errorLocal?.response?.status));
  }
}

//

export function* getClientConfigurationSchemaSaga({ payload }: ActionWithPayload) {
  try {
    const { isCreatePage, ...requestData } = payload;
    const { data } = yield call(integrationsService.getClientConfigurationSchema, requestData);

    yield put(actionCreators.getClientConfigurationSchemaSuccess(data));

    if (isCreatePage) {
      yield put(actionCreators.getClientSystemSettings(requestData));
    }
  } catch (error) {
    const errorLocal = error as any;
    if (errorLocal?.response?.data?.status !== ErrorCodes.NOT_FOUND_SCHEMA) {
      yield put(
        notificationsActionCreators.addNotification({
          type: NotificationType.ERROR,
          messageId: 'error-messages-something-wrong',
          errorText: errorLocal?.response?.data?.title || error,
          errorStatus: errorLocal?.response?.status,
        }),
      );
    } else {
      yield put(actionCreators.getClientConfigurationSchemaFailed(ErrorCodes.NOT_FOUND_SCHEMA));
    }
  }
}

export function* getClientSystemSettingsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getClientSystemsSettings, payload);

    yield put(
      actionCreators.getClientSystemSettingsSuccess({
        settings: {
          currentSettings: [],
          parentSettings: [...data.settings],
        },
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientSystemSettingsFailed());
  }
}

export function* getClientIntegrationSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getIntegrationById, payload);

    yield put(actionCreators.getClientIntegrationSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientIntegrationFailed());
  }
}

export function* createClientIntegrationSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.createClientIntegration, payload);

    yield put(actionCreators.createClientIntegrationSuccess());
    yield browserHistory.push({
      pathname: APP_URLS.toClientIntegrationProfile(data.customerId, data.clientId, data.id),
      state: { fromIntegrationCreatePage: true },
    });
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.createClientIntegrationFailed(ErrorCodes.CommonError));
  }
}

export function* getClientAvailableSystemsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientAvailableSystems, payload);

    yield put(actionCreators.getClientAvailableSystemsSuccess(data));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientAvailableSystemsFailed());
  }
}

export function* updateClientIntegrationSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, integrationId, withTestConnection } = payload;
    yield call(integrationsService.updateIntegrationById, payload);
    const { data } = yield call(integrationsService.getIntegrationById, {
      accessToken,
      integrationId,
    });
    yield put(actionCreators.updateClientIntegrationSuccess(data));
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-data-was-updated',
      }),
    );
    if (withTestConnection) {
      yield put(actionCreators.getClientTestConnection({ accessToken, integrationId }));
    }
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateClientIntegrationFailed(ErrorCodes.CommonError));
  }
}

export function* updateClientIntegrationStatusSaga({ payload }: ActionWithPayload) {
  try {
    const { accessToken, isProfilePage, integrationId, action } = payload;
    yield call(clientsService.updateClientIntegrationStatus, { accessToken, integrationId, action });
    if (isProfilePage) {
      yield put(
        actionCreators.getClientIntegration({
          accessToken,
          integrationId,
        }),
      );
      yield put(actionCreators.updateClientIntegrationStatusSuccess({ isProfilePage, integrationId }));
    } else {
      yield put(actionCreators.updateClientIntegrationStatusSuccess({ isProfilePage, integrationId }));
    }
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-status-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateClientIntegrationStatusFailed(ErrorCodes.CommonError));
  }
}

export function* getClientTestConnectionSaga({ payload }: ActionWithPayload) {
  try {
    yield call(integrationsService.testConnection, payload);

    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-connection-success',
      }),
    );
    yield put(actionCreators.getClientTestConnectionSuccess());
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        autoDeleteTime: 4000,
        messageId: 'integration-connection-failed',
        errorText: errorLocal?.response?.data?.title || error,
        errorTraceId: errorLocal?.response?.data?.traceId ? getErrorTraceId(errorLocal?.response?.data?.traceId) : '',
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientTestConnectionFailed());
  }
}

export function* clientSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_CLIENT, getClientSaga),
    takeEvery(ActionTypeKeys.UPDATE_CLIENT, updateClientSaga),
    takeEvery(ActionTypeKeys.SET_CLIENT_STATUS, setClientStatusSaga),

    takeEvery(ActionTypeKeys.GET_CLIENT_AVAILABLE_TRANSFORMATION_TYPES, getClientAvailableTransformationTypesSaga),
    takeEvery(ActionTypeKeys.GET_CLIENT_TRANSFORMATION_SCHEMA, getClientTransformationSchemaSaga),
    takeEvery(ActionTypeKeys.GET_CLIENT_TRANSFORMATIONS, getClientTransformationsSaga),
    takeEvery(ActionTypeKeys.GET_CLIENT_TRANSFORMATION, getClientTransformationSaga),
    takeEvery(ActionTypeKeys.CREATE_CLIENT_TRANSFORMATION, createClientTransformationSaga),
    takeEvery(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION, updateClientTransformationSaga),
    takeEvery(ActionTypeKeys.UPDATE_CLIENT_TRANSFORMATION_STATUS, updateClientTransformationStatusSaga),

    takeEvery(ActionTypeKeys.GET_CLIENT_AVAILABLE_SYSTEMS, getClientAvailableSystemsSaga),
    takeEvery(ActionTypeKeys.GET_CLIENT_INTEGRATIONS, getClientIntegrationsSaga),
    takeEvery(ActionTypeKeys.GET_CLIENT_INTEGRATION, getClientIntegrationSaga),
    takeEvery(ActionTypeKeys.CREATE_CLIENT_INTEGRATION, createClientIntegrationSaga),
    takeEvery(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION, updateClientIntegrationSaga),
    takeEvery(ActionTypeKeys.UPDATE_CLIENT_INTEGRATION_STATUS, updateClientIntegrationStatusSaga),

    takeEvery(ActionTypeKeys.GET_CLIENT_CONFIGURATION_SCHEMA, getClientConfigurationSchemaSaga),

    takeEvery(ActionTypeKeys.GET_CLIENT_SYSTEM_SETTINGS, getClientSystemSettingsSaga),

    takeEvery(ActionTypeKeys.GET_CLIENT_TEST_CONNECTION, getClientTestConnectionSaga),

    takeEvery(ActionTypeKeys.GET_CLIENT_OPERATIONS_BY_CLIENT_ID, getClientOperationsSaga),
  ];
}
