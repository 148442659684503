import { useEffect } from 'react';
import { Switch, Route, useParams, useLocation } from 'react-router-dom';
import { APP_URLS } from 'common/constants';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Message } from 'common/components';
import StatusSelector from 'components/StatusSelector';
import { ClientRequestData, ClientData, ClientRequestParams, ClientStatusRequestData } from 'types/Client';
import { useAccessToken } from 'hooks';
import getClientProfileBreadcrumbs from './getClientProfileBreadcrumbs';
import { ClientEditForm } from './ClientEditForm';
import OperationsListContainer from './Operations';
import IntegrationsListContainer from './Integrations/IntegrationsList/IntegrationsListContainer';
import TransformationListContainer from './Transformations/TransformationList/TransformationListContainer';
import {
  Content,
  Head,
  HeadActions,
  TabButton,
  TabContent,
  TabContentInner,
  TabsList,
  TabsWrapper,
  Title,
  TitleMark,
} from './styles';
import ApproverRefrencesGrid from './ApproverReferences/ApproverReferencesGrid';

type clientUrlParams = {
  customerId: string;
  clientId: string;
};

interface ClientProps {
  isFetching: boolean;
  errorCode: number | null;
  client: ClientData;
  getClient: (payload: ClientRequestParams) => void;
  updateClient: (payload: ClientRequestData) => void;
  setStatus: (payload: ClientStatusRequestData) => void;
  resetPartial: () => void;
}

const Client = ({ isFetching, errorCode, client, getClient, updateClient, setStatus, resetPartial }: ClientProps) => {
  const accessToken = useAccessToken();
  const { customerId, clientId } = useParams<clientUrlParams>();
  const pathname = useLocation().pathname;
  const isClientDataPage = pathname.toLowerCase().includes('client-data');
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    if (accessToken) {
      getClient({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
  }, [accessToken, getClient, customerId, clientId]);

  useEffect(() => {
    if (!isClientDataPage) {
      return;
    }
    setBreadcrumbs(getClientProfileBreadcrumbs(Number(customerId), Number(clientId), client.customerName, client.name));
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, isClientDataPage, setBreadcrumbs]);

  return (
    <>
      <Head>
        <Title>
          {client?.name}
          <TitleMark>id#{client?.id}</TitleMark>
        </Title>
        <HeadActions>
          {client.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={client.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <TabsList>
            <TabButton exact to={APP_URLS.toClientProfile(Number(customerId), Number(clientId))}>
              <Message id='client-screen-client-data-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientIntegrations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-integrations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientTransformations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-transformations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientApproverReferences(Number(customerId), Number(clientId))}>
              <Message id='client-screen-approver-references-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-operation-status-tab' />
            </TabButton>
          </TabsList>
          <TabContent>
            <TabContentInner>
              <Switch>
                <Route
                  exact
                  path={APP_URLS.clientProfile}
                  render={() => (
                    <ClientEditForm
                      isFetching={isFetching}
                      formData={client}
                      errorCode={errorCode}
                      resetPartial={resetPartial}
                      customerId={Number(customerId)}
                      onSubmitHandler={updateClient}
                    />
                  )}
                />
                <Route exact path={APP_URLS.clientIntegrations} render={() => <IntegrationsListContainer />} />
                <Route exact path={APP_URLS.clientOperations} render={() => <OperationsListContainer />} />
                <Route
                  exact
                  path={APP_URLS.clientApproverReferences}
                  render={() => <ApproverRefrencesGrid customerId={customerId} clientId={clientId} />}
                />
                <Route
                  exact
                  path={APP_URLS.clientTransformations}
                  render={() => <TransformationListContainer customerId={customerId} clientId={clientId} />}
                />
              </Switch>
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default Client;
