import { call, put, takeEvery } from 'redux-saga/effects';
import { integrationsService } from 'common/api/integrationsService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { ActionWithPayload } from 'store/Action';
import { ErrorCodes } from 'common/constants';
import { ActionTypeKeys, actionCreators } from './integrationsDefaultSettingsReducer';

export function* getDefaultSystemSettingsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(integrationsService.getSystemsSettings, payload);

    yield put(
      actionCreators.getDefaultSystemSettingsSuccess({
        settings: {
          currentSettings: [...data.settings],
          parentSettings: [],
        },
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getDefaultSystemSettingsFailed());
  }
}

export function* getConfigurationSchema({ payload }: ActionWithPayload) {
  try {
    const { accessToken, systemId } = payload;
    const { data } = yield call(integrationsService.getConfigurationSchema, payload);

    yield put(actionCreators.getConfigurationSchemaSuccess(data));

    yield put(actionCreators.getDefaultSystemSettings({ accessToken, systemId }));
  } catch (error) {
    const errorLocal = error as any;
    if (errorLocal?.response?.data?.status !== ErrorCodes.NOT_FOUND_SCHEMA) {
      yield put(
        notificationsActionCreators.addNotification({
          type: NotificationType.ERROR,
          messageId: 'error-messages-something-wrong',
          errorText: errorLocal?.response?.data?.title || error,
          errorStatus: errorLocal?.response?.status,
        }),
      );
    } else {
      yield put(actionCreators.getConfigurationSchemaFailed(ErrorCodes.NOT_FOUND_SCHEMA));
    }
  }
}

export function* updateDefaultSettingsSaga({ payload }: ActionWithPayload) {
  try {
    yield call(integrationsService.updateIntegrationDefaultSettings, payload);
    const { data } = yield call(integrationsService.getSystemsSettings, payload);
    yield put(
      actionCreators.updateDefaultSettingsSuccess({
        settings: {
          currentSettings: [...data.settings],
          parentSettings: [],
        },
      }),
    );
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.SUCCESS,
        messageId: 'integration-default-settings-was-updated',
      }),
    );
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.updateDefaultSettingsFailed(ErrorCodes.CommonError));
  }
}

export function* integrationsDefaultSettingsSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_DEFAULT_SYSTEM_SETTINGS, getDefaultSystemSettingsSaga),
    takeEvery(ActionTypeKeys.GET_CONFIGURATION_SCHEMA, getConfigurationSchema),
    takeEvery(ActionTypeKeys.UPDATE_DEFAULT_SETTINGS, updateDefaultSettingsSaga),
  ];
}
