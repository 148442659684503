import { availableSystemsToOptions } from 'utils';
import { State as SharedState } from 'common/reducers/sharedReducer';
import { State as IntegrationsDefaultSettingsState } from './integrationsDefaultSettingsReducer';

export const getIntegrationsDefaultSettingsView = (
  sharedState: SharedState,
  state: IntegrationsDefaultSettingsState,
) => ({
  isFetching: state.isFetching,
  errorCode: state.errorCode,
  availableSystems: availableSystemsToOptions(sharedState.integrationSystems),
  integrationSchema: state.integrationSchema,
  integration: state.integration,
});
