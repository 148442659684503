import { prepareMultipleSelectOptionsFromArray, prepareSelectOptionsFromDictionaries } from 'utils/formHelpers';
import { Dictionaries, systemsFilterOptions } from 'common/constants';
import { integrationsSystemsNamesMapping } from 'types/Integrations';
import { State as SharedState } from 'common/reducers/sharedReducer';
import {
  operationScopeTypeNamesMapping,
  operationStatusNamesMapping,
  operationTypeNamesMapping,
} from 'types/Operations';
import { State as OperationsAllState } from './operationsAllReducer';

export const getOperationsAllView = (sharedState: SharedState, state: OperationsAllState) => ({
  isFetching: state.isFetching,
  operations: state.data,
  totalCount: state.totalCount,
  page: state.page,
  perPage: state.perPage,
  orderBy: state.orderBy,
  sortOrder: state.sortOrder,
  filterScope: state.filterScope,
  filterType: state.filterType,
  filterStatus: state.filterStatus,
  filterSystem: state.filterSystem,
  filterCustomerName: state.filterCustomerName,
  filterClientName: state.filterClientName,
  filterDateFrom: state.filterDateFrom,
  filterDateTo: state.filterDateTo,
  filterSourceEntityId: state.filterSourceEntityId,
  filterTargetEntityId: state.filterTargetEntityId,
  showOnlyDeactivatedSystems: state.showOnlyDeactivatedSystems,
  customersOptions: state.customersOptions,
  clientsOptions: state.clientsOptions,
  connectedSystemsOptions: prepareMultipleSelectOptionsFromArray(
    sharedState.integrationSystems,
    systemsFilterOptions[0],
    integrationsSystemsNamesMapping,
  ),
  operationsScopeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_SCOPE_TYPES],
    operationScopeTypeNamesMapping,
  ),
  operationsTypeOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_TYPES],
    operationTypeNamesMapping,
  ),
  operationsStatusOptions: prepareSelectOptionsFromDictionaries(
    sharedState.dictionaries[Dictionaries.OPERATION_STATUSES],
    operationStatusNamesMapping,
  ),
});
