import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchInputFocus, useAccessToken } from 'hooks';
import { APP_URLS } from 'common/constants';
import { ClientData, CustomerClientsRequestData, MultipleClientsStatusRequestData } from 'types/Client';
import { CustomerByIdRequestData, CustomerData } from 'types/Customer';
import { Status } from 'types/Status';
import { FormSelectOption } from 'types/Form';
import { TablePerPage, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { ImportFileData, ImportMode, ImportType } from 'types/Import';
import { getArrayOfOptionsValues, getSelectedMultiOptions } from 'utils';
import { Button, ButtonLink, DataTable, Input, Message, Paginator, Select, Spinner, Switcher } from 'common/components';
import { Key } from 'common/components/DataTable/types';
import {
  ConfigurationSchemaRequest,
  CustomerIntegrationsRequestParams,
  IntegrationData,
  IntegrationSchema,
  IntegrationsSystems,
  IntegrationSystemOptions,
} from 'types/Integrations';
import { RefreshIcon } from 'common/components/Icons';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { getAvailableStatuses } from 'utils/formHelpers';
import { ImportIntegrations } from './ImportIntegrations';
import ImportModal from './components/ImportModal';
import ActivateModal from './components/ActivateModal';
import customerClientsTableColumns, { ClientsDataTableRow } from './customerClientsTableColumns';
import getCustomerClientsBreadcrumbs from './getCustomerClientsBreadcrumbs';
import { ImportClients } from './ImportClients';
import {
  FiltersColumn,
  FiltersColumnWide,
  FiltersLeft,
  FiltersRight,
  FiltersWrapper,
  Head,
  HeadAction,
  HeadActionButton,
  Title,
  Wrapper,
  FilterRowColumn,
  FilterRow,
} from './styles';
import { ImportApproverReferences } from './ImportApproverReferences';

interface ClientsProps {
  isFetching: boolean;
  errorCode: null | number;
  isUploading: boolean;
  isUploaded: boolean;
  isIncludeDeactivated: boolean;
  showOnlyDeactivatedSystems: boolean;
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  uploadFile: File;
  uploadFileName: string;
  importType: ImportType;
  importMode: ImportMode;
  filterStatus: FormSelectOption[];
  filterSystem: IntegrationSystemOptions[];
  filterSearch: string;
  customer: CustomerData;
  clients: ClientData[];
  availableSystems: IntegrationSystemOptions[];
  connectedSystemsOptions: IntegrationSystemOptions[];
  clientStatusOptions: FormSelectOption[];
  csvHeaders: any;
  customerSystems: IntegrationsSystems[];
  customerIntegrations: IntegrationData[];
  integrationSchema: IntegrationSchema;
  getCustomer: (payload: CustomerByIdRequestData) => void;
  getClients: (payload: CustomerClientsRequestData) => void;
  setTablePage: (payload: number) => void;
  setTablePerPage: (payload: number) => void;
  setFilterStatus: (payload: FormSelectOption[]) => void;
  setFilterSystem: (payload: IntegrationSystemOptions[]) => void;
  setFilterSearch: (payload: string) => void;
  setTableOrder: (payload: TablesSortOrderPayload) => void;
  setUploadFile: (payload: File) => void;
  setImportType: (payload: null | ImportType) => void;
  setImportMode: (payload: ImportMode) => void;
  importFile: (payload: ImportFileData) => void;
  activateMultipleClients: (payload: MultipleClientsStatusRequestData) => void;
  resetUploadFile: () => void;
  reset: () => void;
  setIsIncludeDeactivated: (payload: boolean) => void;
  setShowOnlyDeactivatedSystems: (payload: boolean) => void;
  getCustomerIntegrations: (payload: CustomerIntegrationsRequestParams) => void;
  getIntegrationsCsvFileHeaders: (payload: any) => void;
  getClientConfigurationSchema: (payload: ConfigurationSchemaRequest) => void;
}

type CustomerUrlParams = {
  customerId: string;
};

enum ImportModalDialogType {
  Clients = 'Clients',
  Integrations = 'Integrations',
  ApproverReferences = 'ApproverReferences',
}

const CustomerClients = ({
  isFetching,
  errorCode,
  isUploading,
  isUploaded,
  isIncludeDeactivated,
  showOnlyDeactivatedSystems,
  totalCount,
  page,
  perPage,
  orderBy,
  sortOrder,
  uploadFile,
  uploadFileName,
  importType,
  importMode,
  filterStatus,
  filterSystem,
  filterSearch,
  customer,
  clients,
  availableSystems,
  connectedSystemsOptions,
  clientStatusOptions,
  csvHeaders,
  customerSystems,
  customerIntegrations,
  integrationSchema,
  getCustomer,
  getClients,
  setTablePage,
  setTablePerPage,
  setFilterStatus,
  setFilterSystem,
  setFilterSearch,
  setTableOrder,
  setUploadFile,
  setImportType,
  setImportMode,
  importFile,
  activateMultipleClients,
  resetUploadFile,
  reset,
  setIsIncludeDeactivated,
  setShowOnlyDeactivatedSystems,
  getCustomerIntegrations,
  getIntegrationsCsvFileHeaders,
  getClientConfigurationSchema,
}: ClientsProps) => {
  const accessToken = useAccessToken();
  const { customerId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [isFiltersDisabled, setFiltersDisabled] = useState(true);

  const [importModalDialogType, setImportModalDialogType] = useState<ImportModalDialogType | undefined>(undefined);

  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isActivateModalVisible, setActivateModalVisible] = useState(false);
  const [setIsSearchInputChanged, inputRef, setSearchInputFocus] = useSearchInputFocus();
  const [integrationSystemId, setIntegrationSystemId] = useState('');
  const requestData = {
    accessToken,
    customerId: Number(customerId),
    page: 1,
    size: Number(perPage),
    orderBy: orderBy,
    sortOrder: sortOrder,
    shouldContainDeactivatedSystem: showOnlyDeactivatedSystems,
    statuses:
      filterStatus[0].value === clientStatusOptions[0].value
        ? getAvailableStatuses(isIncludeDeactivated, clientStatusOptions)
        : getArrayOfOptionsValues(filterStatus),
    systems:
      filterSystem[0].value === connectedSystemsOptions[0].value ? undefined : getArrayOfOptionsValues(filterSystem),
    search: filterSearch ? filterSearch : undefined,
  };

  const statusFilterOptions = !isIncludeDeactivated
    ? clientStatusOptions.filter((option: FormSelectOption) => option.value !== Status.DEACTIVATED)
    : clientStatusOptions;

  useEffect(() => {
    setSearchInputFocus();
  }, [isFetching, setSearchInputFocus]);

  useEffect(() => {
    if (accessToken) {
      getClients({
        accessToken,
        customerId: Number(customerId),
        page: page,
        size: perPage,
        orderBy: orderBy,
        sortOrder: sortOrder,
        shouldContainDeactivatedSystem: showOnlyDeactivatedSystems,
        statuses:
          filterStatus[0].value === clientStatusOptions[0].value
            ? getAvailableStatuses(isIncludeDeactivated, clientStatusOptions)
            : getArrayOfOptionsValues(filterStatus),
      });
    }

    return () => reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, reset, getClients, getCustomer, customerId, clientStatusOptions.length]);

  useEffect(() => {
    if (!customer.name && accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, customer.name, getCustomer]);

  // TODO: needed for alert in CSV integration popup
  useEffect(() => {
    if (customerSystems && customerSystems.length && !customerIntegrations.length && accessToken) {
      getCustomerIntegrations({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, customerSystems, customerIntegrations.length, getCustomerIntegrations]);

  useEffect(() => {
    if (isUploaded) {
      setImportModalDialogType(undefined);
    }
  }, [importModalDialogType, isUploaded]);

  useEffect(() => {
    setFiltersDisabled(
      isFetching ||
        (!totalCount &&
          filterSystem[0].value === connectedSystemsOptions[0].value &&
          filterStatus[0].value === clientStatusOptions[0].value),
    );
  }, [isFetching, totalCount, filterSystem, filterStatus, connectedSystemsOptions, clientStatusOptions]);

  useEffect(() => {
    setBreadcrumbs(getCustomerClientsBreadcrumbs(Number(customerId), customer.name));
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, setBreadcrumbs]);

  const handleOnPageChange = (page: { selected: number }) => {
    setTablePage(page.selected);
    setIsSearchInputChanged(false);
    getClients({
      ...requestData,
      page: page.selected,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleOnItemsPerPageChange = (perPage: TablePerPage) => {
    setTablePerPage(Number(perPage.value));
    setTablePage(1);
    setIsSearchInputChanged(false);
    getClients({
      ...requestData,
      size: Number(perPage.value),
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableFilterStatusChange = (event: FormSelectOption[]) => {
    setTablePage(1);
    setIsSearchInputChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, clientStatusOptions, setFilterStatus);
    getClients({
      ...requestData,
      statuses:
        selectedOptions[0].value === clientStatusOptions[0].value
          ? getAvailableStatuses(isIncludeDeactivated, clientStatusOptions)
          : getArrayOfOptionsValues(selectedOptions),
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableFilterSystemChange = (event: IntegrationSystemOptions[]) => {
    setTablePage(1);
    setIsSearchInputChanged(false);
    const selectedOptions = getSelectedMultiOptions(event, connectedSystemsOptions, setFilterSystem);
    getClients({
      ...requestData,
      systems:
        selectedOptions[0].value === connectedSystemsOptions[0].value
          ? undefined
          : getArrayOfOptionsValues(selectedOptions),
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const handleTableSortChange = (name: string, direction: TableSortOrder) => {
    setTableOrder({ orderBy: name, sortOrder: direction });
    setTablePage(1);
    setIsSearchInputChanged(false);
    getClients({
      ...requestData,
      orderBy: name,
      sortOrder: direction,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getClients({
      ...requestData,
      search: value ? value : undefined,
    });
  }, 500);

  const handleSearch = (value: string) => {
    if (value !== filterSearch) {
      setIsSearchInputChanged(true);
    }
    setFilterSearch(value);

    if (value.trim().length !== 0 || filterSearch.trim().length) {
      debouncedSearch(value);
      setTablePage(1);
    }
  };

  const onRefreshButtonClick = () => {
    setIsSearchInputChanged(false);
    getClients({
      ...requestData,
      page: page,
    });
    setSelectedClients([]);
    setSelectedRowKeys([]);
  };

  const onTableChange = (selectedKeys: Key[], selectedRows: any[]) => {
    setSelectedRowKeys(selectedKeys);
    setSelectedClients(selectedRows);
  };

  const onShowDeactivatedChange = (event: any) => {
    setIsIncludeDeactivated(event.target.checked);
    setTablePage(1);
    getClients({
      ...requestData,
      statuses:
        filterStatus[0].value === clientStatusOptions[0].value
          ? getAvailableStatuses(event.target.checked, clientStatusOptions)
          : getArrayOfOptionsValues(filterStatus),
    });
  };

  const onShowOnlyDeactivatedSystemsChange = (event: any) => {
    setShowOnlyDeactivatedSystems(event.target.checked);
    setTablePage(1);
    getClients({
      ...requestData,
      shouldContainDeactivatedSystem: event.target.checked,
    });
  };

  const onActivateSelectedHandler = useCallback(() => {
    setActivateModalVisible(true);
  }, []);

  // Activate Modal START
  const onActivateModalCancelHandler = useCallback(() => {
    setActivateModalVisible(false);
    // Clear on Cancel
    setSelectedClients([]);
    setSelectedRowKeys([]);
  }, []);

  const onActivateModalConfirmHandler = useCallback(() => {
    activateMultipleClients({
      accessToken,
      customerId: Number(customerId),
      clientIds: selectedClients.map((client: any) => Number(client.id)),
      targetStatus: Status.ACTIVE,
    });

    setActivateModalVisible(false);
    // Clear on activate
    setSelectedClients([]);
    setSelectedRowKeys([]);
  }, [customerId, selectedClients, activateMultipleClients, accessToken]);
  // Activate Modal END

  // Import Modal START
  const onImportModalCancelHandler = useCallback(() => {
    setImportModalDialogType(undefined);
  }, []);

  const onImportIntegrationsConfirmHandler = useCallback(() => {
    importFile({
      accessToken,
      importType,
      importMode,
      uploadFile,
      customerId: Number(customerId),
      integrationSystemId: integrationSystemId,
    });
  }, [integrationSystemId, customerId, uploadFile, importFile, importType, importMode, accessToken]);

  const onImportClientsConfirmHandler = useCallback(() => {
    importFile({
      accessToken,
      importType,
      importMode,
      uploadFile,
      customerId: Number(customerId),
      integrationSystemId: undefined,
    });
  }, [customerId, uploadFile, importFile, importType, importMode, accessToken]);

  const renderImportModal = () => {
    switch (importModalDialogType) {
      case ImportModalDialogType.Clients:
        return (
          <ImportModal
            withScroll={true}
            isFetching={isUploading}
            isConfirmButtonDisabled={!uploadFileName}
            modalTitle={<Message id='clients-screen-import-clients-title' />}
            modalBody={
              <ImportClients
                onFileUpload={setUploadFile}
                setImportType={setImportType}
                setImportMode={setImportMode}
                resetUpload={resetUploadFile}
              />
            }
            onModalConfirmHandler={onImportClientsConfirmHandler}
            onModalCancelHandler={onImportModalCancelHandler}
          />
        );
      case ImportModalDialogType.ApproverReferences:
        return (
          <ImportModal
            withScroll={true}
            isFetching={isUploading}
            isConfirmButtonDisabled={!uploadFileName}
            modalTitle={<Message id='clients-screen-import-approver-references-title' />}
            modalBody={
              <ImportApproverReferences
                onFileUpload={setUploadFile}
                setImportType={setImportType}
                resetUpload={resetUploadFile}
              />
            }
            onModalConfirmHandler={onImportClientsConfirmHandler}
            onModalCancelHandler={onImportModalCancelHandler}
          />
        );
      case ImportModalDialogType.Integrations:
        return (
          <ImportModal
            withScroll={false}
            isFetching={isUploading}
            isConfirmButtonDisabled={!uploadFileName}
            modalTitle={<Message id='clients-screen-import-integrations-title' />}
            modalBody={
              <ImportIntegrations
                isFetching={isFetching}
                errorCode={errorCode}
                availableSystems={availableSystems}
                csvHeaders={csvHeaders}
                integrationSchema={integrationSchema}
                getIntegrationsCsvFileHeaders={getIntegrationsCsvFileHeaders}
                onFileUpload={setUploadFile}
                setImportType={setImportType}
                setImportMode={setImportMode}
                resetUpload={resetUploadFile}
                customerIntegrations={customerIntegrations}
                setIntegrationSystemId={setIntegrationSystemId}
                getClientConfigurationSchema={getClientConfigurationSchema}
              />
            }
            onModalConfirmHandler={onImportIntegrationsConfirmHandler}
            onModalCancelHandler={onImportModalCancelHandler}
          />
        );
      default:
        return null;
    }
  };

  // Import Modal END

  return (
    <>
      <Head>
        <Title>
          <Message id='clients-screen-title' />
        </Title>
        <HeadAction>
          <Button primary onClick={() => setImportModalDialogType(ImportModalDialogType.ApproverReferences)}>
            <Message id='clients-screen-import-approver-references-button' />
          </Button>
          <Button primary onClick={() => setImportModalDialogType(ImportModalDialogType.Integrations)}>
            <Message id='clients-screen-import-integrations-button' />
          </Button>
          <Button primary onClick={() => setImportModalDialogType(ImportModalDialogType.Clients)}>
            <Message id='clients-screen-import-clients-button' />
          </Button>
          <HeadActionButton>
            <ButtonLink primary='true' to={APP_URLS.toCreateClients(Number(customerId))}>
              <Message id='clients-screen-create-clients-button' />
            </ButtonLink>
          </HeadActionButton>
        </HeadAction>
      </Head>
      <Wrapper>
        <FiltersWrapper>
          <FilterRow>
            <FiltersLeft>
              <FiltersColumn>
                <Select
                  value={filterSystem}
                  options={connectedSystemsOptions}
                  label={<Message id='clients-screen-filter-connected-systems' />}
                  onChange={handleTableFilterSystemChange}
                  isMulti
                  isClearable={false}
                  disabled={isFiltersDisabled}
                />
              </FiltersColumn>
              <FiltersColumn>
                <Select
                  isMulti
                  isClearable={false}
                  value={filterStatus}
                  options={statusFilterOptions}
                  label={<Message id='clients-screen-filter-status' />}
                  onChange={handleTableFilterStatusChange}
                  disabled={isFiltersDisabled}
                />
              </FiltersColumn>
              <FiltersColumnWide>
                <Input
                  inputRef={inputRef}
                  disabled={isFetching}
                  id='filterSearch'
                  type='text'
                  label={<Message id='clients-screen-filter-search' />}
                  value={filterSearch}
                  onChange={handleSearch}
                  placeholder='Client Name, Org. Number'
                />
              </FiltersColumnWide>
            </FiltersLeft>
            <FiltersRight>
              <Button primary disabled={!selectedClients.length} onClick={onActivateSelectedHandler}>
                <Message id='clients-screen-activate-selected-button' />
              </Button>
              <Button primary disabled={isFetching} onClick={onRefreshButtonClick}>
                <RefreshIcon width={20} height={20} />
              </Button>
            </FiltersRight>
          </FilterRow>
          <FilterRow>
            <FiltersLeft>
              <FilterRowColumn>
                <Switcher
                  text={<Message id='clients-screen-include-deactivated-clients' />}
                  leftLabel='No'
                  rightLabel='Yes'
                  isChecked={isIncludeDeactivated}
                  onChange={onShowDeactivatedChange}
                />
              </FilterRowColumn>
              <FilterRowColumn>
                <Switcher
                  text={<Message id='clients-screen-show-deactivated-systems' />}
                  tooltipText={<Message id='clients-screen-this-toggle-shows-only-entities-with-deactivated-systems' />}
                  leftLabel='No'
                  rightLabel='Yes'
                  isChecked={showOnlyDeactivatedSystems}
                  onChange={onShowOnlyDeactivatedSystemsChange}
                />
              </FilterRowColumn>
            </FiltersLeft>
          </FilterRow>
        </FiltersWrapper>
        <DataTable
          isFetching={isFetching}
          data={clients}
          loadingComponent={Spinner}
          columns={customerClientsTableColumns(Number(customerId))}
          emptyMessage={<Message id='no-data-message' />}
          orderBy={orderBy}
          sortOrder={sortOrder}
          onSortChange={handleTableSortChange}
          rowSelection={{
            selectedRowKeys,
            onChange: onTableChange,
            getCheckboxProps: (record: ClientsDataTableRow) => ({
              disabled: record.status === Status.ACTIVE,
            }),
          }}
        />
        <Paginator
          totalCount={totalCount}
          page={page}
          perPage={perPage}
          onItemsPerPageChange={handleOnItemsPerPageChange}
          onPageChange={handleOnPageChange}
        />
        {renderImportModal()}
        <ActivateModal
          isVisible={isActivateModalVisible}
          selectedClients={selectedClients}
          onModalConfirmHandler={onActivateModalConfirmHandler}
          onModalCancelHandler={onActivateModalCancelHandler}
        />
      </Wrapper>
    </>
  );
};

export default CustomerClients;
