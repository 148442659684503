import { createStore, applyMiddleware, Middleware } from 'redux';
import { History as HistoryInterface } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

export const configureStore = (history: HistoryInterface) => {
  const middlewares: Middleware[] = [routerMiddleware(history), sagaMiddleware];
  // const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(...middlewares, logger)));
  const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(...middlewares)));

  // sagaMiddleware.run(rootSaga);

  return store;
};

export const runRootSaga = () => {
  sagaMiddleware.run(rootSaga);
};
