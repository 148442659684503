import { ReactElement } from 'react';
import { Message } from 'common/components';
import { APP_URLS } from 'common/constants';
import { TableDateField } from 'types/Tables';
import { getFormattedLongDate } from 'utils';
import { TransferStatus } from 'types/TransferHistoricalData';
import { DateWrap, StatusLabel, StyledLink } from './styles';

interface TransferHistoricalDataTableRow {
  transferable: boolean;
  id: number;
  key: string;
  clientId: number;
  clientName: string;
  lastExecuted: string;
  organizationNumber: string;
  status: TransferStatus;
}

interface TransferHistoricalDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: TransferHistoricalDataTableRow;
}

const transferHistoricalDataTableColumns = (customerId: number) => {
  return [
    {
      name: 'clientId',
      title: <Message id='transfer-historical-data-table-client-id' />,
      width: 100,
      sortable: true,
      cellCallback: ({ row: { clientId } }: TransferHistoricalDataTable) => (
        <StyledLink to={APP_URLS.toClient(Number(customerId), Number(clientId))}>{clientId}</StyledLink>
      ),
    },
    {
      name: 'clientName',
      title: <Message id='transfer-historical-data-table-client-name' />,
      width: 300,
      sortable: true,
      cellCallback: ({ row: { clientId, clientName } }: TransferHistoricalDataTable) => (
        <StyledLink to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>{clientName}</StyledLink>
      ),
    },
    {
      name: 'organizationNumber',
      title: <Message id='transfer-historical-data-table-organization-number' />,
      width: 200,
      cellCallback: ({ row: { organizationNumber } }: TransferHistoricalDataTable) => (
        <strong>{organizationNumber}</strong>
      ),
    },
    {
      name: TableDateField.LAST_EXECUTED,
      title: <Message id='transfer-historical-data-table-last-executed' />,
      width: 300,
      sortable: true,
      cellCallback: ({ row: { lastExecuted } }: TransferHistoricalDataTable) => (
        <DateWrap>{getFormattedLongDate(lastExecuted)}</DateWrap>
      ),
    },
    {
      name: 'status',
      title: <Message id='transfer-historical-data-table-status' />,
      width: 200,
      cellCallback: ({ row: { status } }: TransferHistoricalDataTable) => {
        const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);
        return (
          <>
            <StatusLabel status={status}>{statusFormatted}</StatusLabel>
          </>
        );
      },
    },
  ];
};

export default transferHistoricalDataTableColumns;
