import { TableAlignment, TableDateField, TableSortOrder } from '../types';
import { Th, SortableArrows, Title } from './styles';

interface HeadCellProps {
  name: string;
  title: string;
  sortable?: boolean;
  orderBy: string;
  align?: TableAlignment;
  sortOrder?: TableSortOrder;
  colSpan?: string | number;
  headCallback?: () => void;
  onSortChange: (name: string, direction: string) => void;
}

const HeadCell = ({ name, title, sortable, align, orderBy, sortOrder, headCallback, onSortChange }: HeadCellProps) => {
  const handleSortChange = () => {
    if (sortable) {
      const isOrderReverse =
        name === TableDateField.UPDATE_ON ||
        name === TableDateField.DATE_TIME ||
        name === TableDateField.LAST_EXECUTED ||
        name === TableDateField.LAST_UPDATE ||
        name === TableDateField.STARTED_DATE_TIME ||
        name === TableDateField.FINISHED_DATE_TIME ||
        name === TableDateField.CREATED_ON;

      let direction = isOrderReverse ? TableSortOrder.DESCENDING : TableSortOrder.ASCENDING;

      if (sortOrder === TableSortOrder.ASCENDING && name === orderBy) {
        direction = TableSortOrder.DESCENDING;
      }
      if (sortOrder === TableSortOrder.DESCENDING && name === orderBy) {
        direction = TableSortOrder.ASCENDING;
      }

      onSortChange(name, direction);
    }
  };

  const renderArrows = () => {
    let isAsc;
    let isDesc;
    if (orderBy === name && sortOrder === TableSortOrder.ASCENDING) {
      // eslint-disable-next-line prefer-const
      isAsc = orderBy === name && sortOrder === TableSortOrder.ASCENDING;
    }
    if (orderBy === name && sortOrder === TableSortOrder.DESCENDING) {
      // eslint-disable-next-line prefer-const
      isDesc = orderBy === name && sortOrder === TableSortOrder.DESCENDING;
    }
    return <SortableArrows isAsc={isAsc} isDesc={isDesc} />;
  };

  const renderContent = () => {
    if (typeof headCallback === 'function') {
      return headCallback();
    }
    return <Title isHighlighted={orderBy === name}>{title}</Title>;
  };

  return (
    <Th onClick={handleSortChange} isSortable={sortable} alignment={align}>
      {renderContent()}
      {sortable && renderArrows()}
    </Th>
  );
};

export default HeadCell;
