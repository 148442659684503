import axios from 'axios';
import Qs from 'qs';

export const createAuthAxiosInstanceWithUrl = (url: string) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'application/JSON; charset=UTF-8',
    },
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const createAuthAxiosInstanceWithBlob = (url: string) => {
  return axios.create({
    baseURL: url,
    responseType: 'blob',
    headers: {
      'Content-type': 'application/JSON; charset=UTF-8',
    },
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

export const createAxiosInstanceWithUrlMultipart = (url: string) => {
  return axios.create({
    baseURL: url,
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
};
