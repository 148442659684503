import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import {
  CustomerByIdRequestData,
  CustomerData,
  CustomerIntegrationStatusRequest,
  CustomerRequestDataExtended,
  CustomerStatusRequestData,
} from 'types/Customer';
import {
  IntegrationData,
  CustomerIntegrationsRequestParams,
  IntegrationSchema,
  CustomerIntegrationBySystemRequestParams,
  IntegrationsSystems,
  CustomerAvailableIntegrationsSystemRequestParams,
  IntegrationSystem,
  CustomerConfigurationSchemaRequest,
  CreateCustomerIntegrationsRequestParams,
  IntegrationSettingsDefault,
  IntegrationRequest,
  UpdateIntegrationRequest,
  TestConnectionRequest,
} from 'types/Integrations';
import { OperationData, OperationsCustomerState, OperationsList, OperationsRequestData } from 'types/Operations';
import { defaultFilterOptions, systemsFilterOptions } from 'common/constants';
import { FormSelectOption } from 'types/Form';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import { TableDateField, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { ClientsOptionsRequestDataByCustomerId } from 'types/Client';

// Actions
export enum ActionTypeKeys {
  GET_CUSTOMER = 'CUSTOMERS/GET_CUSTOMER',
  GET_CUSTOMER_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_FAILED = 'CUSTOMERS/GET_CUSTOMER_FAILED',
  UPDATE_CUSTOMER = 'CUSTOMERS/UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_SUCCESS = 'CUSTOMERS/UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILED = 'CUSTOMERS/UPDATE_CUSTOMER_FAILED',
  GET_CUSTOMER_INTEGRATIONS = 'CUSTOMERS/GET_CUSTOMER_INTEGRATIONS',
  GET_CUSTOMER_INTEGRATIONS_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_INTEGRATIONS_SUCCESS',
  GET_CUSTOMER_INTEGRATIONS_FAILED = 'CUSTOMERS/GET_CUSTOMER_INTEGRATIONS_FAILED',
  GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS = 'CUSTOMERS/GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS',
  GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_SUCCESS',
  GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_FAILED = 'CUSTOMERS/GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_FAILED',
  GET_CUSTOMER_AVAILABLE_SYSTEMS = 'CUSTOMERS/GET_CUSTOMER_AVAILABLE_SYSTEMS',
  GET_CUSTOMER_AVAILABLE_SYSTEMS_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_AVAILABLE_SYSTEMS_SUCCESS',
  GET_CUSTOMER_AVAILABLE_SYSTEMS_FAILED = 'CUSTOMERS/GET_CUSTOMER_AVAILABLE_SYSTEMS_FAILED',
  SET_CUSTOMER_STATUS = 'CUSTOMERS/SET_CUSTOMER_STATUS',
  SET_CUSTOMER_STATUS_SUCCESS = 'CUSTOMERS/SET_CUSTOMER_STATUS_SUCCESS',
  SET_CUSTOMER_STATUS_FAILED = 'CUSTOMERS/SET_CUSTOMER_STATUS_FAILED',
  GET_CUSTOMER_CONFIGURATION_SCHEMA = 'CUSTOMERS/GET_CUSTOMER_CONFIGURATION_SCHEMA',
  GET_CUSTOMER_CONFIGURATION_SCHEMA_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_CONFIGURATION_SCHEMA_SUCCESS',
  GET_CUSTOMER_CONFIGURATION_SCHEMA_FAILED = 'CUSTOMERS/GET_CUSTOMER_CONFIGURATION_SCHEMA_FAILED',
  GET_CUSTOMER_SYSTEM_SETTINGS = 'CUSTOMERS/GET_CUSTOMER_SYSTEM_SETTINGS',
  GET_CUSTOMER_SYSTEM_SETTINGS_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_SYSTEM_SETTINGS_SUCCESS',
  GET_CUSTOMER_SYSTEM_SETTINGS_FAILED = 'CUSTOMERS/GET_CUSTOMER_SYSTEM_SETTINGS_FAILED',
  GET_CUSTOMER_INTEGRATION = 'CUSTOMERS/GET_CUSTOMER_INTEGRATION',
  GET_CUSTOMER_INTEGRATION_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_INTEGRATION_SUCCESS',
  GET_CUSTOMER_INTEGRATION_FAILED = 'CUSTOMERS/GET_CUSTOMER_INTEGRATION_FAILED',
  CREATE_CUSTOMER_INTEGRATION = 'CUSTOMERS/CREATE_CUSTOMER_INTEGRATION',
  CREATE_CUSTOMER_INTEGRATION_SUCCESS = 'CUSTOMERS/CREATE_CUSTOMER_INTEGRATION_SUCCESS',
  CREATE_CUSTOMER_INTEGRATION_FAILED = 'CUSTOMERS/CREATE_CUSTOMER_INTEGRATION_FAILED',
  UPDATE_CUSTOMER_INTEGRATION = 'CUSTOMERS/UPDATE_CUSTOMER_INTEGRATION',
  UPDATE_CUSTOMER_INTEGRATION_SUCCESS = 'CUSTOMERS/UPDATE_CUSTOMER_INTEGRATION_SUCCESS',
  UPDATE_CUSTOMER_INTEGRATION_FAILED = 'CUSTOMERS/UPDATE_CUSTOMER_INTEGRATION_FAILED',
  UPDATE_CUSTOMER_INTEGRATION_STATUS = 'CUSTOMERS/UPDATE_CUSTOMER_INTEGRATION_STATUS',
  UPDATE_CUSTOMER_INTEGRATION_STATUS_SUCCESS = 'CUSTOMERS/UPDATE_CUSTOMER_INTEGRATION_STATUS_SUCCESS',
  UPDATE_CUSTOMER_INTEGRATION_STATUS_FAILED = 'CUSTOMERS/UPDATE_CUSTOMER_INTEGRATION_STATUS_FAILED',
  GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID = 'CUSTOMERS/GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID',
  GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_SUCCESS',
  GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_FAILED = 'CUSTOMERS/GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_FAILED',
  SET_OPERATIONS_TABLE_PAGE = 'CUSTOMERS/SET_OPERATIONS_TABLE_PAGE',
  SET_OPERATIONS_TABLE_PER_PAGE = 'CUSTOMERS/SET_OPERATIONS_TABLE_PER_PAGE',
  SET_OPERATIONS_TABLE_SORT_ORDER = 'CUSTOMERS/SET_OPERATIONS_TABLE_SORT_ORDER',
  SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE',
  SET_OPERATIONS_TABLE_FILTER_OPERATION = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_OPERATION',
  SET_OPERATIONS_TABLE_FILTER_STATUS = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_STATUS',
  SET_OPERATIONS_TABLE_FILTER_SYSTEM = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_SYSTEM',
  SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME',
  SET_OPERATIONS_TABLE_FILTER_DATE_FROM = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_DATE_FROM',
  SET_OPERATIONS_TABLE_FILTER_DATE_TO = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_DATE_TO',
  SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS = 'CUSTOMERS/SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS',
  SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID',
  SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID = 'CUSTOMERS/SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID',
  GET_CLIENTS_OPTIONS = 'CUSTOMERS/GET_CLIENTS_OPTIONS',
  GET_CLIENTS_OPTIONS_SUCCESS = 'CUSTOMERS/GET_CLIENTS_OPTIONS_SUCCESS',
  GET_CLIENTS_OPTIONS_FAILED = 'CUSTOMERS/GET_CLIENTS_OPTIONS_FAILED',
  GET_CUSTOMER_TEST_CONNECTION = 'CUSTOMERS/GET_CUSTOMER_TEST_CONNECTION',
  GET_CUSTOMER_TEST_CONNECTION_SUCCESS = 'CUSTOMERS/GET_CUSTOMER_TEST_CONNECTION_SUCCESS',
  GET_CUSTOMER_TEST_CONNECTION_FAILED = 'CUSTOMERS/GET_CUSTOMER_TEST_CONNECTION_FAILED',
  CUSTOMER_RESET = 'CUSTOMERS/CUSTOMER_RESET',
  CUSTOMER_RESET_PARTIAL = 'CUSTOMERS/CUSTOMER_RESET_PARTIAL',
  INTEGRATION_RESET = 'CUSTOMERS/INTEGRATION_RESET',
  CUSTOMER_OPERATIONS_RESET = 'CUSTOMERS/CUSTOMER_OPERATIONS_RESET',
}

// ActionCreators
export const actionCreators = {
  getCustomer: (payload: CustomerByIdRequestData) => createAction(ActionTypeKeys.GET_CUSTOMER, payload),
  getCustomerSuccess: (payload: CustomerData) => createAction(ActionTypeKeys.GET_CUSTOMER_SUCCESS, payload),
  getCustomerFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_FAILED),

  updateCustomer: (payload: CustomerRequestDataExtended) => createAction(ActionTypeKeys.UPDATE_CUSTOMER, payload),
  updateCustomerSuccess: (payload: CustomerData) => createAction(ActionTypeKeys.UPDATE_CUSTOMER_SUCCESS, payload),
  updateCustomerFailed: (payload: number) => createAction(ActionTypeKeys.UPDATE_CUSTOMER_FAILED, payload),

  getCustomerIntegrations: (payload: CustomerIntegrationsRequestParams) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS, payload),
  getCustomerIntegrationsSuccess: (payload: IntegrationData[]) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS_SUCCESS, payload),
  getCustomerIntegrationsFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS_FAILED),

  getCustomerClientAllIntegrations: (payload: CustomerIntegrationsRequestParams) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS, payload),
  getCustomerClientAllIntegrationsSuccess: (payload: IntegrationData[]) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_SUCCESS, payload),
  getCustomerClientAllIntegrationsFailed: () =>
    createAction(ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_FAILED),

  getCustomerAvailableSystems: (payload: CustomerAvailableIntegrationsSystemRequestParams) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS, payload),
  getCustomerAvailableSystemsSuccess: (payload: IntegrationSystem[]) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS_SUCCESS, payload),
  getCustomerAvailableSystemsFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS_FAILED),

  setCustomerStatus: (payload: CustomerStatusRequestData) => createAction(ActionTypeKeys.SET_CUSTOMER_STATUS, payload),
  setCustomerStatusSuccess: () => createAction(ActionTypeKeys.SET_CUSTOMER_STATUS_SUCCESS),
  setCustomerStatusFailed: () => createAction(ActionTypeKeys.SET_CUSTOMER_STATUS_FAILED),

  customerReset: () => createAction(ActionTypeKeys.CUSTOMER_RESET),
  customerResetPartial: () => createAction(ActionTypeKeys.CUSTOMER_RESET_PARTIAL),
  integrationReset: () => createAction(ActionTypeKeys.INTEGRATION_RESET),

  getCustomerConfigurationSchema: (payload: CustomerConfigurationSchemaRequest) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA, payload),
  getCustomerConfigurationSchemaSuccess: (payload: IntegrationSchema) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA_SUCCESS, payload),
  getCustomerConfigurationSchemaFailed: (payload: number) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA_FAILED, payload),

  getCustomerSystemSettings: (payload: CustomerIntegrationBySystemRequestParams) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS, payload),
  getCustomerSystemSettingsSuccess: (payload: IntegrationSettingsDefault) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS_SUCCESS, payload),
  getCustomerSystemSettingsFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS_FAILED),

  getCustomerIntegration: (payload: IntegrationRequest) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_INTEGRATION, payload),
  getCustomerIntegrationSuccess: (payload: IntegrationData) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_INTEGRATION_SUCCESS, payload),
  getCustomerIntegrationFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_INTEGRATION_FAILED),

  createCustomerIntegration: (payload: CreateCustomerIntegrationsRequestParams) =>
    createAction(ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION, payload),
  createCustomerIntegrationSuccess: () => createAction(ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION_SUCCESS),
  createCustomerIntegrationFailed: () => createAction(ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION_FAILED),

  getCustomerOperations: (payload: OperationsRequestData) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID, payload),
  getCustomerOperationsSuccess: (payload: OperationsList) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_SUCCESS, payload),
  getCustomerOperationsFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_FAILED),

  setOperationsTablePage: (payload: number) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_PAGE, payload),
  setOperationsTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_PER_PAGE, payload),
  setOperationsTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_SORT_ORDER, payload),
  setOperationsFilterOperationScope: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE, payload),
  setOperationsFilterOperation: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION, payload),
  setOperationsFilterStatus: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_STATUS, payload),
  setOperationsFilterSystems: (payload: IntegrationsSystems[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SYSTEM, payload),
  setOperationsFilterClientName: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME, payload),
  setFilterDateFrom: (payload: null | Date) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_FROM, payload),
  setFilterDateTo: (payload: null | Date) => createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_TO, payload),
  setFilterSourceEntityId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID, payload),
  setFilterTargetEntityId: (payload: string) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID, payload),
  setShowOnlyDeactivatedSystems: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS, payload),

  updateCustomerIntegration: (payload: UpdateIntegrationRequest) =>
    createAction(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION, payload),
  updateCustomerIntegrationSuccess: (payload: IntegrationData) =>
    createAction(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_SUCCESS, payload),
  updateCustomerIntegrationFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_FAILED, payload),

  updateCustomerIntegrationStatus: (payload: CustomerIntegrationStatusRequest) =>
    createAction(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS, payload),
  updateCustomerIntegrationStatusSuccess: (
    payload: Pick<CustomerIntegrationStatusRequest, 'isProfilePage' | 'integrationId'>,
  ) => createAction(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS_SUCCESS, payload),
  updateCustomerIntegrationStatusFailed: (payload: number) =>
    createAction(ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS_FAILED, payload),

  getClientsOptions: (payload: ClientsOptionsRequestDataByCustomerId) =>
    createAction(ActionTypeKeys.GET_CLIENTS_OPTIONS, payload),
  getClientsOptionsSuccess: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.GET_CLIENTS_OPTIONS_SUCCESS, payload),
  getClientsOptionsFailed: () => createAction(ActionTypeKeys.GET_CLIENTS_OPTIONS_FAILED),

  getCustomerTestConnection: (payload: TestConnectionRequest) =>
    createAction(ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION, payload),
  getCustomerTestConnectionSuccess: () => createAction(ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION_SUCCESS),
  getCustomerTestConnectionFailed: () => createAction(ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION_FAILED),

  customerOperationsReset: () => createAction(ActionTypeKeys.CUSTOMER_OPERATIONS_RESET),
};

// State
export interface State {
  isFetching: boolean;
  isTestConnectionFetching: boolean;
  customer: CustomerData;
  integrations: IntegrationData[];
  customerAndAllClientsIntegrations: IntegrationData[];
  integration: IntegrationData;
  availableSystems: IntegrationSystem[];
  integrationSchema: IntegrationSchema;
  errorCode: number | null;
  operations: OperationsCustomerState;
}

export const initialState: State = {
  isFetching: false,
  isTestConnectionFetching: false,
  customer: {} as CustomerData,
  integrations: [] as IntegrationData[],
  customerAndAllClientsIntegrations: [] as IntegrationData[],
  integration: {} as IntegrationData,
  availableSystems: [],
  integrationSchema: {} as IntegrationSchema,
  errorCode: null,
  operations: {
    data: [] as OperationData[],
    totalCount: 0,
    page: 1,
    perPage: ITEMS_PER_PAGE_COUNT,
    orderBy: TableDateField.STARTED_DATE_TIME,
    sortOrder: TableSortOrder.DESCENDING,
    filterScope: [defaultFilterOptions],
    filterType: [defaultFilterOptions],
    filterStatus: [defaultFilterOptions],
    filterSystem: [systemsFilterOptions[0]],
    filterClientName: [defaultFilterOptions],
    filterDateFrom: null,
    filterDateTo: null,
    filterSourceEntityId: '',
    filterTargetEntityId: '',
    showOnlyDeactivatedSystems: false,
    clientsOptions: [] as FormSelectOption[],
  },
};

// Reducer
export const customerReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CUSTOMER:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customer: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customer: action.payload,
        errorCode: null,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.SET_CUSTOMER_STATUS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.SET_CUSTOMER_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_CUSTOMER_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integrations: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_INTEGRATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customerAndAllClientsIntegrations: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_CLIENT_ALL_INTEGRATIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };


    case ActionTypeKeys.CUSTOMER_RESET:
      return {
        ...initialState,
      };

    case ActionTypeKeys.CUSTOMER_RESET_PARTIAL:
      return {
        ...state,
        errorCode: null,
      };

    case ActionTypeKeys.INTEGRATION_RESET:
      return {
        ...state,
        integration: initialState.integration,
        integrationSchema: initialState.integrationSchema,
      };

    case ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA:
      return {
        ...state,
        integrationSchema: initialState.integrationSchema,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integrationSchema: action.payload,
        errorCode: null,
      };

    case ActionTypeKeys.GET_CUSTOMER_CONFIGURATION_SCHEMA_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS:
    case ActionTypeKeys.GET_CUSTOMER_INTEGRATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS_SUCCESS:
    case ActionTypeKeys.GET_CUSTOMER_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integration: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_SYSTEM_SETTINGS_FAILED:
    case ActionTypeKeys.GET_CUSTOMER_INTEGRATION_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        availableSystems: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_AVAILABLE_SYSTEMS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.CREATE_CUSTOMER_INTEGRATION_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        integration: action.payload,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { integrationId, isProfilePage } = action.payload;

      if (!isProfilePage) {
        return {
          ...state,
          isFetching: false,
          integrations: state.integrations.map((integration) => {
            if (integration.id === integrationId) {
              return {
                ...integration,
                isActive: !integration.isActive,
              };
            } else {
              return {
                ...integration,
              };
            }
          }),
        };
      } else {
        return {
          ...state,
          isFetching: false,
        };
      }

    case ActionTypeKeys.UPDATE_CUSTOMER_INTEGRATION_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
        errorCode: action.payload,
      };

    case ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        operations: {
          ...state.operations,
          data: action.payload.data,
          totalCount: action.payload.totalCount,
        },
      };

    case ActionTypeKeys.GET_CUSTOMER_OPERATIONS_BY_CLIENT_ID_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_PAGE:
      return {
        ...state,
        operations: {
          ...state.operations,
          page: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_PER_PAGE:
      return {
        ...state,
        operations: {
          ...state.operations,
          perPage: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_SORT_ORDER:
      return {
        ...state,
        operations: {
          ...state.operations,
          orderBy: action.payload.orderBy,
          sortOrder: action.payload.sortOrder,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION_SCOPE:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterScope: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_OPERATION:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterType: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_STATUS:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterStatus: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SYSTEM:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterSystem: [...action.payload],
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_CLIENT_NAME:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterClientName: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_FROM:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterDateFrom: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_DATE_TO:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterDateTo: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_SOURCE_ENTITY_ID:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterSourceEntityId: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_FILTER_TARGET_ENTITY_ID:
      return {
        ...state,
        operations: {
          ...state.operations,
          filterTargetEntityId: action.payload,
        },
      };

    case ActionTypeKeys.SET_OPERATIONS_TABLE_SHOW_DEACTIVATED_SYSTEMS:
      return {
        ...state,
        operations: {
          ...state.operations,
          showOnlyDeactivatedSystems: action.payload,
        },
      };

    case ActionTypeKeys.GET_CLIENTS_OPTIONS:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENTS_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        operations: {
          ...state.operations,
          clientsOptions: [...action.payload],
        },
      };

    case ActionTypeKeys.GET_CLIENTS_OPTIONS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION:
      return {
        ...state,
        isTestConnectionFetching: true,
      };

    case ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION_SUCCESS:
      return {
        ...state,
        isTestConnectionFetching: false,
      };

    case ActionTypeKeys.GET_CUSTOMER_TEST_CONNECTION_FAILED:
      return {
        ...state,
        isTestConnectionFetching: false,
      };

    case ActionTypeKeys.CUSTOMER_OPERATIONS_RESET:
      return {
        ...state,
        isFetching: false,
        operations: initialState.operations,
      };

    default:
      return state;
  }
};
