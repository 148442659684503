import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import {
  ExecuteClientsRequestData,
  TransferClientData,
  TransferClients,
  TransferClientsRequestData,
  TransferredDataItem,
} from 'types/TransferHistoricalData';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import { SelectOptionTransferStatus } from 'types/Form';
import { transferDataStatusFilterOptions } from 'common/constants';
import { TableSortOrder, TablesSortOrderPayload } from 'types/Tables';

// Actions
export enum ActionTypeKeys {
  GET_CLIENTS_FOR_TRANSFER_DATA = 'TRANSFER_HISTORICAL_DATA/GET_CLIENTS_FOR_TRANSFER_DATA',
  GET_CLIENTS_FOR_TRANSFER_DATA_SUCCESS = 'TRANSFER_HISTORICAL_DATA/GET_CLIENTS_FOR_TRANSFER_DATA_SUCCESS',
  GET_CLIENTS_FOR_TRANSFER_DATA_FAILED = 'TRANSFER_HISTORICAL_DATA/GET_CLIENTS_FOR_TRANSFER_DATA_FAILED',
  EXECUTE_DATA_TRANSFER = 'TRANSFER_HISTORICAL_DATA/EXECUTE_DATA_TRANSFER',
  EXECUTE_DATA_TRANSFER_SUCCESS = 'TRANSFER_HISTORICAL_DATA/EXECUTE_DATA_TRANSFER_SUCCESS',
  EXECUTE_DATA_TRANSFER_FAILED = 'TRANSFER_HISTORICAL_DATA/EXECUTE_DATA_TRANSFER_FAILED',
  SET_TABLE_PAGE = 'TRANSFER_HISTORICAL_DATA/SET_TABLE_PAGE',
  SET_TABLE_PER_PAGE = 'TRANSFER_HISTORICAL_DATA/SET_TABLE_PER_PAGE',
  // eslint-disable-next-line max-len
  SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_STATUS = 'TRANSFER_HISTORICAL_DATA/SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_STATUS',
  // eslint-disable-next-line max-len
  SET_TRANSFER_HISTORICAL_DATA_TABLE_SORT_ORDER = 'TRANSFER_HISTORICAL_DATA/SET_TRANSFER_HISTORICAL_DATA_TABLE_SORT_ORDER',
  // eslint-disable-next-line max-len
  SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_SEARCH = 'TRANSFER_HISTORICAL_DATA/SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_SEARCH',
  TRANSFER_HISTORICAL_DATA_RESET = 'TRANSFER_HISTORICAL_DATA/TRANSFER_HISTORICAL_DATA_RESET',
  TRANSFER_HISTORICAL_DATA_RESET_FILTERS = 'TRANSFER_HISTORICAL_DATA/TRANSFER_HISTORICAL_DATA_RESET_FILTERS',
}

// ActionCreators
export const actionCreators = {
  getClientsForTransferData: (payload: TransferClientsRequestData) =>
    createAction(ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA, payload),
  getClientsForTransferDataSuccess: (payload: TransferClients) =>
    createAction(ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA_SUCCESS, payload),
  getClientsForTransferDataFailed: () => createAction(ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA_FAILED),
  executeDataTransfer: (payload: ExecuteClientsRequestData) =>
    createAction(ActionTypeKeys.EXECUTE_DATA_TRANSFER, payload),
  executeDataTransferSuccess: (payload: TransferredDataItem[]) =>
    createAction(ActionTypeKeys.EXECUTE_DATA_TRANSFER_SUCCESS, payload),
  executeDataTransferFailed: () => createAction(ActionTypeKeys.EXECUTE_DATA_TRANSFER_FAILED),
  setTablePage: (payload: number) => createAction(ActionTypeKeys.SET_TABLE_PAGE, payload),
  setTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_TABLE_PER_PAGE, payload),
  setTransferHistoricalDataTableFilterStatus: (payload: SelectOptionTransferStatus[]) =>
    createAction(ActionTypeKeys.SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_STATUS, payload),
  setTransferHistoricalDataTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_TRANSFER_HISTORICAL_DATA_TABLE_SORT_ORDER, payload),
  setTransferHistoricalDataTableFilterSearch: (payload: string) =>
    createAction(ActionTypeKeys.SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_SEARCH, payload),
  transferHistoricalDataReset: () => createAction(ActionTypeKeys.TRANSFER_HISTORICAL_DATA_RESET),
  transferHistoricalDataResetFilters: () => createAction(ActionTypeKeys.TRANSFER_HISTORICAL_DATA_RESET_FILTERS),
};

// State
export interface State {
  isFetching: boolean;
  totalCount: number;
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterSearch: string;
  clients: TransferClientData[];
  transferredData: TransferredDataItem[];
  filterStatus: SelectOptionTransferStatus[];
}

export const initialState: State = {
  isFetching: false,
  totalCount: 0,
  page: 1,
  perPage: ITEMS_PER_PAGE_COUNT,
  orderBy: 'lastExecuted',
  sortOrder: TableSortOrder.DESCENDING,
  filterSearch: '',
  clients: [] as TransferClientData[],
  transferredData: [] as TransferredDataItem[],
  filterStatus: [transferDataStatusFilterOptions[0]],
};

// Reducer
export const transferHistoricalDataReducer: Reducer<State> = (
  state: State = initialState,
  action: AnyAction,
): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clients: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case ActionTypeKeys.GET_CLIENTS_FOR_TRANSFER_DATA_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case ActionTypeKeys.SET_TABLE_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case ActionTypeKeys.SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_STATUS:
      return {
        ...state,
        filterStatus: [...action.payload],
      };

    case ActionTypeKeys.SET_TRANSFER_HISTORICAL_DATA_TABLE_SORT_ORDER:
      return {
        ...state,
        orderBy: action.payload.orderBy,
        sortOrder: action.payload.sortOrder,
      };

    case ActionTypeKeys.SET_TRANSFER_HISTORICAL_DATA_TABLE_FILTER_SEARCH:
      return {
        ...state,
        filterSearch: action.payload,
      };

    case ActionTypeKeys.EXECUTE_DATA_TRANSFER:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.EXECUTE_DATA_TRANSFER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transferredData: action.payload,
      };

    case ActionTypeKeys.EXECUTE_DATA_TRANSFER_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.TRANSFER_HISTORICAL_DATA_RESET_FILTERS:
      return {
        ...state,
        filterStatus: initialState.filterStatus,
      };

    case ActionTypeKeys.TRANSFER_HISTORICAL_DATA_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
