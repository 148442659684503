import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { SidebarInfoData, SidebarInfoRequestData } from 'types/Sidebar';

// Actions
export enum ActionTypeKeys {
  GET_SIDEBAR_INFO = 'SIDEBAR/GET_SIDEBAR_INFO',
  GET_SIDEBAR_INFO_SUCCESS = 'SIDEBAR/GET_SIDEBAR_INFO_SUCCESS',
  GET_SIDEBAR_INFO_FAILED = 'SIDEBAR/GET_SIDEBAR_INFO_FAILED',
}

// ActionCreators
export const actionCreators = {
  getSidebarInfo: (payload: SidebarInfoRequestData) => createAction(ActionTypeKeys.GET_SIDEBAR_INFO, payload),
  getSidebarInfoSuccess: (payload: SidebarInfoData) => createAction(ActionTypeKeys.GET_SIDEBAR_INFO_SUCCESS, payload),
  getSidebarInfoFailed: () => createAction(ActionTypeKeys.GET_SIDEBAR_INFO_FAILED),
};

// State
export interface State {
  isFetching: boolean;
  customersAllCount: number;
  clientsAllCount: number;
  clientsCount: number;
}

export const initialState: State = {
  isFetching: false,
  customersAllCount: 0,
  clientsAllCount: 0,
  clientsCount: 0,
};

// Reducer
export const sidebarReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_SIDEBAR_INFO:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_SIDEBAR_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customersAllCount: action.payload.customersAllCount,
        clientsAllCount: action.payload.clientsAllCount,
        clientsCount: action.payload.clientsCount,
      };

    case ActionTypeKeys.GET_SIDEBAR_INFO_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
};
