import { IntegrationData, IntegrationSettings } from 'types/Integrations';

interface prepareRequestType {
  integration: null | IntegrationData;
  isIntegrationsDefaultPage: boolean;
  formData: any;
  fieldsArray: any;
  systemId?: string;
}

export const prepareRequestData = ({
  integration,
  isIntegrationsDefaultPage,
  formData,
  fieldsArray,
  systemId,
}: prepareRequestType) => {
  if (isIntegrationsDefaultPage) {
    const updateSystemRequestData = {
      settings: [] as IntegrationSettings[],
    };

    Object.keys(formData).forEach((key: string) => {
      const findSchemaField = fieldsArray.find((field: any) => field.fieldId === key);
      if (findSchemaField !== undefined) {
        updateSystemRequestData.settings.push({
          name: findSchemaField.name,
          value: formData[key]?.toString(),
        });
      }
    });

    return updateSystemRequestData;
  }

  if (!integration) {
    const createRequestData = {
      systemId: systemId,
      isActive: true,
      settings: [] as IntegrationSettings[],
    };

    Object.keys(formData).forEach((key: string) => {
      if (formData[key] !== undefined) {
        const findSchemaField = fieldsArray.find((field: any) => field.fieldId === key);
        const isFieldOverride = findSchemaField && formData[findSchemaField.fieldGroup];

        if (!isFieldOverride && findSchemaField !== undefined && findSchemaField.isOverridable) {
          return;
        }

        if (findSchemaField !== undefined) {
          createRequestData.settings.push({
            name: findSchemaField.name,
            value: formData[key].toString(),
          });
        }
      }
    });

    return createRequestData;
  } else {
    const editRequestData = {
      systemId: systemId,
      isActive: isIntegrationsDefaultPage ? undefined : integration.isActive,
      settings: [] as IntegrationSettings[],
    };

    Object.keys(formData).forEach((key: string) => {
      if (formData[key] !== undefined) {
        const findSchemaField = fieldsArray.find((field: any) => field.fieldId === key);
        const isFieldOverride = findSchemaField && formData[findSchemaField.fieldGroup];

        if (!isFieldOverride && findSchemaField !== undefined && findSchemaField.isOverridable) {
          return;
        }

        if (findSchemaField !== undefined) {
          editRequestData.settings.push({
            name: findSchemaField.name,
            value: formData[key].toString(),
          });
        }
      }
    });

    return editRequestData;
  }
};
