import { APP_URLS } from 'common/constants';
import { BREADCRUMBS_TITLES } from 'common/constants/Breadcrumbs';

const getCustomerProfileBreadcrumbs = (
  customerId: number,
  customerName: string,
): { title: string; url?: string; isActive?: boolean }[] => [
  {
    title: BREADCRUMBS_TITLES.GENERAL,
    url: APP_URLS.customers,
  },
  {
    title: BREADCRUMBS_TITLES.CUSTOMERS,
    url: APP_URLS.customers,
  },
  {
    title: customerName,
    url: APP_URLS.toCustomerProfile(customerId),
  },
];

export default getCustomerProfileBreadcrumbs;
