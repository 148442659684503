import { State as CustomerState } from 'screens/Customer/logic/customerReducer';
import { State as SidebarState } from './sidebarReducer';

export const getSidebarView = (state: SidebarState, customerState: CustomerState) => ({
  isFetching: state.isFetching,
  customersAllCount: state.customersAllCount,
  clientsAllCount: state.clientsAllCount,
  clientsCount: state.clientsCount,
  customerIntegrations: customerState.integrations,
  customerAndAllClientsIntegrations: customerState.customerAndAllClientsIntegrations,
});
