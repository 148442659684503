import { AnyAction, Reducer } from 'redux';
import { createAction } from 'store/Action';
import { ClientData, Clients, ClientsRequestData } from 'types/Client';
import { TableDateField, TableSortOrder, TablesSortOrderPayload } from 'types/Tables';
import { FormSelectOption } from 'types/Form';
import { ITEMS_PER_PAGE_COUNT } from 'common/components/Paginator/constants';
import { defaultFilterOptions, systemsFilterOptions } from 'common/constants';
import { IntegrationsSystems, IntegrationSystemOptions } from 'types/Integrations';

// Actions
export enum ActionTypeKeys {
  GET_CLIENTS_ALL = 'CLIENTS_ALL/GET_CLIENTS_ALL',
  GET_CLIENTS_ALL_SUCCESS = 'CLIENTS_ALL/GET_CLIENTS_ALL_SUCCESS',
  GET_CLIENTS_ALL_FAILED = 'CLIENTS_ALL/GET_CLIENTS_ALL_FAILED',
  SET_CLIENTS_ALL_TABLE_PAGE = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_PAGE',
  SET_CLIENTS_ALL_TABLE_PER_PAGE = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_PER_PAGE',
  SET_CLIENTS_ALL_TABLE_FILTER_STATUS = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_FILTER_STATUS',
  SET_CLIENTS_ALL_TABLE_FILTER_SYSTEM = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_FILTER_SYSTEM',
  SET_CLIENTS_ALL_TABLE_FILTER_SEARCH = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_FILTER_SEARCH',
  SET_CLIENTS_ALL_TABLE_SORT_ORDER = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_SORT_ORDER',
  SET_CLIENTS_ALL_TABLE_INCLUDE_DEACTIVATED = 'CLIENTS_ALL/SET_CLIENTS_ALL_TABLE_INCLUDE_DEACTIVATED',
  SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS = 'CLIENTS_ALL/SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS',
  CLIENTS_ALL_RESET = 'CLIENTS_ALL/CLIENTS_ALL_RESET',
}

export const actionCreators = {
  getClientsAll: (payload: ClientsRequestData) => createAction(ActionTypeKeys.GET_CLIENTS_ALL, payload),
  getClientsAllSuccess: (payload: Clients) => createAction(ActionTypeKeys.GET_CLIENTS_ALL_SUCCESS, payload),
  getClientsAllFailed: () => createAction(ActionTypeKeys.GET_CLIENTS_ALL_FAILED),
  setClientsAllTablePage: (payload: number) => createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_PAGE, payload),
  setClientsAllTablePerPage: (payload: number) => createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_PER_PAGE, payload),
  setClientsAllTableFilterStatus: (payload: FormSelectOption[]) =>
    createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_FILTER_STATUS, payload),
  setClientsAllTableFilterSystem: (payload: IntegrationsSystems[]) =>
    createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_FILTER_SYSTEM, payload),
  setClientsAllTableFilterSearch: (payload: string) =>
    createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_FILTER_SEARCH, payload),
  setClientsAllTableSortOrder: (payload: TablesSortOrderPayload) =>
    createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_SORT_ORDER, payload),
  setIsIncludeDeactivated: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_CLIENTS_ALL_TABLE_INCLUDE_DEACTIVATED, payload),
  setShowOnlyDeactivatedSystems: (payload: boolean) =>
    createAction(ActionTypeKeys.SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS, payload),
  clientsAllReset: () => createAction(ActionTypeKeys.CLIENTS_ALL_RESET),
};

// State
export interface State {
  isFetching: boolean;
  totalCount: number;
  clients: ClientData[];
  page: number;
  perPage: number;
  orderBy: string;
  sortOrder: TableSortOrder;
  filterStatus: FormSelectOption[];
  filterSystem: IntegrationSystemOptions[];
  filterSearch: string;
  isIncludeDeactivated: boolean;
  showOnlyDeactivatedSystems: boolean;
}

export const initialState: State = {
  isFetching: false,
  totalCount: 0,
  clients: [] as ClientData[],
  page: 1,
  perPage: ITEMS_PER_PAGE_COUNT,
  orderBy: TableDateField.UPDATE_ON,
  sortOrder: TableSortOrder.DESCENDING,
  filterStatus: [defaultFilterOptions],
  filterSystem: [systemsFilterOptions[0]],
  filterSearch: '',
  isIncludeDeactivated: false,
  showOnlyDeactivatedSystems: false,
};

// Reducer
export const clientsAllReducer: Reducer<State> = (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case ActionTypeKeys.GET_CLIENTS_ALL:
      return {
        ...state,
        isFetching: true,
      };

    case ActionTypeKeys.GET_CLIENTS_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clients: action.payload.data,
        totalCount: action.payload.totalCount,
      };

    case ActionTypeKeys.GET_CLIENTS_ALL_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_FILTER_STATUS:
      return {
        ...state,
        filterStatus: [...action.payload],
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_FILTER_SYSTEM:
      return {
        ...state,
        filterSystem: [...action.payload],
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_FILTER_SEARCH:
      return {
        ...state,
        filterSearch: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_SORT_ORDER:
      return {
        ...state,
        orderBy: action.payload.orderBy,
        sortOrder: action.payload.sortOrder,
      };

    case ActionTypeKeys.SET_CLIENTS_ALL_TABLE_INCLUDE_DEACTIVATED:
      return {
        ...state,
        isIncludeDeactivated: action.payload,
      };

    case ActionTypeKeys.SET_CLIENTS_TABLE_SHOW_DEACTIVATED_SYSTEMS:
      return {
        ...state,
        showOnlyDeactivatedSystems: action.payload,
      };

    case ActionTypeKeys.CLIENTS_ALL_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
