import { Icon } from 'types/Icon';

export const IntegrationsDefaultIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '16'} height={height || '20'} viewBox='0 0 16 20'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M11.02,3 C11.02,3.351 10.96,3.687 10.85,4 L14,4 C15.105,4 16,4.895 16,6 L16,18 C16,19.105 15.105,20 14,20 L2,20 C0.895,20 0,19.105 0,18 L0,6 C0,4.895 0.895,4 2,4 L5.191,4 C5.08,3.687 5.02,3.351 5.02,3 C5.02,1.343 6.363,0 8.02,0 C9.677,0 11.02,1.343 11.02,3 Z M2,6.5 C2,6.224 2.224,6 2.5,6 L4.02,6 L4.02,7.5 C4.02,7.776 4.244,8 4.52,8 L11.52,8 C11.796,8 12.02,7.776 12.02,7.5 L12.02,6 L13.5,6 C13.777,6 14,6.224 14,6.5 L14,17.5 C14,17.776 13.777,18 13.5,18 L2.5,18 C2.224,18 2,17.776 2,17.5 L2,6.5 Z M8.02,4 C7.468,4 7.02,3.552 7.02,3 C7.02,2.448 7.468,2 8.02,2 C8.573,2 9.02,2.448 9.02,3 C9.02,3.552 8.573,4 8.02,4 Z'
        id='IntegrationsDefaultIcon-path-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-472.000000, -3190.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(352.000000, 133.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#IntegrationsDefaultIcon-path-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
