import styled from 'styled-components';
import { Button as ButtonInterface } from 'types/Button';
import {
  defineButtonBackground,
  defineButtonBorder,
  defineButtonColor,
  defineTextAlignment,
  getButtonFontSize,
  getButtonPadding,
  getButtonSize,
} from '../utils';

/* eslint-disable */
export const Btn = styled.button<ButtonInterface>`
  font-size: ${({ size }) => getButtonFontSize({ size })};
  width: ${({ fullWidth, circleButton }) => (fullWidth ? '100%' : circleButton ? '36px' : 'auto')};
  min-height: ${({ size, circleButton }) => getButtonSize({ size, circleButton })};
  min-width: ${({ circleButton }) => (circleButton ? '36px' : '38px')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign, rightIcon, leftIcon }) => defineTextAlignment(textAlign, rightIcon, leftIcon)};
  padding: ${({ size, circleButton, iconButton }) => getButtonPadding({ circleButton, iconButton, size })};
  border-radius: ${({ circleButton }) => (circleButton ? '50%' : '4px')};
  border: 2px solid
    ${({ theme, primary, secondary, danger, success, pending, transparent, isBgTransparent }) =>
      defineButtonBorder(theme, primary, secondary, danger, success, pending, transparent, isBgTransparent)};
  outline: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background: ${({
    theme,
    primary,
    secondary,
    danger,
    success,
    pending,
    transparent,
    iconButton,
    circleButton,
    isBgTransparent,
    disabled,
  }) =>
    defineButtonBackground(
      theme,
      primary,
      secondary,
      danger,
      success,
      pending,
      transparent,
      iconButton,
      circleButton,
      isBgTransparent,
      disabled,
    ).default};
  color: ${({ theme, primary, secondary, transparent, iconButton, circleButton }) =>
    defineButtonColor(theme, primary, secondary, transparent, iconButton, circleButton)};
  transition: all 0.3s ease;
  box-shadow: ${({ transparent, disabled, iconButton }) =>
    disabled || transparent || iconButton ? 'none' : '0px 8px 8px rgba(31, 47, 77, 0.1)'};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ theme, primary, secondary, danger, success, pending, transparent, iconButton, circleButton }) =>
      defineButtonBackground(theme, primary, secondary, danger, success, pending, transparent, iconButton, circleButton)
        .hover};
    box-shadow: ${({ disabled, iconButton }) =>
      disabled || iconButton ? 'none' : '0px 8px 8px rgba(31, 47, 77, 0.3)'};
  }

  &:active {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background: ${({ theme, primary, secondary, danger, success, pending, transparent, iconButton, circleButton }) =>
      defineButtonBackground(theme, primary, secondary, danger, success, pending, transparent, iconButton, circleButton)
        .active};
    box-shadow: none;
  }

  &:focus {
    box-shadow: ${({ disabled, iconButton }) =>
      disabled || iconButton ? 'none' : '0px 8px 8px rgba(31, 47, 77, 0.3)'};
  }

  & > * {
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  }
`;
/* eslint-enable */

interface ButtonIconProps {
  primary?: boolean;
  secondary?: boolean;
  transparent?: boolean;
}

export const BtnIconLeft = styled.span<ButtonIconProps>`
  display: inline-flex;
  color: ${({ theme, primary, secondary, transparent }) =>
    primary || secondary || transparent ? theme.light : theme.fontPrimary};
  margin-right: 10px;
`;

export const BtnIconRight = styled.span<ButtonIconProps>`
  display: inline-flex;
  color: ${({ theme, primary, secondary, transparent }) =>
    primary || secondary || transparent ? theme.light : theme.fontPrimary};
  margin-left: 10px;
`;

export const ButtonLeft = styled.div`
  align-items: center;
  display: flex;
`;
