import { ReactElement } from 'react';
import { APP_URLS } from 'common/constants';
import { TableDateField } from 'types/Tables';
import { IntegrationsInfo, IntegrationsSystems, integrationsSystemsNamesMapping } from 'types/Integrations';
import { getFormattedLongDate } from 'utils';
import { Message } from 'common/components/Message';
import { Status } from 'types/Status';
import { TagList, TagListItem, StatusLabel, StyledLink, DateWrap } from './styles';

interface ClientsDataTable {
  cellData: number;
  rowIndex: number;
  cellName: string;
  cellTitle: ReactElement;
  row: ClientsDataTableRow;
}

export interface ClientsDataTableRow {
  id: number;
  customerId: number;
  key: number;
  name: string;
  customerName: string;
  lastUpdate: string;
  comment: string | null;
  organizationNumber: string;
  status: Status;
  integrations: IntegrationsInfo[];
}

const clientsAllTableColumns = [
  {
    name: 'id',
    title: <Message id='clients-all-screen-table-client-id' />,
    width: 100,
    sortable: true,
    cellCallback: ({ row: { id, customerId } }: ClientsDataTable) => (
      <StyledLink to={APP_URLS.toClient(Number(customerId), Number(id))}>{id}</StyledLink>
    ),
  },
  {
    name: 'name',
    title: <Message id='clients-all-screen-table-client-name' />,
    width: 350,
    sortable: true,
    cellCallback: ({ row: { id, customerId, name } }: ClientsDataTable) => (
      <StyledLink to={APP_URLS.toClient(Number(customerId), Number(id))}>{name}</StyledLink>
    ),
  },
  {
    name: 'organizationNumber',
    title: <Message id='clients-all-screen-table-organization-number' />,
    width: 200,
    cellCallback: ({ row: { organizationNumber } }: ClientsDataTable) => <strong>{organizationNumber}</strong>,
  },
  {
    name: 'customerName',
    title: <Message id='clients-all-screen-table-customer-name' />,
    width: 350,
    cellCallback: ({ row: { customerId, customerName } }: ClientsDataTable) => (
      <StyledLink to={APP_URLS.toCustomerProfile(Number(customerId))}>{customerName}</StyledLink>
    ),
  },
  {
    name: 'connectedSystems',
    title: <Message id='clients-all-screen-table-connected-systems' />,
    width: 300,
    cellCallback: ({ row: { integrations } }: ClientsDataTable) => (
      <>
        {integrations && integrations.length ? (
          <TagList>
            {integrations.map((item: IntegrationsInfo, index: number) => (
              <TagListItem
                key={item.name + index}
                isActive={item.isActive}
                title={item.isActive ? 'Active' : 'Inactive'}
              >
                {`${item.name === IntegrationsSystems.VICAI ? integrationsSystemsNamesMapping[item.name] : item.name}`}
              </TagListItem>
            ))}
          </TagList>
        ) : (
          <span>
            <Message id='clients-all-screen-no-connected-systems' />
          </span>
        )}
      </>
    ),
  },
  {
    name: TableDateField.UPDATE_ON,
    title: <Message id='clients-all-screen-table-last-update' />,
    width: 300,
    sortable: true,
    cellCallback: ({ row: { lastUpdate } }: ClientsDataTable) => (
      <DateWrap>{getFormattedLongDate(lastUpdate)}</DateWrap>
    ),
  },
  {
    name: 'status',
    title: <Message id='clients-all-screen-table-status' />,
    width: 200,
    sortable: true,
    cellCallback: ({ row: { status } }: ClientsDataTable) => {
      const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);

      return (
        <>
          <StatusLabel status={status}>{statusFormatted}</StatusLabel>
        </>
      );
    },
  },
];

export default clientsAllTableColumns;
