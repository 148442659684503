import { EInvoiceDto, IPAIAdminApiClient } from 'common/api/generated/PAIAdminApiClient';
import { ZipAdditionalData, toZipAdditionalData } from './zipAdditionalData';

export enum DownloadFileType {
  eInvoice = 'eInvoice',
  zipBatch = 'zipBatch',
}

export type DownloadEInvoiceFileInfo = {
  type: DownloadFileType.eInvoice;
  record: { customerId: number; internalId: string; systemName: string; fileName: string };
};

export type DownloadZipBatchFileInfo = {
  type: DownloadFileType.zipBatch;
  record: { customerId: number; systemName: string; zip: ZipAdditionalData };
};

export type DownloadFileInfo = DownloadEInvoiceFileInfo | DownloadZipBatchFileInfo;

export const toDownloadFilesInfo = (dto: EInvoiceDto): DownloadFileInfo[] => {
  const zipData = toZipAdditionalData(dto);

  if (!!zipData && !!dto.customerId && !!dto.systemName) {
    return [
      {
        type: DownloadFileType.zipBatch,
        record: { customerId: dto.customerId, systemName: dto.systemName, zip: zipData },
      },
    ];
  }

  if (!!dto.customerId && !!dto.internalId && !!dto.systemName) {
    return (
      dto.blobFiles?.map((blobFileName) => {
        return {
          type: DownloadFileType.eInvoice,
          record: {
            customerId: dto.customerId!,
            internalId: dto.internalId!,
            systemName: dto.systemName!,
            fileName: blobFileName,
          },
        };
      }) ?? ([] as DownloadFileInfo[])
    );
  }

  return [];
};

export const downloadFile = async (paiClient?: IPAIAdminApiClient, downloadInfo?: DownloadFileInfo): Promise<void> => {
  if (downloadInfo === undefined) {
    throw new Error('Can not download file, downloadInfo data is undefined.');
  }

  const { type, record } = downloadInfo;

  switch (type) {
    case DownloadFileType.eInvoice:
      const { customerId, internalId, fileName } = record as DownloadEInvoiceFileInfo["record"];

      const result = await paiClient?.accessPointControllerDownloadEInvoiceFile(customerId, internalId, { fileName });
      const url = window.URL.createObjectURL(new Blob([result!.data]));

      const a1 = document.createElement('a');
      a1.setAttribute('download', result?.fileName || fileName);
      a1.href = url;
      document.body.appendChild(a1);
      a1.click();
      document.body.removeChild(a1);

      break;

    case DownloadFileType.zipBatch:
      const { zip } = record as DownloadZipBatchFileInfo["record"];

      const a2 = document.createElement('a');
      a2.href = zip.ZipBatchFileUri.replace('azure-storage-emulator', 'localhost'); // blobUrl;
      a2.target = '_blank';
      a2.download = zip.ZipBatchFileName;
      a2.click();

      break;

    default:
      throw new Error('Unsupported download file type.');
  }
};
