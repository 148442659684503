import styled from 'styled-components';

export const CopyButton = styled.span`
  cursor: pointer;
  display: inline-block;
  height: 20px;

  &:hover {
    color: ${({ theme }) => theme.linkColor};
  }
`;
