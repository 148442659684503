import { ReactNode } from 'react';
import { TableAlignment } from '../types';
import { Td } from './styles';

interface BodyCellProps {
  name: string;
  title: string | ReactNode;
  defaultContent?: string;
  rowIndex: number | string;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  align?: TableAlignment;
  sortable?: boolean;
  nestedTable?: boolean;
  collapse?: boolean;
  rowData: any;
  cellCallback?: (e: any) => void | null;
  highlighted?: (payload: any) => void | boolean;
}

const BodyCell = (props: BodyCellProps) => {
  const getOtherProps = () => {
    const {
      name,
      title,
      defaultContent,
      rowIndex,
      rowData,
      cellCallback,
      minWidth,
      maxWidth,
      highlighted,
      ...otherProps
    } = props;

    if (Object.keys(otherProps).length) {
      const restProps = {};

      Object.keys(otherProps).forEach((propName: string) => {
        // @ts-expect-error: Element implicitly has an 'any' type
        if (typeof otherProps[propName] === 'function') {
          // @ts-expect-error: Element implicitly has an 'any' type
          restProps[propName] = (event: any) => {
            // @ts-expect-error: Element implicitly has an 'any' type
            otherProps[propName]({
              event,
              cellData: rowData[name],
              cellName: name,
              cellTitle: title,
              row: rowData,
              rowIndex,
            });
          };
        } else {
          Object.assign(restProps, {
            // @ts-expect-error: Element implicitly has an 'any' type
            [propName]: otherProps[propName],
          });
        }
      });
      return restProps;
    }
    return {};
  };

  const renderContent = () => {
    const { name, title, defaultContent, rowIndex, rowData, cellCallback } = props;
    if (typeof cellCallback === 'function') {
      return cellCallback({
        cellData: rowData[name],
        cellName: name,
        cellTitle: title,
        row: rowData,
        rowIndex,
      });
    }

    if (typeof rowData[name] === 'string' || typeof rowData[name] === 'number') {
      return rowData[name];
    }
    return defaultContent;
  };

  const getHighlightedPropValue = () => {
    if (!props.highlighted) {
      return undefined;
    }
    if (typeof props.highlighted === 'function') {
      return props.highlighted({ row: props.rowData });
    }
    return props.highlighted;
  };

  return (
    <Td
      alignment={props.align}
      highlighted={getHighlightedPropValue()}
      nestedTable={props.nestedTable}
      collapse={props.collapse}
      {...getOtherProps()}
    >
      {renderContent()}
    </Td>
  );
};

export default BodyCell;
