import { Icon } from 'types/Icon';

export const RetryIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '16'} height={height || '20'} viewBox='0 0 16 20'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M2.37388527,4.15112667 C2.07688527,4.34812667 2.07688527,4.78512667 2.37388527,4.98312667 L7.97188527,9.04812667 C8.30488527,9.27012667 8.74988527,9.03212667 8.74988527,8.63212667 L8.74988527,5.65012667 C11.5868853,6.12612667 13.7498853,8.59412667 13.7498853,11.5671267 C13.7498853,14.8801267 11.0628853,17.5671267 7.74988527,17.5671267 C5.07088527,17.5671267 2.80288527,15.8111267 2.03088527,13.3891267 C1.88288527,12.9231267 1.47488527,12.5671267 0.985885272,12.5671267 L0.943885272,12.5671267 C0.342885272,12.5671267 -0.127114728,13.0971267 0.0308852724,13.6771267 C0.956885272,17.0711267 4.06188527,19.5671267 7.74988527,19.5671267 C12.1678853,19.5671267 15.7498853,15.9851267 15.7498853,11.5671267 C15.7498853,7.48712667 12.6958853,4.12112667 8.74988527,3.62812667 L8.74988527,0.501126668 C8.74988527,0.101126668 8.30488527,-0.136873332 7.97188527,0.0851266678 L2.37388527,4.15112667 Z'
        id='path-RetryIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-789.000000, -3245.000000)'>
        <g transform='translate(120.000000, 3057.000000)'>
          <g transform='translate(669.000000, 188.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-RetryIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
