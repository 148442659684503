import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/customerReducer';
import { getIntegrationCreateView } from '../../logic/customerSelectors';
import IntegrationCreate from './IntegrationCreate';

const mapStateToProps = ({ customer }: RootState) => getIntegrationCreateView(customer);

const mapDispatchToProps = {
  getCustomer: actionCreators.getCustomer,
  setStatus: actionCreators.setCustomerStatus,
  getAvailableSystems: actionCreators.getCustomerAvailableSystems,
  createCustomerIntegration: actionCreators.createCustomerIntegration,
  getCustomerConfigurationSchema: actionCreators.getCustomerConfigurationSchema,
  resetPartial: actionCreators.customerResetPartial,
  integrationReset: actionCreators.integrationReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationCreate);
