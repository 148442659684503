import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Alert, Button, Message, Select, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import IntegrationForm from 'components/IntegrationForm';
import StatusSelector from 'components/StatusSelector';
import { APP_URLS, ErrorCodes } from 'common/constants';
import { ClientData, ClientRequestParams, ClientStatusRequestData } from 'types/Client';
import {
  IntegrationsSystems,
  IntegrationsLevel,
  IntegrationSystem,
  ClientAvailableIntegrationsSystemRequestParams,
  IntegrationSchema,
  CreateClientIntegrationsRequestParams,
  IntegrationSystemOptions,
  ClientsConfigurationSchemaRequest,
  IntegrationData,
} from 'types/Integrations';
import { getAllFieldsFromSchema } from 'components/ConfigurationForm/utils';
import { prepareRequestData } from 'components/IntegrationForm/utils';
import { mapSystemName } from 'utils';
import { useAccessToken } from 'hooks';
import getClientIntegrationsCreateBreadcrumbs from './getClientIntegrationsCreateBreadcrumbs';
import {
  ButtonBack,
  Content,
  FormColumn,
  FormColumnWrapper,
  FormInfo,
  FormInfoTitle,
  FormRow,
  FormTitleMark,
  Head,
  HeadActions,
  TabButton,
  TabContent,
  TabContentInner,
  TabHeader,
  TabsList,
  TabsWrapper,
  TabTitle,
  Title,
  TitleMark,
} from './styles';

type clientUrlParams = {
  customerId: string;
  clientId: string;
};

interface IntegrationCreateProps {
  isFetching: boolean;
  errorCode: number | null;
  client: ClientData;
  availableSystems: IntegrationSystem[];
  integration: IntegrationData;
  integrationSchema: IntegrationSchema;
  createClientIntegration: (payload: CreateClientIntegrationsRequestParams) => void;
  getClient: (payload: ClientRequestParams) => void;
  setStatus: (payload: ClientStatusRequestData) => void;
  getClientConfigurationSchema: (payload: ClientsConfigurationSchemaRequest) => void;
  getAvailableSystems: (payload: ClientAvailableIntegrationsSystemRequestParams) => void;
  resetPartial: () => void;
  integrationReset: () => void;
}

const IntegrationCreate = ({
  isFetching,
  errorCode,
  client,
  integration,
  availableSystems,
  integrationSchema,
  getClient,
  setStatus,
  getClientConfigurationSchema,
  resetPartial,
  integrationReset,
  createClientIntegration,
  getAvailableSystems,
}: IntegrationCreateProps) => {
  const accessToken = useAccessToken();
  const { pathname } = useLocation();
  const isIntegrationsDefaultPage = pathname.toLowerCase().includes('integrations-default');
  const { push } = useHistory();
  const { customerId, clientId } = useParams<clientUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [currentSystem, setCurrentSystem] = useState<any>({ id: '0', value: IntegrationsSystems.UNKNOWN, label: '' });
  const [isSystemNameOptionsExists, setIsSystemNameOptionsExists] = useState(false);

  useEffect(() => {
    setBreadcrumbs(
      getClientIntegrationsCreateBreadcrumbs(Number(customerId), Number(clientId), client.customerName, client.name),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, clientId, client.customerName, client.name, setBreadcrumbs]);

  useEffect(() => {
    if (!client.name && accessToken) {
      getClient({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }
  }, [accessToken, customerId, clientId, client.name, getClient]);

  useEffect(() => {
    if (accessToken) {
      getAvailableSystems({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
      });
    }

    return () => integrationReset();
  }, [accessToken, customerId, clientId, getAvailableSystems, integrationReset]);

  useEffect(() => {
    setIsSystemNameOptionsExists(Boolean(availableSystems.length));
  }, [availableSystems]);

  const onSystemNameChangeHandler = (integrationOption: IntegrationSystemOptions) => {
    if (integrationOption.value !== currentSystem.value) {
      setCurrentSystem(integrationOption);
      integrationReset();
      resetPartial();

      getClientConfigurationSchema({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
        isCreatePage: true,
        systemId: integrationOption.id,
      });
    }
  };

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toClientIntegrations(Number(customerId), Number(clientId)));
  }, [customerId, clientId, push]);

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      const allFields = getAllFieldsFromSchema(integrationSchema, integration.settings);
      const preparePayload = prepareRequestData({
        integration: null,
        isIntegrationsDefaultPage,
        formData: data,
        fieldsArray: allFields,
        systemId: currentSystem.id,
      });

      createClientIntegration({
        accessToken,
        customerId: Number(customerId),
        clientId: Number(clientId),
        requestData: preparePayload,
      });
    },
    [
      accessToken,
      isIntegrationsDefaultPage,
      currentSystem.id,
      integrationSchema,
      customerId,
      clientId,
      createClientIntegration,
      integration,
    ],
  );

  const renderForm = useMemo(
    () => (
      <IntegrationForm
        isFetching={isFetching}
        errorCode={errorCode}
        integrationName={currentSystem.value}
        integration={integration}
        integrationSchema={integrationSchema}
        onSubmit={onSubmitButtonClickHandler}
        onCancel={onCancelHandler}
        resetForm={resetPartial}
        level={clientId ? IntegrationsLevel.CLIENT : IntegrationsLevel.CUSTOMER}
      />
    ),
    [
      isFetching,
      errorCode,
      clientId,
      currentSystem.value,
      integration,
      integrationSchema,
      onSubmitButtonClickHandler,
      onCancelHandler,
      resetPartial,
    ],
  );

  const renderMissingSchemaAlert = useMemo(() => {
    if (errorCode === ErrorCodes.NOT_FOUND_SCHEMA) {
      return (
        <Alert
          title={
            <Message
              id='schema-for-client-not-found-title'
              values={{
                variable: mapSystemName(currentSystem.value),
              }}
            />
          }
        >
          <p>
            <Message
              id='please-create-schema-before-create-integration'
              values={{
                variable: mapSystemName(currentSystem.value),
              }}
            />
          </p>
        </Alert>
      );
    }
    return null;
  }, [currentSystem.value, errorCode]);

  const renderRequiredLevelsAlert = useMemo(() => {
    const isRequiredLevelsExists = Array.isArray(integrationSchema.requiredParentLevels)
      ? integrationSchema.requiredParentLevels.length > 0
      : false;

    if (!isRequiredLevelsExists) {
      return null;
    }

    const levels = integrationSchema.requiredParentLevels.join(', ');

    return (
      <Alert title={<Message id='alert-pay-attention' />}>
        <p>
          <Message
            id='please-be-aware-that-settings-for-levels-are-configured'
            values={{
              variable: levels,
            }}
          />
        </p>
      </Alert>
    );
  }, [integrationSchema.requiredParentLevels]);

  return (
    <>
      <Head>
        <Title>
          {client?.name}
          <TitleMark>id#{client?.id}</TitleMark>
        </Title>
        <HeadActions>
          {client.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={client.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <TabsList>
            <TabButton exact to={APP_URLS.toClientProfile(Number(customerId), Number(clientId))}>
              <Message id='client-screen-client-data-tab' />
            </TabButton>
            <TabButton to={APP_URLS.toClientIntegrations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-integrations-tab' />
            </TabButton>
            <TabButton exact to={APP_URLS.toClientOperations(Number(customerId), Number(clientId))}>
              <Message id='client-screen-operation-status-tab' />
            </TabButton>
          </TabsList>
          <TabContent>
            <TabHeader>
              <ButtonBack>
                <Button transparent onClick={onCancelHandler}>
                  <ArrowLeftIcon />
                </Button>
              </ButtonBack>
              <TabTitle>
                <Message id='client-integrations-add-new-integration' />
              </TabTitle>
            </TabHeader>
            <TabContentInner>
              {isFetching && <Spinner />}
              <FormColumnWrapper>
                <FormColumn>
                  <FormRow>
                    <FormTitleMark>
                      <Message id='client-integrations-select-system' />
                    </FormTitleMark>
                    <Select
                      id='systemName'
                      isRequired
                      disabled={!isSystemNameOptionsExists}
                      options={availableSystems}
                      value={currentSystem}
                      label={''}
                      onChange={onSystemNameChangeHandler}
                    />
                  </FormRow>
                </FormColumn>
              </FormColumnWrapper>
              {renderMissingSchemaAlert}
              {renderRequiredLevelsAlert}
              {isSystemNameOptionsExists ? (
                renderForm
              ) : (
                <FormInfo>
                  <FormInfoTitle>
                    <Message id='client-integrations-all-integrations-for-this-client-created' />
                  </FormInfoTitle>
                </FormInfo>
              )}
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default IntegrationCreate;
