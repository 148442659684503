export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_ADMINUI_CLIENT_ID ? process.env.REACT_APP_AUTH_ADMINUI_CLIENT_ID : '',
    // eslint-disable-next-line max-len
    authority: `https://${process.env.REACT_APP_AUTH_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AUTH_TENANT_ID}/${process.env.REACT_APP_AUTH_USER_FLOW}`,
    knownAuthorities: [`${process.env.REACT_APP_AUTH_TENANT_NAME}.b2clogin.com`],
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const loginRequest = {
  scopes: [`https://${process.env.REACT_APP_AUTH_TENANT_NAME}.onmicrosoft.com/paiadminapi/admin_scope`],
};
