import { APP_URLS } from 'common/constants';
import { Message } from 'common/components/Message';
import { StyledLink } from '../styles';

const subscriptionMonitoringTableColumns = [
  {
    name: 'customerName',
    title: <Message id='monitoring-screen-table-customer-name' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { customerId, customerName } }: any) => (
      <StyledLink to={APP_URLS.toCustomerProfile(Number(customerId))}>{customerName}</StyledLink>
    ),
  },
  {
    name: 'clientName',
    title: <Message id='monitoring-screen-table-client-name' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { customerId, clientId, clientName } }: any) => (
      <StyledLink to={APP_URLS.toClient(Number(customerId), Number(clientId))}>{clientName}</StyledLink>
    ),
  },
  {
    name: 'status',
    title: <Message id='monitoring-screen-table-status' />,
    width: 350,
    sortable: false,
    cellCallback: ({ row: { status } }: any) => <span>{status}</span>,
  },
];

export default subscriptionMonitoringTableColumns;
