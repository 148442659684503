import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { NotificationType } from 'types/Notifications';
import { ClientData } from 'types/Client';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { clientsService } from 'common/api/clientsService';
import { ActionTypeKeys, actionCreators } from './clientsAllReducer';

export function* getClientsAllSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientsAll, payload);

    const prepareData = {
      data: data.data.map((item: ClientData) => ({
        ...item,
        key: item.id,
      })),
      totalCount: data.totalCount,
    };

    yield put(actionCreators.getClientsAllSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientsAllFailed());
  }
}

export function* clientsAllSaga() {
  yield* [takeEvery(ActionTypeKeys.GET_CLIENTS_ALL, getClientsAllSaga)];
}
