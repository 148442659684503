import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { EInvoiceStatus } from 'common/api/generated/PAIAdminApiClient';

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const TableWrapper = styled.div``;

export const FiltersWrapper = styled.div`
  position: sticky;
  top: 56px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
  padding-bottom: 10px;
`;

export const FiltersRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 12px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;
export const ButtonWrapper2 = styled.div`
  margin-right: 10px;
`;

export const FiltersColumnsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 1 50%;
`;

export const FiltersColumn = styled.div`
  min-width: 150px;
  margin-right: 24px;
  width: 100%;
`;

export const FiltersColumnWide = styled(FiltersColumn)``;

export const FiltersColumnDateRange = styled(FiltersColumn)`
  max-width: 210px;
`;

export const FiltersColumnName = styled(FiltersColumn)`
  max-width: 300px;
`;

interface StatusLabelType {
  status?: string;
}

const getStatusColor = (theme: any, status: string | undefined) => {
  if (!status) {
    return theme.fontPrimary;
  }
  if (status === EInvoiceStatus.Completed) {
    return theme.status.success;
  }
  if (status === EInvoiceStatus.Processing) {
    return theme.status.pending;
  }
  if (status === EInvoiceStatus.Failed) {
    return theme.status.error;
  }

  return theme.status.info;
};

export const StatusLabel = styled.span<StatusLabelType>`
  font-weight: 600;
  color: ${({ theme, status }) => getStatusColor(theme, status)};
`;

export const ActionIcon = styled.span`
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
`;

export const Link = styled.a`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-right: 10px;
`;

export const TableIdWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const TableIdText = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const TableNameWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  word-break: break-all;
`;

export const DownloadFileLink = styled.span`
  color: ${({ theme }) => theme.fontPrimary};
  display: inline-block;
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledLink = styled(RouterLink)`
  color: ${({ theme }) => theme.linkColor};
`;

export const DateWrap = styled.div`
  overflow: hidden;
  user-select: text;
`;
