import { Controller } from 'react-hook-form';
import { validateScriptId, validateStringValue, validateCronValue, validateRegexValue } from 'utils/validation';
import { FormDataTypes } from 'common/constants/Form';
import { Checkbox, CronScheduler, Input, Message } from 'common/components';

interface DynamicFormElementProps {
  fieldId: string;
  disabled: boolean;
  isRequired: boolean;
  isEncrypted: boolean;
  dataType: string;
  name: string;
  control: any;
  defaultValue: any;
  validationRegex: any;
  onChange?: (event: any) => void;
}

const DynamicFormElement = ({
  fieldId,
  disabled,
  isRequired,
  isEncrypted,
  dataType,
  name,
  control,
  defaultValue,
  validationRegex,
  onChange,
}: DynamicFormElementProps) => {
  switch (dataType) {
    case FormDataTypes.STRING:
      return (
        <Controller
          name={fieldId}
          control={control}
          rules={{
            required: isRequired,
            validate: (value: string) => validateStringValue(isRequired, value, name, validationRegex),
          }}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => {
            const messageId = fieldState.error?.message ? fieldState.error.message : `${name}-error`;

            return (
              <Input
                id={fieldId}
                isRequired={isRequired}
                disabled={disabled}
                isError={fieldState.invalid}
                isTypePassword={isEncrypted}
                type={isEncrypted ? 'password' : 'text'}
                label={name ? <Message id={name} /> : name}
                value={field.value ? field.value : ''}
                onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
                errorText={<Message id={messageId} />}
              />
            );
          }}
        />
      );
    case FormDataTypes.INTEGER:
      return (
        <Controller
          name={fieldId}
          control={control}
          rules={{
            required: isRequired,
            validate: validateScriptId,
          }}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => {
            const messageId = fieldState.error?.message ? fieldState.error.message : `${name}-error`;

            return (
              <Input
                id={fieldId}
                isRequired={isRequired}
                disabled={disabled}
                isError={fieldState.invalid}
                isTypePassword={isEncrypted}
                type={isEncrypted ? 'password' : 'number'}
                isInteger
                label={name ? <Message id={name} /> : name}
                value={field.value ? field.value : ''}
                onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
                errorText={<Message id={messageId} />}
              />
            );
          }}
        />
      );
    case FormDataTypes.CRON:
      return (
        <Controller
          name={fieldId}
          control={control}
          rules={{
            required: isRequired,
            validate: (value: string) => validateCronValue(isRequired, value, name, validationRegex),
          }}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => {
            const messageId = fieldState.error?.message ? fieldState.error.message : `${name}-error`;

            return (
              <CronScheduler
                id={fieldId}
                label={name ? <Message id={name} /> : name}
                disabled={disabled}
                onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
                value={field.value ? field.value : ''}
                isError={fieldState.invalid}
                errorText={<Message id={messageId} />}
              />
            );
          }}
        />
      );
    case FormDataTypes.REGEX:
      return (
        <Controller
          name={fieldId}
          control={control}
          rules={{
            required: isRequired,
            validate: (value: string) => validateRegexValue(isRequired, value, name, validationRegex),
          }}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => {
            const messageId = fieldState.error?.message ? fieldState.error.message : `${name}-error`;

            return (
              <Input
                id={fieldId}
                isRequired={isRequired}
                disabled={disabled}
                isError={fieldState.invalid}
                isTypePassword={isEncrypted}
                type={isEncrypted ? 'password' : 'text'}
                label={name ? <Message id={name} /> : name}
                value={field.value ? field.value : ''}
                onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
                errorText={<Message id={messageId} />}
              />
            );
          }}
        />
      );
    case FormDataTypes.BOOLEAN:
      return (
        <Controller
          name={fieldId}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Checkbox
              id={fieldId}
              label={name ? <Message id={name} /> : name}
              value={field.value}
              onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
              disabled={disabled}
              isRequired={isRequired}
            />
          )}
        />
      );

    default:
      return null;
  }
};

export default DynamicFormElement;
