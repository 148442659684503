import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/notificationReducer';
import { getNotificationsView } from './logic/notificationSelectors';
import NotificationManager from './NotificationManager';

const mapStateToProps = (state: RootState) => getNotificationsView(state);

const mapDispatchToProps = {
  removeNotification: actionCreators.removeNotification,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(NotificationManager);
