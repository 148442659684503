import { ReactNode } from 'react';
import { connect } from 'react-redux';
import BreadcrumbsContextProvider from 'common/components/Breadcrumbs/BreadcrumbsContext';
import SidebarContainer from 'components/Sidebar';
import { RootState } from 'store/rootReducer';
import HeaderContainer from 'components/Header';
import { Wrapper, Content, Main } from './styles';

interface LayoutProps {
  isHeaderExists?: boolean;
  isSidebarExists?: boolean;
  isSubNavExists?: boolean;
  isMainNavCollapsed: boolean;
  isSubNavCollapsed: boolean;
  children: ReactNode;
}

const Layout = ({
  isSubNavCollapsed,
  isMainNavCollapsed,
  isHeaderExists,
  isSidebarExists,
  isSubNavExists,
  children,
}: LayoutProps) => {
  return (
    <BreadcrumbsContextProvider>
      <Wrapper isHeaderExists={!!isHeaderExists}>
        {!!isHeaderExists && (
          <HeaderContainer
            isMainNavCollapsed={isMainNavCollapsed}
            isSubNavCollapsed={isSubNavCollapsed}
            isSubNavExists={!!isSubNavExists}
          />
        )}
        {isHeaderExists ? (
          <Main
            isMainNavCollapsed={isMainNavCollapsed}
            isSubNavCollapsed={isSubNavCollapsed}
            isSidebarExists={!!isSidebarExists}
            isSubNavExists={!!isSubNavExists}
          >
            {!!isSidebarExists && (
              <SidebarContainer
                isMainNavCollapsed={isMainNavCollapsed}
                isSubNavCollapsed={isSubNavCollapsed}
                isSubNavExists={!!isSubNavExists}
              />
            )}
            <Content>{children}</Content>
          </Main>
        ) : (
          children
        )}
      </Wrapper>
    </BreadcrumbsContextProvider>
  );
};

const mapStateToProps = ({ shared }: RootState) => ({
  isMainNavCollapsed: shared.isMainNavCollapsed,
  isSubNavCollapsed: shared.isSubNavCollapsed,
});

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, {})(Layout);
