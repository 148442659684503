import { useParams } from 'react-router-dom';
import { Message } from 'common/components/Message';
import {
  NavigationTitle,
  NavigationWrapper,
  NavList,
  NavItem,
  NavLinkStyled,
  NavLinkInner,
  IconContainer,
  NavItemText,
  NavItemLabel,
  CollapsedNavigationTitle,
} from './styles';

interface NavigationItem {
  path: ((id: number) => string) | string;
  name: string;
  title: string;
  messageId: string;
  icon: any; // todo check type of icon
}

interface NavigationProps {
  isMainNavCollapsed?: boolean;
  isDarkTheme?: boolean;
  isAccessPointPageHidden?: boolean;
  navTitle: string;
  navTitleSecondary?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  navLabels?: {};
  navItems: NavigationItem[];
}

const Navigation = ({
  isDarkTheme = false,
  isMainNavCollapsed = false,
  isAccessPointPageHidden = true,
  navTitle,
  navTitleSecondary,
  navLabels,
  navItems,
}: NavigationProps) => {
  const params: any = useParams();

  const getLink = (callback: ((id: number) => string) | string) =>
    typeof callback === 'function' ? callback(params.customerId) : callback;

  const renderNavItems = (items: NavigationItem[]) => {
    return (
      <NavList>
        {items.map((navLink: NavigationItem) => {
          const navItemLabel = () => {
            if (!navLabels) {
              return null;
            }
            //@ts-ignore
            if (navLabels[`${navLink.name}`] === 0) {
              return null;
            }
            //@ts-ignore
            return navLabels[`${navLink.name}`] && navLabels[`${navLink.name}`];
          };

          if (navLink.name === 'transferEInvoices' && isAccessPointPageHidden) {
            return null;
          }

          return (
            <NavItem key={navLink.name} title={navLink.title}>
              <NavLinkStyled
                darktheme={(!!isDarkTheme).toString()}
                collapsed={isMainNavCollapsed.toString()}
                to={getLink(navLink.path)}
              >
                <NavLinkInner isMainNavCollapsed={isMainNavCollapsed}>
                  <IconContainer isMainNavCollapsed={isMainNavCollapsed}>
                    <navLink.icon />
                  </IconContainer>
                  <NavItemText isMainNavCollapsed={isMainNavCollapsed}>
                    <Message id={navLink.messageId} />
                  </NavItemText>
                  {navItemLabel() && (
                    <NavItemLabel isMainNavCollapsed={isMainNavCollapsed}>{navItemLabel()}</NavItemLabel>
                  )}
                </NavLinkInner>
              </NavLinkStyled>
            </NavItem>
          );
        })}
      </NavList>
    );
  };

  return (
    <NavigationWrapper>
      <NavigationTitle isMainNavCollapsed={isMainNavCollapsed}>
        <span>
          <Message id={navTitle} />
        </span>
      </NavigationTitle>
      <CollapsedNavigationTitle isMainNavCollapsed={isMainNavCollapsed}>
        <span>
          <Message id={navTitleSecondary ? navTitleSecondary : navTitle} />
        </span>
      </CollapsedNavigationTitle>
      {renderNavItems(navItems)}
    </NavigationWrapper>
  );
};

export default Navigation;
