import { ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NotificationProps } from 'types/Notifications';
import NotificationToast from './Notification';
import { NotificationWrapper } from './styles';

interface NotificationManagerProps {
  notifications: NotificationProps[];
  children: ReactNode;
  removeNotification: (payload: string) => void;
}

const NotificationManager = ({ notifications, children, removeNotification }: NotificationManagerProps) => {
  return (
    // @ts-ignore
    <>
      <NotificationWrapper isEmpty={!notifications.length}>
        <TransitionGroup>
          {notifications.map((note: any) => {
            const key = note.id || new Date().getTime();
            return (
              <CSSTransition timeout={400} classNames='notification' key={key}>
                <NotificationToast removeNotification={removeNotification} key={key} {...note} />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </NotificationWrapper>
      {children}
    </>
  );
};

export default NotificationManager;
