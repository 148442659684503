import { Icon } from 'types/Icon';

export const InfoIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '18'} height={height || '18'} viewBox='0 0 18 18'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M9,0 C13.9706,0 18,4.029 18,9 C18,13.971 13.9706,18 9,18 C4.0294,18 0,13.971 0,9 C0,4.029 4.0294,0 9,0 Z M9,2 C5.134,2 2,5.134 2,9 C2,12.866 5.134,16 9,16 C12.866,16 16,12.866 16,9 C16,5.134 12.866,2 9,2 Z M9,7 C9.5523,7 10,7.448 10,8 L10,13 C10,13.552 9.5523,14 9,14 C8.4477,14 8,13.552 8,13 L8,8 C8,7.448 8.4477,7 9,7 Z M9,4 C9.5523,4 10,4.448 10,5 C10,5.552 9.5523,6 9,6 C8.4477,6 8,5.552 8,5 C8,4.448 8.4477,4 9,4 Z'
        id='path-InfoIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-1256.000000, -3241.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(1136.000000, 134.000000)'>
            <use fill={color || 'currentColor'} xlinkHref='#path-InfoIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
