import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { FormSelectOption } from 'types/Form';

import { APP_URLS } from 'common/constants';
import { useAuthenticatedAdminApiClient } from 'hooks/usePaiClient';
import { useRemoteApiCall } from 'hooks/useRemoteApiCall';
import { Button, Input, Message, Select, Spinner } from 'common/components';
import { ArrowLeftIcon } from 'common/components/Icons';
import { FormButtonsLeft, FormButtonsRight } from 'components/ConfigurationForm/styles';

import {
  ButtonBack,
  FormButtons,
  FormColumn,
  FormColumnWrapper,
  FormRow,
  FormWrapper,
  TabHeader,
  TabTitle,
} from './styles';

import { usePortalBreadcrumbsEffect } from './breadcrumbs';

const defaultValues = {
  identityProviderId: '',
  domain: '',
};

interface CreatePortalFormProps {
  identityProvider: FormSelectOption;
  domain: string;
}

type CustomerUrlParams = {
  customerId: string;
};

export const validateDomain = (value: string) => {
  if (!value?.replace(/\s/g, '')) {
    return 'error-customer-portal-domain-cant-be-empty';
  }

  if (!/^[a-z][a-z\\d-]{0,61}[a-z\\d]$/.test(value)) {
    return 'error-customer-portal-domain-only-alphanumeric';
  }

  return true;
};

const PortalCreate = () => {
  const { customerId } = useParams<CustomerUrlParams>();
  const { push } = useHistory();
  const paiClient = useAuthenticatedAdminApiClient();
  const [isFetching, callRemoteApi] = useRemoteApiCall();

  // breadcrumbs

  usePortalBreadcrumbsEffect('Create Customer Portal');

  // identity providers

  const [identityProviderSelectOptions, setIdentityProviderSelectOptions] = useState<FormSelectOption[]>([]);
  useEffect(() => {
    (async () => {
      const result = await callRemoteApi(() => paiClient?.customerPortalControllerGetConfiguredIdentityProviders());
      setIdentityProviderSelectOptions(
        result?.reverse().map((ip, index) => ({ id: index, value: ip.id ?? '', label: ip.displayName ?? '' })) || [],
      );
    })();
  }, [paiClient, callRemoteApi]);

  // form handling

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toCustomerPortal(Number(customerId)));
  }, [customerId, push]);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
  } = useForm({ mode: 'onChange' });

  const [isFormDisabled, setFormDisabled] = useState(false);

  const onSubmit = useCallback(
    async (data: CreatePortalFormProps) => {
      setFormDisabled(true);

      const result = await callRemoteApi(() =>
        paiClient?.customerPortalControllerCustomerPortalCreate(Number(customerId), {
          identityProviderId: data.identityProvider.value,
          domain: data.domain,
        }),
      );

      setFormDisabled(false);

      if (result) {
        push(APP_URLS.toCustomerPortal(Number(customerId)));
      }
    },
    [paiClient, customerId, callRemoteApi, push],
  );

  return (
    <>
      <TabHeader>
        <ButtonBack>
          <Button transparent onClick={onCancelHandler}>
            <ArrowLeftIcon />
          </Button>
        </ButtonBack>
        <TabTitle>
          <Message id={'create-customer-portal-tab'} />
        </TabTitle>
      </TabHeader>
      <FormWrapper onSubmit={(e: any) => e.preventDefault()}>
        {isFetching && <Spinner />}
        <FormColumnWrapper>
          <FormColumn>
            <FormRow>
              <Controller
                control={control}
                name='identityProvider'
                defaultValue={defaultValues.identityProviderId}
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      isRequired
                      disabled={isFormDisabled}
                      id='identityProvider'
                      options={identityProviderSelectOptions}
                      value={field.value}
                      label={<Message id={'create-customer-portal-identity-provider-label'} />}
                      onChange={field.onChange}
                      isError={fieldState.invalid}
                      errorText={<Message id={'create-customer-portal-identity-provider-error-label'} />}
                    />
                  );
                }}
              />
            </FormRow>
            <FormRow>
              <Controller
                control={control}
                name='domain'
                defaultValue={defaultValues.domain}
                rules={{
                  required: true,
                  minLength: 2,
                  maxLength: 100,
                  validate: validateDomain,
                }}
                render={({ field, fieldState }) => {
                  const messageId = fieldState.error?.message
                    ? fieldState.error.message
                    : 'customer-portal-domain-label-error';

                  return (
                    <Input
                      isRequired
                      disabled={isFormDisabled}
                      isError={fieldState.invalid}
                      id='domain'
                      type='text'
                      label={<Message id={'customer-portal-domain-label'} />}
                      value={field.value}
                      onChange={field.onChange}
                      errorText={<Message id={messageId} />}
                    />
                  );
                }}
              />
            </FormRow>
          </FormColumn>
        </FormColumnWrapper>
        <div>
          <FormButtons>
            <FormButtonsLeft></FormButtonsLeft>
            <FormButtonsRight>
              <Button transparent onClick={onCancelHandler}>
                <Message id={'cancel-button'} />
              </Button>
              <Button disabled={!isDirty || !isValid} primary onClick={handleSubmit(onSubmit)}>
                <Message id={'save-button'} />
              </Button>
            </FormButtonsRight>
          </FormButtons>
        </div>
      </FormWrapper>
    </>
  );
};

export default PortalCreate;
