import styled from 'styled-components';

export const Wrapper = styled.div``;

interface FlipToggleContainerProps {
  isDisabled?: boolean;
}

export const FlipToggleContainer = styled.label<FlipToggleContainerProps>`
  width: 80px;
  height: 26px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  box-shadow: 0 2px 2px ${({ theme }) => theme.boxShadow};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.65' : '1')};
`;

export const FlipToggleLabels = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.4s ease-in-out;
  overflow: hidden;

  &:after {
    content: attr(data-off);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.buttons.dangerTransparent};
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
  }

  &:before {
    content: attr(data-on);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    top: 0;
    left: calc(80px * -1);
    height: 100%;
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.buttons.successTransparent};
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${FlipToggleLabels}:after {
    transform: translateX(80px);
  }

  &:checked + ${FlipToggleLabels}:before {
    transform: translateX(80px);
  }
`;
