import { FC } from 'react';
import { Route, Switch, Redirect, RouteProps } from 'react-router';
import { useIsAuthenticated } from '@azure/msal-react';
import { APP_URLS } from 'common/constants';
import { Layout } from 'components/Layout';
import Login from 'screens/Login';
import { Integrations } from 'screens/Integrations';
import Customers from 'screens/Customers';
import CustomerContainer from 'screens/Customer';
import CustomerCreateContainer from 'screens/CustomerCreate';
import CustomerClientsContainer from 'screens/CustomerClients';
import ClientContainer from 'screens/Client';
import ClientCreateContainer from 'screens/ClientCreate';
import ClientsAllContainer from 'screens/ClientsAll';
import SyncMasterDataContainer from 'screens/SyncMasterData';
import TransferHistoricalDataContainer from 'screens/TransferHistoricalData';
import CustomerOperationsContainer from 'screens/Customer/Operations';
import CustomerIntegrationCreateContainer from 'screens/Customer/Integrations/IntegrationCreate';
import CustomerIntegrationEditContainer from 'screens/Customer/Integrations/IntegrationEdit';
import ClientIntegrationCreateContainer from 'screens/Client/Integrations/IntegrationCreate';
import ClientIntegrationEditContainer from 'screens/Client/Integrations/IntegrationEdit';
import ClientTransformationProfileContainer from 'screens/Client/Transformations/TransformationEdit';
import ClientTransformationCreateContainer from 'screens/Client/Transformations/TransformationCreate';
import IntegrationsDefaultSettingsContainer from 'screens/IntegrationsDefaultSettings';
import CustomerTransferEInvoicesContainer from 'screens/TransferEInvoices/TransferEInvoicesContainer';
import ReleaseNotes from 'screens/ReleaseNotes';
import OperationsAllContainer from 'screens/OperationsAll/OperationsAllContainer';
import TransferEInvoicesAllContainer from 'screens/TransferEInvoicesAll/TransferEInvoicesAllContainer';
import MonitoringContainer from 'screens/Monitoring/MonitoringContainer';

const NoMatch = () => <div>Page Not Found</div>;

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          // @ts-ignore
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

const Router = () => {
  return (
    <Switch>
      <PrivateRoute
        exact
        path={APP_URLS.integrations}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <Integrations />
          </Layout>
        )}
      />
      <PrivateRoute
        exact
        path={APP_URLS.integrationsDefault}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <IntegrationsDefaultSettingsContainer />
          </Layout>
        )}
      />
      <Route
        path={APP_URLS.transferEInvoices}
        render={() => (
          <Switch>
            <PrivateRoute
              path={APP_URLS.transferEInvoices}
              component={() => (
                <Layout isSidebarExists isHeaderExists>
                  <TransferEInvoicesAllContainer />
                </Layout>
              )}
            />
          </Switch>
        )}
      />
      <PrivateRoute
        exact
        path={APP_URLS.operationStatus}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <OperationsAllContainer />
          </Layout>
        )}
      />

      <PrivateRoute
        path={APP_URLS.customerTransferHistoricalData}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <TransferHistoricalDataContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.customerSyncMasterData}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <SyncMasterDataContainer />
          </Layout>
        )}
      />

      <PrivateRoute
        path={APP_URLS.customerOperations}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <CustomerOperationsContainer />
          </Layout>
        )}
      />
      <Route
        path={APP_URLS.customerTransferEInvoices}
        render={() => (
          <Switch>
            <PrivateRoute
              path={APP_URLS.customerTransferEInvoices}
              component={() => (
                <Layout isSidebarExists isSubNavExists isHeaderExists>
                  <CustomerTransferEInvoicesContainer />
                </Layout>
              )}
            />
          </Switch>
        )}
      />
      <PrivateRoute
        path={APP_URLS.clientIntegrationProfile}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <ClientIntegrationEditContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.clientIntegrationCreate}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <ClientIntegrationCreateContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.clientTransformationProfile}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <ClientTransformationProfileContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.clientTransformationCreate}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <ClientTransformationCreateContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.clientCreate}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <ClientCreateContainer />
          </Layout>
        )}
      />
      <Redirect exact from={APP_URLS.client} to={APP_URLS.clientProfile} />
      <Route
        path={APP_URLS.client}
        render={() => (
          <Switch>
            <PrivateRoute
              path={[
                APP_URLS.clientProfile,
                APP_URLS.clientIntegrations,
                APP_URLS.clientTransformations,
                APP_URLS.clientApproverReferences,
                APP_URLS.clientOperations,
              ]}
              component={() => (
                <Layout isSidebarExists isSubNavExists isHeaderExists>
                  <ClientContainer />
                </Layout>
              )}
            />
          </Switch>
        )}
      />
      <PrivateRoute
        path={APP_URLS.customerClients}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <CustomerClientsContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.customerIntegrationProfile}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <CustomerIntegrationEditContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.createCustomerIntegration}
        component={() => (
          <Layout isSidebarExists isSubNavExists isHeaderExists>
            <CustomerIntegrationCreateContainer />
          </Layout>
        )}
      />
      <PrivateRoute
        path={APP_URLS.customerCreate}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <CustomerCreateContainer />
          </Layout>
        )}
      />
      <Redirect exact from={APP_URLS.customer} to={APP_URLS.customerData} />
      <Redirect exact from={APP_URLS.customerProfile} to={APP_URLS.customerData} />
      <Route
        path={APP_URLS.customer}
        render={() => (
          <Switch>
            <PrivateRoute
              path={[APP_URLS.customerData, APP_URLS.customerIntegrations, APP_URLS.customerPortal]}
              component={() => (
                <Layout isSidebarExists isSubNavExists isHeaderExists>
                  <CustomerContainer />
                </Layout>
              )}
            />
          </Switch>
        )}
      />
      <Redirect exact from={APP_URLS.monitoring} to={APP_URLS.monitoringInvoice} />
      <Route
        path={APP_URLS.monitoring}
        render={() => (
          <Switch>
            <PrivateRoute
              path={[APP_URLS.monitoringInvoice, APP_URLS.monitoringSubscriptions]}
              component={() => (
                <Layout isSidebarExists isHeaderExists>
                  <MonitoringContainer />
                </Layout>
              )}
            />
          </Switch>
        )}
      />
      <PrivateRoute
        path={APP_URLS.customers}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <Customers />
          </Layout>
        )}
      />

      <PrivateRoute
        exact
        path={APP_URLS.clients}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <ClientsAllContainer />
          </Layout>
        )}
      />

      <PrivateRoute
        path={APP_URLS.releaseNotes}
        component={() => (
          <Layout isSidebarExists isHeaderExists>
            <ReleaseNotes />
          </Layout>
        )}
      />

      <Redirect from={APP_URLS.login} to={APP_URLS.customers} />
      <Redirect from='/' to={APP_URLS.customers} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export const UnAuthenticatedRouter = () => {
  return (
    <Switch>
      <Route
        path={APP_URLS.login}
        component={() => (
          <Layout>
            <Login />
          </Layout>
        )}
      />
      <Redirect from='/' to={APP_URLS.login} />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default Router;
