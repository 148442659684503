import { Navigation } from 'common/components/Navigation';
import { SUB_NAVIGATION_ITEMS } from './constants';
import { SubNavigationWrapper } from './styles';

interface SubNavigationProps {
  isMainNavCollapsed?: boolean;
  isAccessPointPageHidden: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  subNavGeneralLabels: {};
}

const SubNavigation = ({
  isMainNavCollapsed,
  subNavGeneralLabels,
  isAccessPointPageHidden = true,
}: SubNavigationProps) => {
  return (
    <SubNavigationWrapper>
      <Navigation
        isMainNavCollapsed={isMainNavCollapsed}
        navTitle={'sub-nav-explore-customer-title'}
        navTitleSecondary={'sub-nav-customer-title'}
        navItems={SUB_NAVIGATION_ITEMS}
        navLabels={subNavGeneralLabels}
        isAccessPointPageHidden={isAccessPointPageHidden}
      />
    </SubNavigationWrapper>
  );
};

export default SubNavigation;
