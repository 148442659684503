import { ButtonLink as ButtonLinkInterface } from 'types/Button';
import { Btn, BtnIconLeft, BtnIconRight, ButtonLeft } from './styles';

const ButtonLink = ({ to, rel, target, disabled, leftIcon, rightIcon, children, ...rest }: ButtonLinkInterface) => {
  const renderLeftIcon = () => <BtnIconLeft>{leftIcon}</BtnIconLeft>;
  const renderRightIcon = () => <BtnIconRight>{rightIcon}</BtnIconRight>;

  const renderButtonLayout = () =>
    rightIcon ? (
      <>
        <ButtonLeft>
          {leftIcon && renderLeftIcon()}
          {children}
        </ButtonLeft>
        {rightIcon && renderRightIcon()}
      </>
    ) : (
      <>
        {leftIcon && renderLeftIcon()}
        {children}
      </>
    );

  return (
    <Btn to={to} rel={rel} target={target} disabled={disabled} {...rest}>
      {renderButtonLayout()}
    </Btn>
  );
};

export default ButtonLink;
