import { TransferStatus, transferStatusNamesMapping } from 'types/TransferHistoricalData';

export const transferDataStatusFilterOptions = [
  {
    id: 0,
    value: TransferStatus.All,
    label: 'All',
  },
  {
    id: 1,
    value: TransferStatus.NONE,
    label: transferStatusNamesMapping[TransferStatus.NONE],
  },
  {
    id: 2,
    value: TransferStatus.START,
    label: transferStatusNamesMapping[TransferStatus.START],
  },
  {
    id: 3,
    value: TransferStatus.IN_PROGRESS,
    label: transferStatusNamesMapping[TransferStatus.IN_PROGRESS],
  },
  {
    id: 4,
    value: TransferStatus.PARTIAL_SUCCESS,
    label: transferStatusNamesMapping[TransferStatus.PARTIAL_SUCCESS],
  },
  {
    id: 5,
    value: TransferStatus.SUCCESS,
    label: transferStatusNamesMapping[TransferStatus.SUCCESS],
  },
  {
    id: 6,
    value: TransferStatus.FAILURE,
    label: transferStatusNamesMapping[TransferStatus.FAILURE],
  },
];
