import styled from 'styled-components';

interface ModalWrapperType {
  isVisible: boolean;
  showOverlay?: boolean;
}

export const ModalWrapper = styled.div<ModalWrapperType>`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: ${({ showOverlay }) => (showOverlay ? 'hidden' : 'auto')};
  -webkit-overflow-scrolling: touch;
  padding: 0;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

interface ModalOverlayType {
  isVisible: boolean;
}

export const ModalOverlay = styled.div<ModalOverlayType>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: ${({ theme }) => theme.backgroundOverlay};
  overflow: auto;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  min-width: 320px;

  &:after {
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: '';
    vertical-align: middle;
  }
`;

interface ModalContainerType {
  size: 'standart' | 'medium' | 'large';
}

const getModalSize = (size: 'standart' | 'medium' | 'large') => {
  if (size === 'medium') {
    return '600px';
  }

  if (size === 'large') {
    return '800px';
  }

  return '484px';
};

export const ModalContainer = styled.div<ModalContainerType>`
  position: relative;
  width: ${({ size }) => getModalSize(size)};
  background: ${({ theme }) => theme.backgroundPrimary};
`;

interface ModalScrollBoxType {
  withScroll?: boolean;
}

export const ModalScrollBox = styled.div<ModalScrollBoxType>`
  max-height: calc(100vh - 255px);
  overflow: ${({ withScroll }) => (withScroll ? 'auto' : 'visible')};
`;

export const ModalScrollBoxInner = styled.div`
  // height: 100%;
  // display: flex;
  // flex-direction: column;
`;

export const ModalHeader = styled.header`
  position: relative;
  min-height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.borders.grey};
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 8px 46px;
  text-align: center;
`;

export const ModalHeaderButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const ModalHeaderTitle = styled.h2`
  font-size: 26px;
  line-height: 40px;
  margin: 0;
  font-weight: 400;
`;

interface ModalBodyType {
  minHeight: string;
}

export const ModalBody = styled.div<ModalBodyType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 16px;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '150px')};
`;

interface ModalBodyWrapperType {
  verticalAlignment: 'center' | 'top';
}

export const ModalBodyWrapper = styled.div<ModalBodyWrapperType>`
  flex: ${({ verticalAlignment }) => (verticalAlignment === 'top' ? '1' : 'auto')};
`;

export const ModalFooter = styled.footer`
  padding: 20px;
  box-shadow: 0 2px 10px ${({ theme }) => theme.boxShadow};
`;
