import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators as customerActionCreators } from 'screens/Customer/logic/customerReducer';
import { actionCreators } from './logic/clientCreateReducer';
import ClientCreate from './ClientCreate';
import { getClientCreateView } from './logic/clientCreateSelectors';

const mapStateToProps = ({ customer, clientCreate }: RootState) => getClientCreateView(customer, clientCreate);

const mapDispatchToProps = {
  getCustomer: customerActionCreators.getCustomer,
  createClient: actionCreators.createClient,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(ClientCreate);
