import { Icon } from 'types/Icon';

export const HamburgerIcon = ({ color, width, height }: Icon) => (
  <svg width={width || '14'} height={height || '15'} viewBox='0 0 14 15'>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M13,12 C13.5522847,12 14,12.4477153 14,13 L14,14 C14,14.5522847 13.5522847,15 13,15 L1,15 C0.44771525,15 6.76353751e-17,14.5522847 0,14 L0,13 C-6.76353751e-17,12.4477153 0.44771525,12 1,12 L13,12 Z M13,6 C13.5522847,6 14,6.44771525 14,7 L14,8 C14,8.55228475 13.5522847,9 13,9 L1,9 C0.44771525,9 6.76353751e-17,8.55228475 0,8 L0,7 C-6.76353751e-17,6.44771525 0.44771525,6 1,6 L13,6 Z M13,0 C13.5522847,-1.01453063e-16 14,0.44771525 14,1 L14,2 C14,2.55228475 13.5522847,3 13,3 L1,3 C0.44771525,3 6.76353751e-17,2.55228475 0,2 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L13,0 Z'
        id='path-HamburgerIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-997.000000, -3243.000000)'>
        <g transform='translate(120.000000, 3107.000000)'>
          <g transform='translate(877.000000, 136.000000)'>
            <use fill={color || 'currentColor'} fillRule='nonzero' xlinkHref='#path-HamburgerIcon-1' />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
