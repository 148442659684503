/* eslint-disable prettier/prettier */

import { Button, Message, Modal } from 'common/components';
import * as React from 'react';
import { AlertModalFooter, AlertModalContent } from './styles';

type AlertModalProps = {
  isVisible: boolean;
  title: React.ReactNode;
  content?: React.ReactNode;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const AlertModal = ({
  isVisible,
  title,
  content,
  loading,
  onCancel,
  onConfirm,
}: AlertModalProps) => {
  // rendering of the content
  const modalContent = (
    <AlertModalContent>
      {content}
    </AlertModalContent>
  );

  // rendering of the footer
  const modalFooter = (
    <AlertModalFooter>
      <Button transparent disabled={false} onClick={onCancel}>
        <Message id='cancel-button' />
      </Button>
      <Button primary disabled={loading} onClick={onConfirm}>
        <Message id='confirm-button' />
      </Button>
    </AlertModalFooter>
  );

  return (
    <>
      {isVisible && (
        <Modal
          withScroll
          hideOnOverlayClicked={false}
          closeOnEsc={false}
          isVisible={isVisible}
          title={title}
          footer={modalFooter}
          onCloseClicked={() => onCancel()}
        >
          {modalContent}
        </Modal>
      )}
    </>
  );
};

export default AlertModal;
