import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { customersService } from 'common/api/customersService';
import { OperationData } from 'types/Operations';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { defaultFilterOptions } from 'common/constants';
import { operationsService } from 'common/api/operationsService';
import { clientsService } from 'common/api/clientsService';
import { actionCreators, ActionTypeKeys } from './operationsAllReducer';

export function* getOperationsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(operationsService.getOperations, payload);
    const prepareData = {
      data: data.data.map((item: OperationData, index: number) => ({ ...item, id: `${index + 1}_${item.traceId}` })),
      totalCount: data.totalCount,
    };
    yield put(actionCreators.getOperationsSuccess(prepareData));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getOperationsFailed());
  }
}

export function* getCustomersOptionsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(customersService.getCustomersBasic, payload);

    const prepareData = data.map((item: any) => ({
      ...item,
      value: item.name,
      label: item.name,
    }));

    yield put(actionCreators.getCustomersOptionsSuccess([operationsService, ...prepareData]));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getCustomersOptionsFailed());
  }
}

export function* getClientsOptionsSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(clientsService.getClientsBasic, payload);

    const prepareData = data.data.map((item: any) => ({
      ...item,
      value: item.name,
      label: item.name,
    }));

    yield put(actionCreators.getClientsOptionsSuccess([defaultFilterOptions, ...prepareData]));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || errorLocal,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.getClientsOptionsFailed());
  }
}

export function* operationsAllSaga() {
  yield* [
    takeEvery(ActionTypeKeys.GET_OPERATIONS, getOperationsSaga),
    takeEvery(ActionTypeKeys.GET_CUSTOMERS_OPTIONS, getCustomersOptionsSaga),
    takeEvery(ActionTypeKeys.GET_CLIENTS_OPTIONS, getClientsOptionsSaga),
  ];
}
