import { useEffect } from 'react';
import { UploadOneFileDropzone, Message } from 'common/components';
import { ImportType } from 'types/Import';
import { Content, Title, Note, NoteMark, List, ListItem, UploadWrapper } from './styles';

interface ImportApproverReferencesProps {
  onFileUpload: (payload: File) => void;
  setImportType: (payload: ImportType | null) => void;
  resetUpload: () => void;
}

const ImportApproverReferences = ({ onFileUpload, setImportType, resetUpload }: ImportApproverReferencesProps) => {
  useEffect(() => {
    setImportType(ImportType.IMPORT_APPROVER_REFERENCES);
    return () => resetUpload();
  }, [setImportType, resetUpload]);

  return (
    <Content>
      <Title>Import Approver References</Title>
      <p>
        <Message id='clients-screen-import-csv-file-should-contain-data-with-following-headers' />
      </p>
      <List>
        <ListItem>
          <strong>InputReference</strong>
        </ListItem>
        <ListItem>
          <strong>DimensionId</strong>
        </ListItem>
        <ListItem>
          <strong>DimensionName</strong>
        </ListItem>
      </List>
      <Note>
        <p>
          <Message id='clients-screen-import-any-other-columns-will-be-ignored' /> ( <NoteMark>,</NoteMark> ),{' '}
          <Message id='clients-screen-import-so-save-from-spreadsheet-as-csv' />
        </p>
        <p>
          <Message id='clients-screen-import-such-programs-automatically-wrap-any-fields-containing-commas' />
        </p>
      </Note>
      <UploadWrapper>
        <UploadOneFileDropzone onUpload={onFileUpload} />
      </UploadWrapper>
    </Content>
  );
};

export default ImportApproverReferences;
