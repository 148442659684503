import { components } from 'react-select';
import { ArrowDownIcon, ArrowUpIcon } from 'common/components/Icons';
import { Message } from 'common/components';
import { MultiValueWrapper } from './styles';

export const Control = (props: any) => (
  <components.Control {...props} isError={props.selectProps.isError} isDisabled={props.selectProps.isDisabled} />
);

export const SingleValue = (props: any) => (
  <span>
    <components.SingleValue {...props}>
      {props.data.translation ? <Message id={props.data.translation} /> : props.children}
    </components.SingleValue>
  </span>
);

export const MultiValueContainer = (props: any) => (
  <MultiValueWrapper title={props.data.name}>
    <components.MultiValueContainer {...props} />
  </MultiValueWrapper>
);

export const MultiValueLabel = (props: any) => (
  <components.MultiValueLabel {...props}>
    {props.data.translation ? <Message id={props.data.translation} /> : props.children}
  </components.MultiValueLabel>
);

export const DropdownIndicator = (props: any) =>
  components.DropdownIndicator && (
    <span style={{ pointerEvents: 'none' }}>
      <components.DropdownIndicator {...props} isError={props.selectProps.isError}>
        {props.selectProps.menuIsOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </components.DropdownIndicator>
    </span>
  );

export const ClearIndicator = (props: any) => (
  <span>
    <components.ClearIndicator {...props} />
  </span>
);

// eslint-disable-next-line
export const Option = (props: any) => (
  <components.Option {...props}>
    {props.data.translation ? <Message id={props.data.translation} /> : props.children}
  </components.Option>
);
