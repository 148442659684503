import { useState, ReactNode, useEffect } from 'react';
import { TickIcon } from 'common/components/Icons';
import { CheckboxContainer, LabelWrapper, LabelText, HiddenCheckbox, StyledCheckbox, CheckboxHolder } from './styles';

interface CheckboxProps {
  id?: string;
  value?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  isRequired?: boolean;
  textPosition?: 'left' | 'right';
  label?: ReactNode;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
}

const Checkbox = ({
  id,
  value,
  disabled,
  indeterminate,
  textPosition = 'right',
  label,
  onChange,
  isRequired,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value ? value : false);
  }, [value]);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
    onChange && onChange(event.target.checked);
  };

  return (
    <CheckboxContainer>
      <LabelWrapper htmlFor={id} disabled={disabled} textPosition={textPosition}>
        <CheckboxHolder>
          <HiddenCheckbox
            id={id}
            type='checkbox'
            disabled={disabled}
            indeterminate={indeterminate}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <StyledCheckbox disabled={disabled} indeterminate={indeterminate} textPosition={textPosition}>
            {isChecked ? <TickIcon /> : null}
          </StyledCheckbox>
        </CheckboxHolder>
        <LabelText disabled={disabled}>{label}</LabelText>
        {isRequired && <span>*</span>}
      </LabelWrapper>
    </CheckboxContainer>
  );
};

export default Checkbox;
