import styled from 'styled-components';
import { TableAlignment } from '../types';
import { getTableAlignment } from '../utils';

export const Tbody = styled.tbody``;

interface TdProps {
  alignment?: TableAlignment;
  highlighted?: any;
  nestedTable?: boolean;
  collapse?: boolean;
}

const getTableCellBackground = (theme: any, highlighted: any, nestedTable?: boolean, collapse?: boolean) => {
  if (!collapse) {
    return theme.table.highlight;
  }

  if (nestedTable) {
    return highlighted ? theme.backgroundPrimary : theme.table.highlight;
  }

  return highlighted ? theme.table.highlight : theme.backgroundPrimary;
};

export const Td = styled.td<TdProps>`
  padding: 10px;
  font-size: 13px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme, highlighted, nestedTable, collapse }) =>
    getTableCellBackground(theme, highlighted, nestedTable, collapse)};
  vertical-align: middle;
  text-align: ${({ alignment }) => getTableAlignment(alignment)};
`;

export const TdCollapse = styled(Td)`
  padding: 0;
`;

export const TdEmpty = styled(Td)`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.fontSecondary};
  font-weight: 600;
  background-color: ${({ theme }) => theme.backgroundPrimary};
`;

interface TrProps {
  collapse: boolean;
}

export const Tr = styled.tr<TrProps>`
  &:hover > ${Td} {
    background-color: ${({ theme }) => theme.backgroundSecondary};
  }
`;

export const TrCollapse = styled(Tr)`
  display: ${({ collapse }) => (collapse ? 'none' : 'table-row')};
`;

export const TableRowOpener = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.borders.grey};
  background: ${({ theme }) => theme.backgroundPrimary};
  border-radius: 3px;
  overflow: hidden;

  &:hover {
    color: ${({ theme }) => theme.buttons.white};
    background-color: ${({ theme }) => theme.buttons.primary};
    border: 1px solid ${({ theme }) => theme.borders.primary};
  }
`;

export const TableCollapseContent = styled.div`
  padding: 0;
  border-top: 2px solid ${({ theme }) => theme.fontPrimary};
  border-bottom: 2px solid ${({ theme }) => theme.fontPrimary};
  background: ${({ theme }) => theme.table.highlight};
`;
