import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { APP_URLS, ErrorCodes } from 'common/constants';
import { Message, Spinner, Button, Alert, FlipToggle } from 'common/components';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { ArrowLeftIcon } from 'common/components/Icons';
import {
  IntegrationSchema,
  IntegrationsLevel,
  IntegrationsStatusAction,
  IntegrationsSystems,
  CustomerConfigurationSchemaRequest,
  IntegrationData,
  IntegrationRequest,
  UpdateIntegrationRequest,
  TestConnectionRequest,
} from 'types/Integrations';
import {
  CustomerByIdRequestData,
  CustomerData,
  CustomerIntegrationStatusRequest,
  CustomerStatusRequestData,
} from 'types/Customer';
import StatusSelector from 'components/StatusSelector';
import IntegrationForm from 'components/IntegrationForm';
import { getAllFieldsFromSchema } from 'components/ConfigurationForm/utils';
import { prepareRequestData } from 'components/IntegrationForm/utils';
import { mapSystemName } from 'utils';
import { useAccessToken } from 'hooks';
import CustomerTabsList from '../../CustomerTabsList';
import getCustomerIntegrationProfileBreadcrumbs from './getCustomerIntegrationEditBreadcrumbs';
import {
  Head,
  Title,
  TitleMark,
  Content,
  TabsWrapper,
  TabContent,
  TabContentInner,
  TabHeader,
  TabTitle,
  ButtonBack,
  HeadActions,
  TabHeaderLeft,
  TabHeaderRight,
} from './styles';

type CustomerUrlParams = {
  customerId: string;
  clientId: string;
  integrationId: string;
};

interface IntegrationEditProps {
  isFetching: boolean;
  isTestConnectionFetching: boolean;
  errorCode: number | null;
  customer: CustomerData;
  integration: IntegrationData;
  integrationSchema: IntegrationSchema;
  getCustomer: (payload: CustomerByIdRequestData) => void;
  setStatus: (payload: CustomerStatusRequestData) => void;
  resetPartial: () => void;
  integrationReset: () => void;
  getCustomerIntegration: (payload: IntegrationRequest) => void;
  getCustomerConfigurationSchema: (payload: CustomerConfigurationSchemaRequest) => void;
  updateCustomerIntegration: (payload: UpdateIntegrationRequest) => void;
  updateCustomerIntegrationStatus: (payload: CustomerIntegrationStatusRequest) => void;
  getCustomerTestConnection: (payload: TestConnectionRequest) => void;
}

interface LocationState {
  fromIntegrationCreatePage: boolean;
}

const IntegrationEdit = ({
  errorCode,
  isFetching,
  isTestConnectionFetching,
  customer,
  getCustomer,
  integration,
  integrationSchema,
  setStatus,
  integrationReset,
  getCustomerIntegration,
  getCustomerConfigurationSchema,
  updateCustomerIntegration,
  updateCustomerIntegrationStatus,
  resetPartial,
  getCustomerTestConnection,
}: IntegrationEditProps) => {
  const accessToken = useAccessToken();
  const { pathname, state } = useLocation<LocationState>();
  const isIntegrationsDefaultPage = pathname.toLowerCase().includes('integrations-default');
  const { push } = useHistory();
  const { customerId, clientId, integrationId } = useParams<CustomerUrlParams>();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const isProfilePage = /profile+$/.test(pathname);

  useEffect(() => {
    if (accessToken && state?.fromIntegrationCreatePage && integration.id && integration.showTestConnectionButton) {
      getCustomerTestConnection({
        accessToken,
        integrationId: integration.id,
      });
      window.history.replaceState({}, document.title);
    }
  }, [accessToken, state, integration.id, integration.showTestConnectionButton, getCustomerTestConnection]);

  useEffect(() => {
    setBreadcrumbs(
      getCustomerIntegrationProfileBreadcrumbs(Number(customerId), customer.name, integration?.systemName),
    );
    return () => setBreadcrumbs(null);
  }, [customerId, customer.name, integration?.systemName, setBreadcrumbs]);

  useEffect(() => {
    if (!customer.name && accessToken) {
      getCustomer({
        accessToken,
        customerId: Number(customerId),
      });
    }
  }, [accessToken, customerId, customer.name, getCustomer]);

  useEffect(() => {
    if (accessToken && integrationId) {
      getCustomerIntegration({
        accessToken,
        integrationId,
      });
    }
  }, [accessToken, integrationId, getCustomerIntegration]);

  useEffect(() => {
    if (accessToken && integration.systemId) {
      getCustomerConfigurationSchema({
        accessToken,
        customerId: Number(customerId),
        isCreatePage: false,
        systemId: integration.systemId,
      });
    }
  }, [accessToken, integration.systemId, customerId, getCustomerConfigurationSchema]);

  useEffect(() => {
    return () => integrationReset();
  }, [integrationReset]);

  const onCancelHandler = useCallback(() => {
    push(APP_URLS.toCustomerIntegrations(Number(customerId)));
  }, [customerId, push]);

  const onSubmitButtonClickHandler = useCallback(
    ({ data }: any) => {
      const allFields = getAllFieldsFromSchema(integrationSchema, integration.settings);
      const preparePayload = prepareRequestData({
        integration,
        isIntegrationsDefaultPage,
        formData: data,
        fieldsArray: allFields,
        systemId: integration.systemId,
      });

      if (integration) {
        updateCustomerIntegration({
          accessToken,
          integrationId: integrationId,
          requestData: preparePayload,
          withTestConnection: integration.showTestConnectionButton,
        });
      }
    },
    [accessToken, isIntegrationsDefaultPage, integration, integrationId, integrationSchema, updateCustomerIntegration],
  );

  const onFlipToggleChangeHandler = (event: any) => {
    if (integration) {
      updateCustomerIntegrationStatus({
        accessToken,
        isProfilePage,
        customerId: Number(customerId),
        integrationId: integration.id,
        system: integration.systemName,
        action: event.target.checked ? IntegrationsStatusAction.ACTIVATE : IntegrationsStatusAction.DEACTIVATE,
      });
    }
  };

  const onTestConnectionHandler = useCallback(() => {
    getCustomerTestConnection({
      accessToken,
      integrationId: integration.id,
    });
  }, [accessToken, integration.id, getCustomerTestConnection]);

  const renderForm = useMemo(() => {
    if (!integration?.systemName) {
      return null;
    }

    return (
      <IntegrationForm
        isFetching={isFetching}
        errorCode={errorCode}
        integrationName={integration.systemName}
        integration={integration}
        integrationSchema={integrationSchema}
        onSubmit={onSubmitButtonClickHandler}
        onCancel={onCancelHandler}
        resetForm={resetPartial}
        testConnection={onTestConnectionHandler}
        level={clientId ? IntegrationsLevel.CLIENT : IntegrationsLevel.CUSTOMER}
      />
    );
  }, [
    isFetching,
    clientId,
    errorCode,
    integrationSchema,
    integration,
    onSubmitButtonClickHandler,
    onCancelHandler,
    onTestConnectionHandler,
    resetPartial,
  ]);

  const renderMissingSchemaAlert = useMemo(() => {
    if (!integration || !integration?.systemName) {
      return null;
    }

    if (errorCode === ErrorCodes.NOT_FOUND_SCHEMA) {
      return (
        <Alert
          title={
            <Message
              id='schema-for-customer-not-found-title'
              values={{
                variable: mapSystemName(integration?.systemName),
              }}
            />
          }
        >
          <p>
            <Message
              id='please-create-schema-before-create-integration'
              values={{
                variable: mapSystemName(integration?.systemName),
              }}
            />
          </p>
        </Alert>
      );
    }
    return null;
  }, [integration, errorCode]);

  const renderRequiredLevelsAlert = useMemo(() => {
    const isRequiredLevelsExists = Array.isArray(integrationSchema.requiredParentLevels)
      ? integrationSchema.requiredParentLevels.length > 0
      : false;

    if (!isRequiredLevelsExists) {
      return null;
    }

    const levels = integrationSchema.requiredParentLevels.join(', ');

    return (
      <Alert title={<Message id='alert-pay-attention' />}>
        <p>
          <Message
            id='please-be-aware-that-settings-for-levels-are-configured'
            values={{
              variable: levels,
            }}
          />
        </p>
      </Alert>
    );
  }, [integrationSchema.requiredParentLevels]);

  return (
    <>
      <Head>
        <Title>
          {customer?.name} <TitleMark>id#{customer?.id}</TitleMark>
        </Title>
        <HeadActions>
          {customer.status && (
            <StatusSelector isDisabled={isFetching} currentStatus={customer.status} onStatusSelect={setStatus} />
          )}
        </HeadActions>
      </Head>
      <Content>
        <TabsWrapper>
          <CustomerTabsList customerId={Number(customerId)} />
          <TabContent>
            <TabContentInner>
              <TabHeader>
                <TabHeaderLeft>
                  <ButtonBack>
                    <Button transparent onClick={onCancelHandler}>
                      <ArrowLeftIcon />
                    </Button>
                  </ButtonBack>
                  <TabTitle>
                    {integration ? mapSystemName(integration?.systemName) : ''}{' '}
                    <TitleMark>{integration?.id ? `id#${integration?.id}` : null}</TitleMark>
                  </TabTitle>
                </TabHeaderLeft>
                <TabHeaderRight>
                  {integration && (
                    <FlipToggle
                      dataOn='Active'
                      dataOff='Inactive'
                      isChecked={integration.isActive}
                      isDisabled={integration.systemName === IntegrationsSystems.VICAI || isFetching}
                      onChange={onFlipToggleChangeHandler}
                    />
                  )}
                </TabHeaderRight>
              </TabHeader>
              {isFetching || isTestConnectionFetching ? <Spinner /> : null}
              {renderMissingSchemaAlert}
              {renderRequiredLevelsAlert}
              {renderForm}
            </TabContentInner>
          </TabContent>
        </TabsWrapper>
      </Content>
    </>
  );
};

export default IntegrationEdit;
