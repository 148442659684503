import { ColumnItem, TableSortOrder } from '../types';
import HeadCell from './HeadCell';
import { Thead, ThOpener } from './styles';

interface HeaderProps {
  collapsedTable?: boolean;
  columns: ColumnItem[];
  orderBy: string;
  sortOrder?: TableSortOrder;
  onSortChange?: (name: string, direction: TableSortOrder) => void;
}

const Head = ({ collapsedTable, columns, orderBy, sortOrder, onSortChange }: HeaderProps) => {
  return (
    <Thead>
      <tr>
        {columns.map((column: any) => (
          <HeadCell orderBy={orderBy} sortOrder={sortOrder} onSortChange={onSortChange} key={column.name} {...column} />
        ))}
        {collapsedTable && <ThOpener />}
      </tr>
    </Thead>
  );
};
export default Head;
