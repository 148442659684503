export const getTextareaBackground = ({ theme, isFloating, isError }: any) => {
  if (isFloating && !isError) {
    // return theme.form.activeBackground;
  }
  return theme.backgroundPrimary;
};

export const getTextareaColor = ({ theme, disabled }: any) => {
  if (disabled) {
    return theme.fontSecondary;
  }
  return theme.fontPrimary;
};

export const getTextareaBorder = ({ theme, isFocused, isFloating, isError }: any) => {
  if (isError) {
    return theme.colors.error;
  }
  if (isFloating) {
    // return theme.primary; // TODO: remove isFloating
  }
  if (isFocused) {
    return theme.primary;
  }
  return theme.borders.grey;
};

export const getPlaceholderColor = ({ theme, isError, disabled }: any) => {
  if (isError) {
    return theme.fontError;
  }
  if (disabled) {
    return theme.fontSecondary;
  }
  return theme.fontPrimary;
};
