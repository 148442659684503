import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from '../../logic/clientReducer';
import { getIntegrationView } from '../../logic/clientSelectors';
import IntegrationEdit from './IntegrationEdit';

const mapStateToProps = ({ client }: RootState) => getIntegrationView(client);

const mapDispatchToProps = {
  setStatus: actionCreators.setClientStatus,
  getClient: actionCreators.getClient,
  updateClientIntegration: actionCreators.updateClientIntegration,
  updateClientIntegrationStatus: actionCreators.updateClientIntegrationStatus,
  getClientIntegration: actionCreators.getClientIntegration,
  getClientConfigurationSchema: actionCreators.getClientConfigurationSchema,
  getClientTestConnection: actionCreators.getClientTestConnection,
  resetPartial: actionCreators.clientResetPartial,
  integrationReset: actionCreators.integrationReset,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(IntegrationEdit);
