import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { actionCreators } from './logic/customerReducer';
import Customer from './Customer';
import { getCustomerView } from './logic/customerSelectors';

const mapStateToProps = ({ shared, customer }: RootState) => getCustomerView(shared, customer);

const mapDispatchToProps = {
  getCustomer: actionCreators.getCustomer,
  updateCustomer: actionCreators.updateCustomer,
  setStatus: actionCreators.setCustomerStatus,
  reset: actionCreators.customerReset,
  resetPartial: actionCreators.customerResetPartial,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default connect<{}, {}, any, any>(mapStateToProps, mapDispatchToProps)(Customer);
