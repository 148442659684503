import { Select } from 'common/components';
import Pagination from './Pagination';
import { ITEMS_PER_PAGE_COUNT, ITEMS_PER_PAGE_OPTIONS } from './constants';
import { ItemsPerPage, PaginationWrapper, PaginatorControls, PaginatorInfo, PaginatorWrapper } from './styles';

interface PaginatorProps {
  isItemsPerPageVisible?: boolean;
  totalCount: number;
  page: number;
  perPage: number;
  onItemsPerPageChange: (payload: any) => void;
  onPageChange: (payload: any) => void;
}

const Paginator = ({
  isItemsPerPageVisible = true,
  totalCount,
  page,
  perPage = ITEMS_PER_PAGE_COUNT,
  onItemsPerPageChange,
  onPageChange,
}: PaginatorProps) => {
  const isPaginationAvailable = !!totalCount;
  const offsetFrom = (page - 1) * perPage + 1;
  let offsetTo = page * perPage;
  offsetTo = offsetTo > totalCount ? totalCount : offsetTo;

  const selectValue = ITEMS_PER_PAGE_OPTIONS.find((item: any) => Number(item.value) === Number(perPage));

  if (isPaginationAvailable) {
    return (
      <PaginatorWrapper>
        <PaginatorInfo>
          {offsetFrom} to {offsetTo} of {totalCount}
        </PaginatorInfo>
        <PaginatorControls>
          {isItemsPerPageVisible && (
            <ItemsPerPage>
              <Select
                isClearable={false}
                isSearchable={false}
                value={selectValue}
                options={ITEMS_PER_PAGE_OPTIONS}
                onChange={onItemsPerPageChange}
                menuPlacement='top'
              />
            </ItemsPerPage>
          )}
          <PaginationWrapper>
            <Pagination
              totalItemsCount={totalCount}
              itemsCountPerPage={perPage}
              page={page}
              onPageChange={onPageChange}
            />
          </PaginationWrapper>
        </PaginatorControls>
      </PaginatorWrapper>
    );
  }
  return null;
};

export default Paginator;
