import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload } from 'store/Action';
import { customersService } from 'common/api/customersService';
import { actionCreators as notificationsActionCreators } from 'components/NotificationManager/logic/notificationReducer';
import { NotificationType } from 'types/Notifications';
import { browserHistory } from 'browserHistory';
import { APP_URLS } from 'common/constants';
import { ActionTypeKeys, actionCreators } from './customerCreateReducer';

export function* createNewCustomerSaga({ payload }: ActionWithPayload) {
  try {
    const { data } = yield call(customersService.createCustomer, payload);

    yield put(actionCreators.createCustomerSuccess());
    yield browserHistory.push(APP_URLS.toCustomerProfile(data.id));
  } catch (error) {
    const errorLocal = error as any;
    yield put(
      notificationsActionCreators.addNotification({
        type: NotificationType.ERROR,
        messageId: 'error-messages-something-wrong',
        errorText: errorLocal?.response?.data?.title || error,
        errorStatus: errorLocal?.response?.status,
      }),
    );
    yield put(actionCreators.createCustomerFailed());
  }
}

export function* customerCreateSaga() {
  yield* [takeEvery(ActionTypeKeys.CUSTOMER_CREATE, createNewCustomerSaga)];
}
