import { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import throttle from 'lodash/throttle';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import rehypeToc from '@jsdevtools/rehype-toc';
import { useBreadcrumbsContext } from 'common/components/Breadcrumbs/BreadcrumbsContext';
import { Spinner, Message } from 'common/components';
import getReleaseNotesBreadcrumbs from './getReleaseNotesBreadcrumbs';
import { Wrapper, Head, Title, Content, MarkdownContainer } from './styles';
const MARKDOWN_TOC_TAG = '[[_TOC_]]';

const ReleaseNotes = () => {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [content, setContent] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isTocExists, setIsTocExists] = useState(false);
  const [initialTopPosition, setInitialTopPosition] = useState(0);
  const [topPosition, setTopPosition] = useState(0);

  const DOCS_URL = `${window.location.origin}/docs`;
  const transformImageUri = (content: string) => (/^https?:/.test(content) ? content : DOCS_URL + content);
  const transformLinkUri = (content: string) => {
    if (content.indexOf('.attachments') === -1) {
      return content;
    }
    return /^https?:/.test(content) ? content : DOCS_URL + content;
  };

  const rehypePlugins = isTocExists ? [rehypeRaw, rehypeSlug, rehypeToc] : [rehypeRaw, rehypeSlug];

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    if (scrollTop >= initialTopPosition - 92) {
      setTopPosition(scrollTop - 92);
    } else {
      setTopPosition(0);
    }
  }, [initialTopPosition]);

  const handleScrollThrottled = throttle(handleScroll, 300);

  useEffect(() => {
    const tocContainer = document.querySelector('.toc');
    if (isTocExists && tocContainer) {
      setInitialTopPosition(tocContainer.getBoundingClientRect().top);
    }
  }, [isTocExists]);

  useEffect(() => {
    if (!isTocExists || !initialTopPosition) {
      return;
    }
    window.addEventListener('scroll', handleScrollThrottled);
    return () => {
      window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, [isTocExists, initialTopPosition, handleScrollThrottled]);

  useEffect(() => {
    setIsFetching(true);
    fetch('./docs/release-notes.md')
      .then((res) => {
        return res.text();
      })
      .then((text) => {
        const isTocTagExists = text.search(MARKDOWN_TOC_TAG) > -1;
        setIsTocExists(isTocTagExists);
        return isTocTagExists ? setContent(text.replace(MARKDOWN_TOC_TAG, '')) : setContent(text);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Something went wrong with *.md file: ', error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    setBreadcrumbs(getReleaseNotesBreadcrumbs());
    return () => setBreadcrumbs(null);
  }, [setBreadcrumbs]);

  return (
    <Wrapper>
      <Head>
        <Title>
          <Message id='release-notes-screen-title' />
        </Title>
      </Head>
      <Content>
        <MarkdownContainer isAsideExists={isTocExists} topPosition={topPosition}>
          {isFetching && <Spinner />}
          <ReactMarkdown
            // eslint-disable-next-line react/no-children-prop
            children={content}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={rehypePlugins}
            transformImageUri={transformImageUri}
            transformLinkUri={transformLinkUri}
          />
        </MarkdownContainer>
      </Content>
    </Wrapper>
  );
};
export default ReleaseNotes;
