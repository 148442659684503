import { Icon } from 'types/Icon';

export const CopiedIcon = ({ color, width, height }: Icon) => (
  <svg viewBox='0 0 17 16.92' width={width || '17'} height={height || '20'}>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d='M16.98625,1.70725 C17.37725,1.31725 17.37725,0.68325 16.98625,0.29325 C16.59625,-0.09775 15.96325,-0.09775 15.57225,0.29325 L5.01825,10.84725 L1.70725,7.53725 C1.31725,7.14625 0.68325,7.14625 0.29325,7.53725 C-0.09775,7.92825 -0.09775,8.56125 0.29325,8.95125 L4.26125,12.92025 C4.27725,12.93725 4.29225,12.95425 4.30925,12.97025 C4.50425,13.16625 4.76025,13.26325 5.01625,13.26325 C5.06425,13.26325 5.11225,13.26025 5.16025,13.25325 C5.36725,13.22425 5.56725,13.13025 5.72625,12.97025 C5.74325,12.95425 5.75925,12.93725 5.77425,12.91925 L16.98625,1.70725 Z'
        id='path-TickIcon-1'
      />
    </defs>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(5.000000, 2.000000) scale(0.7 0.7)'>
        <use fill={color || 'currentColor'} xlinkHref='#path-TickIcon-1' />
      </g>
    </g>
    <g>
      <path
        // eslint-disable-next-line max-len
        d='M11.18 0H5a1.67 1.67 0 0 0-1.67 1.67v11.66A1.67 1.67 0 0 0 5 15h8.33A1.67 1.67 0 0 0 15 13.33V3.82zM5 13.33V1.67h5V5h3.33v8.33z'
        fill={color || 'currentColor'}
      />
      <path d='M1.67 3.33H0v13.34a1.66 1.66 0 0 0 1.67 1.66h10v-1.66h-10z' fill={color || 'currentColor'} />
    </g>
  </svg>
);
