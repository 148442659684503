import { ReactNode } from 'react';
import { ErrorBoundary as ErrorBoundaryComponent } from 'react-error-boundary';
import ApplicationFallback from './ApplicationFallback';

interface ErrorBoundaryProps {
  fallback?: any;
  children: ReactNode;
}

const myErrorHandler = (error: Error) => {
  // eslint-disable-next-line no-console
  console.log('Error ', error);
};

const ErrorBoundary = ({ fallback = ApplicationFallback, children }: ErrorBoundaryProps) => {
  return (
    <ErrorBoundaryComponent FallbackComponent={fallback} onError={myErrorHandler}>
      {children}
    </ErrorBoundaryComponent>
  );
};

export default ErrorBoundary;
